//import layouts
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";

import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import { grey } from "@mui/material/colors";

//hooks
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";

//components
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SelectInfo from "./data/SelectInfo";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import SoftAvatar from "components/SoftAvatar";
import Swal from "sweetalert2";
import styled from "@emotion/styled";

import { Formik, Form, Field } from "formik";
import validationSchema from "./validerChamps/ValidationSchema";
import { CloudUpload, DateRange, InsertInvitation } from "@mui/icons-material";
import { green } from "material-ui-core/colors";
import api from "utils/axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import io from "socket.io-client";
import { useSoftUIController } from "context";
import SoftTypography from "components/SoftTypography";
const socket = io(process.env.REACT_APP_SOCKET_URL);

function UpdateTache() {
  const theme = useTheme();
  const navigate = useNavigate();

  //get all the email of observateurs
  const [emailObservateurs, setEmailObservateurs] = useState([]);
  const [emailResponsables, setEmailResponsables] = useState([]);
  const getEmailObservateursEtResponsables = () => {
    api.get("get-user-commercial?role=commercial").then((response) => {
      const observateursEmail = response.data.map((observateur) => observateur.email);
      setEmailObservateurs(observateursEmail);

      const responsablesEmail = response.data.map((responsableEmail) => responsableEmail.email);
      setEmailResponsables(responsablesEmail);
    });
  };

  useEffect(() => {
    getEmailObservateursEtResponsables();
  }, [setEmailObservateurs, setEmailResponsables]);

  //get all the entreprises existant
  const [entreprisesExistant, setEntreprisesExistant] = useState([]);
  const getEntreprisesExistant = () => {
    api.get("get-enterprises").then((response) => {
      const existantEntreprises = response.data.map(
        (entrepriseExistant) => entrepriseExistant.name
      );
      setEntreprisesExistant(existantEntreprises);
    });
  };

  useEffect(() => {
    getEntreprisesExistant();
  }, [setEntreprisesExistant]);

  //get all the entreprises potentiel
  const [entreprisesPotentiel, setEntreprisesPotentiel] = useState([]);
  const getEntreprisesPotentiel = () => {
    api.get("tasks/get-entreprises-potentiel").then((response) => {
      const potentielEntreprises = response.data.map(
        (potentielEntreprise) => potentielEntreprise.nomEntreprise
      );
      setEntreprisesPotentiel(potentielEntreprises);
    });
  };

  useEffect(() => {
    getEntreprisesPotentiel();
  }, [setEntreprisesPotentiel]);

  const { id } = useParams();

  const [tache, setTache] = useState({});
  const [newTache, setNewTache] = useState({});

  const getTache = (id) => {
    api.get(`tasks/get-task/${id}`).then((response) => {
      setTache(response.data);
    });
  };

  const initialValues = {
    nom: tache.nom,
    description: tache.description,
    responsable: tache.responsable,
    dateLimite: tache.dateLimite,
  };

  //telecharger fichier
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const newOption = { value: file, label: file.name };

    // const fileExists = selectedOptions.some((option) => option.label === newOption.label);
    // Check if a file with the same name already exists in selectedOptions
    const fileExists = selectedOptions.some((option) => {
      const indexOfDash = option.label.lastIndexOf("-");
      // Find the index of the first occurrence of '.'
      const indexOfDot = option.label.lastIndexOf(".");

      // Check if both '-' and '.' exist in the file name
      if (indexOfDash !== -1 && indexOfDot !== -1) {
        // Extract the part of the file name from the beginning until the first '-' and from the last '.' until the end
        const extractedFileName =
          option.label.substring(0, indexOfDash) + option.label.substring(indexOfDot);

        // Compare the extractedFileName with newValue.label
        return extractedFileName === newOption.label;
      }
      return false;
    });

    // If the file doesn't exist in selectedOptions, add it
    if (!fileExists) {
      setSelectedOptions([...selectedOptions, newOption]);
    }
  };

  useEffect(() => {
    if (tache.fichiers?.length > 0) {
      setSelectedOptions(tache.fichiers.map((file) => ({ value: undefined, label: file })));
    }
  }, [tache.fichiers]);

  const token = localStorage.getItem("accessToken");
  const updateTache = () => {
    const fd = new FormData();
    fd.append("nom", newTache.nom || tache.nom);
    fd.append("description", newTache.description || tache.description);
    fd.append("observateurs", newTache.observateurs || tache.observateurs);
    fd.append("responsable", newTache.responsable || tache.responsable);
    fd.append("entreprise", newTache.entreprise || tache.entreprise);
    fd.append("evaluation", newTache.evaluation || tache.evaluation);
    selectedOptions &&
      selectedOptions.length > 0 &&
      selectedOptions.forEach((file) => {
        fd.append("fichiers", file.value !== undefined ? file.value : file.label);
      });
    fd.append("dateLimite", newTache.dateLimite || tache.dateLimite);
    fd.append("rappel", newTache.rappel || tache.rappel);
    fd.append("statut", newTache.statut || tache.statut);
    fd.append("priorite", newTache.priorite || tache.priorite);
    // fd.append("commentaires", JSON.stringify(newTache.commentaires || tache.commentaires));

    if (
      (newTache.nom?.length > 1 || newTache.nom === undefined) &&
      (newTache.description?.length >= 5 || newTache.description === undefined)
    ) {
      api.put(`tasks/update-task/${id}`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${token}`
        },        
      }).then((response) => {
        navigate("/commercials/listTaches");
        Swal.fire({
          title: "Succès",
          text: "Editer tâche réussie",
          icon: "success",
          confirmButtonText: "ok",
        });
      });
    } else {
      Swal.fire({
        title: "Warning",
        text: "Ajouter correct informations.",
        icon: "warning",
        confirmButtonText: "ok",
      });
    }
  };

  const [controller, _] = useSoftUIController();

  const [comments, setComments] = useState([]);
  const handleCommentaires = (id) => {
    api.get(`tasks/get-comments-by-task/${id}`).then((res) => {
      setComments(res.data.comments);
    });
  };

  useEffect(() => {
    getTache(id);
    handleCommentaires(id);
  }, []);

  //pour les comments
  const [commentaire, setCommentaire] = useState("");
  const [showButton, setShowButton] = useState(false);

  const handleInputChange = (event) => {
    setCommentaire(event.target.value);
    setShowButton(event.target.value.trim() !== "");
  };

  // When the user add a comment
  const handleSaveComment = () => {
    const commentData = {
      utilisateur: controller.user.info.firstName + " " + controller.user.info.lastName,
      avatar: `${process.env.REACT_APP_BASE_URL}${controller.user.info?.imageUrl}`,
      commentaire: commentaire,
      createdAt: new Date(),
    };
    // Emit a comment event to the server
    socket.emit("comment", commentData, id);
  };

  // Listen for new comments from the server
  socket.on("comment", (_) => {
    handleCommentaires(id);
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ errors, touched, setFieldValue, handleSubmit, handleBlur }) => (
          <Form mt="16px" onSubmit={handleSubmit} style={{ marginTop: "16px" }}>
            <Card
              sx={{
                border: `1px solid ${grey[200]}`,
                bgcolor: "white",
                borderRadius: "15px",
                p: "20px",
                mt: "16px",
                overflow: "visible",
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" >
              <Typography
                variant="h6"
                sx={{ fontSize: "20px", fontWeight: "500" }}
              >
                Editer Tâche
              </Typography>
              <SoftButton
                type="submit"
                variant="gradient"
                color="purple"
                onClick={() => {
                  updateTache();
                }}
              >
                Modifier
              </SoftButton>
              </Box>

              <Grid container mt="8px">
                <Grid container xs={12} md={6}>
                  <Grid item xs={12} sx={{ display: "block" }}>
                    <Box mr={{ sx: "0px", md: "8px" }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                      >
                        Nom de tâche*
                      </SoftTypography>
                      <Field name="nom">
                        {({ field }) => (
                          <SoftInput
                            {...field}
                            variant="outlined"
                            fullWidth
                            placeholder="Saisissez le nom de la tâche"
                            onBlur={handleBlur}
                            value={newTache.nom !== undefined ? newTache.nom : tache.nom || ""}
                            error={newTache.nom?.length <= 1}
                            success={newTache.nom?.length > 1 || newTache.nom === undefined}
                            onChange={(event) => {
                              setNewTache({
                                ...newTache,
                                nom: event.target.value,
                              });
                              setFieldValue("nom", event.target.value);
                            }}
                          />
                        )}
                      </Field>
                      {newTache.nom?.length <= 1 && (
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "400", fontSize: "12px" }}
                          color="error"
                        >
                          {errors.nom}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sx={{ display: "block" }}>
                    <Box mr={{ sx: "0px", md: "8px" }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                      >
                        Description*
                      </SoftTypography>
                      <Field name="description">
                        {({ field }) => (
                          <SoftInput
                            {...field}
                            multiline
                            rows={8}
                            placeholder="Description"
                            variant="outlined"
                            onBlur={handleBlur}
                            value={
                              newTache.description !== undefined
                                ? newTache.description
                                : tache.description || ""
                            }
                            error={newTache.description?.length < 5}
                            success={
                              newTache.description?.length >= 5 || newTache.description === undefined
                            }
                            onChange={(event) => {
                              setNewTache({
                                ...newTache,
                                description: event.target.value,
                              });
                              setFieldValue("description", event.target.value);
                            }}
                          />
                        )}
                      </Field>
                      {newTache.description?.length < 5 && (
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "400", fontSize: "12px" }}
                          color="error"
                        >
                          {errors.description}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                <Grid container xs={12} md={6}>
                  <Grid item xs={12} sx={{ display: "block" }} mt={{ xs : "8px" , md : "0px" }}>
                    <Box ml={{ sx: "0px", md: "8px" }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                      >
                        Observateurs
                      </SoftTypography>

                      <SoftSelect
                        options={emailObservateurs
                          .filter(
                            (emailObservateur) =>
                              !tache.observateurs || !tache.observateurs.includes(emailObservateur)
                          )
                          .map((emailObservateur) => {
                            return {
                              value: emailObservateur,
                              label: emailObservateur,
                            };
                          })}
                        size="medium"
                        isMulti
                        value={
                          newTache.observateurs !== undefined
                            ? newTache.observateurs &&
                              newTache.observateurs
                                .filter((obs) => obs !== "Hamza.ramy.ing@gmail.com")
                                .map((emailObservateur) => {
                                  return {
                                    value: emailObservateur,
                                    label: emailObservateur,
                                  };
                                })
                            : (tache.observateurs &&
                                tache.observateurs
                                  .filter((obs) => obs !== "Hamza.ramy.ing@gmail.com")
                                  .map((emailObservateur) => {
                                    return {
                                      value: emailObservateur,
                                      label: emailObservateur,
                                    };
                                  })) ||
                              []
                        }
                        onChange={(selectedOptions) => {
                          // Get the values of the selected options
                          const selectedValues = selectedOptions
                            .filter((option) => option.value !== "Hamza.ramy.ing@gmail.com")
                            .map((option) => option.value)
                            .concat("Hamza.ramy.ing@gmail.com");

                          // Update the observateurs state in tache and newTache
                          setTache({ ...tache, observateurs: selectedValues });
                          setNewTache({ ...newTache, observateurs: selectedValues });
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sx={{ display: "block", mt: "16px" }}>
                    <Box ml={{ sx: "0px", md: "8px" }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                      >
                        Responsable*
                      </SoftTypography>
                      <Field name="responsable">
                        {({ _ }) => (
                          <SoftSelect
                            options={emailResponsables.map((emailResponsable) => {
                              return {
                                value: emailResponsable,
                                label: emailResponsable,
                              };
                            })}
                            value={{
                              value: tache.responsable,
                              label: tache.responsable,
                            }}
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setNewTache({
                                ...newTache,
                                responsable: event.value,
                              });
                              setTache({
                                ...tache,
                                responsable: event.value,
                              });
                            }}
                          />
                        )}
                      </Field>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sx={{ display: "block", mt:"8px" }}>
                    <Box ml={{ sx: "0px", md: "8px" }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                      >
                        Entreprise
                      </SoftTypography>

                      <SoftSelect
                        options={[
                          {
                            value: "Entreprises Existant",
                            label: "Entreprises Existant",
                            options: entreprisesExistant.map((entrepriseExistant) => {
                              return {
                                value: entrepriseExistant,
                                label: entrepriseExistant,
                              };
                            }),
                          },
                          {
                            value: "Entreprises Potentiel",
                            label: "Entreprises Potentiel",
                            options: entreprisesPotentiel.map((entreprisePotentiel) => {
                              return {
                                value: entreprisePotentiel,
                                label: entreprisePotentiel,
                              };
                            }),
                          },
                        ]}
                        value={
                          tache.entreprise
                            ? { value: tache.entreprise, label: tache.entreprise }
                            : null
                        }
                        placeholder={tache.entreprise ? null : "Sélecionner..."}
                        onChange={(event) => {
                          setNewTache({
                            ...newTache,
                            entreprise: event.value,
                          });
                          setTache({
                            ...tache,
                            entreprise: event.value,
                          });
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sx={{ display: "block", mt: "16px" }}>
                    <Box display="flex" alignItems="center" ml={{ sx: "0px", md: "8px" }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        mr="8px"
                      >
                        Évaluation :
                      </SoftTypography>
                      {tache.evaluation && (
                        <RadioGroup
                          sx={{ mt: "8px" }}
                          value={tache.evaluation}
                          onChange={(event) => {
                            setTache({ ...tache, evaluation: event.target.value });
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            <FormControlLabel
                              sx={{ ml: { xs: "0px", md: "4px" } }}
                              value="Aucune"
                              control={<Radio />}
                              label="Aucune"
                            />
                            <FormControlLabel
                              sx={{ ml: "4px" }}
                              value="Positive"
                              control={<Radio />}
                              label="Positive"
                            />
                            <FormControlLabel
                              sx={{ ml: "4px" }}
                              value="Négative"
                              control={<Radio />}
                              label="Négative"
                            />
                          </Box>
                        </RadioGroup>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ display: "block", mt: "16px" }}>
                  <Box>
                    <SoftButton
                      sx={{ mb: 1 }}
                      component="label"
                      onChange={handleFileUpload}
                      variant="contained"
                      startIcon={<CloudUpload />}
                    >
                      Télécharger des fichiers
                      <VisuallyHiddenInput type="file" />
                    </SoftButton>
                    
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      placeholder="Télécharger des fichiers"
                      options={[]}
                      value={selectedOptions}
                      filterSelectedOptions
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Télécharger des fichiers"
                        />
                      )}
                      onChange={(event, newValue) => {
                        setSelectedOptions(newValue);
                      }}
                      getOptionLabel={(option) => option.label}
                    />
                  </Box>
                </Grid>
              </Grid>
            

              <Grid container mt="8px">
                <Grid item xs={12} md={6} sx={{ display: "block" }}>
                  <Box mr={{ sx: "0px", md: "8px" }}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      sx={{ mb: 0.5 }}
                    >
                      Date limite*
                    </SoftTypography>
                    <Box sx={{ width: "100%", position: "relative" }}>
                      <Field name="dateLimite">
                        {({ _ }) => (
                          <DatePicker
                            placeholder="jj / mm / aaaa --:--"
                            format="DD/MM/YYYY HH:mm:ss"
                            plugins={[<TimePicker key="timePicker" position="bottom" />]}
                            style={{
                              width: "100%",
                              position: "absolute",
                              right: "0px",
                              left: "0px",
                              top: "0px",
                              padding: "20px 12px",
                              cursor: "pointer",
                              borderRadius: "10px",
                              borderColor:
                                tache.dateLimite === "" && !!touched.dateLimite
                                  ? "red"
                                  : tache.dateLimite !== ""
                                  ? green[300]
                                  : grey[300],
                            }}
                            value={
                              newTache.dateLimite !== undefined
                                ? newTache.dateLimite
                                : tache.dateLimite || ""
                            }
                            onChange={(selectedOption) => {
                              setNewTache({
                                ...newTache,
                                dateLimite: selectedOption.format(),
                              });
                            }}
                            minDate={new Date()}
                          />
                        )}
                      </Field>

                      <Box
                        sx={{
                          position: "absolute",
                          top: "70%",
                          right: "12px",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <InsertInvitation
                          sx={{ width: "16px ! important", height: "16px !important" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block" }}>
                  <Box
                    ml={{ xs: "0px", md: "8px", position: "relative" }}
                    mt={{ xs: "16px", md: "0px" }}
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      sx={{ mb: 0.5 }}
                    >
                      Rappel
                    </SoftTypography>

                    <Box sx={{ position: "relative", width: "100%" }}>
                      <DatePicker
                        placeholder="jj / mm / aaaa --:--"
                        multiple
                        format="DD/MM/YYYY HH:mm:ss"
                        plugins={[
                          <TimePicker key="timePicker" position="bottom" />,
                          <DatePanel key="datePannel" position="right" />,
                        ]}
                        style={{
                          width: "100%",
                          position: "absolute",
                          right: "0px",
                          left: "0px",
                          top: "0px",
                          padding: "20px 12px",
                          cursor: "pointer",
                          borderRadius: "10px",
                        }}
                        value={newTache.rappel !== undefined ? newTache.rappel : tache.rappel || ""}
                        onChange={(selectedOptions) => {
                          setNewTache({
                            ...newTache,
                            rappel: selectedOptions.map((option) => option.format()),
                          });
                        }}
                        minDate={new Date()}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: "70%",
                          right: "12px",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <DateRange sx={{ width: "16px ! important", height: "16px !important" }} />
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box mr={{ sx: "0px", md: "8px" }} position="relative">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                    >
                      Statut
                    </SoftTypography>

                    <SoftSelect
                      options={SelectInfo.statut}
                      value={SelectInfo.statut.find((stat) => stat.value === tache.statut)}
                      onChange={(event) => {
                        setNewTache({
                          ...newTache,
                          statut: event.value,
                        });
                        setTache({
                          ...tache,
                          statut: event.value,
                        });
                      }}
                    />
                  </Box>
                </Grid>


                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box ml={{ sx: "0px", md: "8px" }} mr={{ sx: "0px", md: "8px" }}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                    >
                      Priorité
                    </SoftTypography>
                    <SoftSelect
                      options={SelectInfo.priorite}
                      value={SelectInfo.priorite.find((pri) => pri.value === tache.priorite)}
                      onChange={(event) => {
                        setNewTache({
                          ...newTache,
                          priorite: event.value,
                        });
                        setTache({
                          ...tache,
                          priorite: event.value,
                        });
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Card>

            <Card
              sx={{
                border: `1px solid ${grey[200]}`,
                bgcolor: "white",
                borderRadius: "15px",
                p: "20px",
                mt: "16px",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "20px", fontWeight: "500" }}
                color={theme.palette.text.primary}
              >
                Commentaires
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 500, m: "16px 0px 0px 64px" }}
                color={theme.palette.text.primary}
              >
                {controller.user.info.firstName} {controller.user.info.lastName}
              </Typography>

              <SoftBox display="flex">
                <SoftBox
                  borderRadius="50%"
                  width="3.625rem"
                  height="3.625rem"
                  display="flex"
                  justifyContent="center"
                  alignItems="bottom"
                  color="white"
                  mx="auto"
                  sx={{ cursor: "pointer", marginRight: "3px" }}
                >
                  <SoftAvatar
                    src={
                         `${process.env.REACT_APP_BASE_URL}${controller.user.info?.imageUrl}`
                    }
                    alt="logo"
                  />
                </SoftBox>

                <SoftInput
                  placeholder="Ajouter un commentaire"
                  value={commentaire}
                  onChange={handleInputChange}
                />
              </SoftBox>
              {showButton && (
                <Button
                  variant="contained"
                  sx={{
                    width: "150px",
                    textAlign: "center",
                    m: "8px 0px 0px 64px",
                    bgColor: "grey",
                  }}
                  onClick={() => {
                    handleSaveComment();
                    setCommentaire("");
                    setShowButton(false);
                  }}
                >
                  Enregistrer
                </Button>
              )}
              {comments.map((comment) => {
                return (
                  <SoftBox key={comment.createdAt} display="block" sx={{ mt: "16px" }}>
                    <SoftBox sx={{ display: "flex", alignItems: "center" }}>
                      <SoftAvatar
                        src={`${comment.avatar}`}
                        alt="logo"
                      />
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: 500, ml: "8px" }}
                        color={theme.palette.text.primary}
                      >
                        {comment.utilisateur}
                      </Typography>
                    </SoftBox>

                    <SoftBox sx={{ width: "100%", ml: "64px" }}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                        }}
                      >
                        {comment.commentaire}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: 500,
                          color: theme.palette.text.primary,
                        }}
                      >
                        {moment(comment.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                      </Typography>
                    </SoftBox>
                  </SoftBox>
                );
              })}
            </Card>
          </Form>
        )}
      </Formik>
    </DashboardLayout>
  );
}

export default UpdateTache;
