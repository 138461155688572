import React, { useRef } from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
// import RecordRTC from 'recordrtc';

function randomID(len) {
  let result = '';
  var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

export function getUrlParams(url = window.location.href) {
  let urlStr = url.split('?')[1];
  return new URLSearchParams(urlStr);
}

const VideoConferance = () => {
  const roomID = getUrlParams().get('roomID') || randomID(5);
  const containerRef = useRef(null);
  const recorderRef = useRef(null);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
    recorderRef.current = new RecordRTC(stream, {
      type: 'video',
    });
    recorderRef.current.startRecording();
  };

  const stopRecording = async () => {
    recorderRef.current.stopRecording(() => {
      const blob = recorderRef.current.getBlob();
      // Send the blob to your backend for storage
      // You can use fetch or any other method to send the recorded data to your server
      const formData = new FormData();
      formData.append('video', blob, 'screen-recording.webm');
      fetch('http://localhost:4001/upload-video', {
        method: 'POST',
        body: formData,
      });
    });
  };

  const myMeeting = (element) => {
    const appID = 159181313;
    const serverSecret = '900cffd11f2c48177fd1e986a3928b3cqsdfhgbgnj,l:m!ùk^$^jhnpgofiudgyxcfqsg hjbnkfh,lgmjm;';
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, randomID(5), randomID(5));

    const zp = ZegoUIKitPrebuilt.create(kitToken);
    zp.joinRoom({
      container: element,
      sharedLinks: [
        {
          name: 'Personal link',
          url: window.location.protocol + '//' + window.location.host + window.location.pathname + '?roomID=' + roomID,
        },
      ],
      scenario: {
        mode: ZegoUIKitPrebuilt.GroupCall,
        config: {
          enableStereo: true,
        },
      },
    });

    // Start recording when the meeting starts
    startRecording();
  };

  return (
    <div style={{ zIndex: 0 }}>
      <div ref={(el) => { containerRef.current = el; myMeeting(el); }} style={{ width: '100vw', height: '100vh' }}></div>
      <button onClick={stopRecording}>Stop Recording</button>
    </div>
  );
};

export default VideoConferance;
