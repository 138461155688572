// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import DataTable from "pages/billing-settings/components/List/components/DataTable";


function List({ title, data, paginationTracker, onAdd, onDelete, onEdit, initPage }) {
  return (
    <SoftBox my={3}>
      <Card>
        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
          <SoftBox lineHeight={1}>
            <SoftTypography variant="h5" fontWeight="medium">
              { title }
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              CHANGE OR DELETE ME - A lightweight, extendable, dependency-free javascript HTML table plugin.
            </SoftTypography>
          </SoftBox>
          <Stack spacing={1} direction="row">
            <SoftButton variant="gradient" color="customized" size="small" onClick={onAdd}>
              + nouveau
            </SoftButton>
          </Stack>
        </SoftBox>
        <DataTable
          table={data}
          entriesPerPage={{
            defaultValue: 5,
            entries: [5, 7, 10, 15, 20, 25],
          }}
          canSearch
          paginationTracker={paginationTracker}
          onDelete={onDelete}
          onEdit={onEdit}
          initPage={initPage}
        />
      </Card>
    </SoftBox>
  );
}


List.defaultProps = {
  title: "",
  data: { columns: [], rows: [] },
  paginationTracker: () => {},
  onAdd: () => {},
  onDelete: () => {},
  onEdit: () => {},
  initPage: 0,
}

List.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  paginationTracker: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  initPage: PropTypes.number
}

export default List;