import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const Theme = () => {
  return (
    <Card id="profile">
      <SoftBox m={2}>
        <SoftTypography variant="h5" fontWeight="medium"> Aperçu de la facture </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <img src={process.env.REACT_APP_BASE_URL+"/invoice-example.jpg"} alt="example d'une facture" width="80%" />
      </SoftBox>
    </Card>
  )
}

export default Theme;