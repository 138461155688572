import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import React, { useState } from 'react'
import SoftEditor from "components/SoftEditor";
import { Card, Grid } from '@mui/material';
import SoftAvatar from 'components/SoftAvatar';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SoftPagination from 'components/SoftPagination';

export default function Flux() {
    const [currentPage, setCurrentPage] = useState(1); // Page actuelle
    const itemsPerPage = 8; // Nombre d'articles par page
    const totalPages = 2;
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Fonction pour passer à la page précédente
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    return (
        <SoftBox mt={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={8}>
                    <SoftEditor />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card height="100%" >
                        <SoftBox p={1.5}>
                            <Grid container spacing={3} mb={2}>
                                <Grid item xs={12} md={3}>
                                    <SoftAvatar
                                        src={"https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                                        }
                                        variant="rounded"
                                        size="xl"
                                        shadow="sm"
                                    />
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <SoftBox height="100%" mt={0.5} lineHeight={1}>
                                        <SoftTypography variant="h5" fontWeight="medium">
                                            Ahmed Ben Ahmed
                                        </SoftTypography>
                                        <SoftTypography variant="button" fontWeight="medium"
                                            color="secondary"
                                            fontSize="12px">
                                            Prof
                                        </SoftTypography>
                                        <SoftBox>
                                            <SoftTypography variant="button" fontWeight="medium"
                                                color="dark"
                                                fontSize="12px">
                                                Lorem ipsum dolor sit amet consectetur. Viverra nunc
                                            </SoftTypography>
                                        </SoftBox>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                border: '1px solid #e0e0e0',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <InputBase
                                                style={{ border: 'none', outline: 'none' }}
                                                sx={{ ml: 1, flex: 1 }}
                                                placeholder="Répondre a la commentaire"
                                            />
                                            <IconButton color="secondary" aria-label="directions">
                                                <SendIcon style={{ color: 'blue' }} />
                                            </IconButton>
                                        </div>

                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} mb={2}>
                                <Grid item xs={12} md={3}>
                                    <SoftAvatar
                                        src={"https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                                        }
                                        variant="rounded"
                                        size="xl"
                                        shadow="sm"
                                    />
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <SoftBox height="100%" mt={0.5} lineHeight={1}>
                                        <SoftTypography variant="h5" fontWeight="medium">
                                            Ahmed Ben Ahmed
                                        </SoftTypography>
                                        <SoftTypography variant="button" fontWeight="medium"
                                            color="secondary"
                                            fontSize="12px">
                                            Prof
                                        </SoftTypography>
                                        <SoftBox>
                                            <SoftTypography variant="button" fontWeight="medium"
                                                color="dark"
                                                fontSize="12px">
                                                Lorem ipsum dolor sit amet consectetur. Viverra nunc
                                            </SoftTypography>
                                        </SoftBox>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                border: '1px solid #e0e0e0',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <InputBase
                                                style={{ border: 'none', outline: 'none' }}
                                                sx={{ ml: 1, flex: 1 }}
                                                placeholder="Répondre a la commentaire"
                                            />
                                            <IconButton color="secondary" aria-label="directions">
                                                <SendIcon style={{ color: 'blue' }} />
                                            </IconButton>
                                        </div>

                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            {/*    <SoftPagination>
          {currentPage > 1 && (
            <SoftPagination item onClick={prevPage}><ArrowBackIosIcon /></SoftPagination>
          )}
          {Array.from({ length: totalPages }, (_, index) => (
            <SoftPagination item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </SoftPagination >
          ))}
          {currentPage < totalPages && (
            <SoftPagination item onClick={nextPage}><ArrowForwardIosIcon /> </SoftPagination>
          )}
    </SoftPagination>  */}
        </SoftBox>
    )
}