

export default class Purchase {
  
  /**
   * @constructor
   * 
   * @param {string} type : the type of purchase item
   * @param {number} quantity : the quantity of purchase items. (Default: 0)
   * @param {number} unitPriceHT : the unit price of the purchase item before VAT. (Default: 0)
   * @param {number} tva : the vat percentage. (Default: 0)
   * @param {number} discount : the discount percentage. (Default: 0)
   * @param {string} description : a description of the purchase item. (Default: "")
   * @param {boolean} applyTva : apply tva or not
   */
  constructor(type, quantity = 0, unitPriceHT = 0, tva = 0, discount = 0, description = "", applyTva, tvaName = 'Aucun TVA') {
    this.type = type;
    this.quantity = quantity;
    this.unitPriceHT = unitPriceHT,
    this.tva = tva;
    this.discount = discount;
    this.description = description;
    this.applyTva = applyTva;
    this.tvaName = tvaName;
  }

  get totalHT () {
    const total = this.unitPriceHT * this.quantity; // total before the discount
    return total - (this.discount * total / 100);
  }

  get totalTTC () {
    // totalHT + totalHT * (purshe.tva / 100)
    return this.totalHT + (this.applyTva ? this.totalHT * (this.tva / 100) : 0);
  }

  /**
   * Serializes the purchase object to a plain ojbect.
   * @returns {object} a plain object representing the purchase item.
   */
  toObject() {
    return ({
      type: this.type,
      quantity: this.quantity,
      unitPriceHT: this.unitPriceHT,
      tva: this.tva,
      discount: this.discount,
      description: this.description,
      applyTva: this.applyTva,
      totalHT: this.totalHT,
      totalTTC: this.totalTTC,
      tvaName: this.tvaName,
    });
  }

  /**
   * Creates a deep copy of the purchase object.
   * @returns {Purchase} a new 'Purchase' object that is a deep copy of the original.
   */
  deepCopy() {
    return new Purchase(this.type, this.quantity, this.unitPriceHT, this.tva, this.discount, this.description, this.applyTva, this.tvaName);
  }

};