import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import React, { useEffect, useState } from "react";
import Cube from "examples/Icons/Cube";
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import SoftTypography from "components/SoftTypography";
import StudentInfo from "./components/update-student-components/StudentInfo";
import ChangePassword from "components/shared-users/updateUser/ChangePassword";
import Parametre from "./components/update-student-components/Parametre";
import { Card, Grid } from "@mui/material";
import Header from "components/shared-users/Header";
import { useParams } from "react-router-dom";
import api from "utils/axios";

export default function UpdateStudent() {
  const { id } = useParams();
  const [student, setStudent] = useState({});
  const [selectedItem, setSelectedItem] = useState(0);

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };
  const handleFetchStudentById = async (idToFetch) => {
    try {
      if (idToFetch) {
        const response = await api.get(`/get-user/${idToFetch}`);
        const fetchStudent = response.data;
        setStudent(fetchStudent);
      }
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    if (id !== undefined) {
      handleFetchStudentById(id);
    }
  }, [id]);
  
  const sidenavItems = [
    { icon: <Shop />, label: "Étudiant", href: "/update" },
    { icon: <Cube />, label: "Mot de passe", href: "/change-password" },
    { icon: <Office />, label: "Parametre de compte", href: "/setting" },
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, index) => {
    const itemKey = `item-${index}`;
    const isSelected = index === selectedItem;
    return (
      <SoftBox key={itemKey} component="li" onClick={() => handleItemClick(index)} pt={index === 0 ? 0 : 1}>
      <SoftTypography
       component="a"
       href={`#${href}`}
       variant="button"
       fontWeight="regular"
       color="text"
       textTransform="capitalize"
       sx={({
         borders: { borderRadius },
         functions: { pxToRem },
         palette: { light },
         transitions,
       }) => ({
         display: "flex",
         alignItems: "center",
         borderRadius: borderRadius.md,
         padding: `${pxToRem(10)} ${pxToRem(16)}`,
         transition: transitions.create("background-color", {
           easing: transitions.easing.easeInOut,
           duration: transitions.duration.shorter,
         }),
         "&:hover": {
           backgroundColor: light.main,
         },
         backgroundColor: index === selectedItem ? '#e0e0e0' : 'transparent',
       })}
     >
       <SoftBox mr={1.5} lineHeight={1}>
         {icon}
       </SoftBox>
       {label}
     </SoftTypography>
   </SoftBox>
 );
});
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={9}>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
            <Card
              sx={{
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                position: "sticky",
                top: "1%",
              }}
            >
              <SoftBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={2}
                m={0}
                sx={{ listStyle: "none" }}
              >
                {renderSidenavItems}
              </SoftBox>
            </Card>
            </Grid>
            
          <Grid item xs={12} lg={9}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header user={id && student} />
                </Grid>
                <Grid item xs={12}>
                  {selectedItem === 0 ? (
                    <StudentInfo user={student} />
                  ) : selectedItem === 1 ? (
                    <ChangePassword user={student} />
                  ) : selectedItem === 2 ? (
                    <Parametre user={student} />
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}
