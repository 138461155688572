import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { enseignant,dateDebut,dateFin},
} = checkout;

const validations = [
  Yup.object().shape({
    [enseignant.name]: Yup.string().required(enseignant.errorMsg),
    [dateDebut.name]: Yup.string().required(dateDebut.errorMsg),
    [dateFin.name]: Yup.string().required(dateFin.errorMsg),
  }), 
];

export default validations;