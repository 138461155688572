export const getSender =  (loggedUser, users)=>{
    return users[0]._id === loggedUser._id ? users[1].name : users[0].name
}

export const getSenderFull =  (loggedUser, users)=>{
    return users[0]._id === loggedUser._id ? users[1] : users[0]
}

export const isSameSender = (messages, m, i, userId)=>{
    return(
        i < messages.length - 1 &&
        (messages[i + 1].author.id !== m.author.id ||
        messages[i + 1].author.id === undefined) &&
        messages[i].author.id !== userId
    )
}

export const isLastMessage = (messages, i, userId) =>{
    return(
        i === messages.length - 1 &&
        messages[messages.length - 1].author.id !== userId &&
        messages[messages.length - 1].author.id 
    )
}

export const isSameSenderMargin = (messages, m, i, userId) => {
 
  
    if (
      i < messages.length - 1 &&
      messages[i + 1].author.id === m.author.id &&
      messages[i].author.id !== userId
    )
      return 33;
    else if (
      (i < messages.length - 1 &&
        messages[i + 1].author.id !== m.author.id &&
        messages[i].author.id !== userId) ||
      (i === messages.length - 1 && messages[i].author.id !== userId)
    )
      return 0;
    else return "auto";
  };

export const isSameUser = (messages, m, i) => {
    return i > 0 && messages[i - 1].author.id === m.author.id;
  };
  