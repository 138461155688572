import React, { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

import api from "utils/axios";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import Sidenav from "layouts/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import routes, { userRoutes } from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator, setUser, setPurchase} from "context";

import SignInIllustration from "pages/authentication/SignInIllustration"; // Sign In page
import ForgetPassword from "pages/authentication/ForgetPassword"; // forget password page

// Images
import brand from "assets/images/logo-ct.png";

import NewEnterprise from "pages/enterprise/AddEnterprise";
import UpdateEnterprise from "pages/enterprise/UpdateEnterprise";
import NewInstractor from "pages/user/instructor/AddInstructor"
import NewCommercial from "pages/commercial/AddCommercial";
import UpdateCommercial from "pages/commercial/UpdateCommercial";
import InstructeurView from "pages/user/instructor/InstractorView"
import LoadingAnimation from "components/LoadingAnimation";
import Error500 from "pages/Error500";
import SetPassword from "pages/authentication/SetPassword";
import ResetPassword from "pages/authentication/ResetPassword";
import AddDepartement from "pages/resource-management/departement/AddDepartement";
import CommercialView from "pages/commercial/CommercialView";
import AddClientB2B from "pages/user/client/AddClientB2B";
import AddClientB2C from "pages/user/client/AddClientB2C";
import UpdateInstructeur from "pages/user/instructor/UpdateInstractor"
import AddCollaboratorAdmin from "pages/user/collaborator/AddCollaboratorAdmin"
import AddCollaboratorEditor from "pages/user/collaborator/AddCollaboratorEditor"
import CollaboratorView from "pages/user/collaborator/View"
import Updatecollaborator from "pages/user/collaborator/Updatecollaborator"
import UpdateClient from "pages/user/client/UpdateClient";
import ResponsableView from "pages/user/client/ResponsableView";

// student Import 
import NewStudent from "pages/user/students/AddStudent";
import UpdateStudent from "pages/user/students/UpdateStudent";
import StudentView from "pages/user/students/StudentView";
import AddEmploi from "pages/planning/emploi/AddEmploi";

// class Import 
import NewClass from "pages/resource-management/classe/AddClass";
import ClassView from "pages/resource-management/classe/ClassView";
import UpdateClass from "pages/resource-management/classe/UpdateClass";
import outOfSideBarRoutes from "out-of-sidebar.routes";
import PlanCard from "pages/resource-management/plan/PlanCard";
import AddExamen from "pages/planning/exmen/AddExamen";
import EmploiView from "pages/planning/emploi/EmploiView";
import UpdateEmploi from "pages/planning/emploi/UpdateEmploi";
import ListAllCours from "pages/resource-management/classe/components/matiere/ListAllCours";
import AddCours from "pages/resource-management/classe/components/matiere/components/AddCours";
import NewSubject from "pages/resource-management/subject/NewSubject";
import UpdateSubject from "pages/resource-management/subject/UpdateSubject";

export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor,facilityInfo } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // invocing module needs to clear the saved purchases in the context api when we change the pathes
    setPurchase(dispatch, { actionType: 'clear' });
  }, [pathname]);
  
 
  useEffect(() => {
    // check if there is refresh token and try to authenticate using it
    const refreshToken =
      localStorage.getItem("refreshToken") || sessionStorage.getItem("refreshToken");
    if (!refreshToken) {
      // guard
      setIsLoading(false);
      return;
    }

    api.get("/refresh-token", {
        headers: { Authorization: `Basic ${refreshToken}` },
      })
      .then((response) => {
        const { refreshToken, accessToken } = response.data;
        // save the user information in the context api and redirect...
        const userInfo = atob(accessToken.split(".")[1]);
        // dispatch: info: JSON.parse(atob(accessToken)), accessToken: full-coded-string, refreshToken: full-coded-string
        setUser(dispatch, { info: JSON.parse(userInfo), accessToken, refreshToken });
        localStorage.setItem("accessToken", accessToken);
        const userData = atob(accessToken.split(".")[1]);
        const parsedData = JSON.parse(userData);
        localStorage.setItem('sessionId', parsedData.sessionId);
        if (parsedData.role === "company-admin" || parsedData.role === "admin" || parsedData.role === "editor") {
          localStorage.setItem("licence", JSON.stringify(parsedData.licenceInfo))
        }
       
        const intervalId = setInterval(() => {
          api
            .get("/refresh-token", {
              headers: { Authorization: `Basic ${refreshToken}` },
            })
            .then((response) => {
              const { refreshToken, accessToken } = response.data;
              // save the user information in the context api and redirect...
              const userInfo = atob(accessToken.split(".")[1]);
              // dispatch: info: JSON.parse(atob(accessToken)), accessToken: full-coded-string, refreshToken: full-coded-string
              localStorage.setItem("accessToken", accessToken);
              setUser(dispatch, { info: JSON.parse(userInfo), accessToken, refreshToken });
              localStorage.setItem("accessToken", accessToken);
             
            })
            .catch((error) => {
              if (error.code === "ERR_BAD_REQUEST") {
                // invalid refresh token, clear the storage (session & local)
                sessionStorage.removeItem("refreshToken");
                localStorage.removeItem("refreshToken");
                localStorage.clear();
                setIsAuth(false);
                setUser(dispatch,{});
                navigate("/"); // navigate to the signin page
              } else if (error.code === "ERR_NETWORK") {
                navigate("/error-500");
              }
              clearInterval(intervalId);
            });
        }, 840000);
        setIsAuth(true);
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          // invalid refresh token, clear the storage (session & local)
          sessionStorage.removeItem("refreshToken");
          localStorage.removeItem("refreshToken");
          localStorage.clear();
          setUser(dispatch,{});
          navigate(0);
        } else if (error.code === "ERR_NETWORK") {
          navigate("/error-500");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // push a new routes without affecting the side bar UI...
  routes.push(
    {
      route: "/companies/add-enterprise",
      component: <NewEnterprise />,
      key: "add-enterprise"
    },
    {
      route: "/companies/:id",
      component: <UpdateEnterprise />,
      key: "add-enterprise",
    },
    {
      route: "/commercials/add-commercial",
      component: <NewCommercial />,
      key: "add-commercial",
    },
    {
      route: "/commercials/:id",
      component: <UpdateCommercial />,
      key: "add-commercial",
    },
    {
      route: "/commercials/view/:id",
      component: <CommercialView />,
      key: "add-commercial",
    },
    {
      route: "/administration/instructor/add-instructor",
      component: <NewInstractor />,
      key: "add-instructor",
    },
    {
      route: "/administration/instructor/:id",
      component: <UpdateInstructeur />,
      key: "add-instractor",
    },
    {
      route: "/administration/instructor/view/:id",
      component: <InstructeurView />,
      key: "add-instractor",
    },



    {
      route: "/administration/collaborator/add-collaborator-editor",
      component: <AddCollaboratorEditor />,
      key: "add-collaborator-editor",
    },


    {
      route: "/administration/collaborator/add-collaborator-admin",
      component: <AddCollaboratorAdmin />,
      key: "add-collaborator-admin",
    },
    {
      route: "/administration/collaborator/:id",
      component: <Updatecollaborator />,
      key: "add-collaborator",
    },
    {
      route: "/administration/collaborator/view/:id",
      component: <CollaboratorView />,
      key: "add-collaborator",
    },

      {
      route: "/administration/responsables/add-responsable-B2B",
      component: <AddClientB2B />,
      key: "add-responsable",
    },
    

    // students Route
    {
      route: "/administration/students/add-student",
      component: <NewStudent />,
      key: "add-student",
    },

    {
      route: "/administration/students/:id",
      component: <UpdateStudent />,
      key: "update-student",
    },
    {
      route: "/administration/students/view/:id",
      component: <StudentView />,
      key: "student-view",
    },
    {
      route: "/plan/plan/:id",
      component: <PlanCard />,
      key: "plan",
    },
    // end students Route

    // classe Route
    {
      route: "/classes/add-class",
      component: <NewClass />,
      key: "add-class",
    },
    {
      route: "/classes/:id",
      component: <UpdateClass />,
      key: "update-class",
    },
    {
      route: "/classes/view/:id",
      component: <ClassView />,
      key: "class-view",
    },
    // end classe Route
    {
      route: "/administration/responsables/add-responsable-B2C",
      component: <AddClientB2C />,
      key: "add-responsable",
    },
    {
      route: "/administration/responsables/:id",
      component: <UpdateClient />,
      key: "add-responsable",
    },
    {
      route: "/administration/responsables/view/:id",
      component: <ResponsableView />,
      key: "add-responsable",
    },
    {
      route: "/planning/emplois/add-emploi",
      component: <AddEmploi />,
      key: "add-planning",
    },
    {
      route: "/planning/emplois/:id",
      component: <UpdateEmploi />,
      key: "add-planning",
    },
    {
      route: "/planning/emplois/view/:id",
      component: <EmploiView />,
      key: "add-planning",
    },
    {
      route: "/planning/examens/add-examen",
      component: <AddExamen />,
      key: "add-planning",
    },
    // cours
    {
      route: "/classes/:id/matieres/:subjectId",
      component: <ListAllCours />,
      key: "cours",
    },
    {
      route: "/classes/:id/matieres/:subjectId/add-cours",
      component: <AddCours />,
      key: "cours",
    },
    {
      key: "plan",
      route: "/plan/plantable/:id",
      component: <PlanCard />,
    },
    //subject
    {
      name: "Matières",
      key: "subject",
      route: "/plan/subject/add-subject",
      component: <NewSubject />,
    },
    {
      name: "Matières",
      key: "subject",
      route: "/plan/subject/:id",
      component: <UpdateSubject />,
    },




    ...outOfSideBarRoutes
  );
  let facilityInfoo;
  let facilityParsed;
  if(controller.user.info && controller.user.info.role === "company-admin" &&localStorage.getItem("facility")){
    facilityInfoo = localStorage.getItem("facility");
    facilityParsed = facilityInfoo && JSON.parse(facilityInfoo);
  }

  const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.collapse ) {
      const filteredSubItems = route.collapse.filter((subItem) => {
        if (subItem.etablisementType) {
          return subItem.etablisementType.includes(facilityParsed ? facilityParsed?.type : null);
        }
        return true; // Include the item by default if etablisementType is not defined
      });

      // Update the route's collapse property with the filtered sub-items
      route.collapse = filteredSubItems;

      // Recursively call getRoutes with the updated route.collapse
      return (
        <React.Fragment key={route.key}>
          {route.route && <Route exact path={route.route} element={route.component} key={route.key} />}
          {getRoutes(filteredSubItems)}
        </React.Fragment>
      );
    }

    if (route.route) {
      return <Route exact path={route.route} element={route.component} key={route.key} />;
    }

    return null;
  });



  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  return (() => {
    if (isLoading) {
      return <LoadingAnimation />;
    } else if (direction === "rtl") {
      return (
        <CacheProvider value={rtlCache}>
          <ThemeProvider theme={themeRTL}>
            <CssBaseline />
            {layout === "dashboard" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={brand}
                  brandName="Square"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
                {configsButton}
              </>
            )}
            {layout === "vr" && <Configurator />}
            <Routes>
              {getRoutes(routes)}
              <Route path="/error-500" element={<Error500 />} />
              <Route
                path="/forget-password"
                element={!isAuth ? <ForgetPassword /> : <Navigate to="/dashboards/default" />}
              />
              <Route
                path="/"
                element={!isAuth ? <SignInIllustration /> : <Navigate to="/dashboards/default" />}
              />
              <Route path="*" element={<Navigate to="/dashboards/default" />} />
            </Routes>
          </ThemeProvider>
        </CacheProvider>
      );
    } else {
      return (
        <>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
            {pathname.startsWith("/video") ? null : (
              <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="Square"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            )}
              
              {/* <Configurator />
              {configsButton} */}
            </>
          )}
          {/* {layout === "vr" && <Configurator />} */}
          <Routes>
            {getRoutes(routes)}
            <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/error-500" element={<Error500 />} />
            <Route
              path="/forget-password"
              element={!isAuth ? <ForgetPassword /> : <Navigate to="/dashboards/default" />}
            />
            <Route
              path="/"
              element={!isAuth ? <SignInIllustration /> : <Navigate to="/dashboards/default" />}
            />
            <Route path="*" element={<Navigate to="/dashboards/default" />} />
          </Routes>
        </ThemeProvider>
        </>
      );
    }
  })();
}
