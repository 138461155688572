// page path => /billing/new-quotations

import { useState, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";



import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';
import Switch from "@mui/material/Switch";


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";


import FormField from "pages/billing-settings/components/FormField";
import selectData from "pages/billing-settings/components/GeneralSettings/data/selectData.js";
import LoadingAnimation from "components/LoadingAnimation";
import SoftButton from "components/SoftButton";


import api from "utils/axios";
import Purchase from "pages/billings-new/Purchase";
import ArticleRow from "pages/billings-new/ArticleRow";
import Totals from "pages/billings-new/Totals";


import DashboardNavbar from "layouts/DashboardNavbar";
import { useSoftUIController } from "context";


/**
 * inputs list : 
 * 1) recipient info
 * Informations-----------------
 * 2) Durée de validité en Jours: [text input of type number] (conditions.devisValidity)
 * 3) Devise (currency): [drop down list]
 * 4) tva non applicable (showNoneTvaMsg: boolean)
 * -----------------------------
 * 5) Articles:  [{type, quantity, unitPriceHT, tva%, discount%, totalHT: (getter func. calc & return totalHT), totalTTC: (getter func. calc & return totalTTC)}, {}]
 * 6) Remise générale in % (generalDiscount: number)
 * 7) Règlement ( condition de règlement: conditions.paymentTerm , mode de règlement: conditions.paymentMethod, intérets de retard: conditions.lateInterest)
 * 8) Textes affichés sur le document : 
 *    Text d'introduction (visible sur le devis) : (introTxt)
 *    Text de conclusion (visible sur le devis) : (conditions.closingTxt)
 *    Pied de page (visible sur le devis) : (footerTxt)
 *    Conditions générales de vente (visible sur le devis): (conditions.generalCondition)
 * 9) Notes: (notes)
 * 
 * 
 */

const NewQuotations = () => {

  // ----------------------------------------------------------------------- inputs
  const [configs, setConfigs] = useState();
  const [aziz, setAziz] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showBtnSpinner, setShowBtnSpinner] = useState(false);
  const [quotationPrefInputs, setQuotationPrefInputs] = useState([]);
  const [regulationInputs, setRegulationInputs] = useState([]);
  const [purchaseCount, setPurchaseCount] = useState(1);
  const [controller, _] = useSoftUIController();
  const [clients, setClients] = useState([]); // { firstName, lastName, address, phoneNumber, email, responsableType }

  // ----------------------------------------------------------------------- outputs
  const { current: recipient } = useRef({ name: "", address: "", country: "Tunisia", phone: "", webSite: "", email: "", tvaNumber: "-", activityCode: "-", enterpriseNumber: "-" });
  const { current: transmitter } = useRef({ name: '', contact: '', address: '', country: '', email: '' });

  const [currency, setCurrency] = useState();
  const [lateInterest, setLateInterest] = useState('');

  const { current: customLateInterest } = useRef({ value: '' });

  const { current: coordinates } = useRef({ userLocation: '', userLongitude: '', userLatitude: '' });
  const { current: generalDiscount } = useRef({ value: 0 });

  const { current: conditions } = useRef({ paymentTerm: "", paymentMethod: "", closingTxt: "", devisValidity: 0, generalCondition: "" });


  const [showNoneTvaMsg, setShowNoneTvaMsg] = useState();

  const { current: introTxt } = useRef({ value: '' });
  const { current: footerTxt } = useRef({ value: '' });
  const { current: notes } = useRef({ value: '' });
  const defaultTva = {
    name: (aziz?.general.tva || 'Aucun TVA : (0%)'),
    value: Number(aziz?.tva.find(tva => tva.name === aziz?.general.tva)?.value || 0),
  };
  const { current: purshes } = useRef([new Purchase(aziz?.general.articleType, 0, 0, defaultTva.value, 0, "", !aziz?.general?.tvaApplication, defaultTva.name)]);
  // const { current: purshes } = useRef([new Purchase(aziz?.articleTypes, 0, 0, defaultTva.value, 0, "", !aziz?.general?.tvaApplication, defaultTva.name)]);
  const [navigator, setNavigator] = useState({ navigate: false, path: '/' });
  const user = controller.user.info;


  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) return;
  const decodedToken = atob(accessToken.split('.')[1] || '');
  if (decodedToken === '') return;
  const decodedTokenObject = JSON.parse(decodedToken);

  useEffect(() => {
    // document.title = 'Nouveau devis';

    const handleFetch = () => {
      const invoicesConfigId = decodedTokenObject?.enterpriseInfo?.invoicesConfigId;

      transmitter.name = `${decodedTokenObject.firstName} ${decodedTokenObject.lastName}`;
      transmitter.contact = decodedTokenObject.phoneNumber;
      transmitter.address = decodedTokenObject.address;
      transmitter.country = "Tunisia"; // 'Tunisia' is the default value you can change it
      transmitter.email = decodedTokenObject.email;
      api.get(`get-invoices-config?invoicesConfigId=${invoicesConfigId}`).then(({ data }) => {
        console.log(data," : data");
        setAziz(data);
        setConfigs({ ...data });
        setShowNoneTvaMsg(data.general?.tvaApplication);
        setCurrency(data.general?.devis);
        introTxt.value = (data.devisPreferences?.introTxtFR);
        conditions.closingTxt = data.devisPreferences?.closingTxtFR;
        conditions.generalCondition = data.devisPreferences?.saleTermsFR;
        conditions.paymentTerm = data.general?.paymentTerm;
        conditions.paymentMethod = data.general?.paymentMethod;
        setLateInterest(data.general?.lateInterest?.description);

        conditions.paymentModality = [
          {value: "sans facilité", label:"sans facilité"},
          {value:"avec facilité", label: "avec facilité"}
        ]

        footerTxt.value = data.devisPreferences?.footerFR;

        setQuotationPrefInputs([
          {
            key: "introTxtFR",
            label: "Texte d'introduction (visible sur le devis)",
            defaultValue: data.devisPreferences?.introTxtFR,
            onChange: (e) => { introTxt.value = e.target.value; }
          },
          {
            key: "conditions.closingTxt",
            label: "Texte de conclusion (visible sur le devis)",
            defaultValue: data.devisPreferences?.closingTxtFR,
            onChange: (e) => { conditions.closingTxt = e.target.value }
          },
          {
            key: "footerFR",
            label: "Pied de page (visible sur le devis)",
            defaultValue: data.devisPreferences?.footerFR,
            onChange: (e) => { footerTxt.value = e.target.value }
          },
          {
            key: "devisPreferences.saleTermsFR",
            label: "Conditions générales de vente (visible sur le devis)",
            defaultValue: data.devisPreferences?.saleTermsFR,
            onChange: (e) => { conditions.generalCondition = e.target.value }
          },
          {
            key: "notes",
            label: "notes",
            onChange: (e) => notes.value = (e.target.value)
          }
        ]);

        setRegulationInputs([
          {
            key: "paymentTerm",
            label: "Conditions de règlement",
            options: data.paymentTerms?.map(item => ({ value: item, label: item })),
            defaultValue: { label: data.general?.paymentTerm },
            onChange: (e) => { conditions.paymentTerm = e.value }
          },
          // {
          //   key: "paymentMethod",
          //   label: "Mode de règlement",
          //   options: data.paymentMethods?.map(item => ({ value: item, label: item })),
          //   defaultValue: { label: data.general?.paymentMethod },
          //   onChange: (e) => { conditions.paymentMethod = e.value }
          // },
          {
            key: "lateInterest",
            label: "Intérêts de retard",
            options: [...(data.lateInterests?.map(({ description, value }) => ({ value: description, label: description }))), { value: "À préciser", label: "À préciser" }],
            defaultValue: { label: data.general?.lateInterest?.description },
            onChange: (e) => { setLateInterest(e.value) }
          },
          {
            key: "paymentModality",
            label: "Modalité de paiement",
            options: conditions.paymentModality?.map((item) => { 
              return ({ value: item.value, label: item.label })
             }),
            defaultValue: { label: "sans facilité" },
            onChange: (e) => { conditions.paymentModality = e.value }
          },
        ]);
        return;
      }).catch((error) => {
        console.debug(error);
      }).finally(() => {
        setIsLoading(false);
      });

    }
    handleFetch();

    const facility = JSON.parse(localStorage.getItem("facility"));
    const fetchClients = async () => {

      let clientsToAdd = new Set(); // Using Set to avoid duplicates

      if (user.role === "super-admin" || user.role === "commercial") {
        const responses = await Promise.all([
          api.get("/get-enterprises"),
          api.get("tasks/get-entreprises-potentiel")
        ]);

        responses.forEach(({ data }) => {
          data.forEach(({ name, email, adress, phoneNumber, serialNumber, nomEntreprise, matriculeFiscal }) => {
            clientsToAdd.add({ name, email, adress, phoneNumber, serialNumber, nomEntreprise, matriculeFiscal });
          });
        });
      } else if (user.role === "company-admin" || user.role === "admin") {
        const facility = JSON.parse(localStorage.getItem("facility"));
        const responses = await Promise.all([
          api.get(`/get-responsable-by-type/${facility._id}?responsableType=b2b`),
          api.get(`/get-responsable-by-type/${facility._id}?responsableType=b2c`)
        ]);

        responses.forEach(({ data }) => {
          data.forEach(({ firstName, lastName, address, phoneNumber, email, responsableType }) => {
            clientsToAdd.add({ firstName, lastName, address, phoneNumber, email, responsableType });
          });
        });
      }

    // Convert Set to array and set the state
    setClients(prevClients => [...prevClients, ...Array.from(clientsToAdd)]);
  };

  fetchClients();
  }, []);
  // useEffect(() => {
  //   if (aziz) {
  //     const defaultTva = {
  //       name: (aziz.general.tva || 'Aucun TVA : (0%)'),
  //       value: Number(aziz.tva.find(tva => tva.name === aziz.general.tva)?.value || 0),
  //     };

  //     purshes.push(
  //       // new Purchase(data.general.articleType, 5, 25.7103, data.tva[0].value, 5, "Data - Power BI, Talend, PL 300", !data.general?.tvaApplication, data.tva[0].name),
  //       // new Purchase(data.general.articleType, 30, 84.99, data.tva[1].value, 8.77, "Vitest - Javascript Unit Testing", !data.general?.tvaApplication, data.tva[1].name),
  //       // new Purchase(data.general.articleType, 82, 434, data.tva[2].value, 30, "Système - DevOps", !data.general?.tvaApplication, data.tva[2].name),
  //       new Purchase(aziz.general.articleType, 0, 0, defaultTva.value, 0, "", !aziz.general?.tvaApplication, defaultTva.name),
  //     );
  //   }

  // }, [])

  const recipientInputs = [
    { key: "name", label: "Nom et Prénom", onChange: (e, key) => { recipient[key] = e.target.value } },
    { key: "address", label: "Adresse", onChange: (e, key) => { recipient[key] = e.target.value } },
    // { key: "country", label: "Pays" }, the default will be tunisia but you can make it changable in the user
    { key: "phone", label: "Numéro de téléphone", onChange: (e, key) => { recipient[key] = e.target.value } },
    { key: "webSite", label: "Site web", onChange: (e, key) => { recipient[key] = e.target.value } },
    { key: "email", label: "Adresse email", onChange: (e, key) => { recipient[key] = e.target.value } },
    { key: "tvaNumber", label: "tva-number-change-me", onChange: (e, key) => { recipient[key] = e.target.value } },
    { key: "activityCode", label: "activity-code-change-me", onChange: (e, key) => { recipient[key] = e.target.value } },
    { key: "enterpriseNumber", label: "enterprise-number-change-me", onChange: (e, key) => { recipient[key] = e.target.value } },
  ];


  //------------------------------------------------------------------------------------------------------------------------------------------- on-submit-handler ...
  const submitHandler = () => {


    const purchases = [];
    for (const p of purshes) {
      if (p.quantity === 0 || p.unitPriceHT === 0) continue;
      const purchaseObject = p.toObject();
      if (showNoneTvaMsg) purchaseObject.tva = 0;
      purchases.push(purchaseObject);
    }

    if (recipient.name.length === 0 || recipient.email.length === 0) {
      // guard : no client is chosen
      alert('please pick a client');
      return;
    }

    if (purchases.length === 0) {
      // guard : empty purchases list
      alert('empty purchases list');
      return;
    }

    setShowBtnSpinner(true);


    // console.debug({
    //   transmitter,
    //   recipient,
    //   introTxt: introTxt.value,
    //   currency,
    //   purshes: purchases,
    //   showNoneTvaMsg,
    //   conditions: {
    //     ...conditions,
    //     lateInterest: lateInterest === 'À préciser' ? customLateInterest.value : lateInterest
    //   },
    //   footerTxt: footerTxt.value,
    //   generalDiscount: generalDiscount.value,
    //   coordinates,
    //   notes: notes.value,
    // });


    api.post('create-devis', {
      transmitter,
      recipient,
      introTxt: introTxt.value,
      currency,
      purshes: purchases,
      showNoneTvaMsg,
      conditions: {
        ...conditions,
        lateInterest: lateInterest === 'À préciser' ? customLateInterest.value : lateInterest
      },
      footerTxt: footerTxt.value,
      generalDiscount: generalDiscount.value,
      coordinates,
      notes: notes.value,
    }).then(({ data }) => {
      const quotationLink = `/billing/quotations/${data._id}`;
      setNavigator({ navigate: true, path: quotationLink });
    }).catch(error => {
      console.debug(error);
    }).finally(() => {
      setShowBtnSpinner(false);
    });
  };

  // toggle the application of tva state for all the articles
  const tvaApplicationHandler = (e) => {
    const value = e.target.checked;
    for (const purchase of purshes) {
      purchase.applyTva = !value;
    }
    setShowNoneTvaMsg(value); // this will update the UI
    console.log(showNoneTvaMsg, " : showNoneTvaMsg");
  };


  //---------------------------------------------------------------------------------------------------------- Functions: add, duplicate, update and remove purchase (ArticleRow)
  const addPurchase = () => {
    const defaultTva = {
      name: (configs?.general?.tva || 'Aucun TVA : (0%)'),
      value: Number(configs?.tva.find(tva => tva.name === configs?.general?.tva)?.value || 0),
    };

    // purshes = [...purshes, new Purchase(configs.general?.articleType, 0, 0, defaultTva.value, 0, "", !showNoneTvaMsg, defaultTva.name)];
    purshes.push(new Purchase(configs.general?.articleType, 0, 0, defaultTva.value, 0, "", !showNoneTvaMsg, defaultTva.name))

    setPurchaseCount(prev => (prev + 1)); // just to update the UI
  };

  const duplicatePurchase = (index) => {
    const copyPurchase = purshes[index].deepCopy();
    purshes.splice(index, 0, copyPurchase);
    setPurchaseCount(prev => (prev + 1)); // just to update the UI
  };

  const changePurchaseInput = (index, key, newValue) => {
    purshes[index][key] = newValue;
  };

  const removePurchase = (index) => {
    purshes.splice(index, 1);
    setPurchaseCount(prev => (prev - 1)); // just to update the UI
  };



  // ----------------------------------------------------------------------------------------------------- Function: change the general discount handler
  const changeGeneralDiscountHandler = (newGeneralDiscountVal) => {
    generalDiscount.value = newGeneralDiscountVal;
  }

  if (navigator.navigate) {
    return <Navigate to={navigator.path} />;
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- JSX
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        isLoading ? <LoadingAnimation /> :
          <Card sx={{ overflow: "visible", my:"32px" }}>
            <SoftBox p={3} mb={1}> <SoftTypography variant="h3" color="info">Nouveau devis</SoftTypography> </SoftBox>
            <SoftBox component="form" pb={3} px={3}>
              <Grid container spacing={3}>
                {/* -------------------------------------------------------------------------------------------------------------------- destinataire */}
                <Grid item xs={12}>
                  <SoftTypography variant="h4" fontWeight="medium"> Destinataire </SoftTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftSelect
                    options={
                      clients.map(({ firstName, lastName, address, phoneNumber, email, responsableType, name, serialNumber, nomEntreprise, matriculeFiscal }) => ({
                        label: user.role === "super-admin"  ?(nomEntreprise ? `${nomEntreprise}${matriculeFiscal ? " ," + matriculeFiscal : ""}, entreprise potentielle` : `${name}, ${serialNumber}, entreprise`) : 
                        user.role === "commercial" ?  (nomEntreprise ? `${nomEntreprise}, entreprise potentielle` :`${name}, entreprise`):
                        user.role === "admin" || user.role === "company-admin" && `${firstName} ${lastName}, ${responsableType}`,
                        value: { firstName, lastName, address, phoneNumber, email }
                      }))
                    }

                    onChange={(e) => {
                      const client = e.value;
                      // { name: "-", address: "-", country: "Tunisia", phone: "-", webSite: "-", email: "-", tvaNumber: "-", activityCode: "-", enterpriseNumber: "-" }
                      recipient.name = `${client.firstName} ${client.lastName}`;
                      recipient.address = client.address;
                      recipient.phone = client.phoneNumber;
                      recipient.email = client.email;
                    }}
                  />
                </Grid>

                {
                  // recipientInputs.map(({ key, label, onChange }) => (
                  //   <Grid key={key} item xs={12} sm={6}>
                  //     <FormField
                  //       label={label}
                  //       placeholder={ label.toLowerCase() }
                  //       onChange = { (e) => onChange(e, key) }
                  //     />
                  //   </Grid>
                  // ))
                }

                {/* -------------------------------------------------------------------------------------------------------------------- Informations */}
                <Grid item xs={12}>{<SoftTypography variant="h4" fontWeight="medium"> Informations </SoftTypography>}</Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    label="Durée de validité en Jours"
                    inputProps={{ type: "number", min: 0 }}
                    defaultValue={0}
                    onChange={(e) => { conditions.devisValidity = +e.target.value }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Devise
                      </SoftTypography>
                    </SoftBox>
                    <SoftSelect
                      options={selectData.currency}
                      defaultValue={selectData.currency.filter(currency => currency.value === configs?.general?.devis)}
                      onChange={(e) => { setCurrency(e.value) }}
                    />
                  </SoftBox>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    width={{ xs: "100%", sm: "auto" }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <SoftBox mr={1}>
                      <Switch defaultChecked={configs?.general.tvaApplication} onChange={tvaApplicationHandler} />
                    </SoftBox>
                    <SoftBox lineHeight={0} mx={1}>
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        TVA non applicable
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Grid>

                {/* -------------------------------------------------------------------------------------------------------------------- Règlement */}
                <Grid item xs={12}>{<SoftTypography variant="h4" fontWeight="medium"> Règlement </SoftTypography>}</Grid>
                {
                  regulationInputs.map(({ key, label, options, defaultValue, onChange }) => (
                    <Grid key={key} item xs={12} sm={3}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {label}
                          </SoftTypography>
                        </SoftBox>
                        <SoftSelect
                          options={options}
                          defaultValue={defaultValue}
                          onChange={onChange}
                        />
                      </SoftBox>
                    </Grid>
                  ))
                }

                {
                  lateInterest === "À préciser" && (
                    <Grid item xs={12} sm={3}>
                      <FormField
                        label={"Intérêts de retard personnalisé"}
                        onBlur={(e) => { customLateInterest.value = e.target.value; }}
                      />
                    </Grid>
                  )
                }

                {/* -------------------------------------------------------------------------------------------------------------------- Articles */}
                <Grid item xs={12}>{<SoftTypography variant="h4" fontWeight="medium"> Articles </SoftTypography>}</Grid>
                {
                  purshes.map(({ type, quantity, unitPriceHT, tva, discount, description, totalHT, totalTTC, applyTva, tvaName }, index) => {
                    return (
                      <ArticleRow
                        key={`article-row-${index}-${uuidv4()}`}
                        articlesType={aziz.articleTypes}
                        type={type}
                        quantity={quantity}
                        unitPriceHT={unitPriceHT}
                        tva={tva}
                        discount={discount}
                        description={description}
                        totalHT={totalHT}
                        totalTTC={totalTTC}
                        index={index}
                        applyTva={applyTva}
                        tvaName={tvaName}
                        duplicatePurchase={duplicatePurchase.bind(null, index)}
                        removePurchase={removePurchase}
                        changePurchaseInput={changePurchaseInput}
                        tvaList={configs?.tva}
                      />
                    )
                  })
                }

                <Grid item xs={12} sm={12}>
                  <SoftBox
                    display="flex"
                    justifyContent="start"
                  >
                    <SoftButton
                      variant="gradient"
                      color="dark"
                      size="medium"
                      onClick={addPurchase}
                    >
                      Ajouter une ligne
                    </SoftButton>
                  </SoftBox>
                </Grid>

                <Grid item xs={12} sm={9} />
                <Totals currency={currency} applyTva={!showNoneTvaMsg} onChangeGeneralDiscount={changeGeneralDiscountHandler} />

                

                {/* ---------------------------------------------------------------------------------------------------------------------------- Textes affichés sur le document */}
                <Grid item xs={12}>{<SoftTypography variant="h4" fontWeight="medium"> Textes affichés sur le document </SoftTypography>}</Grid>
                {
                  quotationPrefInputs.map((input) => (
                    <Grid key={input.key} item xs={12} sm={6}>
                      <FormField
                        label={input.label}
                        placeholder={input.label}
                        defaultValue={input.defaultValue}
                        onChange={input.onChange}
                      />
                    </Grid>
                  ))
                }

                <Grid item xs={12} sm={12}>
                  <SoftBox
                    display="flex"
                    justifyContent="end"
                  >
                    {
                      showBtnSpinner
                        ? <CircularProgress />
                        : <SoftButton
                          variant="gradient"
                          color="customized"
                          size="medium"
                          onClick={submitHandler}
                        >
                          Créer le devis
                        </SoftButton>
                    }
                  </SoftBox>
                </Grid>

              </Grid>
            </SoftBox>
          </Card>
      }
    </DashboardLayout>
  )
}

export default NewQuotations;
