import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { parse, differenceInSeconds } from "date-fns";
import { Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";

function RemainingTime({ dateLimite }) {
  const [remainingTime, setRemainingTime] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeRemaining = calculateTimeRemaining(dateLimite);
      setRemainingTime(timeRemaining);
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dateLimite]);

  const calculateTimeRemaining = (dateString) => {
    const dateObj = parse(dateString, "dd/MM/yyyy HH:mm:ss", new Date());

    // Calculate the difference between the parsed date and the current date in seconds
    const diffInSeconds = differenceInSeconds(dateObj, new Date());

    // Determine if the difference is negative
    const isNegative = diffInSeconds < 0;

    // Get the absolute value of the difference
    const absDiffInSeconds = Math.abs(diffInSeconds);

    // Calculate the hours, minutes, and seconds
    const hours = Math.floor(absDiffInSeconds / 3600);
    const minutes = Math.floor((absDiffInSeconds % 3600) / 60);
    const seconds = absDiffInSeconds % 60;

    // Format the output
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    // Construct the formatted time string
    let formattedTime = `${
      isNegative ? "- " : "+ "
    }${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    if(isNegative && formattedHours > 24){
      formattedTime = "Tâche non déterminer";
    }

    return formattedTime;
  };

  return (
    <Typography
      variant="body2"
      fontSize="10px"

      ml="4px"
      sx={{
        color:
          remainingTime.charAt(0) === "+" ? `${green[500]} !important` : `${red[500]} !important`,
      }}
    >
      {remainingTime}
    </Typography>
  );
}

RemainingTime.propTypes = {
  dateLimite: PropTypes.string,
};

export default RemainingTime;
