import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SoftBox from "components/SoftBox";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SoftSelect from "components/SoftSelect";
import SoftBadgeDot from "components/SoftBadgeDot";
import { useState, useEffect } from "react";
import SelectInfo from "../data/Selectinfo";
function DefaultLineChart({ LineCharteData, title, height, display, typeChartResponse , moisChart, setMoisChart }) {
  const [typeChart, setTypeChart] = useState("Chiffre Affaires");
  const [periodeChart, setPeriodeChart] = useState("Annuel");
  const [chartLabels, setChartLabels] = useState([]);

  useEffect(() => {
    // Update chart labels based on selected month
    if (periodeChart === "Mensuel") {
      const selectedMonthIndex = SelectInfo.mois.findIndex((mois) => mois.value === moisChart);
      const daysInMonth = new Date(new Date().getFullYear(), selectedMonthIndex + 1, 0).getDate();
      setChartLabels(Array.from({ length: daysInMonth }, (_, i) => i + 1));
    } else {
      setChartLabels(["Jan", "Fev", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);
    }
  }, [periodeChart, moisChart]);

  let filteredDatasets = LineCharteData.datasets;

  if (typeChartResponse) {
    if (periodeChart === "Annuel") {
      filteredDatasets = LineCharteData.datasets.filter(dataset => dataset.label === typeChart);
    } else if (periodeChart === "Mensuel" && typeChart === "Chiffre Affaires") {
      filteredDatasets = LineCharteData.datasets.filter(dataset => dataset.label === "Chiffre Affaires Par jour");
    } else if (periodeChart === "Mensuel" && typeChart === "Gain") {
      filteredDatasets = LineCharteData.datasets.filter(dataset => dataset.label === "Gain Par jour");
    } else if (periodeChart === "Mensuel" && typeChart === "Charge") {
      filteredDatasets = LineCharteData.datasets.filter(dataset => dataset.label === "Charge Par jour");
    }
  }
  const datasets = filteredDatasets.map(dataset => ({
    ...dataset,
    tension: 0.4,
    borderWidth: 3,
    pointRadius: 2,
    pointBackgroundColor: colors[dataset.color]?.main || colors.dark.main,
    borderColor: colors[dataset.color]?.main || colors.dark.main,
    maxBarThickness: 6,
  }));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      y: {
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
        },
        ticks: {
          display: true,
          padding: 10,
          color: "#b2b9bf",
          font: {
            size: 11,
            family: typography.fontFamily,
            style: "normal",
            lineHeight: 2,
          },
        },
      },
      x: {
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          borderDash: [5, 5],
        },
        ticks: {
          display: true,
          color: "#b2b9bf",
          padding: 10,
          font: {
            size: 11,
            family: typography.fontFamily,
            style: "normal",
            lineHeight: 2,
          },
        },
      },
    },
  };

  return (
    <Card sx={{ width: "100%", p: 3, overflow: "visible" }}>
      <Box display={{ xs: "block", md: "flex" }} alignItems="center" justifyContent="space-between">
        <Typography variant="h6" color="initial">{title}</Typography>
        <Box ml="32px" display="flex" alignItems="center">
          {LineCharteData.datasets.length > 1 && !typeChartResponse &&
            LineCharteData.datasets.map((dataset) => (
              <SoftBadgeDot key={dataset.label} color={dataset.color} badgeContent={dataset.label} />
            ))
          }
        </Box>
        <Box display={display}>
          <Grid container spacing={2} display="flex" alignItems="center">
            <Grid item xs={12} md={periodeChart === "Mensuel" ? 4 : 6}>
              <SoftSelect
                placeholder="choisir type"
                options={SelectInfo.type}
                defaultValue={SelectInfo.type.find((type) => type.value === typeChart)}
                onChange={(event) => {
                  setTypeChart(event.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={periodeChart === "Mensuel" ? 4 : 6}>
              <SoftSelect
                placeholder="choisir période"
                options={SelectInfo.periode}
                defaultValue={SelectInfo.periode.find((periode) => periode.value === periodeChart)}
                onChange={(event) => {
                  setPeriodeChart(event.value);
                }}
              />
            </Grid>
            {periodeChart === "Mensuel" &&
              <Grid item xs={12} md={4} >
                <SoftSelect
                  placeholder="choisir mois"
                  options={SelectInfo.mois}
                  defaultValue={SelectInfo.mois.find((mois) => mois.value === moisChart)}
                  onChange={(event) => {
                    setMoisChart(event.value);
                  }}
                />
              </Grid>
            }
          </Grid>
        </Box>
      </Box>
      <SoftBox width="100%" height={{ xs: "400px", lg: height }}>
        <Line data={{ ...LineCharteData, labels: chartLabels, datasets }} options={options} />
      </SoftBox>
    </Card>
  );
}

DefaultLineChart.defaultProps = {
  height: "170px",
  display: "none",
};

DefaultLineChart.propTypes = {
  title: PropTypes.string,
  LineCharteData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
        data: PropTypes.arrayOf(PropTypes.number),
      }),
    )
  }).isRequired,
  height: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  typeChartResponse: PropTypes.bool,
  moisChart: PropTypes.string,
  setMoisChart: PropTypes.func,
};

export default DefaultLineChart;
