// page path => /billing/new-bill

import { useState, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";


import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';
import Switch from "@mui/material/Switch";


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";


import FormField from "pages/billing-settings/components/FormField";
import selectData from "pages/billing-settings/components/GeneralSettings/data/selectData.js";
import LoadingAnimation from "components/LoadingAnimation";
import SoftButton from "components/SoftButton";


import api from "utils/axios";
import Purchase from "pages/billings-new/Purchase";
import ArticleRow from "pages/billings-new/ArticleRow";
import Totals from "pages/billings-new/Totals";

import DashboardNavbar from "layouts/DashboardNavbar";


/**
 * inputs list : 
 * 1) recipient info
 * Informations-----------------
 * 2) Durée de validité en Jours: [text input of type number] (conditions.devisValidity)
 * 3) Devise (currency): [drop down list]
 * 4) tva non applicable (showNoneTvaMsg: boolean)
 * -----------------------------
 * 5) Articles:  [{type, quantity, unitPriceHT, tva%, discount%, totalHT: (getter func. calc & return totalHT), totalTTC: (getter func. calc & return totalTTC)}, {}]
 * 6) Remise générale in % (generalDiscount: number)
 * 7) Règlement ( condition de règlement: conditions.paymentTerm , mode de règlement: conditions.paymentMethod, intérets de retard: conditions.lateInterest)
 * 8) Textes affichés sur le document : 
 *    Text d'introduction (visible sur le devis) : (introTxt)
 *    Text de conclusion (visible sur le devis) : (conditions.closingTxt)
 *    Pied de page (visible sur le devis) : (footerTxt)
 * 9) Notes: (notes)
 * 
 * 
 */


const NewBill = ({ type }) => { // facture, insc or pre-insc
  const typeBill = type;
  // ----------------------------------------------------------------------- inputs
  const [configs, setConfigs] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showBtnSpinner, setShowBtnSpinner] = useState(false);
  const [billPrefInputs, setBillPrefInputs] = useState([]);
  const [regulationInputs, setRegulationInputs] = useState([]);
  const [purchaseCount, setPurchaseCount] = useState(1);
  const [clients, setClients] = useState([]); // { firstName, lastName, address, phoneNumber, email, responsableType }

  // ----------------------------------------------------------------------- outputs
  const { current: recipient} = useRef({ name: "", address: "", country: "Tunisia", phone: "", webSite: "", email: "", tvaNumber: "-", activityCode: "-", enterpriseNumber: "-" });
  const { current: transmitter} = useRef({name: '', contact: '', address: '', country: '', email: '' });

  const [currency, setCurrency] = useState();
  const [lateInterest, setLateInterest] = useState('');

  const { current: customLateInterest } = useRef({ value: '' });

  const { current: purshes } = useRef([]);
  const { current: generalDiscount } = useRef({value: 0});

  const { current: conditions } = useRef({ paymentTerm: "", paymentMethod: "", closingTxt: "" });
  const { current: rib } = useRef({ iban: "", bic: "", titulaire: "" });


  const [showNoneTvaMsg, setShowNoneTvaMsg] = useState();

  const [paymentDetails, setPaymentDetails] = useState([{
    amount: Math.floor(Math.random() * 10),
    date: new Date(),
    transactionNumber: "",
    type: "--------",
    percentage: (Math.random() * 100),
    startDate: new Date(),
    endDate: new Date()
  }]);

  const { current: introTxt } = useRef({ value: '' });
  const { current: footerTxt } = useRef({ value: '' });
  const { current: notes } = useRef({ value: '' });
  const [navigator, setNavigator] = useState({ navigate: false, path: '/' });
  
  
  let documentTitle = 'Nouvelle' + { facture: ' Facture', insc: ' Inscriptions',  'pre-insc': ' Préinscriptions' }[type];
  useEffect(() => {
    document.title = documentTitle;
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    const decodedToken = atob(accessToken.split('.')[1] || '');
    if (decodedToken === '') return;
    
    const decodedTokenObject = JSON.parse(decodedToken);

    const invoicesConfigId = decodedTokenObject?.enterpriseInfo?.invoicesConfigId;
    
    transmitter.name = `${decodedTokenObject.firstName} ${decodedTokenObject.lastName}`;
    transmitter.contact = decodedTokenObject.phoneNumber;
    transmitter.address = decodedTokenObject.address;
    transmitter.country = "Tunisia"; // 'Tunisia' is the default value you can change it
    transmitter.email = decodedTokenObject.email;

    api.get(`get-invoices-config?invoicesConfigId=${invoicesConfigId}`).then(({data}) => {
      console.log(data," : data");
      setConfigs({...data});
      setShowNoneTvaMsg(data.general?.tvaApplication);
      setCurrency(data.general?.devis);
      introTxt.value = (data.facturePreferences?.introTxtFR);
      
      conditions.closingTxt = data.facturePreferences?.closingTxtFR;
      conditions.paymentTerm = data.general?.paymentTerm;
      conditions.paymentMethod = data.general?.paymentMethod;
      setLateInterest(data.general?.lateInterest?.description);

      conditions.paymentModality = [
        {value: "sans facilité", label:"sans facilité"},
        {value:"avec facilité", label: "avec facilité"}
      ]
      
      footerTxt.value = data.facturePreferences?.footerFR;

      const defaultTva = {
        name: (data.general.tva || 'Aucun TVA : (0%)'),
        value: Number(data.tva.find(tva => tva.name === data.general.tva)?.value || 0),
      };

      purshes.push(
        // new Purchase(data.general.articleType, 5, 25.7103, data.tva[0].value, 5, "Data - Power BI, Talend, PL 300", !data.general?.tvaApplication, data.tva[0].name),
        // new Purchase(data.general.articleType, 30, 84.99, data.tva[1].value, 8.77, "Vitest - Javascript Unit Testing", !data.general?.tvaApplication, data.tva[1].name),
        // new Purchase(data.general.articleType, 82, 434, data.tva[2].value, 30, "Système - DevOps", !data.general?.tvaApplication, data.tva[2].name),
        new Purchase(data.general.articleType, 0, 0, defaultTva.value, 0, "", !data.general?.tvaApplication, defaultTva.name),
      );

      setBillPrefInputs([
        {
          key: "introTxtFR",
          label: "Texte d'introduction (visible sur le devis)",
          defaultValue: data.facturePreferences?.introTxtFR,
          onChange: (e) => { introTxt.value = e.target.value; }
        },
        {
          key: "conditions.closingTxt",
          label: "Texte de conclusion (visible sur le devis)",
          defaultValue: data.facturePreferences?.closingTxtFR,
          onChange: (e) => { conditions.closingTxt = e.target.value }
        },
        {
          key: "footerFR",
          label: "Pied de page (visible sur le devis)",
          defaultValue: data.facturePreferences?.footerFR,
          onChange: (e) => { footerTxt.value = e.target.value }
        },
        {
          key: "notes",
          label: "notes",
          onChange: (e) => notes.value = (e.target.value)
        }
      ]);

      setRegulationInputs([
        {
          key: "paymentTerm",
          label: "Conditions de règlement",
          options: data.paymentTerms?.map(item => ({ value: item, label: item})),
          defaultValue: { label : data.general?.paymentTerm },
          onChange: (e) => { conditions.paymentTerm = e.value }
        },
        // {
        //   key: "paymentMethod",
        //   label: "Mode de règlement",
        //   options: data.paymentMethods?.map(item => ({ value: item, label: item})),
        //   defaultValue: { label: data.general?.paymentMethod },
        //   onChange: (e) => { conditions.paymentMethod = e.value }
        // },
        {
          key: "lateInterest",
          label: "Intérêts de retard",
          options: [...(data.lateInterests?.map(({description, value}) => ({ value: description, label: description}))), { value: "À préciser", label: "À préciser" }],
          defaultValue: { label : data.general?.lateInterest?.description },
          onChange: (e) => { setLateInterest(e.value) }
        },
        {
          key: "paymentModality",
          label: "Modalité de paiement",
          options: conditions.paymentModality?.map((item) => { 
            return ({ value: item.value, label: item.label })
           }),
          defaultValue: { label: "sans facilité" },
          onChange: (e) => { conditions.paymentModality = e.value }
        },
      ]);

      const facility = JSON.parse(localStorage.getItem("facility"));
      Promise.all([
        api.get(`/get-responsable-by-type/${facility._id}?responsableType=b2b`),
        api.get(`/get-responsable-by-type/${facility._id}?responsableType=b2c`)
      ]).then((responses) => {      
        responses.forEach(({ data }) => {
          const newClients = data.map(({firstName, lastName, address, phoneNumber, email, responsableType}) => ({ firstName, lastName, address, phoneNumber, email, responsableType }));
          setClients(prevClients => [...prevClients, ...newClients]);
        });
      });

    }).catch((error) => {
      console.debug(error);
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  const recipientInputs = [
    { key: "name", label: "Nom et Prénom", onChange: (e, key) => { recipient[key] = e.target.value} },
    { key: "address", label: "Adresse", onChange: (e, key) => { recipient[key] = e.target.value } },
    // { key: "country", label: "Pays" }, the default will be tunisia but you can make it changable in the user
    { key: "phone", label: "Numéro de téléphone", onChange: (e, key) => { recipient[key] = e.target.value }},
    { key: "webSite", label: "Site web", onChange: (e, key) => { recipient[key] = e.target.value }},
    { key: "email", label: "Adresse email", onChange: (e, key) => { recipient[key] = e.target.value }},
    { key: "tvaNumber", label:"tva-number-change-me", onChange: (e, key) => { recipient[key] = e.target.value }},
    { key: "activityCode", label: "activity-code-change-me", onChange: (e, key) => { recipient[key] = e.target.value }},
    { key: "enterpriseNumber", label: "enterprise-number-change-me", onChange: (e, key) => { recipient[key] = e.target.value }},
  ];

  //------------------------------------------------------------------------------------------------------------------------------------------- on-submit-handler ...
  const submitHandler = () => {


    const purchases = [];
    for (const p of purshes) {
      if (p.quantity === 0 || p.unitPriceHT === 0) continue;
      const purchaseObject = p.toObject();
      if (showNoneTvaMsg) purchaseObject.tva = 0;
      purchases.push(purchaseObject);
    }

    if (recipient.name.length === 0 || recipient.email.length === 0) {
      // guard : no client is chosen
      alert('please pick a client');
      return;
    }

    if (purchases.length === 0) {
      // guard : empty purchases list
      alert('empty purchases list');
      return;
    }

    setShowBtnSpinner(true);
    const requestBody = {
      transmitter,
      recipient,
      introTxt: introTxt.value,
      currency,
      purshes: purchases,
      showNoneTvaMsg,
      rib,
      conditions: {
        ...conditions,
        lateInterest: lateInterest === 'À préciser' ? customLateInterest.value : lateInterest
      },
      paymentDetails,
      footerTxt: footerTxt.value,
      generalDiscount: generalDiscount.value,
      notes: notes.value,
    };

    api.post(`create-facture?type=${type}`, requestBody).then(({data}) => {
      const typeMapper = {
        'facture': 'factures',
        'insc': 'inscriptions',
        'pre-insc': 'preinscriptions'
      };
      const quotationLink = `/billing/${typeMapper[type]}/${data._id}`;
      setNavigator({ navigate: true, path: quotationLink });
    }).catch(error => {
      console.debug(error);
    }).finally(() => {
      setShowBtnSpinner(false);
    });
  };

  // toggle the application of tva state for all the articles
  const tvaApplicationHandler = (e) => {
    const value = e.target.checked;
    for (const purchase of purshes) {
      purchase.applyTva = !value;
    }
    setShowNoneTvaMsg(value); // this will update the UI
  };

  //---------------------------------------------------------------------------------------------------------- Functions: add, duplicate, update and remove purchase (ArticleRow)
  const addPurchase = () => {
    
    const defaultTva = {
      name: (configs?.general?.tva || 'Aucun TVA : (0%)'),
      value: Number(configs?.tva.find(tva => tva.name === configs?.general?.tva)?.value || 0),
    };

    purshes.push(new Purchase(configs.general?.articleType, 0, 0, defaultTva.value, 0, "", !showNoneTvaMsg, defaultTva.name));
    setPurchaseCount(prev => (prev + 1)); // just to update the UI
  };

  const duplicatePurchase = (index) => {
    const copyPurchase = purshes[index].deepCopy();
    purshes.splice(index, 0, copyPurchase);
    setPurchaseCount(prev => (prev + 1)); // just to update the UI
  };

  const changePurchaseInput = (index, key, newValue) => {
    purshes[index][key] = newValue;
  };

  const removePurchase = (index) => {
    purshes.splice(index, 1);
    setPurchaseCount(prev => (prev - 1)); // just to update the UI
  };



  // ----------------------------------------------------------------------------------------------------- Function: change the general discount handler
  const changeGeneralDiscountHandler = (newGeneralDiscountVal) => {
    generalDiscount.value = newGeneralDiscountVal;
  }

  if (navigator.navigate) {
    console.debug(navigator);
    return <Navigate to={navigator.path} />;
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- JSX
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        isLoading ? <LoadingAnimation /> :
        <Card sx={{ overflow: "visible", mt:"32px" }}>
          <SoftBox p={3} mb={1}>
            <SoftTypography variant="h3" color="info"> { documentTitle } </SoftTypography>
          </SoftBox>
          <SoftBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              {/* -------------------------------------------------------------------------------------------------------------------- destinataire */}
              <Grid item xs={12}>
                <SoftTypography variant="h4" fontWeight="medium"> Destinataire </SoftTypography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftSelect
                  options = {
                    clients.map(({firstName, lastName, address, phoneNumber, email, responsableType}) => ({
                      label: `${firstName} ${lastName}, ${responsableType}`,
                      value: { firstName, lastName, address, phoneNumber, email }
                    }))
                  }
                 
                  onChange={(e) => {
                    const client = e.value;
                    // { name: "-", address: "-", country: "Tunisia", phone: "-", webSite: "-", email: "-", tvaNumber: "-", activityCode: "-", enterpriseNumber: "-" }
                    recipient.name = `${client.firstName} ${client.lastName}`;
                    recipient.address = client.address;
                    recipient.phone = client.phoneNumber;
                    recipient.email = client.email;
                  }}
                />
              </Grid>
          

              {/* -------------------------------------------------------------------------------------------------------------------- Informations */}
              <Grid item xs={12}>{<SoftTypography variant="h4" fontWeight="medium"> Informations </SoftTypography>}</Grid>
              <Grid item xs={12} sm={4}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Devise
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    options={selectData.currency}
                    defaultValue={selectData.currency.filter(currency => currency.value === configs?.general?.devis)}
                    onChange={(e) => { setCurrency(e.value) }}
                  />
                </SoftBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <SoftBox
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width={{ xs: "100%", sm: "auto" }}
                mt={{ xs: 1, sm: 0 }}
                >
                  <SoftBox mr={1}>
                    <Switch defaultChecked={ configs?.general.tvaApplication } onChange={tvaApplicationHandler} />
                  </SoftBox>
                  <SoftBox lineHeight={0} mx={1}>
                    <SoftTypography variant="button" color="text" fontWeight="regular">
                      TVA non applicable
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </Grid>

              {/* -------------------------------------------------------------------------------------------------------------------- Règlement */}
              <Grid item xs={12}>{<SoftTypography variant="h4" fontWeight="medium"> Règlement </SoftTypography>}</Grid>
                {
                  regulationInputs.map(({ key, label, options, defaultValue, onChange }) => (
                    <Grid key={key} item xs={12} sm={3}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {label}
                          </SoftTypography>
                        </SoftBox>
                        <SoftSelect
                          options={options}
                          defaultValue={defaultValue}
                          onChange={onChange}
                        />
                      </SoftBox>
                    </Grid>
                  ))
                }
                <Grid item xs={12} sm={3}>
                  {
                    lateInterest === "À préciser" && (
                    <FormField
                      label={"Intérêts de retard personnalisé"}
                      onBlur = {(e) => { customLateInterest.value = e.target.value; }}
                    />
                  )
                  }
                </Grid>
                       
                {
                  (type=== "facture" || type === "insc") && 
                  <Grid key={Math.random()} item xs={12} sm={3}>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {"Compte bancaire (RIB)"}
                      </SoftTypography>
                    </SoftBox>
                    <SoftSelect
                      options={ configs?.bankAccounts?.map(({iban, bic, titulaire, label}) => ({value: {iban, bic, titulaire}, label: label})) }
                      onChange={(e) => {
                        const { iban, bic, titulaire } = e.value;
                        rib.iban = iban;
                        rib.bic = bic;
                        rib.titulaire = titulaire;
                      }}
                    />
                  </SoftBox>
                </Grid>}
              
              {/* -------------------------------------------------------------------------------------------------------------------- Articles */}
              <Grid item xs={12}>{<SoftTypography variant="h4" fontWeight="medium"> Articles </SoftTypography>}</Grid>
              {
                purshes.map(({type, quantity, unitPriceHT, tva, discount, description, totalHT, totalTTC, applyTva, tvaName}, index) => {
                  return (
                    <ArticleRow 
                      key={`article-row-${index}-${uuidv4()}`}
                      type={type}
                      articlesType={configs.articleTypes}
                      quantity = {quantity}
                      unitPriceHT={unitPriceHT}
                      tva = {tva}
                      discount = {discount}
                      description = {description}
                      totalHT = {totalHT}
                      totalTTC = {totalTTC}
                      index={index}
                      applyTva={applyTva}
                      tvaName={tvaName}
                      duplicatePurchase = {duplicatePurchase.bind(null, index)}
                      removePurchase = {removePurchase}
                      changePurchaseInput = {changePurchaseInput}
                      tvaList = {configs.tva}
                      typeBill={typeBill}
                    />
                  )
                })
              }

              <Grid item xs={12} sm={12}>
                <SoftBox
                display="flex"
                justifyContent="start"
                >
                  <SoftButton
                    variant="gradient"
                    color="dark"
                    size="medium"
                    onClick={addPurchase}
                  >
                    Ajouter une ligne
                  </SoftButton>
                </SoftBox>
              </Grid>

              <Grid item xs={12} sm={9} />
              <Totals currency={currency} applyTva={!showNoneTvaMsg} onChangeGeneralDiscount={changeGeneralDiscountHandler} />

              {/* ---------------------------------------------------------------------------------------------------------------------------- Textes affichés sur le document */}
              <Grid item xs={12}>{<SoftTypography variant="h4" fontWeight="medium"> Textes affichés sur le document </SoftTypography>}</Grid>
              {
                billPrefInputs.map((input) => (
                  <Grid key={input.key} item xs={12} sm={6}>
                    <FormField
                      label={input.label}
                      placeholder={input.label}
                      defaultValue={input.defaultValue}
                      onChange = {input.onChange}
                    />
                  </Grid>
                ))
              }

              <Grid item xs={12} sm={12}>
                <SoftBox
                display="flex"
                justifyContent="end"
                >
                  {
                    showBtnSpinner 
                    ? <CircularProgress />
                    : <SoftButton
                      variant="gradient"
                      color="customized"
                      size="medium"
                      onClick={submitHandler}
                      >
                        Créer la facture
                      </SoftButton>
                  }
                </SoftBox>
              </Grid>

            </Grid>
          </SoftBox>
        </Card>
      }
    </DashboardLayout>
  )
};



NewBill.propTypes = {
  type: PropTypes.string,
}


export default NewBill;





const bodyRequest = {
  transmitter: { // done
    name: "string",
    contact: "string",
    address: "string",
    country: "string",
    email: "string"
  },
  recipient: { // done 
    id: "64f9cd1b6ae6bcfcdbbe7168",
    name: "esprit",
    address: "arian / chotrana",
    country: "Tunisia",
    phone: "70 000 000",
    webSite: "esprit.tn",
    email: "fake.email.pstc@mail.com",
    tvaNumber: "19",
    activityCode: "esprit-naf",
    enterpriseNumber: "esprit-siren"
  },
  introTxt: "Factures Texte d'introduction par défaut PSTC", // done
  currency: " DT", // done
  purshes: [ // done 
    {
      type: "Formation",
      description: "Vitest - Javascript Unit Testing",
      unitPriceHT: 0.11772574601354169,
      quantity: 85,
      tva: 3.222246054509763,
      discount: 6.743982282648016,
      totalHT: 9.331839117623224,
      totalTTC: 9.632533935404037
    },
    {
      type: "Formation",
      description: "Jest - Javascript Unit Testing",
      unitPriceHT: 48.63733775048766,
      quantity: 35,
      tva: 13.100169897116132,
      discount:  1.6709742987129905,
      totalHT: 1673.8617117984572,
      totalTTC: 1893.1404398868315
     }
  ],
  showNoneTvaMsg: true, // done 
  rib: { // done
    iban: "iban-value",
    bic: "bic-value",
    titulaire: "titulaire-pstc"
  },
  conditions: { // done
    paymentTerm: "30 jours fin de mois",
    paymentMethod: "Virement bancaire",
    lateInterest: "2% par mois",
    closingTxt: "Factures Texte de conclusion par défaut PSTC"
  },
  paymentDetails: [{
    "amount": 10,
    "date": "2023/08/08",
    "transactionNumber": "numéro-virement-bancaire",
    "type": "espèces",
    "percentage": 18.25,
    "startDate": "2020/07/22",
    "endDate": "2021/07/22"
  },
  {
    "amount": 12,
    "date": "2023/08/08",
    "transactionNumber": "numéro-virement-bancaire",
    "attachedFile": "file-url",
    "type": "chèque-garantie",
    "chequeNumber": "N°00132",
    "percentage": 18.25,
    "startDate": "2020/07/22",
    "endDate": "2021/07/22"
  },
  {
    "amount": 14,
    "date": "2023/08/08",
    "transactionNumber": "numéro-virement-bancaire",
    "attachedFile": "file-url",
    "type": "chèque-versable",
    "chequeNumber": "N°00132",
    "percentage": 18.25,
    "startDate": "2020/07/22",
    "endDate": "2021/07/22"
  },
  {
    "amount": 17,
    "date": "2023/08/08",
    "transactionNumber": "numéro-virement-bancaire",
    "attachedFile": "file-url",
    "type": "virement bancaire",
    "percentage": 18.25,
    "startDate": "2020/07/22",
    "endDate": "2021/07/22"
  }],
  "footerTxt": "Factures Pied de page par défaut PSTC",
  "generalDiscount": 0,
  "notes": "some important notes about this invoice... "
}
