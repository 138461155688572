import { useState, useRef } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftButton from "components/SoftButton";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

// Settings page components
import FormField from "pages/billing-settings/components/FormField";

// Data
import selectData from "pages/billing-settings/components/GeneralSettings/data/selectData.js";


import api from "utils/axios.js";

function GeneralSettings({ configs, setConfigs }) {
  
  // you can change the condition to: configs.general.lateInterest?.value === -1 it's more optimal
  const [showCustomelateInterest, setShowCustomeLateInterest] = useState(configs.general?.lateInterest?.description === "À préciser");
  const [inputsValidity, setInputsValidity] = useState({isTvaValid: true, noneTvaFRTextValidity: true, noneTvaENTextValidity: true, customLateInterestsValidity: true});
  const { current: updates } = useRef({});
  const [isLoad, setIsLoad] = useState(false);
  const [alert, setAlert] = useState({show: false, message: "", type:""});


  const clearAlert = (ms) => {
    setTimeout(() => {
      setAlert({ show: false, message: "", type: ""});
    }, ms);
  };

  const handleSubmitionButton = (e) => {
    setIsLoad(true);
    api.patch(`/update-invoices-config/${configs._id}`, updates).then((response) => {
      console.log(response);
      setConfigs(prevState => {
        const newState = { ...prevState };
        for (const [key, value] of Object.entries(updates)) {
          const [outerKey, innerKey] = key.split(".");
          newState[outerKey][innerKey] = value;
        }
        return newState;
      });

      setAlert({ show: true, message: "la configuration des factures a été mise à jour avec succès", type: "success"});
    }).catch((error) => {
      console.debug(error);
      setAlert({ show: true, message: error.message, type: "error"});
    }).finally(() => {
      setIsLoad(false);
      clearAlert(10_000);
    });
  };

  function getDefaultTvaLabel () {
    if (configs.general.tva === null) return `Aucun TVA : (0%)`;
    for(const { name, value } of configs.tva) {
      if (name === configs.general.tva) {
        return `${name} : (${value}%)`;
      }
    }
  }
  
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">Paramètre général</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>

          {/* ---------------------------------------- country + currency ------------------------------------------------------ */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {/* --------------------------------- country */}
              <Grid item xs={12} sm={6}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Pays
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    options={selectData.countries}
                    defaultValue={selectData.countries.filter(country => country.label === configs.general?.country)}
                    onChange={(e) => updates['general.country'] = e.label }
                  />
                </SoftBox>
              </Grid>
              {/* --------------------------------- currency */}
              <Grid item xs={12} sm={6}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Devise par défaut
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    options={selectData.currency}
                    defaultValue={selectData.currency.filter(currency => currency.value === configs.general?.devis)}
                    onChange={(e) => { updates["general.devis"] = e.value; }}
                  />
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
          
          {/* ---------------------------------------- default article type + default tva ------------------------------------------------------ */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {/* --------------------------------- default article type */}
              <Grid item xs={12} sm={6}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Type d&apos;article par défaut *
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    options={configs.articleTypes?.map(article => ({ value: article, label: article }))}
                    defaultValue={{ label : configs.general.articleType }}
                    onChange={(e) => { updates["general.articleType"] = e.value }}
                  />
                </SoftBox>
              </Grid>
              {/* --------------------------------- default tva */}
              <Grid item xs={12} sm={6}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      {"TVA (%) par défaut (Choisissez un parmi les tva déclarée dans la list.)"}
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    options={[
                      { value: null, label: 'Aucun TVA : (0%)' },
                      configs && configs?.tva ? [...configs.tva.map(({ name, value}) => ({ value: name, label: `${name} : (${value}%)`}))] : []
                    ]}
                    defaultValue={{ label : configs && configs?.tva && getDefaultTvaLabel() }}
                    onChange={(e) => {
                      updates["general.tva"] = (e.value);
                    }}
                  />
                </SoftBox>

              </Grid>
            </Grid>
          </Grid>
          {/* ---------------------------------------- tva non applicable par défaut ? ------------------------------------------------------ */}
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <SoftBox
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width={{ xs: "100%", sm: "auto" }}
                mt={{ xs: 1, sm: 0 }}
                >
                  <SoftBox mr={1}>
                    <Switch defaultChecked={configs.general.tvaApplication} onChange={(e) => { updates["general.tvaApplication"] = (e.target.checked)} } />
                  </SoftBox>
                  <SoftBox lineHeight={0} mx={1}>
                    <SoftTypography variant="button" color="text" fontWeight="regular">
                      TVA non applicable par défaut
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
          {/* ---------------------------------------- text input field (noneTvaFRText) + text input field (noneTvaENText) ------------------------------------------------------ */}
          <Grid item xs={12} sm={6}>
            <FormField
              label="Texte affiché lorsque la TVA n'est pas applicable en Français *"
              placeholder="tva non appliquée text..."
              defaultValue={configs.general.noneTvaFRText}
              onChange = {
                (e) => {
                  updates["general.noneTvaFRText"] = (e.target.value);
                  setInputsValidity((prevState) => ({...prevState, noneTvaFRTextValidity: e.target.value.length > 6}))
                }
              }
              error={!inputsValidity.noneTvaFRTextValidity}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Text affiché lorsque la TVA n'est pas applicable en Anglais *"
              placeholder="no tva applyed text..."
              defaultValue={configs.general.noneTvaENText}
              onChange = {
                (e) => {
                  updates["general.noneTvaENText"] = (e.target.value);
                  setInputsValidity((prevState) => ({...prevState, noneTvaENTextValidity: e.target.value.length > 6}))
                }
              }
              error={!inputsValidity.noneTvaENTextValidity}

            />
          </Grid>

          {/* ---------------------------------------- select box (paymentTerms) + select box (paymentMethods) ------------------------------------------------------ */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {/* --------------------------------- paymentTerms */}
              <Grid item xs={12} sm={6}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Conditions de règlement par défaut
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    options={configs.paymentTerms?.map((term) => ({ value: term, label: term }))}
                    defaultValue={{ label : configs.general.paymentTerm }}
                    onChange={(e) => {updates["general.paymentTerm"] = e.value}}
                  />
                </SoftBox>
              </Grid>
              {/* --------------------------------- paymentMethods */}
              <Grid item xs={12} sm={6}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Mode de règlement par défaut
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    options={configs.paymentMethods?.map((method) => ({ value: method, label: method }))}
                    defaultValue={{ label: configs.general.paymentMethod }}
                    onChange={(e) => {updates["general.paymentMethod"] = e.value}}
                  />
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
          
          {/*--------------------------------- select box (lateInterests) - text input field (conditional rendering if lateInterests select box point to a customized choise) ---------------------------------*/}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {/* --------------------------------- lateInterests */}
              <Grid item xs={12} sm={6}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Intérests de retard par défaut
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    options={[...configs.lateInterests?.map(({description, value}) => ({ value: value, label: description })), { value: -1, label: "À préciser" }]}
                    defaultValue={{ label : configs.general.lateInterest?.description }}
                    onChange={(e) => {
                      if (e.label === "À préciser") {
                        updates["general.lateInterest"] = { description: "", value: -1 };
                        setShowCustomeLateInterest(true);
                      } else {
                        updates["general.lateInterest"] = { description: e.label, value: e.value };
                        setShowCustomeLateInterest(false);
                      }
                    }}
                  />
                </SoftBox>
              </Grid>
              {/* --------------------------------- text input field (conditional rendering) */}
              {
                showCustomelateInterest && 
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Intérêts de retard par défaut personnalisé"
                    placeholder="Intérêts personnalisé"
                    onChange = {
                      (e) => {
                        updates["general.lateInterest"] = { description: e.target.value, value: -1 };
                        setInputsValidity((prevState) => ({...prevState, customLateInterestsValidity: e.target.value.length > 0 }))
                      }
                    }
                    error = {!inputsValidity.customLateInterestsValidity}
                  />
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            { alert.show && <Alert severity={alert.type}> {alert.message} </Alert> }
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox
              display="flex"
              justifyContent="end"
            >
              
              {
                isLoad 
                ? <CircularProgress/>
                : <SoftButton
                  variant="gradient"
                  color="customized"
                  size="medium"
                  onClick={handleSubmitionButton}
                  disabled={!Object.values(inputsValidity).every((validity) => validity) || updates["general.lateInterest"]?.description.length === 0}
                >
                  Enregistrer
                </SoftButton>
              }
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

GeneralSettings.defaultProps = {
  configs: {
    general: {},
    articleTypes: [],
    paymentMethods: [],
    paymentTerms: [],
    lateInterests: [{description: '', value: 0}]
  },
  setConfigs: new Function(),
};

// Typechecking props for BaseLayout
GeneralSettings.propTypes = {
  configs: PropTypes.object,
  setConfigs: PropTypes.func,
};

export default GeneralSettings;
