/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "pages/resource-management/classe/schemas/form";

const {
  formField: {name,groupNumber,abilityMaximum},
} = checkout;

const validations = [
  Yup.object().shape({

    [name.name]: Yup.string()
    .required(name.errorMsg)
      .min(2, "La longueur minimale requise est de 2 caractères.")
      .max(30, "La longueur maximale autorisée est de 30 caractères."),
      
    [abilityMaximum.name]: Yup.string()
      .required(abilityMaximum.errorMsg)
      .matches(/^[0-9]+$/gi, "La valeur doit etre un nombre"),

      [groupNumber.name]: Yup.number()
      .typeError("La valeur doit être un nombre")
      .integer("La valeur doit être un nombre entier")
      .min(1, "La valeur doit être supérieure ou égale à 1")
      .max(10, "La valeur doit être inférieure ou égale à 10"),
  }),
 
];

export default validations;
