/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "class",
  formField: {
    name: {
      name: "name",
      label: "Nom de classe*",
      type: "text",
      placeholder: "Nom de classe",
      errorMsg: "Nom de classe est obligatoire.",
    },
    

    level: {
      name: "level",
      label: "Nom du niveau*",
      type: "text",
      placeholder: "Nom du niveau",
      errorMsg: "Nom du niveau est obligatoire .",
    },
    filiere: {
      name: "filiere",
      label: "Nom du filiere*",
      type: "text",
      placeholder: "Nom du filiere",
      errorMsg: "Nom du filiere est obligatoire .",
    },
    parcour: {
      name: "parcour",
      label: "Nom du parcour*",
      type: "text",
      placeholder: "Nom du parcour",
      errorMsg: "Nom du parcour est obligatoire .",
    },
    abilityMaximum: {
      name: "abilityMaximum",
      label: "maximum de capacité*",
      type: "text",
      placeholder: "maximum de capacité",
      errorMsg: "maximum de capacité est obligatoire.",
    },
    groupNumber: {
      name: "groupNumber",
      label: "nombre des groupes",
      type: "text",
      placeholder: "nombre des groupes",
      errorMsg: "nombre des groupes est obligatoire.",
    },
    periode: {
      name: "periode",
      label: "periode",
      type: "text",
      placeholder: "periode",
      errorMsg: "période est obligatoire.",
    },
  },
};

export default form;
