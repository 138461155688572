import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw, RichUtils } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PropTypes from "prop-types";

const TextEditor = ({ value, onChange, readOnly }) => {
  const [editorState, setEditorState] = useState(() =>
    value
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
      : EditorState.createEmpty()
  );

  useEffect(() => {
    // Update editorState when value prop changes
    if (value) {
      const contentState = convertFromRaw(JSON.parse(value));
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [value]);

  const handleEditorStateChange = (newEditorState) => {
    // Update editor state
    setEditorState(newEditorState);

    // Extract raw content from the new editor state
    const rawContentState = convertToRaw(newEditorState.getCurrentContent());

    // Convert raw content to a string and pass it to the onChange prop
    const rawContentStateString = JSON.stringify(rawContentState);
    onChange(rawContentStateString);

    // Check if the current selection has focus
    const selectionState = newEditorState.getSelection();
    if (!selectionState.getHasFocus()) {
      // If the selection does not have focus, move the cursor to the end of the content
      const contentState = newEditorState.getCurrentContent();
      const lastBlock = contentState.getLastBlock();
      const endSelection = selectionState.merge({
        anchorKey: lastBlock.getKey(),
        anchorOffset: lastBlock.getLength(),
        focusKey: lastBlock.getKey(),
        focusOffset: lastBlock.getLength(),
      });
      const newSelectionState = endSelection.set("hasFocus", true);
      const newEditorStateWithFocus = EditorState.acceptSelection(
        newEditorState,
        newSelectionState
      );
      setEditorState(newEditorStateWithFocus);
    }
  };

  // Handle inline style toggling (e.g., bold, italic)
  const toggleInlineStyle = (style) => {
    handleEditorStateChange(RichUtils.toggleInlineStyle(editorState, style));
  };

  // Handle block type toggling (e.g., headings, blockquote)
  const toggleBlockType = (blockType) => {
    handleEditorStateChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const handleKeyDown = (e) => {
    // Allow Tab key to propagate if readOnly is false
    if (!readOnly && e.key === "Tab") {
      return;
    }
  };

  return (
    <div
      style={{
        border: "1px solid #ccc",
        padding: "10px",
        backgroundColor: readOnly ? "#e8ecee" : null,
      }}
    >
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        readOnly={readOnly} // Set readOnly prop
        onKeyDown={handleKeyDown}
        toolbar={{
          options: ["inline", "blockType", "list", "textAlign"],
          inline: {
            options: ["bold", "italic", "underline", "strikethrough", "monospace"],
            toggleInlineStyle: toggleInlineStyle,
          },
          blockType: {
            inDropdown: true,
            options: ["Normal", "Blockquote", "Code"],
            toggleBlockType: toggleBlockType,
          },
          list: {
            options: ["unordered", "ordered"],
          },
          textAlign: {
            options: ["left", "center", "right"],
          },
        }}
      />
    </div>
  );
};

TextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default TextEditor;
