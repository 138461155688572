import checkout from "components/shared-users/schemas/form";

const {
  formField: {
    firstName,
    lastName,
    email,
    address,
    zipCode,
    siteWeb,
    passeport,
    cin,
    rib,
    bank,
    description,
    phone,
    gender,
    cnssNumber,
    occupation,
    choix,
    responsable
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [email.name]: "",
  [address.name]: "",
  [zipCode.name]: "",
  [siteWeb.name]: "",
  [passeport.name]: "",
  [cin.name]: "",
  [rib.name]: "",
  [bank.name]: "",
  [description.name]: "",
  [phone.name]: "",
  [gender.name]: "",
  [cnssNumber.name]: "",
  [occupation.name]: "",
  [choix.name]: "",
  [responsable.name]: "",
};

export default initialValues;
