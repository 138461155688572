// page path => /billing/factures/:invoiceId || /billing/inscriptions/:invoiceId || /billing/preinscriptions/:invoiceId

import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Alert from '@mui/material/Alert';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

// Invoice page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import LoadingAnimation from "components/LoadingAnimation";


import api from "utils/axios";
import fileDownload from "js-file-download";


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import moment from "moment";
import 'moment/locale/fr';
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";


const InvoiceDetails = ({ type }) => {
  const [invoice, setInvoice] = useState({});
  const [invoiceType, setInvoiceType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({ show: false, message: "" });
  const [downloadError, setDownloadError] = useState(false);
  const [isPreInsc, setIsPreInsc] = useState(false);
  const { invoiceId } = useParams();
  const navigate = useNavigate();

  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;


  useEffect(() => {
    
    const endpoint = `/get-${type === 'quotations' ? 'devis' : 'facture'}-by-id/${invoiceId}?type=${type}`;

    api.get(endpoint).then((res) => {
      const { data } = res;
      if (data?.numeration && data.numeration.charAt(0) === "F") setInvoiceType("Facture");
      else if (data?.numeration && data.numeration.charAt(0) === "D") setInvoiceType("Devis");
      else if (data?.numeration && data.numeration.charAt(0) === "I" && data.type === "insc") setInvoiceType("Inscription");
      else if (data?.numeration && data.numeration.charAt(0) === "I" && data.type === "pre-insc") {
        setIsPreInsc(true);
        setInvoiceType("Pré Inscription");
      }
      setInvoice(data);
      console.log(data, ": invoice");
    }).catch(error => {
      console.debug(error.code);
      if (error.code === "ERR_BAD_REQUEST") setError({ show: true, type: "Error 404", message: `aucune facture fondée avec l'identifiant donné (${invoiceId})` });
      else if (error.code === "ERR_NETWORK") setError({ show: true, type: "Error 500", message: `erreur côté serveur` });
      else setError({ show: true, type: "Error", message: `erreur inconnue` });
    }).finally(() => {
      setIsLoading(false);
    });

  }, [invoiceId]);

  const columnsTitle = ["Type", "Description",  "Prix unitaire HT", "Quantité",	"TVA", "Réduction",	"Total HT"];
  const recipientInfo = [
    { label: "Nom", key: "name" },
    { label: "Matricule Fiscal", key: "matriculeFiscal" },
    { label: "Adresse", key: "address" },
    { label: "Pays", key: "country" },
    { label: "Numéro d'entreprise", key: "enterpriseNumber" },
    { label: "Code d'activité", key: "activityCode" },
    { label: "Numéro de TVA", key: "tvaNumber" },
    { label: "Numéro de téléphone", key: "phone" },
    { label: "Adresse email", key: "email" }
  ];

  const transmiterInfo = [
    { label: "Société", key: "name" },
    { label: "Matricule Fiscal", key: "matriculeFiscal" },
    { label: "Votre contact", key: "contact" },
    { label: "Adresse", key: "address" },
    { label: "Pays", key: "country" },
    { label: "Adresse email", key: "email" }
  ];

  const conditionsInfo = [
    { label: "Conditions de règlement", key:"paymentTerm", endSentence: "" },
    { label: "Intérets de retard", key:"lateInterest", endSentence: "" },
    { label: "Mode de règlement", key:"paymentMethod", endSentence: "" },
    // { label: "Condition Générale", key: "generalCondition", endSentence: "" },
    // { label: "Durée de validité", key: "devisValidity", endSentence: "Jours" },
  ];

  const tranchesInfo =[
    { label: "Montant", key:"amount"},
    { label: "Date", key:"date"},
    { label: "Numéro de virement", key:"transactionNumber"},
    { label: "Type", key:"type"},
    { label: "Status", key:"status"},
    { label: "Pourcentage", key:"percentage"},
    { label: "Date début", key:"startDate"},
    { label: "Date fin", key:"endDate"}
  ]

  const { d1, d3, d4, d5 } = typography;

  const downloadHandler = () => {
    const endpoint = `get-${type === 'quotations' ? 'devis' : 'facture'}-by-id/${invoiceId}?resType=pdf&type=${type}`;
    api.get(endpoint,{ responseType: "blob" }).then(res => {
      const { data, headers } = res;
      const extension = headers['content-type'] && headers['content-type'].split('/')[1];
      fileDownload(data, `${invoice.numeration}.${extension}`);
    }).catch(error => {
      console.debug({error});
      setDownloadError(true);
      setTimeout( _ => setDownloadError(false), 5000);
    });
  };

  const preInscToInsc = () => {
    api.post(`pre-inscription-to-inscription/${invoiceId}`).then(res => {
      navigate('/billing/preinscriptions');
    }).catch(error => {
      console.debug(error);
    });
  };


  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      {
        (function() {
          if (isLoading) {
            return <LoadingAnimation />;
          } else {
            return (
              <SoftBox mt={{ xs: 4, md: 10 }} mb={{ xs: 4, md: 8 }}>
                <Grid container justifyContent="center">
                  <Grid item xs={12} sm={10} md={8}>
                    <Card>
                      {
                        error.show && (
                          <Grid m={5} item xs={12} lg={6}>
                            <SoftBox
                              fontSize={{ xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize }}
                              lineHeight={1.2}
                            >
                              <SoftTypography variant="inherit" color="error" textGradient fontWeight="bold">
                                { error.type }
                              </SoftTypography>
                            </SoftBox>
                            <SoftTypography id="error-message" variant="h2" color="dark" textGradient fontWeight="bold">
                              { error.message }
                            </SoftTypography>
                            <SoftBox mt={1} mb={2}>
                              <SoftTypography variant="body1" color="text">
                                {"Nous vous suggérons d'aller sur la page d'accueil pendant que nous résolvons ce problème."}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox mt={4} mb={2}>
                              <SoftButton id="return-btn" component={Link} to="./.." variant="gradient" color="dark">
                                aller au list des facture
                              </SoftButton>
                            </SoftBox>
                          </Grid>
                        ) 
                      }
                      {
                        !error.show && (
                          <>
                            {/* Invoice header */}
                            <Box sx={{display:"flex" , alignItems:'center'}}>
                              <Box sx={{flexGrow :"1"}}>
                                <SoftTypography variant="h6" sx={{ fontWeight:"400", fontSize:"24px", p:"16px 0px 0px 16px" }}>
                                  {invoiceType} {invoice.numeration}
                                </SoftTypography>
                                <SoftTypography variant="h6" sx={{ fontWeight:"400", fontSize:"16px", pl:"16px" , color:"grey" }}>
                                  {moment(invoice.createdAt).local("fr").format("D MMMM YYYY")}
                                </SoftTypography>
                              </Box>
                              <Box sx={{ p :"16px" }}>
                                <img style={{ width :"90px", height : "90px"}} src={`${process.env.REACT_APP_BASE_URL}/default-enterprise-icon.jpg`} alt="Logo Entreprise" />
                              </Box>
                            </Box>

                            <Grid container sx={{p: "0px 16px"}}>
                              <Grid item xs={12} md={6}>
                                <SoftTypography variant="h6" sx={{ fontSize :"16px" , fontWeight :"500" }}>
                                  Émetteur
                                </SoftTypography>
                                {
                                  transmiterInfo.map(({label, key}, index) => (
                                    <Box id={key} variant="h6" fontWeight="medium" key={index} sx={{ display:"flex", alignItems:"center" }}>
                                      <SoftTypography variant="body2" sx={{ fontSize:"14px", color:"grey" }}>
                                        {label} : 
                                      </SoftTypography>
                                      <SoftTypography variant="body2" sx={{ fontSize:"14px", color:"black" }}>
                                        {invoice.transmitter && invoice.transmitter[key]}
                                      </SoftTypography>
                                    </Box>
                                  ))
                                }
                              </Grid>
                              <Grid item xs={12} md={6} ml={{ sx:"0px" }}>
                                <SoftTypography variant="h6" sx={{fontSize :"16px" , fontWeight :"500" }}>
                                  Destinataire
                                </SoftTypography>
                                {
                                  recipientInfo.map(({label, key}, index) => (
                                    <Box id={key} variant="h6" fontWeight="medium" key={index} sx={{ display:"flex", alignItems:"center" }}>
                                      <SoftTypography variant="body2" sx={{ fontSize:"14px", color:"grey" }}>
                                        {label} : 
                                      </SoftTypography>
                                      <SoftTypography variant="body2" sx={{ fontSize:"14px", color:"black" }}>
                                        {invoice.recipient && invoice.recipient[key]}
                                      </SoftTypography>
                                    </Box>
                                  ))
                                }
                              </Grid>
                            </Grid>
                            {invoice.introTxt !== "" &&
                              <Grid container sx={{ pl : "16px" }}>
                                <Grid item xs={12} sx={{ display: "flex", alignItems:"center",  my:1 }}>
                                  <SoftTypography variant="body2" sx={{ fontSize:"14px", color:"black" }}>
                                    {invoice.introTxt}
                                  </SoftTypography>
                                </Grid>
                              </Grid>
                            }
                            <SoftTypography variant="h6" sx={{pl:"16px", fontSize :"16px" , fontWeight :"500" }}>Détail</SoftTypography>
                            <SoftBox p="0px 16px">
                              <SoftBox width="100%" overflow="auto">
                                <Table sx={{ minWidth: "32rem" }}>
                                  {/*--------------------------------------------------------------------------------------- table header (first row) */}
                                  <SoftBox component="thead">
                                    <TableRow id="table-columns" sx={{bgcolor :grey[200]}}>
                                      {
                                        columnsTitle.map(
                                          (colName, index) => (
                                            <SoftBox
                                              id={`column-${index}`}
                                              key={index}
                                              component="th"
                                              p={1}
                                              textAlign="left"
                                              borderBottom={borderBottom}
                                              sx={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                              }}
                                            >
                                              <SoftTypography variant="h6" color="text" fontWeight="medium">
                                                {colName}
                                              </SoftTypography>
                                            </SoftBox>
                                          )
                                        )
                                      }
                                    </TableRow>
                                  </SoftBox>
                                  
                                  {/*--------------------------------------------------------------------------------------- table body*/}
                                  <TableBody id="table-rows">
                                    {
                                      invoice.purshes?.map((purshe, index) => (
                                        <TableRow key={index}>
                                          <SoftBox id={`type-${index+1}`} component="td" p={1} textAlign="left" borderBottom={borderBottom}>
                                            <SoftTypography variant="body2" color="text">
                                              {purshe.type}
                                            </SoftTypography>
                                          </SoftBox>
                                          <SoftBox id={`description-${index+1}`} component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                            <SoftTypography variant="body2" color="text">
                                              {purshe.description}
                                            </SoftTypography>
                                          </SoftBox>
                                          <SoftBox id={`unit-price-ht-${index+1}`} component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                            <SoftTypography variant="body2" color="text">
                                              {purshe.unitPriceHT}
                                            </SoftTypography>
                                          </SoftBox>
                                          <SoftBox id={`quantity-${index+1}`} component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                            <SoftTypography variant="body2" color="text">
                                              {purshe.quantity}
                                            </SoftTypography>
                                          </SoftBox>
                                          <SoftBox id={`tva-${index+1}`} component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                            <SoftTypography variant="body2" color="text">
                                              {purshe.tva}
                                            </SoftTypography>
                                          </SoftBox>
                                          <SoftBox id={`discount-${index+1}`} component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                            <SoftTypography variant="body2" color="text">
                                              {(purshe.discount || "0") + "%"}
                                            </SoftTypography>
                                          </SoftBox>
                                          <SoftBox id={`total-ht-${index+1}`} component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                            <SoftTypography variant="body2" color="text">
                                              {purshe.totalHT}
                                            </SoftTypography>
                                          </SoftBox>
                                        </TableRow>
                                      ))
                                    }
                                    {/* --------------------------------------Totals-------------------------------------- */}
                                    {
                                      invoice.total?.map((total, index) => (
                                        <TableRow key={index}>
                                          <SoftBox component="td" textAlign="left" p={1} />
                                          <SoftBox component="td" textAlign="left" p={1} />
                                          <SoftBox component="td" textAlign="left" p={1} />
                                          <SoftBox component="td" textAlign="left" p={1} />
                                          <SoftBox component="td" textAlign="left" p={1} />
                                          <SoftBox component="td" textAlign="left" p={1}>
                                            <SoftTypography id={total.title} variant="h6">{total.title}</SoftTypography>
                                          </SoftBox>
                                          <SoftBox component="td" textAlign="left" p={1}>
                                            <SoftTypography id={total.value} variant="h6">{total.value}</SoftTypography>
                                          </SoftBox>
                                        </TableRow>
                                      ))
                                    }
                                  </TableBody>
                                </Table>
                              </SoftBox>
                            </SoftBox>

                            <Grid container sx={{p:"16px"}}>
                              <Grid item xs={12} md={7} lg={5}>
                                <SoftBox>
                                  <SoftTypography variant="h6" sx={{fontSize :"16px" , fontWeight :"500" }}>
                                    Conditions
                                  </SoftTypography>
                                  {
                                    conditionsInfo.map(({label, key, endSentence}, index) => (
                                      invoice.conditions[key] &&
                                        <Box id={key} variant="h6" fontWeight="medium" key={index} sx={{ display:"flex", alignItems:"center" }}>
                                          <SoftTypography variant="body2" sx={{ fontSize:"14px", color:"grey" }}>
                                            {label} : 
                                          </SoftTypography>
                                          <SoftTypography variant="body2" sx={{ fontSize:"14px", color:"black" }}>
                                            {invoice.conditions && invoice.conditions[key]} {endSentence}
                                          </SoftTypography>
                                        </Box>
                                    ))
                                  }
                                  {
                                    invoice.paymentDetails && invoice.paymentDetails.length > 0 &&
                                    invoice.paymentDetails.map((paymentDetail, paymentIndex) => (
                                      <Box key={paymentIndex}>
                                        <SoftTypography variant="body2" sx={{ fontSize:"14px", color:"grey" }}>tranche n°{paymentIndex + 1} -------------------------------------------------</SoftTypography>
                                        {
                                          tranchesInfo.map(({ label, key }, trancheIndex) => (
                                            <Box id={key} variant="h6" fontWeight="medium" key={trancheIndex} sx={{ display: "flex", alignItems: "center" }}>
                                              <SoftTypography variant="body2" sx={{ fontSize: "14px", color: "grey" }}>
                                                {label} :
                                              </SoftTypography>
                                              <SoftTypography variant="body2" sx={{ fontSize: "14px", color: "black", marginLeft: "8px" }}>
                                                {key === 'date' || key === 'startDate' || key === 'endDate' ? moment(paymentDetail[key]).format('DD/MM/YYYY HH:mm:ss') : key==="amount" ? paymentDetail[key] + " " + invoice.currency: paymentDetail[key]}
                                              </SoftTypography>
                                            </Box>
                                          ))
                                        }
                                      </Box>
                                    ))
                                  }
                                </SoftBox>
                              </Grid>
                              {invoice.notes !== "" &&
                                <Grid item xs={12} sx={{ display: "flex", alignItems:"center", mb:3 }}>
                                <SoftTypography variant="body2" sx={{ fontSize:"14px", color:"grey", pr: "8px" }}>
                                  NOTES :
                                </SoftTypography>
                                {
                                  invoice.notes && (
                                    <SoftTypography variant="body2" sx={{ fontSize:"14px", color:"black" }}>
                                      {invoice.notes}
                                    </SoftTypography>
                                  )
                                }
                              </Grid>}
                            </Grid>

                            <Grid container pl={2}>
                              {invoice.conditions && invoice.conditions?.closingTxt !== "" &&
                                <Grid item xs={12} sx={{ display:"flex", alignItems:"center" }}>
                                  <SoftTypography  variant="body2" sx={{ fontSize:"14px", color:"black" }}>
                                    { invoice.conditions?.closingTxt }
                                  </SoftTypography>
                                </Grid>
                              }
                              {invoice.footerTxt !== "" && 
                                <Grid item xs={12} sx={{ display:"flex", alignItems:"center" }}>
                                  <SoftTypography variant="body2" sx={{ fontSize:"14px", color:"black" }}>
                                    {invoice.footerTxt}
                                  </SoftTypography>
                                </Grid>}
                            </Grid>

                            <Box sx={{ display: "flex", alignItems:"center", px:"16px", mt:"32px" }}>
                              <SoftTypography sx={{ flexGrow :"1", fontSize:"14px", fontWeight:"400" }}>
                              {invoiceType} {invoice.numeration}
                              </SoftTypography>
                              <SoftTypography sx={{ fontSize:"14px", fontWeight:"400"  }}>
                                Page 1 sur 1
                              </SoftTypography>
                            </Box>

                            <Grid container sx={{ display:"flex", alignItems:"center", py:"32px", px:{xs : '32px', md :"16px", lg:"32px"}}}>

                              
                              {
                                isPreInsc &&
                                <Grid item xs={12} md={7} lg={9.4}>
                                  <SoftBox
                                    width="100%"
                                    height={{ xs: "auto", md: "100%" }}
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="flex-end"
                                    mt={{ xs: 2, md: 0 }}
                                  >
                                    <SoftButton
                                      id = "download-btn"
                                      variant="gradient"
                                      color="info"
                                      onClick={preInscToInsc}
                                    >
                                      Convertier à une inscription
                                    </SoftButton>
                                  </SoftBox>
                                </Grid>
                              }
                              <Grid item xs={12}  md={isPreInsc ? 5 : 12} lg={isPreInsc ? 2.6 : 12}>
                                <SoftBox
                                  width="100%"
                                  display="flex"
                                  mt={{ xs :"16px", md:"0px" }}
                                  justifyContent="flex-end"
                                  alignItems="flex-end"
                                >
                                  <SoftButton
                                    id = "download-btn"
                                    variant="gradient"
                                    size="medium"
                                    color="info"
                                    onClick={downloadHandler}
                                  >
                                    Télécharge PDF
                                  </SoftButton>
                                </SoftBox>
                              </Grid>
                            </Grid>

                            {/* <SoftBox p={3}>
                              <Grid container justifyContent="space-between">
                                <Grid item xs={12} md={4}>
                                  <SoftTypography variant="h4" fontWeight="medium"> Destinataire </SoftTypography>
                                  {
                                    recipientInfo.map(({label, key}, index) => (
                                      <SoftTypography id={key} variant="h6" fontWeight="medium" key={index}>
                                        { `${label} : ${invoice.recipient && invoice.recipient[key]}` }
                                      </SoftTypography>
                                    ))
                                  }
                                </Grid>
                                <Grid item xs={12} md={7} lg={3}>
                                  <SoftBox width="100%" textAlign={{ xs: "left", md: "right" }}>
                                    <SoftTypography variant="h4" fontWeight="medium">
                                      Conditions
                                    </SoftTypography>
                                    <SoftBox mb={1}>
                                      {
                                        conditionsInfo.map(({label, key, endSentence}, index) => (
                                          invoice.conditions[key] && 
                                          <SoftTypography id={key} key={index} variant="body2">
                                            {`${label} : ${invoice.conditions && invoice.conditions[key]} ${endSentence}`}
                                          </SoftTypography>
                                        ))
                                      }
                                    </SoftBox>
                                  </SoftBox>
                                </Grid>
                              </Grid>
                              <SoftBox mt={{ xs: 5, md: 10 }}>
                                <Grid container justifyContent="space-between">
                                  <Grid item xs={12} md={4}>
                                    <SoftTypography id="invoice-title" variant="h5" fontWeight="medium">
                                      {`${invoiceType} : ${invoice.numeration}`}
                                    </SoftTypography>
                                    {
                                      invoice.notes && (
                                        <SoftTypography id="invoice-intro-text" variant="h6" fontWeight="medium" my={3}>
                                          {invoice.notes}
                                        </SoftTypography>
                                      )
                                    }
                                    <SoftTypography id="invoice-intro-text" variant="h6" fontWeight="medium" my={3}>
                                      {invoice.introTxt}
                                    </SoftTypography>
                                  </Grid>
                                  <Grid item xs={12} md={7} lg={5}>
                                    <SoftBox
                                      width="100%"
                                      display="flex"
                                      flexDirection={{ xs: "column", md: "row" }}
                                      alignItems={{ xs: "flex-start", md: "center" }}
                                      textAlign={{ xs: "left", md: "right" }}
                                      mt={{ xs: 3, md: 0 }}
                                    >
                                      <SoftBox width="50%">
                                        <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                          Créée le:
                                        </SoftTypography>
                                      </SoftBox>
                                      <SoftBox width="50%">
                                        <SoftTypography id="invoice-creation-date" variant="h6" fontWeight="medium">
                                          {new Date(invoice.createdAt).toLocaleString('fr')}
                                        </SoftTypography>
                                      </SoftBox>
                                    </SoftBox>
                                  </Grid>
                                </Grid>
                              </SoftBox>
                            </SoftBox> */}
        
                            {/*---------------------------------------------------------------------------------------------------------------Invoice table */}
                            {/* <SoftBox p={3}>
                              <SoftBox width="100%" overflow="auto">
                                <Table sx={{ minWidth: "32rem" }}> */}
                                  {/*--------------------------------------------------------------------------------------- table header (first row) */}
                                  {/* <SoftBox component="thead">
                                    <TableRow id="table-columns">
                                      {
                                        columnsTitle.map(
                                          (colName, index) => (
                                            <SoftBox id={`column-${index}`} key={index} component="th" p={1} textAlign="left" borderBottom={borderBottom}>
                                              <SoftTypography variant="h6" color="text" fontWeight="medium">
                                                {colName}
                                              </SoftTypography>
                                            </SoftBox>
                                          )
                                        )
                                      }
                                    </TableRow>
                                  </SoftBox> */}
                                  
                                  {/*--------------------------------------------------------------------------------------- table body*/}
                                  {/* <TableBody id="table-rows">
                                    {
                                      invoice.purshes?.map((purshe, index) => (
                                        <TableRow key={index}>
                                          <SoftBox id={`type-${index+1}`} component="td" p={1} textAlign="left" borderBottom={borderBottom}>
                                            <SoftTypography variant="body2" color="text">
                                              {purshe.type}
                                            </SoftTypography>
                                          </SoftBox>
                                          <SoftBox id={`description-${index+1}`} component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                            <SoftTypography variant="body2" color="text">
                                              {purshe.description}
                                            </SoftTypography>
                                          </SoftBox>
                                          <SoftBox id={`unit-price-ht-${index+1}`} component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                            <SoftTypography variant="body2" color="text">
                                              {purshe.unitPriceHT}
                                            </SoftTypography>
                                          </SoftBox>
                                          <SoftBox id={`quantity-${index+1}`} component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                            <SoftTypography variant="body2" color="text">
                                              {purshe.quantity}
                                            </SoftTypography>
                                          </SoftBox>
                                          <SoftBox id={`tva-${index+1}`} component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                            <SoftTypography variant="body2" color="text">
                                              {purshe.tva}
                                            </SoftTypography>
                                          </SoftBox>
                                          <SoftBox id={`discount-${index+1}`} component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                            <SoftTypography variant="body2" color="text">
                                              {(purshe.discount || "0") + "%"}
                                            </SoftTypography>
                                          </SoftBox>
                                          <SoftBox id={`total-ht-${index+1}`} component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                            <SoftTypography variant="body2" color="text">
                                              {purshe.totalHT}
                                            </SoftTypography>
                                          </SoftBox>
                                        </TableRow>
                                      ))
                                    } */}
                                    {/* --------------------------------------Totals-------------------------------------- */}
                                    {/* {
                                      invoice.total?.map((total, index) => (
                                        <TableRow key={index}>
                                          <SoftBox component="td" textAlign="left" p={1} />
                                          <SoftBox component="td" textAlign="left" p={1} />
                                          <SoftBox component="td" textAlign="left" p={1} />
                                          <SoftBox component="td" textAlign="left" p={1} />
                                          <SoftBox component="td" textAlign="left" p={1} />
                                          <SoftBox component="td" textAlign="left" p={1}>
                                            <SoftTypography id={total.title} variant="h6">{total.title}</SoftTypography>
                                          </SoftBox>
                                          <SoftBox component="td" textAlign="left" p={1}>
                                            <SoftTypography id={total.value} variant="h6">{total.value}</SoftTypography>
                                          </SoftBox>
                                        </TableRow>
                                      ))
                                    }
                                  </TableBody>
                                </Table>
                              </SoftBox>
                            </SoftBox> */}
          
                            {/* Invoice footer */}
                            {/* <SoftBox p={3} mt={7}>
                              <Grid container>
                                <Grid item xs={12} lg={5}>
                                  <SoftTypography id="invoice-closing-text" variant="h6" fontWeight="medium">
                                    { invoice.conditions?.closingTxt }
                                  </SoftTypography>
                                  <SoftTypography
                                    component="span"
                                    variant="h6"
                                    fontWeight="medium"
                                    id="invoice-footer-text"
                                  >
                                    {invoice.footerTxt}
                                  </SoftTypography>
                                  {
                                  invoice.coordinates &&
                                    ( 
                                      <>
                                      <SoftTypography
                                        mt={2}
                                        variant="h5"
                                        fontWeight="medium"
                                        id="invoice-footer-text"
                                      >
                                        {"Coordonnées: "}
                                      </SoftTypography>
                                      
                                      <SoftTypography
                                        variant="subtitle2"
                                        fontWeight="regular"
                                        id="invoice-footer-text"
                                      >
                                        {"Longitude de l'utilisateur: " + invoice.coordinates?.userLongitude}
                                      </SoftTypography>
                                      <SoftTypography
                                        variant="subtitle2"
                                        fontWeight="regular"
                                        id="invoice-footer-text"
                                      >
                                        {"Latitude de l'utilisateur: " + invoice.coordinates?.userLatitude}
                                      </SoftTypography>
                                      <SoftTypography
                                        variant="subtitle2"
                                        fontWeight="regular"
                                        id="invoice-footer-text"
                                      >
                                        {"Emplacement de l'utilisateur: " + invoice.coordinates?.userLocation}
                                      </SoftTypography>
                                      </>
                                      
                                    )
                                  }
                                  
                                 
                                </Grid>

                                <Grid item xs={12} lg={isPreInsc ? 3 : 7}>
                                  <SoftBox
                                    width="100%"
                                    height={{ xs: "auto", md: "100%" }}
                                    display="flex"
                                    justifyContent={{ xs: "flex-start", md: "flex-end" }}
                                    alignItems="flex-end"
                                    mt={{ xs: 2, md: 0 }}
                                  >
                                    <SoftButton
                                      id = "download-btn"
                                      variant="gradient"
                                      color="info"
                                      onClick={downloadHandler}
                                    >
                                      Télécharge PDF
                                    </SoftButton>
                                  </SoftBox>
                                </Grid>
                                
                                {
                                  isPreInsc &&
                                  <Grid item xs={12} lg={4}>
                                    <SoftBox
                                      width="100%"
                                      height={{ xs: "auto", md: "100%" }}
                                      display="flex"
                                      justifyContent={{ xs: "flex-start", md: "flex-end" }}
                                      alignItems="flex-end"
                                      mt={{ xs: 2, md: 0 }}
                                    >
                                      <SoftButton
                                        id = "download-btn"
                                        variant="gradient"
                                        color="info"
                                        onClick={preInscToInsc}
                                      >
                                        Convertier à une inscription
                                      </SoftButton>
                                    </SoftBox>
                                  </Grid>
                                }

                              </Grid>
                            </SoftBox> */}
                          </>
                        )
                      }
                      {
                        downloadError && (<Alert id="download-error-alert" severity="error"> {"Désolé, une erreur s'est produite lors du téléchargement de votre fichier. Veuillez réessayer plus tard."} </Alert>)
                      }
                    </Card>
                  </Grid>
                </Grid>
              </SoftBox>
            )
          }
        })()
      }
    </DashboardLayout>
  );
}


InvoiceDetails.defaultProps = {
  type: "",
}

InvoiceDetails.propTypes = {
  type: PropTypes.oneOf(["facture", "inscription", "quotations"])
}

export default InvoiceDetails;