import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import "react-phone-input-2/lib/style.css";
import { Autocomplete, TextField, Typography,IconButton, Stack } from "@mui/material";
import EnterpriseFormField from "pages/enterprise/schema/enterprise-schemas/EnterpriseFormField";
import api from "utils/axios";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SoftButton from "components/SoftButton";

function ClassInfo({ formData }) {
  const [inputs, setInputs] = useState([{ name: "" }]);
  const [optionsLevels, setOptionsLevels] = useState([]);
  const [optionsFilieres, setOptionsFilieres] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [optionsStudents, setOptionsStudents] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [optionsSemestre, setOptionsSemestre] = useState([]);
  const [optionsSousSemestre, setOptionsSousSemestre] = useState([]);
  const [optionsParcours, setOptionsParcours] = useState([]);
  const [optionsPeriodes, setOptionsPeriodes] = useState([]);
  const [selectedParcour, setSelectedParcour] = useState(null);
  const [selectedFiliere, setSelectedFiliere] = useState(null);
  const [selectedPeriode, setSelectedPeriode] = useState(null);
  const [inputsSub, setInputsSub] = useState([{instructor:"",subject:"",optionsInstructor:[], subjectError: '', instructorError: ''}]);
  const [selectedSousSemester, setSelectedSousSemester] = useState(null);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const { formField, values, errors, touched } = formData;
  const { name, abilityMaximum, groupNumber } = formField;
  const { name: nameV, abilityMaximum: abilityMaximumV,groupNumber:groupNumberV } = values;
  values.level = selectedLevel;
  values.periode = selectedPeriode;
  values.filiere = selectedFiliere;
  values.parcour = selectedParcour;
  useEffect(() => {
    api.get(`/faculty/all-filiere/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsFilieres(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
          niveaux: item.niveaux,
        }))
      );
    });
    api
      .get(`/faculty/all-periodes/${facility.scholarityConfigId}`)
      .then((res) => {
        setOptionsPeriodes(
          res.data.map((item) => ({
            value: item._id,
            label: item.name,
          }))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const handleAddInput = () => {
    setInputsSub([...inputsSub, {instructor:"",subject:""}]);
  };
  const handleRemoveInput = (indexToRemove) => {
    const newInputs = [...inputsSub];
    if (indexToRemove >= 0 && indexToRemove < newInputs.length) {
      // Remove the input at the specified index
      newInputs.splice(indexToRemove, 1);
      setInputsSub(newInputs);
    }
  };

  return (
    <>
      <SoftBox>
        <SoftBox pt={2}>
          <SoftBox lineHeight={0} p={1}>
            <SoftTypography id="add-company-admin" variant="h5" fontWeight="bold">
              Ajouter Classe
            </SoftTypography>
          </SoftBox>
          {errorMessage && (
            <Typography align="center" sx={{ mt: 2 }} variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
          <SoftBox mt={1.625}>
            <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="name"
                  type={name.type}
                  label={name.label}
                  name={name.name}
                  value={nameV}
                  placeholder={name.placeholder}
                  inputProps={{
                    minLength: 2,
                    maxLength: 30,
                  }}
                  autoFocus
                  error={errors.name && touched.name}
                  success={nameV.length > 0 && !errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="abilityMaximum"
                  type={abilityMaximum.type}
                  label={abilityMaximum.label}
                  name={abilityMaximum.name}
                  value={abilityMaximumV}
                  placeholder={abilityMaximum.placeholder}
                  error={errors.abilityMaximum && touched.abilityMaximum}
                  success={abilityMaximumV.length > 0 && !errors.abilityMaximum}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="nbregroupe"
                  type={groupNumber.type}
                  label={groupNumber.label}
                  name={groupNumber.name}
                  value={groupNumberV}
                  placeholder={groupNumber.placeholder}
                  error={errors.groupNumber && touched.groupNumber}
                  success={groupNumberV.length > 0 && !errors.groupNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Filière*
                </SoftTypography>
                <Autocomplete
                  options={optionsFilieres}
                  id="filiere"
                  title="filiere"
                  name={name.filiere}
                  clearIcon={null}
                  required={true}
                  aria-required={true}
                  getOptionLabel={(option) => option.label}
                  defaultValue={null}
                  value={optionsFilieres.find((option) => option.value === inputs[0].level)}
                  onChange={(event, newValue) => {
                    if (newValue !== null && newValue !== undefined) {
                      setSelectedFiliere(newValue.value);
                      setSelectedParcour("");
                      setSelectedLevel("");
                      setOptionsLevels(
                        newValue?.niveaux?.map((item) => ({
                          value: item._id,
                          label: item.name,
                          parcours: item.parcours,
                        }))
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      value={inputs[0].blabla}
                      required={true}
                      placeholder="Tous les filière"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Nom du niveau*
                </SoftTypography>
                <Autocomplete
                  options={optionsLevels}
                  id="niveau"
                  title="niveau"
                  name={name.level}
                  clearIcon={null}
                  required={true}
                  aria-required={true}
                  getOptionLabel={(option) => option.label}
                  defaultValue={null}
                  value={optionsLevels.find((option) => option.value === selectedLevel)||null}
                  onChange={(event, newValue) => {
                    if (newValue !== null && newValue !== undefined) {
                      setSelectedLevel(newValue.value);
                      setOptionsParcours(
                        newValue?.parcours?.map((item) => ({
                          value: item._id,
                          label: item.name,
                        }))
                      );      
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      value={inputs[0].blabla}
                      required={true}
                      placeholder="Tous les niveaux"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Parcours*
                </SoftTypography>
                <Autocomplete
                  options={optionsParcours}
                  id="parcours"
                  title="parcours"
                  clearIcon={null}
                  required={true}
                  aria-required={true}
                  getOptionLabel={(option) => option.label}
                  defaultValue={null}
                  value={optionsParcours.find((option) => option.value === selectedParcour)}
                  onChange={(event, newValue) => {
                    if (newValue !== null && newValue !== undefined) {
                      setSelectedParcour(newValue.value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      value={inputs[0].blabla}
                      required={true}
                      placeholder="Tous les parcours"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mb: 0.5 }}
                >
                  Périodes*
                </SoftTypography>
                <Autocomplete
                  options={optionsPeriodes}
                  clearIcon={null}
                  getOptionLabel={(option) => option.label}
                  required={true}
                  aria-required={true}
                   value={
                    optionsPeriodes.find((option) => option.value === selectedPeriode || null)
                  } 
                  onChange={(event, newValue) => {
                    if (newValue !== null && newValue !== undefined) {
                      setSelectedPeriode(newValue.value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField 
                    {...params} 
                    variant="standard" 
                    required={true}
                    placeholder="Toutes les périodes" 
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12}  sm={12}>
              <SoftTypography id="title" variant="body2" fontWeight="bold" mt={2}>
              Ajouter matières et enseignants
            </SoftTypography>
              </Grid>
              {inputsSub.map((input, index) => (
                          <>
                            <Grid item xs={12} sm={inputsSub.length > 1 ? 5.75 : 6}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                Matière*
                              </SoftTypography>
                              <Autocomplete
                                //options={optionsSubject}
                                id="Matière"
                                title="matière"
                                clearIcon={null}
                                required={true}
                                aria-required={true}
                                getOptionLabel={(option) => option.label}
                                defaultValue={null}
                               /*  onChange={(event, newValue) => {
                                  if (newValue !== null && newValue !== undefined) {
                                    const newInputs = [...inputs];
                                    newInputs[index].subject = newValue.value;
                                    newInputs[index].optionsInstructor = newValue?.instructors?.map((item) => ({
                                      value: item._id,
                                      label: `${item?.firstName} ${item?.lastName}`,
                                    }))

                                    setInputs(newInputs);
                                  }
                                }} */
                                // value={optionsSubject.find((option) => option.value === selectedParcour)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    //value={inputs[0].blabla}
                                    required={true}
                                    placeholder="Tous les matières"
                                  />
                                )}
                              />
                              {/* {input.subject.length < 1 && (
                                <Typography mt={0.75} variant="caption" color="error">
                                  {input.subjectError}
                                </Typography>
                              )} */}
                            </Grid>

                            <Grid item xs={12} sm={inputsSub.length > 1 ? 5.75 : 6}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                                sx={{ mb: 0.5 }}
                              >
                                Enseignant*
                              </SoftTypography>
                              <Autocomplete
                                //options={inputs[index].optionsInstructor}
                                clearIcon={null}
                                getOptionLabel={(option) => option.label}
                                required={true}
                                aria-required={true}
                              /*   value={
                                  inputs[index].optionsInstructor?.find((option) => option.value === input?.instructor || null)
                                }
                                onChange={(event, newValue) => {
                                  if (newValue !== null && newValue !== undefined) {
                                    const newInputs = [...inputs];
                                    newInputs[index].instructor = newValue.value;
                                    setInputs(newInputs);
                                  }
                                }} */
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    required={true}
                                    placeholder="Tous les enseignants"
                                  />
                                )}
                              />
                            {/*   {input.instructor.length < 1 && (
                                <Typography mt={0.75} variant="caption" color="error">
                                  {input.instructorError}
                                </Typography>
                              )} */}
                            </Grid>
                            <Grid item xs={12} sm={0.5} mt={4}>
                              {inputsSub.length > 1 && (
                                <IconButton>
                                  <RemoveCircleIcon
                                    id={`btn-delete-${index}`}
                                    color="error"
                                    fontSize="medium"
                                    onClick={() => handleRemoveInput(index)}
                                  />
                                </IconButton>
                              )}
                            </Grid>
                          </>
                        ))}
            </Grid>
            <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ height: "100%" }}
                      >
                        <SoftButton
                          id="btn-ajouter-plus"
                          size="small"
                          sx={{
                            mt: inputsSub.length > 1 ? 3 : -0.5,
                            borderWidth: "2px",
                            borderStyle: "dashed solid",
                            borderColor: "purple ",
                          }}
                          onClick={handleAddInput}
                        >
                          + ajouter autre matière
                        </SoftButton>
                      </Stack>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </>
  );
}
// typechecking props for ClassInfo
ClassInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
export default ClassInfo;
