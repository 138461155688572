import { useEffect, useRef, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, CircularProgress, Slide } from "@mui/material";
import api from "utils/axios";
import { useNavigate } from "react-router-dom";
import SubjectActions from "./components/SubjectActions";
import Stack from "@mui/material/Stack";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import AddSubject from "./subject-cabinet/AddSubject";
import AddSubjectExcel from "./subject-cabinet/AddSubjectExcel";
import SubjectActionsCabinet from "./subject-cabinet/SubjectActionsCabinet";



function SubjectTable() {
  const [loading, setLoading] = useState(false);
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [data, setData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [instructor, setInstructor] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const myRef = useRef(null);
  const navigate = useNavigate();
  const facility = JSON.parse(localStorage.getItem("facility"));

  const handleFetch = async () => {
    setLoading(true);
    try {
      let endpoint;
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/all-subjects/${facility.scholarityConfigId}`;
      } else {
        endpoint = `/faculty/all-subjects/${facility.scholarityConfigId}`;
      }  
      const response = await api.get(endpoint);
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
//fetch instructor
const handleFetchInstructor = async () => {
  try {
    const response = await api.get(`/get-all-users-by-role/${facility._id}?role=instructor`);
    setInstructor(response.data.map((item) => item));
  } catch (error) {
    throw error;
  }
};

  useEffect(() => {
    handleFetch();
    handleFetchInstructor()
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "flex-start", sm: "space-between" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Liste des matiéres
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
              {facility?.type === "centreDeFormation" ? (
              <>
                <SoftButton variant="outlined" color="purple" size="small" mr={2} >
                  <Icon>description</Icon>
                  &nbsp;export Excel
                </SoftButton>
               <AddSubjectExcel handleFetch={handleFetch}  />
                </>) : 
                (
                <>
                <SoftButton variant="outlined" color="purple" size="small" mr={2} /* onClick={handleExportExcel} */>
                  <Icon>description</Icon>
                  &nbsp;export Excel
                </SoftButton>
                <SoftButton variant="outlined" color="purple" size="small" mr={2} /* onClick={handleExportExcel} */>
                  <Icon>description</Icon>
                  &nbsp;import Excel
                </SoftButton>
                </>
              )}

             {facility?.type==="centreDeFormation" ? (
                  <AddSubject handleFetch={handleFetch} instructor={instructor}/>
             ):(
               <SoftButton
                id="add"
                onClick={() => navigate("/plan/subject/add-subject")}
                variant="gradient"
                color="purple"
                size="small"
                mb={{ xs: 2, sm: 0 }}
              >
                +Ajouter Matière
              </SoftButton> 
             )}
             
              
            </Stack>
          </SoftBox>
          {loading ? (
            <SoftBox
              sx={{
                mt: 13,
                textAlign: "center",
              }}
            >
              <CircularProgress />
            </SoftBox>
          ) : (
            facility?.type==="centreDeFormation" ? (
                <div>
              <Slide direction="right" in={!showDetails} mountOnEnter unmountOnExit>
                <div ref={myRef}>
                  {!showDetails && (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Nom du Matière", accessor: "subjectName" },
                          { Header: "Enseignants", accessor: "enseignants" },
                          { Header: "action", accessor: "action", width: "10%" },
                        ],
                        rows:
                          data &&
                          data.map((subject, index) => {
                            return {
                              subjectName:subject.name,
                              enseignants: subject?.instructors?.map((instructor,index) => (
                                <div key={instructor?._id}>
                                  <ul>                                 
                                      <li key={index}>{instructor?.firstName} {instructor?.lastName}</li> 
                                  </ul>                  
                                </div>
                              )),                           
                              action: (
                                <SubjectActionsCabinet
                                  key={subject._id}
                                  subject={subject}
                                  instructor={instructor}
                                  handleFetch={handleFetch}
                                  setShowDetails={setShowDetails}
                                  setDetailsData={setDetailsData}
                                />
                              ),
                            };
                          }),
                      }}
                      entriesPerPage={
                        afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false
                      }
                      canSearch={afficheFilter ? true : false}
                    />
                  )}
                </div>
              </Slide>
            </div>):(
              <div>
              <Slide direction="right" in={!showDetails} mountOnEnter unmountOnExit>
                <div ref={myRef}>
                  {!showDetails && (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "code", accessor: "code", width: "15%" },
                          { Header: "Nom du Matière", accessor: "subjectName" },
                          { Header: "Coefficient Matière", accessor: "coeff" },
                          { Header: "Credit Matière", accessor: "credit" },
                          { Header: "Forme:VH", accessor: "volumeHoraire" },
                          { Header: "Module", accessor: "module" },
                          { Header: "Types", accessor: "types" },
                          { Header: "action", accessor: "action", width: "10%" },
                        ],
                        rows:
                          data &&
                          data.map((subject, index) => {
                            return {
                              code: subject?.code,
                              subjectName:subject.name,
                              coeff: subject?.coefficient,
                              credit: subject?.credit,
                              module: subject?.module?.name,
                              types: subject?.type?.length,
                              volumeHoraire: subject.type.reduce(
                                (acc, type) => acc + type.volumeHoraire,
                                0
                              ),
                              action: (
                                <SubjectActions
                                  key={subject._id}
                                  subject={subject}
                                  handleFetch={handleFetch}
                                  setShowDetails={setShowDetails}
                                  setDetailsData={setDetailsData}
                                />
                              ),
                            };
                          }),
                      }}
                      entriesPerPage={
                        afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false
                      }
                      canSearch={afficheFilter ? true : false}
                    />
                  )}
                </div>
              </Slide>
              {showDetails && (
                <>
                  <ArrowBackIcon
                    onClick={() => setShowDetails(false)}
                    sx={{ cursor: "pointer", lineHeight: 0, mt: 3, ml: 2 }}
                  />
                  <Slide direction="left" in={showDetails} mountOnEnter unmountOnExit>
                    <div>
                      <SoftBox lineHeight={1}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5, ml: 3 }}
                        >
                          Nom de la matière : {detailsData?.name}
                        </SoftTypography>
                      </SoftBox>
                      <DataTable
                        table={{
                          columns: [
                            { Header: "type", accessor: "typeName" },
                            { Header: "volume horaire", accessor: "vh" },
                            { Header: "durée", accessor: "durée" },
                            { Header: "Enseignat", accessor: "enseignant" },
                          ],
                          rows:
                          detailsData &&
                          detailsData.type &&
                          detailsData.type.map((subject) => ({
                            typeName: subject.type,
                            durée: subject.duration,
                            vh: subject.volumeHoraire,
                            enseignant: subject.profs.map((prof) => (
                              <div key={prof._id}>
                                <ul>                                 
                                  <li>{prof.firstName} {prof.lastName}</li>   
                                </ul>                  
                              </div>
                            )),
                          }))
                        }}
                        entriesPerPage={
                          afficheFilter
                            ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }
                            : false
                        }
                        canSearch={afficheFilter ? true : false}
                      />
                    </div>
                  </Slide>
                </>
              )}
            </div>
            )
          )}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}
export default SubjectTable;
