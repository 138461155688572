import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'layouts/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import Document from "examples/Icons/Document";
import Cube from "examples/Icons/Cube";
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import { useParams } from 'react-router-dom';
import { Card, Grid } from '@mui/material';
import Parametre from './components/update-client-components/Parametre';
import ResponsableB2B from './components/update-client-components/ResponsableB2B';
import StudentList from './components/update-client-components/StudentList';
import api from 'utils/axios';
import ResponsableB2C from './components/update-client-components/ResponsableB2C';
import ChangePassword from 'components/shared-users/updateUser/ChangePassword';
import Header from 'components/shared-users/Header';

export default function UpdateClient() {
  const { id } = useParams();
  const [client, setClient] = useState({});
  const [selectedItem, setSelectedItem] = useState(0);
  const handleItemClick = (index) => {
    setSelectedItem(index);
  };
  const handleFetchClientById = async (idToFetch) => {
    try {
      if (idToFetch) {
        const response = await api.get(`/get-user/${idToFetch}`);
        const fetchedClient = response.data;
        setClient(fetchedClient);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (id !== undefined) {
      handleFetchClientById(id);
    }
  }, [id]);
  const sidenavItems = [
    { icon: <Shop />, label: "responsable", href: "/responsable-update" },
    { icon: <Document />, label: "etudiant", href: "/student" },
    { icon: <Cube />, label: "mot de passe", href: "/change-password" },
    { icon: <Office />, label: "Parametre de compte", href: "/account" },
  ];
  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, index) => {
    const itemKey = `item-${index}`;
    const isSelected = index === selectedItem;
    return (
      <SoftBox key={itemKey} component="li" onClick={() => handleItemClick(index)} pt={index === 0 ? 0 : 1}>
        <SoftTypography
          component="a"
          href={`#${href}`}
          variant="button"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
            backgroundColor: index === selectedItem ? '#e0e0e0' : 'transparent',
          })}
        >
          <SoftBox mr={1.5} lineHeight={1}>
            {icon}
          </SoftBox>
          {label}
        </SoftTypography>
      </SoftBox>
    );
  });
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox mt={9}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              {/* START SIDE NAV*/}
              <Card
                sx={{
                  borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                  position: "sticky",
                  top: "1%",
                }}
              >
                <SoftBox
                  component="ul"
                  display="flex"
                  flexDirection="column"
                  p={2}
                  m={0}
                  sx={{ listStyle: "none" }}
                >
                  {renderSidenavItems}
                </SoftBox>
              </Card>
              {/* END SIDE NAV*/}
            </Grid>

            <Grid item xs={12} lg={9}>
              <SoftBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Header user={id && client} />
                  </Grid>
                  <Grid item xs={12}>
                    {
                      selectedItem === 0 ? client.responsableType === "B2B" ? <ResponsableB2B client={client} /> : <ResponsableB2C client={client} /> :
                        selectedItem === 1 ? <StudentList client={client} /> :
                          selectedItem === 2 ? <ChangePassword user={client} /> :
                            selectedItem === 3 ? <Parametre client={client} /> : ""
                    }
                  </Grid>
                </Grid>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </DashboardLayout>
    </>
  )
}
