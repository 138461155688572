import { useEffect, useRef, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { Box, CircularProgress, Slide, Typography } from "@mui/material";
import api from "utils/axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Stack from '@mui/material/Stack';
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import AddTroncCommun from "./AddTroncCommun";
import TroncCommunActions from "./components/TroncCommunActions";
function TroncCommunTable() {
  const [loading, setLoading] = useState(false);
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [data, setData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const myRef = useRef(null);

  const facility = JSON.parse(localStorage.getItem("facility"));
  useEffect(() => {
    document.title = "tronc commun";
  }, []);
  const getLast6Characters = (code) => code?.slice(-6);
  const handleFetch = async () => {
    setLoading(true)
    try {
      const response = await api.get(`/faculty/all-troncs/${facility.scholarityConfigId}`);
      setData(response.data);
    } catch (error) {
      throw error
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    handleFetch();
  }, [])
 
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Liste des troncs communs
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
              <SoftButton variant="outlined" color="purple" size="small" mr={2}>
                export
              </SoftButton>
              <AddTroncCommun token={handleFetch} />
            </Stack>
          </SoftBox>
          {loading ? (
            <SoftBox
              sx={{
                mt: 13,
                textAlign: "center",
              }}
            >
              <CircularProgress />
            </SoftBox>
          ) : (
            <div>
              <Slide direction="right" in={!showDetails} mountOnEnter unmountOnExit>
                <div ref={myRef}>
                  {!showDetails && (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Code", accessor: (row) => getLast6Characters(row.code), width: "15%" },
                          { Header: "Département", accessor: "departement" },
                          { Header: "Nom Tronc Commun", accessor: "troncCommunName" },
                          { Header: 'sous-période', accessor: 'sousPeriodes', Cell: row => <div>{row.value}</div> },
                          { Header: "action", accessor: "action", width: "12%" },
                        ],
                        rows:
                          data && data.map((tronc, index) => ({
                            code: tronc?.code,
                            departement: tronc?.departement?.name,  
                            troncCommunName:tronc?.name,
                            sousPeriodes: tronc?.sousPeriodes?.length,
                            action: (
                              <TroncCommunActions 
                                key={tronc._id}
                                tronc={tronc}
                                handleFetch={handleFetch}
                                setShowDetails={setShowDetails}
                                setDetailsData={setDetailsData}
                              />
                            ),
                          })),
                      }}
                      entriesPerPage={
                        afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false
                      }
                      canSearch={afficheFilter ? true : false}
                    />
                  )}
                </div>
              </Slide>
              {showDetails && (
                <>
                  <ArrowBackIcon
                    onClick={() => setShowDetails(false)}
                    sx={{ cursor: "pointer", lineHeight: 0, mt: 3, ml: 2 }}
                  />
                  <Slide direction="left" in={showDetails} mountOnEnter unmountOnExit>
                    <div>
                    <SoftBox lineHeight={1}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5, ml: 3 }}
                        >
                         {detailsData?.name}
                        </SoftTypography>
                      </SoftBox> 
                      <DataTable
                        table={{
                          columns: [
                            { Header: "code", accessor: (row) => getLast6Characters(row.code), width: "20%" },
                            { Header: "Nom de sous-période", accessor: "sousPeriodes", width: "30%" }, // Adjusted accessor
                          ],
                          rows: detailsData 
                            ? detailsData?.sousPeriodes?.map((s) => ({
                              code: detailsData?.code,
                              sousPeriodes: s?.name
                            }))
                            : [],
                        }}
                        entriesPerPage={afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false}
                        canSearch={afficheFilter}
                      />
                    </div>
                  </Slide>
                </>
              )}
            </div>
          )}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}
export default TroncCommunTable;