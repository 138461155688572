import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import { Tooltip } from "@mui/material";

// Custom styles for CardStudents
function CardStudents({students, image, name,email, dateTime,level, specialitie, members, dropdown,departementId,start,end}) {
  const facility = JSON.parse(localStorage.getItem("facility"));
  const formatMonthYear = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "2-digit", month: "2-digit" };
    return date.toLocaleDateString(undefined, options);
  };
  // Format start and end as "MM/YY"
  const formattedStart = start ? formatMonthYear(start) : "";
  const formattedEnd = end ? formatMonthYear(end) : "";
  return (
    <Card>
      <SoftBox p={1.75}>
        <SoftBox display="flex" alignItems="flex-start" justifyContent="space-between">
          <SoftAvatar src={image} alt={name} size="xl" variant="rounded" />
          <SoftBox ml={1} lineHeight={0} sx={{ overflow: "hidden" }}>
            <SoftBox mb={0.2} lineHeight={0}>
              <Tooltip title={name || ""}>
                <SoftTypography
                  variant="h6"
                  textTransform="capitalize"
                  fontWeight="medium"
                  sx={{
                    wordWrap: "break-word",
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                  }}
                >
                  {name}
                </SoftTypography>
              </Tooltip>
            </SoftBox>
            <SoftBox display="flex" flexDirection="column" justifyContent="center" lineHeight={0}>
              <SoftTypography
                variant="button"
                fontWeight="medium"
                color="secondary"
                fontSize="12px"
              >
                {email}
              </SoftTypography>              
            </SoftBox>
          </SoftBox>
          
          <SoftBox
            sx={{
              ml: "auto",
              alignSelf: "flex-start",
              py: 1.25,
            }}
            display="flex"
    alignItems="center"  // Add this line
          >
            {start && end && (
              <SoftBox
                display="flex"
                flexDirection="column"
                lineHeight={1}
                ml={2}
                alignItems="flex-end"               
              >
                <SoftTypography variant="button" fontWeight="bold">
                {formattedStart}-{formattedEnd}
                </SoftTypography>
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color="secondary"
                  fontSize="12px"
                  sx={{
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                >
                  Année Scolaire
                </SoftTypography>
              </SoftBox>
            )}
            {dropdown && (
              <SoftTypography color="secondary" onClick={dropdown.action}>
                <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                  more_vert
                </Icon>
              </SoftTypography>
            )}
            {dropdown.menu}
          </SoftBox>
        </SoftBox>
      {/*   {facility.type === "faculty" ? (
        <SoftBox my={2} lineHeight={1}>
          <SoftTypography
            variant="button"
            fontWeight="medium"
            color="secondary"
            fontSize="13px"
            sx={{ textAlign: "center" }}
          >
            Dep : {departementId} &nbsp;Spé :&nbsp; {specialitie} &nbsp;Niv :&nbsp; {level}
          </SoftTypography>
        </SoftBox>
      ) : facility.type === "lycee" ? (
        <SoftBox my={2} lineHeight={1}>
          <SoftTypography
            variant="button"
            fontWeight="medium"
            color="secondary"
            fontSize="13px"
            sx={{ textAlign: "center" }}
          >
            Spé :&nbsp; {specialitie} &nbsp;Niv :&nbsp; {level}
          </SoftTypography>
        </SoftBox>
      ) : (
        <SoftBox my={2} lineHeight={1}>
          <SoftTypography
            variant="button"
            fontWeight="medium"
            color="secondary"
            fontSize="13px"
            sx={{ textAlign: "center" }}
          >
            Niv :&nbsp; {level}
          </SoftTypography>
        </SoftBox>
      )} */}

       {/*  <Divider />
        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
          {students > -1 ? (
            <SoftBox display="flex" flexDirection="column" lineHeight={0}>
              <SoftTypography variant="button" fontWeight="medium">
                {students}
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="medium" color="secondary">
                Etudiants
              </SoftTypography>
            </SoftBox>
          ) : null}

          <SoftBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={0}>
            <SoftTypography variant="button" fontWeight="medium"></SoftTypography>
            <EmailIcon sx={{ mr: 1, cursor: "pointer", color: "#7F47E6" }} />
            <SendIcon sx={{ cursor: "pointer", color: "#7F47E6" }} />
          </SoftBox> 
        </SoftBox>*/}
      </SoftBox>
    </Card>
  );
  
}

// Setting default values for the props of ComplexProjectCard
CardStudents.defaultProps = {
  color: "dark",
  dateTime: "",
  members: [],
  dropdown: false,

};

// Typechecking props for the ProfileInfoCard
CardStudents.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  students: PropTypes.arrayOf(PropTypes.string),
  level: PropTypes.string,
  specialitie: PropTypes.string,
  description: PropTypes.node.isRequired,
  departementId: PropTypes.string,
  members: PropTypes.arrayOf(PropTypes.string),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};
export default CardStudents;
