import { Close } from "@mui/icons-material";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import SoftInput from "components/SoftInput";
import { Field, Formik , Form} from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import api from "utils/axios";
import validationSchema from "./validerChamps/ValidationSchema";
import { grey } from "@mui/material/colors";

function ViewDemande({ open, handleCloseModal, demandeId }) {
  const [demande, setDemande] = useState({});
  const handleDemande = (demandeId) => {
    api.get(`get-demande/${demandeId}`).then((res) => {
      setDemande(res.data);
    });
  };

  useEffect(() => {
    if (demandeId) {
      handleDemande(demandeId);
    }
  }, [demandeId]);

  const [userFullName, setUserFullName] = useState("");
  const [niveauName , setNiveauName] = useState('');
  const [filiereName , setFiliereName] = useState('');
  const handleUser = (id) => {
    api.get(`get-user/${id}`).then((res) => {
      setUserFullName(res.data.firstName + " " + res.data.lastName);
      setNiveauName(res.data.parcour.niveau.name);
      api.get(`faculty/one-filiere/${res.data.parcour.niveau.filiere}`).then((res)=>{
        setFiliereName(res.data.name);
      });

    });
  };

  useEffect(() => {
    if (demande.userId) {
      handleUser(demande.userId);
    }
  }, [demande.userId]);

  const initialValues = {
    type: demande.type,
    description: demande.description,
    evaluation: demande.evaluation,
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      sx={{
        "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
          bgcolor: "rgba(0,0,0,0.03) !important",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          height: "75vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: "2px 2px 2px 2px grey",
          p: "24px 32px",
        }}
      >
        <Formik initialValues={initialValues} validationSchema={validationSchema}>
          {({ errors, touched, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
            <Form onSubmit={handleSubmit} style={{ marginTop: "8px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography>Voir Demande</Typography>
                <IconButton onClick={handleCloseModal}>
                  <Close
                    fontSize="24px !important"
                    sx={{
                      "&:hover": {
                        bgcolor: grey[100],
                        borderRadius: "50%",
                      },
                    }}
                  />
                </IconButton>
              </Box>

              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mr: { xs: "0px", md: "8px" }, mt: { xs: "16px", md: "0px" } }}>
                    <Typography variant="body2">Nom et Prénom</Typography>

                    <SoftInput
                      variant="outlined"
                      value={userFullName}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ ml: { xs: "0px", md: "8px" }, mt: { xs: "16px", md: "0px" } }}>
                    <Typography variant="body2">Nom et Prénom</Typography>

                    <SoftInput
                      variant="outlined"
                      value={filiereName}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mr: { xs: "0px", md: "8px" }, mt:"16px" }}>
                    <Typography variant="body2">Nom et Prénom</Typography>

                    <SoftInput
                      variant="outlined"
                      value={niveauName}
                      disabled
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mr: { xs: "0px", md: "8px" }, mt: "16px" }}>
                    <Typography variant="body2">Type</Typography>

                    <Field name="type">
                      {({ field }) => (
                        <SoftInput value={demande.type} success={demande.type !== ""} disabled />
                      )}
                    </Field>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ ml: { xs: "0px", md: "8px" }, mt: "16px" }}>
                    <Typography variant="body2">Evaluation</Typography>
                    <SoftInput
                      placeholder={
                        demande.evaluation === undefined ? "Aucune évaluation existe" : null
                      }
                      value={demande.evaluation}
                      disabled
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box sx={{ mt: "16px", width: "100%" }}>
                <Typography variant="body2">Description</Typography>
                <Field name="description">
                  {({ field }) => (
                    <SoftInput
                      {...field}
                      multiline
                      rows={3}
                      variant="outlined"
                      error={demande.description?.length < 10}
                      success={demande.description?.length >= 10}
                      disabled
                      value={demande.description}
                    />
                  )}
                </Field>
              </Box>

              <Box sx={{ mt: "16px", width: "100%" }}>
                <Typography variant="body2">Reponse</Typography>

                <SoftInput
                  multiline
                  rows={3}
                  placeholder={demande.reponse === undefined ? "Aucune reponse existe" : null}
                  value={demande.reponse}
                  disabled
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

ViewDemande.propTypes = {
  open: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  demandeId: PropTypes.string,
};

export default ViewDemande;
