// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBadge from "components/SoftBadge";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import { Box } from "@mui/material";
import { DragHandle, KeyboardArrowDown, KeyboardDoubleArrowUpOutlined } from "@mui/icons-material";

function Card({ évaluation, content, date, priorité, responsable }) {

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <SoftBadge size="xs" color={évaluation.color} badgeContent={évaluation.label} container />
      </Box>
      <Box mt={1} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <SoftTypography variant="body2" color="text">
          {content}
        </SoftTypography>
        <SoftTypography mr="8px">
          {priorité.label === "Haute" ? (
            <KeyboardDoubleArrowUpOutlined color={priorité.color} />
          ) : priorité.label === "Moyenne" ? (
            <DragHandle size="large" color={priorité.color} />
          ) : (
            <KeyboardArrowDown color="#30F2F2" />
          )}
        </SoftTypography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <SoftTypography variant="caption" color="black">
          {date}
        </SoftTypography>
        <SoftAvatar
          variant="circular"
          src={responsable}
          alt="Image responsable"
          size="xs"
          sx={{
            cursor: "pointer",
            mr: "8px",
          }}
        />
      </Box>
    </>
  );
}

// Typechecking props for the Card
Card.propTypes = {
  évaluation: PropTypes.shape({
    color: PropTypes.oneOf(["secondary", "success", "error"]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  content: PropTypes.node.isRequired,
  date: PropTypes.string.isRequired,
  priorité: PropTypes.shape({
    label: PropTypes.oneOf(["Haute", "Moyenne", "Faible"]).isRequired,
    color: PropTypes.oneOf(["error", "warning"]).isRequired,
  }).isRequired,
  responsable: PropTypes.string.isRequired,
};

export default Card;
