import {  LinearProgress, Stack } from '@mui/material'
import React, { useEffect } from 'react'

const DesktopLogin = () => {
  const token = localStorage.getItem("refreshToken") || sessionStorage.getItem("refreshToken");
  const userData = atob(token.split('.')[1]);
  const parsedData =JSON.parse(userData);
  useEffect(() => {
    const handleRedirect = () => {
      try {
        window.location.href = `studdify://open?token=${parsedData.email}`;
      } catch (error) {
        console.log(error);
        
      }
    }
    handleRedirect();
  }, []);
  return (
    <Stack sx={{ width: '100%', color: 'grey.500', height: '95vh', display: 'flex', justifyContent: 'center' }}>
      <LinearProgress />
    </Stack>
  )
}

export default DesktopLogin
