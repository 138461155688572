import { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { Box, CircularProgress, Slide } from "@mui/material";
import api from "utils/axios";
import AddDomain from "./AddDomain";
import DomainActions from "./components/DomainActions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Stack from '@mui/material/Stack';
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

function DomainTable() {
  const [loading, setLoading] = useState(false);
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [data, setData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const myRef = useRef(null);

  useEffect(() => {
    document.title = "domaine";
  }, []);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const handleFetch = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/faculty/all-domaines/${facility.scholarityConfigId}`);
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetch();
  }, []);
  const getLast6Characters = (code) => code?.slice(-6);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Liste des domaines
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
              <SoftButton variant="outlined" color="purple" size="small" mr={2}>
                export
              </SoftButton>
              <AddDomain handleFetch={handleFetch} />
            </Stack>
          </SoftBox>
          {loading ? (
            <SoftBox sx={{ mt: 13, textAlign: "center" }}>
              <CircularProgress />
            </SoftBox>
          ) : (
            <div>
              <Slide direction="right" in={!showDetails} mountOnEnter unmountOnExit>
                <div ref={myRef}>
                  {!showDetails && (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "code", accessor: (row) => getLast6Characters(row.code), width: "15%" },
                          { Header: "nom du domaine", accessor: "departementName", width: "35%" },
                          { Header: "Nombre de département", accessor: "departements", width: "35%" },
                          { Header: "action", accessor: "action", width: "15%" },
                        ],
                        rows: data && data.map((domain,index) => ({
                          code: domain.code,
                          departementName:domain.name,
                          departements: domain.departements.length,
                          action: (
                            <DomainActions
                              key={index}
                              domain={domain}
                              handleFetch={handleFetch}
                              setShowDetails={setShowDetails}
                              setDetailsData={setDetailsData}
                            />
                          ),
                        })),
                      }}
                      entriesPerPage={afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false}
                      canSearch={afficheFilter ? true : false}
                    />
                  )}
                </div>
              </Slide>
              {showDetails && (
                <>
                  <ArrowBackIcon
                    onClick={() => setShowDetails(false)}
                    sx={{ cursor: "pointer", lineHeight: 0, mt: 3, ml: 2 }}
                  />
                  <Slide direction="left" in={showDetails} mountOnEnter unmountOnExit>
                    <div>
                      <SoftBox lineHeight={1}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5, ml: 3 }}
                        >
                          Nom du domaine : {detailsData?.name}
                        </SoftTypography>
                      </SoftBox>
                      <DataTable
                        table={{
                          columns: [
                            { Header: "code domaine", accessor: (row) => getLast6Characters(row.code), width: "30%" },
                            { Header: "Nom de département", accessor: "department" },
                          ],
                          rows: detailsData && detailsData.departements
                            ? detailsData.departements.map((department) => ({
                              code: detailsData.code,
                              department:department.name,
                            }))
                            : [],
                        }}
                        entriesPerPage={afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false}
                        canSearch={afficheFilter}
                      />
                    </div>
                  </Slide>
                </>
              )}
            </div>
          )}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default DomainTable;
