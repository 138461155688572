import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import SoftSelect from "components/SoftSelect";
import { useEffect, useState } from "react";
import SelectInfo from "../data/SelectInfo";


function BarChart({title , verticalBarChartData , moisBarChart , setMoisBarChart , commercialsName , commercialName , setCommercialName , periodeBarChart, setPeriodeBarChart}){
  const [chartLabels, setChartLabels] = useState([]);

  useEffect(() => {
    // Update chart labels based on selected month
    if (periodeBarChart === "Mensuel") {
      const selectedMonthIndex = SelectInfo.mois.findIndex((mois) => mois.value === moisBarChart);
      const daysInMonth = new Date(new Date().getFullYear(), selectedMonthIndex + 1, 0).getDate();
      setChartLabels(Array.from({ length: daysInMonth }, (_, i) => i + 1));
    } else {
      setChartLabels(["Jan", "Fev", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);
    }
  }, [periodeBarChart, moisBarChart]);

  let filteredDatasets = verticalBarChartData.datasets;

  // if (periodeBarChart === "Annuel") {
  //   filteredDatasets = verticalBarChartData.datasets.filter(dataset => dataset.label === commercialName);
  // } else if (periodeBarChart === "Mensuel" && typeChart === "Chiffre Affaires") {
  //   filteredDatasets = verticalBarChartData.datasets.filter(dataset => dataset.label === "Gain Super Admin");
  // } else if (periodeBarChart === "Mensuel" && typeChart === "Gain") {
  //   filteredDatasets = verticalBarChartData.datasets.filter(dataset => dataset.label === "Gain Par jour");
  // } else if (periodeBarChart === "Mensuel" && typeChart === "Charge") {
  //   filteredDatasets = verticalBarChartData.datasets.filter(dataset => dataset.label === "Charge Par jour");
  // }

  return(
      <Card>
          <Box display={{ xs: "block", md: "flex" }} alignItems="center" justifyContent="space-between" p="18px 18px 0px 18px">
            <Typography variant="h6" color="initial">{title}</Typography>
            <Box  display="flex" alignItems="center">
            <Grid container spacing={2}>
                <Grid item xs={12} md={periodeBarChart === "Mensuel" ? 4 : 6}>
                  <SoftSelect
                    placeholder="choisir Commercial"
                    options={commercialsName}
                    // defaultValue={SelectInfo.type.find((type) => type.value === commercialName)}
                    onChange={(event) => {
                      setCommercialName(event.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={periodeBarChart === "Mensuel" ? 4 : 6}>
                  <SoftSelect
                    placeholder="choisir période"
                    options={SelectInfo.periode}
                    defaultValue={SelectInfo.periode.find((periode) => periode.value === periodeBarChart)}
                    onChange={(event) => {
                      setPeriodeBarChart(event.value);
                    }}
                  />
                </Grid>
                {periodeBarChart === "Mensuel" &&
                  <Grid item xs={12} md={4} >
                    <SoftSelect
                      placeholder="choisir mois"
                      options={SelectInfo.mois}
                      defaultValue={SelectInfo.mois.find((mois) => mois.value === moisBarChart)}
                      onChange={(event) => {
                        setMoisBarChart(event.value);
                      }}
                    />
                  </Grid>
                }
              </Grid>
            </Box>
          </Box>
          <VerticalBarChart chart={{ ...verticalBarChartData, labels: chartLabels }} />
      </Card>
  )
}

BarChart.propTypes = {
    title: PropTypes.string,
    verticalBarChartData : PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.string),
      datasets: PropTypes.shape({
        label: PropTypes.string,
        color: PropTypes.oneOf(["primary" , "secondary" , "success" , "error" , "warning", "info",  "dark", "light"]),
        data: PropTypes.arrayOf(PropTypes.number),
      }),
    }),
    moisBarChart : PropTypes.string,
    setMoisBarChart : PropTypes.func,
    commercialsName : PropTypes.arrayOf(PropTypes.string),
    commercialName : PropTypes.string,
    setCommercialName : PropTypes.func,
    periodeBarChart: PropTypes.string,
    setPeriodeBarChart: PropTypes.func,
  }

export default BarChart;