import PropTypes from "prop-types";
import React, { useState, useEffect, createContext, useContext } from 'react';
import { Menu, MenuItem, Slide, Snackbar } from '@mui/material';
// Create a context for the context menu
const ContextMenuContext = createContext();

export const useContextMenu = () => {
  return useContext(ContextMenuContext);
};
const TransitionLeft = (props) => <Slide {...props} direction="right" />;
export const ContextMenuProvider = ({ children }) => {
  const [contextMenu, setContextMenu] = useState({
    isVisible: false,
    anchorPosition: null,
    items: [],
  });
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const showContextMenu = (event, items) => {
    event.preventDefault();
    setContextMenu({
      isVisible: true,
      anchorPosition: { x: event.clientX, y: event.clientY },
      items,
    });
  };

  const handleClose = () => {
    setContextMenu({ ...contextMenu, isVisible: false });
  };

  useEffect(() => {
    const handleClick = () => {
      if (contextMenu.isVisible) {
        handleClose();
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [contextMenu.isVisible]);

  useEffect(() => {
    const handleContextMenu = (event) => {
      const defaultItems = [
        { label: 'Action 1', onClick: () => alert('Action 1 clicked') },
        { label: 'Action 2', onClick: () => alert('Action 2 clicked') },
      ];
      showContextMenu(event, defaultItems);
    };
    const handleKeyDown = (event) => {
      if (event.key === 'F12' ||
        (event.ctrlKey && event.shiftKey && (event.key === 'I' || event.key === 'C' || event.key === 'J')) ||
        (event.ctrlKey && event.key === 'U')) {
        openErrorSB();
        event.preventDefault();
      }
      if (event.key === "keyup") {
        closeErrorSB();
        event.preventDefault();
      }
    };


    document.addEventListener('keydown', handleKeyDown);

    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);



  return (
    <ContextMenuContext.Provider value={showContextMenu}>
      {children}
      <Menu
        open={contextMenu.isVisible}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu.anchorPosition ? { top: contextMenu.anchorPosition.y, left: contextMenu.anchorPosition.x } : undefined}
      >
        {contextMenu.items.map((item, index) => (
          <MenuItem key={index} onClick={() => { item.onClick(); handleClose(); }}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <Snackbar
        open={errorSB}
        onClose={closeErrorSB}
        message="You don't have permission to perform this action"
        TransitionComponent={TransitionLeft}
        autoHideDuration={3000}
      />
    </ContextMenuContext.Provider>
  );
};

ContextMenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
