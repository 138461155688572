/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { redirect, useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
// @mui core components
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";

// Soft UI Dashboard PRO React components
import team1 from "assets/images/team-1.jpg";
import SoftBox from "components/SoftBox";
// Soft UI Dashboard PRO React example components
import ChatIcon from "@mui/icons-material/Chat";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationItem from "examples/Items/NotificationItem";
import ChildCareIcon from '@mui/icons-material/ChildCare';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarDesktopMenu,
  navbarIconButton,
  navbarMobileMenu,
  navbarRow,
} from "layouts/DashboardNavbar/styles";
import Alert from '@mui/material/Alert';


// Soft UI Dashboard PRO React context

// Images
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import team2 from "assets/images/team-2.jpg";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { setFacility, setLogout, setUser } from "context";
import DefaultItem from "examples/Items/DefaultItem";
import SchoolIcon from '@mui/icons-material/School';
import { Box, Badge, Stack } from "@mui/material";
import api from "utils/axios";
import { setChat } from "context";
import timeAgo from "utils/dateFormat";


function DashboardNavbar({ absolute, light, isMini }) {
  const [clicked, setClicked] = useState(false);
  const [clickedMenuProfile, setClickedMenuProfile] = useState(false);

  const handleClick = () => { setClicked(!clicked); };
  const handleClickMenuProfile = () => { setClickedMenuProfile(!clickedMenuProfile); };


  const [navbarType, setNavbarType] = useState();
  const [notification, setNotification] = useState([]);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [pageScrolled, setPageScrolled] = useState(false); // État local pour suivre si la page a été 
  // scrollée
  const [showAlert, setShowAlert] = useState({
    size: false,
    users: false
  });

  const [openMenuProfile, setOpenMenuProfile] = useState(false);
  const [openMenuFacility, setOpenMenuFacility] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(5);
  const [limitSize, setLimitSize] = useState();
  const [errorMessage, setErrorMessage] = useState({
    size: null,
    users: null
  });
  const [hasError, setHasError] = useState({
    size: false,
    users: false
  });

  // const route = useLocation().pathname.split("/").slice(1);
  useEffect(() => {
    api.get("/get-all-notif").then((res) => {
      setNotification(res.data);
    }).catch((err) => {
      return err;
    })
  }, [notification.length, controller.notifications]);

  useEffect(() => {
    if (localStorage.getItem("licence")) {
      const licence = JSON.parse(localStorage.getItem("licence"));
      setLimitSize(licence?.remainingDataSizeMb || 223343);
      handleCheckSizeLimit(licence);
      if (licence?.remainingUser === 0) {
        setHasError({ size: hasError.size, users: true })
        setShowAlert({ size: showAlert.size, users: true });
        setErrorMessage({
          size: errorMessage.size,
          users: "Vous avez atteint le nombre maximum d'utilisateurs"
        })
      }
    }
  }, [controller.user, limitSize]);

  const handleCheckSizeLimit = async (licence) => {
    if (!licence) return;
    try {
      const { data } = await api.get(
        `/get-files-size-enterprise/${licence.enterpriseId}`
      );
      if (data.Megabytes === limitSize || data.Megabytes > limitSize) {
        setHasError({
          size: true,
          users: hasError.users
        })
        setShowAlert({ users: showAlert.users, size: true });
        setErrorMessage({
          users: errorMessage.users,
          size: "You dont have access to push another file"
        })
      }
    } catch (error) {
      throw error;
    }
  }

  const handleUpdateNotif = async (notifId) => {
    try {
      await api.patch(`/update-notif/${notifId}`, {
        status: "read"
      });
      notification.map((notif) => {
        if (notif._id === notifId) {
          notif.status = "read";
        }
      });
      setNotification(notification);
    } catch (error) {
      return error
    }
  }
  const handleOpenNotif = async (userId, notifId) => {
    if (notifId) {
      await handleUpdateNotif(notifId);
    }
    const response = await api.post("/create-chat", {
      id: userId
    });
    handleCloseMenu();
    setChat(dispatch, {
      open: true,
      receiver: {
        chat: response.data,
        chatId: response.data._id,
        fullName: response.data.users
          .filter(user => user._id !== userId)
          .map(user => user.firstName + " " + user.lastName)
          .join(', '),
        userId: userId,
        image: process.env.REACT_APP_BASE_URL + response.data.users
          .filter(user => user._id !== userId)
          .map(user => user.imageUrl)
          .join(',')
      }
    })
  }

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      const scrolled = window.scrollY > 50;

      // Mettez à jour l'état local pour activer miniSidenav si la page a été scrollée
      setPageScrolled(scrolled);
      //setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => {
    // Logique pour gérer l'ouverture du menu
    setOpenMenu(event.currentTarget);

    // Mettez à jour l'état pour indiquer que le bouton a été cliqué
    setButtonClicked(!buttonClicked);
  };
  const handleCloseMenu = () => {
    setOpenMenu(false);
    setClicked(false);
    setVisibleNotifications(5);
  };

  const handleOpenMenuProfile = (event) => setOpenMenuProfile(event.currentTarget);
  const handleOpenMenuFacility = (event) => setOpenMenuFacility(event.currentTarget);
  const handleCloseMenuProfile = () => {
    setOpenMenuProfile(false);
    setVisibleNotifications(5);
  }

  const handleCloseMenuFacility = () => setOpenMenuFacility(false);
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const refreshToken =
        localStorage.getItem("refreshToken") || sessionStorage.getItem("refreshToken");
        if(!refreshToken) return navigate("/login");
      await api.delete("/logout", {
        headers: { Authorization: `Basic ${refreshToken}` }
      })
      setLogout(dispatch);
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("facility");
      localStorage.removeItem("licence");
      sessionStorage.clear();
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeFacility = async (facility) => {
    await setFacility(dispatch, facility);
    localStorage.setItem("facility", JSON.stringify(facility));
    handleCloseMenuFacility();
    navigate(0);
  }


  // Render the notifications menu

  const renderMenu = () => {

    const showVoirPlus = notification && notification.length > visibleNotifications;

    const handleVoirPlusClick = () => {
      setVisibleNotifications((prevCount) => prevCount + 5);
    };

    return (
      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}

      >
        {notification.length > 0 ? (
          <Box>
            {notification?.slice(0, visibleNotifications).map((notif, index) => (
              <>
                {notif.status === "unread" ? (
                  <Box key={index} sx={{ cursor: "pointer" }} onClick={() => handleOpenNotif(notif.userId)}>
                    <Stack direction="row"  >
                      <NotificationItem
                        key={index}
                        image={<img src={`${process.env.REACT_APP_BASE_URL}/${notif.image}`} alt="person" />}
                        title={["New message", notif.title]}
                        date={timeAgo(notif.createdAt)}
                        onClick={() => handleOpenNotif(notif.userId, notif._id)}
                      />
                      <Badge color="secondary" variant="dot" sx={{ mt: 1 }} />
                    </Stack>
                  </Box>
                ) : (
                  <NotificationItem
                    key={index}
                    image={<img src={`${process.env.REACT_APP_BASE_URL}/${notif.image}`} alt="person" />}
                    title={["New message", notif.title]}
                    date={timeAgo(notif.createdAt)}
                    onClick={() => handleOpenNotif(notif.userId)}
                  />
                )}

              </>
            ))}

            {showVoirPlus && (
              <SoftBox pl={1} textAlign="center">
                <SoftTypography
                  variant="button"
                  sx={{ cursor: "pointer" }}
                  color="text"
                  fontWeight="medium"
                  onClick={handleVoirPlusClick}
                >
                  Voir plus
                </SoftTypography>
              </SoftBox>
            )}
          </Box>
        ) : (
          <SoftBox pl={1} textAlign="center">
            <SoftTypography
              variant="button"
              sx={{ cursor: "pointer" }}
              color="text"
              fontWeight="medium"
              onClick={handleCloseMenu}
            >
              Aucune notification
            </SoftTypography>
          </SoftBox>
        )}
      </Menu>
    );
  };


  // Render the profile menu
  const renderMenuProfile = () => (
    <Menu
      anchorEl={openMenuProfile}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenuProfile)}
      onClose={handleCloseMenuProfile}
      sx={{ mt: 2 }}
    >
      <DefaultItem
        color="secondary"
        icon="account_circle"
        title={controller?.user?.info?.lastName}
        description={controller?.user?.info?.role}
        sx={{ cursor: "pointer" }}
        onClick={handleCloseMenuProfile}
      />
      <DefaultItem
        color="secondary"
        icon="settings"
        title="Parametres"
        description=""
        sx={{ cursor: "pointer" }}
        onClick={() => navigate(`/settings/${controller?.user?.info?._id}`)}
      />
      <DefaultItem
        color="secondary"
        icon="logout"
        title="Déconnecter"
        description=""
        sx={{ cursor: "pointer" }}
        onClick={handleLogout}
      />
    </Menu>
  );
  let facilityInfo;
  let facilityParsed;
  if (controller?.user?.info?.role === "company-admin") {
    facilityInfo = localStorage.getItem("facility");
    facilityParsed = JSON.parse(facilityInfo);
  }

  const renderMenuFacilities = () => {

    if (controller.user.info.role === "company-admin") {
      return (
        <Menu
          anchorEl={openMenuFacility}
          anchorReference={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={Boolean(openMenuFacility)}
          onClose={handleCloseMenuFacility}
          sx={{ mt: 2 }}
        >
          {controller?.user?.info?.ownedFacilities.map((facility) => (
            <Box sx={{ backgroundColor: `${facility._id === facilityParsed?._id ? "#D8D8D8" : "#fff"}`, cursor: "pointer", '&:hover': { backgroundColor: "lightgray" } }} key={facility._id}>
              <DefaultItem
                color="primary"
                icon={<ChildCareIcon />}
                title={facility.name}
                description={facility.type}
                sx={{ cursor: "pointer", '&:hover': { backgroundColor: "lightgray" } }}
                onClick={() => handleChangeFacility(facility)}
              />
            </Box>
          ))}
        </Menu>
      )
    }
  }

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color={light ? "transparent" : "white"} // Utilisez l'état local pour définir la couleur de l'AppBar
      sx={(theme) => navbar(theme, { transparentNavbar, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox color={pageScrolled ? "inherit" : "inherit"} mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
        </SoftBox>
        {isMini ? null : (
          //cas responsive 
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            <SoftBox>
              <IconButton
                color={pageScrolled ? "inherit" : "inherit"}
                size="large"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            </SoftBox>
            <SoftBox mr={1.6} color={light ? "white" : "inherit"}>
              {/* {
                controller?.user?.info?.role === "company-admin" && (
                  <SoftButton
                    //color="light"
                    //color={clicked ? "light" : "white"}
                    sx={{navbarIconButton, boxShadow: '4', mr: 1 }}

                    circular
                    iconOnly
                    style={{ width: "45px", height: "45px" }}
                    //size="large"
                    // sx={navbarIconButton}
                  
                    aria-controls="notification-menu"
                    aria-haspopup="true"
                    variant="gradient"
                    onClick={handleOpenMenuFacility}
                  >
                    <SchoolIcon />
                  </SoftButton>
                )
              } */}
              <SoftButton
                circular
                iconOnly
                color="white"
                style={{ width: "45px", height: "45px" }}
                //size="large"
                sx={{ navbarIconButton, boxShadow: '4', }}
                aria-controls="notification-menu"
                aria-haspopup="true"
              //variant="gradient"
              >
                <ChatIcon />
              </SoftButton>{" "}



              <SoftButton
                circular
                iconOnly
                style={{ width: "45px", height: "45px" }}
                sx={{
                  navbarIconButton,
                  boxShadow: clicked ? '4' : '4', // Modifier la boîte d'ombre en fonction du clic
                  backgroundColor: clicked ? 'light' : 'white',
                }}
                aria-controls="notification-menu"
                aria-haspopup="true"

                //variant="gradient"
                onClick={(event) => {
                  handleOpenMenu(event);

                  handleClick();
                }}
                color={clicked ? "light" : "white"}
              // color="white"
              >
                <Badge sx={{ p: 1 }} badgeContent={notification.filter((notif) => notif.status === "unread").length} color="error">
                  <NotificationsIcon />
                </Badge>
              </SoftButton>{" "}


              <SoftButton
                color={clickedMenuProfile ? "light" : "white"}
                circular
                iconOnly
                style={{ width: "45px", height: "45px" }}
                //size="large"
                sx={{ navbarIconButton, boxShadow: '4', }}
                aria-controls="notification-menu"
                aria-haspopup="true"
                //variant="gradient"
                onClick={(event) => {
                  handleOpenMenuProfile(event);
                  handleClickMenuProfile();
                }}
              >
                <SoftAvatar src={`${process.env.REACT_APP_BASE_URL}/${controller?.user?.info?.imageUrl}`} alt="profile-image"
                  //variant="circle" 
                  //size="md" 
                  style={{ width: "40px", height: "40px" }} />
              </SoftButton>

              {renderMenu()}
              {renderMenuProfile()}
              {controller?.user?.info?.role === "company-admin" && renderMenuFacilities()}
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
      {hasError.size && (
        <Collapse in={showAlert.size}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert({ users: showAlert.users, size: false });
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2, mt: 1 }}
          >
            {errorMessage.size}
          </Alert>
        </Collapse>
      )}
      {hasError.users && (
        <Collapse in={showAlert.users}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert({ size: showAlert.size, users: false });
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2, mt: 1 }}
          >
            {errorMessage.users}
          </Alert>
        </Collapse>
      )}
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

