import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Entrez le type de document"),

  statut: Yup.string().required("Entrez le statut de document"),

  description: Yup.string()
    .required("Entrez la description de document")
    .min(10, "La longueur minimale requise est de 10 caractères."),

  evaluation: Yup.string().required("Entrez l'évaluation de demande"),
});

export default validationSchema;
