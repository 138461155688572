import { Close } from "@mui/icons-material";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import SoftSelect from "components/SoftSelect";
import PropTypes from "prop-types";

import SoftTypography from 'components/SoftTypography';
import SelectData from "./data/SelectData";
import SoftInput from "components/SoftInput";
import { Field, Form, Formik } from "formik";
import validationSchema from "./validerChamps/ValidationSchema";
import { useEffect, useState } from "react";
import SoftButton from "components/SoftButton";
import api from "utils/axios";
import Swal from "sweetalert2";
import { useSoftUIController } from "context";

function AjouterDemande({ open, handleCloseModal, handleDemandes }) {
  const [controller, _] = useSoftUIController();
  const initialValues = {
    type: "",
    description: "",
  };

  const [type, setType] = useState("");
  const [description, setDescription] = useState("");

  const createDemandeDocument = () => {
    if (type !== "" && description?.length >= 10) {
      api
        .post("create-demande", {
          type: type,
          description: description,
          statut: controller.user.info.role === "student" ? "A faire" : "Terminer",
          evaluation: controller.user.info.role === "student" ? undefined : "Valider",
        })
        .then((res) => {
          handleDemandes();
          handleCloseModal();
          Swal.fire({
            title: "Succès",
            text: "Ajouter demande document réussie",
            icon: "success",
            confirmButtonText: "ok",
          });
        })
    } else {
      Swal.fire({
        title: "Warning",
        text: "Veuillez ajouter tous les champs obligatoires.",
        icon: "warning",
        confirmButtonText: "ok",
      });
    }
  };

  const [niveauName, setNiveauName] = useState('');
  const [filiereName, setFiliereName] = useState('');

  const handleNiveauAndFiliereName = (idParcour) => {
    api.get(`faculty/get-one-parcours/${idParcour}`).then((res) => {
      api.get(`faculty/get-one-level/${res.data.niveau}`).then((res) => {
        setNiveauName(res.data.name);
        api.get(`faculty/one-filiere/${res.data.filiere}`).then((res) => {
          setFiliereName(res.data.name);
        });
      })
    })
  }

  useEffect(() => {
    if (controller.user.info.parcour) {
      handleNiveauAndFiliereName(controller.user.info.parcour);
    }
  }, [controller.user.info.parcour])

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      sx={{
        "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
          bgcolor: "rgba(0,0,0,0.03) !important",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          height: controller.user.info.role === "student" ? "80vh" : "55vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: "2px 2px 2px 2px grey",
          p: "24px 32px",
        }}
      >
        <Formik initialValues={initialValues} validationSchema={validationSchema}>
          {({ errors, touched, handleSubmit, handleChange, handleBlur }) => (
            <Form onSubmit={handleSubmit} style={{ marginTop: "8px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="h5" sx={{ flexGrow: "1" }}>Ajouter Demande</Typography>
                <IconButton onClick={handleCloseModal}>
                  <Close
                    fontSize="24px !important"
                    sx={{
                      "&:hover": {
                        bgcolor: grey[100],
                        borderRadius: "50%",
                      },
                    }}
                  />
                </IconButton>
              </Box>

              {controller.user.info.role === "student" &&
                <Grid container sx={{ mt: "16px" }}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ mr: { xs: "0px", md: "8px" } }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >Nom et Prénom</SoftTypography>
                      <SoftInput
                        variant="outlined"
                        disabled
                        value={controller.user.info.firstName + " " + controller.user.info.lastName}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ ml: { xs: "0px", md: "8px" }, mt: { xs: "16px", md: "0px" } }}>
                     <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >Filière</SoftTypography> 
                      <SoftInput
                        variant="outlined"
                        disabled
                        value={filiereName}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box sx={{ mr: { xs: "0px", md: "8px" }, mt: "16px" }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >Niveau</SoftTypography>
                      <SoftInput
                        variant="outlined"
                        disabled
                        value={niveauName}
                      />
                    </Box>
                  </Grid>
                </Grid>
              }

              <Box sx={{ mt: "16px", width: { xs: "100%", md: "49%" } }}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mb: 0.5 }}
                >Type*</SoftTypography>
                <Field name="type">
                  {({ field }) => (
                    <SoftSelect
                      options={SelectData.type.map((type) => {
                        return {
                          value: type.value,
                          label: type.label,
                        };
                      })}
                      size="medium"
                      placeholder="Choisir Type Demande"
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setType(event.value);
                      }}
                    />
                  )}
                </Field>
                {type === "" && !!touched.type && (
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "400", fontSize: "12px" }}
                    color="error"
                  >
                    {errors.type}
                  </Typography>
                )}
              </Box>

              <Box sx={{ mt: "16px", width: "100%" }}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mb: 0.5 }}
                >Description*</SoftTypography>
                <Field name="description">
                  {({ field }) => (
                    <SoftInput
                      {...field}
                      multiline
                      rows={3}
                      placeholder="Description"
                      variant="outlined"
                      error={!!errors.description && !!touched.description}
                      success={field.value.length >= 10 && !!touched.description}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        handleChange(event);
                        setDescription(event.target.value);
                      }}
                    />
                  )}
                </Field>
                {!!errors.description && !!touched.description && (
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "400", fontSize: "12px" }}
                    color="error"
                  >
                    {errors.description}
                  </Typography>
                )}
              </Box>

              <Box
                sx={{
                  mt: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SoftButton
                  sx={{ mt: 2 }}
                  variant="outlined"
                  color="dark"
                  size="small"
                  onClick={handleCloseModal}
                >
                  Annuler
                </SoftButton>
                <SoftButton
                  sx={{ mt: 2 }}
                  variant="gradient"
                  color="purple"
                  size="small"
                  onClick={() => {
                    createDemandeDocument();
                  }}
                >
                  Ajouter
                </SoftButton>
              </Box>

            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

AjouterDemande.propTypes = {
  open: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleDemandes: PropTypes.func,
};

export default AjouterDemande;
