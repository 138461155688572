import { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { useSoftUIController } from "context";
import PropTypes from "prop-types";
import api from "utils/axios";
import { setNotification } from "context";
import { Slide, Snackbar } from "@mui/material";
import { setConnectedUsers } from "context";

// Create a new context for the socket
const SocketContext = createContext();

// Custom hook to use the SocketContext
export const useSocket = () => useContext(SocketContext);
// Transition component for Snackbar
const TransitionLeft = (props) => <Slide {...props} direction="right" />;
export const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [controller, dispatch] = useSoftUIController();
  const [alert, setAlert] = useState(false);
  const [messageText, setMessageText] = useState("");
  const user = controller.user.info;
  // Effect hook to handle socket connection and events
  useEffect(() => {
    // If no user, return
    if (!user) return;

    // Initialize socket with user id
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      query: { userId: user._id },
    });

    // Set socket state
    setSocket(socket);

    // Emit various events
    socket.emit("connected", (data) => setConnectedUsers(dispatch, data));
    socket.emit("join", "hello");
    socket.emit("setup", user);
    socket.emit("newConnection", user._id);

    // Listen for "connectedUsers" event
    socket.on("connectedUsers", (data) => setConnectedUsers(dispatch, data));

    // Listen for "message recieved" event
    socket.on("message recieved", (newMessageRecived) => {
      const notifText = JSON.parse(newMessageRecived);
      setMessageText(`New Message from ${notifText.author.firstName} ${notifText.author.lastName}`);

      // Get all notifications and set alert
      api
        .get("/get-all-notif")
        .then((res) => {
          setNotification(dispatch, { notifications: res.data });
          setAlert(true);
        })
        .catch(console.log);
    });

    // Close socket on unmount
    return () => socket.close();
  }, [user, dispatch]);
  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
      <Snackbar
        open={alert}
        onClose={() => setAlert(false)}
        TransitionComponent={TransitionLeft}
        message={messageText}
        autoHideDuration={3000}
      />
    </SocketContext.Provider>
  );
};
SocketContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
