import {Card} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Swal from "sweetalert2";
import React from "react";
import PropTypes from "prop-types";
import api from "utils/axios";
import { useNavigate } from "react-router-dom";

export default function Parametre({ commercial }) {
  const navigate = useNavigate();
  const showAlertDeleteAccount = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler"
      })
      .then((result) => {
        if (result.isConfirmed) {
          api
            .delete(`/user/${commercial?._id}`)
            .then(() => {
              Swal.fire("Supprimé !", "Le commercial a été supprimé.", "success").then(() => {
                navigate("/commercials/commercial");
              });
            })
            .catch(() => {
              Swal.fire("Erreur!", "Le commercial n'a pas été supprimé.", "Erreur       ");
            });
        }
      });
  };
  const showAlertDesactivateAccount = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        confirmButtonText: `${commercial?.etat === "active" ? "Desactiver" : "Activer"}`,
      })
      .then((result) => {
        if (result.isConfirmed) {
          api
            .post(`/change-status-user/${commercial?._id}`)
            .then(() => {
              if (commercial?.etat === "active") {
                Swal.fire(
                  "Désactivé !",
                  "Le commercial a été désactivé avec succès.",
                  "success",
                  "success"
                ).then(() => {
                  navigate("/commercials/commercial");
                });
              } else if (commercial?.etat === "desactive") {
                Swal.fire(
                  "Activé!",
                  "Le commercial a été activée avec succès.",
                  "success"
                ).then(() => {
                  navigate("/commercials/commercial");
                });
              }
            })
            .catch(() => {
              Swal.fire("Erreur !", "Le commercial n'a pas été désactivé ou activé.", "erreur");
            });
        }
      });
  };
  return (
    <Card id="delete-account">
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Supprimer le compte</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Une fois que vous supprimez  ce compte, il n&lsquo;y a pas de retour en arrière. Soyez sûr!        </SoftTypography>

      </SoftBox>
      <SoftBox
        pb={3}
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <SoftBox ml="auto" display="flex" flexDirection={{ xs: "column", sm: "row" }}>

          <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <SoftButton size="small"
              id="btn-delete" onClick={showAlertDeleteAccount} variant="outlined" color="secondary">
              Supprimer le compte
            </SoftButton>
            <SoftButton

              id="btn-setat-compte"
              variant="gradient"
              size="small"
              disabled={commercial?.etat === "enAttente"}
              onClick={showAlertDesactivateAccount}
              color={
                commercial?.etat === "active"
                  ? "error"
                  : commercial?.etat === "desactive"
                    ? "success"
                    : "purple"
              }
              sx={{ height: "100%", ml: 2 }}
            >
              {commercial?.etat === "active"
                ? "desactiver"
                : commercial?.etat === "desactive"
                  ? "activer"
                  : "en Attente"}
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}
Parametre.propTypes = {
  commercial: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
