// page => /billing/settings

import { useState, useEffect, useRef } from "react";

import api from "utils/axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import DialogContent from '@mui/material/DialogContent';


// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

import { useSoftUIController } from "context";

// Settings page components
import Sidenav from "pages/billing-settings/components/Sidenav";
import GeneralSettings from "pages/billing-settings/components/GeneralSettings";
import Theme from "pages/billing-settings/components/Theme";
import List from "pages/billing-settings/components/List";
import Preferences from "pages/billing-settings/components/Preferences";
import Numeration from "pages/billing-settings/components/Numeration";
import LoadingAnimation from "components/LoadingAnimation";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import SoftButton from "components/SoftButton";


function Settings() {
  const [isLoading, setIsLoading] = useState(true);
  const [popUpAlert, setPopUpAler] = useState({ show: false, message: "", type: "" });
  const [updateDialog, setUpdateDialog] = useState({ show: false, title: "", id: -1, inputs: [{ label: "", value: "" }], error: "" });
  const { current: pageIndexRef } = useRef({ tva: 0, stampDuty: 0, tds: 0, paymentTerms: 0, paymentMethods: 0, lateInterests: 0, articleTypes: 0, bankAccounts: 0 }); // help keep track of the current page when the component is rundering
  const [configs, setConfigs] = useState();
  const [selectedSubPageId, setSelectedSubPageId] = useState(0);
  const [{ user }, dispatch] = useSoftUIController();
  const [tvaList, setTvaList] = useState(
    {
      columns: [
        { Header: "ID", accessor: "id" },
        { Header: "Nom", accessor: "name" },
        { Header: "Valeur (%)", accessor: "value" },
        { Header: "Action", accessor: "action" }
      ],
      rows: []
    }
  );

  const [stampDutyList, setStampDutyList] = useState({
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Nom", accessor: "name" },
      { Header: `Valeur en ${configs?.general?.devis}`, accessor: "value" },
      { Header: "Action", accessor: "action" }
    ],
    rows: []
  });

  const [tdsList, setTdsList] = useState({
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Nom", accessor: "name" },
      { Header: "Valeur (%)", accessor: "value" },
      { Header: "Action", accessor: "action" }
    ],
    rows: []
  });

  const [paymentTerms, setPaymentTerms] = useState({
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Type", accessor: "type" },
      { Header: "Action", accessor: "action" }
    ],
    rows: []
  });

  const [paymentMethods, setPaymentMethods] = useState({
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Type", accessor: "type" },
      { Header: "Action", accessor: "action" }
    ],
    rows: []
  });

  const [lateInterests, setLateInterests] = useState({
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Type", accessor: "type" },
      { Header: "Action", accessor: "action" }
    ],
    rows: []
  });

  const [articleTypes, setArticleTypes] = useState({
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Type", accessor: "type" },
      { Header: "Action", accessor: "action" }
    ],
    rows: []
  });

  const [bankAccounts, setBankAccounts] = useState({
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "libellé", accessor: "label" },
      { Header: "IBAN", accessor: "iban" },
      { Header: "BIC", accessor: "bic" },
      { Header: "Titre", accessor: "titulaire" },
      { Header: "Action", accessor: "action" }
    ],
    rows: []
  });

  //--------------------------------------------------------------------------------------- fetch and restructure the data 
  useEffect(() => {
    const invoicesConfigId = user?.info?.enterpriseInfo?.invoicesConfigId;
    api.get(`get-invoices-config?invoicesConfigId=${invoicesConfigId}`).then(({ data }) => {
      setConfigs({ ...data });
      if (data.tva && Array.isArray(data.tva)) {
        const tvaRows = [];
        for (let i = 0; i < data.tva.length; i += 1) {
          const { name, value } = data.tva[i];
          tvaRows.push({
            id: i + 1,
            name,
            value
          });
        }
        setTvaList(prevState => ({ ...prevState, rows: tvaRows }));
        // -------------------------------------------------------------------- stamp duty rows
        const stampDutyRows = [];
        for (let i = 0; i < data.stampDuty.length; i += 1) {
          const { name, value } = data.stampDuty[i];
          stampDutyRows.push({
            id: i + 1,
            name,
            value
          })
        }
        setStampDutyList(prevState => {
          const newState = { ...prevState, rows: stampDutyRows };
          newState.columns[2].Header = `Valeur en ${data.general.devis}`;
          return newState;
        });

        // -------------------------------------------------------------------- tds rows
        const tdsRows = [];
        for (let i = 0; i < data.tds.length; i += 1) {
          const { name, value } = data.tds[i];
          tdsRows.push({
            id: i + 1,
            name,
            value
          })
        }
        setTdsList(prevState => ({ ...prevState, rows: tdsRows }));

        // -------------------------------------------------------------------- paymentMethods
        const paymentMethodsRows = [];
        for (let i = 0; i < data.paymentMethods.length; i += 1) {
          paymentMethodsRows.push({ id: i + 1, type: data.paymentMethods[i] });
        }
        setPaymentMethods(prevState => ({ ...prevState, rows: paymentMethodsRows }));

        // -------------------------------------------------------------------- paymentTerms
        const paymentTermsRows = [];
        for (let i = 0; i < data.paymentTerms.length; i += 1) {
          paymentTermsRows.push({ id: i + 1, type: data.paymentTerms[i] });
        }
        setPaymentTerms(prevState => ({ ...prevState, rows: paymentTermsRows }));

        // -------------------------------------------------------------------- lateInterests
        const lateInterestsRows = [];
        for (let i = 0; i < data.lateInterests.length; i += 1) {
          const { description, value } = data.lateInterests[i];
          lateInterestsRows.push({ id: i + 1, type: description, value });
        }
        setLateInterests(prevState => ({ ...prevState, rows: lateInterestsRows }));

        // -------------------------------------------------------------------- paymentTerms
        const articleTypesRows = [];
        for (let i = 0; i < data.articleTypes.length; i += 1) {
          articleTypesRows.push({ id: i + 1, type: data.articleTypes[i] });
        }
        setArticleTypes(prevState => ({ ...prevState, rows: articleTypesRows }));

        // -------------------------------------------------------------------- bankAccounts
        const bankAccountsRows = [];
        for (let i = 0; i < data.bankAccounts.length; i += 1) {
          const { iban, bic, titulaire, label } = data.bankAccounts[i];
          bankAccountsRows.push({ id: i + 1, iban, bic, titulaire, label });
        }
        setBankAccounts(prevState => ({ ...prevState, rows: bankAccountsRows }));
      }
    }).catch((error) => {
      console.debug(error);
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    const subPagesTitles = [
      'General',
      'TVA',
      'Droit De Timbre',
      'Retenue a la source',
      'Conditions de réglement',
      'Mode de réglement',
      'Intérets de retard',
      `Type d'article`,
      'Comptes bancaire',
      'Numérotation',
      'Thémes des documents',
      'Préférence pour les devis',
      'Préférence pour facture',
      'Préférence pour inscriptions'
    ];
    document.title = 'Facturation . ' + subPagesTitles[selectedSubPageId];
  }, [selectedSubPageId]);

  //----------------------------------------------- use me to keep track of the current page number
  const paginationTracker = (key, pageIndex) => {
    pageIndexRef[key] = pageIndex > -1 ? pageIndex : 0; // page index should always be equal or grater than zero
  }

  //------------------------------------------------ delete, edit, a tva( name, value) pair based on her id (index + 1)
  const deleteTVAHandler = (id) => {
    const newRows = tvaList.rows.filter((row) => (id !== row.id)); // rows after the deletion process
    // correct the id's after the deletion process
    for (let i = 0; i < newRows.length; i += 1) {
      newRows[i].id = i + 1;
    }
    const newTvaList = newRows.map(({ name, value }) => ({ name, value })); // tva list after the deletion proccess
    api.patch(`update-invoices-config/${configs._id}`, { tva: newTvaList }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setTvaList(prevState => ({
        ...prevState,
        rows: newRows
      }));
    }).catch(error => {
      console.debug('deletion process for row with id ', id, ' failed', error.message);
      setPopUpAler({ show: true, message: `Il est impossible de supprimer la ligne avec id: (${id}) dans ce tableau.`, type: "error" });
    });
  }

  // open a dialog for ADDING or EDITING and inject the labels + values in the inputs fields
  const TVADialogHandler = (id) => {
    if (id < 1) {
      // dialog for adding a new row
      setUpdateDialog(prevState => (
        { ...prevState, show: true, title: `Ajouter un nouveau TVA`, id, inputs: [{ label: "nom", type: "text", maxChar: 50, value: "" }, { label: "valeur", type: "number", value: "" }] }
      ));
    } else {
      // dialog for editing a row
      const { name, value } = tvaList.rows[id - 1];
      setUpdateDialog(preState => (
        { ...preState, show: true, title: `Modifier le TVA avec l'id (${id})`, id, inputs: [{ label: "nom", type: "text", maxChar: 50, value: name }, { label: "valeur", type: "number", value }] }
      ));
    }
  };


  // send the edit http request to the backend server
  const editTVA = () => {
    const newTvaList = tvaList.rows.map(({ name, value }) => ({ name, value }));
    const inputs = updateDialog.inputs;
    const name = inputs[0].value;
    const value = Number(inputs[1].value);
    const id = updateDialog.id;
    if (id < 1) {
      // adding a new tva
      newTvaList.push({ name, value });
    } else if (id <= newTvaList.length) {
      // edit an old tva
      newTvaList[id - 1] = { name, value };
    }

    api.patch(`update-invoices-config/${configs._id}`, { tva: newTvaList }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setTvaList(prevState => {
        const newState = { ...prevState };
        if (id < 1) {
          newState.rows.push({ id: newTvaList.length, name, value });
        } else if (id <= newTvaList.length) {
          newState.rows[id - 1].name = name;
          newState.rows[id - 1].value = value;
        }
        return newState;
      });
      setUpdateDialog({ show: false, title: "", id: -1, inputs: [{ label: "", value: "" }], error: "" }); // after succeed reset the updateDialog state
    }).catch(error => {
      setUpdateDialog(prevState => ({ ...prevState, error: "error" }));
    });
  };

  //------------------------------------------------ delete, edit, a stamp duty ( name, value) pair based on her id (index + 1)
  const deleteStampDutyHandler = (id) => {
    const newRows = stampDutyList.rows.filter((row) => (id !== row.id));
    // correct the id hole after the deletion of the row
    for (let i = 0; i < newRows.length; i += 1) {
      newRows[i].id = i + 1;
    }

    const newStampDuty = newRows.map(({ name, value }) => ({ name, value }));
    api.patch(`update-invoices-config/${configs._id}`, { stampDuty: newStampDuty }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setStampDutyList(prevState => ({
        ...prevState,
        rows: newRows
      }))
    }).catch(error => {
      console.debug('deletion proccess for row with id ', id, ' failed', error.message);
      setPopUpAler({ show: true, message: `Il est impossible de supprimer la ligne avec id: (${id}) dans ce tableau.`, type: "error" });
    });
  }

  // open the dialog and inject the labels + values in the inputs fields
  const StampDutyDialogHandler = (id) => {
    if (id < 1) {
      setUpdateDialog(preState => (
        { ...preState, show: true, title: `Ajouter un droit de timbre`, id, inputs: [{ label: "nom", maxChar: 50, type: "text", value: "" }, { label: "valeur", type: "number", value: "" }] }
      ));
    } else {
      const { name, value } = stampDutyList.rows[id - 1];
      setUpdateDialog(preState => (
        { ...preState, show: true, title: `Modifier le droit de timbre avec l'id (${id})`, id, inputs: [{ label: "nom", maxChar: 50, type: "text", value: name }, { label: "valeur", type: "number", value }] }
      ));
    }
  };

  // send the edit http request to the backend server
  const editStampDuty = () => {
    const newStampDuty = stampDutyList.rows.map(({ name, value }) => ({ name, value }));
    const inputs = updateDialog.inputs;
    const name = inputs[0].value;
    const value = Number(inputs[1].value);
    const id = updateDialog.id;

    if (id < 1) {
      newStampDuty.push({ name, value });
    } else if (id <= newStampDuty.length) {
      newStampDuty[updateDialog.id - 1] = { name, value };
    }

    api.patch(`update-invoices-config/${configs._id}`, { stampDuty: newStampDuty }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setStampDutyList(prevState => {
        const newState = { ...prevState };
        if (id < 1) {
          newState.rows.push({ id: newStampDuty.length, name, value });
        } else if (id <= newStampDuty.length) {
          newState.rows[updateDialog.id - 1].name = name;
          newState.rows[updateDialog.id - 1].value = value;
        }
        return newState;
      });
      setUpdateDialog({ show: false, title: "", id: -1, inputs: [{ label: "", value: "" }], error: "" }); // after succeed reset the updateDialog state
    }).catch(error => {
      setUpdateDialog(prevState => ({ ...prevState, error: "error" }));
    });
  }

  //------------------------------------------------ delete, edit a tds ( name, value) pair based on her id (index + 1)
  const deleteTdsHandler = (id) => {
    const newRows = tdsList.rows.filter(row => (id !== row.id));
    // index correction...
    for (let i = 0; i < newRows.length; i += 1) {
      newRows[i].id = i + 1;
    }
    const newTds = newRows.map(({ name, value }) => ({ name, value }));
    api.patch(`update-invoices-config/${configs._id}`, { tds: newTds }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setTdsList(prevState => ({
        ...prevState,
        rows: newRows
      }));
    }).catch((error) => {
      console.debug('deletion proccess for row with id ', id, ' failed', error.message);
      setPopUpAler({ show: true, message: `Il est impossible de supprimer la ligne avec id: (${id}) dans ce tableau.`, type: "error" });
    })
  }

  // open the dialog and inject the labels + values in the inputs fields
  const TdsDialogHandler = (id) => {
    if (id < 1) {
      setUpdateDialog(preState => (
        { ...preState, show: true, title: `Ajouter un retenue à source`, id, inputs: [{ label: "nom", maxChar: 50, type: "text", value: "" }, { label: "valeur", type: "number", value: "" }] }
      ));
    } else {
      const { name, value } = tdsList.rows[id - 1];
      setUpdateDialog(preState => (
        { ...preState, show: true, title: `Modifier le retenue à la source avec l'id (${id})`, id, inputs: [{ label: "nom", maxChar: 50, type: "text", value: name }, { label: "valeur", type: "number", value }] }
      ));
    }
  };

  // send the edit http request to the backend server
  const editTds = () => {
    const newTds = tdsList.rows.map(({ name, value }) => ({ name, value }));
    const inputs = updateDialog.inputs;
    const name = inputs[0].value;
    const value = Number(inputs[1].value);
    const id = updateDialog.id;
    if (id < 1) {
      // push new row
      newTds.push({ name, value });
    } else if (id <= newTds.length) {
      // editing a row
      newTds[id - 1] = { name, value };
    }

    api.patch(`update-invoices-config/${configs._id}`, { tds: newTds }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setTdsList(prevState => {
        const newState = { ...prevState };
        if (id < 1) {
          newState.rows.push({ id: newTds.length, name, value });
        } else if (id <= newTds.length) {
          newState.rows[id - 1].name = name;
          newState.rows[id - 1].value = value;
        }
        return newState;
      });
      setUpdateDialog({ show: false, title: "", id: -1, inputs: [{ label: "", type: "text", value: "" }], error: "" }); // after succeed reset the updateDialog state
    }).catch(error => {
      setUpdateDialog(prevState => ({ ...prevState, error: "error" }));
    });
  }


  //------------------------------------------------ delete a paymentTerm  based on his id (index + 1)
  const deletePaymentTermsHandler = (id) => {
    const newRows = paymentTerms.rows.filter(row => (id !== row.id));
    for (let i = 0; i < newRows.length; i += 1) {
      newRows[i].id = i + 1;
    }
    const newPaymentTerms = newRows.map(({ type }) => type);
    api.patch(`update-invoices-config/${configs._id}`, { paymentTerms: newPaymentTerms }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setPaymentTerms(prevState => ({
        ...prevState,
        rows: newRows
      }));
    }).catch((error) => {
      console.debug('deletion proccess for row with id ', id, ' failed', error.message);
      setPopUpAler({ show: true, message: `Il est impossible de supprimer la ligne avec id: (${id}) dans ce tableau.`, type: "error" });
    })
  }


  // open the dialog and inject the labels + values in the inputs fields
  const paymentTermsDialogHandler = (id) => {
    if (id < 1) {
      setUpdateDialog(preState => (
        { ...preState, show: true, title: `Ajouter une conditions de réglement`, id, inputs: [{ label: "type", maxChar: 1000, type: "text", value: "" }] }
      ));
    } else {
      const { type } = paymentTerms.rows[id - 1];
      setUpdateDialog(preState => (
        { ...preState, show: true, title: `Modifier la conditions de réglement avec l'id (${id})`, id, inputs: [{ label: "type", maxChar: 1000, type: "text", value: type }] }
      ));
    }
  };

  // send the edit http request to the backend server
  const editPaymentTerms = () => {
    const newPaymentTerms = paymentTerms.rows.map(({ type }) => type);
    const inputs = updateDialog.inputs;
    const type = inputs[0].value;
    const id = updateDialog.id;

    if (id < 1) newPaymentTerms.push(type);
    else if (id <= newPaymentTerms.length) newPaymentTerms[id - 1] = type;

    api.patch(`update-invoices-config/${configs._id}`, { paymentTerms: newPaymentTerms }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setPaymentTerms(prevState => {
        const newState = { ...prevState };
        if (id < 1) newState.rows.push({ id: newPaymentTerms.length, type });
        else if (id <= newPaymentTerms.length) newState.rows[id - 1].type = type;
        return newState;
      });
      setUpdateDialog({ show: false, title: "", id: -1, inputs: [{ label: "", type: "text", value: "" }], error: "" }); // after succeed reset the updateDialog state
    }).catch(error => {
      setUpdateDialog(prevState => ({ ...prevState, error: "error" }));
    });
  }


  //------------------------------------------------ delete, edit a payment method based on his id (index + 1)
  const deletePaymentMethodsHandler = (id) => {
    const newRows = paymentMethods.rows.filter(row => (id !== row.id));
    for (let i = 0; i < newRows.length; i += 1) {
      newRows[i].id = i + 1;
    }
    const newPaymentMethods = newRows.map(({ type }) => type);
    api.patch(`update-invoices-config/${configs._id}`, { paymentMethods: newPaymentMethods }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setPaymentMethods(prevState => ({
        ...prevState,
        rows: newRows
      }));
    }).catch(error => {
      console.debug('deletion proccess for row with id ', id, ' failed', error.message);
      setPopUpAler({ show: true, message: `Il est impossible de supprimer la ligne avec id: (${id}) dans ce tableau.`, type: "error" });
    })
  }

  // open the dialog and inject the labels + values in the inputs fields
  const paymentMethodsDialogHandler = (id) => {
    if (id < 1) {
      setUpdateDialog(preState => (
        { ...preState, show: true, title: `Ajouter un mode de réglement`, id, inputs: [{ label: "type", maxChar: 100, type: "text", value: "" }] }
      ));
    } else {
      const { type } = paymentMethods.rows[id - 1];
      setUpdateDialog(preState => (
        { ...preState, show: true, title: `Modifier le mode de réglement avec l'id (${id})`, id, inputs: [{ label: "type", maxChar: 100, type: "text", value: type }] }
      ));
    }
  };

  // send the edit http request to the backend server
  const editPaymentMethods = () => {
    const newPaymentMethods = paymentMethods.rows.map(({ type }) => type);
    const inputs = updateDialog.inputs;
    const type = inputs[0].value;
    const id = updateDialog.id;

    if (id < 1) newPaymentMethods.push(type);
    else if (id < newPaymentMethods.length) newPaymentMethods[id - 1] = type;
    api.patch(`update-invoices-config/${configs._id}`, { paymentMethods: newPaymentMethods }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setPaymentMethods(prevState => {
        const newState = { ...prevState };
        if (id < 1) newState.rows.push({ id: newPaymentMethods.length, type });
        else if (id < newPaymentMethods.length) newState.rows[updateDialog.id - 1].type = type;
        return newState;
      });
      setUpdateDialog({ show: false, title: "", id: -1, inputs: [{ label: "", type: "text", value: "" }], error: "" }); // after succeed reset the updateDialog state
    }).catch(error => {
      setUpdateDialog(prevState => ({ ...prevState, error: "error" }));
    });
  };

  //------------------------------------------------ delete, edit a late interest based on his id (index + 1)
  const deleteLateInterestsHandler = (id) => {
    const newRows = lateInterests.rows.filter(row => (id !== row.id));
    for (let i = 0; i < newRows.length; i += 1) {
      newRows[i].id = i + 1;
    }
    const newLateInterests = newRows.map(({ type, value }) => ({ description: type, value }));

    api.patch(`update-invoices-config/${configs._id}`, { lateInterests: newLateInterests }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setLateInterests(prevState => ({
        ...prevState,
        rows: newRows
      }));
    }).catch(error => {
      console.debug('deletion proccess for row with id ', id, ' failed', error.message);
      setPopUpAler({ show: true, message: `Il est impossible de supprimer la ligne avec id: (${id}) dans ce tableau.`, type: "error" });
    })
  }

  // open the dialog and inject the labels + values in the inputs fields
  const lateInterestsDialogHandler = (id) => {
    if (id < 1) {
      setUpdateDialog(preState => (
        { ...preState, show: true, title: `Ajouter un intérets de retard`, id, inputs: [{ label: "type", maxChar: 50, type: "text", value: "" }] }
      ));
    } else {
      const type = lateInterests.rows[id - 1].type;
      setUpdateDialog(preState => (
        { ...preState, show: true, title: `Modifier l'intérets de retard avec l'id (${id})`, id, inputs: [{ label: "type", maxChar: 50, type: "text", value: type }] }
      ));
    }
  };

  // send the edit http request to the backend server
  const editLateInterests = () => {
    const newLateInterests = lateInterests.rows.map(({ type, value }) => ({ description: type, value }));
    const inputs = updateDialog.inputs;
    const type = inputs[0].value;
    const id = updateDialog.id;

    if (id < 1) newLateInterests.push({ description: type, value: -1 });
    else if (id <= newLateInterests.length) newLateInterests[id - 1].description = type;

    api.patch(`update-invoices-config/${configs._id}`, { lateInterests: newLateInterests }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setLateInterests(prevState => {
        const newState = { ...prevState };
        if (id < 1) newState.rows.push({ id: newLateInterests.length, type, value: -1 });
        else if (id <= newLateInterests.length) newState.rows[id - 1].type = type;
        return newState;
      });
      setUpdateDialog({ show: false, title: "", id: -1, inputs: [{ label: "", type: "text", value: "" }], error: "" }); // after succeed reset the updateDialog state
    }).catch(error => {
      setUpdateDialog(prevState => ({ ...prevState, error: "error" }));
    });
  }

  //------------------------------------------------ delete an article type based on his id (index + 1)
  const deleteArticleTypesHandler = (id) => {
    const newRows = articleTypes.rows.filter(row => (id !== row.id));
    for (let i = 0; i < newRows.length; i += 1) {
      newRows[i].id = i + 1;
    }
    const newArticleTypes = newRows.map(({ type }) => type);
    api.patch(`update-invoices-config/${configs._id}`, { articleTypes: newArticleTypes }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setArticleTypes(prevState => ({ ...prevState, rows: newRows }));
    }).catch(error => {
      console.debug('deletion proccess for row with id ', id, ' failed', error.message);
      setPopUpAler({ show: true, message: `Il est impossible de supprimer la ligne avec id: (${id}) dans ce tableau.`, type: "error" });
    });
  }

  // open the dialog and inject the labels + values in the inputs fields
  const articleTypesDialogHandler = (id) => {
    if (id < 1) {
      setUpdateDialog(preState => (
        { ...preState, show: true, title: `Ajouter un mode de réglement`, id, inputs: [{ label: "type", maxChar: 100, type: "text", value: "" }] }
      ));
    } else {
      const { type } = articleTypes.rows[id - 1];
      setUpdateDialog(preState => (
        { ...preState, show: true, title: `Modifier le mode de réglement avec l'id (${id})`, id, inputs: [{ label: "type", maxChar: 100, type: "text", value: type }] }
      ));
    }
  };

  // send the edit http request to the backend server
  const editArticletypes = () => {
    const newArticleTypes = articleTypes.rows.map(({ type }) => type);
    const inputs = updateDialog.inputs;
    const type = inputs[0].value;
    const id = updateDialog.id;

    if (id < 1) newArticleTypes.push(type);
    else if (id <= newArticleTypes.length) newArticleTypes[id - 1] = type;

    api.patch(`update-invoices-config/${configs._id}`, { articleTypes: newArticleTypes }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setArticleTypes(prevState => {
        const newState = { ...prevState };
        if (id < 1) newState.rows.push({ id: newArticleTypes.length, type });
        else if (id <= newArticleTypes.length) newState.rows[updateDialog.id - 1].type = type;
        return newState;
      });
      setUpdateDialog({ show: false, title: "", id: -1, inputs: [{ label: "", type: "text", value: "" }], error: "" }); // after succeed reset the updateDialog state
    }).catch(error => {
      setUpdateDialog(prevState => ({ ...prevState, error: "error" }));
    });
  };

  //------------------------------------------------ delete a bank account based on his id (index + 1)
  const deleteBankAccountsHandler = (id) => {
    const newRows = bankAccounts.rows.filter(row => (id !== row.id));
    for (let i = 0; i < newRows.length; i += 1) {
      newRows[i].id = i + 1;
    }
    const newBankAccounts = newRows.map(({ iban, bic, titulaire, label }) => ({ iban, bic, titulaire, label }));
    api.patch(`update-invoices-config/${configs._id}`, { bankAccounts: newBankAccounts }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setBankAccounts(prevState => ({ ...prevState, rows: newRows }));
    }).catch(error => {
      console.debug('deletion proccess for row with id ', id, ' failed', error.message);
      setPopUpAler({ show: true, message: `Il est impossible de supprimer la ligne avec id: (${id}) dans ce tableau.`, type: "error" });
    })
  }

  // open the dialog and inject the labels + values in the inputs fields
  const bankAccountsDialogHandler = (id) => {
    if (id < 1) {
      setUpdateDialog(preState => (
        {
          ...preState, show: true, title: `Ajouter un comptes bancaire`, id, inputs: [
            { label: "bic", maxChar: 50, type: "text", value: "" },
            { label: "iban", maxChar: 50, type: "text", value: "" },
            { label: "libellé", maxChar: 50, type: "text", value: "" },
            { label: "titre", maxChar: 50, type: "text", value: "" }
          ]
        }
      ));
    } else {
      const { bic, iban, label, titulaire } = bankAccounts.rows[id - 1];
      const inputs = [
        { label: "bic", maxChar: 50, type: "text", value: bic },
        { label: "iban", maxChar: 50, type: "text", value: iban },
        { label: "libellé", maxChar: 50, type: "text", value: label },
        { label: "titre", maxChar: 50, type: "text", value: titulaire }
      ];
      setUpdateDialog(preState => (
        { ...preState, show: true, title: `Modifier le comptes bancaire avec l'id (${id})`, id, inputs }
      ));
    }
  };

  // send the edit bank accounts http request to the backend server
  const editBankAccounts = () => {

    const newBankAccounts = bankAccounts.rows.map(({ iban, bic, titulaire, label }) => ({ iban, bic, titulaire, label }));

    const bic = updateDialog.inputs[0].value;
    const iban = updateDialog.inputs[1].value;
    const label = updateDialog.inputs[2].value;
    const titulaire = updateDialog.inputs[3].value;
    const id = updateDialog.id;

    if (id < 1) newBankAccounts.push({ bic, iban, label, titulaire });
    else if (id <= newBankAccounts.length) newBankAccounts[id - 1] = { bic, iban, label, titulaire };


    api.patch(`update-invoices-config/${configs._id}`, { bankAccounts: newBankAccounts }).then(res => {
      if (res?.data?.updated) {
        setConfigs(res.data.updated);
      }
      setBankAccounts(prevState => {
        const newState = { ...prevState };
        if (id < 1) {
          newState.rows.push({ id: newBankAccounts.length, iban, bic, label, titulaire });
        } else if (id <= newBankAccounts.length) {
          newState.rows[updateDialog.id - 1].iban = iban;
          newState.rows[updateDialog.id - 1].bic = bic;
          newState.rows[updateDialog.id - 1].label = label;
          newState.rows[updateDialog.id - 1].titulaire = titulaire;
        }
        return newState;
      });
      setUpdateDialog({ show: false, title: "", id: -1, inputs: [{ label: "", type: "text", value: "" }], error: "" }); // after succeed reset the updateDialog state
    }).catch(error => {
      setUpdateDialog(prevState => ({ ...prevState, error: "error" }));
    });

  }

  const devisPreferences = {
    frStrings: [
      {
        key: "devisPreferences.introTxtFR",
        label: "Text d'introduction par défaut",
        defaultValue: configs?.devisPreferences?.introTxtFR,
      },
      {
        key: "devisPreferences.closingTxtFR",
        label: "Text de conclusion par défaut",
        defaultValue: configs?.devisPreferences?.closingTxtFR,
      },
      {
        key: "devisPreferences.footerFR",
        label: "Pied de page par défaut",
        defaultValue: configs?.devisPreferences?.footerFR,
      },
      {
        key: "devisPreferences.saleTermsFR",
        label: "Conditions générales de vente par défaut",
        defaultValue: configs?.devisPreferences?.saleTermsFR,
      },
    ],
    enStrings: [
      {
        key: "devisPreferences.introTxtEN",
        label: "Default introductory text",
        defaultValue: configs?.devisPreferences?.introTxtEN,
      },
      {
        key: "devisPreferences.closingTxtEN",
        label: "Default closing text",
        defaultValue: configs?.devisPreferences?.closingTxtEN,
      },
      {
        key: "devisPreferences.footerEN",
        label: "Default footer",
        defaultValue: configs?.devisPreferences?.footerEN,
      },
      {
        key: "devisPreferences.saleTermsEN",
        label: "Default terms of sale",
        defaultValue: configs?.devisPreferences?.saleTermsEN,
      },
    ],
    booleans: [
      {
        key: "devisPreferences.showMyName",
        label: "Afficher mon nom dans les PDF",
        defaultValue: !!configs?.devisPreferences?.showMyName,
      },
      {
        key: "devisPreferences.showSignBlock",
        label: "Cacher le bloc de signature dans les PDF",
        defaultValue: !!configs?.devisPreferences?.showSignBlock,
      },
    ]
  };


  const facturePreferences = {
    frStrings: [
      {
        key: "facturePreferences.introTxtFR",
        label: "Texte d'introduction par défaut",
        defaultValue: configs?.facturePreferences?.introTxtFR,
      },
      {
        key: "facturePreferences.closingTxtFR",
        label: "Texte de conclusion par défaut",
        defaultValue: configs?.facturePreferences?.closingTxtFR,
      },
      {
        key: "facturePreferences.footerFR",
        label: "Pied de page par défaut",
        defaultValue: configs?.facturePreferences?.footerFR,
      },
    ],

    enStrings: [
      {
        key: "facturePreferences.introTxtEN",
        label: "Default introductory text",
        defaultValue: configs?.facturePreferences?.introTxtEN,
      },
      {
        key: "facturePreferences.closingTxtEN",
        label: "Default closing text",
        defaultValue: configs?.facturePreferences?.closingTxtEN,
      },
      {
        key: "facturePreferences.footerEN",
        label: "Default footer",
        defaultValue: configs?.facturePreferences?.footerEN,
      },
    ],
    booleans: [
      {
        key: "facturePreferences.showMyName",
        label: "Afficher mon nom dans les PDF",
        defaultValue: configs?.facturePreferences?.showMyName,
      }
    ]
  };

  const inscriptionPreferences = {
    frStrings: [
      {
        key: "inscriptionPreferences.introTxtFR",
        label: "Texte d'introduction par défaut",
        defaultValue: configs?.inscriptionPreferences?.introTxtFR,
      },
      {
        key: "inscriptionPreferences.closingTxtFR",
        label: "Texte de conclusion par défaut",
        defaultValue: configs?.inscriptionPreferences?.closingTxtFR,
      },
      {
        key: "inscriptionPreferences.footerFR",
        label: "Pied de page par défaut",
        defaultValue: configs?.inscriptionPreferences?.footerFR,
      },
    ],

    enStrings: [
      {
        key: "inscriptionPreferences.introTxtEN",
        label: "Default introductory text",
        defaultValue: configs?.inscriptionPreferences?.introTxtEN,
      },
      {
        key: "inscriptionPreferences.closingTxtEN",
        label: "Default closing text",
        defaultValue: configs?.inscriptionPreferences?.closingTxtEN,
      },
      {
        key: "inscriptionPreferences.footerEN",
        label: "Default footer",
        defaultValue: configs?.inscriptionPreferences?.footerEN,
      },
    ],
    booleans: [
      {
        key: "inscriptionPreferences.showMyName",
        label: "Afficher mon nom dans les PDF",
        defaultValue: configs?.inscriptionPreferences?.showMyName,
      }
    ]
  };


  const subPages = [
    <GeneralSettings
      key="0"
      configs={configs}
      setConfigs={setConfigs}
    />, // general subpage => configs.general

    <List
      key="1"
      title="List des TVA"
      data={tvaList}
      onAdd={TVADialogHandler.bind(null, -1)}
      onEdit={TVADialogHandler}
      onDelete={deleteTVAHandler}
      paginationTracker={paginationTracker.bind(null, "tva")}
      initPage={pageIndexRef.tva}
    />, // TVA subpage => configs.tva

    <List
      key="2"
      title="List des droit de timbre"
      data={stampDutyList}
      onAdd={StampDutyDialogHandler.bind(null, -1)}
      onEdit={StampDutyDialogHandler}
      onDelete={deleteStampDutyHandler}
      paginationTracker={paginationTracker.bind(null, "stampDuty")}
      initPage={pageIndexRef.stampDuty}
    />, // Droit de timbre => configs.stampDuty

    <List
      key="3"
      title="List des retenue à sources"
      data={tdsList}
      onAdd={TdsDialogHandler.bind(null, -1)}
      onEdit={TdsDialogHandler}
      onDelete={deleteTdsHandler}
      paginationTracker={paginationTracker.bind(null, "tds")}
      initPage={pageIndexRef.tds}
    />, // Retenue à la source => configs.tds

    <List
      key="4"
      title="List des conditions de réglement"
      data={paymentTerms}
      onAdd={paymentTermsDialogHandler.bind(null, -1)}
      onEdit={paymentTermsDialogHandler}
      onDelete={deletePaymentTermsHandler}
      paginationTracker={paginationTracker.bind(null, "paymentTerms")}
      initPage={pageIndexRef.paymentTerms}
    />, // Conditions de réglement => configs.paymentTerms

    <List
      key="5"
      title="List des modes de règlements"
      onAdd={paymentMethodsDialogHandler.bind(null, -1)}
      onEdit={paymentMethodsDialogHandler}
      onDelete={deletePaymentMethodsHandler}
      data={paymentMethods}
      paginationTracker={paginationTracker.bind(null, "paymentMethods")}
      initPage={pageIndexRef.paymentMethods}
    />, // Mode de règlement => configs.paymentMethods

    <List
      key="6"
      title="List des intérets de retard"
      data={lateInterests}
      onAdd={lateInterestsDialogHandler.bind(null, -1)}
      onEdit={lateInterestsDialogHandler}
      onDelete={deleteLateInterestsHandler}
      paginationTracker={paginationTracker.bind(null, "lateInterests")}
      initPage={pageIndexRef.lateInterests}
    />, // Intérets de retard => configs.lateInterests

    <List
      key="7"
      title="List des types d'article"
      data={articleTypes}
      onAdd={articleTypesDialogHandler.bind(null, -1)}
      onEdit={articleTypesDialogHandler}
      onDelete={deleteArticleTypesHandler}
      paginationTracker={paginationTracker.bind(null, "articleTypes")}
      initPage={pageIndexRef.articleTypes}
    />, // Type d'article => configs.articleTypes

    <List
      key="8"
      title="List des comptes bancaire"
      data={bankAccounts}
      onAdd={bankAccountsDialogHandler.bind(null, -1)}
      onEdit={bankAccountsDialogHandler}
      onDelete={deleteBankAccountsHandler}
      paginationTracker={paginationTracker.bind(null, "bankAccounts")}
      initPage={pageIndexRef.bankAccounts}
    />, // Comptes bancaire => configs.bankAccounts

    <Numeration
      key="9"
      numbering={configs?.numbering}
      setConfigs={setConfigs}
      configsId={configs?._id}
    />, // Numérotation => configs.numbering

    <Theme key="10" />, // Thèmes des documents => configs.theme

    <Preferences
      titles={["Préférences pour les devis", "Devis en français", "Devis en anglais"]}
      key="11"
      frStrings={devisPreferences.frStrings}
      enStrings={devisPreferences.enStrings}
      booleans={devisPreferences.booleans}
      setConfigs={setConfigs}
      configsId={configs?._id}
    />, // Préférence pour les devis => configs.devisPreferences

    <Preferences
      titles={["Préférences pour les factures", "Factures en français", "Facture en anglais"]}
      key="12"
      frStrings={facturePreferences.frStrings}
      enStrings={facturePreferences.enStrings}
      booleans={facturePreferences.booleans}
      setConfigs={setConfigs}
      configsId={configs?._id}
    />, // Préférence pour facture => configs.facturePreferences
    <Preferences
      titles={["Préférences pour les inscriptions", "Inscription en français", "Inscription en anglais"]}
      key="13"
      frStrings={inscriptionPreferences.frStrings}
      enStrings={inscriptionPreferences.enStrings}
      booleans={inscriptionPreferences.booleans}
      setConfigs={setConfigs}
      configsId={configs?._id}
    /> // Préférence pour inscriptions => configs.inscriptionPreferences
  ];

  const changeSubPageHandler = (id) => {
    setSelectedSubPageId(id);
  }

  const handleAlertClose = () => {
    setPopUpAler(prevState => ({ ...prevState, show: false }));
  };

  const closeUpdateDialogHandler = () => setUpdateDialog({ show: false, title: "", id: -1, inputs: [{ label: "", value: "" }], error: "" });

  // this object map the current subpage index (selectedSubPageId) to their respectivelly edit function ( the function that should execute when we click to the `enregistrer` button in the update dialog)
  const dialogEditFn = {
    // "selectedSubPageId": editFn
    "1": editTVA,
    "2": editStampDuty,
    "3": editTds,
    "4": editPaymentTerms,
    "5": editPaymentMethods,
    "6": editLateInterests,
    "7": editArticletypes,
    "8": editBankAccounts
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={4}>
        <Dialog open={popUpAlert.show} aria-labelledby="alert-dialog-title" onBackdropClick={handleAlertClose}>
          <DialogTitle id="alert-dialog-title">
            <Alert variant="outlined" severity="error">
              {popUpAlert.message}
            </Alert>
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleAlertClose} autoFocus>
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
        {/* update dialog ------------------------------------------------------------------------------------------------- */}
        <Dialog open={updateDialog.show} onClose={closeUpdateDialogHandler}>
          <DialogTitle>{updateDialog.title}</DialogTitle>
          <DialogContent>
            {
              updateDialog.inputs?.map((input, index) => (
                <SoftBox key={index} my={1}>
                  <SoftTypography variant="h6"> {input.label} </SoftTypography>
                  <SoftInput
                    onChange={
                      (e) => {
                        console.debug(e.target.value, "error if input length = ", e.target.value.length, ">", input.maxChar);
                        console.debug(updateDialog.inputs[index]?.value);
                        setUpdateDialog(prevState => {
                          const newState = { ...prevState };
                          newState.inputs[index].value = e.target.value;
                          return newState;
                        })
                      }
                    }
                    defaultValue={input.value} size="small" type={input.type}
                    error={updateDialog.inputs[index]?.value?.length > input.maxChar}
                  />
                </SoftBox>
              ))
            }
            {updateDialog.error?.length > 0 && <Alert severity="error">{updateDialog.error}</Alert>}
          </DialogContent>
          <DialogActions>
            <SoftButton variant="outlined"color="dark" size="small" onClick={closeUpdateDialogHandler}>Cancel</SoftButton>
            <SoftButton color="purple" variant="gradient" size="small" onClick={dialogEditFn[selectedSubPageId]} disabled={updateDialog.inputs.find(input => {
              if (!input.maxChar) return;
              return input.value.length > input.maxChar;
            }) !== undefined} >Enregistrer</SoftButton>
          </DialogActions>
        </Dialog>
        {
          isLoading ? <LoadingAnimation /> :
            <Grid container spacing={3}>
              <Grid item xs={12} lg={3}>
                <Sidenav selectedSubPageId={selectedSubPageId} changeSubPage={changeSubPageHandler} />
              </Grid>
              <Grid item xs={12} lg={9}>
                <SoftBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {
                        subPages[selectedSubPageId]
                      }
                    </Grid>
                  </Grid>
                </SoftBox>
              </Grid>
            </Grid>
        }
      </SoftBox>
    </DashboardLayout>
  );
}

export default Settings;
