import SoftBox from "components/SoftBox";
import React, { useEffect, useState } from "react";
import { Checkbox, Grid, Stack, TextField, Typography } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { ErrorMessage, useFormikContext } from "formik";
import FormFieldSubject from "../../schema/FormFieldSubject";

export default function CategoryInfoUpdate({ formData }) {
  const { formField, errors, touched, values } = formData;
  const { setFieldError, setFieldValue } = useFormikContext();

  const {
    checkedPcExamen,
    checkedPcDs1,
    checkedPcDs2,
    checkedPcCc,
    checkedPcTd,
    checkedPcTp,
    checkedPcMp,
    pcexamen,
    pctd,
    pctp,
    pcmp,
    pcds1,
    pcds2,
    pccc,
    pourcentage,
  } = formField;
  const {
    checkedPcExamen: checkedPcExamenV,
    checkedPcDs1: checkedPcDs1V,
    checkedPcDs2: checkedPcDs2V,
    checkedPcCc: checkedPcCcV,
    checkedPcTd: checkedPcTdV,
    checkedPcTp: checkedPcTpV,
    checkedPcMp: checkedPcMpV,
    checkedCours: checkedCoursV,
    checkedTd: checkedTdV,
    checkedTp: checkedTpV,
    checkedMp: checkedMpV,
    pcexamen: pcexamenV,
    pctd: pctdV,
    pctp: pctpV,
    pcmp: pcmpV,
    pcds1: pcds1V,
    pcds2: pcds2V,
    pccc: pcccV,
    pourcentage: pourcentageV,
  } = values;

  useEffect(() => {
    let sum = 0;
    sum =
      Number(pcexamenV || 0) +
      Number(pctdV || 0) +
      Number(pctpV || 0) +
      Number(pcmpV || 0) +
      Number(pcds1V || 0) +
      Number(pcds2V || 0) +
      Number(pcccV || 0);
    setFieldValue("pourcentage", sum);
  }, [pcexamenV, pctdV, pctpV, pcmpV, pcds1V, pcds2V, pcccV]);

  return (
    <SoftBox mt={1.625}>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        <Grid
          p={1}
          container
          spacing={2}
          sx={{
            mt: "21",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            alignItems: "flex-start",
          }}
        >
          <Grid item xs={12} sm={6}>
            <SoftTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              Catégories :
            </SoftTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mt={0.75}>
              <SoftTypography component="div" variant="caption" color="error">
                <ErrorMessage name={pourcentage.name} />
              </SoftTypography>
            </SoftBox>
          </Grid>
          {values.categorie === "Matiere" && values.checkedCours && (
            <>
              <Grid item xs={12} sm={2}>
                <Grid container spacing={1} mt={3}>
                  <Grid item xs={6} sm={6}>
                    <SoftTypography
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      Examen
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Checkbox
                      defaultChecked={checkedPcExamenV}
                      onChange={() => {
                        setFieldValue("checkedPcExamen", !checkedPcExamenV);
                        setFieldValue("pcexamen", 0);
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormFieldSubject
                  id="pcexamen"
                  name="pcexamen"
                  type="number"
                  margin="normal"
                  variant="standard"
                  fullWidth
                  placeholder="Pourcentage examen"
                  disabled={!checkedPcExamenV}
                  value={pcexamenV}
                  success={pcexamenV >= 0}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Grid container spacing={1} mt={3}>
                  <Grid item xs={6} sm={6}>
                    <SoftTypography
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      DS1
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Checkbox
                      defaultChecked={checkedPcDs1V}
                      onChange={() => {
                        setFieldValue("checkedPcDs1", !checkedPcDs1V);
                        setFieldValue("pcds1", 0);
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormFieldSubject
                  id="pcds1"
                  name="pcds1"
                  type="number"
                  margin="normal"
                  variant="standard"
                  fullWidth
                  placeholder="Poucentage ds1"
                  disabled={!checkedPcDs1V}
                  value={pcds1V}
                  success={pcds1V >= 0}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Grid container spacing={1} mt={3}>
                  <Grid item xs={6} sm={6}>
                    <SoftTypography
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      DS2
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Checkbox
                      defaultChecked={checkedPcDs2V}
                      onChange={() => {
                        setFieldValue("checkedPcDs2", !checkedPcDs2V);
                        setFieldValue("pcds2", 0);
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormFieldSubject
                  id="pcds2"
                  name="pcds2"
                  type="number"
                  margin="normal"
                  variant="standard"
                  fullWidth
                  placeholder="Pourcentage ds2"
                  disabled={!checkedPcDs2V}
                  value={pcds2V}
                  success={pcds2V >= 0}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Grid container spacing={1} mt={3}>
                  <Grid item xs={6} sm={6}>
                    <SoftTypography
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      CC
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Checkbox
                      defaultChecked={checkedPcCcV}
                      onChange={() => {
                        setFieldValue("checkedPcCc", !checkedPcCcV);
                        setFieldValue("pccc", 0);
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormFieldSubject
                  id="pccc"
                  name="pccc"
                  type="number"
                  margin="normal"
                  variant="standard"
                  fullWidth
                  placeholder="pourcentage contrôle continue"
                  disabled={!checkedPcCcV}
                  value={pcccV}
                  success={pcccV >= 0}
                />
              </Grid>
            </>
          )}
          {values.categorie === "Matiere" && values.checkedTd && (
            <>
              <Grid item xs={12} sm={2}>
                <Grid container spacing={1} mt={3}>
                  <Grid item xs={6} sm={6}>
                    <SoftTypography
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      TD
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Checkbox
                      defaultChecked={checkedPcTdV}
                      disabled
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormFieldSubject
                  id="pctd"
                  name="pctd"
                  type="number"
                  margin="normal"
                  variant="standard"
                  fullWidth
                  placeholder="Pourcentage td"
                  disabled={!checkedPcTdV}
                  value={pctdV}
                  success={pctdV >= 0}
                />
              </Grid>
              {values.checkedTd && !values.checkedPcTp && !checkedPcMpV && (
                <>
                  <Grid item xs={12} sm={2}></Grid>
                  <Grid item xs={12} sm={4}></Grid>
                </>
              )}
            </>
          )}
          {values.categorie === "Matiere" && values.checkedTp && (
            <>
              <Grid item xs={12} sm={2}>
                <Grid container spacing={1} mt={3}>
                  <Grid item xs={6} sm={6}>
                    <SoftTypography
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      TP
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Checkbox
                      defaultChecked={checkedPcTpV}
                      disabled
                      //onChange={() => setFieldValue("checkedPcTp",!checkedPcTpV)}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormFieldSubject
                  id="pctp"
                  name="pctp"
                  type="number"
                  margin="normal"
                  variant="standard"
                  fullWidth
                  placeholder="Pourcentage tp"
                  disabled={!checkedPcTpV}
                  value={pctpV}
                  success={pctpV >= 0}
                />
              </Grid>
              {values.checkedTp && !checkedPcTdV && !checkedPcMpV && (
                <>
                  <Grid item xs={12} sm={2}></Grid>
                  <Grid item xs={12} sm={4}></Grid>
                </>
              )}
            </>
          )}

          {values.categorie === "Matiere" && values.checkedMp && (
            <>
              <Grid item xs={12} sm={2}>
                <Grid container spacing={1} mt={3}>
                  <Grid item xs={6} sm={6}>
                    <SoftTypography
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      Mini projet
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Checkbox
                      defaultChecked={checkedPcMpV}
                      disabled
                      //onChange={() => setFieldValue("checkedPcMp",!checkedPcMpV)}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormFieldSubject
                  id="pcmp"
                  name="pcmp"
                  type="number"
                  margin="normal"
                  variant="standard"
                  fullWidth
                  placeholder="Pourcentage mini projet"
                  disabled={!checkedPcMpV}
                  value={pcmpV}
                  success={pcmpV >= 0}
                />
              </Grid>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={4}></Grid>
            </>
          )}
        </Grid>
      </Grid>
    </SoftBox>
  );
}
CategoryInfoUpdate.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
