import { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import {
  CircularProgress,
  Slide,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import api from "utils/axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stack from "@mui/material/Stack";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import AddParcours from "./AddParcours";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import ParcoursActions from "./components/ParcoursActions";

function ParcoursTable() {
  const [loading, setLoading] = useState(false);
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsLevel, setOptionsLevel] = useState([]);
  const [optionsFilieres, setOptionsFilieres] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const myRef = useRef(null);
  const [selectedFiliere, setSelectedFiliere] = useState("");
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [niveau, setNiveau] = useState("");
  const [speciality, setSpeciality] = useState(null);
  const [tronc, setTronc] = useState(null);
  const [errorMessageLevel, setErrorMessageLevel] = useState();
  useEffect(() => {
    document.title = "parcours";
  }, []);

  const handleFetch = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/faculty/get-parcours-by-filtrage/${facility?.scholarityConfigId}`
      );
      setData(response?.data);
    } catch (error) {
       throw error
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetch();
  }, []);
  useEffect(() => {
    if (detailsData && detailsData.parcours && detailsData.parcours[0]) {
      setSpeciality(detailsData?.parcours[0]?.speciality?._id);
      setTronc(detailsData?.parcours[0]?.tronc?._id);
      setSelectedFiliere(
        detailsData &&
          detailsData.parcours &&
          detailsData.parcours[0] &&
          detailsData?.parcours[0]?.niveau?.filiere
      );
      api.get(`/faculty/all-filiere/${facility?.scholarityConfigId}`).then((res) => {
        setOptionsFilieres(
          res.data.map((item) => ({
            value: item._id,
            label: item.name,
          }))
        );
      });
      api
        .get(`/faculty/get-levels-by-filiere/${selectedFiliere}`)
        .then((res) => {
          setOptionsLevel(
            res?.data?.map((item) => ({
              value: item._id,
              label: item.name,
            }))
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setError("Resource not found:", error.response.data);
            // Handle 404 error gracefully (e.g., display a message to the user)
          } else {
            setError("API request failed:", error.message);
            // Handle other errors
          }
        });
    }
  }, [detailsData, open]);
  const handleClose = () => {
    setOpen(false);
    setNiveau("");
    setSelectedLevel("");
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddNiveau = async () => {
    try {
      setLoading(true);
      if (niveau === "") {
        setErrorMessageLevel("Le niveau doit être sélectionné.");
      } else {
        const requestData = [{ tronc:tronc, speciality: speciality, niveaux: [niveau] }];
        await api.post(
          `/faculty/create-parcours?scholarityConfigId=${facility?.scholarityConfigId}`,
          requestData
        );
        setOpen(true);
        Swal.fire("Success!", "Le niveau ajoutée avec succès", "success").then(() => {
          setErrorMessageLevel("");
          setNiveau("");
          setSelectedLevel("");
          handleFetch();
          setShowDetails(false);
          //navigate(0)
          setOpen(false);
        });
      }
    } catch (error) {
      //setError(error.response.data.message);
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddNiveau();
    }
  };
  const getLast6Characters = (code) => code.slice(-6);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          {!showDetails && (
            <SoftBox
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent={{ xs: "flex-start", sm: "space-between" }}
              alignItems={{ xs: "flex-start", sm: "center" }}
              p={3}
            >
              <SoftBox lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Liste des parcours
                </SoftTypography>
              </SoftBox>
              <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
                <SoftButton variant="outlined" color="purple" size="small" mr={2}>
                  export
                </SoftButton>
                <AddParcours handleFetch={handleFetch} />
              </Stack>
            </SoftBox>
          )}
          {loading ? (
            <SoftBox sx={{ mt: 13, textAlign: "center" }}>
              <CircularProgress />
            </SoftBox>
          ) : (
            <div>
              <Slide direction="right" in={!showDetails} mountOnEnter unmountOnExit>
                <div ref={myRef}>
                  {!showDetails && (
                    <DataTable
                      table={{
                        columns: [                          
                          { Header: "Nom du parcours ", accessor: "parcoursName", width: "20%" },
                          { Header: "niveaux", accessor: "niveaux", width: "15%" },
                          { Header: "action", accessor: "action", width: "10%" },
                        ],
                        rows:
                          data &&
                          Object.values(data)?.map((parcours) => ({
                            parcoursName: parcours.name,
                            niveaux: parcours?.parcours?.length,
                            action: (
                              <ParcoursActions
                                key={parcours?._id}
                                par={parcours}
                                setDetailsData={setDetailsData}
                                setShowDetails={setShowDetails}
                                handleFetch={handleFetch}
                                deleteUpdate
                                deleteDelete
                              />
                            ),
                          })),
                      }}
                      entriesPerPage={
                        afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false
                      }
                      canSearch={afficheFilter}
                    />
                  )}
                </div>
              </Slide>
              {showDetails && (
                <>
                  <ArrowBackIcon
                    onClick={() => setShowDetails(false)}
                    sx={{ cursor: "pointer", lineHeight: 0, mt: 3, ml: 2 }}
                  />
                  <Slide direction="left" in={showDetails} mountOnEnter unmountOnExit>
                    <div>
                      <SoftBox lineHeight={1} display="flex" justifyContent="space-between" p={2}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Nom du parcours : {detailsData?.name}
                        </SoftTypography>
                        <SoftButton
                          id="add"
                          onClick={handleClickOpen}
                          variant="gradient"
                          color="purple"
                          size="small"
                          mb={{ xs: 2, sm: 0 }}
                        >
                          +Ajouter niveau
                        </SoftButton>
                      </SoftBox>
                      <DataTable
                        table={{
                          columns: [
                            {
                              Header: "code",
                              accessor: (row) => getLast6Characters(row.code),
                              width: "15%",
                            },
                            { Header: "nom du Niveau", accessor: "niveauName", width: "20%" },
                            { Header: "nombre des modules", accessor: "modules", width: "15%" },
                            { Header: "action", accessor: "action", width: "10%" },                         
                          ],
                          rows: detailsData
                            ? detailsData?.parcours?.map((parcou) => ({
                                code: parcou?.code,
                                niveauName: parcou?.niveau?.name,
                                modules: parcou?.modules.length,
                                action: (
                                  <ParcoursActions
                                    parcours={parcou}
                                    handleFetch={handleFetch}
                                    deleteEyes
                                  />
                                ),
                              }))
                            : [],
                        }}
                        entriesPerPage={
                          afficheFilter
                            ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }
                            : false
                        }
                        canSearch={afficheFilter}
                      />
                    </div>
                  </Slide>
                </>
              )}
            </div>
          )}
        </Card>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          onKeyDown={handleKeyPress}
        >
          <DialogTitle>Ajouter un niveau</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              "&:hover": {
                backgroundColor: (theme) => theme.palette.grey[200],
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <DialogContentText>
              Vous devez saisir le nouveau niveau de votre parcours.
            </DialogContentText>
            <Grid sx={{ mt: 1 }}>
              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "flex-start",
                }}
              >
                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Filière*
                  </SoftTypography>
                  <Autocomplete
                    options={optionsFilieres}
                    title="Sélectionner une filière"
                    getOptionLabel={(option) => option.label || ""}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    clearIcon={null}
                    disabled={true}
                    defaultValue={
                      optionsFilieres.find((option) => option.value === selectedFiliere) || null
                    }               
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Veuillez
                          sélectionner la
                          filière "
                      />
                    )}
                  />
                </Grid>  
                 <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Nom du tronc commun*
                  </SoftTypography>
                  <Autocomplete
                    options={options}
                    title="Sélectionner du tronc commun"
                    getOptionLabel={(option) => option.label || ""}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    clearIcon={null}
                    disabled={true}                 
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder={detailsData?.parcours[0]?.tronc?.name}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Nom spécialité*
                  </SoftTypography>
                  <Autocomplete
                    options={options}
                    title="Sélectionner Spécialité"
                    getOptionLabel={(option) => option.label || ""}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    clearIcon={null}
                    disabled={true}                 
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder={detailsData?.parcours[0]?.speciality?.name}
                      />
                    )}
                  />
                </Grid>
             
                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Niveau*
                  </SoftTypography>
                  <Autocomplete
                    title="Select niveau"
                    clearIcon={null}
                    options={optionsLevel}
                    filterSelectedOptions
                    value={optionsLevel.find((option) => option.value === selectedLevel) || null}
                    onChange={(e, newValue) => {
                      if (newValue !== null && newValue !== undefined) {
                        setNiveau(newValue.value);
                        setSelectedLevel(newValue.value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        value={niveau}
                        placeholder="Veuillez
                         sélectionner
                         le niveau de
                         votre parcours"
                      />
                    )}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                  />
                  {niveau === "" && (
                    <Typography mt={0.75} variant="caption" color="error">
                      {errorMessageLevel}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
              <SoftButton
                onClick={handleClose}
                sx={{ mt: 2 }}
                variant="outlined"
                color="dark"
                size="small"
              >
                Annuler
              </SoftButton>
              {loading ? (
                <CircularProgress />
              ) : (
                <SoftButton
                  onClick={handleAddNiveau}
                  sx={{ mt: 2 }}
                  variant="gradient"
                  color="customized"
                  size="small"
                >
                  Ajouter
                </SoftButton>
              )}
            </SoftBox>
          </DialogActions>
        </Dialog>
      </SoftBox>
    </DashboardLayout>
  );
}

export default ParcoursTable;
