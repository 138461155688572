import { Description } from "@mui/icons-material";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  titre: Yup.string()
    .required("Entrez le nom de la Projet")
    .min(2, "La longueur minimale requise est de 2 caractères."),

  responsable: Yup.string()
    .required("Entrez le responsable de la Projet")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Entrez une adresse e-mail valide pour le responsable de la Projet"
    ),
});



export default validationSchema;
