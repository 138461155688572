import React, { useState } from 'react'
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Card, Grid } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftAvatar from 'components/SoftAvatar';
import SoftTypography from 'components/SoftTypography';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import Divider from '@mui/material/Divider';
import SoftButton from 'components/SoftButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SoftPagination from 'components/SoftPagination';

export default function Td() {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle
  const itemsPerPage = 8; // Nombre d'articles par page
  const totalPages = 2;
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Fonction pour passer à la page précédente
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const members = [team1, team2, team3, team4];
  const renderMembers = members.map((member, key) => {
    const memberKey = `member-${key}`;
    return (
      <SoftAvatar
        key={memberKey}
        src={member}
        alt="member profile"
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          "&:not(:first-of-type)": {
            ml: -1.25,
          },
          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    );
  });
  return (
    <SoftBox mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={8}>
          <>
            <Accordion expanded={isAccordionOpen}
              onChange={(event, expanded) => setIsAccordionOpen(expanded)} style={{ borderRadius: '12px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', marginBottom: '10px' }}>
              <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                <Grid container spacing={1}>
                  {/* Left section */}
                  <Grid container spacing={1} item xs={12} md={12} lg={4} textAlign={{ xs: 'center', md: 'left' }}>
                    <Grid item xs={12} md={4} container justifyContent="center" alignItems="center" textAlign="center">
                      <SoftAvatar
                        src={"https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                        }
                        variant="rounded"
                        size="xl"
                        shadow="sm"
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <SoftBox height="100%" lineHeight={1}>
                        <SoftTypography variant="h6" fontWeight="medium">
                          Exercice Algorithme
                        </SoftTypography>
                        <SoftTypography variant="button" color="text" fontWeight="medium">
                          Par : Nouri Mohamed
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  </Grid>
                  {/* Middle section */}
                  <Grid item xs={12} md={12} lg={6}>
                    {!isAccordionOpen && (<SoftTypography
                      fontWeight="400"
                      color="secondary"
                      fontSize="12px"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </SoftTypography>)}
                  </Grid>
                  {/* Right section */}
                  <Grid item xs={12} md={12} lg={2} display={"flex"} flexDirection={"row"}
                    alignItems={"flex-start"} justifyContent={{ xs: 'center', md: "flex-end" }}>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                      <SoftBox display="flex" flexDirection="column" lineHeight={0}>
                        <SoftTypography variant="button"
                          fontWeight="medium"
                          color="dark"
                          fontSize="12px">
                          Ajouter le 16-11-2023
                        </SoftTypography>
                        <SoftTypography variant="button" fontWeight="medium">
                          <SoftBox display="flex" flexDirection="column" justifyContent="center" lineHeight={0}>
                            <SoftBox display="flex" justifyContent={{ xs: 'center', md: "flex-end" }} sx={{ mr: 0.5 }}>
                              {renderMembers}
                            </SoftBox>
                          </SoftBox>
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Typography fontWeight="400"
                  color="dark"
                  fontSize="14px">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo
                  lobortis eget.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo
                  lobortis eget.
                </Typography>
                <Card style={{
                  padding: "15px", marginTop: "10px", maxWidth: "50%",
                  boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.1)'
                }}>
                  <Grid container spacing={1} item xs={12} md={12} lg={12} textAlign={{ xs: 'center', md: 'left' }}>
                    <Grid item xs={12} md={12} lg={4} container justifyContent="center" alignItems="center" textAlign="center">
                      <SoftAvatar
                        src={"https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                        }
                        variant="rounded"
                        size="xl"
                        shadow="sm"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={8}>
                      <SoftBox height="100%" lineHeight={1}>
                        <SoftTypography variant="h6" fontWeight="medium">
                          Exercice 1
                        </SoftTypography>
                        <SoftTypography fontWeight="400"
                          color="dark"
                          fontSize="14px">
                          PDF
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </Card>
                <Divider style={{ width: '100%', height: '2px', backgroundColor: 'black' }} />
                <Card style={{
                  padding: "15px", marginTop: "10px", maxWidth: "50%",
                  boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.1)'
                }}>
                  <Grid container spacing={1} item xs={12} md={12} lg={12} textAlign={{ xs: 'center', md: 'left' }}>
                    <Grid item xs={12} md={4} container justifyContent="center" alignItems="center" textAlign="center">
                      <SoftAvatar
                        src={"https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                        }
                        variant="rounded"
                        size="xl"
                        shadow="sm"
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <SoftBox height="100%" lineHeight={1}>
                        <SoftTypography variant="h6" fontWeight="medium">
                          Correction Exercice 1
                        </SoftTypography>
                        <SoftTypography fontWeight="400"
                          color="dark"
                          fontSize="14px">
                          PDF
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </Card>
                <SoftBox display="flex" m={2} justifyContent={{ xs: 'center', md: "flex-end" }}>
                  <SoftButton id="add" variant="gradient" color="dark">
                    <AddCircleOutlineIcon style={{ marginRight: '8px' }} /> Ajouter Fichier
                  </SoftButton>
                </SoftBox>
              </AccordionDetails>
            </Accordion>
          </>
        </Grid>
        <Grid item xs={12} md={6} lg={4} >
          <Card height="100%" >
            <SoftBox p={1.5}>
              <Grid container spacing={3} mb={2}>
                <Grid item xs={12} md={3}>
                  <SoftAvatar
                    src={"https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                    }
                    variant="rounded"
                    size="xl"
                    shadow="sm"
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <SoftBox height="100%" mt={0.5} lineHeight={1}>
                    <SoftTypography variant="h5" fontWeight="medium">
                      Ahmed Ben Ahmed
                    </SoftTypography>
                    <SoftTypography variant="button" fontWeight="medium"
                      color="secondary"
                      fontSize="12px">
                      Prof
                    </SoftTypography>
                    <SoftBox>
                      <SoftTypography variant="button" fontWeight="medium"
                        color="dark"
                        fontSize="12px">
                        Lorem ipsum dolor sit amet consectetur. Viverra nunc
                      </SoftTypography>
                    </SoftBox>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid #e0e0e0',
                        borderRadius: '8px',
                      }}
                    >
                      <InputBase
                        style={{ border: 'none', outline: 'none' }}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Répondre a la commentaire"
                      />
                      <IconButton color="secondary" aria-label="directions">
                        <SendIcon style={{ color: 'blue' }} />
                      </IconButton>
                    </div>

                  </SoftBox>
                </Grid>
              </Grid>
              <Grid container spacing={3} mb={2}>
                <Grid item xs={12} md={3}>
                  <SoftAvatar
                    src={"https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                    }
                    variant="rounded"
                    size="xl"
                    shadow="sm"
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <SoftBox height="100%" mt={0.5} lineHeight={1}>
                    <SoftTypography variant="h5" fontWeight="medium">
                      Ahmed Ben Ahmed
                    </SoftTypography>
                    <SoftTypography variant="button" fontWeight="medium"
                      color="secondary"
                      fontSize="12px">
                      Prof
                    </SoftTypography>
                    <SoftBox>
                      <SoftTypography variant="button" fontWeight="medium"
                        color="dark"
                        fontSize="12px">
                        Lorem ipsum dolor sit amet consectetur. Viverra nunc
                      </SoftTypography>
                    </SoftBox>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid #e0e0e0',
                        borderRadius: '8px',
                      }}
                    >
                      <InputBase
                        style={{ border: 'none', outline: 'none' }}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Répondre a la commentaire"
                      />
                      <IconButton color="secondary" aria-label="directions">
                        <SendIcon style={{ color: 'blue' }} />
                      </IconButton>
                    </div>

                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
      {/*   <SoftPagination>
          {currentPage > 1 && (
            <SoftPagination item onClick={prevPage}><ArrowBackIosIcon /></SoftPagination>
          )}
          {Array.from({ length: totalPages }, (_, index) => (
            <SoftPagination item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </SoftPagination >
          ))}
          {currentPage < totalPages && (
            <SoftPagination item onClick={nextPage}><ArrowForwardIosIcon /> </SoftPagination>
          )}
    </SoftPagination> */}
    </SoftBox>
  
  )
}
