const SelectInfo = {
  statut: [
    { value: "A faire", label: "A faire" },
    { value: "En cours", label: "En cours" },
    { value: "Terminer", label: "Terminer" },
  ],
  priorite: [
    { value: "Haute", label: "Haute" },
    { value: "Moyenne", label: "Moyenne" },
    { value: "Faible", label: "Faible" },
  ],
  entreprise: [
    {
      value: "Entreprise Existant",
      label: "Entreprise Existant",
      options: [
        {
          value: "mohamed@proservices-training-company.com",
          label: "mohamed@proservices-training-company.com",
        },
        {
          value: "majouli@proservices-training-company.com",
          label: "majouli@proservices-training-company.com",
        },
        {
          value: "hamza.ramy@proservices-training-company.com",
          label: "hamza.ramy@proservices-training-company.com",
        },
      ],
    },
    {
      value: "Entreprise Potentiel",
      label: "Entreprise Potentiel",
      options: [
        {
          value: "mohamed.chaouech@proservices-training-company.com",
          label: "mohamed.chaouech@proservices-training-company.com",
        },
        {
          value: "majouli.baha@proservices-training-company.com",
          label: "majouli.baha@proservices-training-company.com",
        },
        {
          value: "hamza.ramy.ing@proservices-training-company.com",
          label: "hamza.ramy.ing@proservices-training-company.com",
        },
      ],
    },
  ],
  statutEntreprise: [
    { value: "Neutral", label: "Neutral" },
    { value: "Positive", label: "Positive" },
    { value: "Négative", label: "Négative" },
  ],
};
export default SelectInfo;
