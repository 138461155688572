import { useState, useRef, useEffect } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Alert from '@mui/material/Alert';
import PropTypes from "prop-types";
import CircularProgress from '@mui/material/CircularProgress';

import api from "utils/axios.js";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Settings page components
import FormField from "pages/billing-settings/components/FormField";

const Preferences = ({ titles, frStrings, enStrings, booleans, setConfigs, configsId, }) => {

  const [inputsValidity, setInputsValidity] = useState({isTvaValid: true, noneTvaFRTextValidity: true, noneTvaENTextValidity: true, customLateInterestsValidity: true});
  const { current: updates } = useRef({});
  const [isLoad, setIsLoad] = useState(false);
  const [alert, setAlert] = useState({show: false, message: "", type:""});


  useEffect(() => {
    frStrings.forEach( ({key, defaultValue}) => { updates[key] = defaultValue; });
    enStrings.forEach( ({key, defaultValue}) => { updates[key] = defaultValue; });
    booleans.forEach( ({key, defaultValue}) => { updates[key] = defaultValue; });
  }, [frStrings, enStrings, booleans]);

  const clearAlert = (ms) => {
    setTimeout(() => {
      setAlert({ show: false, message: "", typ: ""});
    }, ms);
  };

  const handleSubmitionButton = () => {
    setIsLoad(true);
    api.patch(`/update-invoices-config/${configsId}`, updates).then((response) => {
      setConfigs(prevState => {
        const newState = {...prevState};
        for (const [preference, value] of Object.entries(updates)) {
          const [outerKey, innerKey] = preference.split(".");
          newState[outerKey][innerKey] = value;
        }
        return newState;
      });
      setAlert({ show: true, message: "la configuration des factures a été mise à jour avec succès", type: "success"});
    }).catch((error) => {
      console.debug(error);
      setAlert({ show: true, message: error.message, type: "error"});
    }).finally(() => {
      setIsLoad(false);
      clearAlert(10_000);
    });
  };

  return (
    <Card id="preferences" sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h4"> {titles[0]} </SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>{ <SoftTypography variant="h5"> {titles[1]} </SoftTypography> }</Grid>
          {/* ---------------------------------------- booleans configs ------------------------------------------------------ */}
          <Grid item xs={12}>
            <Grid container>
              {
                booleans.map(input => (
                  <Grid key={input.key} item xs={12} sm={6}>
                    <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    width={{ xs: "100%", sm: "auto" }}
                    mt={{ xs: 1, sm: 0 }}
                    >
                      <SoftBox mr={1}>
                        <Switch defaultChecked={input.defaultValue} onChange={(e) => { updates[input.key] = (e.target.checked)} } />
                      </SoftBox>
                      <SoftBox lineHeight={0} mx={1}>
                        <SoftTypography variant="button" color="text" fontWeight="regular">
                          {input.label}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
          {/* ---------------------------------------- fr strings + en strings configs ------------------------------------------------------ */}
          {
            frStrings.map(input => (
              <Grid key={input.key} item xs={12} sm={6}>
                <FormField
                  label={input.label}
                  placeholder={input.label}
                  defaultValue={input.defaultValue}
                  onChange = {(e) => updates[input.key] = (e.target.value)}
                />
              </Grid>
            ))
          }
          <Grid item xs={12}>{<SoftTypography variant="h5"> {titles[2]} </SoftTypography>}</Grid>
          {
            enStrings.map(input => (
              <Grid key={input.key} item xs={12} sm={6}>
                <FormField
                  label={input.label}
                  placeholder={input.label}
                  defaultValue={input.defaultValue}
                  onChange = { (e) => updates[input.key] = (e.target.value) }
                />
              </Grid>
            ))
          }
            
          {/* --------------------------------------------------------------------------------------------------------------------- alert message + submit button */}
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                { alert.show && <Alert severity={alert.type}> {alert.message} </Alert> }
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox
                  display="flex"
                  justifyContent="end"
                >
                  {
                    isLoad
                    ? <CircularProgress/>
                    : <SoftButton
                      variant="gradient"
                      color="customized"
                      size="medium"
                      onClick={handleSubmitionButton}
                    >
                      Enregistrer
                    </SoftButton>
                  }
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  )
};

Preferences.defaultProps = {
  titles: new Array(3).fill(""),
  frStrings: [{ key: "", label: "", defaultValue: "" }],
  enStrings: [{ key: "", label: "", defaultValue: "" }],
  booleans: [{ key: "", label: "", defaultValue: false}],
  setConfigs: () => {},
  configsId: ""
};

// Typechecking props for BaseLayout
Preferences.propTypes = {
  titles: PropTypes.array,
  frStrings: PropTypes.array,
  enStrings: PropTypes.array,
  booleans: PropTypes.array,
  setConfigs: PropTypes.func,
  configsId: PropTypes.string,
};

export default Preferences;