import { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { Box, CircularProgress, Slide, Typography } from "@mui/material";
import api from "utils/axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stack from "@mui/material/Stack";
import AddPeriode from "./AddPeriode";
import PeriodeActions from "./components/PeriodeActions";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";
import SoftSelect from "components/SoftSelect";

function PeriodeTable() {
  const [loading, setLoading] = useState(false);
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [data, setData] = useState([]);
  const [optionsDepartement, setOptionsDepartement] = useState([]);
  const [optionsFiliere, setOptionsFiliere] = useState([]);
  const [optionsParcours, setOptionsParcours] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const myRef = useRef(null);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const facility = JSON.parse(localStorage.getItem("facility"));
  useEffect(() => {
    // change page title in the header
    document.title = "période";
  }, []);

  const handleFetch = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/faculty/all-periodes/${facility.scholarityConfigId}`);
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetch();
  }, []);

  const getLast6Characters = (code) => code?.slice(-6);
  function formatPeriod(startDateStr, endDateStr) {
    // Convertir les chaînes de date en objets Date
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    // Extraire les années des dates
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    // Formatage de la période
    const formattedPeriod = `${startYear}-${endYear}`;
    return formattedPeriod;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "flex-start", sm: "space-between" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Liste des périodes
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
              <SoftButton variant="outlined" color="purple" size="small" mr={2}>
                export
              </SoftButton>
              <AddPeriode handleFetch={handleFetch} />
            </Stack>
          </SoftBox>
          {loading ? (
            <SoftBox sx={{ mt: 13, textAlign: "center" }}>
              <CircularProgress />
            </SoftBox>
          ) : (
            <div>
              <Slide direction="right" in={!showDetails} mountOnEnter unmountOnExit>
                <div ref={myRef}>
                  {!showDetails && (
                    <>
                      <SoftBox p={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={0} lg={4.5}></Grid>
                          <Grid item xs={12} lg={2.5}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography size="small" component="label" variant="caption" fontWeight="bold">
                                Departement
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect
                              options={optionsDepartement}
                              size="small"                           
                            />
                          </Grid>
                          <Grid item xs={12} lg={2.5}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography size="small"  component="label" variant="caption" fontWeight="bold">
                                Filière
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect size="small"  options={optionsFiliere} />
                          </Grid>
                          <Grid item xs={12} lg={2.5}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography size="small" component="label" variant="caption" fontWeight="bold">
                                Parcours
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect size="small" options={optionsParcours} />
                          </Grid>
                        </Grid>
                      </SoftBox>
                      <DataTable
                        table={{
                          columns: [
                            { Header: "Nom de période", accessor: "name" },
                            { Header: "Date Début", accessor: (row) => formatDate(row.startDate) },
                            { Header: "Date fin", accessor: (row) => formatDate(row.endDate) },
                            { Header: "nombre des classes", accessor: "classes" },
                            { Header: "action", accessor: "action", width: "12%" },
                          ],
                          rows:
                            data &&
                            data?.map((periode) => ({
                              code: periode?.code,
                              name:periode?.name || "_",
                              startDate: periode?.startDate || "-",
                              endDate: periode?.endDate || "-",
                              classes:periode?.classes?.length,

                              action: (
                                <PeriodeActions
                                  key={periode.code}
                                  periode={periode}
                                  handleFetch={handleFetch}
                                  setShowDetails={setShowDetails}
                                  setDetailsData={setDetailsData}
                                />
                              ),
                            })),
                        }}
                        entriesPerPage={
                          afficheFilter
                            ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }
                            : false
                        }
                        canSearch={afficheFilter}
                      />
                    </>
                  )}
                </div>
              </Slide>

              {showDetails && (
                <>
                  <ArrowBackIcon
                    onClick={() => setShowDetails(false)}
                    sx={{ cursor: "pointer", lineHeight: 0, mt: 3, ml: 2 }}
                  />
                  <Slide direction="left" in={showDetails} mountOnEnter unmountOnExit>
                    <div>
                      <SoftBox lineHeight={1}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5, ml: 3 }}
                        >
                          {detailsData.name} : {formatPeriod(detailsData.startDate, detailsData.endDate) }
                        </SoftTypography>
                      </SoftBox>

                      <DataTable
                        table={{
                          columns: [
                            {
                              Header: "code classe",
                              accessor: (row) => getLast6Characters(row.code),
                              width: "30%",
                            },
                            { Header: "nom classe", accessor: "classeName" },
                          ],
                          rows:[]                     
                        }}
                        entriesPerPage={
                          afficheFilter
                            ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }
                            : false
                        }
                        canSearch={afficheFilter}
                      />
                    </div>
                  </Slide>
                </>
              )}
            </div>
          )}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}
export default PeriodeTable;
