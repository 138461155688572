import { useEffect, useState } from "react";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Stack from '@mui/material/Stack';
import {  Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "utils/axios";
import { useSoftUIController } from "context";
import AddSalle from "./AddSalle";
import SalleActions from "./components/SalleActions";

function SalleTable() {
  const [loading, setLoading] = useState(false);
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [data, setData] = useState([]);
  const facility = JSON.parse(localStorage.getItem("facility"));
  useEffect(()=> {
    // change page title in the header
    document.title = "salle";
  }, []);
  const handleFetch = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/faculty/all-salles/${facility.scholarityConfigId}`);
      setData(response?.data);
    } catch (error) {
     throw error;
    }finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetch();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
        <SoftBox
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }} 
            justifyContent={{ xs: 'flex-start', sm: 'space-between' }} 
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Liste des salles
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
              <SoftButton variant="outlined" color="purple" size="small" mr={2}>
                export
              </SoftButton>
              <AddSalle token={handleFetch} />     
              </Stack>
          </SoftBox>
          {loading ? (
            <SoftBox
              sx={{
                mt: 13,
                textAlign: "center",
              }}
            >
              <CircularProgress />
            </SoftBox>
          ) : (
            <DataTable
              table={{
                columns: [
                  { Header: "Nom de la salle", accessor: "salleName" },
                   { Header: "capacite", accessor: "capacite" },             
                  { Header: "Type", accessor: "typeName" }, 
                  { Header: "Nom de bloc", accessor: "blocName" },
                  { Header: "action", accessor: "action", width: "12%" },
                ],
                rows:
                  data &&
                  data.map((salle, index) => {
                    return {
                      salleName: salle.name,
                      capacite: salle?.capacity,                 
                      blocName: salle?.bloc?.name,                 
                      typeName: salle?.typeSalle?.name,                 
                       action: (
                      <SalleActions key={salle._id} salle={salle} handleFetch={handleFetch} />
                    ), 
                    };
                  }),
              }}
              entriesPerPage={
                afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false
              }
              canSearch={afficheFilter ? true : false}
            />
          )}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}
export default SalleTable;
