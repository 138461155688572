// page path => /billing/inscriptions

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import fileDownload from "js-file-download";
import { Link } from "react-router-dom";

import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import InvoiceCard from "pages/billings-display/components/InvoiceCard";
import SoftBox from "components/SoftBox";
import Grid from "@mui/material/Grid";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import LoadingAnimation from "components/LoadingAnimation";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import PlaceholderCard from "pages/billings-display/components/PlaceholderCard";

import api from "utils/axios";
import { useSoftUIController } from "context";


import DashboardNavbar from "layouts/DashboardNavbar";

const InscriptionList = ({ type }) => {
  const [{ user }, dispatch] = useSoftUIController();
  const [listInscription, setListInscription] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  const [premiumSupportMenu, setPremiumSupportMenu] = useState(null);
  const [designToolsMenu, setDesignToolsMenu] = useState(null);
  const [lookingGreatMenu, setLookingGreatMenu] = useState(null);
  const [developerFirstMenu, setDeveloperFirstMenu] = useState(null);
  const [popUpAlert, setPopUpAlert] = useState({show: false, message: "", type: ""});

  // TeamProfileCard dropdown menu handlers
  const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget);
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const openPremiumSupportMenu = (event) => setPremiumSupportMenu(event.currentTarget);
  const closePremiumSupportMenu = () => setPremiumSupportMenu(null);
  const openDesignToolsMenu = (event) => setDesignToolsMenu(event.currentTarget);
  const closeDesignToolsMenu = () => setDesignToolsMenu(null);
  const openLookingGreatMenu = (event) => setLookingGreatMenu(event.currentTarget);
  const closeLookingGreatMenu = () => setLookingGreatMenu(null);
  const openDeveloperFirstMenu = (event) => setDeveloperFirstMenu(event.currentTarget);
  const closeDeveloperFirstMenu = () => setDeveloperFirstMenu(null);

  useEffect(() => {
    
    document.title = type === 'insc' ? 'Inscriptions' : 'Préinscription';

    const enterpriseId = user?.info?.enterpriseId || user?.info?.enterpriseInfo?._id || user?.info?.enterprise;
    if (!enterpriseId) console.warn(`nullish value for the enterpriseId`, {enterpriseId, user});

    api.get(`/get-facture-by-enterprise-id/${enterpriseId}?type=${type}`).then((res) => {
      setListInscription(res.data);
    }).catch(error => {
      // TODO: show network error message...
      console.debug(error);
    }).finally(() => {
      setIsLoading(false);
    });

  }, [type]);

  const downloadHandler = (id, fileName) => {
    api.get(`get-facture-by-id/${id}?resType=pdf&type=inscription`, {
      responseType: "blob"
    }).then(res => {
      const { data, headers } = res;
      const extension = (typeof headers['content-type'] === "string") && headers['content-type'].split('/')[1];
      fileDownload(data, `${fileName}.${extension}`);
    }).catch(error => {
      console.debug("error =>", error);
      setPopUpAlert({show: true, message: "Désolé, une erreur s'est produite lors du téléchargement de votre fichier. Veuillez réessayer plus tard.", type: "error"});
    });

  };
  
  const handleAlertClose = (id) => setPopUpAlert({show: false, message: "", type: ""});

  const preinscToInsc = (id) => {
    api.post(`pre-inscription-to-inscription/${id}`).then((res) => {
      // filter out the pre-inscription with this id
      setListInscription((prevState) => {
        const filtredList = prevState.filter((preInsc) => (preInsc._id !== id));
        return filtredList;
      });
    }).catch(error => {
      // show an error alert...
      setPopUpAlert({show: true, message: "Désolé, une erreur s'est produite lors du transformation de votre préinscription. Veuillez réessayer plus tard.", type: "error"});
      console.debug(error);
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        isLoading ? <LoadingAnimation /> : (
          <SoftBox pt={5} pb={2}>
            <Dialog open={popUpAlert.show} aria-labelledby="alert-dialog-title" onBackdropClick={handleAlertClose}>
              <DialogTitle id="alert-dialog-title">
                <Alert variant="outlined" severity="error">
                  {popUpAlert.message}
                </Alert>
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleAlertClose} autoFocus>
                  Fermer
                </Button>
              </DialogActions>
            </Dialog>
            <Grid container>
              <Grid item xs={12} md={8}>
                <SoftBox mb={1}>
                  <SoftTypography variant="h5">{`${type === "insc" ? "Inscription" : "Préinscription" } (${listInscription.length})`}</SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
            <SoftBox mt={{ xs: 1, lg: 3 }} mb={1}>
              <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                  <Link to={type === 'insc' ? '/billing/new-insc' : '/billing/new-pre-insc'}>
                    <PlaceholderCard title = {{variant: "h6", text: `Nouvelle ${type === 'insc' ? "inscription" : "préinscription"}`}} />
                  </Link>
                  </Grid>
                {
                  listInscription.map((item, index) => {
                    return (
                      <Grid key={index} item xs={12} md={6} lg={4}>
                        <InvoiceCard
                          key={index}
                          image={logoSlack}
                          title={item.numeration}
                          recipient={item.recipient?.name}
                          total = {item.total[item.total.length - 1]}
                          dateTime = {new Date(item.createdAt).toLocaleDateString('fr')}
                          onDownload = {downloadHandler.bind(null, item._id, item.numeration)}
                          preinscToInsc = { type === 'pre-insc' ? preinscToInsc.bind(null, item._id) : undefined }
                          invoiceId={item._id}
                        />
                      </Grid>
                    )
                  }
                )
                }
              </Grid>
            </SoftBox>
          </SoftBox>
        )
      }
    </DashboardLayout>
  )
}

InscriptionList.defaultProps = {
  type: "",
}

InscriptionList.propTypes = {
  type: PropTypes.oneOf(["pre-insc", "insc"])
}

export default InscriptionList;
