import { Card, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useSoftUIController } from "context";
import Cube from "examples/Icons/Cube";
import Shop from "examples/Icons/Shop";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "utils/axios";
import UserInfo from "./components/UserInfo";
import ChangePassword from "components/shared-users/updateUser/ChangePassword";
import EnterpriseInfo from "pages/enterprise/components/update-enterprise-components/EnterpriseInfo";

const UpdateUser = () => {
  const [controller, dispatch] = useSoftUIController();
  const [enterprise, setEnterprise] = useState({});
  const {id} = useParams();
  const [user,setUser] = useState({});
  
  const [selectedItem, setSelectedItem] = useState(0);
    const handleItemClick = (index) => {
        setSelectedItem(index);
      };
      const handleFetchUserById = async (idToFetch) => {
        try {
          if (idToFetch) {
            const response = await api.get(`/get-user/${idToFetch}`);
            const fetchUser = response.data;
            setUser(fetchUser);
          }
        } catch (error) {
          console.log(error);
        }
      };
      
        const handleFetchEnterpriseById = async (idToFetch) => {
          try {
            if (idToFetch) {
              const response = await api.get(`/get-enterprise/${idToFetch}`);
              const fetchedEnterprise = response.data;
              setEnterprise(fetchedEnterprise);
            }
          } catch (error) {
            console.log(error);
          }
        };
  
        useEffect(() => {
          if (user && controller?.user?.info?.enterprise !== undefined) {
            handleFetchEnterpriseById(controller?.user?.info?.enterprise);
          }
        }, [controller?.user?.info?.enterprise]);
      
      
      
     
      useEffect(() => {
        if (id !== undefined) {
            handleFetchUserById(id);
        }
      }, [id]);

      const sidenavItems = [
        { icon: <Shop />, label: "Information", href: "/update" },
        { icon: <Cube />, label: " mot de passe", href: "/change-password" },
      ];
      if (controller?.user?.info?.role === "company-admin") {
        const newItem = { icon: <Shop />, label: "Enterprise", href: "/enterprise" };
        sidenavItems.unshift(newItem);
      }
      const renderSidenavItems = sidenavItems.map(({ icon, label, href }, index) => {
        const itemKey = `item-${index}`;
        return (
          <SoftBox key={itemKey} component="li" onClick={() => handleItemClick(index)} pt={index === 0 ? 0 : 1}>
          <SoftTypography
           component="a"
           href={`#${href}`}
           variant="button"
           fontWeight="regular"
           color="text"
           textTransform="capitalize"
           sx={({
             borders: { borderRadius },
             functions: { pxToRem },
             palette: { light },
             transitions,
           }) => ({
             display: "flex",
             alignItems: "center",
             borderRadius: borderRadius.md,
             padding: `${pxToRem(10)} ${pxToRem(16)}`,
             transition: transitions.create("background-color", {
               easing: transitions.easing.easeInOut,
               duration: transitions.duration.shorter,
             }),
    
             "&:hover": {
               backgroundColor: light.main,
             },
             backgroundColor: index === selectedItem ? '#e0e0e0' : 'transparent',
           })}
         >
           <SoftBox mr={1.5} lineHeight={1}>
             {icon}
           </SoftBox>
           {label}
         </SoftTypography>
       </SoftBox>
     );
    });
  return (
    <DashboardLayout>
    <DashboardNavbar />
    <SoftBox mt={9}>
      <Grid container spacing={3}>
      <Grid item xs={12} lg={3}>
            {/* START SIDE NAV*/ }
          <Card
            sx={{
              borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
              position: "sticky",
              top: "1%",
            }}
          >
            <SoftBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={2}
              m={0}
              sx={{ listStyle: "none" }}
            >
              {renderSidenavItems}
            </SoftBox>
          </Card>
          {/* END SIDE NAV*/ }
          </Grid>
          
        <Grid item xs={12} lg={9}>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {/* <Header commercial={id && commercial} /> */}
              </Grid>
              <Grid item xs={12}>
              {selectedItem === 0 &&
    (controller?.user?.info?.role === "company-admin" ? <EnterpriseInfo enterprise={enterprise}getFunction={handleFetchEnterpriseById}/> : <UserInfo user={user} title="Information" />)
  }
  {selectedItem === 1  &&
    (controller?.user?.info?.role === "company-admin" ? <UserInfo user={user} />: null)}
  {sidenavItems.length === 3 ? selectedItem === 2 && <ChangePassword user={user} isProfile={true} />:selectedItem === 1 && <ChangePassword user={user} isProfile={true} />}
              </Grid>
            </Grid>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  </DashboardLayout>
  )
}

export default UpdateUser