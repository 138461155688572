import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import api from "utils/axios";
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CloseIcon from "@mui/icons-material/Close";

const SalleActions = ({ salle, handleFetch }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageCapacity, setErrorMessageCapacity] = useState("");
  const [optionsTypesSalle, setOptionsTypesSalle] = useState([]);
  const [optionsBlocs, setOptionsBlocs] = useState([]);
  const facility = JSON.parse(localStorage.getItem("facility"));

  useEffect(() => {
    api.get(`/faculty/all-type-salles/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsTypesSalle(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
        }))
      );
    });
    api.get(`/faculty/all-blocs/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsBlocs(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
        }))
      );
    });
  }, []);

  const [inputs, setInputs] = useState([
    {
      name: salle?.name,
      typeSalle: salle?.typeSalle?._id,
      capacity: salle?.capacity,
      bloc: salle?.bloc?._id,
    },
  ]);
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[0][name] = value;
    setInputs(newInputs);
  };
  const handleUpdate = async () => {
    try {
      setLoading(true);
      // Validate the inputs before submitting
      const hasInvalidInputs = inputs.some((input) => input.name.length < 2);
      if (hasInvalidInputs) {
        // Set the error message
        setErrorMessage("La longueur minimale requise est de 2 caractères.");
      }else if (inputs[0]?.capacity < 1  || !Number.isInteger(Number(inputs[0]?.capacity))) {   
        setErrorMessageCapacity("La capacité doit être supérieur ou égale a 1 et n'accepte pas les virgules.");
      } else {
        await api.put(`/faculty/update-salle/${salle._id}`, inputs[0]);
        Swal.fire("Succès", "la salle a été modifié avec succès.", "success").then(() => {
          setErrorMessage("");
          setInputs([{  
            name: "",
            typeSalle: "",
            capacity: "",
            bloc: "",}]);
          handleFetch();
          setOpen(false);
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Error", "la salle n'a pas été modifié", "error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setInputs([
      {
        name: salle?.name,
        typeSalle: salle?.typeSalle?._id,
        capacity: salle?.capacity,
        bloc: salle?.bloc?._id,
      },
    ]);
  };
  const handleDelete = async () => {
    try {
      setLoading(true);
      const newSwal = Swal.mixin({
        customClass: {
          confirmButton: "button button-success",
          cancelButton: "button button-error",
        },
        buttonsStyling: false,
      });
      newSwal
        .fire({
          title: "Êtes-vous sûr(e) ?",
          text: "Vous voulez supprimer cette salle ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui, supprimer!",
          cancelButtonText: "Annuler",
        })
        .then((resut) => {
          if (resut.isConfirmed) {
            api
              .delete(`/faculty/delete-salle/${salle._id}`)
              .then(() => {
                newSwal
                  .fire("Succès", "La salle a été supprimée avec succès", "success")
                  .then(() => {
                    setOpen(false);
                    handleFetch();
                  });
              })
              .catch(() => {
                newSwal.fire("Erreur!", "La salle n'a pas été supprimée", "error");
              });
          }
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };
  return (
    <SoftBox display="flex" alignItems="center">
      {/*   <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Consulter" placement="left">
          <VisibilityIcon
            onClick={() => {
              setDetailsData(formation);
              setShowDetails(true);
            }}
          />
        </Tooltip>
      </SoftTypography> */}
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Modifier" placement="top">
            <Icon onClick={() => setOpen(true)} id="edit-enterprise-page">
              edit
            </Icon>
          </Tooltip>
          <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={handleClose}
            onKeyDown={handleKeyPress}
          >
            <DialogTitle>Modifier Salle</DialogTitle>
            <DialogContent>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.grey[200],
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContentText>
                Vous devez saisir le nom de votre salle afin de l&apos;ajouter.
              </DialogContentText>
              {inputs.map((input, index) => (
                <>
                  <Grid key={index} sx={{ mt: 1 }}>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "flex-start",
                      }}
                    >
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Nom de la salle
                        </SoftTypography>
                        <SoftInput
                          name="name"
                          margin="normal"
                          id="name"
                          label="salle"
                          type="text"
                          fullWidth
                          placeholder="Nom de la salle"
                          inputProps={{
                            minLength: 2,
                            maxLength: 30,
                          }}
                          autoFocus
                          variant="standard"
                          value={inputs[0].name}
                          onChange={(event) => handleInputChange("hello", event)}
                          error={input.name.length < 2}
                          success={input.name.length >= 2}
                        />
                        {input.name.length < 2 && (
                          <Typography mt={0.75} variant="caption" color="error">
                            {errorMessage}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Capacité*
                        </SoftTypography>
                        <SoftInput
                          id="capacity"
                          name="capacity"
                          type="number"
                          margin="normal"
                          variant="standard"
                          fullWidth
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          placeholder="capacité"
                          value={inputs[0].capacity}
                           error={input.error && (input.capacity < 1 || !Number.isInteger(Number(input.capacity)))}      
                      success={input.capacity >= 1  && Number.isInteger(Number(input.capacity))}
                      onChange={(event) => handleInputChange(index, event)} 
                        />
                          {(input.capacity ==="" || input.capacity < 1|| !Number.isInteger(Number(input.capacity))) && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorMessageCapacity}
                      </Typography>
                    )} 
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Type de la salle*
                        </SoftTypography>
                        <Autocomplete
                          id={`size-small-standard`}
                          options={optionsTypesSalle}
                          title="Select departement"
                          aria-required={true}
                          required={true}
                          getOptionLabel={(option) => option.label}
                          defaultValue={null}
                          clearIcon={null}
                          placeholder="Toutes les types"
                          value={optionsTypesSalle.find((option) => option.value === inputs[0]?.typeSalle) || null}
                          onChange={(event, newValue) => {
                            if (newValue !== null && newValue !== undefined) {
                              const newInputs = [...inputs];
                              newInputs[index].typeSalle = newValue.value;
                              setInputs(newInputs);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              required={true}
                              placeholder="Toutes les types"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Bloc*
                        </SoftTypography>
                        <Autocomplete
                          id={`size-small-standard`}
                          options={optionsBlocs}
                          title="Select bloc"
                          aria-required={true}
                          required={true}
                          getOptionLabel={(option) => option.label}
                          defaultValue={null}
                          clearIcon={null}
                          placeholder="Toutes les blocs"
                           value={optionsBlocs.find((option) => option.value === inputs[0]?.bloc) || null}
                          onChange={(event, newValue) => {
                            if (newValue !== null && newValue !== undefined) {
                              const newInputs = [...inputs];
                              newInputs[index].bloc = newValue.value;
                              setInputs(newInputs);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              required={true}
                              placeholder="Toutes les blocs"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ))}
            </DialogContent>
            <DialogActions>
              <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                <SoftButton onClick={handleClose} sx={{ mt: 2 }} variant="outlined" color="dark">
                  Annuler
                </SoftButton>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <SoftButton
                    onClick={handleUpdate}
                    sx={{ mt: 2 }}
                    variant="gradient"
                    color="customized"
                  >
                    Modifier
                  </SoftButton>
                )}
              </SoftBox>
            </DialogActions>
          </Dialog>
        </SoftTypography>
      </SoftBox>
      <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="supprimer" placement="left">
          <Icon id="delete-enterprise" onClick={handleDelete}>
            delete
          </Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
};

SalleActions.propTypes = {
  salle: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array, PropTypes.string])
    .isRequired,
  handleFetch: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
};
export default SalleActions;
