import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import {
  Autocomplete,
  Avatar,
  Chip,
  CircularProgress,
  Icon,
  ListItem,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import api from "utils/axios";
import PhoneInput from "react-phone-input-2";
import { getCountryAdd } from "utils/citysTn";
import { fetchData ,downloadFile} from "utils/citysTn";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
//Formik 
import { ErrorMessage, Formik } from "formik";
import validations from "pages/enterprise/schema/enterprise-schemas/EnterpriseValidation";
import EnterpriseFormField from "pages/enterprise/schema/enterprise-schemas/EnterpriseFormField";

function EnterpriseInfo({ enterprise, getFunction }) {

  const [info, setInfo] = useState({});
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companySecondaryPhone, setCompanySecondaryPhone] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [logo, setLogo] = useState(null);
  const [logoUpdate, setLogoUpdate] = useState(null);
  const [files, setFiles] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [logoToDelete, setLogoToDelete] = useState(null);

const initialValues = {
    companyName: enterprise.name || "",
    serialNumber:enterprise.serialNumber||"",
    email:enterprise.email||"",
    physiqualAddress:enterprise.physiqualAddress||"",
    postCode:enterprise.postCode ||"",
    phone:enterprise.phone||"",
    country:enterprise.country||"",
    city:enterprise.city||"",
  };
  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      const form = new FormData();
      const compani = {
        name: values.companyName,
        serialNumber: values.serialNumber,
        email: values.email,
        siteWeb: companyWebsite,
        phone: values.phone,
        postCode: values.postCode,
        physiqualAddress: values.physiqualAddress,
        secondaryPhone: companySecondaryPhone,
        description: companyDescription,
        logoUrlToDelete:logoToDelete,
        country: dataToSend.country ? dataToSend.country : values.country,
        city: dataToSend.city ? dataToSend.city : values.city,
        logoUrl:
          logoUpdate !== null ? logoUpdate : logo !== null && logoUpdate === null ? logo : null,
      };
      selectedOptions &&
      selectedOptions.length > 0 &&
      selectedOptions.forEach((item) => {
        form.append("company.files", item.value);
      }); 
      form.append("filesToDelete", JSON.stringify(filesToDelete)); 
      form.append("company", JSON.stringify(compani));
      form.append("company.logo", compani.logoUrl);
      const updateEnterprise = await api.patch(`/update-enterprise/${enterprise._id}`, form);
      if (updateEnterprise.status === 200) {
        Swal.fire("Succès", "Mise à jour entreprise réussie", "success").then(() => {
          setLoading(false)
          navigate(0);
        });
      }
     // getFunction();
    } catch (error) {
      setLoading(true)
      if (error.response && error.response.data && error.response.data.message) {
      Swal.fire("Error",error.response.data.message,"error").then(()=>navigate(0));
      } else {
        Swal.fire("Erreur","Une erreur s'est produite. Veuillez réessayer ultérieurement.","error").then(()=>navigate(0));
      }
    }
  };
  
  const navigate = useNavigate();
  const company = enterprise ? enterprise : {};
  useEffect(() => {
    // Initialize companyName when enterprise is available
    if (enterprise && company) {
      setCompanyWebsite(enterprise.siteWeb);
      setCompanySecondaryPhone(enterprise.secondaryPhone);
      setCompanyDescription(enterprise.description);
      setLogo(enterprise.logoUrl);
      setFiles(enterprise.files);
      setFiles(enterprise.files)
      setDataToSend({country:enterprise.country,city:enterprise.city})
    }
  }, [enterprise]);

  const countries = getCountryAdd(info, data);
  const [dataToSend, setDataToSend] = useState({
    country: "",
    city: "",
  });
  const handleCountryChange = (selectedOption) => {
    const selectedCountry = selectedOption.value;
    const cities = data.find((country) => country.country === selectedCountry).cities;

    setDataToSend({ ...dataToSend, country: selectedCountry });
    setCityOptions(cities.map((city) => ({ value: city, label: city })));
  };

  const handleCityChange = (selectedOption) => {
    setDataToSend({ ...dataToSend, city: selectedOption.value });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    const newOption = { value: file, label: file.name };

    // Check if the file is already selected
    const isSelected = selectedOptions.some((option) => option.label === newOption.label);

    if (!isSelected) {
      // If it's not selected, add it to both options and selectedOptions
      setOptions([...options, newOption]);
      setSelectedOptions([...selectedOptions, newOption]);
    }
  };

  const fileInputRef = useRef(null);
  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };
  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // Specify the allowed file extensions
      const allowedExtensions = ["jpg", "jpeg", "png", "gif", "jfif"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        // Display an error message if the file extension is not allowed
        setErrorMessage("Format de fichier non pris en charge. Veuillez sélectionner un fichier JPG, JPEG, PNG ou GIF.");
        setLogoUpdate(null); // Clear the logo
        return;
      }

      // Reset the error message if the file extension is allowed
      setErrorMessage("");
      setLogoUpdate(selectedFile);

      // Specify the maximum allowed file size in bytes (e.g., 2MB)
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      const maxWidth = 650; // Maximum width in pixels
      const maxHeight = 650; // Maximum height in pixels

      if (selectedFile.size > maxSize) {
        // Display an error message if the file size exceeds the limit
        setErrorMessage("La taille du fichier est trop grande. La taille maximale autorisée est de 2 Mo.");
        setLogoUpdate(null); // Clear the logo
      } else {
        // Check image dimensions
        const img = new Image();
        img.src = URL.createObjectURL(selectedFile);

        img.onload = () => {
          if (img.width > maxWidth || img.height > maxHeight) {
            // Display an error message if the image dimensions exceed the limit
            setErrorMessage(
              `Les dimensions de l'image sont trop grandes. Les dimensions maximales autorisées sont de ${maxWidth}x${maxHeight} pixels.`
            );
            setLogoUpdate(null); // Clear the logo
          }
        };
      }
    }
  };
  const handleDeleteLogo = () => {
    // Handle deleting the logo here
    // You can implement this function as needed
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setLogoUpdate(null);
    setLogo(null);
    setLogoToDelete(enterprise?.logoUrl);
    if (logoUpdate === null) {
    }
  };

  const handleDeleteFile = (fileToDelete) => {
    // Remove the file from the 'files' array
    const updatedFiles = files.filter(file => file !== fileToDelete);

    // Add the file to the 'filesToDelete' array
    setFilesToDelete([...filesToDelete, fileToDelete]);

    // Update the 'files' state
    setFiles(updatedFiles);
  };
  useEffect(() => {
    const fetchDataAsync = async () => {
      const data = await fetchData();
      setData(data);
    };
    fetchDataAsync();
  }, []);
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  return (
    <>
{
  Object.keys(enterprise).length > 0 && ( 
  <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validations[0]}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
     <Card id="basic-info" sx={{ overflow: "visible" }}>
        <SoftBox p={3}>
          <SoftBox sx={{ display: "flex", justifyContent: "space-between" }}>
            <SoftTypography variant="h5">Informations de l&apos;entreprise</SoftTypography>
          </SoftBox>
          <Grid item xs={12} sm={13} container justifyContent="center">
            <SoftBox position="relative" height="max-content" mx="auto">
              <Avatar
                onClick={handleAvatarClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{
                  filter: isHovered ? "blur(2px)" : "none",
                  cursor: "pointer",
                  width: 100,
                  height: 100,
                  borderRadius: "16px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={
                  logo && !logoUpdate 
                    ? `${process.env.REACT_APP_BASE_URL}/${logo}`
                    : logoUpdate
                    ? URL.createObjectURL(logoUpdate)
                    : ""
                }
                alt="profile picture"
                size="xl"
                variant="rounded"
              >
                Logo
              </Avatar>
              <SoftBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                <SoftButton
                  onClick={handleDeleteLogo}
                  variant="gradient"
                  color="error"
                  size="small"
                  iconOnly
                >
                  <Icon>delete</Icon>
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </Grid>
          <input
            type="file"
            // Specify the accepted file types
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={onFileChange}
            accept=".jpg, .jpeg, .png, .gif .jfif"
          />
          {errorMessage && (
            <Typography align="center" sx={{ mt: 2 }} variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
        </SoftBox>

        <SoftBox component="form" pb={3} px={3} justifyContent="center">     
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <EnterpriseFormField
                label="Nom*"
                placeholder="Nom"
                inputProps={{
                  minLength:2,
                  maxLength: 30
                }}
                defaultValue={values.companyName}
                value={values.companyName}
                onBlur={handleBlur}
                onChange={handleChange}
                name="companyName"
                error={!!touched.companyName && !!errors.companyName}
                success={values.companyName?.length > 0 && !errors.companyName}
              />
           
          </Grid>
          <Grid item xs={12} sm={6}>
              <EnterpriseFormField
                label="Matricule Fiscal*"
                inputProps={{
                 maxLength: 16
               }}
                placeholder="Matricule Fiscal"
                defaultValue={values.serialNumber}
                value={values.serialNumber}
                onBlur={handleBlur}
                onChange={handleChange}
                name="serialNumber"
                error={!!touched.serialNumber && !!errors.serialNumber}
                success={values.serialNumber?.length > 0 && !errors.serialNumber}
              />
            </Grid>
       </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}  mb={1}>
              <EnterpriseFormField
                label="Email*"
                inputProps={{
                   type: "email" ,
                  maxLength: 50
                }}
                placeholder="Email"
                defaultValue={values.email}
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                name="email"
                error={!!touched.email && !!errors.email}
                success={values.email?.length > 0 && !errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EnterpriseFormField
              name="companyWebsite"
                label="Site web"
                placeholder="Site web"
                defaultValue={companyWebsite}
                value={companyWebsite}
                inputProps={{
                  maxLength: 100
                }}
                onChange={(e) => setCompanyWebsite(e.target.value)}
              />
            </Grid>
            </Grid>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}  mb={1}>
              <SoftTypography variant="caption" fontWeight="bold" color="text">
                Numéro De Téléphone*
              </SoftTypography>
              <PhoneInput
                placeholder="+216 735 631 620"
                inputStyle={{ width: "100%" }}
                country={"tn"}
                enableSearch              
                defaultValue={values.phone}
                value={values.phone}
                onBlur={handleBlur}
                onChange={(value) => {
                  handleChange({ target: { name: 'phone', value } });
                }}
                name="phone"
                error={!!touched.phone && !!errors.phone}
                success={values.phone?.length > 0 && !errors.phone}
              />
                <SoftBox mt={0.75}>
              <SoftTypography component="div" variant="caption" color="error">
                <ErrorMessage name={"phone"} />
              </SoftTypography>
            </SoftBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftTypography variant="caption" fontWeight="bold" color="text">
              deuxième téléphone
              </SoftTypography>
              <PhoneInput
                inputStyle={{ width: "100%" }}
                placeholder="+40 735 631 620"
                country={"tn"}
                enableSearch
                value={companySecondaryPhone}
                onChange={(e) => setCompanySecondaryPhone(e)}
              />
            </Grid>
        </Grid>
        <Grid container spacing={3}  mb={2}>
            <Grid item xs={12} sm={6} >
              <SoftTypography  component="label" variant="caption" fontWeight="bold">
                Pays*
              </SoftTypography>
              <SoftSelect
              inputId="country"
                value={{value: dataToSend.country, label: dataToSend.country }}
                options={countries?.map((country) => ({ value: country, label: country }))}
                onChange={handleCountryChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}  >
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Ville*
              </SoftTypography>
              <SoftSelect inputId="city" options={cityOptions}  value={{ value: dataToSend.city, label: dataToSend.city }} onChange={handleCityChange} />
            </Grid>
          </Grid>
          <Grid container spacing={3} >
            <Grid item xs={12} md={6}>
              <EnterpriseFormField
                label="Adresse*"
                placeholder="Adresse"
                inputProps={{
                  maxLength: 50
                }}
                defaultValue={values.physiqualAddress}
                value={values.physiqualAddress}
                onBlur={handleBlur}
                onChange={handleChange}
                name="physiqualAddress"
                error={!!touched.physiqualAddress && !!errors.physiqualAddress}
                success={values.physiqualAddress?.length > 0 && !errors.physiqualAddress}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EnterpriseFormField
              type="number"
                label="Code Postal*"
                placeholder="Code Postal"
                //onChange={(e) => setCompanyZipCode(e.target.value)}
                defaultValue={values.postCode}
                value={values.postCode}
                onBlur={handleBlur}
                onChange={handleChange}
                name="postCode"
                error={!!touched.postCode && !!errors.postCode}
                success={values.postCode?.length > 0 && !errors.postCode}
              />
            </Grid>
            </Grid>
            <Grid container spacing={3}>
            <Grid sx={{ mt: 2, mr: "auto", display: "flow" }} item xs={12}>
              <SoftButton
                sx={{ mb: 1 }}
                component="label"
                onChange={handleFileUpload}
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
               Télécharger des fichiers
                <VisuallyHiddenInput type="file" />
              </SoftButton>
              <Autocomplete
                multiple
                id="tags-standard"
                placeholder="Télécharger des fichiers"
                options={options}
                value={selectedOptions}
                filterSelectedOptions
                freeSolo
                renderInput={(params) => (
                  <TextField {...params} variant="standard" placeholder="Télécharger des fichiers" />  
                )}
                onChange={(event, newValue) => {
                  setSelectedOptions(newValue);
                }}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.label === value.label}
              />  
            </Grid>  
            <Grid item xs={12} md={6}  style={{marginTop:"-8px"}}>
            {
                files && files.length > 0 ? files.map((file,index) =>(
                  <Grid
                  key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                <ListItem >
              <Chip size="small" label={file} onDelete={()=>handleDeleteFile(file)} />
              <BrowserUpdatedIcon
                      onClick={() => downloadFile(file.name ? file.name : file)}
                      fontSize="default"
                      sx={{ cursor: "pointer", marginLeft: 0.5 }} 
                    />
              </ListItem>
              </Grid>
                )) : (
                  <Typography sx={{ p: 1 }} variant="body2">
                  Aucun fichier
                </Typography>
                )
              }
              
            </Grid>
            </Grid>
            <Grid container spacing={3}> 
            <Grid item xs={12} md={12}  m={1}>
              <FormField
                multiline
                rows={5}
                label="Description"
                defaultValue={companyDescription}
                value={companyDescription}
                onChange={(e) => setCompanyDescription(e.target.value)}
                inputProps={{
                  maxLength: 250
                }}
              />
            </Grid>
            </Grid>
        </SoftBox>
        <Grid item xs={12} mb={2}>
          <SoftBox ml={3} sx={{ mr:3}} display={"flex"} justifyContent={"space-between"}>
              <SoftButton
                onClick={()=>navigate('/companies/companies')}
                sx={{ mr: 2 }}
                variant="outlined"
                color="dark"
                size="small"
              >
                Annuler
              </SoftButton>
      
            {loading ? (
              <CircularProgress />
            ) : (
              <SoftButton type="submit" size="small" color="customized" variant="gradient">
                Modifier
              </SoftButton>
            )}
          </SoftBox>
        </Grid>
      </Card> 
          </form>
        )}
      </Formik>
)
}
    </>
  );
}
EnterpriseInfo.propTypes = {
  enterprise: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
EnterpriseInfo.propTypes = {
  getFunction: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
export default EnterpriseInfo;
