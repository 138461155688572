import { Box, Card, CardContent, CircularProgress, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import React, { useEffect, useState } from "react";
import SoftButton from "components/SoftButton";
import { useNavigate, useParams } from "react-router-dom";
import api from "utils/axios";
import ProfileInfoCard from "components/shared-users/ProfileInfoCard";
import Header from "layouts/pages/profile/components/Header";
import SoftAvatar from "components/SoftAvatar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DataTable from "examples/Tables/DataTable";

export default function ResponsableView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState({});
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [afficheFilter, setAfficheFilter] = useState(true);

  const [imgsViewer, setImgsViewer] = useState(false);

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const handleFetchClientById = async (id) => {
    try {
      setLoading(true);
      if (id) {
        const response = await api.get(`/get-user/${id}`);
        const fetchClient = response.data;
        setLoading(false);
        setClient(fetchClient);
      }
    } catch (error) {
      setLoading(true);
      console.log(error);
    }
  };
  const handleFetchStudents = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/get-students-by-responsable-id/${id}`);
      setStudents(response?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };
  useEffect(() => {
    if (id !== undefined) {
      handleFetchStudents();
      handleFetchClientById(id);
    }
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="informations de profil" searchVisible={false} ExportVisible={false} />
      {loading ? (
        <SoftBox
          sx={{
            mt: 25,
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </SoftBox>
      ) : (
        <SoftBox mt={5} mb={3}>
          {client?.responsableType === "B2C" ? (
            <Card sx={{ height: "100%" }}>
              <SoftBox p={1.5} mt={2} ml={1.5}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item>
                    <SoftAvatar
                      src={
                        client?.imageUrl?.startsWith("https")
                          ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                          : `${process.env.REACT_APP_BASE_URL}/${client?.imageUrl}`
                      }
                      variant="rounded"
                      size="xl"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <SoftBox height="100%" mt={0.5} lineHeight={1}>
                      <SoftTypography variant="h5" fontWeight="medium">
                        {client?.firstName} {client?.lastName}
                      </SoftTypography>
                      <SoftTypography variant="button" color="text" fontWeight="medium">
                        {client?.role}
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} xl={6}>
                    <ProfileInfoCard
                      info={{
                        email: client?.email,
                        "Date de Naissance": client?.birthday,
                        genre: client?.gender,
                        cin: client?.cin,
                        "Numéro Téléphone": client?.phoneNumber
                          ? `+${client.phoneNumber}`
                          : "",
                        "Deuxième Téléphone": client?.secondaryPhoneNumber
                          ? `+${client.secondaryPhoneNumber}`
                          : "",
                        adresse: client?.address,
                        "code Postal": client?.zipCode,
                        pays: client?.country,
                        ville: client?.city,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} xl={6}>
                    <ProfileInfoCard
                      info={{
                        "Site Web": client?.siteWeb,
                        Occupation: client?.occupation,
                        passeport: client?.passeport,
                        "Nom De Banque": client?.bank,
                        rib: client?.rib,
                        "Numéro CNSS": client?.cnssNumber,
                        fichier: (
                          <SoftBox component="ul" ml={-3} pl={2}>
                            {client?.files?.map((file, index) => (
                              <SoftBox
                                component="li"
                                color="text"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                fontSize="1rem"
                                lineHeight={2}
                                key={index}
                                mb={2}
                                mt={1}
                                sx={{
                                  p: 0.75,
                                  borderBottom: `1px solid black`,
                                  backgroundColor: index % 2 === 0 ? "#F2F2F2" : "transparent",
                                }}
                              >
                                <SoftTypography variant="button" color="text" fontWeight="regular"
                                  sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "90%",
                                  }}>
                                  {file}
                                </SoftTypography>
                                <FileDownloadIcon
                                  onClick={() => downloadFile(file.name ? file.name : file)}
                                  fontSize="small"
                                  sx={{ cursor: "pointer" }}
                                />
                              </SoftBox>
                            ))}
                          </SoftBox>
                        ),
                        description: (client?.comment ?
                          (<Box variant="outlined">
                            <Box >
                              <SoftTypography
                                variant="button"
                                color="text"
                                fontWeight="regular"
                                sx={{ textAlign: "justify" }}
                              >
                                {client?.comment}
                              </SoftTypography>
                            </Box>
                          </Box>) : ""
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </SoftBox>
              <DataTable
                table={{
                  columns: [
                    { Header: "image", accessor: "imageUrl", width: "12%" },
                    { Header: "Nom", accessor: "firstName", width: "12%" },
                    { Header: "Prenom", accessor: "lastName", width: "12%" },
                    { Header: "email", accessor: "email", width: "15%" },
                    { Header: "télephone", accessor: "phone", width: "15%" },
                    { Header: "niveau", accessor: "niveau", width: "15%" },
                    { Header: "classe", accessor: "classe", width: "15%" },
                  ],
                  rows: students &&
                    students?.map((student, index) => {
                      return {
                        imageUrl: <SoftAvatar
                          src={
                            student?.imageUrl?.startsWith("https")
                              ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                              : `${process.env.REACT_APP_BASE_URL}/${student?.imageUrl}`
                          }
                          variant="rounded"
                          size="xl"
                          shadow="sm"
                        />
                        ,
                        firstName: student?.firstName,
                        lastName: student?.lastName,
                        email: student?.email,
                        phone: student?.phoneNumber
                          ? `+${student.phoneNumber}`
                          : "",
                        niveau: student?.classe?.level?.name,
                        classe: student?.classe?.name,
                      };
                    }),
                }}
                entriesPerPage={
                  afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false
                }
                canSearch={afficheFilter ? true : false}
              />
            </Card>
          ) : (
            <Card sx={{ height: "100%" }}>
              <SoftBox p={1.5} mt={2} ml={1.5}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item>
                    <SoftAvatar
                      src={
                        client?.imageUrl?.startsWith("https")
                          ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                          : `${process.env.REACT_APP_BASE_URL}/${client?.imageUrl}`
                      }
                      variant="rounded"
                      size="xl"
                      shadow="sm"
                    />
                  </Grid>
                  <Grid item>
                    <SoftBox height="100%" mt={0.5} lineHeight={1}>
                      <SoftTypography variant="h5" fontWeight="medium">
                        {client?.firstName} {client?.lastName}
                      </SoftTypography>
                      <SoftTypography variant="button" color="text" fontWeight="medium">
                        {client?.role}
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} xl={6}>
                    <ProfileInfoCard
                      info={{
                        "Nom de Société": client?.coporationName,
                        "Matricule Fiscal": client?.coporationSerialNumber,
                        email: client?.email,
                        cin: client?.cin,
                        "Numéro Téléphone": client?.phoneNumber
                          ? `+${client.phoneNumber}`
                          : "",
                        "Deuxième Téléphone": client?.secondaryPhoneNumber
                          ? `+${client.secondaryPhoneNumber}`
                          : "",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} xl={6}>
                    <ProfileInfoCard
                      info={{
                        adresse: client?.address,
                        "code Postal": client?.zipCode,
                        pays: client?.country,
                        ville: client?.city,
                        description: (client?.comment ?
                          (<Box variant="outlined">
                            <Box >
                              <SoftTypography
                                variant="button"
                                color="text"
                                fontWeight="regular"
                                sx={{ textAlign: "justify" }}
                              >
                                {client?.comment}
                              </SoftTypography>
                            </Box>
                          </Box>) : ""
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </SoftBox>
              <DataTable
                table={{
                  columns: [
                    { Header: "image", accessor: "imageUrl", width: "12%" },
                    { Header: "Nom", accessor: "firstName", width: "12%" },
                    { Header: "Prenom", accessor: "lastName", width: "12%" },
                    { Header: "email", accessor: "email", width: "15%" },
                    { Header: "télephone", accessor: "phone", width: "15%" },
                    { Header: "niveau", accessor: "niveau", width: "15%" },
                    { Header: "classe", accessor: "classe", width: "15%" },
                  ],
                  rows: students &&
                    students?.map((student) => {
                      return {
                        imageUrl: <SoftAvatar
                          src={
                            student?.imageUrl?.startsWith("https")
                              ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                              : `${process.env.REACT_APP_BASE_URL}/${student?.imageUrl}`
                          }
                          variant="rounded"
                          size="xl"
                          shadow="sm"
                        />
                        ,
                        firstName: student?.firstName,
                        lastName: student?.lastName,
                        email: student?.email,
                        phone: student?.phoneNumber
                          ? `+${student.phoneNumber}`
                          : "",
                        niveau: student?.classe?.level?.name,
                        classe: student?.classe?.name,

                      };
                    }),
                }}
                entriesPerPage={
                  afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false
                }
                canSearch={afficheFilter ? true : false}
              />  
              <SoftBox mt={3} >
              <Grid item xs={12} lg={12}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                container>
                <SoftButton
                  id="btn-cancel"
                  onClick={() => navigate("/administration/responsables")}
                  variant="gradient"
                  color="purple"
                >
                  Retour
                </SoftButton>
              </Grid>
            </SoftBox>
              
            </Card>

          )}
        
        </SoftBox>
      )}
    </DashboardLayout>
  );
}
