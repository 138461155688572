import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  nom: Yup.string()
    .required("Entrez le nom de l'event")
    .min(2, "La longueur minimale requise est de 2 caractères."),

  description: Yup.string()
    .required("Entrez la description de la tâche")
    .min(5, "La longueur minimale requise est de 5 caractères."),

  categorie: Yup.string().required("Entrez le categorie de l'event"),

  dateDebut: Yup.string().required("Entrez la date debut de l'event"),
  heureDebut: Yup.string().required("Entrez l'heure debut de l'event"),
  dateFin: Yup.string().required("Entrez la date fin de l'event"),
  heureFin: Yup.string().required("Entrez l'heure fin de l'event"),
  lieu: Yup.string().required("Entrez le lieu de l'event"),
  lieuPrecis: Yup.string().required("Entrez le lieu précis de l'event")
  .min(3, "La longueur minimale requise est de 3 caractères."),

  capacite: Yup.number().required("Entrez le capaciter des personnes dans l'event")
  .test('is-positive', 'La capacite doit être supérieure à zéro', value => value > 0),
  
  lien: Yup.string().required("Entrez le lien de l'event")
  .min(10, "La longueur minimale requise est de 10 caractères."),

  prixParticipant: Yup.number().required("Entrez le prix de participation dans l'event")
  .test('is-positive', 'Le prix de participation doit être supérieure à zéro', value => value > 0),
  datesSelectionner: Yup.string().required("Entrez les dates personnalisées de l'event"),
});

export default validationSchema;
