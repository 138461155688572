import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

function Header({ user }) {

  return (
    <Card id="profile">
      <SoftBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SoftAvatar
            src={user?.imageUrl?.startsWith("https")
            ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
            : `${process.env.REACT_APP_BASE_URL}/${user?.imageUrl}`}
              variant="rounded"
              size="xl"
              shadow="sm"
              alt="image de profile"
         

            />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {user?.firstName} {user?.lastName}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
              {user?.role}
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}
Header.defaultProps = {
  color: "dark",

};
// typechecking props for UserInfo
Header.propTypes = {

  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  title: PropTypes.string.isRequired,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
export default Header;
