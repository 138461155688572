import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DataTable from "examples/Tables/DataTable";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
} from "@mui/material";
import { useParams } from "react-router-dom";
import api from "utils/axios";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PersonAddDisabledIcon from "@mui/icons-material/PersonAddDisabled";
import Swal from "sweetalert2";

const StudentInfo = ({handleFetchStudentList,handleClose}) => {
  const { id } = useParams();
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [inputs, setInputs] = useState([{}]);
  const [data, setData] = useState([]);
  const [optionsStudents, setOptionsStudents] = useState([]);
  const [optionsStudentsInSpecificClasse, setOptionsStudentsInSpecificClasse] = useState([]);
  const facility = JSON.parse(localStorage.getItem("facility"));

  const handleFetch = async () => {
    try {
      setLoading(true);
      let endpoint;
      if (facility?.type === "centreDeFormation") {
        endpoint =`training-company/get-students/${facility._id}/class/${id}`;
      } else {
        endpoint = `/faculty/get-students/${facility._id}/class/${id}`;
      }  
      const response = await api.get(endpoint);
      setOptionsStudents(response.data.studentsWithoutClasse);
      setOptionsStudentsInSpecificClasse(response.data.studentInSpecificClasse);
      const initialStudents = response.data.studentInSpecificClasse.map((student) => student._id);
      setInputs([{ students: [], students: initialStudents ,studentsToDelete: [], studentsToDelete: initialStudents }]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
   const handleFetchClass = async () => {
    try {
      setLoading(true);
      let endpoint;
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/get-classe/${id}`;
      } else {
        endpoint = `/faculty/get-classe/${id}`;
      }  
      const response = await api.get(endpoint);
      setData(response.data)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetch();
    handleFetchClass();
  }, []);
  
  const handleAdd = (index, itemId) => {
    if (itemId) {
      const newInputs = [...inputs];
      const studentsArray = newInputs[0]?.students || [];
      const studentsToDeleteArray = newInputs[0]?.studentsToDelete || [];
      const itemIndex = studentsArray.indexOf(itemId);
      // Check if the student is not in the studentsArray
      const isStudentInArray = itemIndex !== -1;
      // Check if data is an array and the student is not in the data array
      const isStudentInData =
        Array.isArray(data) && data?.students?.map(studentData => studentData?.id).includes(itemId);
      const maxAbility = data?.abilityMaximum;
      if (facility?.type === "centreDeFormation" ? 
      (!isStudentInArray && !isStudentInData ) 
      :(!isStudentInArray && !isStudentInData  && studentsArray.length < maxAbility) ) {
        // If itemId doesn't exist in studentsArray, is not in data array, and doesn't exceed maxAbility, add it
        studentsArray.push(itemId);
        // Remove from studentsToDeleteArray if it exists
        const deleteIndex = studentsToDeleteArray.indexOf(itemId);
        if (deleteIndex !== -1) {
          studentsToDeleteArray.splice(deleteIndex, 1);
        }
      } else if (isStudentInArray) {
        // If itemId exists in studentsArray, remove it and add to studentsToDeleteArray
        studentsArray.splice(itemIndex, 1);
        studentsToDeleteArray.push(itemId);
      } else {
        // Notify user using SweetAlert if the number of students exceeds the ability maximum
        Swal.fire({
          icon: "warning",
          title: "Limite d'ajout atteinte",
          text: `Vous ne pouvez pas ajouter plus de ${maxAbility} étudiants.`,
        });
      }
  
      // Remove duplicates from studentsArray
      const uniqueStudentIds = Array.from(new Set(studentsArray));
      newInputs[0].students = uniqueStudentIds;
      newInputs[0].studentsToDelete = studentsToDeleteArray;
  
      setInputs(newInputs);
    }
  };
  
  const handleUpdate = async () => {
    try {
      setLoad(true);
      let endpoint;
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/update-student-in-classe/${id}`;
      } else {
        endpoint = `/faculty/update-student-in-classe/${id}`;
      }  
      const response = await api.patch(endpoint, {
        studentsToAdd: inputs[0]?.students || [],
        studentsToDelete: inputs[0]?.studentsToDelete || [],
      });
      Swal.fire("Success!", "Mise à jour étudiants réussie", "success");
      handleFetch();
      handleFetchStudentList();
      handleClose()
    } catch (error) {
      console.log(error);
      Swal.fire("Erreur!", "Les étudiants n'ont pas été modifiés", "error");
    } finally {
      setLoad(false);
    }
  };

  return (
    <SoftBox id="basic-info" sx={{ overflow: "visible" }}>

      {loading ? (
        <>
          <SoftBox
            sx={{
              mt: 13,
              textAlign: "center",
            }}
          >
            <CircularProgress />
          </SoftBox>
        </>
      ) : (
        <DataTable
          table={{
            columns: [
              { Header: "nom de l'étudiant", accessor: "student", width: "40%" },
              { Header: "email", accessor: "email", width: "40%" },
              { Header: "action", accessor: "action" },
            ],
            rows: (optionsStudentsInSpecificClasse  || [])
              .concat(optionsStudents || [])
              .map((student, index) => ({
                student: (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        sx={{ mr: 2 }}
                        src={
                          student?.imageUrl?.startsWith("https")
                            ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                            : `${process.env.REACT_APP_BASE_URL}/${student?.imageUrl}`
                        }
                        alt="Logo"
                      />
                      {student.firstName} {student.lastName}
                    </Box>
                  </>
                ),
                email: student.email,
                action: (
                  <>
                    <SoftButton
                      onClick={() => handleAdd(index, student._id)}
                      color={inputs[0]?.students?.includes(student._id) ? "error" : "success"}
                      sx={{ color: "#fff" }}
                      startIcon={
                        inputs[0]?.students?.includes(student._id) ? (
                          <PersonAddDisabledIcon />
                        ) : (
                          <PersonAddAltIcon />
                        )
                      }
                      variant="contained"
                      id={`btn-ajouter-supprimer-${index}`}
                    >
                      {inputs[0]?.students?.includes(student._id) ? "Supprimer" : "Ajouter"}
                    </SoftButton>
                  </>
                ),
              })),
          }}
          entriesPerPage={
            afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false
          }
          canSearch={afficheFilter ? true : false}
        />
      )}
      <Grid
        item
        xs={12}
        ml={3}
        sx={{ mr: 2, mt: 2 }}
        mb={2}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <SoftButton id={`btn-cancel`} onClick={() => window.location.reload()} color="dark" variant="outlined">
          Annuler
        </SoftButton>
        {load ? (
          <CircularProgress />
        ) : (
          <SoftButton id={`btn-update`} onClick={handleUpdate} sx={{ ml: 2 }} color="customized" variant="gradient">
            Modifier
          </SoftButton>
        )}
      </Grid>
    </SoftBox>
  );
};
// typechecking props for StudentInfo
StudentInfo.propTypes = {
  handleClose: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  handleFetchStudentList: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default StudentInfo;
