import api from "utils/axios";


  const fetchEnterpriseLogoDataURL = async (logoUrl) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${logoUrl}`);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error(`Error fetching data URL for logo ${logoUrl}: ${error}`);
      return null;
    }
  };
  
  const dataTableData = async () => {
    try {
      const response = await api.get("/get-enterprises");
      const enterprises = response.data;
  
      const photoPromises = [];
  
      for (const enterprise of enterprises) {
        const dataUrl = await fetchEnterpriseLogoDataURL(enterprise.logoUrl);
        photoPromises.push({ ...enterprise, dataUrl });
      }
  
      return photoPromises;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
  
  export default dataTableData;
  