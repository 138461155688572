import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { StyledEngineProvider } from '@mui/material/styles';
// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";
import { SocketContextProvider } from "context/SocketContext";
import { ContextMenuProvider } from "context/Menu";

/* react 18

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <App />
    </SoftUIControllerProvider>
  </BrowserRouter>
);
*/

// react 17.0.2
ReactDOM.render(
  <BrowserRouter>

    <SoftUIControllerProvider>
      <SocketContextProvider>
         <ContextMenuProvider> 
          <App />
         </ContextMenuProvider> 
      </SocketContextProvider>
    </SoftUIControllerProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
