import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SoftBadge from "components/SoftBadge";
import SoftTypography from "components/SoftTypography";
import { Box } from "@mui/material";
import moment from "moment";
import SoftButton from "components/SoftButton";
import { useSoftUIController } from "context";
import api from "utils/axios";
function Card({ id, evaluation, type, description, date, onAddEventClick }) {
  const [isEventAdded, setIsEventAdded] = useState(null);
  const [controller, _] = useSoftUIController();

  useEffect(()=>{
    api.get(`get-demande/${id}`).then((res) => {
      setIsEventAdded(res.data.isEventAdded);
    });
  },[id]);

  const handleAddEventClick = () => {
    if (!isEventAdded) {
      onAddEventClick();
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <SoftBadge
          size="xs"
          color={evaluation.color}
          badgeContent={evaluation.label}
          container
        />
        {type === "Événement" &&
          evaluation.label === "Valider" &&
          controller.user.info.role === "student" &&
          isEventAdded === false && (
            <SoftButton
              size="small"
              variant="gradient"
              color="info"
              sx={{ p: "0px 10px" }}
              onClick={handleAddEventClick}
            >
              Ajouter Événement
            </SoftButton>
          )}
      </Box>
      <SoftTypography variant="body2">{type}</SoftTypography>
      <SoftTypography variant="body2" color="text" fontSize="14px">
        {description}
      </SoftTypography>
      <SoftTypography variant="caption" color="black">
        {moment(date).format("DD/MM/YYYY HH:mm:ss")}
      </SoftTypography>
    </>
  );
}

Card.propTypes = {
  id : PropTypes.string,
  evaluation: PropTypes.shape({
    color: PropTypes.oneOf(["secondary", "success", "error"]).isRequired,
    label: PropTypes.oneOf(["Valider", "Non valider", "Neutral"]).isRequired,
  }),
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onAddEventClick: PropTypes.func,
};

export default Card;
