// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    width: 48px;
    height: 48px;
    margin: auto;
    position: relative;
  }
  
  .loader:before {
    content: '';
    width: 48px;
    height: 5px;
    background: #955efc   ;
    position: absolute;
    top: 60px;
    left: 0;
    border-radius: 50%;
    animation: shadow324 0.5s linear infinite;
  }
  
  .loader:after {
    content: '';
    width: 100%;
    height: 100%;
    background: #7931FF
    ;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    animation: jump7456 0.5s linear infinite;
  }
  
  @keyframes jump7456 {
    15% {
      border-bottom-right-radius: 3px;
    }
  
    25% {
      transform: translateY(9px) rotate(22.5deg);
    }
  
    50% {
      transform: translateY(18px) scale(1, .9) rotate(45deg);
      border-bottom-right-radius: 40px;
    }
  
    75% {
      transform: translateY(9px) rotate(67.5deg);
    }
  
    100% {
      transform: translateY(0) rotate(90deg);
    }
  }
  
  @keyframes shadow324 {
  
    0%,
      100% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.2, 1);
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/loader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,WAAW;IACX,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,kBAAkB;IAClB,yCAAyC;EAC3C;;EAEA;IACE,WAAW;IACX,WAAW;IACX,YAAY;IACZ;IACA;IACA,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE;MACE,+BAA+B;IACjC;;IAEA;MACE,0CAA0C;IAC5C;;IAEA;MACE,sDAAsD;MACtD,gCAAgC;IAClC;;IAEA;MACE,0CAA0C;IAC5C;;IAEA;MACE,sCAAsC;IACxC;EACF;;EAEA;;IAEE;;MAEE,sBAAsB;IACxB;;IAEA;MACE,wBAAwB;IAC1B;EACF","sourcesContent":[".loader {\n    width: 48px;\n    height: 48px;\n    margin: auto;\n    position: relative;\n  }\n  \n  .loader:before {\n    content: '';\n    width: 48px;\n    height: 5px;\n    background: #955efc   ;\n    position: absolute;\n    top: 60px;\n    left: 0;\n    border-radius: 50%;\n    animation: shadow324 0.5s linear infinite;\n  }\n  \n  .loader:after {\n    content: '';\n    width: 100%;\n    height: 100%;\n    background: #7931FF\n    ;\n    position: absolute;\n    top: 0;\n    left: 0;\n    border-radius: 4px;\n    animation: jump7456 0.5s linear infinite;\n  }\n  \n  @keyframes jump7456 {\n    15% {\n      border-bottom-right-radius: 3px;\n    }\n  \n    25% {\n      transform: translateY(9px) rotate(22.5deg);\n    }\n  \n    50% {\n      transform: translateY(18px) scale(1, .9) rotate(45deg);\n      border-bottom-right-radius: 40px;\n    }\n  \n    75% {\n      transform: translateY(9px) rotate(67.5deg);\n    }\n  \n    100% {\n      transform: translateY(0) rotate(90deg);\n    }\n  }\n  \n  @keyframes shadow324 {\n  \n    0%,\n      100% {\n      transform: scale(1, 1);\n    }\n  \n    50% {\n      transform: scale(1.2, 1);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
