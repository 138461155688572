import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import {Card,Grid, } from '@mui/material';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import api from 'utils/axios';
const Parametre = ({ enterprise }) => {
  const navigate = useNavigate();
  const [adminInfo, setAdminInfo] = useState({});
  const company = enterprise ? enterprise : {};
  const handleFetchCompanyAdminInfo = async () => {
    try {
      const response = await api.get(`/get-user/${enterprise.enterpriseAdmin._id}`)
      setAdminInfo(response.data);
    } catch (error) {
      throw error
    }
  }
  useEffect(() => {
    if (enterprise && company) {
      handleFetchCompanyAdminInfo();
    }
  }, [enterprise, company]);


  const showAlertDeleteAccount = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr(e)",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText:"Annuler"
      })
      .then((result) => {
        if (result.isConfirmed) {
          api.delete(`/delete-enterprise/${enterprise._id}`)
            .then(() => {
              Swal.fire("Supprimé !", "Le enterprise a été supprimé.", "success").then(() => {
                navigate("/companies/companies")
              })
            }).catch(() => {
              Swal.fire("Erreur!", "Enterprise n'a pas été supprimé.", "Erreur");
            })
        }
      });
  };

  const showAlertDesactivateAccount = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr(e)",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `${adminInfo.etat === "active" ? "Desactivate" : "Activate"}`,
      })
      .then((result) => {
        if (result.isConfirmed) {
          api.post(`/change-status-user/${adminInfo._id}`)
            .then(() => {
              if (adminInfo.etat === "active") {
                Swal.fire( "Désactivé !",
                "Enterprise a été désactivé avec succès.",
                "success",
                "success").then(() => {
                  navigate("/companies/companies")
                })
              } else if (adminInfo.etat === "desactive") {
                Swal.fire(
                  "Activé!",
                  "Le Enterprise a été activée avec succès.",
                  "success").then(() => {
                  navigate("/companies/companies")
                })
              }
            }).catch(() => {
              Swal.fire("Erreur !", "Enterprise n'a pas été désactivé ou activé.", "erreur");
            })
        }
      });
  };
  return (
    <Card id="delete-account">
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Supprimer le compte</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
        Une fois que vous supprimez  ce compte, il n&lsquo;y a pas de retour en arrière. Soyez sûr!           </SoftTypography>
      </SoftBox>
      <SoftBox
        pb={3}
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >

        <Grid item xs={12}    mb={2} display={"flex"} justifyContent={"flex-end"} >
            <SoftButton onClick={showAlertDeleteAccount} sx={{ mr:2}} variant="outlined" color="secondary" size="small">
              Supprimer le compte
            </SoftButton>
            <SoftButton  disabled={adminInfo.etat === "enAttente"}  onClick={showAlertDesactivateAccount} color={adminInfo.etat === "active" ? "error" : adminInfo.etat === "desactive" ? "success" : "purple"} variant="gradient" size="small" sx={{ height: "100%" }}>
              {adminInfo.etat === "active" ? "desactive" : adminInfo.etat === "desactive" ? "activate" : "en Attente"}
            </SoftButton>
        </Grid>
      </SoftBox>
    </Card>
  )
}
Parametre.propTypes = {
  enterprise: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
export default Parametre
