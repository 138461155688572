//import layouts
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import SoftTypography from 'components/SoftTypography';

import { Box, Card, Grid, Typography } from "@mui/material";

import { grey } from "@mui/material/colors";

//hooks
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";

//components
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";

import Swal from "sweetalert2";

import { Formik, Form, Field } from "formik";
import validationSchema from "../ListTaches/validerChamps/ValidationSchema";

//librarie utiliser pour créer facilement un champ de saisie de numéro de téléphone avec une validation et une mise en forme intégrées
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// add, get , update and delete entreprise potentiel from backend tasks.
import api from "utils/axios";
import { useNavigate } from "react-router-dom";
import SoftSelect from "components/SoftSelect";

const initialValues = {
  nomEntr: "",
  adresse: "",
  emailCom: "",
};

function AjouterEntreprisePotentiel() {
  const navigate = useNavigate();
  const theme = useTheme();

  const [nomEntreprise, setNomEntreprise] = useState("");
   const [matriculeFiscal,setMatriculeFiscal] = useState("") ; 
  const [emailEntreprise, setEmailEntreprise] = useState("");
  const [siteWeb, setSiteWeb] = useState("");
  
  //phone number state
  const [numTel, setNumTel] = useState("");

  const handleOnChangeNumTel = (value, _) => {
    setNumTel(value);
  };

  const [numFix, setNumFix] = useState("");
  const handleOnChangeNumFix = (value, _) => {
    setNumFix(value);
  };

  const [adresse, setAdresse] = useState("");
  const [codePostal, setCodePostal] = useState();
  const [nomEtPrenomAgent, setNomEtPrenomAgent] = useState("");
  // const [statut, setStatut] = useState("Neutral");
  const [emailCommercial, setEmailCommercial] = useState("");
  const [description, setDescription] = useState("");
  const statut = "No Statut Actual ";
  const [emailResponsables, setEmailResponsables] = useState([]);

  const getEmailObservateursEtResponsables = () => {
    api.get("get-user-commercial?role=commercial").then((response) => {
      const responsablesEmail = response.data.map((responsableEmail) => responsableEmail.email);
      setEmailResponsables(responsablesEmail);
    });
  };

  useEffect(() => {
    getEmailObservateursEtResponsables();
  }, [setEmailResponsables]);

  const createEntreprisePotentiel = () => {
    if (nomEntreprise?.length > 1 && adresse?.length >= 10 && emailCommercial !== "") {
      if (matriculeFiscal === "" || (matriculeFiscal.length >= 8 && matriculeFiscal.length <= 16)) {
        api
          .post("tasks/create-entreprise-potentiel", {
            nomEntreprise,
            matriculeFiscal,
            emailEntreprise,
            siteWeb,
            numTel,
            numFix,
            adresse,
            codePostal,
            nomEtPrenomAgent,
            statut,
            emailCommercial,
            description,
          })
          .then((res) => {
            Swal.fire({
              title: "Succès",
              text: "Ajouter entreprise potentielle réussie",
              icon: "success",
              confirmButtonText: "ok",
            });
            navigate("/commercials/entreprisesPotentiel");
          });
      } else {
        Swal.fire({
          title: "Warning",
          text: "Saisir la Matricule fiscal correctement",
          icon: "warning",
          confirmButtonText: "ok",
        });
      }
    } else {
      Swal.fire({
        title: "Warning",
        text: "Veuillez ajouter tous les champs obligatoires",
        icon: "warning",
        confirmButtonText: "ok",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ errors, touched, handleSubmit, handleBlur, handleChange }) => (
          <Form onSubmit={handleSubmit}>
           <Box sx={{ display: "flex", mt: "16px", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="h5" sx={{ flexGrow: "1" }}> Nouvelle Entreprise Potentielle
              </Typography>
             
            </Box>

            <Card
              sx={{
                border: `1px solid ${grey[200]}`,
                bgcolor: "white",
                borderRadius: "15px",
                p: "20px",
                mt: "16px",
                overflow: "visible",
              }}
            >
               
              <Grid container mt="16px">
                <Grid item xs={12} md={6} sx={{ display: "block" }}>
                  <Box mr={{ sx: "0px", md: "8px" }}>
                  <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                      Nom entreprise*
                    </SoftTypography>
                    <Field name="nomEntr">
                      {({ field }) => (
                        <SoftInput
                          {...field}
                          variant="outlined"
                          fullWidth
                          placeholder="Nom Entreprise"
                          error={!!errors.nomEntr && !!touched.nomEntr}
                          success={!errors.nomEntr && touched.nomEntr}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            handleChange(event);
                            setNomEntreprise(event.target.value);
                          }}
                        />
                      )}
                    </Field>
                    {errors.nomEntr && touched.nomEntr && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "400", fontSize: "12px" }}
                        color="error"
                      >
                        {errors.nomEntr}
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block" }} mt={{ xs: "16px", md: "0px" }}>
                  <Box ml={{ sx: "0px", md: "8px" }}>
                  <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                      Matricule Fiscal
                    </SoftTypography>
                    <Field name="matriculeFiscal">
                      {({ field }) => (
                        <SoftInput
                          {...field}
                          variant="outlined"
                          fullWidth
                          placeholder="Matricule Fiscal"
                          error={!!errors.matriculeFiscal && !!touched.matriculeFiscal}
                          success={!errors.matriculeFiscal && touched.matriculeFiscal}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            handleChange(event);
                            setMatriculeFiscal(event.target.value);
                          }}
                        />
                      )}
                    </Field>
                    {errors.matriculeFiscal && touched.matriculeFiscal && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "400", fontSize: "12px" }}
                        color="error"
                      >
                        {errors.matriculeFiscal}
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box mr={{ sx: "0px", md: "8px" }}>
                  <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                      Email entreprise
                    </SoftTypography>
                    <SoftInput
                      variant="outlined"
                      fullWidth
                      placeholder="Email Entreprise"
                      onChange={(event) => {
                        setEmailEntreprise(event.target.value);
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box ml={{ sx: "0px", md: "8px" }}>
                  <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                      Site Web
                    </SoftTypography>
                    <SoftInput
                      variant="outlined"
                      fullWidth
                      placeholder="Site Web"
                      onChange={(event) => {
                        setSiteWeb(event.target.value);
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box mr={{ sx: "0px", md: "8px" }}>
                  <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                      Numéro de Téléphone
                    </SoftTypography>
                    <PhoneInput
                      country={"tn"}
                      value={numTel}
                      onChange={handleOnChangeNumTel}
                      inputStyle={{
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box ml={{ sx: "0px", md: "8px" }}>
                  <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                      Numéro Fix
                    </SoftTypography>
                    <PhoneInput
                      country={"tn"}
                      value={numFix}
                      onChange={handleOnChangeNumFix}
                      inputStyle={{
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box mr={{ sx: "0px", md: "8px" }}>
                  <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                      Adresse*
                    </SoftTypography>
                    <Field name="adresse">
                      {({ field }) => (
                        <SoftInput
                          {...field}
                          type="text"
                          variant="outlined"
                          fullWidth
                          placeholder="Adresse"
                          error={errors.adresse && touched.adresse}
                          success={!errors.adresse && touched.adresse}
                          // onBlur={() => handleBlur("adresse")}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            handleChange(event);
                            setAdresse(event.target.value);
                          }}
                        />
                      )}
                    </Field>
                    {errors.adresse && touched.adresse && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "400", fontSize: "12px" }}
                        color="error"
                      >
                        {errors.adresse}
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box ml={{ sx: "0px", md: "8px" }}>
                  <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                      Code Postal
                    </SoftTypography>
                    <SoftInput
                      type="number"
                      variant="outlined"
                      fullWidth
                      placeholder="Code Postal"
                      onChange={(event) => {
                        setCodePostal(event.target.value);
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={4} sx={{ display: "block", mt: "16px" }}>
                  <Box mr={{ sx: "0px", md: "8px" }}>
                  <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                      Nom & Prénom Agent
                    </SoftTypography>
                    <SoftInput
                      variant="outlined"
                      fullWidth
                      placeholder="Nom et Prénom agent"
                      onChange={(event) => {
                        setNomEtPrenomAgent(event.target.value);
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={4} sx={{ display: "block", mt: "16px" }}>
                  <Box ml={{ sx: "0px", mx: "8px" }}>
                  <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                      Statut
                    </SoftTypography>
                    <Typography
                      variant="body2"
                      sx={{
                        bgcolor: "#94A3B8",
                        p: "8px 10px",
                        borderRadius: "8px",
                        color: theme.palette.background.default,
                      }}
                    >
                      {statut}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={4} sx={{ display: "block", mt: "16px" }}>
                  <Box ml={{ sx: "0px", md: "8px" }}>
                  <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                      Email commercial*
                    </SoftTypography>
                    <Field name="emailCom">
                      {({ field }) => (
                        <SoftSelect
                          options={emailResponsables.map((emailResponsable) => {
                            return {
                              value: emailResponsable,
                              label: emailResponsable,
                            };
                          })}
                          placeholder="Choisissez un commercial.. "
                          onBlur={handleBlur}
                          onChange={(event) => {
                            setEmailCommercial(event.value);
                          }}
                          
                        />
                      )}
                    </Field>
                    {emailCommercial === "" && !!touched.emailCom && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "400", fontSize: "12px" }}
                        color="error"
                      >
                        {errors.emailCom}
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sx={{ display: "block", mt: "16px" }}>
                  <Box>
                  <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                      Description
                    </SoftTypography>
                    <SoftInput
                      multiline
                      rows={3}
                      placeholder="Description"
                      variant="outlined"
                      onChange={(event) => {
                        setDescription(event.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", mt: "16px", alignItems: "center", justifyContent: "space-between" }}>
              <SoftButton size="small" onClick={() => navigate('/commercials/entreprisesPotentiel')} variant="outlined" color="dark" >
                                Annuler
                              </SoftButton>
              <SoftButton
                type="submit"
                variant="gradient"
                color="purple"
                onClick={() => {
                  createEntreprisePotentiel();
                }}
              >
                Ajouter Entreprise
                </SoftButton>
            </Box>
            </Card>
            {/* <Card
              sx={{
                border: `1px solid ${grey[200]}`,
                bgcolor: "white",
                borderRadius: "15px",
                p: "20px",
                mt: "16px",
                overflow: "visible",
              }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              <SoftButton
                type="submit"
                variant="gradient"
                color="purple"
                onClick={() => {
                  createEntreprisePotentiel();
                }}
              >
                Ajouter
                </SoftButton></Box></Card> */}
          </Form>
        )}
      </Formik>
    </DashboardLayout>
  );
}

export default AjouterEntreprisePotentiel;
