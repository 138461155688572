import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import api from "utils/axios";
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import SoftButton from "components/SoftButton";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";

function ParcoursActions({
  par,
  parcours,
  deleteEyes,
  deleteUpdate,
  deleteDelete,
  sousPeriode,
  handleFetch,
  setShowDetails,
  setDetailsData,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [speciality, setSpeciality] = useState(parcours?.speciality?._id || null);
  const [niveau, setNiveau] = useState(parcours?.niveau?._id || "");
  const [tronc, setTronc] = useState(parcours?.tronc?._id || "");
  const [loading, setLoading] = useState(false);
  const [filiere, setFiliere] = useState("");
  const [options, setOptions] = useState([]);
  const [optionsLevel, setOptionsLevel] = useState([]);
  const [optionsFilieres, setOptionsFilieres] = useState([]);
  const [optionsTroncCommun, setOptionsTroncCommun] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageSpeciality, setErrorMessageSpeciality] = useState("");
  const [selectedFiliere, setSelectedFiliere] = useState(parcours?.niveau?.filiere);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [error,setError]=useState("")
  const [departementId, setDepartementId] = useState(parcours?.speciality?.departement);
  const facility = JSON.parse(localStorage.getItem("facility"));
  useEffect(() => {
    if (parcours) {
      setSelectedFiliere(parcours?.niveau?.filiere);
      setSelectedLevel(parcours?.niveau?._id);
    }
  }, [parcours]);
  useEffect(() => {
    api.get(`/faculty/all-filiere/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsFilieres(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
          niveaux: item.niveaux,
          departement: item.departement._id,
        }))
      );
    });
    setNiveau(parcours?.niveau?._id || "");
    if (departementId) {
      api
        .get(`/faculty/get-specialities-by-departement/${departementId}`)
        .then((res) => {
          setOptions(
            res.data.map((item) => {
              return {
                value: item._id,
                label: item.name,
              };
            })
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setError("Resource not found:", error.response.data);
            // Handle 404 error gracefully (e.g., display a message to the user)
          } else {
            setError("API request failed:", error.message);
            // Handle other errors
          }
        });
        api
        .get(`/faculty/get-troncs-by-departement/${departementId}`)
        .then((res) => {
          setOptionsTroncCommun(
            res.data.map((item) => {
              return {
                value: item._id,
                label: item.name,
              };
            })
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setError("Resource not found:", error.response.data);
            // Handle 404 error gracefully (e.g., display a message to the user)
          } else {
            setError("API request failed:", error.message);
            // Handle other errors
          }
        });   
    }
    setSpeciality(parcours?.speciality?._id || "");
    setTronc(parcours?.tronc?._id || "");
    api
      .get(`/faculty/one-filiere/${selectedFiliere}`)
      .then((res) => {
        setOptionsLevel(
          res?.data?.niveaux?.map((item) => ({
            value: item._id,
            label: item.name,
          }))
        );
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setError("Resource not found:", error.response.data);
          // Handle 404 error gracefully (e.g., display a message to the user)
        } else {
          setError("API request failed:", error.message);
          // Handle other errors
        }
      });
  }, [selectedFiliere]);

  const handleUpdate = async () => {
    try {
      setLoading(true);
      if (speciality === null) {
        setErrorMessageSpeciality("specialité doit être sélectionné.");
      } else if (selectedLevel === null) {
        setErrorMessage("le niveau doit être sélectionné.");
      } else {
        const response = await api.patch(`/faculty/update-parcour/${parcours._id}`, {
          tronc,
          speciality,
          niveau,
        });
        Swal.fire("Success!", "Mise à jour niveau réussie", "success");
        navigate(0);
        setOpen(false);
        setErrorMessage("");
      }
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    //setSpeciality(parcours?.domaine || "");
  };
  const showAlert = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous voulez supprimer ce parcours ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(`/faculty/delete-parcours/${parcours._id}`);
            if (response) {
              (await Swal.fire("Suppression !", "Le niveau a été supprimée.", "success"))
                .isConfirmed
                ? navigate(0)
                : "";
            }
          } catch (error) {
            Swal.fire("Erreur!", "Le niveau n'a pas été supprimée.", "error");
          }
        }
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };
  return (
    <SoftBox display="flex" alignItems="center">
      {!deleteEyes && (
        <SoftBox>
          <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
            <Tooltip title="Consulter" placement="left">
              <VisibilityIcon
                onClick={() => {
                  setDetailsData(par);
                  setShowDetails(true);
                }}
              />
            </Tooltip>
          </SoftTypography>
        </SoftBox>
      )}
      {!deleteUpdate && (
        <SoftBox mx={2}>
          <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
            <Tooltip title="Modifier" placement="top">
              <Icon onClick={() => setOpen(true)} id="edit-enterprise-page">
                edit
              </Icon>
            </Tooltip>
            <Dialog
              fullWidth
              maxWidth="md"
              open={open}
              onClose={handleClose}
              onKeyDown={handleKeyPress}
            >
              <DialogTitle>Modifier un parcours</DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.grey[200],
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <DialogContentText>
                  Vous devez saisir le nouveau nom de votre parcours.
                </DialogContentText>
                <Grid sx={{ mt: 1 }}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      alignItems: "flex-start",
                    }}
                  >
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Filière*
                      </SoftTypography>
                      <Autocomplete
                        options={optionsFilieres}
                        title="Sélectionner une filière"
                        getOptionLabel={(option) => option.label || ""}
                        isOptionEqualToValue={(option, value) => option.value === value}
                        clearIcon={null}
                        disabled={true}
                        defaultValue={
                          optionsFilieres.find((option) => option.value === selectedFiliere) || null
                        }
                        onChange={(event, newValue) => {
                          if (newValue !== null && newValue !== undefined) {
                            setFiliere(newValue.value);
                            setSelectedLevel(null);
                            setNiveau("");
                            setDepartementId("");
                            setErrorMessage("");
                            setErrorMessageSpeciality("");
                            setSpeciality(null);
                            //setSelectedFiliere(newValue.value)
                            setOptionsLevel(
                              newValue?.niveaux?.map((item) => ({
                                value: item._id,
                                label: item.name,
                              }))
                            );
                            setDepartementId(newValue?.departement);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Veuillez
                          sélectionner la
                          filière "
                          />
                        )}
                      />
                    </Grid>{" "}
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Nom du tronc commun
                      </SoftTypography>
                      <Autocomplete
                        options={optionsTroncCommun}
                        title="Sélectionner tronc commun"
                        getOptionLabel={(option) => option.label || ""}
                        isOptionEqualToValue={(option, value) => option.value === value}
                        clearIcon={null}
                        disabled={true}
                        value={optionsTroncCommun.find((option) => option.value === tronc) || null}
                        onChange={(e, newValue) => {
                          if (newValue !== null && newValue !== undefined) {
                            setTronc(newValue.value);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            value={parcours?.tronc?._id}
                            placeholder="Veuillez sélectionner votre tronc commun"
                          />
                        )}
                      />               
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Nom spécialité*
                      </SoftTypography>
                      <Autocomplete
                        options={options}
                        title="Sélectionner Spécialité"
                        getOptionLabel={(option) => option.label || ""}
                        isOptionEqualToValue={(option, value) => option.value === value}
                        clearIcon={null}
                        disabled={true}
                        value={options.find((option) => option.value === speciality) || null}
                        onChange={(e, newValue) => {
                          if (newValue !== null && newValue !== undefined) {
                            setSpeciality(newValue.value);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            value={parcours?.speciality?._id}
                            placeholder="Veuillez sélectionner votre spécialité"
                          />
                        )}
                      />
                      {speciality === null && (
                        <Typography mt={0.75} variant="caption" color="error">
                          {errorMessageSpeciality}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Niveau*
                      </SoftTypography>
                      <Autocomplete
                        title="Select niveau"
                        clearIcon={null}
                        options={optionsLevel}
                        filterSelectedOptions
                        value={
                          optionsLevel.find((option) => option.value === selectedLevel) || null
                        }
                        onChange={(e, newValue) => {
                          if (newValue !== null && newValue !== undefined) {
                            setNiveau(newValue.value);
                            setSelectedLevel(newValue.value);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            value={parcours?.niveau?.name}
                            placeholder="Veuillez
                         sélectionner
                         le niveau de
                         votre parcours"
                          />
                        )}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                      />
                      {selectedLevel === null && (
                        <Typography mt={0.75} variant="caption" color="error">
                          {errorMessage}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                  <SoftButton
                    onClick={handleClose}
                    sx={{ mt: 2 }}
                    variant="outlined"
                    color="dark"
                    size="small"
                  >
                    Annuler
                  </SoftButton>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <SoftButton
                      onClick={handleUpdate}
                      sx={{ mt: 2 }}
                      variant="gradient"
                      color="customized"
                      size="small"
                    >
                      Modifier
                    </SoftButton>
                  )}
                </SoftBox>
              </DialogActions>
            </Dialog>
          </SoftTypography>
        </SoftBox>
      )}
      {!deleteDelete && (
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="supprimer" placement="left">
            <Icon id="delete-enterprise" onClick={showAlert}>
              delete
            </Icon>
          </Tooltip>
        </SoftTypography>
      )}
    </SoftBox>
  );
}
ParcoursActions.propTypes = {
  parcours: PropTypes.object.isRequired,
  par: PropTypes.object.isRequired,
  sousPeriode: PropTypes.object.isRequired,
  handleFetch: PropTypes.func.isRequired,
  setDetailsData: PropTypes.array.isRequired,
  setShowDetails: PropTypes.bool.isRequired,
  deleteEyes: PropTypes.bool.isRequired,
  deleteUpdate: PropTypes.bool.isRequired,
  deleteDelete: PropTypes.bool.isRequired,
};
export default ParcoursActions;
