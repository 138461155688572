import Default from "layouts/dashboards/default";
import Overview from "layouts/ecommerce/overview";
// Soft UI Dashboard PRO React icons
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleIcon from "@mui/icons-material/People";
import Shop from "examples/Icons/Shop";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import EmojiEvents from "@mui/icons-material/EmojiEvents";
import PaidIcon from "@mui/icons-material/Paid";
import Document from "examples/Icons/Document";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BillingSettings from "pages/billing-settings";
import InscriptionList from "pages/billings-display/InscriptionList";
import InvoicesList from "pages/billings-display/InvoicesList";
import EnterpriseTable from "pages/enterprise/EnterpriseTable";
import DepartementTable from "pages/resource-management/departement/DepartementTable";
import HomeIcon from "@mui/icons-material/Home";
import ListCardsCommercial from "pages/commercial/ListCardsCommercial";
import ListCardsClass from "pages/resource-management/classe/ListCardsClass";
import LevelTable from "pages/resource-management/level/LevelTable";
import DuoIcon from "@mui/icons-material/Duo";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Groups2Icon from "@mui/icons-material/Groups2";
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import BookIcon from '@mui/icons-material/Book';
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ModuleTable from "pages/resource-management/module/ModuleTable";
import SpecialityTable from "pages/resource-management/speciality/SpecialityTable";
import SubjectTable from "pages/resource-management/subject/SubjectTable";
import ListCardsClient from "pages/user/client/ListCardsClient";
import ListCardsCollaborator from "pages/user/collaborator/ListCardsCollaborator";
import ListCardsInstractor from "pages/user/instructor/ListCardsInstractor";
import ListCardsStudent from "pages/user/students/ListCardsStudent";

import QuotationsList from "pages/billings-display/QuotationsList";
import ListCardEmploi from "pages/planning/emploi/ListCardEmploi";
import ListCardExamen from "pages/planning/exmen/ListCardExamen";
import SalleTable from "pages/resource-management/salle/SalleTable";
import SemesterTable from "pages/resource-management/semester/SemesterTable";
import VideoConferance from "pages/video-conferance/VideoConferance";
import DomainTable from "pages/resource-management/domaine/DomainTable";
import ListCardMatiere from "pages/cours/matiere/ListCardMatiere";
import Main from "pages/cours/notes/Main";
import FormationTable from "pages/resource-management/formation/FormationTable";
import FiliereTable from "pages/resource-management/filiere/FiliereTable";
import Table from "pages/resource-management/plan/Table";
import PeriodeTable from "pages/resource-management/Periode/PeriodeTable";
import ChatCard from "pages/chat/ChatCard";
import ChatIcon from "@mui/icons-material/Chat";
import Home from "pages/home/Home";
import ParcoursTable from "pages/resource-management/parcours/ParcoursTable";
import AddEvent from "pages/events/AddEvent";
import EventTable from "pages/events/EventTable";
import TroncCommunTable from "pages/resource-management/tronc-commun/TroncCommunTable";
import BlocTable from "pages/resource-management/bloc/BlocTable";
import TypeTable from "pages/resource-management/type/TypeTable";
import KanbanDemande from "pages/demandes/Kanban/KanbanDemandes";
import ListDemandeDocument from "pages/demandes/ListeDemandes/ListDemandes";
import KanbanEvents from "pages/demandes/events/kanbanEvents/KanbanEvents";
import ListEvents from "pages/demandes/events/ListEvents";
import EntreprisesPotentiel from "pages/commercial/EntreprisesPotentiel/ListEntreprisesPotentiel";
import Kanban from "pages/commercial/kanban";
import ListTaches from "pages/commercial/ListTaches/ListTaches";

import Kpicompany from "pages/Kpicompany";

import Kpi from "pages/kpi";
import ListCharge from "pages/gestion-financier/ListCharge";
import DesktopApplication from "pages/desktopAppliction/DesktopApplication";
import ListeTâches from "pages/suiviTâche/ListeTâches";
import KanbanTâches from "pages/suiviTâche/kanban";
import ListeProjet from "pages/suiviTâche/gestiondesProjet/ListeProjet";

const accessToken = localStorage.getItem("accessToken");
let user = null;
if (accessToken) {
  user = JSON.parse(atob(accessToken.split(".")[1]));
}

const facility = JSON.parse(localStorage.getItem("facility"));
/* let sidebarRoutes = [];

if (facility && facility.type !== "faculty") {
  sidebarRoutes.push({
    etablisementType: ["faculty", "lycee"], 
    name: "Domaines",
    key: "domain",
    route: "/plan/domain",
    component: <DomainTable />,
  });
} */
const routes = [
  {
    role: [ "editor","commercial","admin","student","instructor","responsable"],
    type: "collapse",
    name: "Tableaux de bord",
    key: "dashboards",
    icon: <Shop size="12px" />,
    route: "/dashboards/default",
    component: <Overview />,
  },
  {
    role: ["super-admin"],
    type: "collapse",
    name: "Tableaux de board",
    key: "dashboards",
    icon: <Shop size="12px" />,
    route: "/dashboards/default",
    component: <Kpi />,
  },
  {
    role: ["company-admin"],
    type: "collapse",
    name: "Tableaux de board",
    key: "dashboards",
    icon: <Shop size="12px" />,
    route: "/dashboards/default",
    component: <Kpicompany />,
  },
  // {
  //   type: "collapse",
  //   name: "Accueil",
  //   key: "accueil",
  //   icon: <HomeIcon size="12px" />,
  //   route: "/accueil/default",
  //   component: <Home />,
  // }, 
  {
    role: ["commercial", "super-admin"],
    type: "collapse",
    name: " Entreprises",
    key: "companies",
    icon: <ApartmentIcon size="12px" />,
    route: "/companies/companies",
    component: <EnterpriseTable />,
  },
  {
    role: ["super-admin", "commercial"],
    type: "collapse",
    name: "Commerciaux",
    key: "commercials",
    route: "/commercials/commercial",
    component: <ListCardsCommercial />,
    icon: <PeopleIcon size="12px" />,
    collapse: [
      {
        name: "Liste Commercial",
        key: "commercial",
        route: "/commercials/commercial",
        component: <ListCardsCommercial />,
      },
      {
        name: "Liste Tâches",
        key: "listTaches",
        route: "/commercials/listTaches",
        component: <ListTaches />,
      },
      {
        name: "Kanban Tâches",
        key: "kanban",
        route: "/commercials/kanban",
        component: <Kanban />,
      },
      {
        role: ["super-admin"],
        name: "Entreprises Potentielles",
        key: "entreprisesPotentiel",
        route: "/commercials/entreprisesPotentiel",
        component: <EntreprisesPotentiel />,
      },
    ],
  },
 
  {
    role: ["company-admin", "admin" , "editor"],
    type: "collapse",
    name: "Gestion Des Projets",
    key: "suiviTache",
    route: "/suiviTache/ListeProjet",
    component: <ListeProjet />,
    icon: <PeopleIcon size="12px" />,
    collapse: [
      {
        name: "Liste Des Projets",
        key: "ListeProjet",
        route: "/suiviTache/ListeProjet",
        component: <ListeProjet />,
      },
       
    ],
  },
  
  
  {
    role: ["super-admin", "company-admin" , "admin", "editor"],
    type: "collapse",
    name: "Administration",
    key: "administration",
    //route: "/administration/commercial",
    //component: <ListCardsCommercial />,
    icon: <ManageAccountsIcon size="12px" />,
    collapse: [
      {
        role:["company-admin", "commercial", "admin", "super-admin"],
        name: "Finances",
        key: "finance",
        route: "/administration/finance",
        component: <ListCardsCommercial />,
        collapse: [
          {
            role:["company-admin", "admin", "super-admin"],
            type: "collapse",
            name: "Gestion Charges",
            key: "charges",
            icon: <RequestQuoteIcon size="12px" />,
            route: "/administration/charges/listCharges",
            component: <ListCharge />,
          },
          {
            name: "Facturations",
            key: "billing",
            route: "/billing/settings",
            component: <BillingSettings />,
            icon: <RequestQuoteIcon size="12px" />,
            collapse: [
              {
                name: "Devis",
                key: "quotations",
                route: "/billing/quotations",
                component: <QuotationsList />,
              },
              {
                name: "Factures",
                key: "factures",
                route: "/billing/factures",
                component: <InvoicesList />,
              },
              {
                name: "Préinscription",
                key: "preinscriptions",
                route: "/billing/preinscriptions",
                component: <InscriptionList type="pre-insc" />,
              },
              {
                name: "Inscriptions",
                key: "inscriptions",
                route: "/billing/inscriptions",
                component: <InscriptionList type="insc" />,
              },
              {
                name: "Paramètres",
                key: "settings",
                route: "/billing/settings",
                component: <BillingSettings />,
              },
            ],
          },
        ],
      }, 
      {
        name: "Utilisateurs",
        role: ["company-admin","admin", "editor","instructor","responsable","student"],
        //key: "users",
        route: "/administration/collaborator",
        component: <ListCardsCollaborator />,
        collapse: [
          { 
            role: ["company-admin","admin", "editor"],
            name: "Collaborateurs",
            key: "collaborator",
            route: "/administration/collaborator",
            component: <ListCardsCollaborator />,
          },
          { 
            role: ["company-admin","admin", "editor","instructor"],
            name: "Instructeurs",
            key: "instructor",
            route: "/administration/instructor",
            component: <ListCardsInstractor />,
          },
          { 
            role: ["company-admin","admin", "editor","instructor","responsable"],
            name: "Responsables",
            key: "responsables",
            route: "/administration/responsables",
            component: <ListCardsClient />,
          },
          { 
            role: ["company-admin","admin", "editor","instructor","responsable","student"],
            name: "Étudiants",
            key: "students",
            route: "/administration/students",
            component: <ListCardsStudent />,
          },
        ],
      },
    ],
  },

  {
    role: ["company-admin","admin", "editor","responsable","student", "instructor"],
    type: "collapse",
    name: "Scolarité",
    key: "schooling",
    icon: <AssuredWorkloadIcon size="12px" />,
    collapse: [
      {
        role: ["admin", "editor","responsable","student", "instructor"],
        type: "collapse",
        name: "Demandes",
        key: "demandes",
        icon: <RequestQuoteIcon size="12px" />,
        route: "/demandes/kanban",
        component: <KanbanDemande />,
        collapse: [
          {
            name: "Kanban Demandes",
            key: "kanban",
            route: "/demandes/kanban",
            component: <KanbanDemande />,
          },
          {
            role: ["admin", "editor"],
            name: "Liste Demandes",
            key: "listesDemandes",
            route: "/demandes/listesDemandes",
            component: <ListDemandeDocument />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Evènements",
        key: "events",
        icon: <EmojiEvents size="12px" />,
        route: "/events/kanbanEvents",
        component: <KanbanEvents />,
        collapse: [
          {
            name: "Kanban Events",
            key: "kanbanEvents",
            route: "/events/kanbanEvents",
            component: <KanbanEvents />,
          },
          {
            name: "Liste Events",
            key: "listEvents",
            route: "/events/listEvents",
            component: <ListEvents />,
          },
        ],
      },
    ],
  },
  {
    etablisementType: ["faculty", "lycee"],
    role: ["admin", "editor", "company-admin"],
    type: "collapse",
    name: "Plans d'etudes",
    key: "plan",
    route: "/plan/plan",
    icon: <SummarizeIcon size="12px" />,
    component: <Table />,
    collapse: [
      {
        name: "Paramètrage",
        collapse: [
          {
            name: "Années scolaires",
            key: "parametres",
            route: "/plan/parametres/period",
            component: <PeriodeTable />,
            collapse: [
              {
                name: "Périodes",
                key: "period",
                route: "/plan/parametres/period",
                component: <PeriodeTable />,
              },
              {
                name: "Sous Périodes",
                key: "sous-period",
                route: "/plan/parametres/sous-period",
                component: <SemesterTable />,
              },
            ],
          },
          {
            name: "Bloc et salles",
            key: "parametresalle",
            route: "/plan/parametresalle/bloc",
            component: <BlocTable />,
            collapse: [
              {
                name: "Bloc des salles",
                key: "bloc",
                route: "/plan/parametresalle/bloc",
                component: <BlocTable />,
              },
              {
                name: "Types des salles",
                key: "type",
                route: "/plan/parametresalle/type",
                component: <TypeTable />,
              },
              {
                name: "Nom des salles",
                key: "salle",
                route: "/plan/parametresalle/salle",
                component: <SalleTable />,
              },
            ],
          },
        ],
      },
      {
        name: "Domaines",
        key: "domain",
        route: "/plan/domain",
        component: <DomainTable />,
      },
      {
        name: "Départements",
        key: "departements",
        route: "/plan/departements",
        component: <DepartementTable />,
      },
      {
        name: "Formations",
        key: "formation",
        route: "/plan/formation",
        component: <FormationTable />,
      },
      {
        name: "Filiéres",
        key: "filiere",
        route: "/plan/filiere",
        component: <FiliereTable />,
      },
      {
        name: "Niveaux",
        key: "level",
        route: "/plan/level",
        component: <LevelTable />,
      },
      {
        name: "Tronc Commun",
        key: "tronc-commun",
        route: "/plan/tronc-commun",
        component: <TroncCommunTable />,
      },
      {
        name: "Spécialitées",
        key: "speciality",
        route: "/plan/speciality",
        component: <SpecialityTable />,
      },
      {
        name: "Parcours",
        key: "parcours",
        route: "/plan/parcours",
        component: <ParcoursTable />,
      },
      {
        name: "Modules",
        key: "modules",
        route: "/plan/modules",
        component: <ModuleTable />,
      },
      {
        name: "Matières",
        key: "subject",
        route: "/plan/subject",
        component: <SubjectTable />,
      },
    ],
  },
  { 
    etablisementType: ["centreDeFormation"],
    role: ["admin", "editor", "company-admin"],
    type: "collapse",
    name: "Matières",
    key: "subject",
    route: "/subject",
    component: <SubjectTable />,
    icon: <LocalLibraryIcon size="12px" />,
  },
  { 
  
    role: ["company-admin","admin", "editor","instructor","student","responsable"],
    type: "collapse",
    name: "Classes",
    key: "classes",
    route: "/classes",
    component: <ListCardsClass />,
    icon: <Groups2Icon size="12px" />,
  },

  {
    role: [/* "company-admin","admin", "editor","instructor","student","responsable" */],
      
    type: "collapse",
    name: "Planification",
    key: "planning",
    route: "/planning/emplois",
    component: <ListCardEmploi />,
    icon: <Document size="12px" />,
    collapse: [

      {
        name: "Emplois",
        key: "emplois",
        route: "/planning/emplois",
        component: <ListCardEmploi />,
      },
      {
        name: "Examens",
        key: "examens",
        route: "/planning/examens",
        component: <ListCardExamen />,
      },
    ],
  },

  {
    role: [],
    type: "collapse",
    name: "Cours",
    key: "cours",
    route: "/cours/matieres",
    component: <ListCardMatiere />,
    icon: <AutoStoriesIcon size="12px" />,
    collapse: [
      {
        name: "Emploi de temps",
        key: "emploi-temps",
        route: "/cours/emploi-temps",
        component: <Main />,
      },
      {
        name: "Emploi d'examen",
        key: "emploi-examen",
        route: "/cours/emploi-examen",
        component: <Main />,
      },
      {
        name: "Matières",
        key: "matieres",
        route: "/cours/matieres",
        component: <ListCardMatiere />,
      },
      {
        name: "Notes",
        key: "notes",
        route: "/cours/notes",
        component: <Main />,
      },
      {
        name: "Moyenne",
        key: "moyenne",
        route: "/cours/moyenne",
        component: <Main />,
      },
      {
        name: "Présence",
        key: "presence",
        route: "/cours/presence",
        component: <Main />,
      },
      {
        name: "Evaluation",
        key: "evaluation",
        route: "/cours/evaluation",
        component: <Main />,
      },
    ],
  },
  {
    role: [],
    type: "collapse",
    name: "Historique",
    key: "history",
    route: "/history/default",
    component: <Default />,
    icon: <HistoryEduIcon size="12px" />,
  },
  {
    role: [],
    type: "collapse",
    name: "Video Conferance",
    key: "video",
    route: "/video",
    component: <VideoConferance />,
    icon: <DuoIcon size="12px" />,
  },
  {
    role: [],
    type: "collapse",
    name: "Chat",
    key: "chat",
    route: "/chat",
    component: <ChatCard />,
    icon: <ChatIcon size="12px" />,
  },
  {
    role: ["super-admin"],
    type: "collapse",
    name: "Desktop App",
    key: "desktop",
    route: "/desktop",
    component: <DesktopApplication />,
    icon: <HomeIcon size="12px" />,
  }
];

const filterRoutesByRoleAndType = (routes, userRole, facilityType) => {
  return routes.map(route => {
    const filteredRoute = { ...route };

    // If the route has nested routes, filter them recursively
    if (filteredRoute.collapse && filteredRoute.collapse.length > 0) {
      filteredRoute.collapse = filterRoutesByRoleAndType(filteredRoute.collapse, userRole, facilityType);
    }

    // Include the route if its role matches the user's role
    const roleMatches = !filteredRoute.role || filteredRoute.role.includes(userRole);
    
    // Include the route if its etablisementType matches the facility type
    const typeMatches = !filteredRoute.etablisementType || filteredRoute.etablisementType.includes(facilityType);

    // Include the route if it has nested routes after filtering or if both role and type match
    if (filteredRoute.collapse && filteredRoute.collapse.length > 0) {
      return roleMatches && typeMatches ? filteredRoute : null;
    }

    // Include the route if both role and type match
    if (roleMatches && typeMatches) {
      return filteredRoute;
    }

    // Exclude routes where the role or type doesn't match
    return null;
  }).filter(route => route !== null); // Remove null entries from the array
};

// Apply role-based and type-based filtering to the routes
const routesFiltered = user === null ? routes : filterRoutesByRoleAndType(routes, user.role, facility?.type);


// const filterRoutesByRole = (routes, userRole) => {
//   routes.filter(route =>{
//     if (route.role && route.role.length > 0 && route.role.includes(user.role)) {
//       return true; // Include routes where user's role matches route's role
//     } else if (route.role === undefined || route.role === null) {
//       return true; // Include routes where role is undefined or null
//     } else {
//       return false; // Exclude routes where role doesn't match and isn't undefined or null
//     }
//   });
// };

// const routesFiltered = user === null ? routes : filterRoutesByRole(routes, user.role);


export default routesFiltered;
