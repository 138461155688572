import { Card, CardContent, CircularProgress, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import React, { useEffect, useState } from "react";
import SoftButton from "components/SoftButton";
import { useNavigate, useParams } from "react-router-dom";
import api from "utils/axios";


import ProfileInfoCard from "components/shared-users/ProfileInfoCard";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";


import { downloadFile } from "../../../utils/citysTn";
import SoftAvatar from "components/SoftAvatar";
import Header from "layouts/pages/profile/components/Header";

export default function View() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [instractor, setInstractor] = useState({});
  const [loading, setLoading] = useState(false);

  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const handleFetchInstractorById = async (id) => {
    try {
      setLoading(true);
      if (id) {
        const response = await api.get(`/get-user/${id}`);
        const fetchInstractor = response.data;
        setLoading(false);
        setInstractor(fetchInstractor);
      }
    } catch (error) {
      setLoading(true);
      console.log(error);
    }
  };
  useEffect(() => {
    if (id !== undefined) {
      handleFetchInstractorById(id);
    }
  }, [id]);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Header title="informations de profil" searchVisible={false} ExportVisible={false} />
      {loading ? (
        <SoftBox
          sx={{
            mt: 25,
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </SoftBox>
      ) : (
        <>
          <Card sx={{ height: "100%" }}>
            <SoftBox p={1.5} mt={2} ml={1.5}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <SoftAvatar
                    src={
                      instractor?.imageUrl?.startsWith("https")
                        ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                        : `${process.env.REACT_APP_BASE_URL}/${instractor?.imageUrl}`
                    }
                    variant="rounded"
                    size="xl"
                    shadow="sm"
                  />
                </Grid>
                <Grid item>
                  <SoftBox height="100%" mt={0.5} lineHeight={1}>
                    <SoftTypography variant="h5" fontWeight="medium">
                      {instractor?.firstName} {instractor?.lastName}
                    </SoftTypography>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      {instractor?.role}
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} xl={6}>
                  <ProfileInfoCard
                    info={{
                      email: instractor?.email,
                      "Date de Naissance": instractor?.birthday,
                      genre: instractor?.gender,
                      cin: instractor?.cin,
                      "Numéro Téléphone": instractor?.phoneNumber
                        ? `+${instractor.phoneNumber}`
                        : "",
                      "Deuxième Téléphone": instractor?.secondaryPhoneNumber
                        ? `+${instractor.secondaryPhoneNumber}`
                        : "",
                      adresse: instractor?.address,
                      "code Postal": instractor?.zipCode,
                      pays: instractor?.country,
                      ville: instractor?.city,
                    }}
                  />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <ProfileInfoCard
                    info={{
                      "Site Web": instractor?.siteWeb,
                      Occupation: instractor?.occupation,
                      passeport: instractor?.passeport,
                      "Nom De Banque": instractor?.bank,
                      rib: instractor?.rib,
                      "Numéro CNSS": instractor?.cnssNumber,
                      fichier: (
                        <SoftBox component="ul" ml={-3} pl={2}>
                          {instractor?.files?.map((file, index) => (
                            <SoftBox
                              component="li"
                              color="text"
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              fontSize="1rem"
                              lineHeight={2}
                              key={index}
                              mb={2}
                              mt={1}
                              sx={{
                                p: 0.75,
                                borderBottom: `1px solid black`,
                                backgroundColor: index % 2 === 0 ? "#F2F2F2" : "transparent",
                              }}
                            >
                              <SoftTypography variant="button" color="text" fontWeight="regular"  
                              sx={{
                                whiteSpace: "nowrap", 
                                overflow: "hidden",
                                textOverflow: "ellipsis", 
                                maxWidth: "90%", 
                              }}>
                                {file}
                              </SoftTypography>
                              <FileDownloadIcon
                                onClick={() => downloadFile(file.name ? file.name : file)}
                                fontSize="small"
                                sx={{ cursor: "pointer" }}
                              />
                            </SoftBox>
                          ))}
                        </SoftBox>
                      ),
                      description: (instractor?.comment ?
                        (<Card variant="outlined"sx={{  backgroundColor: "#F2F2F2", }}>
                          <CardContent >
                            <SoftTypography
                              variant="button"
                              color="text"
                              fontWeight="regular"
                              sx={{ textAlign: "justify" }}
                            >
                              {instractor?.comment}
                            </SoftTypography>
                          </CardContent>
                        </Card>):""
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <SoftBox mt={3}>
            <Grid
              item
              xs={12}
              lg={12}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
              container
            >
              <SoftButton
                id="btn-cancel"
                onClick={() => navigate("/administration/instructor")}
                variant="gradient"
                color="purple"
                size="small"
              >
                Retour
              </SoftButton>
            </Grid>
          </SoftBox>
            </SoftBox>
          </Card>
      
        </>
      )}
    </DashboardLayout>
  );
}
