import { CircularProgress, Box } from '@mui/material';
import SoftBox from 'components/SoftBox';
import React, { useState } from 'react';

export default function Main() {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && (
        <SoftBox
          sx={{
            mt: 20,
            textAlign: "center",
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress sx={{ mr: 1 }} />
            <span style={{ fontSize: '1.2rem' }}>En cours</span>
          </Box>
        </SoftBox>
      )}
    </>
  );
}
