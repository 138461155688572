import checkout from "pages/enterprise/schema/enterprise-schemas/EnterpriseSchema";

const {formField:
    {
     companyName,
     serialNumber,
     email,
     siteWeb,
     phone,
     secondaryPhone,
     physiqualAddress,
     postCode,
     logoUrl,
     description,
     files,
     CompanyAdminfirstName,
     CompanyAdminlastName,
     CompanyAdminemail,
     CompanyAdmincin,
     CompanyAdminsiteWeb,
     CompanyAdminoccupation,
     CompanyAdminaddress,
     CompanyAdminzipCode,
     CompanyAdminbank,
     CompanyAdminrib,
     CompanyAdmincnssNumber,
     CompanyAdminphone,
     CompanyAdminSecondaryphone,
     facilityName,
     facilityType,
     CompanyAdmingender,
     CompanyAdminpassport,
     CompanyAdminfiles,
     CompanyAdminimageUrl,
     CheckedStatus,
     country,
     city,
     CompanyAdminbirthday,
     CompanyAdminDescription
    }} 
    = checkout;
const initialValues = {
    [companyName.name]: "",
    [serialNumber.name]: "",
    [email.name]: "",
    [siteWeb.name]: "",
    [phone.name]: "",
    [logoUrl.name]: null,
    [files.name]: [],
    [secondaryPhone.name]: "",
    [physiqualAddress.name]: "",
    [country.name]:"",
    [city.name]:"",
    [postCode.name]: "",
    [description.name]: "",
    [CompanyAdminfirstName.name]: "",
    [CompanyAdminlastName.name]: "",
    [CompanyAdminphone.name]: "",
    [CompanyAdminSecondaryphone.name]: "",
    [CompanyAdminemail.name]: "",
    [CompanyAdmincin.name]: "",
    [CompanyAdminsiteWeb.name]: "",
    [CompanyAdminoccupation.name]: "",
    [CompanyAdminaddress.name]: "",
    [CompanyAdminzipCode.name]: "",
    [CompanyAdminbank.name]: "",
    [CompanyAdminrib.name]: "",
    [CompanyAdmincnssNumber.name]: "",
    [CompanyAdminbirthday.name]: "",
    [CompanyAdmingender.name]: "",
    [CompanyAdminpassport.name]: "",
    [CompanyAdminfiles.name]: [],
    [CompanyAdminimageUrl.name]: null,
    [CheckedStatus.name]: [""],
    [CompanyAdminDescription.name]: "",

}

export default initialValues;