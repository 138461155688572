//import layouts
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import SoftTypography from 'components/SoftTypography';
import {
  Autocomplete,
  Box,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

//colors
import { green, grey } from "@mui/material/colors";

//hooks
import { useEffect, useState } from "react";

//components
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";

import SoftSelect from "components/SoftSelect";
import SelectInfo from "./data/SelectInfo";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

import Swal from "sweetalert2";

import { Formik, Form, Field } from "formik";
import { CloudUpload, DateRange, InsertInvitation } from "@mui/icons-material";

import validationSchema from "./validerChamps/ValidationSchema";
import api from "utils/axios";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from 'prop-types';


const initialValues = {
  nom: "",
  description: "",
  responsable: "",
  dateLimite: "",
};
function AjouterTâche() {
  const { projectID } = useParams();

  const navigate = useNavigate();
  const facility = JSON.parse(localStorage.getItem("facility"));

  const [nom, setnom] = useState("");
  const [description, setDescription] = useState("");
  const [observateurs, setObservateurs] = useState([]);
  const [responsable, setResponsable] = useState("");
  // const [entreprise, setEntreprise] = useState("");
  // changer Evaluation
  const [evaluation, setEvaluation] = useState("Aucune");
  const [fichiers, setFichiers] = useState([]);
  const [dateLimite, setDateLimite] = useState("");
  const [rappel, setRappel] = useState([]);
  // change select { A faire , En cours , Terminer}
  const [statut, setStatut] = useState("A faire");
  //changer select { Haute , Moyenne , Faible }
  const [priorite, setPriorite] = useState("Haute");

  //telecharger fichier
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    const newOption = { value: file, label: file.name };
    // Check if the file is already selected
    const isSelected = selectedOptions.some((option) => option.label === newOption.label);
    if (!isSelected) {
      // If it's not selected, add it to both options and selectedOptions
      setOptions([...options, newOption]);
      setSelectedOptions([...selectedOptions, newOption]);
    }
  };

  useEffect(() => {
    setFichiers(selectedOptions);
  }, [selectedOptions]);

  //get all the email of observateurs
  const [emailObservateurs, setEmailObservateurs] = useState([]);
  const [emailResponsables, setEmailResponsables] = useState([]);
  const getEmailObservateursEtResponsables = () => {
    let endpoint;
    // Determine the endpoint URL based on facility type
    if (facility?.type === "centreDeFormation") {
      endpoint = `training-company/get-emails-center-formation/${facility._id}`;
    } else {
      endpoint = `faculty/get-emails-faculty/${facility._id}`;
    }
    api.get(endpoint, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      const observateursEmail = response.data.emailsAdminEditor.map((observateur) => observateur);
      setEmailObservateurs(observateursEmail);

      setObservateurs(response.data.emailCompanyAdmin.map((emailcomp) => emailcomp));

      const responsablesEmail = response.data.emailsAdminEditor.map((responsableEmail) => responsableEmail);
      setEmailResponsables(responsablesEmail);
    });
  };

  useEffect(() => {
    getEmailObservateursEtResponsables();
  }, [setEmailObservateurs, setEmailResponsables]);

  const token = localStorage.getItem("accessToken")
  const createTache = () => {
    const fd = new FormData();
    fd.append("nom", nom),
    fd.append("description", description),
    fd.append("observateurs", observateurs),
    fd.append("responsable", responsable),
    fd.append("evaluation", evaluation),
    fichiers &&
    fichiers.length > 0 &&
    fichiers.forEach((item) => {
      fd.append("fichiers", item.value);
    }),
    fd.append("dateLimite", dateLimite),
    fd.append("rappel", rappel),
    fd.append("statut", statut),
    fd.append("priorite", priorite);
  
    if (nom !== "" && description !== "" && responsable !== "" && dateLimite !== "") {
      let endpoint;
      // Determine the endpoint URL based on facility type
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/create-tache-center-formation/${projectID}`;
      } else {
        endpoint = `faculty/create-tache-faculty/${projectID}`;
      }
  
      api
        .post(endpoint, fd)
        .then((res) => {
          Swal.fire({
            title: "Succès",
            text: "Ajouter tâche réussie",
            icon: "success",
            confirmButtonText: "ok",
          });
  
          navigate(`/suiviTache/listeTaches/${projectID}`);
        });
    } else {
      Swal.fire({
        title: "Warning",
        text: "Ajouter Tous Les Champs Obligatoires.",
        icon: "warning",
        confirmButtonText: "ok",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ errors, touched, handleSubmit, handleChange, handleBlur }) => (
          <Form onSubmit={handleSubmit} style={{ marginTop: "16px" }}>

            <Card
              sx={{
                border: `1px solid ${grey[200]}`,
                bgcolor: "white",
                borderRadius: "15px",
                p: "20px",
                mt: "16px",
                overflow: "visible",
              }}
            >
              <Typography variant="h5" sx={{ flexGrow: "1" }}>
                Nouvelle Tâche
              </Typography>

              <Grid container>
                <Grid container mt="8px" xs={12} md={6}>


                  <Grid item xs={12} sx={{ display: "block" }}>
                    <Box mr="8px">
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                        Nom de tâche*
                      </SoftTypography>
                      <Field name="nom">
                        {({ field }) => (
                          <SoftInput
                            {...field}
                            variant="outlined"
                            fullWidth
                            placeholder="Nom Tâche"
                            error={!!errors.nom && !!touched.nom}
                            success={field.value.length >= 2 && !!touched.nom}
                            onBlur={handleBlur}
                            onChange={(event) => {
                              handleChange(event);
                              setnom(event.target.value);
                            }}
                          />
                        )}
                      </Field>
                      {!!errors.nom && !!touched.nom && (
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "400", fontSize: "12px" }}
                          color="error"
                        >
                          {errors.nom}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sx={{ display: "block", mt: "16px" }}>
                    <Box mr={{ xs: "0px", md: "8px" }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                        Description*
                      </SoftTypography>
                      <Field name="description">
                        {({ field }) => (
                          <SoftInput
                            {...field}
                            multiline
                            rows={4}
                            placeholder="Description"
                            variant="outlined"
                            error={!!errors.description && !!touched.description}
                            success={field.value.length >= 5 && !!touched.description}
                            onBlur={handleBlur}
                            onChange={(event) => {
                              handleChange(event);
                              setDescription(event.target.value);
                            }}
                          />
                        )}
                      </Field>
                      {!!errors.description && !!touched.description && (
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "400", fontSize: "12px" }}
                          color="error"
                        >
                          {errors.description}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                <Grid container xs={12} md={6}>
                  <Grid item xs={12} sx={{ display: "block" }}>
                    <Box mt="8px" ml={{ xs: "0px", md: "8px" }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                      // sx={{ mb: 0.5 }}
                      >
                        Observateurs
                      </SoftTypography>

                      <SoftSelect
                        defaultValue={[{ value: "Ajouter | +", label: "Ajouter | +" }]}
                        options={emailObservateurs.map((emailObservateur) => {
                          return {
                            value: emailObservateur,
                            label: emailObservateur,
                          };
                        })}
                        size="medium"
                        isMulti
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions
                            .filter((option) => option.value !== "Ajouter | +")
                            .map((option) => option.value);
                          //j'utiliser ce code pour supprimer la redondance des valeurs
                          const uniqueValues = Array.from(
                            new Set([...observateurs, ...selectedValues])
                          );
                          setObservateurs(uniqueValues);
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sx={{ display: "block", mt: "8px" }} mt={2}>
                    <Box ml={{ sx: "0px", md: "8px" }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                        Responsable*
                      </SoftTypography>
                      <Field name="responsable">
                        {({ _ }) => (
                          <SoftSelect
                            options={emailResponsables.map((emailResponsable) => {
                              return {
                                value: emailResponsable,
                                label: emailResponsable,
                              };
                            })}
                            placeholder="choisissez  un commercial...."
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setResponsable(event.value);
                            }}
                          />
                        )}
                      </Field>
                      {responsable === "" && !!touched.responsable && (
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "400", fontSize: "12px" }}
                          color="error"
                        >
                          {errors.responsable}
                        </Typography>
                      )}
                    </Box>
                  </Grid>


                  <Grid item xs={12} sx={{ display: "block", mt: "16px" }}>
                    <Box ml={{ sx: "0px", md: "8px" }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                        Évaluation :
                      </SoftTypography>
                      <FormControl>
                        <RadioGroup sx={{ mt: "8px" }} defaultValue="Aucune">
                          <Box display="flex" alignItems="center">
                            <FormControlLabel
                              sx={{ ml: { xs: "0px", md: "4px" } }}
                              value="Aucune"
                              control={<Radio />}
                              label="Aucune"
                              onClick={() => {
                                setEvaluation("Aucune");
                              }}
                            />
                            <FormControlLabel
                              sx={{ ml: "4px" }}
                              value="Positive"
                              control={<Radio />}
                              label="Positive"
                              onClick={() => {
                                setEvaluation("Positive");
                              }}
                            />
                            <FormControlLabel
                              sx={{ ml: "4px" }}
                              value="Négative"
                              control={<Radio />}
                              label="Négative"
                              onClick={() => {
                                setEvaluation("Négative");
                              }}
                            />
                          </Box>
                        </RadioGroup>
                      </FormControl>
                    </Box>

                  </Grid>

                </Grid>
              </Grid>

              <Grid item xs={12} md={12} sx={{ display: "block", mt: "16px" }}>
                <Box >
                  <SoftButton
                    component="label"
                    variant="contained"
                    fontWeight="medium"
                    onChange={handleFileUpload}
                    startIcon={<CloudUpload />}
                    sx={{ mb: 0.5 }}
                  >
                    Télécharger des fichiers
                    <VisuallyHiddenInput type="file" />
                  </SoftButton>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    placeholder="Télécharger des fichiers"
                    options={options}
                    value={selectedOptions}
                    filterSelectedOptions
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Télécharger des fichiers"
                      />
                    )}
                    onChange={(event, newValue) => {
                      setSelectedOptions(newValue);
                    }}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                  />
                </Box>
              </Grid>




              <Grid container mt="8px">
                <Grid item xs={12} md={6} sx={{ display: "block" }}>
                  <Box mr={{ sx: "0px", md: "8px" }}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      sx={{ mb: 0.5 }}
                    >
                      Date limite*
                    </SoftTypography>
                    <Box sx={{ width: "100%", position: "relative" }}>
                      <Field name="dateLimite">
                        {({ field }) => (
                          <DatePicker
                            placeholder="jj / mm / aaaa --:--"
                            format="DD/MM/YYYY HH:mm:ss"
                            plugins={[<TimePicker key="timePicker" position="bottom" />]}
                            style={{
                              width: "100%",
                              position: "absolute",
                              right: "0px",
                              left: "0px",
                              top: "0px",
                              padding: "20px 12px",
                              cursor: "pointer",
                              borderRadius: "10px",
                              borderColor:
                                dateLimite === "" && !!touched.dateLimite
                                  ? "red"
                                  : field.value
                                    ? green[300]
                                    : grey[300],
                            }}
                            onChange={(selectedOption) => {
                              setDateLimite(selectedOption.format());
                            }}
                            minDate={new Date()}
                          />
                        )}
                      </Field>

                      <Box
                        sx={{
                          position: "absolute",
                          top: "70%",
                          right: "12px",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <InsertInvitation
                          sx={{ width: "16px ! important", height: "16px !important" }}
                        />
                      </Box>
                    </Box>
                    {dateLimite === "" && !!touched.dateLimite && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "400", fontSize: "12px", mt: "10px" }}
                        color="error"
                      >
                        {errors.dateLimite}
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block" }}>
                  <Box ml={{ sx: "0px", md: "8px", position: "relative" }}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      sx={{ mb: 0.5 }}
                    >
                      Rappel
                    </SoftTypography>

                    <Box sx={{ position: "relative", width: "100%" }}>
                      <DatePicker
                        placeholder="jj / mm / aaaa --:--"
                        multiple
                        format="DD/MM/YYYY HH:mm:ss"
                        plugins={[
                          <TimePicker key="timePicker" position="bottom" />,
                          <DatePanel key="datePannel" />,
                        ]}
                        style={{
                          width: "100%",
                          position: "absolute",
                          right: "0px",
                          left: "0px",
                          top: "0px",
                          padding: "20px 40px 20px 12px",
                          cursor: "pointer",
                          borderRadius: "10px",
                        }}
                        onChange={(selectedOptions) => {
                          setRappel(selectedOptions.map((option) => option.format()));
                        }}
                        minDate={new Date()}
                        maxDate={dateLimite}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: "70%",
                          right: "12px",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <DateRange sx={{ width: "16px ! important", height: "16px !important" }} />
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box mr={{ sx: "0px", md: "8px" }} position="relative">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      sx={{ mb: 0.5 }}
                    >
                      Statut
                    </SoftTypography>

                    <SoftSelect
                      options={SelectInfo.statut}
                      defaultValue={SelectInfo.statut.find((stat) => stat.label === statut)}
                      onChange={(event) => {
                        setStatut(event.value);
                      }}
                    />
                  </Box>
                </Grid>



                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box ml={{ sx: "0px", md: "8px" }} mr={{ sx: "0px", md: "8px" }}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      sx={{ mb: 0.5 }}
                    >
                      Priorité
                    </SoftTypography>
                    <SoftSelect
                      options={SelectInfo.priorite}
                      defaultValue={SelectInfo.priorite.find((prio) => prio.label === priorite)}
                      onChange={(event) => {
                        setPriorite(event.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <Typography>
                  </Typography>
                  <SoftButton
                    type="submit"
                    variant="gradient"
                    color="purple"
                    onClick={() => {
                      createTache();
                    }}
                  >
                    Ajouter Une Tâche
                  </SoftButton>
                </Box>
              </Grid>

            </Card>
          </Form>
        )}
      </Formik>

    </DashboardLayout>
  );
}
AjouterTâche.propTypes = {
  projectID: PropTypes.string.isRequired,
};

export default AjouterTâche;
