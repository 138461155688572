import { Button } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import { useEffect } from "react";

export default function DesktopApplication() {
  const token = localStorage.getItem("refreshToken") || sessionStorage.getItem("refreshToken");
  const userData = atob(token.split('.')[1]);
  const parsedData =JSON.parse(userData);
  
  const handleClick = () => {
    window.location.href = `studdify://open?token=${parsedData.email}`;
  }
  const isDesktop = () => {
    return typeof window !== 'undefined' && typeof window.__TAURI__ !== 'undefined';
  };

  useEffect(() => {
    if (isDesktop()) {
      console.log('Desktop application is running');
    } else {
      console.log('Desktop application is not running');
    }
  }, [window.process]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Button onClick={handleClick} variant="contained" color="primary">
          Desktop Application
        </Button>
      </div>
    </DashboardLayout>
  );
}
