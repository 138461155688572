import React, { useState } from "react";
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import api from "utils/axios";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSoftUIController } from "context";

function TypeActions({ type, handleFetch, setShowDetails, setDetailsData }) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(type.name);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [controller] = useSoftUIController();

  const handleUpdate = async () => {
    try {
      setLoading(true);
      if (name.length < 2) {
        // Set the error message
        setErrorMessage("La longueur minimale requise est de 2 caractères.");
      } else {
        const response = await api.put(`/faculty/update-type-salle/${type._id}`, { name });
        setName(response.data.name);
        Swal.fire("Success!", "Mise à jour type réussie", "success");
        handleFetch();
        setOpen(false);
      }
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setName(type.name);
  };
  const showAlert = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous voulez supprimer ce type ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(`/faculty/delete-type-salle/${type._id}`);
            if (response) {
              (await Swal.fire("Suppression !", "Le type a été supprimée.", "success"))
                .isConfirmed && handleFetch();
            }
          } catch (error) {
            Swal.fire("Erreur!", "Le type n'a pas été supprimée.", "error");
          }
        }
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };
  return (
    <SoftBox display="flex" alignItems="center">
       <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Consulter" placement="left">
          <VisibilityIcon
            onClick={() => {
              setDetailsData(type);
              setShowDetails(true);
            }}
          />
        </Tooltip>
      </SoftTypography> 
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Modifier" placement="top">
            <Icon onClick={() => setOpen(true)} id="edit-enterprise-page">
              edit
            </Icon>
          </Tooltip>
          <Dialog fullWidth open={open} onKeyDown={handleKeyPress}>
            <DialogTitle>Modifier type</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.grey[200],
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <DialogContentText>
                Vous devez saisir le nouveau nom de votre type.
              </DialogContentText>
              <Grid>
                <SoftTypography variant="caption" fontWeight="bold" color="text">
                  Nom du type*
                </SoftTypography>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <SoftInput
                    name="name"
                    margin="normal"
                    id="name"
                    label="type"
                    type="text"
                    fullWidth
                    placeholder="Nom du type"
                    inputProps={{
                      minLength: 2,
                      maxLength: 50,
                    }}
                    variant="standard"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={name.length < 2}
                    success={name.length >= 2}
                    autoFocus
                  />
                  {name.length < 2 && (
                    <SoftTypography m={0.75} variant="caption" color="error">
                      {errorMessage}
                    </SoftTypography>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                <SoftButton onClick={handleClose} sx={{ mt: 2 }} size="small" variant="outlined" color="dark">
                  Annuler
                </SoftButton>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <SoftButton
                    onClick={handleUpdate}
                    sx={{ mt: 2 }}
                    variant="gradient"
                    color="customized"
                    size="small"
                  >
                    Modifier
                  </SoftButton>
                )}
              </SoftBox>
            </DialogActions>
          </Dialog>
        </SoftTypography>
      </SoftBox>
      {controller?.user?.info?.role === "company-admin" && (
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Supprimer" placement="left">
            <Icon id="delete-enterprise" onClick={showAlert}>
              delete
            </Icon>
          </Tooltip>
        </SoftTypography>
      )}
    </SoftBox>
  );
}
TypeActions.propTypes = {
  type: PropTypes.object.isRequired,
  setDetailsData: PropTypes.array.isRequired,
  setShowDetails: PropTypes.bool.isRequired,
  handleFetch: PropTypes.func.isRequired,
};

export default TypeActions;
