export default function comparerSansEspacesEtCases(chaine1, chaine2) {
    // Supprimer les espaces et convertir en minuscules pour les deux chaînes
    var chaine1Modifiee = chaine1.replace(/\s/g, '').toLowerCase();
    var chaine2Modifiee = chaine2.replace(/\s/g, '').toLowerCase();
    // Comparer les chaînes modifiées
    if (chaine1Modifiee === chaine2Modifiee) {
        return true;
    } else {
        return false;
    }
}