import React, { useEffect, useState } from "react";
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import api from "utils/axios";
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSoftUIController } from "context";
function TroncCommunActions({ tronc, handleFetch, setShowDetails, setDetailsData }) {

  const [open, setOpen] = useState(false);
  const [name, setName] = useState(tronc.name);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [controller, dispatch] = useSoftUIController();
  const [departement, setDepartement] = useState(tronc?.departement?._id || "");
  const [sousPeriodes, setsousPeriodes] = useState(tronc.sousPeriodes || []);
  const [OptionsSP, setOptionsSP] = useState([])
  const [errorMessageSp, setErrorMessageSp] = useState("");

  useEffect(() => {
    api.get(`/faculty/all-departements/${facility.scholarityConfigId}`).then((res) => {
      setOptions(
        res.data.map((item) => {
          return {
            value: item._id,
            label: item.name,
          };
        })
      );
    });
    setDepartement(tronc?.departement?._id || "")
    api.get(`/faculty/all-sp/${facility.scholarityConfigId}`).then((res) => {
          setOptionsSP(
            res.data.map((item) => {
              return {
                value: item._id,
                label: item.name,
              };
            })
          );
        });
      }, []);
  // objet sous Periodes => sousPeriode 
  const sous = (sousPeriodes[0]?.sousPeriode ?? sousPeriodes[0]?.value) || sousPeriodes?.map(item => item._id)

  const handleUpdate = async () => {
    try {
      setLoading(true);
      if (name.length < 2) {
        // Set the error message
        setErrorMessage("La longueur minimale requise est de 2 caractères.");
      } else if (sous.length===0) {
        setErrorMessageSp("vous avez sélectionné une sous période au moins");
      } else {
        const response = await api.patch(`/faculty/update-tronc/${tronc._id}`, { name, departement,sousPeriodes:sous  });
        setName(response.data.name);
        setDepartement(response.data.departement);
        setsousPeriodes(response.data.sousPeriodes);
        Swal.fire("Success!", "Mise à jour tronc commun réussie", "success");
        handleFetch();
        setOpen(false);
      }
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setName(tronc.name);
    setDepartement(tronc?.departement || "");
    setsousPeriodes(tronc?.sousPeriodes || "");
  };
  const showAlert = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous voulez supprimer ce tronc commun ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(`/faculty/delete-tronc/${tronc._id}`);
            if (response) {
              (await Swal.fire("Suppression !", "Le tronc commun a été supprimée.", "success"))
                .isConfirmed && handleFetch();
            }
          } catch (error) {
            Swal.fire("Erreur!", "Le tronc commun n'a pas été supprimée.", "error");
          }
        }
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Consulter" placement="left">
          <VisibilityIcon
            onClick={() => {
              setDetailsData(tronc);
              setShowDetails(true);
            }}
          />
        </Tooltip>
      </SoftTypography>
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Modifier" placement="top">
            <Icon onClick={() => setOpen(true)} id="edit-enterprise-page">
              edit
            </Icon>
          </Tooltip>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            onKeyDown={handleKeyPress}
          >
            <DialogTitle>Modifier tronc commun</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.grey[200],
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <DialogContentText>
                Vous devez saisir le nouveau nom de votre tronc commun.
              </DialogContentText>           
              <Grid sx={{ mt: 1 }}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "flex-start",
                  }}
                >    
                  <Grid item xs={12} sm={facility?.type === "faculty" ? 4 : 12}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Nom du tronc commun
                    </SoftTypography>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",

                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                      }}
                    >
                      <SoftInput
                        name="name"
                        margin="normal"
                        id="name"
                        label="spécialité"
                        type="text"
                        fullWidth
                        placeholder="Nom du tronc commun"
                        inputProps={{
                          minLength: 2,
                          maxLength: 50,
                        }}
                        autoFocus
                        variant="standard"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={name?.length < 2}
                        success={name?.length >= 2}
                      />
                      {name?.length < 2 && (
                        <Typography mt={0.75} variant="caption" color="error">
                          {errorMessage}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Nom du département
                    </SoftTypography>
                    <Autocomplete
                      options={options}
                      title="Select département"
                      getOptionLabel={(option) => option.label}
                      clearIcon={null}
                      defaultValue={options.find((option) => option.value === tronc?.departement?._id) || null}
                      onChange={(e, newValue) => {
                        if (newValue !== null && newValue !== undefined) {
                          setDepartement(newValue.value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={tronc?.departement}
                          variant="standard"
                          placeholder="Veuillez sélectionner votre département"
                        />
                      )}
                      disableClearable={true} 
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      sous-période
                    </SoftTypography>
                    <Autocomplete
                    multiple
                    options={OptionsSP}
                    getOptionLabel={(option) => option?.label }
                    clearIcon={null}
                    defaultValue={tronc?.sousPeriodes?.map((sousPeriode) => OptionsSP?.find((option) => option.value === sousPeriode?._id))|| null}
                    onChange={(event, newValue) => {
                      setsousPeriodes(newValue);
                      const updatedInputs = [...sousPeriodes];
                      if (updatedInputs.length > 0) {
                        updatedInputs[0].sousPeriode = newValue.map((value) => value.value);
                        setsousPeriodes(updatedInputs);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                      />
                    )}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                  />
                    {sous?.length ===0 && (
                        <Typography mt={0.75} variant="caption" color="error">
                          {errorMessageSp}
                        </Typography>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                <SoftButton
                  onClick={handleClose}
                  sx={{ mt: 2 }}
                  variant="outlined"
                  color="dark"
                  size="small"
                >
                  Annuler
                </SoftButton>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <SoftButton
                    onClick={handleUpdate}
                    sx={{ mt: 2 }}
                    variant="gradient"
                    color="customized"
                    size="small"
                  >
                    Modifier
                  </SoftButton>
                )}
              </SoftBox>
            </DialogActions>
          </Dialog>
        </SoftTypography>
      </SoftBox>
      {controller?.user?.info?.role === "company-admin" && (
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Supprimer" placement="left">
            <Icon id="delete-enterprise" onClick={showAlert}>
              delete
            </Icon>
          </Tooltip>
        </SoftTypography>
      )}
    </SoftBox>
  );
}
TroncCommunActions.propTypes = {
  tronc: PropTypes.object.isRequired,
  handleFetch: PropTypes.func.isRequired,
  setShowDetails: PropTypes.bool.isRequired,
  setDetailsData: PropTypes.array.isRequired,
};
export default TroncCommunActions;
