import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import { Link } from "react-router-dom";

import SoftTypography from "components/SoftTypography";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CloseIcon from "@mui/icons-material/Close";
import api from "utils/axios";
import Swal from "sweetalert2";
import SoftBox from "components/SoftBox";
import comparerSansEspacesEtCases from "utils/compareEspace";
export default function AddSpeciality({ token }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [departementData, setDepartementData] = useState([]);
  const [spData, setSpdata] = useState([]);
  const [optionsSp, setOptionsSp] = useState([]);
  const [options, setOptions] = useState([]);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const hasOptionsSpData = optionsSp.length > 0;
  const [errorMessageDepartemenet, setErrorMessageDepartemenet] = useState("");
  const [errorMessageSp, setErrorMessageSp] = useState("")
  const [errorMessageSpVide, setErrorMessageSpVide] = useState("")
  const [inputs, setInputs] = useState([{name: "", sousPeriodes: '',  departement: "" }]);

  useEffect(() => {
    api.get(`/faculty/all-departements/${facility.scholarityConfigId}`).then((res) => {
      setDepartementData(res.data);
      setOptions(
        res.data.map((item) => {
          return {
            value: item._id,
            label: item.name,
          };
        })
      );
    });
  }, []);
  useEffect(() => {
    api.get(`/faculty/all-sp/${facility.scholarityConfigId}`).then((res) => {
      setSpdata(res.data);
      setOptionsSp(
        res.data.map((item) => {
          return {
            value: item._id,
            label: item.name,
          };
        })
      );
    });
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setErrorMessageDepartemenet("");
    setErrorMessageSp("");
    setErrorMessageSpVide("");
    setInputs([{name: "", sousPeriodes: '',  departement: ""}]);
  };
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  };
  const handleAddInput = () => {
    setInputs([...inputs, {name: "", sousPeriodes: '',  departement: ""}]);
  };
  const handleRemoveInput = (indexToRemove) => {
    const newInputs = [...inputs];
    if (indexToRemove >= 0 && indexToRemove < newInputs.length) {
      newInputs.splice(indexToRemove, 1);
      setInputs(newInputs);
    }
  };
  const handleAddSpeciality = async () => {
    try {
      setLoading(true);
      const hasInvalidInputs = inputs.some((input) => input.name.length < 2)
      const hasInvalidDepartemenet = inputs.some((input) => !input.departement);
      const hasInvalidSp = inputs.some((input) => !input.sousPeriodes);
      const hasInvalidSplink = inputs.some((input) => !input.sousPeriodes);
      if (hasInvalidInputs) {
        // Set the error message
        setErrorMessage("La longueur minimale requise est de 2 caractères");
      } else if (hasInvalidDepartemenet) {
        setErrorMessageDepartemenet("le département doit être sélectionné")
      } 
       else if (hasInvalidSp && hasInvalidSplink) {
        setErrorMessageSpVide ("Creer une sous période")
        setErrorMessageSp ("vous avez sélectionné une sous période au moins")
      } else {
        const duplicateName = inputs.find(
          (input, index) =>
            index !== inputs.findIndex((otherInput) => comparerSansEspacesEtCases(`${otherInput.name.toLowerCase()} ${otherInput.departement.toLowerCase()}` , `${input.name.toLowerCase()} ${input.departement.toLowerCase()}`))
        );
        if (duplicateName) {
          const departementName = duplicateName.departement;
          let correspondingName = null;
          
          options.forEach(option => {
            if (option.value === departementName) {
              correspondingName = option.label;
            }
          });        
          Swal.fire(
            "Erreur!",
            `Le nom du spécialité ${duplicateName.name} existe déjà dans le departement  ${correspondingName}. Merci de saisir un autre nom !`,
            "error"
          );
        } else {
          await api.post(`/faculty/create-speciality?scholarityConfigId=${facility.scholarityConfigId}`, inputs);
          setOpen(true);
          Swal.fire("Success!", "La spécialité ajoutée avec succès", "success").then(() => {
            setErrorMessage("");
            setErrorMessageDepartemenet("");
            setErrorMessageSp("");
            setErrorMessageSpVide(""),
            setInputs([{ name: "", sousPeriodes: '',  departement: "",checked:false }]);
            token();
            setOpen(false);
          });
        }
      }
    } catch (error) {
      setError(error.response.data.message);
      Swal.fire("Erreur!", error.response.data.message, "error")
    } finally {
      setLoading(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddSpeciality();
    }
  };
  return (
    <div>
      <SoftButton
        id="add" onClick={handleClickOpen} variant="gradient"
        color="purple"
        size="small"
        mb={{ xs: 2, sm: 0 }}
      >
        +Ajouter Spécialité
      </SoftButton>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose} onKeyDown={handleKeyPress}>
        <DialogTitle >Ajouter Spécialité</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            Vous devez saisir le nom de votre spécialité afin de l&apos;ajouter.
          </DialogContentText>
          {inputs.map((input, index) => (
            <Grid key={index} sx={{ mt: 1 }}>           
                <Grid container spacing={1} sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "flex-start",
                }}>
                  <Grid item xs={12} sm={4}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Nom de la spécialité
                    </SoftTypography>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                      }}
                    >
                      <SoftInput
                        name="name"
                        margin="normal"
                        id="name"
                        label="spécialité"
                        type="text"
                        fullWidth
                        placeholder="Nom de la spécialité"
                        inputProps={{
                          minLength: 2,
                          maxLength: 50,
                        }}
                        autoFocus
                        variant="standard"
                        value={input.name}
                        onChange={(event) => handleInputChange(index, event) }               
                        onBlur={() => {
                          const newInputs = [...inputs];
                          newInputs[index].error = input.name.length < 2;
                          setInputs(newInputs);
                        }}
                        error={input.error && input.name.length < 2}
                        success={input.name.length >= 2}
                      />
                      {input.name.length < 2 && (
                        <Typography mt={0.75} variant="caption" color="error">
                          {errorMessage}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Nom du département
                    </SoftTypography>
                    <Autocomplete
                      id={`size-small-standard-${index}`}
                      options={options}
                      title="Select sous période"
                      aria-required={true}
                      required={true}
                      getOptionLabel={(option) => option.label}
                      defaultValue={null}
                      clearIcon={null}
                      placeholder="Tous les départements"
                      value={options.find((option) => option.value === input.departement) || null}
                      onChange={(event, newValue) => {
                        if (newValue !== null && newValue !== undefined) {
                          const newInputs = [...inputs];
                          newInputs[index].departement = newValue.value;
                          setInputs(newInputs);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          required={true}
                          value={input.facilityType}
                          placeholder="Tous les départements"
                        />
                      )}
                    />
                    {input.departement === "" && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorMessageDepartemenet}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={inputs.length > 1 ? 3.5 : 3.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Sous période*
                    </SoftTypography>
                    {hasOptionsSpData ? (
                      <div>
                        <Autocomplete
                          title="Select sous-période"
                          multiple
                          fullWidth
                          clearIcon={null}
                          options={optionsSp}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" placeholder="Toutes les sous période" />
                          )}
                          onChange={(event, newValue) => {
                            const updatedInputs = [...inputs];
                            if (updatedInputs.length > 0) {
                              updatedInputs[index].sousPeriodes = newValue.map((value) => value.value) || null;
                              setInputs(updatedInputs);
                            }
                          }}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => option.label === value.label}
                        />
                        {input.sousPeriodes === "" && (
                          <Typography mt={0.75} variant="caption" color="error">
                            {errorMessageSp}
                          </Typography>
                        )} </div>
                    ) : (
                      <Grid>   
                     <SoftTypography
                        component={Link}
                        to="/plan/parametres/sous-period"
                        variant="button"
                        color="customized"
                        fontWeight="medium"
                        textGradient
                        sx={{ borderBottom: 2 }}
                      >
                        {!loading && "Ajouter des périodes"}
                      </SoftTypography>
                      <br></br>
                      {input.sousPeriodes === "" && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorMessageSpVide}
                      </Typography>
                    )}
                        </Grid>

                    )}
                  </Grid>
                  <Grid item xs={12} sm={0.5} mt={3.5}>
                    {inputs.length > 1 && (
                      <IconButton>
                        <RemoveCircleIcon
                          color="error"
                          fontSize="small"
                          onClick={() => handleRemoveInput(index)}
                        />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
            </Grid>
          ))}
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <SoftButton
              size="small"
              sx={{

                mt: 2,

                borderWidth: "2px",
                borderStyle: "dashed solid",
                borderColor: "purple ",
              }}
              onClick={handleAddInput}
            >
              +ajouter plus
            </SoftButton>
          </Stack>
        </DialogContent>
        <DialogActions>
          <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
            <SoftButton
              onClick={handleClose}
              sx={{ mt: 2 }}
              variant="outlined"
              color="dark"
              size="small"
            >
              Annuler
            </SoftButton>
            {loading ? (
              <CircularProgress />
            ) : (
              <SoftButton
                onClick={handleAddSpeciality}
                sx={{ mt: 2 }}
                variant="gradient"
                color="purple"
                size="small"
              >
                Ajouter
              </SoftButton>
            )}
          </SoftBox>
        </DialogActions>
      </Dialog>
    </div>
  );
}
AddSpeciality.propTypes = {
  token: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
