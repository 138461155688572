import { useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";
import Header from "layouts/pages/profile/components/Header";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";

// NewUser page components
import EnterpriseInfo from "./components/EnterpriseInfo";
import EnterpriseAdminInfo from "./components/EnterpriseAdminInfo";
import EnterpriseConfiguration from "./components/EnterpriseConfiguration";

// NewUser layout schemas for form and form feilds
import validations from "pages/enterprise/schema/enterprise-schemas/EnterpriseValidation";
import form from "pages/enterprise/schema/enterprise-schemas/EnterpriseSchema";
import initialValues from "pages/enterprise/schema/enterprise-schemas/EnterpriseInitialValue";


import { CircularProgress } from "@mui/material";

import { useNavigate } from "react-router-dom";



import api from "utils/axios";
import Swal from "sweetalert2";

function getSteps() {
  return ["Entreprise", "Configuration", "Responsable"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <EnterpriseInfo formData={formData} />;
    case 1:
      return <EnterpriseConfiguration formData={formData} />;
    case 2:
      return <EnterpriseAdminInfo formData={formData} />;

    default:
      return null;
  }
}
function NewEnterprise() {
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const steps = getSteps();
  const { formId, formField } = form;

  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep(activeStep - 1)
  };
  const navigate = useNavigate();
  const handleValidate = async (values, actions) => {
    try {
      setIsLoading(true);
      const { data } = await api.post("/validation", {
        type: "ENTERPRISE_VALIDATION",
        payload: {
          email: values.email,
          serialNumber: values.serialNumber,
          name: values.companyName,
        }
      });

      setIsLoading(false);
      setActiveStep(activeStep + 1)
    } catch (error) {
      Swal.fire("Error!", error.response.data.email !== true ?
        `L'email existe déjà : ${values.email}. Merci de saisir un autre email !`
        : error.response.data.serialNumber !== true ?
          `Matricule fiscal existe déjà : ${values.serialNumber}. Merci de saisir un autre matricule fiscal !`
          : `Le nom existe déjà : ${values.companyName}. Merci de saisir un autre nom !`,
        "error").then(() => {
          setIsLoading(false);
        })
      console.log(error)
      setIsLoading(false);
    }
  }

  const submitForm = async (values, actions) => {
    const form = new FormData();

    const info = {
      name: values.companyName,
      serialNumber: values.serialNumber,
      email: values.email,
      phone: values.phone,
      secondaryPhone: values.secondaryPhone,
      physiqualAddress: values.physiqualAddress,
      postCode: values.postCode,
      description: values.description,
      logoUrl: values.logoUrl,
      files: values.files,
      city: values.city,
      country: values.country,
      siteWeb: values.siteWeb,
    };
    const manager = {
      firstName: values.CompanyAdminfirstName,
      lastName: values.CompanyAdminlastName,
      email: values.CompanyAdminemail,
      cin: values.CompanyAdmincin,
      siteWeb: values.CompanyAdminsiteWeb,
      occupation: values.CompanyAdminoccupation,
      address: values.CompanyAdminaddress,
      zipCode: values.CompanyAdminzipCode,
      bank: values.CompanyAdminbank,
      rib: values.CompanyAdminrib,
      cnssNumber: values.CompanyAdmincnssNumber,
      phoneNumber: values.CompanyAdminphone,
      secondaryPhoneNumber: values.CompanyAdminSecondaryphone,
      gender: values.CompanyAdmingender,
      passeport: values.CompanyAdminpassport,
      imageUrl: values.CompanyAdminimageUrl,
      birthday: values.companyAdminDate,
      files: values.CompanyAdminfiles,
      comment: values.CompanyAdminDescription,
      city: values.adminCity,
      country: values.adminCountry
    }
    const facilities = values.facilityName;
    form.append("company.logo", info.logoUrl);
    info.files && info.files.length > 0 &&
      info.files.forEach((item) => {
        form.append("company.files", item.value);
      })
    // form.append("company.files", JSON.stringify(info.files));
    manager.imageUrl && form.append("companyAdmin.image", manager.imageUrl);
    manager.files && manager.files.length > 0 &&
      manager.files.forEach((item) => {
        form.append("companyAdmin.files", item.value);
      })

    form.append("company", JSON.stringify(info));
    form.append("companyAdmin", JSON.stringify(manager));
    form.append("facility", JSON.stringify(facilities));
    try {
      setIsLoading(true);
      const data = await api.post("/add-enterprise", form);
      console.log(data);
      if (data.status === 201) {
        Swal.fire("succés !", "L'entreprise a été ajouté avec succès", "success").then(() => {
          setIsLoading(false);
          window.location.href = `/billing/quotations/${data.data.data.devis._id}` // data.devis._id
        })
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        Swal.fire("Erreur!", error.response.data.message, "error")
      } else {
        Swal.fire("Erreur!", "Une erreur s'est produite. Veuillez réessayer ultérieurement.", "error")
      }
    }

    setActiveStep(2);
  };
  const handleSubmit = (values, actions) => {
    if (activeStep === 0) {
      handleValidate(values, actions);
    } else if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };


  if (error) {
    Swal.fire("Error!", error === "duplicate inputs" ? "L'adress Mail ou le Numéro de série de l'entreprise est déjà utilisé." : error, "error").then(() => {
      setError(null);
    })

  }
  useEffect(() => {
    document.title = "ajouter enterprise";
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="Ajouter une entreprise" searchVisible={false} ExportVisible={false} />
      <SoftBox py={1} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <SoftBox />
                          ) : (
                            <SoftButton color="dark" variant="outlined" size="small" onClick={handleBack}>
                              Retour
                            </SoftButton>
                          )}
                          {activeStep === 0 && (
                            <SoftBox width="100%" display="flex" justifyContent="space-between">

                              <SoftButton size="small" onClick={() => navigate('/companies/companies')} variant="outlined" color="dark" >
                                Annuler
                              </SoftButton>
                            </SoftBox>
                          )}
                          {isLoading ? (
                            <CircularProgress />
                          ) : (
                            <SoftButton
                              size="small"
                              type="submit"
                              variant="gradient"
                              color="customized"
                              id="send"
                            >
                              {isLastStep ? "Ajouter" : "Suivant"}
                            </SoftButton>
                          )}
                        </SoftBox>


                      </SoftBox>
                    </SoftBox>

                  </Card>

                </Form>
              )}

            </Formik>
          </Grid>
        </Grid>
      </SoftBox>


    </DashboardLayout>
  );
}

export default NewEnterprise;
