import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  nom: Yup.string()
    .required("Entrez le nom de la tâche")
    .min(2, "La longueur minimale requise est de 2 caractères."),

  description: Yup.string()
    .required("Entrez la description de la tâche")
    .min(5, "La longueur minimale requise est de 5 caractères."),
  
  responsable: Yup.string()
    .required("Entrez le responsable de la tâche")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Entrez une adresse e-mail valide pour le responsable de la tâche"
    ),
  dateLimite: Yup.string().required("Entrez la date limite de la tâche"),

  nomEntr: Yup.string()
    .required("Entrez le nom de l'entreprise")
    .min(2, "La longueur minimale requise est de 2 caractères."),

  emailCom: Yup.string()
    .required("Entrez le responsable de l'entreprise")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Entrez une adresse e-mail valide pour le responsable de l'entreprise"
    ),

  adresse: Yup.string()
    .required("Entrez l'adresse de l'entreprise")
    .min(10, "La longueur minimale requise est de 10 caractères."),

    matriculeFiscal: Yup.string()
    .min(8, "La longueur minimale requise est de 8 caractères.")
    .max(16, "La longueur maximale autorisée est de 16 caractères."),
});


export default validationSchema;
