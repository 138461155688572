

const calculateTotal = (purshes, applayTVA, generalDiscount) => {

  console.debug('from calculateInvoicesTotals => ', generalDiscount);

  const detailedCalc = [];

  if (applayTVA) {

    let generalTotalHT = 0; // Total HT 
    let totalDiscount = 0;
    let finalTotalHT;
    let generalTotalTTC = 0;


    const tvaMap = {};

    for (let i = 0; i < purshes.length; i++) {
      const { tva,  totalHT} = purshes[i];

      generalTotalHT += totalHT;

      // tvaList
      if (!tvaMap.hasOwnProperty(tva)) tvaMap[tva] = 0;
      if ( generalDiscount > 0 && Object.keys(tvaMap).length > 1 ) throw new Error('we can not applay a general discount if we have a set of different tva pourcentage');
      tvaMap[tva] += totalHT * (tva/100);
    }

    detailedCalc.push({ title: 'Total HT ', value: `${generalTotalHT.toFixed(2)}` });

    if (generalDiscount > 0) {
      
      for(const tva in tvaMap) tvaMap[tva] -= tvaMap[tva] * (generalDiscount / 100);

      totalDiscount = (generalDiscount / 100) * generalTotalHT;
      detailedCalc.push({ title: `Remise générale (${generalDiscount}%) `, value: `${totalDiscount.toFixed(2)}`});
      finalTotalHT = generalTotalHT - totalDiscount;
      detailedCalc.push({ title: `Total HT final `, value: `${finalTotalHT.toFixed(2)}`});
    }

    const tvaMapSortedKeys = Object.keys(tvaMap).sort((key1, key2) => Number(key1) - Number(key2));

    for (const tva of tvaMapSortedKeys) {
      if (tva === '0') continue;
      detailedCalc.push({ title: `TVA(${tva}%)`, value: `${tvaMap[tva].toFixed(2)}`});
      generalTotalTTC += Number(tvaMap[tva].toFixed(2));
    }

    detailedCalc.push({ title: 'TVA Total ', value: `${Object.values(tvaMap).reduce((accumulator, currentValue) => (accumulator + currentValue), 0).toFixed(2)}`})

    generalTotalTTC += generalTotalHT - totalDiscount;

    detailedCalc.push({ title: `Total TTC `, value: `${generalTotalTTC.toFixed(2)}` });

  } else {
    let total = 0;
    let totalDiscount = 0;
    let finalTotal = 0;

    for (const purshe of purshes) total += purshe.totalHT;
    detailedCalc.push({ title: `Total `, value: `${total}` });
    
    if (generalDiscount > 0) {
      totalDiscount = (generalDiscount / 100) * total;
      detailedCalc.push({ title: `Remise générale (${generalDiscount}%) `, value: `${totalDiscount.toFixed(2)}`});
      finalTotal = total - totalDiscount;
      detailedCalc.push({ title: `Total final `, value: `${finalTotal.toFixed(2)}`});
    }
  }

  return detailedCalc;
};


export default calculateTotal;
