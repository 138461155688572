import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CloseIcon from "@mui/icons-material/Close";
import api from "utils/axios";
import Swal from "sweetalert2";
import SoftBox from "components/SoftBox";
import comparerSansEspacesEtCases from "utils/compareEspace";

export default function AddSpeciality({ token }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageFormation, setErrorMessageFormation] = useState("");
  const [errorMessageDepartemenet, setErrorMessageDepartemenet] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [departementData, setDepartementData] = useState([]);
  const [formation, setForamtionData] = useState([])
  const [inputs, setInputs] = useState([{ name: "", formation: "", departement: "" ,mention:"" }]);
  const [optionsF, setOptionsF] = useState([]);
  const [options, setOptions] = useState([]);
  const facility = JSON.parse(localStorage.getItem("facility"));

  useEffect(() => {
    api.get(`/faculty/all-departements/${facility.scholarityConfigId}`).then((res) => {
      setDepartementData(res.data);
      setOptions(
        res.data.map((item) => {
          return {
            value: item._id,
            label: item.name,
          };
        })
      );
    });
  }, []);
  useEffect(() => {
    api.get(`/faculty/get-all-formations/${facility.scholarityConfigId}`).then((res) => {
      setForamtionData(res.data);
      setOptionsF(
        res.data.map((item) => {
          return {
            value: item._id,
            label: item.name,
          };
        })
      );
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessageFormation("");
    setErrorMessage("");
    setErrorMessageDepartemenet("");
    setInputs([{ name: "", formation: "", departement: "",mention:"" }]);
  };
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  };
  const handleAddInput = () => {
    setInputs([...inputs, { name: "", formation: "", departement: "" ,mention:"" }]);
  };
  const handleRemoveInput = (indexToRemove) => {
    const newInputs = [...inputs];
    if (indexToRemove >= 0 && indexToRemove < newInputs.length) {
      // Remove the input at the specified index
      newInputs.splice(indexToRemove, 1);
      setInputs(newInputs);
    }
  };
  const handleAddFormation = async () => {
    try {
      setLoading(true);
      // Validate the inputs before submitting
      const hasInvalidInputs = inputs.some((input) => input.mention.length < 2)
      const hasInvalidDepartemenet = inputs.some((input) => !input.departement);
      const hasInvalidFormation = inputs.some((input) => !input.formation);
      if (hasInvalidFormation) {
        setErrorMessageFormation("La formation doit être sélectionné. ");
      }
     else if (hasInvalidInputs) {
        setErrorMessage("La longueur minimale requise est de 2 caractères .");
      }
     else if (hasInvalidDepartemenet) {
        setErrorMessageDepartemenet("le département doit être sélectionné.");
      }

      else {
        const duplicateName = inputs.find(
          (input, index) =>
            index !== inputs.findIndex((otherInput) =>comparerSansEspacesEtCases( `${otherInput.formation.toLowerCase()} ${otherInput.mention.toLowerCase()}`, `${input.formation.toLowerCase()} ${input.mention.toLowerCase()}`))
        );
        if (duplicateName) {
          const formationName = duplicateName.formation;
          let correspondingName = null;       
          optionsF.forEach(option => {
            if (option.value === formationName) {
              correspondingName = option.label;
            }
          });        
          // Set the error message for duplicate name
          Swal.fire(
            "Erreur!",
            `Le nom de la filière "${correspondingName} en ${duplicateName.mention} " existe deja, Merci de saisir un autre nom !`,
            "error"
          );
        } else {
          await api.post(`/faculty/create-filiere?scholarityConfigId=${facility.scholarityConfigId}`, inputs);
          setOpen(true);
          Swal.fire("Success!", "La filière ajoutée avec succès", "success").then(() => {
            setErrorMessageFormation("");
            setErrorMessage("");
            setErrorMessageDepartemenet("");
            setInputs([{ name: "", formation: "", departement: "" ,mention:"" }]);
            token();
            setOpen(false);
          });
        }
      }
    } catch (error) {
      setError(error.response.data.message);
      Swal.fire("Erreur!", error.response.data.message, "error")
    } finally {
      setLoading(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddFormation();
    }
  };
  return (
    <div>
      <SoftButton
        id="add" onClick={handleClickOpen} variant="gradient"
        color="purple"
        size="small"
        mb={{ xs: 2, sm: 0 }}
      >
        +Ajouter Filière
      </SoftButton>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose} onKeyDown={handleKeyPress}>
        <DialogTitle >Ajouter Filière </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            Vous devez saisir le nom de votre filière afin de l&apos;ajouter.
          </DialogContentText>
          {inputs.map((input, index) => (
            <Grid key={index} sx={{ mt: 1 }}>
              {facility?.type === "faculty" ? (
                <Grid container spacing={1} sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "flex-start",
                }}>
                  <Grid item xs={12} sm={4}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Formation
                    </SoftTypography>
                    <Autocomplete                  
                      id={`size-small-standard-${index}`}
                      options={optionsF}
                      title="Select formation"
                      aria-required={true}
                      required={true}
                      getOptionLabel={(optionsF) => optionsF.label}
                      defaultValue={null}
                      clearIcon={null}
                      placeholder="Tous les formation"
                      value={optionsF.find((optionsF) => optionsF.value === input.formation) || null}
                      onChange={(event, newValue) => {
                        if (newValue !== null && newValue !== undefined) {
                          const newInputs = [...inputs];
                          newInputs[index].formation = newValue.value;
                          setInputs(newInputs);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          required={true}
                          value={input.formation} 
                          placeholder="Tous les formation"
                          autoFocus
                        />
                      )}
                     
                    />
                    {input.formation === "" && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorMessageFormation}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Mention
                    </SoftTypography>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                      }}
                    >
                      <SoftInput
                        name="mention"
                        margin="normal"
                        id="mention"
                        label="spécialité"
                        type="text"
                        fullWidth
                        placeholder="Nom de Mention"
                        inputProps={{
                          minLength: 2,
                          maxLength: 50,
                        }}
                        
                        variant="standard"
                        value={input.mention}
                        onChange={(event) => handleInputChange(index, event)}
                        onBlur={() => {
                          // Update the error when the input loses focus
                          const newInputs = [...inputs];
                          newInputs[index].error = input.mention.length < 2;
                          setInputs(newInputs);
                        }}
                        error={input.error && input.mention.length < 2}
                        success={input.mention.length >= 2}
                      />
                      {input.mention.length < 2 && (
                        <Typography mt={0.75} variant="caption" color="error">
                          {errorMessage}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3.5}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Département
                    </SoftTypography>
                    <Autocomplete
                      id={`size-small-standard-${index}`}
                      options={options}
                      title="Select département"
                      aria-required={true}
                      required={true}
                      getOptionLabel={(option) => option.label}
                      defaultValue={null}
                      clearIcon={null}
                      placeholder="Tous les départements"
                      value={options.find((option) => option.value === input.departement) || null}
                      onChange={(event, newValue) => {
                        if (newValue !== null && newValue !== undefined) {
                          const newInputs = [...inputs];
                          newInputs[index].departement = newValue.value;
                          setInputs(newInputs);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          required={true}
                          value={input.facilityType}
                          placeholder="Tous les départements"
                        />
                      )}
                    />
                    {input.departement === "" && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorMessageDepartemenet}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={0.5} mt={3.5}>
                    {inputs.length > 1 && (
                      <IconButton>
                        <RemoveCircleIcon
                          color="error"
                          fontSize="small"
                          onClick={() => handleRemoveInput(index)}
                        />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>) : (
                <Grid key={index} sx={{ mt: 1 }}>
                  {/* autre facility
                */}
                </Grid>)}
            </Grid>
          ))}
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <SoftButton
              size="small"
              sx={{

                mt: 2,

                borderWidth: "2px",
                borderStyle: "dashed solid",
                borderColor: "purple ",
              }}
              onClick={handleAddInput}
            >
              +ajouter plus
            </SoftButton>
          </Stack>
        </DialogContent>
        <DialogActions>
          <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
            <SoftButton
              onClick={handleClose}
              sx={{ mt: 2 }}
              variant="outlined"
              color="dark"
              size="small"
            >
              Annuler
            </SoftButton>
            {loading ? (
              <CircularProgress />
            ) : (
              <SoftButton
                onClick={handleAddFormation}
                sx={{ mt: 2 }}
                variant="gradient"
                color="purple"
                size="small"
              >
                Ajouter
              </SoftButton>
            )}
          </SoftBox>
        </DialogActions>
      </Dialog>
    </div>
  );
}
AddSpeciality.propTypes = {
  token: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
