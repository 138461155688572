import { Box, IconButton, Modal, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import SoftTypography from 'components/SoftTypography';
import validationSchema from "./validerChamps/ValidationSchema";
import { grey } from "@mui/material/colors";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import api from "utils/axios";
import Swal from "sweetalert2";
import { useTheme } from "@mui/styles";
import { useEffect, useState } from "react";
import {
  Card,
  Grid,
} from "@mui/material";

import SoftSelect from "components/SoftSelect";
import { Close } from "@mui/icons-material";
function AjouterProjet({ open, handleCloseModal, handleprojects }) {

  const [titre, setTitre] = useState("");
  const [description, setDescription] = useState("");
  const [collaborateursEmail, setCollaborateursEmail] = useState([]);
  const [responsable, setResponsable] = useState("");

  const initialValues = {
    titre: "",
    responsable: "",


  };
  const token = localStorage.getItem("accessToken")

  const facility = JSON.parse(localStorage.getItem("facility"));
  const [emailcollaborateurs, setEmailcolaboteurs] = useState([]);
  const getEmailColaborateurs = () => {
    let endpoint ;
    if(facility?.type === "centreDeFormation"){
      endpoint = `training-company/get-emails-center-formation/${facility._id}`;
    }else{
      endpoint = `faculty/get-emails-faculty/${facility._id}`;
    }
    api.get(endpoint, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      const ECs = response.data.emails.map((collaborateur) => collaborateur);

      setEmailcolaboteurs(ECs);


    });
  };


  useEffect(() => {

    getEmailColaborateurs();
  }, [setEmailcolaboteurs]);

  const createProject = async () => {
    if (titre?.length >= 2 && responsable !== "") {
      try {
        let endpoint;
        if (facility?.type === "centreDeFormation") {
          endpoint = `training-company/create-project-centre-formation?scholarityConfigId=${facility.scholarityConfigId}`;
        } else {
          endpoint = `faculty/create-project-faculty?scholarityConfigId=${facility.scholarityConfigId}`;
        }
  
        const response = await api.post(endpoint, {
          nom: titre,
          responsable: responsable,
          collaborateursEmail: collaborateursEmail,
          description: description,
        });
  
        handleprojects();
        handleCloseModal();
        Swal.fire({
          title: "Succès",
          text: "Ajout de projet réussi",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        Swal.fire({
          title: "Avertissement",
          text: "Nom de projet duppliquer", // Display error message from backend
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    } else {
      Swal.fire({
        title: "Avertissement",
        text: "Veuillez remplir tous les champs obligatoires.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };
  

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      sx={{
        "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
          bgcolor: "rgba(0,0,0,0.03) !important",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          height: "59%",
          overflowY: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: "1px 1px 1px 1px grey",
          p: "24px 32px",
        }}
      >
        <Formik initialValues={initialValues} validationSchema={validationSchema}>
          {({ errors, touched, handleSubmit, handleChange, handleBlur }) => (
            <Form onSubmit={handleSubmit} style={{ marginTop: "16px" }}>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="h5" sx={{ flexGrow: "1" }}>
                  Nouvelle Project
                </Typography>

                <IconButton onClick={handleCloseModal}>

                  <Close
                    fontSize="24px !important"
                    sx={{
                      "&:hover": {
                        bgcolor: grey[100],
                        borderRadius: "50%",
                      },
                    }}
                  />
                </IconButton>
              </Box>
                  <Grid container mt="8px">
                    <Grid container xs={12} md={6}>
                      <Grid item xs={12} sx={{ display: "block" }}>
                        <Box sx={{ mr:{ xs: "0px", md: "8px" } }}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="medium"
                            sx={{ mb: 0.5 }}
                          >
                            Titre De Projet*
                          </SoftTypography>
                          <Field name="titre">
                            {({ field }) => (
                              <SoftInput
                                {...field}
                                variant="outlined"
                                fullWidth
                                placeholder="Titre De Projet"
                                error={!!errors.titre && !!touched.titre}
                                success={field.value?.length >= 2 && !!touched.titre}
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  handleChange(event);
                                  setTitre(event.target.value);
                                }}
                              />
                            )}
                          </Field>
                          {!!errors.titre && !!touched.titre && (
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "400", fontSize: "12px" }}
                              color="error"
                            >
                              {errors.titre}
                            </Typography>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sx={{ display: "block" }}>
                        <Box mt="8px" mr={{ xs: "0px", md: "8px" }}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="medium"
                          // sx={{ mb: 0.5 }}
                          >
                            Colaborateurs
                          </SoftTypography>

                          <SoftSelect
                            defaultValue={[{ value: "Ajouter | +", label: "Ajouter | +" }]}
                            options={emailcollaborateurs.map((collaborateursEmail) => {
                              return {
                                value: collaborateursEmail,
                                label: collaborateursEmail,
                              };
                            })}
                            size="medium"
                            isMulti
                            onChange={(selectedOptions) => {
                              const selectedValues = selectedOptions
                                .filter((option) => option.value !== "Ajouter | +")
                                .map((option) => option.value);
                              //j'utiliser ce code pour supprimer la redondance des valeurs
                              const uniqueValues = Array.from(
                                new Set([...collaborateursEmail, ...selectedValues])
                              );
                              setCollaborateursEmail(uniqueValues);
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sx={{ display: "block"}}>
                        <Box mt="8px" mr={{ xs: "0px", md: "8px" }}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="medium"
                            sx={{ mb: 0.5 }}
                          >
                            Responsable*
                          </SoftTypography>
                          <Field name="responsable">
                            {({ field }) => (
                              <SoftSelect
                                options={emailcollaborateurs.map((responsablesEmail) => {
                                  return {
                                    value: responsablesEmail,
                                    label: responsablesEmail,
                                  };
                                })}
                                placeholder="choisissez  un Responsable...."
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  setResponsable(event.value);
                                }}
                              />
                            )}
                          </Field>
                          {responsable === "" && !!touched.responsable && (
                          <Typography
                          variant="body2"
                          sx={{ fontWeight: "400", fontSize: "12px" }}
                          color="error"
                        >
                      
                              {errors.responsable}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ display: "block"}}>
                      <Box mt={{ xs : "8px", md : "0px"}} ml={{ xs: "0px", md: "8px" }}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          sx={{ mb: 0.5 }}
                        >
                          Description
                        </SoftTypography>

                        <SoftInput
                          multiline
                          rows={9}
                          placeholder="Description"
                          variant="outlined"
                          onChange={(event) => {
                            setDescription(event.target.value);
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                <Box
                  sx={{
                    mt: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <SoftButton
                    type="reset"
                    sx={{ mt: 2 }}
                    variant="outlined"
                    color="dark"
                    size="small"
                    onClick={handleCloseModal}
                  >
                    Annuler
                  </SoftButton>
                  <SoftButton
                    type="submit"
                    sx={{ mt: 2 }}
                    variant="gradient"
                    color="purple"
                    size="small"
                    onClick={createProject}
                  >
                    Ajouter
                  </SoftButton>
                </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

AjouterProjet.propTypes = {
  open: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleprojects: PropTypes.func
};

export default AjouterProjet;
