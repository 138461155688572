import { Box, Card, CircularProgress, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "utils/axios";

import ProfileInfoCard from "components/shared-users/ProfileInfoCard";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadFile } from "utils/citysTn";
import SoftAvatar from "components/SoftAvatar";
export default function StudentView({ user }) {
  const { id } = useParams();
  const [student, setStudent] = useState({});
  const [loading, setLoading] = useState(false);

  const [imgsViewer, setImgsViewer] = useState(false);

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const handleFetchStudentById = async (id) => {
    try {
      setLoading(true);
      if (id) {
        const response = await api.get(`/get-user/${user?._id}`);
        const fetchStudent = response.data;
        setLoading(false);
        setStudent(fetchStudent);
      }
    } catch (error) {
      setLoading(true);
    }
  };
  useEffect(() => {
    if (id !== undefined) {
      handleFetchStudentById(id);
    }
  }, [id]);
  return (
    <>
      {loading ? (
        <SoftBox
          sx={{
            mt: 25,
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </SoftBox>
      ) : (
        <>
          <Card sx={{ height: "100%", overflow: "auto" }} mb={2}>
            <SoftBox p={1.5} mt={2} ml={1.5}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <SoftAvatar
                    src={
                      student?.imageUrl?.startsWith("https")
                        ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                        : `${process.env.REACT_APP_BASE_URL}/${student?.imageUrl}`
                    }
                    variant="rounded"
                    size="xl"
                    shadow="sm"
                  />
                </Grid>
                <Grid item>
                  <SoftBox height="100%" mt={0.5} lineHeight={1}>
                    <SoftTypography variant="h5" fontWeight="medium">
                      {student?.firstName} {student?.lastName}
                    </SoftTypography>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      {student?.role}
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} xl={6}>
                  <ProfileInfoCard
                    info={{
                      email: student?.email,
                      "Date de Naissance": student?.birthday,
                      genre: student?.gender,
                      cin: student?.cin,
                      "Numéro Téléphone": student?.phoneNumber
                        ? `+${student.phoneNumber}`
                        : "",
                      "Deuxième Téléphone": student?.secondaryPhoneNumber
                        ? `+${student.secondaryPhoneNumber}`
                        : "",
                      adresse: student?.address,
                      "code Postal": student?.zipCode,
                    }}
                  />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <ProfileInfoCard
                    info={{
                      pays: student?.country,
                      ville: student?.city,
                      "Site Web": student?.siteWeb,
                      Occupation: student?.occupation,
                      passeport: student?.passeport,
                      fichier: (
                        <SoftBox component="ul" ml={-3} pl={2}>
                          {student?.files?.map((file, index) => (
                            <SoftBox
                              component="li"
                              color="text"
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              fontSize="1rem"
                              lineHeight={2}
                              key={index}
                              mb={2}
                              mt={1}
                              sx={{
                                p: 0.75,
                                borderBottom: `1px solid black`,
                                backgroundColor: index % 2 === 0 ? "#F2F2F2" : "transparent",
                              }}
                            >
                              <SoftTypography variant="button" color="text" fontWeight="regular"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "90%",
                                }}>
                                {file}
                              </SoftTypography>
                              <FileDownloadIcon
                                onClick={() => downloadFile(file.name ? file.name : file)}
                                fontSize="small"
                                sx={{ cursor: "pointer" }}
                              />
                            </SoftBox>
                          ))}
                        </SoftBox>
                      ),
                      description: (student?.comment ?
                        (<Box variant="outlined">
                          <Box >
                            <SoftTypography
                              variant="button"
                              color="text"
                              fontWeight="regular"
                              sx={{ textAlign: "justify" }}
                            >
                              {student?.comment}
                            </SoftTypography>
                          </Box>
                        </Box>) : ""
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </>
      )}
    </>
  );
}
StudentView.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};