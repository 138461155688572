import { Autocomplete, Grid, TextField } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import api from "utils/axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

export default function Confidentialite({ classe,handleFetchClasseById }) {
  const myRef = useRef(null);
  const { id } = useParams();
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [loading, setLoading] = useState(false);
  const [optionsStudents, setOptionsStudents] = useState([]);
  const [blockId, setBlockId] = useState({});

  const handleFetch = async () => {
    try {
      api.get(`training-company/classes/${id}/students`).then((res) => {
        setOptionsStudents(
          res?.data?.students?.map((item) => ({
            value: item._id,
            label: `${item?.firstName} ${item?.lastName}`,
          }))
        );
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  const handleUpdate = async (subjectId) => {
    try {
      setLoading(true);
      const response = await api.put(
        `training-company/add-blocked-students-to-subject/${subjectId}`,
        {
          blockedStudentIds: blockId[subjectId] || []
        }
      );
      Swal.fire({
        title: "Success!",
        text: "Mise à jour de l'accès des étudiants à une matière réussie.",
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          handleFetchClasseById(id);handleFetch()
        }
      });
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SoftBox lineHeight={1} mb={2}>
        <SoftTypography variant="h5" fontWeight="medium">
          Interdire l&lsquo;accès d&lsquo;utilisateurs à une matière
        </SoftTypography>
      </SoftBox>
      <SoftBox>
        <div ref={myRef}>
          <DataTable
            table={{
              columns: [
                { Header: "Nom", accessor: "name", width: "20%" },
                { Header: "liste etudiants bloquée", accessor: "listbloque", width: "60%" },
                { Header: "action", accessor: "action", width: "20%" },
              ],
              rows: classe?.subjects_instructors?.map((subject, index) => {
                const subjectId = subject?.subject?._id;
                const initialBlockedStudents = subject?.subject?.blockedStudents?.map(
                  (studentId) => optionsStudents?.find((student) => student.value === studentId)
                );
                return {
                  name: `${subject?.subject?.name}`,
                  listbloque: (
                    <Grid container xs={12} sm={12} style={{ width: "100%", width: 400 }}>
                      <Autocomplete
                        title="Select étudiant"
                        multiple
                        fullWidth
                        clearIcon={null}
                        options={optionsStudents}
                        value={blockId[subjectId]?.map((id) => optionsStudents.find(student => student.value === id)) || initialBlockedStudents}
                        filterSelectedOptions
                        onChange={(event, newValue) => {
                          setBlockId((prev) => ({
                            ...prev,
                            [subjectId]: newValue.map((item) => item.value),
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Veuillez sélectionner les étudiants"
                          />
                        )}
                        getOptionLabel={(option) => option?.label}
                        isOptionEqualToValue={(option, value) => option?.value === value?.value}
                      />
                    </Grid>
                  ),
                  action: (
                    <SoftButton
                      id={`btn-${index}`}
                      type="submit"
                      variant="gradient"
                      color="purple"
                      size="small"
                      onClick={() => handleUpdate(subjectId)}
                    >
                      Valider
                    </SoftButton>
                  ),
                };
              }),
            }}
            entriesPerPage={
              afficheFilter
                ? { defaultValue: 10, entries: [5, 7, 10, 15, 20, 25] }
                : false
            }
            canSearch={afficheFilter}
          />
        </div>
      </SoftBox>
    </>
  );
}

Confidentialite.propTypes = {
  classe: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  handleFetchClasseById:PropTypes.func.isRequired
};
