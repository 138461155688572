const selectData = {
  gender: [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ],
  birthDate: [
    { value: "january", label: "January" },
    { value: "february", label: "February" },
    { value: "march", label: "March" },
    { value: "april", label: "April" },
    { value: "may", label: "May" },
    { value: "june", label: "June" },
    { value: "july", label: "July" },
    { value: "august", label: "August" },
    { value: "september", label: "September" },
    { value: "october", label: "October" },
    { value: "november", label: "November" },
    { value: "december", label: "December" },
  ],
  days: [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 },
  ],
  years: [
    { value: 1900, label: 1900 },
    { value: 1901, label: 1901 },
    { value: 1902, label: 1902 },
    { value: 1903, label: 1903 },
    { value: 1904, label: 1904 },
    { value: 1905, label: 1905 },
    { value: 1906, label: 1906 },
    { value: 1907, label: 1907 },
    { value: 1908, label: 1908 },
    { value: 1909, label: 1909 },
    { value: 1910, label: 1910 },
    { value: 1911, label: 1911 },
    { value: 1912, label: 1912 },
    { value: 1913, label: 1913 },
    { value: 1914, label: 1914 },
    { value: 1915, label: 1915 },
    { value: 1915, label: 1915 },
    { value: 1915, label: 1915 },
    { value: 1916, label: 1916 },
    { value: 1917, label: 1917 },
    { value: 1918, label: 1918 },
    { value: 1919, label: 1919 },
    { value: 1920, label: 1920 },
    { value: 1921, label: 1921 },
    { value: 1922, label: 1922 },
    { value: 1923, label: 1923 },
    { value: 1924, label: 1924 },
    { value: 1925, label: 1925 },
    { value: 1926, label: 1926 },
    { value: 1927, label: 1927 },
    { value: 1928, label: 1928 },
    { value: 1929, label: 1929 },
    { value: 1930, label: 1930 },
    { value: 1931, label: 1931 },
    { value: 1932, label: 1932 },
    { value: 1933, label: 1933 },
    { value: 1934, label: 1934 },
    { value: 1935, label: 1935 },
    { value: 1936, label: 1936 },
    { value: 1937, label: 1937 },
    { value: 1938, label: 1938 },
    { value: 1939, label: 1939 },
    { value: 1940, label: 1940 },
    { value: 1941, label: 1941 },
    { value: 1942, label: 1942 },
    { value: 1943, label: 1943 },
    { value: 1944, label: 1944 },
    { value: 1945, label: 1945 },
    { value: 1946, label: 1946 },
    { value: 1947, label: 1947 },
    { value: 1948, label: 1948 },
    { value: 1949, label: 1949 },
    { value: 1950, label: 1950 },
    { value: 1951, label: 1951 },
    { value: 1952, label: 1952 },
    { value: 1953, label: 1953 },
    { value: 1954, label: 1954 },
    { value: 1955, label: 1955 },
    { value: 1956, label: 1956 },
    { value: 1957, label: 1957 },
    { value: 1958, label: 1958 },
    { value: 1959, label: 1959 },
    { value: 1960, label: 1960 },
    { value: 1961, label: 1961 },
    { value: 1962, label: 1962 },
    { value: 1963, label: 1963 },
    { value: 1964, label: 1964 },
    { value: 1965, label: 1965 },
    { value: 1966, label: 1966 },
    { value: 1967, label: 1967 },
    { value: 1968, label: 1968 },
    { value: 1969, label: 1969 },
    { value: 1970, label: 1970 },
    { value: 1971, label: 1971 },
    { value: 1972, label: 1972 },
    { value: 1973, label: 1973 },
    { value: 1974, label: 1974 },
    { value: 1975, label: 1975 },
    { value: 1976, label: 1976 },
    { value: 1977, label: 1977 },
    { value: 1978, label: 1978 },
    { value: 1979, label: 1979 },
    { value: 1980, label: 1980 },
    { value: 1981, label: 1981 },
    { value: 1982, label: 1982 },
    { value: 1983, label: 1983 },
    { value: 1984, label: 1984 },
    { value: 1985, label: 1985 },
    { value: 1986, label: 1986 },
    { value: 1987, label: 1987 },
    { value: 1988, label: 1988 },
    { value: 1989, label: 1989 },
    { value: 1990, label: 1990 },
    { value: 1991, label: 1991 },
    { value: 1992, label: 1992 },
    { value: 1993, label: 1993 },
    { value: 1994, label: 1994 },
    { value: 1995, label: 1995 },
    { value: 1996, label: 1996 },
    { value: 1997, label: 1997 },
    { value: 1998, label: 1998 },
    { value: 1999, label: 1999 },
    { value: 2000, label: 2000 },
    { value: 2001, label: 2001 },
    { value: 2002, label: 2002 },
    { value: 2003, label: 2003 },
    { value: 2004, label: 2004 },
    { value: 2005, label: 2005 },
    { value: 2006, label: 2006 },
    { value: 2007, label: 2007 },
    { value: 2008, label: 2008 },
    { value: 2009, label: 2009 },
    { value: 2010, label: 2010 },
    { value: 2011, label: 2011 },
    { value: 2012, label: 2012 },
    { value: 2013, label: 2013 },
    { value: 2014, label: 2014 },
    { value: 2015, label: 2015 },
    { value: 2016, label: 2016 },
    { value: 2017, label: 2017 },
    { value: 2018, label: 2018 },
    { value: 2019, label: 2019 },
    { value: 2020, label: 2020 },
    { value: 2021, label: 2021 },
  ],

  currency: [
    { value: "DT", label: "Dinar tunisien (DT)" },
    { value: "$", label: "Dollar des États-Unis ($)" },
    { value: "€", label: "Euro (€)" },
    { value: "₺", label: "Livre turque (₺)"}
    // you can add as much as you want of currency hear with respecting the format of { value: "...", label: "..." }
  ],

  countries: [
    { value: "afghanistan", label: "Afghanistan"},
    { value: "south-africa", label: "Afrique du Sud"},
    { value: "aland-islands", label: "Åland, Îles"},
    { value: "albania", label: "Albanie"},
    { value: "algeria", label: "Algérie" },
    { value: "germany", label: "Allemagne" },
    { value: "andorra", label: "Andorre" },
    { value: "angola", label: "Angola" },
    { value: "anguilla", label: "Anguilla" },
    { value: "antarctica", label: "Antarctique" },
    { value: "antigua-and-barbuda", label: "Antigua-et-Barbuda" },
    { value: "saudi-arabia", label: "Arabie saoudite" },
    { value: "argentina", label: "Argentine" },
    { value: "armenia", label: "Arménie" },
    { value: "aruba", label: "Aruba" },
    { value: "australia", label: "Australie" },
    { value: "austria", label: "Autriche" },
    { value: "azerbaijan", label: "Azerbaïdjan" },
    { value: "bahamas", label: "Bahamas" },
    { value: "bahrain", label: "Bahreïn" },
    { value: "bangladesh", label: "Bangladesh" },
    { value: "barbados", label: "Barbade" },
    { value: "belarus", label: "Bélarus" },
    { value: "belgium", label: "Belgique" },
    { value: "belize", label: "Belize" },
    { value: "benin", label: "Bénin" },
    { value: "bermuda", label: "Bermudes" },
    { value: "bhutan", label: "Bhoutan" },
    { value: "myanmar", label: "Birmanie" },
    { value: "bolivia", label: "Bolivie" },
    { value: "bonaire-saint-eustache-et-saba", label: "Bonaire, Saint-Eustache et Saba" },
    {value: "bosnia-and-herzegovina", label: "Bosnie-Herzégovine"},
    {value: "botswana", label: "Botswana"},
    {value: "brazil", label: "Brésil"},
    {value: "brunei-darussalam", label: "Brunéi Darussalam"},
    {value: "bulgaria", label: "Bulgarie"},
    {value: "burkina-faso", label: "Burkina Faso"},
    {value: "burundi", label: "Burundi"},
    {value: "cambodia", label: "Cambodge"},
    {value: "cameroon", label: "Cameroun"},
    {value: "canada", label: "Canada"},
    {value: "cape-verde", label: "Cap-Vert"},
    {value: "chile", label: "Chili"},
    {value: "china", label: "Chine"},
    {value: "christmas-island", label: "Île Christmas"},
    {value: "cyprus", label: "Chypre"},
    {value: "cocos-keeling-islands", label: "Îles Cocos (Keeling)"},
    {value: "colombie", label: "Colombie"},
    {value: "comores", label: "Comores"},
    {value: "north-korea", label: "Corée du Nord"},
    {value: "south-korea", label: "Corée du Sud"},
    {value: "costa-rica", label: "Costa Rica"},
    {value: "cote-divoire", label: "Côte d'Ivoire"},
    {value: "croatie", label: "Croatie"},
    {value: "cuba", label: "Cuba"},
    {value: "curacao", label: "Curaçao"},
    {value: "danemark", label: "Danemark"},
    {value: "djibouti", label: "Djibouti"},
    {value: "dominique", label: "Dominique"},
    {value: "egypte", label: "Égypte"},
    {value: "united-arab-emirates", label: "Émirats arabes unis"},
    {value: "equateur", label: "Équateur"},
    {value: "erythree", label: "Érythrée"},
    {value: "espagne", label: "Espagne"},
    {value: "estonie", label: "Estonie"},
    {value: "united-states", label: "États-Unis"},
    {value: "eswatini", label: "Eswatini"},
    {value: "ethiopia", label: "Éthiopie"},
    {value: "south-georgia-and-the-south-sandwich-islands", label: "Géorgie du Sud et les îles Sandwich du Sud"},
    {value: "georgia", label: "Géorgie"},
    {value: "fiji", label: "Fidji"},
    {value: "finland", label: "Finlande"},
    {value: "france", label: "France"},
    {value: "gabon", label: "Gabon"},
    {value: "gambia", label: "Gambie"},
    { value: "ghana", label: "Ghana" },
    { value: "gibraltar", label: "Gibraltar" },
    { value: "greece", label: "Grèce" },
    { value: "grenada", label: "Grenade" },
    { value: "greenland", label: "Groënland" },
    { value: "guadeloupe", label: "Guadeloupe" },
    { value: "guam", label: "Guam" },
    { value: "guatemala", label: "Guatemala" },
    { value: "guernsey", label: "Guernesey" },
    { value: "guinea", label: "Guinée" },
    { value: "equatorial-guinea", label: "Guinée Équatoriale" },
    { value: "guinea-bissau", label: "Guinée-Bissau" },
    { value: "guyana", label: "Guyana" },
    { value: "french-guinea", label: "Guyane française" },
    { value: "haiti", label: "Haïti" },
    { value: "honduras", label: "Honduras" },
    { value: "hong-kong", label: "Hong Kong" },
    { value: "hungary", label: "Hongrie" },
    { value: "norfolk-island", label: "Île Norfolk" },
    { value: "isle-of-man", label: "Île de Man" },
    { value: "cayman-islands", label: "Îles Caïmans" },
    { value: "cook-islands", label: "Îles Cook" },
    { value: "faroe-islands", label: "Îles Féroé" },
    { value: "heard-and-mcdonald-islands", label: "Îles Heard-et-MacDonald" },
    { value: "northern-mariana-islands", label: "Îles Mariannes du Nord" },
    { value: "marshall-islands", label: "Îles Marshall" },
    { value: "pitcairn-islands", label: "Îles Pitcairn" },
    { value: "turks-and-caicos-islands", label: "Îles Turques-et-Caïques" },
    { value: "virgin-islands", label: "Îles Vierges, États-Unis" },
    { value: "unincorporated-islands-of-the-united-states", label: "Îles mineures éloignées des États-Unis" },
    { value: "india", label: "Inde" },
    { value: "indonesia", label: "Indonésie" },
    { value: "iraq", label: "Irak" },
    { value: "iran", label: "République islamique d'Iran" },
    { value: "ireland", label: "Irlande" },
    { value: "iceland", label: "Islande" },
    { value: "italy", label: "Italie" },
    { value: "jamaica", label: "Jamaïque" },
    { value: "japan", label: "Japon" },
    { value: "jersey", label: "Jersey" },
    { value: "jordan", label: "Jordanie" },
    { value: "kazakhstan", label: "Kazakhstan" },
    { value: "kenya", label: "Kenya" },
    { value: "kyrgyzstan", label: "Kirghizistan" },
    { value: "kiribati", label: "Kiribati" },
    { value: "kuwait", label: "Koweït" },
    { value: "lao", label: "Lao, République démocratique populaire" },
    { value: "lesotho", label: "Lesotho" },
    { value: "latvia", label: "Lettonie" },
    { value: "lebanon", label: "Liban" },
    { value: "liberia", label: "Libéria" },
    { value: "libya", label: "Libye" },
    { value: "liechtenstein", label: "Liechtenstein" },
    { value: "lithuania", label: "Lituanie" },
    { value: "luxembourg", label: "Luxembourg" },
    { value: "macau", label: "Macau" },
    { value: "macedonia", label: "Macédoine du Nord" },
    { value: "madagascar", label: "Madagascar" },
    { value: "malaysia", label: "Malaisie" },
    { value: "malawi", label: "Malawi" },
    { value: "maldives", label: "Maldives" },
    { value: "mali", label: "Mali" },
    { value: "falkland-islands", label: "Malouines, Îles (Falkland)" },
    { value: "malta", label: "Malte" },
    { value: "morocco", label: "Maroc" },
    { value: "martinique", label: "Martinique" },
    { value: "mauritius", label: "Maurice" },
    { value: "mauritania", label: "Mauritanie" },
    { value: "mayotte", label: "Mayotte" },
    { value: "mexico", label: "Mexique" },
    { value: "micronesia", label: "États fédérés de Micronésie" },
    { value: "moldova", label: "Moldavie" },
    { value: "monaco", label: "Monaco" },
    { value: "mongolia", label: "Mongolie" },
    { value: "montenegro", label: "Monténégro" },
    { value: "montserrat", label: "Montserrat" },
    { value: "mozambique", label: "Mozambique" },
    { value: "namibia", label: "Namibie" },
    { value: "nauru", label: "Nauru" },
    { value: "nepal", label: "Népal" },
    { value: "nicaragua", label: "Nicaragua" },
    { value: "niger", label: "Niger" },
    { value: "nigeria", label: "Nigeria" },
    { value: "niue", label: "Nioue" },
    { value: "norway", label: "Norvège" },
    { value: "new-caledonia", label: "Nouvelle-Calédonie" },
    { value: "new-zealand", label: "Nouvelle-Zélande" },
    { value: "oman", label: "Oman" },
    { value: "uganda", label: "Ouganda" },
    { value: "uzbekistan", label: "Ouzbékistan" },
    { value: "pakistan", label: "Pakistan" },
    { value: "palau", label: "Palaos" },
    { value: "state-of-palestine", label: "État de Palestine" },
    { value: "panama", label: "Panama" },
    { value: "papua-new-guinea", label: "Papouasie-Nouvelle-Guinée" },
    { value: "paraguay", label: "Paraguay" },
    { value: "netherlands", label: "Pays-Bas" },
    { value: "peru", label: "Pérou" },
    { value: "philippines", label: "Philippines" },
    { value: "poland", label: "Pologne" },
    { value: "french-polynesia", label: "Polynésie française" },
    { value: "puerto-rico", label: "Porto Rico" },
    { value: "portugal", label: "Portugal" },
    { value: "qatar", label: "Qatar" },
    { value: "central-african-republic", label: "République centrafricaine" },
    { value: "democratic-republic-of-the-congo", label: "République démocratique du Congo" },
    { value: "dominican-republic", label: "République dominicaine" },
    { value: "republic-of-the-congo", label: "République du Congo" },
    { value: "reunion", label: "Île de la Réunion" },
    { value: "romania", label: "Roumanie" },
    { value: "united-kingdom", label: "Royaume-Uni" },
    { value: "russian-federation", label: "Fédération de Russie" },
    { value: "rwanda", label: "Rwanda" },
    { value: "western-sahara", label: "Sahara occidental" },
    { value: "saint-barthélemy", label: "Saint-Barthélemy" },
    { value: "saint-christopher-and-nevis", label: "Saint-Christophe-et-Niévès" },
    { value: "san-marino", label: "Saint-Marin" },
    { value: "saint-martin-french-part", label: "Saint-Martin (partie française)" },
    { value: "saint-martin-netherlands-part", label: "Saint-Martin (partie néerlandaise)" },
    { value: "saint-pierre-and-miquelon", label: "Saint-Pierre-et-Miquelon" },
    { value: "vatican-city-state", label: "Saint-Siège (état de la cité du Vatican)" },
    { value: "saint-vincent-and-the-grenadines", label: "Saint-Vincent-et-les-Grenadines" },
    { value: "saint-helena-ascension-and-tristan-da-cunha", label: "Sainte-Hélène, Ascension et Tristan da Cunha" },
    { value: "saint-lucia", label: "Sainte-Lucie" },
    { value: "solomon-islands", label: "Salomon, Îles" },
    { value: "salvador", label: "Salvador" },
    { value: "samoa", label: "Samoa" },
    { value: "american-samoa", label: "Samoa américaines" },
    { value: "sao-tome-and-principe", label: "Sao Tomé-et-Principe" },
    { value: "senegal", label: "Sénégal" },
    { value: "serbia", label: "Serbie" },
    { value: "seychelles", label: "Seychelles" },
    { value: "sierra-leone", label: "Sierra Leone" },
    { value: "singapore", label: "Singapour" },
    { value: "slovakia", label: "Slovaquie" },
    { value: "slovenia", label: "Slovénie" },
    { value: "somalia", label: "Somalie" },
    { value: "sudan", label: "Soudan" },
    { value: "south-sudan", label: "Soudan du Sud" },
    { value: "sri-lanka", label: "Sri Lanka" },
    { value: "sweden", label: "Suède" },
    { value: "switzerland", label: "Suisse" },
    { value: "suriname", label: "Suriname" },
    { value: "svalbard-and-jan-mayen", label: "Svalbard et île Jan Mayen" },
    { value: "syrian-arab-republic", label: "République arabe Syrienne" },
    { value: "tajikistan", label: "Tadjikistan" },
    { value: "taiwan", label: "Taïwan" },
    { value: "tanzania", label: "Tanzanie" },
    { value: "chad", label: "Tchad" },
    { value: "czech-republic", label: "Tchéquie" },
    { value: "french-southern-territories", label: "Terres australes françaises" },
    { value: "british-indian-ocean-territory", label: "Territoire britannique de l'océan Indien" },
    { value: "thailand", label: "Thaïlande" },
    { value: "east-timor", label: "Timor oriental" },
    { value: "togo", label: "Togo" },
    { value: "tokelau", label: "Tokelau" },
    { value: "tonga", label: "Tonga" },
    { value: "trinidad-and-tobago", label: "Trinité-et-Tobago" },
    { value: "tunisia", label: "Tunisie" },
    { value: "turkmenistan", label: "Turkménistan" },
    { value: "turkey", label: "Turquie" },
    { value: "tuvalu", label: "Tuvalu" },
    { value: "ukraine", label: "Ukraine" },
    { value: "uruguay", label: "Uruguay" },
    { value: "vanuatu", label: "Vanuatu" },
    { value: "venezuela", label: "Vénézuela" },
    { value: "vietnam", label: "Viêt Nam" },
    { value: "wallis-and-futuna", label: "Wallis et Futuna" },
    { value: "yemen", label: "Yémen" },
    { value: "zambia", label: "Zambie" },
    { value: "zimbabwe", label: "Zimbabwe" },
    { value: "bouvet-island", label: "île Bouvet" },
    { value: "british-virgin-islands", label: "îles Vierges britanniques" }
  ]
};

export default selectData;
