import { Box, Card, CardContent, CircularProgress, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import React, { useEffect, useState } from "react";
import SoftButton from "components/SoftButton";
import { useNavigate, useParams } from "react-router-dom";
import api from "utils/axios";

import ProfileInfoCard from "components/shared-users/ProfileInfoCard";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";

// import { downloadFile } from "../../utils/citysTn";
import SoftAvatar from "components/SoftAvatar";
import Header from "layouts/pages/profile/components/Header";
import moment from "moment";
import ListDemandeDocument from "../ListeDemandes/ListDemandes";

function EventView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(false);

  const handleEventById = (id) => {
    api.get(`get-event/${id}`).then((res) => {
      setEvent(res.data);
    });
  };
  useEffect(() => {
    handleEventById(id);
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <SoftBox
          sx={{
            mt: 25,
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </SoftBox>
      ) : (
        <>
          <Card sx={{ height: "100%", my: "32px" }}>
            <SoftBox p={1.5} mt={2} ml={1.5}>
              <SoftTypography sx={{mb : "16px"}}>Informations de l&apos;évènement</SoftTypography>
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${event.image}`}
                style={{ width: "500px", height: "300px", display: "flex", margin: "auto" }}
              />
              <Grid container>
                <Grid item xs={12} xl={6}>
                  <ProfileInfoCard
                    info={{
                      Nom: event.nom,
                      Categorie: event.categorie,
                      "Date de debut": moment(event.dateDebut).format("DD/MM/YYYY"),
                      "Heure de debut": event.heureDebut,
                      "Date Fin": event.dateFin ? moment(event.dateFin).format("DD/MM/YYYY") : "",
                      "Heure Fin": event.heureFin ? event.heureFin : "",
                      Lieu: event.lieu,
                      "Lieu Précis": event.lieuPrécis ? event.lieuPrecis : "",
                      Capaciter: event.capacite ? event.capacite : "",
                      Lien: event.lien ? event.lien : "",
                      description: event.description
                        ? JSON.parse(event.description).blocks[0].text
                        : "",
                    }}
                  />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <ProfileInfoCard
                    info={{
                      Organisateurs: event.coOrganisateurs
                        ? event.coOrganisateurs.join("  ,  ")
                        : "",
                      "Evenement Gratuit?": event.estGratuit === true ? "true" : "false",
                      "Prix Participation": event.prix ? event.prix : "",
                      "Récurrence Évènement": event.recurrenceEvent,
                      "Dates de Récurrence Évènement": event.datesRecurrenceEvent
                        ? event.datesRecurrenceEvent.join("  ,  ")
                        : "",
                      Statut: event.statut,
                      "Description de Demande": event.descriptionDemande
                        ? event.descriptionDemande
                        : "",
                      Evaluation: event.evaluation ? event.evaluation : "",
                    }}
                  />
                </Grid>
              </Grid>
              <SoftBox mt={3}>
                <Box textAlign="end">
                  <SoftButton
                    id="btn-cancel"
                    onClick={() => navigate("/events/listEvents")}
                    variant="gradient"
                    color="purple"
                    size="small"
                  >
                    Retour
                  </SoftButton>
                </Box>
              </SoftBox>
            </SoftBox>
          </Card>
        </>
      )}
    </DashboardLayout>
  );
}

export default EventView;
