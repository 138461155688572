// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// react-phone-input
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// NewUser page components
import EnterpriseFormField from "pages/enterprise/schema/enterprise-schemas/EnterpriseFormField";
import { useEffect, useRef, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import SoftInput from "components/SoftInput";
import { Autocomplete, Avatar,Icon, TextField, Typography } from "@mui/material";
import SoftButton from "components/SoftButton";
import { getCountryAdd } from "utils/citysTn";
import SoftSelect from "components/SoftSelect";
import { fetchData } from "utils/citysTn";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styled from "@emotion/styled";
import { ErrorMessage } from "formik";

function EnterpriseAdminInfo({ formData }) {
  const { formField, values, errors, touched } = formData;
  const {
    CompanyAdminfirstName,
    CompanyAdminlastName,
    CompanyAdminemail,
    CompanyAdminphone,
    CompanyAdmincin,
    CompanyAdminsiteWeb,
    CompanyAdminoccupation,
    CompanyAdminaddress,
    CompanyAdminzipCode,
    CompanyAdminbank,
    CompanyAdminrib,
    CompanyAdmincnssNumber,
    CompanyAdminpassport,
    CompanyAdmingender,
    CompanyAdminbirthday,
    CompanyAdminDescription
    } = formField;

    const {
      CompanyAdminfirstName:firstNameX,
      CompanyAdminlastName:lastNameX,
      CompanyAdminemail:emailX,
      CompanyAdminsiteWeb:siteWebX,
      CompanyAdmincin:cinX,
      CompanyAdminoccupation:occupationX,
      CompanyAdminzipCode:zipCodeX,
      CompanyAdminbank:bankX,
      CompanyAdminrib:ribX,
      CompanyAdmincnssNumber:cnssNumberX,
      CompanyAdminphone:phoneX,
      CompanyAdminaddress:addressX,
      CompanyAdminSecondaryphone:secondaryPhoneX,
      CompanyAdminpassport:CompanyAdminpassportX,
      CompanyAdmingender: CompanyAdmingenderX,
      companyAdminDate:CompanyAdminbirthdayX,
      CompanyAdminDescription:CompanyAdminDescriptionX,
      CompanyAdminimageUrl:CompanyAdminimageUrlX
    } = values;

   const [errorMessage, setErrorMessage] = useState('');
   const [isHovered, setIsHovered] = useState(false);
   const fileInputRef = useRef(null);
   const [logo, setLogo] = useState(CompanyAdminimageUrlX);
   const [info, setInfo] = useState({})
   const [data, setData] = useState([]);
   const [options,setOptions] = useState([]);
   const [selectedOptions, setSelectedOptions] = useState([]);
   const [cityOptions, setCityOptions] = useState([]);
   const [selectedGender, setSelectedGender] = useState(CompanyAdmingenderX);
   const [date,setDate] = useState(CompanyAdminbirthdayX);
   const [dataToSend, setDataToSend] = useState({

    country: "",
    city: "",
  });
  const [adminPhone, setAdminPhone] = useState(phoneX);
  const [secondAdminPhone, setSecondAdminPhone] = useState(secondaryPhoneX);
  values.CompanyAdminfiles = selectedOptions;
  values.CompanyAdminimageUrl = logo;
  values.adminCity = dataToSend.city;
  values.adminCountry = dataToSend.country;
  values.CompanyAdmingender = selectedGender;
  values.companyAdminDate = date;
  values.CompanyAdminphone = adminPhone;
  values.CompanyAdminSecondaryphone = secondAdminPhone;
  const countries = getCountryAdd(info, data);
  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };
  const handleCountryChange = (selectedOption) => {
    const selectedCountry = selectedOption.value;
    const cities = data.find((country) => country.country === selectedCountry).cities;

    setDataToSend({ ...dataToSend, country: selectedCountry });
    setCityOptions(cities.map((city) => ({ value: city, label: city })));
  };
  const handleCityChange = (selectedOption) => {
    setDataToSend({ ...dataToSend, city: selectedOption.value });
  };
  useEffect(() => {
    const fetchDataAsync = async () => {
      const data = await fetchData();
      setData(data);
    };
    fetchDataAsync();
  }, []);
  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // Specify the allowed file extensions
      const allowedExtensions = ["jpg", "jpeg", "png", "gif", "jfif"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        // Display an error message if the file extension is not allowed
        setErrorMessage("Format de fichier non pris en charge. Veuillez sélectionner un fichier JPG, JPEG, PNG ou GIF.");
        setLogo(null); // Clear the logo
        return;
      }

      // Reset the error message if the file extension is allowed
      setErrorMessage("");
      setLogo(selectedFile);

      // Specify the maximum allowed file size in bytes (e.g., 2MB)
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      const maxWidth = 650; // Maximum width in pixels
      const maxHeight = 650; // Maximum height in pixels

      if (selectedFile.size > maxSize) {
        // Display an error message if the file size exceeds the limit
        setErrorMessage("La taille du fichier est trop grande. La taille maximale autorisée est de 2 Mo.");
        setLogo(null); // Clear the logo
      } else {
        // Check image dimensions
        const img = new Image();
        img.src = URL.createObjectURL(selectedFile);

        img.onload = () => {
          if (img.width > maxWidth || img.height > maxHeight) {
            // Display an error message if the image dimensions exceed the limit
            setErrorMessage(
              `Les dimensions de l'image sont trop grandes. Les dimensions maximales autorisées sont de ${maxWidth}x${maxHeight} pixels.`
            );
            setLogo(null); // Clear the logo
          }
        };
      }
    }
  };

  const handleDeleteLogo = () => {
    // Handle deleting the logo here
    // You can implement this function as needed
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setLogo(null);
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    const newOption = { value: file, label: file.name };

    // Check if the file is already selected
    const isSelected = selectedOptions.some((option) => option.label === newOption.label);

    if (!isSelected) {
      // If it's not selected, add it to both options and selectedOptions
      setOptions([...options, newOption]);
      setSelectedOptions([...selectedOptions, newOption]);
    }
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const handleFileSelect = (event, newValue) => {
    // Check if the selected file is already in the selectedOptions array
    const isAlreadySelected = selectedOptions.some((option) => option.name === newValue.name);

    if (isAlreadySelected) {
      // If it's already selected, remove it from the selectedOptions
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter((option) => option.name !== newValue.name)
      );
    } else {
      // If it's not selected, add it to the selectedOptions
      setSelectedOptions([...selectedOptions, newValue]);
    }
  };

  const currentDate = new Date();
  const monthsToSubtract = 1; // Set the number of months to subtract
  const minDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - monthsToSubtract,
    currentDate.getDate()
  );
  const minDateString = minDate.toISOString().split("T")[0];
  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography id="add-company-admin" variant="h5" fontWeight="bold">
          Informations du responsable de l&apos;entreprise
        </SoftTypography>
        
      </SoftBox>

      <Grid item xs={12} sm={13} container justifyContent="center">
        <SoftBox position="relative" height="max-content" mx="auto">
          <Avatar
            onClick={handleAvatarClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
              filter: isHovered ? "blur(2px)" : "none",
              cursor: "pointer",
              width: 100,
              height: 100,
              borderRadius: "16px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={logo ? URL.createObjectURL(logo) : ""}
            value={logo}
            alt="profile picture"
            size="xl"
            variant="rounded"
          />
          <SoftBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
            <SoftButton
              onClick={handleDeleteLogo}
              variant="gradient"
              color="error"
              size="small"
              iconOnly
            >
              <Icon>delete</Icon>
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </Grid>
      <input
      id="image-user"
        type="file"
        // Specify the accepted file types
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={onFileChange}
        accept=".jpg, .jpeg, .png, .gif .jfif"
      />

      {errorMessage && (
        <Typography align="center" sx={{ mt: 2 }} variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
            id="company-admin-first-name"
              type={CompanyAdminfirstName.type}
              label={CompanyAdminfirstName.label}
              name={CompanyAdminfirstName.name}
              value={firstNameX}
              inputProps={{
                minLength:2,
                maxLength: 30
              }}
              placeholder={CompanyAdminfirstName.placeholder}
              error={errors.CompanyAdminfirstName && touched.CompanyAdminfirstName}
              success={firstNameX.length > 0 && !errors.CompanyAdminfirstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
            id="company-admin-last-name"
              type={CompanyAdminlastName.type}
              label={CompanyAdminlastName.label}
              name={CompanyAdminlastName.name}
              value={lastNameX}
              inputProps={{
                minLength:2,
                maxLength: 30
              }}
              placeholder={CompanyAdminlastName.placeholder}
              error={errors.CompanyAdminlastName && touched.CompanyAdminlastName}
              success={lastNameX.length > 0 && !errors.CompanyAdminlastName}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
            id="company-admin-email"
              type={CompanyAdminemail.type}
              label={CompanyAdminemail.label}
              name={CompanyAdminemail.name}
              value={emailX}
              required={true}
              inputProps={{
                maxLength: 50
              }}
              placeholder={CompanyAdminemail.placeholder}
              error={errors.CompanyAdminemail && touched.CompanyAdminemail}
              success={emailX.length > 0 && !errors.CompanyAdminemail}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl sx={{ m: 2, minWidth: 120 }}>
              <SoftTypography sx={{ mb: 1 }} component="label" variant="caption" fontWeight="bold">
              Genre*
              </SoftTypography>
              <RadioGroup
              id="company-admin-gender"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={CompanyAdmingender.name}
                // name="row-radio-buttons-group"
                 value={selectedGender}
                onChange={(e) => setSelectedGender(e.target.value)}
                //error={errors.CompanyAdmingender && touched.CompanyAdmingender}
                success={CompanyAdmingenderX.length > 0 && !errors.CompanyAdmingender}
              >
                <FormControlLabel id="company-admin-female" value="femme" control={<Radio />} label="Femme" />
                <FormControlLabel id="company-admin-male" value="homme" control={<Radio />} label="Homme" />
              </RadioGroup>{" "}
              <SoftBox mt={0.75}>
                <SoftTypography component="div" variant="caption" color="error">
                  <ErrorMessage name={CompanyAdmingender.name} />
                </SoftTypography>
              </SoftBox>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
        <SoftTypography id="company-admin-date" 
        sx={{ mb: 1 }} 
        component="label" 
        variant="caption"
         fontWeight="bold">
        Date de Naissance
            </SoftTypography>
            <SoftInput 
             type="date"
             value={date}
             name={CompanyAdminbirthday.name} 
             inputProps={{
              max: minDateString
            }}
             onChange={(e) => setDate(e.target.value)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
            id="company-admin-cin"
              type={CompanyAdmincin.type}
              label={CompanyAdmincin.label}
              name={CompanyAdmincin.name}
              value={cinX}
              inputProps={{
                maxLength: 20
              }}
              placeholder={CompanyAdmincin.placeholder}
              error={errors.CompanyAdmincin && touched.CompanyAdmincin}
              success={cinX.length > 0 && !errors.CompanyAdmincin}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              téléphone*
            </SoftTypography>
            <PhoneInput
              id="company-admin-phone"
              country={"tn"}
              value={adminPhone}
              enableSearch
              inputStyle={{
                width: "100%",
              }}
              onChange={(value) => {
                setAdminPhone(value);
              }}
            />
            <SoftBox mt={0.75}>
              <SoftTypography component="div" variant="caption" color="error">
                <ErrorMessage name={CompanyAdminphone.name} />
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
               Deuxième Téléphone
            </SoftTypography>
            <PhoneInput
              id="company-admin-secondary-phone"
              country={"tn"}
              value={secondAdminPhone}
              enableSearch
              inputStyle={{
                width: "100%",
              }}
              onChange={(value) => {
                setSecondAdminPhone(value);
              }}
            />
            
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
            id="company-admin-passport"
              type={CompanyAdminpassport.type}
              label={CompanyAdminpassport.label}
              name={CompanyAdminpassport.name}
              value={CompanyAdminpassportX}
              inputProps={{
                maxLength: 30
              }}
              placeholder={CompanyAdminpassport.placeholder}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
            id="company-admin-site-web"
              type={CompanyAdminsiteWeb.type}
              label={CompanyAdminsiteWeb.label}
              name={CompanyAdminsiteWeb.name}
              value={siteWebX}
              inputProps={{
                maxLength: 100
              }}
              placeholder={CompanyAdminsiteWeb.placeholder}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
            id="company-admin-occupation"
              type={CompanyAdminoccupation.type}
              label={CompanyAdminoccupation.label}
              name={CompanyAdminoccupation.name}
              value={occupationX}
              inputProps={{
                maxLength: 30
              }}
              placeholder={CompanyAdminoccupation.placeholder}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
            id="company-admin-cnss-number"
              type={CompanyAdmincnssNumber.type}
              label={CompanyAdmincnssNumber.label}
              name={CompanyAdmincnssNumber.name}
              value={cnssNumberX}
              inputProps={{
                maxLength: 30
              }}
              placeholder={CompanyAdmincnssNumber.placeholder}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
            Pays            </SoftTypography>
            <SoftSelect
            inputId="company-admin-country"
              circular={true}
              size={"small"}
              variant={"gradient"}
              options={countries.map((country) => ({ value: country, label: country }))}
              onChange={handleCountryChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
            Ville
                        </SoftTypography>
            <SoftSelect
            inputId="company-admin-city"
              circular={true}
              variant={"gradient"}
              size={"small"}
              options={cityOptions}
              onChange={handleCityChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
            id="company-admin-address"
              type={CompanyAdminaddress.type}
              label={CompanyAdminaddress.label}
              name={CompanyAdminaddress.name}
              value={addressX}
              inputProps={{
                maxLength: 50
              }}
              placeholder={CompanyAdminaddress.placeholder}
              error={errors.CompanyAdminaddress && touched.CompanyAdminaddress}
              success={addressX.length > 0 && !errors.CompanyAdminaddress}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
            id="company-admin-zip-code"
              type={CompanyAdminzipCode.type}
              label={`${CompanyAdminzipCode.label}`}
              name={CompanyAdminzipCode.name}
              value={zipCodeX}
              placeholder={CompanyAdminzipCode.placeholder}
              error={errors.CompanyAdminzipCode && touched.CompanyAdminzipCode}
              success={zipCodeX.length > 0 && !errors.CompanyAdminzipCode}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
            id="company-admin-bank"
              type={CompanyAdminbank.type}
              label={CompanyAdminbank.label}
              name={CompanyAdminbank.name}
              value={bankX}
              inputProps={{
                maxLength: 30
              }}
              placeholder={CompanyAdminbank.placeholder}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
            id="company-admin-rib"
              type={CompanyAdminrib.type}
              label={`${CompanyAdminrib.label}`}
              name={CompanyAdminrib.name}
              value={ribX}
              inputProps={{
                maxLength: 20
              }}
              placeholder={CompanyAdminrib.placeholder}
            />
          </Grid>
        </Grid>
        <Grid sx={{ mt: 2, mr: "auto", display: "flow" }} item xs={12}>
          <SoftButton
            id="upload-files-company-admin"
            sx={{ mb: 2 }}
            component="label"
            onChange={handleFileUpload}
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Télécharger des fichiers

            <VisuallyHiddenInput type="file" />
          </SoftButton>
          <Autocomplete
            multiple
            id="tags-standard"
            placeholder="Upload files"
            options={options}
            value={selectedOptions}
            filterSelectedOptions
            freeSolo
            renderInput={(params) => (
              <TextField {...params} variant="standard" placeholder="Télécharger des fichiers" />
            )}
            onChange={(event, newValue) => {
              setSelectedOptions(newValue);
            }}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.label === value.label}
          />
        </Grid>
        <Grid container spacing={3}>
          <Grid sx={{ mt: 1 }} item xs={12}>
            <EnterpriseFormField
            id="company-admin-description"
              type={CompanyAdminDescription.type}
              label={CompanyAdminDescription.label}
              name={CompanyAdminDescription.name}
              value={CompanyAdminDescriptionX}
              placeholder={CompanyAdminDescription.placeholder}
              multiline
              rows={5}
              inputProps={{
                maxLength: 250
              }}
            />
          </Grid>
        </Grid>
      
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for EnterpriseAdminInfo
EnterpriseAdminInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default EnterpriseAdminInfo;
