import PropTypes from "prop-types";

import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ForwardIcon from '@mui/icons-material/Forward';
import Tooltip from '@mui/material/Tooltip';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Box, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { MoreVert } from "@mui/icons-material";


// Custom styles for ComplexProjectCard
function InvoiceCard({ title, recipient, dateTime, total, onDownload, preinscToInsc, invoiceId, paymentDetails }) {
  let allUnpaid;
  let allPaid;
  if(paymentDetails){
    allUnpaid = paymentDetails.every(paymentDetail => paymentDetail.status === "unpaid");
    allPaid = paymentDetails.every(paymentDetail => paymentDetail.status === "paid");
  }

  
  // if(!paymentDetails && title.charAt[0] === "D"){

  // }


  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  const [marquerPayer, setMarquerPayer] = useState(false);

  useEffect(()=>{
    if(allPaid){
      setMarquerPayer(true);
    }

    if(!allPaid && !allUnpaid){
      setMarquerPayer(false);
    }
  },[allUnpaid, allPaid])

  const navigate = useNavigate();

  return (
    <Card sx={{ position:'relative', overflow:"visible" }}>
      <SoftBox p={2}>
        <SoftBox display="flex" alignItems="center">
          <SoftBox lineHeight={0}>
    
            <SoftBox lineHeight={0}>
              <Link to={`./${invoiceId}`}>
                <SoftTypography variant="h6" component="span" textTransform="capitalize" fontWeight="medium" color="dark">
                    {title}
                </SoftTypography>
              </Link>
              {/* states : "Finalisée" || "Encaissée" || "Signé" || "Refusé" */}
              {!paymentDetails && title.charAt(0) !== "F" ?
                <SoftTypography variant="body2" component="span" color="success"> { "(Finalisée)" } </SoftTypography>
                :
                <SoftTypography variant="body2" component="span" color={allUnpaid ? "error" : allPaid ? "success" : "warning"}> {allUnpaid ? "A payée" : allPaid ? "Payée" : "En cours"} </SoftTypography>
              }
            </SoftBox>
            <SoftBox display="flex">
              <SoftTypography variant="overline" textTransform="capitalize" fontWeight="medium">
                {recipient}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          
          <SoftTypography
            color="secondary"
            sx={{
              ml: "auto",
              alignSelf: "flex-start",
            }}
          >
            {
              preinscToInsc !== undefined &&
              <Tooltip title="Transformer cette pré-inscription en inscription officielle">
                <ForwardIcon id={`button-pre-insc-to-insc-${title}`} fontSize="default" sx={{ cursor: "pointer", mr:"32px"}} onClick={preinscToInsc} />
              </Tooltip>
            }
            {/* <Link to={invoiceId}>
              <VisibilityIcon id={`button-display-invoice-${title}`} fontSize="default" sx={{ cursor: "pointer" }} color="secondary" />
            </Link>
            <FileDownloadIcon id={`button-download-invoice-${title}`} fontSize="default" sx={{ cursor: "pointer" }} onClick = {onDownload} /> */}
            <Box position="absolute" top={8} right={8}>
              <IconButton
                ref={anchorRef}
                onClick={handleToggle}
              >
                <MoreVert />
              </IconButton>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                sx={{ zIndex: 1 }}
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                  >
                    <Paper sx={{ bgcolor:"#fffffd" }} >
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList>
                          {paymentDetails && title.charAt(0) === "F" &&
                            <Box>
                              <MenuItem onClick={handleClose}>{marquerPayer ? "Marquer comme annuler" : "Marquer comme payée"}</MenuItem>
                              <Divider />
                            </Box>
                          }
                          <MenuItem onClick={(e)=>{
                            navigate(invoiceId);
                            handleClose(e);
                          }}>
                            Voir plus
                          </MenuItem>
                          <MenuItem onClick={(e)=>{
                            onDownload();
                            handleClose(e);
                          }}>Télécharger</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </SoftTypography>
        
        </SoftBox>
        <Divider />
        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
          <SoftBox display="flex" flexDirection="row" lineHeight={0}>
            <RequestQuoteIcon fontSize="small" />
            <SoftTypography variant="button" fontWeight="medium" color="secondary">
              { total.title + "" + total.value }
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" flexDirection="row" lineHeight={0}>
            <AccessTimeIcon />
            <SoftTypography variant="button" fontWeight="medium" color="secondary">
              {dateTime}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ComplexProjectCard
InvoiceCard.defaultProps = {
  dateTime: "",
  total: {},
  recipient: "",
  onDownload: new Function(),
  preinscToInsc: undefined,
  invoiceId: "",
};

// Typechecking props for the ProfileInfoCard
InvoiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.object,
  dateTime: PropTypes.string,
  recipient: PropTypes.string,
  onDownload: PropTypes.func,
  preinscToInsc: PropTypes.func,
  invoiceId: PropTypes.string,
  paymentDetails: PropTypes.array,
};

export default InvoiceCard;
