import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import { useNavigate } from "react-router-dom";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { AppBar, Box, CircularProgress, Tab, Tabs } from "@mui/material";
import { Grid } from "@mui/material";
import ComplexProjectCard from "./components/Card";
import api from "utils/axios";
import moment from "moment";
import { grey } from "@mui/material/colors";
import SoftSelect from "components/SoftSelect";
import SelectData from "../data/SelectData";
import SoftPagination from "components/SoftPagination";
import { ArrowBackIos } from "@mui/icons-material";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import { useSoftUIController } from "context";
import DashboardNavbar from "layouts/DashboardNavbar";

function ListEvents() {
  const navigate = useNavigate();

  const [controller, _] = useSoftUIController();

  const [currentPage, setCurrentPage] = useState(1);
  const [tabValue, setTabValue] = useState("all");
  const [events, setEvents] = useState([]);
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [loading, setLoading] = useState(true);

  const facility = JSON.parse(localStorage.getItem("facility"));

  const handleEvents = () => {
    setLoading(true);
      api.get(`get-events/${controller.user.info._id}/${facility._id}`).then((response) => {
        // Filter events with evalutation === "Valider"
        const filteredEvents = response.data.eventsByPersonneConnecter.filter((event) => event.evaluation === "Valider");
        // Sort the filtered events by dateDebut
        const sortedEvents = filteredEvents.sort((eventA, eventB) => {
          const dateA = new Date(eventA.dateDebut);
          const dateB = new Date(eventB.dateDebut);
          return dateB - dateA;
        });
        setEvents(sortedEvents);
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = "Événement";
    handleEvents();
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategorie(event.value);
    setCurrentPage(1); // Reset page number when category changes
  };

  const filteredEvents =
    selectedCategorie !== ""
      ? events.filter((event) => event.categorie === selectedCategorie)
      : events;

  const currentDate = new Date();
  const pastEvents = filteredEvents.filter((event) => {
    const eventDate = new Date(event.dateDebut);
    return eventDate < currentDate;
  });

  const upcomingEvents = filteredEvents.filter((event) => {
    const eventDate = new Date(event.dateDebut);
    return eventDate > currentDate;
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const cardsPerPage = 9;
  let totalCards = filteredEvents.length;
  let totalPages = Math.ceil(totalCards / cardsPerPage);

  // Calculate total pages for all, passé, and avenir tabs
  const totalAllPages = Math.ceil(filteredEvents.length / cardsPerPage);
  const totalPasséPages = Math.ceil(pastEvents.length / cardsPerPage);
  const totalAvenirPages = Math.ceil(upcomingEvents.length / cardsPerPage);

  // Calculate currentEvents based on tabValue and currentPage
  let currentEvents = [];
  if (tabValue === "all") {
    currentEvents = filteredEvents.slice(
      (currentPage - 1) * cardsPerPage,
      currentPage * cardsPerPage
    );
    totalPages = totalAllPages;
  } else if (tabValue === "passé") {
    currentEvents = pastEvents.slice((currentPage - 1) * cardsPerPage, currentPage * cardsPerPage);
    totalPages = totalPasséPages;
  } else if (tabValue === "avenir") {
    currentEvents = upcomingEvents.slice(
      (currentPage - 1) * cardsPerPage,
      currentPage * cardsPerPage
    );
    totalPages = totalAvenirPages;
  }

  useEffect(() => {
    setCurrentPage(1); // Reset page number when category changes
  }, [tabValue]);

  // Inside the handleSetTabValue function
  const handleSetTabValue = (_, newValue) => {
    setLoading(true); // Set loading to true when tab value changes
    setTabValue(newValue);
  };

  // Inside the useEffect for handling loading state
  useEffect(() => {
    if (tabValue !== "" || filteredEvents.length !== 0 || currentEvents.length !== 0) {
      setLoading(false); // Set loading to false when events are filtered
    }
  }, [tabValue, filteredEvents, currentEvents]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDeleteEvent = (eventId) => {
    api.delete(`delete-event/${eventId}`).then(() => {
      // Refresh the events after deletion
      handleEvents();
    });
  };

  const handleAddParticipant = (event, userId) => {
    // Check if participant already added to this event
    if (event.participantsId.includes(userId)) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Vous ne pouvez pas vous rejoindre plusieurs fois au même événement.",
        confirmButtonText: "ok",
      });
      return;
    }
    api
      .put(`update-event/${event._id}`, {
        nbParticipant: event.nbParticipant + 1,
        participantsId: [...event.participantsId, userId],
      })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Participation réussie",
          confirmButtonText: "ok",
        });
        // Refresh the events after participant added
        handleEvents();
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card
          sx={{
            border: `1px solid ${grey[200]}`,
            bgcolor: "white",
            borderRadius: "15px",
            p: "20px",
            mt: "16px",
            overflow: "visible",
            zIndex: 1,
          }}
        >
          <Grid container p={3}>
            <Grid item xs={12} md={6}>
              <SoftTypography variant="h5" fontWeight="medium">
                Liste des événements
              </SoftTypography>
            </Grid>
            {(controller.user.info.role === "admin" || controller.user.info.role === "editor" || controller.user.info.role === "company-admin") && (
              <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
                <SoftButton
                  variant="gradient"
                  color="purple"
                  onClick={() => {
                    navigate("/events/listEvents/ajouterEvent");
                  }}
                >
                  
                  Ajouter Évènement
                </SoftButton>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: { xs: "block", md: "flex" },
                alignItems: "center",
                my: { xs: "16px", md: "0px" },
                zIndex: 2,
              }}
            >
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="medium"
                color="text"
                sx={{ mr: 1 }}
              >
                Filtrer Par Catégorie:
              </SoftTypography>
              <Box sx={{ width: "75%" }}>
                <SoftSelect
                  options={SelectData.categorie}
                  onChange={(event) => {
                    handleCategoryChange(event);
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <SoftBox m={2} sx={{ zIndex: 1 }}>
            <AppBar position="static" sx={{ mb: "16px" }}>
              <Tabs orientation={"horizontal"} value={tabValue} onChange={handleSetTabValue}>
                <Tab value="all" label="Tous les événements" />
                <Tab value="avenir" label="Événement à venir" />
                <Tab value="passé" label="Événement passé" />
              </Tabs>
            </AppBar>
          </SoftBox>
          <Box>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "500px",
                }}
              >
                <CircularProgress color="primary" sx={{ mr: 1 }} />
                <span style={{ fontSize: "1.2rem", color: "primary" }}>En cours</span>
              </Box>
            ) : (
              <Box>
                <Grid container spacing={2}>
                  {currentEvents.map((event) => (
                    <Grid item xs={12} md={6} lg={4} key={event._id}>
                      <ComplexProjectCard
                        id={event._id}
                        nomEvent={event.nom}
                        image={event.image}
                        categorie={event.categorie}
                        date={moment(event.dateDebut).format("DD/MM/YYYY") + " " + event.heureDebut}
                        participant={event.nbParticipant}
                        capacite={event.capacite}
                        dateDebut={event.dateDebut}
                        onAddParticipant={() => {
                          handleAddParticipant(event, controller.user.info._id);
                        }}
                        onDelete={() => {
                          const newSwal = Swal.mixin({
                            customClass: {
                              confirmButton: "button button-success",
                              cancelButton: "button button-error",
                            },
                            buttonsStyling: false,
                          });

                          newSwal
                            .fire({
                              title: "Êtes-vous sûr(e)",
                              text: "Vous ne pourrez pas revenir en arrière !",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonText: "Oui, supprimer !",
                              cancelButtonText: "Annuler",
                            })
                            .then(async (result) => {
                              if (result.isConfirmed) {
                                handleDeleteEvent(event._id);
                              }
                            });
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            <SoftPagination
              currentPage={currentPage}
              totalPages={
                tabValue === "all"
                  ? totalAllPages
                  : tabValue === "avenir"
                  ? totalAvenirPages
                  : totalPasséPages
              }
              onPageChange={handlePageChange}
            />
          </Box>
        </Card>

        {/* Render pagination component if there are multiple pages */}
        {totalPages > 1 && (
          <SoftPagination>
            {currentPage > 1 && (
              <SoftPagination item onClick={prevPage}>
                <ArrowBackIos />
              </SoftPagination>
            )}
            {Array.from({ length: totalPages }, (_, index) => (
              <SoftPagination
                item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </SoftPagination>
            ))}
            {currentPage < totalPages && (
              <SoftPagination item onClick={nextPage}>
                <ArrowForwardIos />
              </SoftPagination>
            )}
          </SoftPagination>
        )}
      </SoftBox>
    </DashboardLayout>
  );
}

export default ListEvents;
