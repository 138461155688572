import React, { useEffect, useState } from "react";
import EventCalendar from "../../../../planning/shared/Calendar";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import api from "utils/axios";
import { useParams } from "react-router-dom";
import SoftBox from "components/SoftBox";

export default function Emploi() {
  const [seances, setSeances] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const handleFetch = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/get-all-sessions/${id}`);
      setSeances(response?.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };
  useEffect(() => {
    handleFetch();
  }, []);
  const eventContent = (arg) => {
    const cardStyle = {
      // textAlign:"center",
      padding: "8px 8px",
      backgroundColor: "#7f47e8",
      color: "#333",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "1em",
      marginTop: "-0.5px",
      height: "100%",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      position: "relative", // Ajout de position relative pour positionner les icône
      display: "flex", // Use flexbox
      justifyContent: "space-between", // Align items with space between
      flexDirection: "column", // Arrange items vertically
    };
    const titleStyle = {
      fontSize: "0.9em",
      lineHeight: "1",
      color: "#fff",
      fontWeight: "bold",
      display: "flex", // Add display flex
      justifyContent: "space-between", // Add space between the two elements
    };
    const timeTextStyle = {
      fontSize: "0.7em",
      color: "#fff",
      fontStyle: "italic",
      display: "flex",
      justifyContent: "space-between",
    };
    const instructorStyle = {
      fontSize: "0.7em", // Adjust the size as needed
      color: "#ffcc00", // Adjust the color as needed
    };
    return (
      <div style={cardStyle}>
        <div style={titleStyle}>
          <span>{arg.event.title}</span>
          <span>{arg.event.extendedProps.groupe && arg.event.extendedProps.groupe}</span>
        </div>
        <div style={timeTextStyle}>{arg.timeText}</div>
        <div style={instructorStyle}>{arg.event.extendedProps.instructor}</div>
        <div style={timeTextStyle}>
          <span>
            {arg.event.extendedProps.parQuinzaine === true
              ? `${arg.event.extendedProps.subjectType}/15`
              : arg.event.extendedProps.subjectType}
            {arg.event.extendedProps.week != "toute" && ` - ${arg.event.extendedProps.week}`}
          </span>
          <span>{arg.event.extendedProps.salle}</span>
        </div>
      </div>
    );
  };
  const convertSeancesToEvents = () => {
    return seances.map((seance) => ({
      id: seance?._id,
      title: seance.subject.name,
      daysOfWeek: [new Date(seance.day).getDay().toString()],
      start: `${seance.day}T${seance.startTime}`,
      end: `${seance.day}T${seance.endTime}`,
      allDay: false,
      startTime: seance.startTime,
      endTime: seance.endTime,
      extendedProps: {
        instructor: `${seance?.instructor?.firstName} ${seance?.instructor?.lastName}`,
        subjectType: `${seance?.subjectType}`,
        salle: `${seance?.salle?.name}`,
        parQuinzaine: seance?.parQuinzaine,
        groupe: seance?.groupe,
        week: seance?.week,
      },
    }));
  };
  return (
    <SoftBox pb={2}>
      <SoftBox mt={{ xs: 1, lg: 2 }} mb={1}>
        <EventCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "timeGridWeek,dayGridMonth,timeGridDay",
          }}
          initialView="dayGridMonth"
          validRange={{ start: seances[0]?.planing?.startDate, end: seances[0]?.planing?.endDate }}
          events={convertSeancesToEvents()}
          selectMirror={true}
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }}
          locale={frLocale}
          eventContent={eventContent}
          eventDisplay="block"
          eventBackgroundColor="purple"
          eventBorderColor="purple"
          slotEventOverlap={false}
          slotLabelContent={(slotInfo) => {
            const hourHeight = 60; // Set the desired height for each hour
            return (
              <div style={{ height: `${hourHeight}px`, display: "flex", alignItems: "flex-end" }}>
                {slotInfo.text}
              </div>
            );
          }}
        />
      </SoftBox>
    </SoftBox>
  );
}
