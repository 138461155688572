import { useState } from 'react';
import PropTypes from "prop-types";


import Grid from "@mui/material/Grid";
import FormField from "pages/billing-settings/components/FormField";
import { useSoftUIController } from "context";
import calculateTotal from "pages/billings-new/calculateInvoicesTotals";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';


const TotalRow = ({title, value}) => (
  <>
    <Grid item xs={12} sm={9} />
    <Grid item xs={12} sm={3}>
      { `${title} : ${value}` }
    </Grid>
  </>
);


// TODO: next-step:  disabel the general discount if there was a set of tva that contain more than one value 

const Totals = ({ currency, applyTva, onChangeGeneralDiscount }) => {

  const [{ purchases }, dispatch] = useSoftUIController();
  const [generalDiscount, setGeneralDiscount] = useState(0);

  const purchasesHashMaps = [];
  const tvaSet = new Set();
  
  purchases.map(p => p.toObject()).forEach(p => {
    if (p.quantity > 0 && p.unitPriceHT > 0) {
      purchasesHashMaps.push(p);
      tvaSet.add(p.tva);
    }
  });
  
  const disableInput = (applyTva && tvaSet.size > 1);
  
  console.debug('generalDiscount => ', generalDiscount, 'disableInput => ', disableInput, '(disableInput ? 0 : generalDiscount) => ', (disableInput ? 0 : generalDiscount));
  

  const totals = calculateTotal(purchasesHashMaps, applyTva, (disableInput ? 0 : generalDiscount), currency);



  console.debug('Totals => purchases changed : ', purchases);
  

  return (
    <>
      <Grid item xs={12} sm={3} display="flex">
        <FormField
          label="Remise générale en (%)"
          inputProps={{ type: "number", min: 0 }}
          disabled = {disableInput}
          defaultValue={generalDiscount}
          onChange = {(e) => {
            const value = +e.target.value;
            onChangeGeneralDiscount(value);
            setGeneralDiscount(value);
          }}
        />
        { disableInput && (
          <Tooltip title="Il n'est pas possible d'effectuer une remise générale sur ce document. Veuillez à la place appliquer une remise par ligne.">
            <InfoIcon />
          </Tooltip>
        )}
      </Grid>

      {
        totals.map(({ title, value }, index) => (
          <TotalRow title={title} value={`${value} ${currency}`} key={`offset-${index}-${title}`} />
        ))
      }
    </>
  );
};


TotalRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,

};

Totals.propTypes = {
  currency: PropTypes.string,
  onChangeGeneralDiscount: PropTypes.func,
  applyTva: PropTypes.bool,
}

export default Totals;
