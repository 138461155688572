import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import api from "utils/axios";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import CircularProgress from '@mui/material/CircularProgress';

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import lock from "assets/images/illustrations/customized-lock.png";
import emailSentChat from "assets/images/illustrations/chart.png";
import  { emailRegex } from "utils/regex";

function ForgetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [alert, setAlert] = useState({show: false, message: '', type: ''});
  const [email, setEmail] = useState({ value: "", isValid: true });
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    document.title = "mot de passe oublié";
  }, []);

  const handleEmailInputChanges = (e) => {
    setEmail((prevState) => ({
      ...prevState,
      value: e.target?.value
    }));
  }

  const handleEmailValidation = () => {
    setEmail((prevState) => ({
      ...prevState,
      isValid: emailRegex.test(prevState.value)
    }));
  }

  const sendButtonHandler = () => {
    setIsLoading(true);
    api.post('/reset-password', {
      email: email.value
    }).then((response) => {
      setEmailSent(true);
    }).catch((error) => {
      if (error.code === 'ERR_BAD_REQUEST') {
        setAlert({ show: true, message: error.response?.data?.message, type: 'error'});
      } else if (error.code === 'ERR_NETWORK') {
        setAlert({ show: true, message: 'problem coté server 500', type: 'error'});
      } else {
        setAlert({ show: true, message: error.message, type: 'error'});
      }
      setTimeout(() => {
        setAlert({ show: false, message: '', type: ''});
      }, 10_000);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <IllustrationLayout
      showDefaultNavBar={false}
      title={emailSent ? "Un e-mail a été envoyé" : "Récupérer votre compte Square"}
      description={ emailSent ? "Veuillez vérifier votre boîte de réception et cliquer sur le lien reçu pour réinitialiser votre mot de passe." : "Vous recevrez un e-mail de récupération dans 60 secondes maximum" }
      illustration={{
        image: emailSent ? emailSentChat : lock,
        title: emailSent ? "Retrouvez l'accès" : "Récupération de compte",
        description: emailSent ? "Un chemin simple pour retrouver votre accés perdu. Suivez ces étapes rapides pour restaurer votre compte en toute simplicité." : "Récupérez votre compte en toute simplicité ! Suivez attentivement les instructions de l'e-mail qui arrivera dans votre boîte de réception.",
      }}
    >
      <SoftBox component="form" role="form">
        {
          !emailSent &&
          <SoftBox mb={2} id="forget-password-box">
            <SoftInput
              type="email" placeholder="Adresse e-mail" size="large"
              id="forget-password-email-input" error = {!email.isValid}
              onChange={handleEmailInputChanges}
              onBlur = {handleEmailValidation}
            />
          </SoftBox>
        }
        <SoftBox mt={4} mb={1} display="flex" justifyContent="center">
          {
            isLoading && !emailSent
            ? <CircularProgress />
            : (
              emailSent
              ? <SoftButton variant="gradient" color="customized"  style={{ width: '100%', height: '45px' }} fullWidth id="login-button" onClick={() => navigate('/')}> Se connecter </SoftButton>
              : <SoftButton variant="gradient" color="customized"  style={{ width: '100%', height: '45px' }} fullWidth id="forget-password-send-button" onClick={sendButtonHandler}> envoyer </SoftButton>
            )
          }
        </SoftBox>
        { 
          !emailSent &&
          <SoftBox my={4} display="flex" justifyContent="center">
            <SoftTypography
              component={Link}
              to="/"
              variant="button"
              color="customized"
              fontWeight="medium"
              textGradient
              id="back-to-login"
            >
              {"Retour à la page d’authentification"}
            </SoftTypography>
          </SoftBox>
        }
        {emailSent &&
          <SoftBox display="flex" justifyContent="space-between">
            <SoftTypography
              variant="button"
              fontWeight="medium"
              textGradient
              id="resent-email-text"
            >
              {"Vous n'avez pas reçue le lien"}
            </SoftTypography>
            <SoftTypography
              role="button"
              variant="button"
              fontWeight="medium"
              color={isLoading ? "dark" : "customized"}
              textGradient
              id="resent-email-btn"
              onClick={
                () => {
                  if (isLoading) return;
                  sendButtonHandler();
                }
              }
              sx={{
                cursor: isLoading ? '' : 'pointer'
              }}
            >
              Renvoyer
            </SoftTypography>
          </SoftBox>
        }
        { alert.show && <SoftTypography m={4} color={alert.type} id="error-alert" fontWeight="bold" fontSize={15} style={{ textAlign: 'center' }}  >{ alert.message } </SoftTypography> }
      </SoftBox>
    </IllustrationLayout>
  );
}

export default ForgetPassword;
