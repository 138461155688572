import SoftBox from "components/SoftBox";
import React from "react";
import { Checkbox, Grid, Stack, TextField, Typography } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import FormFieldSubject from "../schema/FormFieldSubject";
import { useFormikContext } from "formik";

export default function TypesInfo({ formData }) {
  const { formField, errors, touched, values } = formData;
  const { setFieldError, setFieldValue } = useFormikContext();

  const {
    checkedCours,
    checkedTd,
    checkedTp,
    checkedMp,
    vhcours,
    durationcours,
    vhtd,
    durationtd,
    vhtp,
    durationtp,
    vhmp,
    durationmp,
  } = formField;
  const {
    checkedPcExamen: checkedPcExamenV,
    checkedPcDs1: checkedPcDs1V,
    checkedPcDs2: checkedPcDs2V,
    checkedPcCc: checkedPcCcV,
    checkedPcTd: checkedPcTdV,
    checkedPcTp: checkedPcTpV,
    checkedPcMp: checkedPcMpV,
    checkedCours: checkedCoursV,
    checkedTd: checkedTdV,
    checkedTp: checkedTpV,
    checkedMp: checkedMpV,
    vhcours: vhcoursV,
    durationcours: durationcoursV,
    vhtd: vhtdV,
    durationtd: durationtdV,
    vhtp: vhtpV,
    durationtp: durationtpV,
    vhmp: vhmpV,
    durationmp: durationmpV,
  } = values;

  return (
    <SoftBox mt={1.625}>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        {values.categorie === "Matiere" && (
          <>
            <Grid item xs={12} sm={2}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={6} sm={6}>
                  <SoftTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                    COURS
                  </SoftTypography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Checkbox
                    defaultChecked={checkedCoursV}
                    onChange={() => {
                      setFieldValue("checkedCours", !checkedCoursV);
                      setFieldValue("checkedPcExamen", !checkedPcExamenV);
                      setFieldValue("checkedPcDs1", !checkedPcDs1V);
                      setFieldValue("checkedPcDs2", !checkedPcDs2V);
                      setFieldValue("checkedPcCc", !checkedPcCcV);
                      setFieldValue("vhcours", 1);
                      setFieldValue("durationcours", 1);
                      if (checkedCoursV === false) {
                        setFieldValue("bloccours", "h");
                        setFieldValue("sallecours", "h");
                        
                      } else {
                        setFieldValue("bloccours", "");
                        setFieldValue("sallecours", "");
                      }
                    }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormFieldSubject
                    id="vhcours"
                    name={vhcours.name}
                    label={vhcours.placeholder}
                    type="number"
                    margin="normal"
                    variant="standard"
                    fullWidth
                    placeholder="Volume horaire  cours"
                    disabled={!checkedCoursV}
                    value={vhcoursV}
                    error={checkedCoursV && errors.vhcours && touched.vhcours}
                    success={checkedCoursV && vhcoursV > 0 && !errors.vhcours}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormFieldSubject
                    id="durationcours"
                    name={durationcours.name}
                    label={durationcours.placeholder}
                    type="number"
                    margin="normal"
                    variant="standard"
                    fullWidth
                    placeholder="durée cours"
                    disabled={!checkedCoursV}
                    value={durationcoursV}
                    error={checkedCours && errors.durationcours && touched.durationcours}
                    success={checkedCours && durationcoursV > 0 && !errors.durationcours}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={6} sm={6}>
                  <SoftTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                    TD
                  </SoftTypography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Checkbox
                    defaultChecked={checkedTdV}
                    onChange={() => {
                      setFieldValue("checkedTd", !checkedTdV);
                      setFieldValue("checkedPcTd", !checkedPcTdV);
                      setFieldValue("vhtd", 1);
                      setFieldValue("durationtd", 1);
                      setFieldValue("pctd", null);
                      if (!checkedTd) {
                        setFieldError("vhtd", errors.vhtd);
                        setFieldError("durationtd", errors.durationtd);
                      } else {
                        setFieldError("vhtd", undefined);
                        setFieldError("durationtd", undefined);
                      }
                      if (checkedTdV === false) {
                        setFieldValue("bloctd", "h");
                        setFieldValue("salletd", "h");
                      } else {
                        setFieldValue("bloctd", "");
                        setFieldValue("salletd", "");
                      }
                    }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormFieldSubject
                    id="vhtd"
                    name={vhtd.name}
                    label={vhtd.placeholder}
                    type="number"
                    margin="normal"
                    variant="standard"
                    fullWidth
                    placeholder="Volume horaire TD"
                    disabled={!checkedTdV}
                    value={vhtdV}
                    error={checkedTdV && errors.vhtd && touched.vhtd}
                    success={checkedTdV && vhtdV > 0 && !errors.vhtd}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormFieldSubject
                    id="durationtd"
                    name={durationtd.name}
                    label={durationtd.placeholder}
                    type="number"
                    margin="normal"
                    variant="standard"
                    fullWidth
                    placeholder="durée td"
                    disabled={!checkedTdV}
                    value={durationtdV}
                    error={checkedTdV && errors.durationtd && touched.durationtd}
                    success={checkedTdV && durationtdV > 0 && !errors.durationtd}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={6} sm={6}>
                  <SoftTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                    TP
                  </SoftTypography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Checkbox
                    defaultChecked={checkedTpV}
                    onChange={() => {
                      setFieldValue("checkedTp", !checkedTpV);
                      setFieldValue("checkedPcTp", !checkedPcTpV);
                      setFieldValue("vhtp", 1);
                      setFieldValue("durationtp", 1);
                      setFieldValue("pctp", null);
                      if (checkedTpV === false) {
                        setFieldValue("bloctp", "h");
                        setFieldValue("salletp", "h");
                      } else {
                        setFieldValue("bloctp", "");
                        setFieldValue("salletp", "");
                      }
                    }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormFieldSubject
                    id="vhtp"
                    name={vhtp.name}
                    label={vhtp.placeholder}
                    type="number"
                    margin="normal"
                    variant="standard"
                    fullWidth
                    placeholder="Volume horaire tp"
                    disabled={!checkedTpV}
                    value={vhtpV}
                    error={checkedTpV && errors.vhtp && touched.vhtp}
                    success={checkedTpV && vhtpV > 0 && !errors.vhtp}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormFieldSubject
                    id="durationtp"
                    name={durationtp.name}
                    label={durationtp.placeholder}
                    type="number"
                    margin="normal"
                    variant="standard"
                    fullWidth
                    placeholder="durée tp"
                    disabled={!checkedTpV}
                    value={durationtpV}
                    error={checkedTpV && errors.durationtp && touched.durationtp}
                    success={checkedTpV && durationtpV > 0 && !errors.durationtp}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={6} sm={6}>
                  <SoftTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                    Mini projet
                  </SoftTypography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Checkbox
                    defaultChecked={checkedMpV}
                    onChange={() => {
                      setFieldValue("checkedMp", !checkedMpV);
                      setFieldValue("checkedPcMp", !checkedPcMpV);
                      setFieldValue("vhmp", 1);
                      setFieldValue("durationmp", 1);
                      setFieldValue("pcmp", null);
                      if (checkedMpV === false) {
                        setFieldValue("blocmp", "h");
                        setFieldValue("sallemp", "h");
                      } else {
                        setFieldValue("blocmp", "");
                        setFieldValue("sallemp", "");
                      }
                    }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormFieldSubject
                    id="vhmp"
                    name={vhmp.name}
                    label={vhmp.placeholder}
                    type="number"
                    margin="normal"
                    variant="standard"
                    fullWidth
                    placeholder="Volume horaire Mp"
                    disabled={!checkedMpV}
                    value={vhmpV}
                    error={checkedMpV && errors.vhmp && touched.vhmp}
                    success={checkedMpV && vhmpV > 0 && !errors.vhmp}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormFieldSubject
                    id="durationmp"
                    name={durationmp.name}
                    label={durationmp.placeholder}
                    type="number"
                    margin="normal"
                    variant="standard"
                    fullWidth
                    placeholder="durée mini projet"
                    disabled={!checkedMpV}
                    value={durationmpV}
                    error={checkedMpV && errors.durationmp && touched.durationmp}
                    success={checkedMpV && durationmpV > 0 && !errors.durationmp}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </SoftBox>
  );
}
TypesInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
