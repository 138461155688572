import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import books from "assets/images/books.svg";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Custom styles for ComplexProjectCard
function ComplexProjectCard({ students, image, name, dateTime, members, dropdown,instructorName }) {
  const navigate = useNavigate();
  const facility = JSON.parse(localStorage.getItem("facility"));
  const renderMembers = members.map((member, key) => {
    const memberKey = `member-${key}`;
    return (
      <SoftAvatar
        key={memberKey}
        src={member}
        alt="member profile"
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",

          "&:not(:first-of-type)": {
            ml: -1.25,
          },

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    );
  });
  return (
    <Card
    //style={{ cursor: "pointer" }}
    >
      <SoftBox
        p={1.75}
        sx={{
          backgroundImage: `url(${books})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100px 150px",
          backgroundPosition: "calc(100% - 10px) calc(100% - 3px)",
        }}
      >
        <SoftBox display="flex" alignItems="flex-start" justifyContent="space-between">
          <SoftAvatar src={image} alt={name} size="xl" variant="rounded" />
          <SoftBox ml={1} lineHeight={0} sx={{ overflow: "hidden" }}>
            <SoftBox mb={0.2} lineHeight={0}>
              <Tooltip title={name || ""}>
                <SoftTypography
                  variant="h6"
                  textTransform="capitalize"
                  fontWeight="medium"
                  sx={{
                    wordWrap: "break-word",
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                  }}
                >
                  {name}
                </SoftTypography>
              </Tooltip>
            </SoftBox>   
          </SoftBox>
          <SoftBox
            sx={{
              ml: "auto",
              alignSelf: "flex-start",
              py: 1.25,
            }}
            display="flex"
            alignItems="center" // Add this line
          >
            {dateTime && (
              <SoftBox
                display="flex"
                flexDirection="column"
                lineHeight={1}
                ml={2}
                alignItems="flex-end"
              >
                <SoftTypography variant="button" fontWeight="bold">
                  {dateTime}
                </SoftTypography>
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color="secondary"
                  fontSize="12px"
                  sx={{
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                >
                  Date
                </SoftTypography>
              </SoftBox>
            )}
            {dropdown && (
              <SoftTypography color="secondary" onClick={dropdown.action}>
                <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                  more_vert
                </Icon>
              </SoftTypography>
            )}
            {dropdown.menu}
          </SoftBox>
        </SoftBox>
        <SoftBox my={2} lineHeight={1} maxWidth={"calc(100% - 23%)"}>
          <SoftTypography
            variant="button"
            fontWeight="medium"
            color="secondary"
            fontSize="12px"
            sx={{ textAlign: "center" }}
          >
            If everything I did failed - which it doesn&lsquo;t, I think that it actually succeeds.
          </SoftTypography>
        </SoftBox>
        <Divider />
        {facility?.type === "centreDeFormation" ? (
          <SoftBox display="flex" justifyContent="space-between" alignItems="center">
            <SoftBox display="flex" flexDirection="column" lineHeight={0}>
              <SoftTypography variant="button" fontWeight="medium">
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  lineHeight={0}
                >
                  <SoftBox display="flex" sx={{ mr: 0.5 }}>
                    {renderMembers}
                    <SoftTypography variant="button" fontWeight="medium" ml={1}>
                     {instructorName}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        ) : (
          <SoftBox display="flex" justifyContent="space-between" alignItems="center">
            {students > -1 ? (
              <SoftBox display="flex" flexDirection="column" lineHeight={0}>
                <SoftTypography variant="button" fontWeight="medium">
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    lineHeight={0}
                  >
                    {members.length > -1 ? (
                      <SoftBox display="flex" sx={{ mr: 0.5 }}>
                        {renderMembers}
                      </SoftBox>
                    ) : null}
                  </SoftBox>
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="medium">
                  {students} Etudiants
                </SoftTypography>
              </SoftBox>
            ) : null}
          </SoftBox>
        )}
      </SoftBox>
    </Card>
  );
}
// Setting default values for the props of ComplexProjectCard
ComplexProjectCard.defaultProps = {
  color: "dark",
  dateTime: "",
  members: [],
  dropdown: false,
};
// Typechecking props for the ProfileInfoCard
ComplexProjectCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  instructorName: PropTypes.string,
  students: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.node.isRequired,
  members: PropTypes.arrayOf(PropTypes.string),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};
export default ComplexProjectCard;
