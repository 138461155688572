import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DashboardNavbar from "layouts/DashboardNavbar";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ComplexProjectCard from "components/shared-users/Cards";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import SoftPagination from "components/SoftPagination"
// Project page components
import Header from "layouts/pages/profile/components/Header";
import api from "utils/axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { useSoftUIController } from 'context';
function AllProjects() {
  // ComplexProjectCard dropdown menu state
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  // TeamProfileCard dropdown menu handlers
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const navigate = useNavigate();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [controller] = useSoftUIController();
  const user=controller?.user?.info
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText:"Annuler"
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(`/user/${selectedUserId}`);
            if (response) {
              (await Swal.fire("Supprimé !", "Le commercial a été supprimé.", "success"))
                .isConfirmed && handleFetch();
               closeSlackBotMenu()
            }
          } catch (error) {
            Swal.fire("Une erreur s'est produite !", "Le commercial n'a pas été supprimé.", "error");
            closeSlackBotMenu()
          }
        }
      });
  };
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  // Fonction pour passer à la page précédente
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => {
    if (
      (user?.isFormateur === true && user?.role === "commercial") ||
      user?.role === "super-admin"
    ) {
      return (
        <Menu
          anchorEl={state}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(state)}
          onClose={close}
          keepMounted
        >
          <MenuItem onClick={() => navigate(`/commercials/view/${selectedUserId}`)}>
            <RemoveRedEyeIcon /> &nbsp; Voir plus
          </MenuItem>
          <MenuItem onClick={() => navigate(`/commercials/${selectedUserId}`)}>
            <EditIcon /> &nbsp; Modifier
          </MenuItem>
          <MenuItem onClick={showAlert}>
            <DeleteIcon /> &nbsp; Supprimer
          </MenuItem>
        </Menu>
      );
    } else {
      return null; // Retourne null si les conditions ne sont pas remplies
    }
  };
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle
  const itemsPerPage = 8; // Nombre d'articles par page
  const handleFetch = async () => {
    try {
      setLoading(true);
      const response = await api.get("/get-user-commercial?role=commercial");
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };
  useEffect(()=> {
    // change page title in the header
    document.title = "commercial";
  }, []);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  // Extraction des données de la page actuelle
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);
  useEffect(() => {
    handleFetch();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header  title="Liste des commerciaux" searchVisible={true} />
      {loading ? (
        <SoftBox
          sx={{
            mt: 13,
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </SoftBox>
      ) : (<>
        <SoftBox pb={2}>
          <SoftBox mt={{ xs: 1, lg: 2 }} mb={1}>
            <Grid container spacing={3}>
            {
  (user?.isFormateur === true && user?.role === "commercial") || user?.role === "super-admin" ? (
    <Grid
      onClick={() => navigate("/commercials/add-commercial")}
      item
      xs={12}
      md={20}
      lg={4}
      sx={{ cursor: "pointer" }}
    >
      <PlaceholderCard
        title={{ variant: "h5", text: "Ajouter Commercial" }}
      ></PlaceholderCard>
    </Grid>
  ) : null
}

              {currentData &&
                currentData?.map((user) => (
                  <Grid key={user?._id} item xs={12} md={6} lg={4}>
                    <ComplexProjectCard
                      image={user?.imageUrl?.startsWith("https")
                      ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                      : `${process.env.REACT_APP_BASE_URL}/${user?.imageUrl}`}
                      title={`${user.firstName} ${user.lastName}`}
                      phone={user.phoneNumber}
                      email={user.email}
                      description=""
                      occupation={user.occupation}
                      userId={user?._id}
                      etat={user?.etat}
                      dropdown={{
                        action: (e) => {
                          // openSlackBotMenu;
                          setSlackBotMenu(e.currentTarget);
                          setSelectedUserId(user._id);
                        },
                        menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </SoftBox>
        </SoftBox>
       {totalPages>1 && ( <SoftPagination>
          {currentPage > 1 && (
            <SoftPagination item onClick={prevPage}><ArrowBackIosIcon /></SoftPagination>
          )}
          {Array.from({ length: totalPages }, (_, index) => (
            <SoftPagination item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </SoftPagination >
          ))}
          {currentPage < totalPages && (
            <SoftPagination item onClick={nextPage}><ArrowForwardIosIcon /> </SoftPagination>
          )}
        </SoftPagination>
)}
      </>
      )}
    </DashboardLayout>
  );
}
export default AllProjects;