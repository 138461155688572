import React, { useEffect, useState } from "react";
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import api from "utils/axios";
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSoftUIController } from "context";
function SpecialityActions({ filiere, handleFetch, setShowDetails, setDetailsData }) {
  const [open, setOpen] = useState(false);
  const [mention, setMention] = useState(filiere?.mention || "");
  const[formation, setformation]=useState(filiere?.formation?._id || "")
  const [departement,SetDepartement]=useState(filiere?.departement?._id || "")
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [errorMessageFormation, setErrorMessageformation] = useState("");
  const [errorMessageDepartement, setErrorMessageDepartement] = useState("");
  const [departementData, setDepartementData] = useState([]);
  const [formationData, setForamtionData] = useState([])
  const [errorMessage, setErrorMessage] = useState("");
  const [optionsF, setOptionsF] = useState([]);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [controller] = useSoftUIController();

  useEffect(() => {
    api.get(`/faculty/all-departements/${facility.scholarityConfigId}`).then((res) => {
      setDepartementData(res.data);
      setOptions(
        res.data.map((item) => {
          return {
            value: item._id,
            label: item.name,
          };
        })
      );
    });
    SetDepartement(filiere?.departement?._id || "")
  }, []);
  useEffect(() => {
    api.get(`/faculty/get-all-formations/${facility.scholarityConfigId}`).then((res) => {
      setForamtionData(res.data);
      setOptionsF(
        res.data.map((item) => {
          return {
            value: item._id,
            label: item.name,
          };
        })
      );
    });
    setformation (filiere?.formation?._id || "")
  }, []);
  
  const handleUpdate = async () => {
    try {
      setLoading(true);
      if (mention.length < 2) {
        // Set the error message
        setErrorMessage("La longueur minimale requise est de 2 caractères.");
      }  else if (errorMessageDepartement){
        setErrorMessageDepartement("") ;
       } else if (errorMessageFormation){
        setErrorMessageformation("");
       }
      else {
        // Effectuez la mise à jour de la formation
        const response = await api.patch(`/faculty/update-filiere/${filiere._id}`, { mention, departement , formation});
        setMention(response.data.mention);    
        Swal.fire("Success!", "Mise à jour formation réussie", "success");
        handleFetch();
        setOpen(false);
        setErrorMessage("");
      }
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setErrorMessageformation("");
    setErrorMessageDepartement("");
    setMention(filiere.mention);
    SetDepartement(filiere?.departement || "");
    setformation (filiere?.formation || "");
  };
  const showAlert = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous voulez supprimer cette formation ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(`/faculty/delete-filiere/${filiere._id}`);
            if (response) {
              (await Swal.fire("Suppression !", "le filiere a été supprimée.", "success"))
                .isConfirmed && handleFetch();
            }
          } catch (error) {
            Swal.fire("Erreur!", "La filiere n'a pas été supprimée.", "error");
          }
        }
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Consulter" placement="left">
          <VisibilityIcon
            onClick={() => {
              setDetailsData(filiere);
              setShowDetails(true);
            }}
          />
        </Tooltip>
      </SoftTypography>
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Modifier" placement="top">
            <Icon onClick={() => setOpen(true)} id="edit-enterprise-page">
              edit
            </Icon>
          </Tooltip>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            onKeyDown={handleKeyPress}
          >
            <DialogTitle>Modifier filiere</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.grey[200],
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <DialogContentText>
                Vous devez saisir le nouveau nom de votre filiere.
              </DialogContentText>
              <Grid sx={{ mt: 1 }}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "flex-start",
                  }}
                >             {facility?.type === "faculty" && (
                  <Grid item xs={12} sm={4}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Nom de departement
                    </SoftTypography>
                    <Autocomplete
                      //    id={`size-small-standard-input-${name}`}
                      options={options}
                      title="Select département"
                      getOptionLabel={(option) => option.label}
                      clearIcon={null}
                      defaultValue={
                        options.find(
                          (option) => option.value === filiere?.departement?._id
                        ) || null
                      }
                      onChange={(e, newValue) => {
                        if (newValue !== null && newValue !== undefined) {
                          SetDepartement(newValue.value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          value={filiere?.departement.name}
                          placeholder="Toutes les branches"
                        />
                      )}
                    />
                  </Grid>
                )}
                  {facility?.type === "faculty" && (
                    <Grid item xs={12} sm={4}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Nom de formation
                      </SoftTypography>
                      <Autocomplete
                        //    id={`size-small-standard-input-${name}`}
                        options={optionsF}
                        title="Select département"
                        getOptionLabel={(option) => option.label}
                        clearIcon={null}
                        defaultValue={
                          optionsF.find(
                            (option) => option.value === filiere?.formation?._id
                          ) || null
                        }
                        onChange={(e, newValue) => {
                          if (newValue !== null && newValue !== undefined) {
                            setformation(newValue.value);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            value={filiere?.formation.name}
                            placeholder="Toutes les branches"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={facility?.type === "faculty" ? 4 : 12}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Mention
                    </SoftTypography>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",

                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                      }}
                    >
                      <SoftInput
                        name="mention"
                        margin="normal"
                        id="mention"
                        label="spécialité"
                        type="text"
                        fullWidth
                        placeholder="Nom de la spécialité"
                        inputProps={{
                          minLength: 2,
                          maxLength: 50,
                        }}
                        autoFocus
                        variant="standard"
                        value={mention}
                        onChange={(e) => setMention(e.target.value)}
                        error={mention?.length < 2}
                        success={mention?.length >= 2}
                      />
                      {mention?.length < 2 && (
                        <Typography mt={0.75} variant="caption" color="error">
                          {errorMessage}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>

              <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                <SoftButton
                  onClick={handleClose}
                  sx={{ mt: 2 }}
                  variant="outlined"
                  color="dark"
                  size="small"
                >

                  Annuler
                </SoftButton>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <SoftButton
                    onClick={handleUpdate}
                    sx={{ mt: 2 }}
                    variant="gradient"
                    color="customized"
                    size="small"
                  >
                    Modifier
                  </SoftButton>
                )}
              </SoftBox>
            </DialogActions>
          </Dialog>
        </SoftTypography>
      </SoftBox>
      {controller?.user?.info?.role === "company-admin" && (
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Supprimer" placement="left">
            <Icon id="delete-enterprise" onClick={showAlert}>
              delete
            </Icon>
          </Tooltip>
        </SoftTypography>
      )}
    </SoftBox>
  );
}
SpecialityActions.propTypes = {
  filiere: PropTypes.object.isRequired,
  handleFetch: PropTypes.func.isRequired,
  setShowDetails: PropTypes.bool.isRequired,
  setDetailsData: PropTypes.array.isRequired,
};
export default SpecialityActions;
