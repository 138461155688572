import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SoftTypography from "components/SoftTypography";

import SoftBox from "components/SoftBox";
import {
  Box,
  Card,
  Container,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
} from "@mui/material";
import SoftAvatar from "components/SoftAvatar";

import SettingsIcon from "@mui/icons-material/Settings";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

import SendIcon from "@mui/icons-material/Send";
import SoftButton from "components/SoftButton";

import { useSoftUIController, setNotification } from "context";
import { setChat } from "context";
import InputEmoji from "react-input-emoji";
import api from "utils/axios";
import { useSocket } from "context/SocketContext";
import { isSameSender, isLastMessage } from "utils/ChatLogics";
import DoneIcon from "@mui/icons-material/Done";

function Chat() {
  const [controller, dispatch] = useSoftUIController();
  // If chat is not open, return null
  if (!controller.chat.open) return null;

  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const { socket } = useSocket();
  const containerRef = useRef();
  const user = controller.user.info;

  // Function to calculate time ago
  function timeAgo(timestamp) {
    const currentDate = new Date();
    const previousDate = new Date(timestamp);

    const timeDifference = currentDate - previousDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days + "d ago";
    } else if (hours > 0) {
      return hours + "h ago";
    } else if (minutes > 0) {
      return minutes + "m ago";
    } else {
      return seconds + "s ago";
    }
  }
  // Function to handle chat close
  const handleCloseChat = () => {
    setChat(dispatch, {
      open: false,
      receiver: {},
    });
  };
  // Function to handle message send
  async function handleOnEnter(text) {
    try {
      const response = await api.post("/message", {
        content: text,
        chatId: controller.chat.receiver.chatId,
      });
      setMessages((prev) => [...prev, response.data]);
      const data = [response.data, controller.chat.receiver.chat];
      socket.emit("new message", JSON.stringify(data));
      setValue("");
    } catch (error) {
      throw error;
    }
  }
  // Fetch initial messages on component mount
  useEffect(() => {
    setLoading(true);
    // Fetch initial messages
    api
      .get(`/message/${controller.chat.receiver.chatId}`)
      .then((res) => {
        const response = res.data.reverse();
        setMessages(response);
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [controller.chat.receiver.chatId, socket]);

  // Listen for new messages
  useEffect(() => {
    socket.on("message recieved", (newMessageRecived) => {
      setMessages((prev) => [...prev, JSON.parse(newMessageRecived)]);
      api
        .get("/get-all-notif")
        .then((res) => {
          setNotification(dispatch, {
            notifications: res.data,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    });

    return () => {
      socket.off("message received");
      socket.off("new message");
    };
  }, [controller.chat.receiver.chatId]);
  // Scroll to bottom on new message
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages.length, loading]);

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const options = { weekday: "short", hour: "numeric", minute: "numeric", hour12: false };
    const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(date);
    return formattedDate;
  }
  return (
    <Card
      sx={{
        height: "95%",
        maxHeight: "480px",
        width: "338px",
        backgroundColor: "#E9ECEF",
        overflow: "visible",
      }}
    >
      <SoftBox sx={{ height: "12%", background: "white" }}>
        <SoftBox
          py={1}
          mt={0.5}
          ml={1.5}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid spacing={2} display="flex" alignItems="center" justifyContent="space-between">
            <SoftBox
              sx={{
                p: 0.3,
                border: `3px solid ${controller.connectedUsers.some(
                  (user) =>
                    user.userId === controller.chat.receiver.userId && user.status === "online"
                )
                  ? "#0CB40C"
                  : "#C20D0D"
                  }`,

                borderRadius: "1rem",
              }}
            >
              <SoftAvatar
                src={controller.chat.receiver.image}
                variant="rounded"
                size="md"
                shadow="sm"
              />
            </SoftBox>

            <Grid>
              <SoftBox height="100%" lineHeight={1} p={1}>
                <Tooltip title={controller.chat.receiver.fullName || ""}>
                  <SoftTypography
                    variant="h6"
                    textTransform="capitalize"
                    fontWeight="medium"
                    sx={{
                      wordWrap: "break-word",
                      maxWidth: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                    }}
                  >
                    {controller.chat.receiver.fullName}
                  </SoftTypography>
                </Tooltip>
                <SoftTypography variant="caption" color="text" fontWeight="medium">
                  {controller.connectedUsers.some(
                    (user) =>
                      user.userId === controller.chat.receiver.userId && user.status === "online"
                  )
                    ? "En ligne"
                    : "Hors ligne"}{" "}
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
          <SoftBox mx={0.5} p={1} display="flex" alignItems="center" justifyContent="space-between">
            <SoftTypography mr={1} sx={{ cursor: "pointer", lineHeight: 0 }}>
              <Tooltip title="Caméra" placement="top">
                <CameraAltIcon id="camera" />
              </Tooltip>
            </SoftTypography>
            <SoftTypography mr={2.5} sx={{ cursor: "pointer", lineHeight: 0 }}>
              <Tooltip title="Paramètre" placement="top">
                <SettingsIcon sx={{ cursor: "pointer" }} />
              </Tooltip>
            </SoftTypography>
            <SoftTypography sx={{ cursor: "pointer", lineHeight: 0 }} onClick={handleCloseChat}>
              <Tooltip title="Fermer" placement="top">
                <CloseIcon sx={{ cursor: "pointer" }} />
              </Tooltip>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      {!loading ? (
        <Container
          ref={containerRef}
          sx={{
            minHeight: "300px",
            maxHeight: "300px",
            height: "auto",
            borderTop: "1px solid black",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          {messages &&
            messages.map((m, i) => {
              return (
                <Box
                  key={m._id}
                  sx={{
                    display: "flex",
                    justifyContent: m.author.id === user._id ? "flex-end" : "flex-start",
                    mb: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: m.author.id === user._id ? "row-reverse" : "row",
                      alignItems: "center",
                    }}
                  >
                    {m.author.id !== user._id ? (
                      <SoftBox
                        borderRadius="50%"
                        width="3.625rem"
                        height="3.625rem"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        mx="auto"
                        sx={{ cursor: "pointer" }}
                      >
                        {(isSameSender(messages, m, i, user._id) ||
                          isLastMessage(messages, i, user._id)) && (
                            <Tooltip
                              title={`${m.author.firstName} ${m.author.lastName}`}
                              placement="bottom-start"
                              hasArrow
                            >
                              <SoftAvatar src={controller.chat.receiver.image} size="md" />
                            </Tooltip>
                          )}
                      </SoftBox>
                    ) : (
                      <></>
                    )}

                    <Paper
                      variant="outlined"
                      sx={{
                        p: 1.5,
                        my: 2.25,
                        ml: m.author.id === user._id ? 0 : 1,
                        mr: m.author.id === user._id ? 1 : 0,
                        background:
                          m.author.id === user._id
                            ? "linear-gradient(90deg, #EBEFF4 0%, #CED4DA 100%)"
                            : "#F5F5F5",
                        borderRadius:
                          m.author.id === user._id ? "20px 20px 5px 20px" : "20px 20px 20px 5px",
                      }}
                    >
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        justifyContent="center"
                      >
                        <SoftTypography
                          variant="caption"
                          color="black"
                          sx={{
                            fontWeight: "Regular",
                            fontSize: "14px",
                            maxWidth: "200px",
                            wordWrap: "break-word",
                          }}
                          mb={1}
                        >
                          {m.text}
                        </SoftTypography>
                        <SoftBox
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ color: "#9645FF" }}
                        >
                          <DoneIcon />
                          <SoftTypography
                            variant="caption"
                            fontWeight="medium"
                            sx={{ color: "#9645FF" }}
                            mx={0.5}
                          >
                            {formatTimestamp(m.createdAt)}
                            {/* {timeAgo(m.createdAt)} */}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Paper>
                  </Box>
                </Box>
              );
            })}
        </Container>
      ) : (
        <Container
          ref={containerRef}
          sx={{
            minHeight: "300px",
            maxHeight: "300px",
            height: "auto",
            borderTop: "1px solid black",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <Stack spacing={1}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={30} />
            <Skeleton variant="rounded" width={210} height={30} />

            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={30} />
            <Skeleton variant="rounded" width={210} height={30} />
            <Skeleton variant="rounded" width={210} height={30} />
          </Stack>
        </Container>
      )}
      <SoftBox
        p={1.5}
        sx={{
          height: "14%",
          background: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80%", maxWidth: "80%", alignItems: "center" }}>
          <InputEmoji
            placeholder="Type a message"
            value={value}
            onChange={setValue}
            height={20}
            onEnter={handleOnEnter}
          />
        </div>

        <SoftButton
          variant="gradient"
          style={{
            background: "linear-gradient(90deg, #FF0080 0%, #7928CA 100%)",
            color: "white",
          }}
          onClick={() => handleOnEnter(value)}
          mb={1}
        >
          <SendIcon fontSize="medium" />
        </SoftButton>
      </SoftBox>
    </Card>
  );
}

export default Chat;
