import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import SendIcon from "@mui/icons-material/Send";
import EmailIcon from "@mui/icons-material/Email";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import { Tooltip } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { Box } from "material-ui-core";
import color from "material-ui-colors/dist/amber";

// Custom styles for ComplexProjectCard
function ComplexProjectCard({ studentList, students, image, name, dateTime, level, specialitie, members, dropdown, departementId, start, end, capacite, groupes }) {
  const facility = JSON.parse(localStorage.getItem("facility"));
  function calculateGenderPercentage(studentList) {
    let maleCount = 0;
    let femaleCount = 0;
    // Count the number of males and females
    studentList?.forEach(student => {
      if (student.gender === "homme") {
        maleCount++;
      } else if (student.gender === "femme") {
        femaleCount++;
      }
    });
    // Calculate the total number of students
    let totalStudents = studentList?.length || 0;
    // Handle division by zero
    if (totalStudents === 0) {
      return {
        malePercentage: 0,
        femalePercentage: 0
      };
    }
    // Calculate the percentages
    let malePercentage = (maleCount / totalStudents) * 100;
    let femalePercentage = (femaleCount / totalStudents) * 100;
    return {
      malePercentage: malePercentage,
      femalePercentage: femalePercentage
    };
  }
  // Call the function and display the results
  let percentages = calculateGenderPercentage(studentList);
  const formatMonthYear = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "2-digit", month: "2-digit" };
    return date.toLocaleDateString(undefined, options);
  };
  // Format start and end as "MM/YY"
  const formattedStart = start ? formatMonthYear(start) : "";
  const formattedEnd = end ? formatMonthYear(end) : "";
  const renderMembers = members.map((member, key) => {
    const memberKey = `member-${key}`;
    return (
      <SoftAvatar
        key={memberKey}
        src={member}
        alt="member profile"
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          "&:not(:first-of-type)": {
            ml: -1.25,
          },
          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    );
  });
  const chartDataHomme = {
    series: [percentages.malePercentage.toFixed(0)], // Fake data for the radial bars
    options: {
      chart: {
        height: 350, // Reduced height for a smaller chart
        width: 350, // Optionally set a width for the chart
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          size: 70, // Reduce the size of the radial bar
          dataLabels: {
            name: {
              fontSize: '14px', // Further reduced font size for the name
              formatter: function () {
                return undefined; // Custom formatter for name
              },
            },

          },
        },
      },
      stroke: {
        dashArray: 1, // Stroke dash array setting
      },
      labels: ['Apples', 'Oranges', 'Bananas', 'Berries'], // Labels for the radial bars
    },
  };
  const chartDataFemme = {
    series: [percentages.femalePercentage.toFixed(0)], // Fake data for the radial bars
    options: {
      chart: {
        height: 350, // Reduced height for a smaller chart
        width: 350, // Optionally set a width for the chart
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          size: 70, // Reduce the size of the radial bar
          dataLabels: {
            name: {
              fontSize: '14px', // Further reduced font size for the name
              formatter: function () {
                return undefined; // Custom formatter for name
              },
            },
          },
        },
      },
      colors: ['#FF4560'],
      stroke: {
        dashArray: 1, // Stroke dash array setting
      },
      labels: ['Apples', 'Oranges', 'Bananas', 'Berries'], // Labels for the radial bars
    },
  };
  return (
    <Card>
      <SoftBox p={1.75}>
        <SoftBox display="flex" alignItems="flex-end" justifyContent="space-between">
          <SoftAvatar src={image} alt={name} size="xl" variant="rounded" />
          <SoftBox ml={1} lineHeight={0} sx={{ overflow: "hidden" }}>
            <SoftBox mb={0.2} lineHeight={0}>
              <Tooltip title={name || ""}>
                <SoftTypography
                  variant="h6"
                  textTransform="capitalize"
                  fontWeight="medium"
                  sx={{
                    wordWrap: "break-word",
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                  }}
                >
                  {name}
                </SoftTypography>
              </Tooltip>
            </SoftBox>
            <SoftBox display="flex" flexDirection="column" justifyContent="center" lineHeight={0}>
              <SoftTypography
                variant="button"
                fontWeight="medium"
                color="secondary"
                fontSize="12px"
              >
                Enseignant
              </SoftTypography>
              {members.length > -1 ? (
                <SoftBox display="flex" sx={{ mr: 0.5 }}>
                  {renderMembers}
                </SoftBox>
              ) : null}
            </SoftBox>
          </SoftBox>

          <SoftBox
            sx={{
              ml: "auto",
              alignSelf: "flex-start",
              py: 1.25,
            }}
            display="flex"
            alignItems="center"  // Add this line
          >
            {start && end && (
              <SoftBox
                display="flex"
                flexDirection="column"
                lineHeight={1}
                ml={2}
                alignItems="flex-end"

              >
                <SoftTypography variant="button" fontWeight="bold">
                  {formattedStart}-{formattedEnd}
                </SoftTypography>
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color="secondary"
                  fontSize="12px"
                  sx={{
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                >
                  {facility.type === "faculty" ? "Année Scolaire" : "période"}
                </SoftTypography>
              </SoftBox>
            )}
            {dropdown && (
              <SoftTypography color="secondary" onClick={dropdown.action}>
                <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                  more_vert
                </Icon>
              </SoftTypography>
            )}
            {dropdown.menu}
          </SoftBox>
        </SoftBox>
      {/*   {facility.type === "faculty" ? ( */}
          <SoftBox lineHeight={1}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Box display="flex" alignItems="center" justifyContent="">
                <div style={{ textAlign: 'center' }}>
                  <h6 style={{fontSize:"10px",color:"#8392ab"}}>Homme</h6>
                  <ReactApexChart
                    options={chartDataHomme.options}
                    series={chartDataHomme.series}
                    type="radialBar"
                    height={100}
                    width={100}
                  /></div>
                <div style={{ textAlign: 'center' }}>
                  <h6 style={{fontSize:"10px",color:"#8392ab"}}>Femme</h6>
                  <ReactApexChart
                    options={chartDataFemme.options}
                    series={chartDataFemme.series}
                    type="radialBar"
                    height={100}
                    width={100}
                  /></div>
              </Box>
            </Box>
           {facility.type === "faculty" &&( <SoftTypography
              variant="button"
              fontWeight="medium"
              color="secondary"
              fontSize="13px"
              sx={{ textAlign: "center" }}
            >
              Niv :&nbsp; {level}
            </SoftTypography>)}
          </SoftBox>
        {/* ) : facility.type === "lycee" ? (
          <SoftBox my={2} lineHeight={1}>
            <SoftTypography
              variant="button"
              fontWeight="medium"
              color="secondary"
              fontSize="13px"
              sx={{ textAlign: "center" }}
            >
              Spé :&nbsp; {specialitie} &nbsp;Niv :&nbsp; {level}
            </SoftTypography>
          </SoftBox>
        ) : (
          null
          <SoftBox my={2} lineHeight={1}>
             <SoftTypography
               variant="button"
               fontWeight="medium"
               color="secondary"
               fontSize="13px"
               sx={{ textAlign: "center" }}
             >
               Niv :&nbsp; {level}
             </SoftTypography>
           </SoftBox> 
        )} */}

        <Divider />
        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
          {students > -1 ? (
            <SoftBox display="flex" flexDirection="column" lineHeight={0}>
              <SoftTypography variant="button" fontWeight="medium">
                {students}
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="medium" color="secondary">
                Etudiants
              </SoftTypography>
            </SoftBox>
          ) : null}
          {groupes && (
            <SoftBox display="flex" flexDirection="column" lineHeight={0}>
              <SoftTypography variant="button" fontWeight="medium">
                {groupes}
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="medium" color="secondary">
                Groupes
              </SoftTypography>
            </SoftBox>
          )}
          {capacite && (
            <SoftBox display="flex" flexDirection="column" lineHeight={0}>
              <SoftTypography variant="button" fontWeight="medium">
                {capacite}
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="medium" color="secondary">
                Capacité
              </SoftTypography>
            </SoftBox>
          )}
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={0}>
            <SoftTypography variant="button" fontWeight="medium"></SoftTypography>
            <EmailIcon sx={{ mr: 1, cursor: "pointer", color: "#7F47E6" }} />
            <SendIcon sx={{ cursor: "pointer", color: "#7F47E6" }} />
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ComplexProjectCard
ComplexProjectCard.defaultProps = {
  color: "dark",
  dateTime: "",
  members: [],
  dropdown: false,

};

// Typechecking props for the ProfileInfoCard
ComplexProjectCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  groupes: PropTypes.string,
  capacite: PropTypes.string,
  students: PropTypes.arrayOf(PropTypes.string),
  level: PropTypes.string,
  specialitie: PropTypes.string,
  description: PropTypes.node.isRequired,
  departementId: PropTypes.string,
  members: PropTypes.arrayOf(PropTypes.string),
  studentList: PropTypes.array.isRequired,
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default ComplexProjectCard;
