import React, { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import DataTable from "examples/Tables/DataTable";
import { CircularProgress, Slide, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "utils/axios";
import Header from "layouts/pages/profile/components/Header";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import { AppBar, Tab, Tabs } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, TextField, MenuItem, InputLabel, Dialog, DialogTitle, DialogContent } from "@mui/material";
import AddSubject from "./AddEvent";
import ComplexProjectCard from "./components/Card";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Menu from "@mui/material/Menu";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SoftPagination from 'components/SoftPagination'
function EventTable() {
  // TeamProfileCard dropdown menu handlers
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  //const totalPages = Math.ceil(data.length / itemsPerPage);
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  const [loading, setLoading] = useState(false);
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [data, setData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const myRef = useRef(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle
  const itemsPerPage = 8; // Nombre d'articles par page
  const totalPages = 3;
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("all");
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  
  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={() => navigate(`/users/students/view/${selectedStudentId}`)}>
        {" "}
        <RemoveRedEyeIcon /> &nbsp; Voir plus
      </MenuItem>
      <MenuItem onClick={() => navigate(`/users/students/${selectedStudentId}`)}>
        {" "}
        <EditIcon /> &nbsp; Modifier
      </MenuItem>
      <MenuItem //onClick={showAlert}
      >
        {" "}
        <DeleteIcon />
        &nbsp; Supprimer
      </MenuItem>
    </Menu>
  );
  // Fonction pour passer à la page précédente
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const facility = JSON.parse(localStorage.getItem("facility"));
  useEffect(() => {
    document.title = "Événement";
  }, []);

  const getLast6Characters = (code) => code?.slice(-6);

  const handleFetch = async () => {
    setLoading(true)
    try {
      const response = await api.get(`faculty/all-filiere/${facility.scholarityConfigId}`);
      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleFetch();
  }, []);

  const breadcrumbs = [
    detailsData?.departement && <Typography underline="hover" key="1" color="inherit" href="/">
      {detailsData?.departement?.name}
    </Typography>,
    <Typography key="3" color="text.primary">
      {detailsData?.name}
    </Typography>,
  ];
  const fetchPastEvents = async () => {
    try {
      const response = await axios.get('/api/events/past-events');
      console.log(response.data); // Faites quelque chose avec les données récupérées, comme les stocker dans un état pour les afficher dans votre interface utilisateur
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(true);
    }
  };

  const fetchUpcomingEvents = async () => {
    try {
      const response = await axios.get('/api/events/upcoming-events');
      console.log(response.data); // Faites quelque chose avec les données récupérées, comme les stocker dans un état pour les afficher dans votre interface utilisateur
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(true);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card  >
          <SoftBox
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Liste des événements
              </SoftTypography>
            </SoftBox>
            <AddSubject token={handleFetch} />
          </SoftBox>
          <Grid>
        <SoftBox m={2}>
          <Grid container>
            <Grid item xs={12} sm={12} lg={6}>
              <AppBar position="static">
                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                  <Tab id="tab-all" value="all" label="Événement à venir" />
                  <Tab id="tab-student" value="student" label="Événement passé" />                </Tabs>       
              </AppBar>
            </Grid>
            <Grid item xs={12} sm={3}></Grid>
             <Grid item xs={12} sm={3}>
                    <Autocomplete

                      options={[
                        { value: 'Arts et culture', label: 'Événement artistique ou culturel' },
                        { value: 'En ligne', label: 'Événement en ligne' },
                        { value: 'Sportifs', label: 'Événement sportif' },
                        { value: 'Club', label: 'Club' },
                        { value: 'Conférence', label: 'Conférence' },
                        { value: 'sortie', label: 'Sortie scolaire/d\'étude' },
                        { value: 'Réunion parental', label: 'Réunion parents' },
                        { value: 'Autre', label:   'Autre' },
                      ]}
                      defaultValue={null}
                      clearIcon={null}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          const newInputs = [...inputs];
                          newInputs[0].type = newValue.value;
                          setInputs(newInputs);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Tous les événements"
                        />
                      )}
                    />
          
                  </Grid>
              
          </Grid>
          <Grid marginLeft={172}></Grid>
        </SoftBox>
      </Grid>
          {loading ? (
            <SoftBox
              sx={{
                mt: 13,
                textAlign: "center",
              }}
            >
              <CircularProgress />
            </SoftBox>
          ) : (
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <ComplexProjectCard
                    dropdown={{
                      action: (e) => {
                       // openSlackBotMenu;
                        setSlackBotMenu(e.currentTarget);
                        //dateTime="16.01.22"
                      },
                        menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                      
                    }}
                  />
                </Grid> 
                </Grid>
            </div>
          )}
        </Card>
      </SoftBox>
      <SoftPagination>
        {currentPage > 1 && (
          <SoftPagination item onClick={prevPage}><ArrowBackIosIcon /></SoftPagination>
        )}
        {Array.from({ length: totalPages }, (_, index) => (
          <SoftPagination item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </SoftPagination >
        ))}
        {currentPage < totalPages && (
          <SoftPagination item onClick={nextPage}><ArrowForwardIosIcon /> </SoftPagination>
        )}
      </SoftPagination>
    </DashboardLayout>
  );
}

export default EventTable;
