import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import api from "utils/axios";
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSoftUIController } from "context";

const ModuleActions = ({ module, handleFetch, setShowDetails, setDetailsData }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState("");
  const [errorMessageCode, setErrorMessageCode] = useState("");
  const [errorMessageParcours, setErrorMessageParcours] = useState("");
  const [errorMessageLevel, setErrorMessageLevel] = useState("");
  const [errorMessageSousPeriode, setErrorMessageSousPeriode] = useState("");
  const [errorMessageFiliere, setErrorMessageFiliere] = useState("");
  const [controller] = useSoftUIController();
  const [optionsFilieres, setOptionsFilieres] = useState([]);
  const [optionsParcours, setOptionsParcours] = useState([]);
  const [optionsSousPeriode, setOptionsSousPeriode] = useState([]);
  const [optionsLevel,setOptionsLevel]=useState([]);
  const [parcour, setParcours] = useState(module?.parcour?._id || "");
  const [sousPeriode, setSousPeriode] = useState(module?.sousPeriode?._id || "");
  const [selectedFiliere, setSelectedFiliere] = useState(module?.parcour?.niveau?.filiere);
  const [selectedLevel, setSelectedLevel] = useState(module?.parcour?.niveau?._id);
  const [selectedParcours, setSelectedParcours] = useState(null);
  const [selectedSousPeriode, setSelectedSousPeriode] = useState();
  const [name, setName] = useState(module?.name || "");
  const [code, setCode] = useState(module?.code || "");
  const [open, setOpen] = useState(false);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [selectedParcour, setSelectedParcour] = useState(module?.parcour?._id);

  useEffect(() => {
    if (module) {
      setSelectedLevel(module?.parcour?.niveau?._id);
      setSelectedSousPeriode(module?.sousPeriode?._id);
      setSelectedParcours(module?.parcour?._id);
      setSelectedParcour(module?.parcour?._id);
    }
  }, [module]);
 
  useEffect(() => {
    api.get(`/faculty/all-filiere/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsFilieres(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
          niveaux: item.niveaux,
        }))
      );
    });
    if(selectedFiliere){
  api.get(`/faculty/get-levels-by-filiere/${selectedFiliere}`)
  .then((res) => {
    setOptionsLevel(
      res?.data?.map((item) => ({
        value: item._id,
        label: item.name,
      }))
    );
  }) .catch((error) => {
    if (error.response && error.response.status === 404) {
      setError("Resource not found:", error.response.data);
      // Handle 404 error gracefully (e.g., display a message to the user)
    } else {
      setError("API request failed:", error.message);
      // Handle other errors
    }
  });
    }
    if(selectedLevel){
        api.get(`/faculty/get-parcours-by-level/${selectedLevel}`)
        .then((res) => {        
          setOptionsParcours(
            res?.data?.map((item) => ({
              value: item._id,
              label: item.name,
            }))
          );
        }) .catch((error) => {
          if (error.response && error.response.status === 404) {
            setError("Resource not found:", error.response.data);
            // Handle 404 error gracefully (e.g., display a message to the user)
          } else {
            setError("API request failed:", error.message);
            // Handle other errors
          }
        });       
    }
    if(selectedParcour){
      api.get(`/faculty/get-sous-period-by-parcours/${selectedParcour}`).then((res) => {
         setOptionsSousPeriode(
           res.data.map((item) => ({
             value: item._id,
             label: item.name,         
           }))
         );
       }).catch((error) => {
         if (error.response && error.response.status === 404) {
           setError("Resource not found:", error.response.data);
           // Handle 404 error gracefully (e.g., display a message to the user)
         } else {
           setError("API request failed:", error.message);
           // Handle other errors
         }
       });
      }
  }, [selectedFiliere,selectedLevel,selectedParcour]);  

  const handleUpdate = async () => {
    try {
      setLoading(true);
        if (code.length < 2) {
          setErrorMessageCode("La longueur minimale requise est de 2 caractères.");
        } else if (name.length < 2) {
          setErrorMessage("La longueur minimale requise est de 2 caractères.");
        }else if (selectedFiliere !== null && selectedLevel === null) {
          setErrorMessageLevel("le niveau doit être sélectionné.");
        } else if (selectedFiliere !== null && selectedLevel !== null && selectedParcours === null) {
          setErrorMessageParcours("le parcours doit être sélectionné.");
        }  else if (selectedFiliere !== null && selectedLevel !== null &&
           selectedParcours !== null && selectedSousPeriode === null) {
            setErrorMessageSousPeriode("la sous periode doit être sélectionné.");
        }  else{
        await api.patch(`/faculty/update-module/${module._id}`, {code,name,parcour,sousPeriode});
        Swal.fire("Succès", "le module a été modifié avec succès.", "success").then(() => {
          setErrorMessageCode("");
          setErrorMessage("");
          setErrorMessageFiliere("")
          setErrorMessageLevel("")
          setErrorMessageParcours("")
          handleFetch();
          setOpen(false);
        });
     }
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessageCode("");
    setErrorMessage("");
    setErrorMessageFiliere("")
    setErrorMessageLevel("")
    setErrorMessageParcours("")
    setSelectedLevel(module?.level?.code);
    setSelectedSousPeriode(module?.sousPeriode?._id);
  };
  const handleDelete = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous voulez supprimer ce module ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(`/faculty/delete-module/${module._id}`);
            if (response) {
              (
                await Swal.fire(
                  "Suppression !",
                  "Le module a été supprimée avec succès.",
                  "success"
                )
              ).isConfirmed && handleFetch();
            }
          } catch (error) {
            Swal.fire("Erreur!", "Le module n'a pas été supprimée.", "error");
          }
        }
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Consulter" placement="left">
          <VisibilityIcon
            onClick={() => {
              setDetailsData(module);
              setShowDetails(true);
            }}
          />
        </Tooltip>
      </SoftTypography>
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Modifier" placement="top">
            <Icon onClick={() => setOpen(true)} id="edit-enterprise-page">
              edit
            </Icon>
          </Tooltip>
          <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={handleClose}
            onKeyDown={handleKeyPress}
          >
            <DialogTitle>Modifier Module</DialogTitle>
            <DialogContent>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.grey[200],
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContentText>
                Vous devez saisir le nouveau nom de votre module.
              </DialogContentText>            
                  <Grid  sx={{ mt: 1 }}>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "flex-start",
                      }}
                    >
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Code du module*
                        </SoftTypography>
                        <SoftInput
                          name="code"
                          margin="normal"
                          id="code"
                          label="module"
                          type="text"
                          fullWidth
                          placeholder="code du module"
                          inputProps={{
                            minLength: 2,
                            maxLength: 50,
                          }}
                          autoFocus
                          variant="standard"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          error={code?.length < 2}
                          success={code?.length >= 2}
                        />
                       {code?.length  < 2 && (
                          <Typography mt={0.75} variant="caption" color="error">
                            {errorMessageCode}
                          </Typography>
                        )} 
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Nom du module*
                        </SoftTypography>
                        <SoftInput
                          name="name"
                          margin="normal"
                          id="name"
                          label="module"
                          type="text"
                          fullWidth
                          placeholder="nom du module"
                          inputProps={{
                            minLength: 2,
                            maxLength: 50,
                          }}
                          variant="standard"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          error={name.length < 2}
                        success={name.length >= 2}
                        />
                         {name.length < 2 && (
                          <Typography mt={0.75} variant="caption" color="error">
                            {errorMessage}
                          </Typography>
                        )} 
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Filière*
                        </SoftTypography>
                        <Autocomplete
                          id="filiere"
                          options={optionsFilieres}
                          title="selectionner filière"
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          value={
                            optionsFilieres.find((option) => option.value === selectedFiliere) ||
                            null
                          }
                          onChange={(e, newValue) => {
                            if (newValue !== null && newValue !== undefined) {
                               setSelectedFiliere(newValue.value);
                               if (selectedFiliere!=="") {
                                setOptionsParcours([])
                               setSelectedParcours(null)
                               setSelectedLevel(null)
                               setSelectedParcour(null)
                               setOptionsSousPeriode([]); 
                               setSelectedSousPeriode(null)
                               setParcours("")
                                setOptionsLevel(newValue?.niveaux?.map((item) => ({
                                  value: item._id,
                                  label: item.name,
                                  parcours:item.parcours
                                })))}                            
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder="Toutes les filières"
                              readOnly
                            />
                          )}
                        />                    
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Niveau*
                        </SoftTypography>
                        <Autocomplete
                          id="niveau"
                          options={optionsLevel}
                          title="selectionner niveau"
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          value={optionsLevel.find((option) => option.value === selectedLevel)}
                          onChange={(e, newValue) => {
                            if (newValue !== null && newValue !== undefined) { 
                              setSelectedLevel(newValue.value);
                               if (selectedLevel!=="") {
                                setOptionsParcours([]);                       
                                setSelectedParcours(null);
                                setOptionsSousPeriode([]);                       
                                setSelectedParcour(null);
                                setParcours("")
                                setSelectedSousPeriode(null)
                              }   
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder="Toutes les niveaux"
                              readOnly
                            />
                          )}
                        />
                        {selectedFiliere !== null && selectedLevel === null && (
                          <Typography mt={0.75} variant="caption" color="error">
                            {errorMessageLevel}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Parcours*
                        </SoftTypography>
                        <Autocomplete
                          id="période"
                          options={optionsParcours}
                          title="période"
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          value={
                            optionsParcours.find((option) => option.value === selectedParcours) ||
                            null
                          }
                          onChange={(e, newValue) => {
                            if (newValue !== null && newValue !== undefined) {                                                     
                              setSelectedParcours(newValue.value)
                              setParcours(newValue.value); 
                              setSelectedParcour(newValue.value);
                              if(selectedParcour!==""){
                                setOptionsSousPeriode([]);                       
                                setSelectedSousPeriode(null)
                              }                             
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              value={module?.parcour?._id}
                              placeholder="Toutes les parcours"
                              readOnly
                            />
                          )}
                        />
                       {selectedFiliere !== null && selectedLevel !== null && selectedParcours === null && (
                          <Typography mt={0.75} variant="caption" color="error">
                            {errorMessageParcours}
                          </Typography>
                        )} 
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Sous Périodes*
                        </SoftTypography>
                        <Autocomplete
                          id="période"
                          options={optionsSousPeriode}
                          title="période"
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          value={
                            optionsSousPeriode.find(
                              (option) => option.value === selectedSousPeriode
                            )
                          }
                          onChange={(e, newValue) => {
                            if (newValue !== null && newValue !== undefined) {
                              setSousPeriode(newValue.value);
                              setSelectedSousPeriode(newValue.value);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              value={module?.sousPeriode?._id}
                              placeholder="Toutes les sous périodes"
                            />
                          )}
                          disableClearable={true}
                        />   
                         {selectedFiliere !== null && selectedLevel !== null && selectedParcours !== null && selectedSousPeriode === null&& (
                          <Typography mt={0.75} variant="caption" color="error">
                            {errorMessageSousPeriode}
                          </Typography>
                        )}              
                      </Grid>
                    
                      <Grid item xs={12} sm={6}></Grid>
                    </Grid>
                  </Grid>               
            </DialogContent>
            <DialogActions>
              <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                <SoftButton onClick={handleClose} sx={{ mt: 2 }} variant="outlined" color="dark" size="small">
                  Annuler
                </SoftButton>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <SoftButton
                    onClick={handleUpdate}
                    sx={{ mt: 2 }}
                    variant="gradient"
                    color="customized" size="small"
                  >
                    Modifier
                  </SoftButton>
                )}
              </SoftBox>
            </DialogActions>
          </Dialog>
        </SoftTypography>
      </SoftBox>
      <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
        {controller?.user?.info?.role === "company-admin" && (
          <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
            <Tooltip title="Supprimer" placement="left">
              <Icon id="delete-enterprise" onClick={handleDelete}>
                delete
              </Icon>
            </Tooltip>
          </SoftTypography>
        )}
      </SoftTypography>
    </SoftBox>
  );
};
ModuleActions.propTypes = {
  module: PropTypes.object.isRequired,
  setDetailsData: PropTypes.array.isRequired,
  setShowDetails: PropTypes.bool.isRequired,
  handleFetch: PropTypes.func.isRequired,
};
export default ModuleActions;
