import { useTheme } from "@emotion/react";
import { Box, Button, Card, Checkbox, FormControlLabel, Grid, Typography, CircularProgress } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftEditor from "components/SoftEditor";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Field, Form, Formik } from "formik";
import DashboardNavbar from "layouts/DashboardNavbar";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { grey } from "material-ui-core/colors";
import SoftSelect from "components/SoftSelect";
import SelectData from "pages/demandes/data/SelectData";
import { useEffect, useState } from "react";
import validationSchema from "./validerChamps/validationSchema";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

import { DateRange } from "@mui/icons-material";
import Image from "./components/Image";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import api from "utils/axios";
import TextEditor from "./components/TextEditor";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import moment, { max, min } from "moment";

function AjouterEvent() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();

  const [image, setImage] = useState("");
  const [nom, setNom] = useState("");
  const [categorie, setCategorie] = useState("");
  const [dateDebut, setDateDebut] = useState("");
  const [heureDebut, setHeureDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const [heureFin, setHeureFin] = useState("");
  //pour le lieu
  const [lieu, setLieu] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lieuPrecis, setLieuPrecis] = useState("");
  const [capacite, setCapacite] = useState(0);
  const [lien, setLien] = useState("");
  const [coOrganisateurs, setcoOrganisateurs] = useState([]);
  const [description, setDescription] = useState("");
  const [estGratuit, setEstGratuit] = useState(true);
  const [prix, setPrix] = useState(0);
  const [recurrenceEvent, setRecurrenceEvent] = useState("Jamais");
  const [datesRecurrenceEvent, setDatesRecurrenceEvent] = useState([]);

  const [showEndDateAndTime, setShowEndDateAndTime] = useState(false);
  const handleToggleEndDateAndTime = () => {
    setShowEndDateAndTime(!showEndDateAndTime);
  };


    const [heureDebutSysteme, setHeureDebutSysteme] = useState("")
    useEffect(() => {
      if (dateDebut !== "") {
        if (dateDebut === moment().format("YYYY-MM-DD")) {
          // Set the minimum heureDebut to the current time
          setHeureDebutSysteme(moment().format("HH:mm"));
        } else {
          // Set the minimum heureDebut to "00:00"
          setHeureDebutSysteme("00:00");
        }
      }
    }, [dateDebut]);

  const initialValues = {
    nom: "",
    categorie: "",
    dateDebut: "",
    heureDebut: "",
    dateFin: "",
    heureFin: "",
    lieu: "",
    lieuPrecis: "",
    capacite: undefined,
    lien: "",
    description: "",
    prixParticipant: undefined,
    datesSelectionner: "",
  };
  const [datesys, setDatesys] = useState("");

  useEffect(() => {
    // Fonction pour obtenir la date système au format "YYYY-MM-DD"
    const getDateSysteme = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    // Mettre à jour la valeur de dateDebut avec la date système
    setDatesys(getDateSysteme());
  }, []); // Le tableau vide [] signifie que cette fonction s'exécutera une seule fois après le rendu initial

  //pour le payment
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setEstGratuit(!estGratuit);
  };

  const [fullNamesStudent, setFullNamesStudent] = useState([]);
  const getFullNamesStudent = () => {
    api.get("get-user-commercial?role=student").then((response) => {
      setFullNamesStudent(
        response.data.map((student) => student.firstName + " " + student.lastName)
      );
    });
  };

  useEffect(() => {
    getFullNamesStudent();
  }, []);

  const [demande, setDemande] = useState({});
  const handleDemandeById = (id) => {
    api.get(`get-demande/${id}`).then((res) => {
      setDemande(res.data);
    });
  };

  useEffect(() => {
    if (id) {
      handleDemandeById(id);
    }
  }, [id]);

  const createEvent = () => {
    if (
      nom?.length >= 2 &&
      categorie !== "" &&
      dateDebut !== "" &&
      heureDebut != "" &&
      lieu !== "" &&

      capacite >0 &&
      description !== "" &&
      JSON.parse(description).blocks[0].text.trim() !== "" &&
      ((lieu === "En présentiel" && lieuPrecis.length >= 3) ||
        (lieu === "En ligne" && lien.length >= 10))
    ) {
      if (recurrenceEvent === "Personnalisé" && datesRecurrenceEvent.length === 0) {
        Swal.fire({
          title: "Warning",
          text: "Ajouter Les Dates De Réccurence Évènement.",
          icon: "warning",
          confirmButtonText: "ok",
        });
      } else {
        let fd = new FormData();
        if (image === "") {
          fd.append("image", "event.jpg");
        } else {
          fd.append("image", image);
        }
        fd.append("nom", nom);
        fd.append("categorie", categorie);
        fd.append("dateDebut", dateDebut);
        fd.append("heureDebut", heureDebut);
        if (showEndDateAndTime) {
          fd.append("dateFin", dateFin);
          fd.append("heureFin", heureFin);
        }
        fd.append("lieu", lieu);
        if (lieu === "En présentiel") {
          fd.append("lieuPrecis", lieuPrecis);

        }
        if (lieu === "En ligne") {
          fd.append("lien", lien);
        }
        fd.append("capacite", capacite);
        if (coOrganisateurs?.length > 0) {
          fd.append("coOrganisateurs", coOrganisateurs);
        }

        fd.append("description", description);
        fd.append("estGratuit", estGratuit);
        if (estGratuit === false) {
          fd.append("prix", prix);
        }
        fd.append("recurrenceEvent", recurrenceEvent);
        if (recurrenceEvent === "Personnalisé") {
          fd.append("datesRecurrenceEvent", datesRecurrenceEvent);
        }
        if (demande != {}) {
          fd.append("descriptionDemande", demande.description);
        }
        if (demande.description !== undefined) {
          fd.append("statut", "A faire");
        } else {
          fd.append("statut", "Terminer");
        }
        if (demande.description === undefined) {
          fd.append("evaluation", "Valider");
        }
        fd.append("nbParticipant", 0);
        if(prix <= 0 && estGratuit === false){
          Swal.fire({
            title: "Warning",
            text: "Veuillez ajouter une prix positive",
            icon: "warning",
            confirmButtonText: "ok",
          });
          return;
        }
        api.post("create-event", fd).then((res) => {
          navigate("/events/kanbanEvents");
          Swal.fire({
            title: "Succès",
            text: "Ajouter évènement réussie",
            icon: "success",
            confirmButtonText: "ok",
          });
        });
      }
    } else {
      if(capacite < 0){
        Swal.fire({
          title: "Warning",
          text: "Veuillez ajouter une capacite positive",
          icon: "warning",
          confirmButtonText: "ok",
        });
      }else{
        Swal.fire({
          title: "Warning",
          text: "Veuillez ajouter tous les champs obligatoires",
          icon: "warning",
          confirmButtonText: "ok",
        });
      } 
    }
  };

  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ errors, touched, handleSubmit, handleChange, handleBlur }) => (
          <Form onSubmit={handleSubmit} style={{ marginTop: "8px" }}>
            <Box display="flex" alignItems="center" mt="16px">
              <Typography variant="h5" sx={{ flexGrow: "1" }} >
                Ajouter événement
              </Typography>
            </Box>
            <Card
              sx={{
                border: `1px solid ${grey[200]}`,
                bgcolor: "white",
                borderRadius: "15px",
                p: "20px",
                mt: "16px",
                overflow: "visible",
              }}
            >
              <Image setImage={setImage} />
              <Grid container spacing={2} sx={{ position: "relative" }}>
                {id !== undefined && (
                  <Grid item xs={12}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Description demande
                    </SoftTypography>

                    <SoftInput
                      disabled
                      multiline
                      rows={3}
                      variant="standard"
                      placeholder="enter le nom de l'événement"
                      success={demande.description?.length >= 10}
                      value={demande.description}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Nom de l&apos;événement*
                  </SoftTypography>
                  <Field name="nom">
                    {({ field }) => (
                      <SoftInput
                        {...field}
                        variant="standard"
                        placeholder="enter le nom de l'événement"
                        error={!!errors.nom && !!touched.nom}
                        success={field.value?.length >= 2 && !!touched.nom}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          setNom(event.target.value);
                        }}
                      />
                    )}
                  </Field>
                  {!!errors.nom && !!touched.nom && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "12px" }}
                      color="error"
                    >
                      {errors.nom}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} sx={{ zIndex: 2, position: "relative" }}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Catégorie de l&apos;événement*
                  </SoftTypography>
                  <Field name="categorie">
                    {({ field }) => (
                      <SoftSelect
                        options={SelectData.categorie}
                        placeholder="Type d'événement"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setCategorie(event.value);
                        }}
                      />
                    )}
                  </Field>
                  {categorie === "" && !!touched.categorie && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "12px" }}
                      color="error"
                    >
                      {errors.categorie}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Date de Début*
                  </SoftTypography>

                  <Field name="dateDebut">
                    {({ field }) => (
                      <SoftInput
                        {...field}
                        variant="outlined"
                        type="date"
                       
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          setDateDebut(event.target.value);
                        }}
                        inputProps={{
                          min: moment(new Date()).format('YYYY-MM-DD'),
                        }}
                      />
                    )}
                  </Field>
                  {!!errors.dateDebut && !!touched.dateDebut && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "12px" }}
                      color="error"
                    >
                      {errors.dateDebut}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Heure de Début*
                  </SoftTypography>
                  <Field name="heureDebut">
                    {({ field }) => (
                      <SoftInput
                        {...field}
                        variant="outlined"
                        type="time"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          setHeureDebut(event.target.value);
                        }}
                        inputProps={{
                          min: heureDebutSysteme, // Set minimum time based on selected dateDebut
                        }}
                      />
                    )}
                  </Field>
                  {!!errors.heureDebut && !!touched.heureDebut && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "12px" }}
                      color="error"
                    >
                      {errors.heureDebut}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Button onClick={handleToggleEndDateAndTime}>+ Date et heure de fin</Button>
                  {/* Affichage conditionnel des champs de date et heure de fin */}
                </Grid>
                {showEndDateAndTime && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Date de Fin*
                      </SoftTypography>
                      <Field name="dateFin">
                        {({ field }) => (
                          <SoftInput
                            {...field}
                            variant="outlined"
                            type="date"
                          
                            onBlur={handleBlur}
                            onChange={(event) => {
                              handleChange(event);
                              setDateFin(event.target.value);
                            }}
                            inputProps={{
                              min: dateDebut
                            }}
                          />
                        )}
                      </Field>
                      {!!errors.dateFin && !!touched.dateFin && (
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "400", fontSize: "12px" }}
                          color="error"
                        >
                          {errors.dateFin}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Heure de Fin*
                      </SoftTypography>
                      <Field name="heureFin">
                        {({ field }) => (
                          <SoftInput
                            {...field}
                            variant="outlined"
                            type="time"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              handleChange(event);
                              setHeureFin(event.target.value);
                            }}
                          />
                        )}
                      </Field>
                      {!!errors.heureFin && !!touched.heureFin && (
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "400", fontSize: "12px" }}
                          color="error"
                        >
                          {errors.heureFin}
                        </Typography>
                      )}
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6} mt={2} sx={{ zIndex: 2, position: "relative" }}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Lieu de l&apos;événement*
                  </SoftTypography>

                  <Field name="lieu">
                    {({ field }) => (
                      <SoftSelect
                        options={SelectData.lieuEvent}
                        placeholder="Est-ce En présentiel ou en ligne ?"
                        value={SelectData.lieuEvent.find((lieu) => lieu.value === lieu)}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setLieu(event.value);
                        }}
                      />
                    )}
                  </Field>
                  {lieu === "" && !!touched.lieu && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "12px" }}
                      color="error"
                    >
                      {errors.lieu}
                    </Typography>
                  )}
                </Grid>
                {lieu === "En présentiel" && (
                  <Grid item xs={12} sm={6} mt={2}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Lieu précis de l&apos;événement*
                    </SoftTypography>
                    <Field name="lieuPrecis">
                      {({ field }) => (
                        <SoftInput
                          {...field}
                          variant="outlined"
                          placeholder="Lieu précis"
                          error={!!errors.lieuPrecis && !!touched.lieuPrecis}
                          success={!errors.lieuPrecis && !!touched.lieuPrecis}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            handleChange(event);
                            setLieuPrecis(event.target.value);
                          }}
                        />
                      )}
                    </Field>
                    {!!errors.lieuPrecis && !!touched.lieuPrecis && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "400", fontSize: "12px" }}
                        color="error"
                      >
                        {errors.lieuPrecis}
                      </Typography>
                    )}
                  </Grid>
                )}
                {lieu === "En ligne" && (
                  <Grid item xs={12} sm={6} mt={2}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Lien de l&apos;événement*
                    </SoftTypography>
                    <Field name="lien">
                      {({ field }) => (
                        <SoftInput
                          {...field}
                          variant="outlined"
                          placeholder="lien"
                          error={!!errors.lien && !!touched.lien}
                          success={!errors.lien && !!touched.lien}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            handleChange(event);
                            setLien(event.target.value);
                          }}
                        />
                      )}
                    </Field>
                    {!!errors.lien && !!touched.lien && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "400", fontSize: "12px" }}
                        color="error"
                      >
                        {errors.lien}
                      </Typography>
                    )}
                  </Grid>
                )}
                <Grid item xs={12} sm={6} mt={2}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Capaciter des personnes dans l&apos;événement*
                  </SoftTypography>
                  <Field name="capacite">
                    {({ field }) => (
                      <SoftInput
                        {...field}
                        type="number"
                        variant="outlined"
                        placeholder="capacite"
                        error={!!errors.capacite && !!touched.capacite}
                        success={!errors.capacite && !!touched.capacite}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          setCapacite(event.target.value);
                        }}
                      />
                    )}
                  </Field>
                  {!!errors.capacite && !!touched.capacite && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "12px" }}
                      color="error"
                    >
                      {errors.capacite}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} sx={{ zIndex: 1, position: "relative" }}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Ajouter des organisateurs
                  </SoftTypography>
                  <SoftSelect
                    options={fullNamesStudent.map((fullName) => ({
                      value: fullName,
                      label: fullName,
                    }))}
                    placeholder="Choisir les coorganisateurs"
                    size="medium"
                    isMulti
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map((option) => option.value);
                      setcoOrganisateurs(selectedValues);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    description de l&apos;événement*
                  </SoftTypography>

                  <Field name="description">
                    {({ field }) => (
                      <TextEditor
                        {...field}
                        value={description}
                        onChange={(event) => {
                          setDescription(event);
                        }}
                        readOnly={false}
                      />
                    )}
                  </Field>
                  {(description === "" || JSON.parse(description).blocks[0].text.trim() === "") &&
                    !!touched.description && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "400", fontSize: "12px" }}
                        color="error"
                      >
                        {errors.description}
                      </Typography>
                    )}
                </Grid>
                <Grid item xs={6} display="flex">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5, mr: { xs: "100px", md: "200px" } }}
                  >
                    Événement :
                  </SoftTypography>
                  <FormControlLabel
                    control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
                    label="Payant"
                  />
                </Grid>
                {isChecked && (
                  <Grid item xs={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Prix participation :
                    </SoftTypography>
                    <Field name="prixParticipant">
                      {({ field }) => (
                        <SoftInput
                          {...field}
                          variant="standard"
                          placeholder="Prix de la participation"
                          type="number"
                          // error={!!errors.prixParticipant && !!touched.prixParticipant}
                          // success={field.value?.length >= 1 && !!touched.prixParticipant}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            handleChange(event);
                            setPrix(event.target.value);
                          }}
                        />
                      )}
                    </Field>
                    {prix === 0 && !!touched.prixParticipant && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "400", fontSize: "12px" }}
                        color="error"
                      >
                        {errors.prixParticipant}
                      </Typography>
                    )}
                  </Grid>
                )}
                {!isChecked && <Grid item xs={6}></Grid>}
                <Grid item xs={12} sm={recurrenceEvent === "Jamais" ? 12 : 6}>
                  <SoftTypography variant="caption" color="text">
                    Récurrence de l&apos;événement
                  </SoftTypography>
                  <SoftSelect
                    options={SelectData.recurenceEvent}
                    defaultValue={SelectData.recurenceEvent.find(
                      (recur) => recur.value === recurrenceEvent
                    )}
                    onChange={(event) => {
                      setRecurrenceEvent(event.value);
                    }}
                  />
                </Grid>
                {recurrenceEvent === "Personnalisé" && (
                  <Grid item xs={12} sm={6} sx={{ display: "block" }}>
                    <Box ml={{ sx: "0px", md: "8px", position: "relative" }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Dates sélectionnées
                      </SoftTypography>

                      <Field name="datesSelectionner">
                        {({ field }) => (
                          <Box sx={{ position: "relative", width: "100%" }}>
                            <DatePicker
                              placeholder="jj / mm / aaaa --:--"
                              multiple
                              format="DD/MM/YYYY HH:mm:ss"
                              plugins={[
                                <TimePicker key="timePicker" position="bottom" />,
                                <DatePanel key="datePannel"/>,
                              ]}
                              style={{
                                width: "100%",
                                position: "absolute",
                                right: "0px",
                                left: "0px",
                                top: "0px",
                                padding: "20px 40px 20px 12px",
                                cursor: "pointer",
                                borderRadius: "10px",
                              }}
                              onChange={(selectedOptions) => {
                                setDatesRecurrenceEvent(
                                  selectedOptions.map((option) => option.format())
                                );
                              }}
                              minDate={moment(dateDebut, "YYYY-MM-DD").format("DD/MM/YYYY")}
                            />
                            <Box
                              sx={{
                                position: "absolute",
                                top: "70%",
                                right: "12px",
                                transform: "translateY(-50%)",
                              }}
                            >
                              <DateRange
                                sx={{ width: "16px ! important", height: "16px !important" }}
                              />
                            </Box>
                          </Box>
                        )}
                      </Field>
                      {datesRecurrenceEvent == "" && !!touched.datesSelectionner && (
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "400", fontSize: "12px", mt: "10px" }}
                          color="error"
                        >
                          {errors.datesSelectionner}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                )}
              </Grid>
              <SoftBox p={2}>

                
                  <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                    <SoftButton id="btn-cancel" onClick={() => window.history.back()} variant="outlined" color="dark">
                      Annuler
                    </SoftButton>

                    {
                      isLoading ? <CircularProgress /> : (

                        <SoftButton id="btn-add" type="submit" variant="gradient" color="customized" onClick={() => {
                          createEvent();
                        }}>

                          Ajouter
                        </SoftButton>)
                    }

                  </SoftBox>
                </SoftBox>
              
            </Card>
            
              

            
          </Form>
        )}
      </Formik>
    </DashboardLayout>
  );
}

export default AjouterEvent;
