// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";


import { Grid } from "@mui/material";

function ProfileInfoCard({ title, description, info }) {
  const labels = [];
  const values = [];

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <SoftBox key={label} display="flex" py={1} pr={2}>
      <Grid item xs={12}  md={12}  lg={4}>
        <SoftTypography id="btn-label-profile" variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: 
      </SoftTypography>
      </Grid>
      <Grid  item xs={12}  md={12}  lg={8}>
      <SoftTypography id="btn-value-profile" variant="button" fontWeight="regular" color="text">
        {values[key]}
      </SoftTypography>
      </Grid>
    </SoftBox>
  ));

  return (
    <>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={2}>
        <SoftTypography id="title-profile" variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SoftTypography>
     
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox mb={2} lineHeight={1}>
          <SoftTypography id="desc-profile" variant="button" color="text" fontWeight="regular">
            {description}
          </SoftTypography>
        </SoftBox>
        <SoftBox>
          {renderItems}
         
        </SoftBox>
      </SoftBox>
    </>
  );
}
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  social: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProfileInfoCard;
