import checkout from "pages/resource-management/subject/schema/form";

const {
  formField: {
    name,
    code,
    filiere,
    niveau,
    parcour,
    module,
    coefficient,
    credit,
    categorie,
    checkedCours,
    checkedTd,
    checkedTp,
    checkedMp,
    vhcours,
    durationcours,
    vhtd,
    durationtd,
    vhtp,
    durationtp,
    vhmp,
    durationmp,
    bloccours,
    sallecours,
    bloctd,
    salletd,
    bloctp,
    salletp,
    blocmp,
    sallemp,
    pourcentage,
    checkedPcExamen,
    checkedPcDs1,
    checkedPcDs2,
    checkedPcCc,
    checkedPcTd,
    checkedPcTp,
    checkedPcMp,
    pcexamen,
    pctd,
    pctp,
    pcmp,
    pcds1,
    pcds2,
    pccc,
    instructorCours,
    instructorTd,
    instructorTp,
    instructorMp,
    idCours,
    idTd,
    idTp,
    idMp,
  },
} = checkout;

const initialValues = {
  [code.name]: "",
  [name.name]: "",
  [filiere.name]: "",
  [niveau.name]: "",
  [parcour.name]: "",
  [module.name]: "",
  [coefficient.name]: "",
  [credit.name]: "",
  [categorie.name]: "",
  [checkedCours.name]: true,
  [checkedTd.name]: false,
  [checkedTp.name]: false,
  [checkedMp.name]: false,
  [vhcours.name]: 1,
  [durationcours.name]: 1,
  [vhtd.name]: 1,
  [durationtd.name]: 1,
  [vhtp.name]: 1,
  [durationtp.name]: 1,
  [vhmp.name]: 1,
  [durationmp.name]: 1,
  [bloccours.name]: "h",
  [sallecours.name]: "h",
  [bloctd.name]: "",
  [salletd.name]: "",
  [bloctp.name]: "",
  [salletp.name]: "",
  [blocmp.name]: "",
  [sallemp.name]: "",
  [pourcentage.name]: 0,
  [checkedPcExamen.name]: true,
  [checkedPcDs1.name]: true,
  [checkedPcDs2.name]: true,
  [checkedPcCc.name]: true,
  [checkedPcTd.name]: false,
  [checkedPcTp.name]: false,
  [checkedPcMp.name]: false,
  [pcexamen.name]: null,
  [pctd.name]: null,
  [pctp.name]: null,
  [pcmp.name]: null,
  [pcds1.name]: null,
  [pcds2.name]: null,
  [pccc.name]: null,
  [idCours.name]: null,
  [idTd.name]: null,
  [idTp.name]: null,
  [idMp.name]: null,
  [instructorCours.name]: [],
  [instructorTd.name]: [],
  [instructorTp.name]: [],
  [instructorMp.name]: [],
};

export default initialValues;
