import { Card, CircularProgress, Dialog, DialogTitle, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import SoftBox from 'components/SoftBox';
import React, { useEffect, useState } from 'react'
import StudentCard from '../StudentCard';
import PropTypes from "prop-types";
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import api from 'utils/axios';
import StudentInfo from 'pages/user/students/components/update-student-components/StudentInfo';
import Swal from 'sweetalert2';
import StudentView from './StudentView';
import CloseIcon from "@mui/icons-material/Close";

export default function Student({ client }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  // TeamProfileCard dropdown menu handlers
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const facilityStorage = localStorage.getItem("facility");
  const facility = facilityStorage && JSON.parse(facilityStorage);

  const handleClose = () => {
    setOpen(false);
    closeSlackBotMenu()
  };
  const handleCloseView = () => {
    setOpenView(false);
    closeSlackBotMenu()

  };
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler"
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(`/facilities/${facility._id}/users/${selectedStudent?._id}`);
            if (response) {
              (await Swal.fire("Supprimé !", "L'étudiant a été supprimé.", "success"))
                .isConfirmed && handleFetch();
              closeSlackBotMenu()
            }
          } catch (error) {
            Swal.fire("Une erreur s'est produite !", "L'étudiant n'a pas été supprimé.", "error");
            closeSlackBotMenu()
          }
        }
      });
  };
  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={() => setOpenView(true)}>
        <RemoveRedEyeIcon />   &nbsp; Voir plus</MenuItem>
      <MenuItem onClick={() => {
        setOpen(true);
      }}> <EditIcon />  &nbsp; Modifier</MenuItem>
      <MenuItem
        onClick={showAlert}
      > <DeleteIcon />&nbsp;   Supprimer</MenuItem>
    </Menu>
  );
  const handleFetch = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/get-students-by-responsable-id/${client?._id}`);
      setData(response?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };
  useEffect(() => {
    // change page title in the header
    document.title = "student";
    handleFetch();
  }, []);
  // Filter data based on the searchQuery
  const filteredData = data?.filter((student) => {
    const fullName = `${student.firstName} ${student.lastName}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });

  return (
    <Card id="edit-student" >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={8}>
          <SoftBox p={3}>
            <SoftTypography variant="h5">Listes des etudiants</SoftTypography>
          </SoftBox>
        </Grid >
        <Grid item xs={12} md={6} lg={4}>
          <SoftBox p={3}>
            <SoftBox ml="auto">
              <SoftInput
                size="large"
                icon={{ component: "search", direction: "left" }}
                placeholder="Rechercher..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
      {loading ? (
        <SoftBox
          sx={{
            mt: 13,
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </SoftBox>
      ) : (
        <SoftBox pb={2}>
          <SoftBox mt={{ xs: 1, lg: 2 }} m={1}>
            {filteredData?.length > 0 ? (<Grid container spacing={3}>
              {filteredData &&
                filteredData?.map((student) => (
                  <Grid key={client?._id} item xs={12} md={6} lg={6}>
                    <StudentCard
                      image={student?.imageUrl?.startsWith("https")
                        ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                        : `${process.env.REACT_APP_BASE_URL}/${student?.imageUrl}`}
                      title={`${student.firstName} ${student.lastName}`}
                      phone={student.phoneNumber}
                      email={student.email}
                      description=""
                      etat={student?.etat}
                      student={student}
                      setOpen={setOpen}
                      dropdown={{
                        action: (e) => {
                          setSlackBotMenu(e.currentTarget);
                          setSelectedStudent(student);
                        },
                        menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                      }}
                    />
                    <Dialog fullWidth maxWidth="lg" open={openView} onClose={handleCloseView}>
                      <DialogTitle >informations de profil</DialogTitle>
                      <IconButton
                        aria-label="close"
                        onClick={handleCloseView}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                          "&:hover": {
                            backgroundColor: (theme) => theme.palette.grey[200],
                          },
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <StudentView user={selectedStudent} />
                    </Dialog>
                    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
                      <StudentInfo user={selectedStudent} />
                    </Dialog>
                  </Grid>
                ))}
            </Grid>) : (<SoftBox
              sx={{
                mt: 10,
                mb: 10,
                textAlign: "center",
              }}
            >
              Aucun étudiant
            </SoftBox>)}
          </SoftBox>
        </SoftBox>
      )}
    </Card>
  )
}
Student.propTypes = {
  client: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};