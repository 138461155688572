import { Grid } from '@mui/material'
import SoftBox from 'components/SoftBox'

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'layouts/DashboardNavbar'
import React from 'react'
import ListChat from './componenets/ListChat'
import Chat from './componenets/Chat'

const ChatCard = () => {
  return (
    <DashboardLayout>
    <DashboardNavbar />
   
    <SoftBox my={4} >
    <Grid container spacing={2}>
          <Grid item xs={12} lg={3.5}>
            <ListChat />
          </Grid>
          <Grid item xs={12} lg={8.5}>
            <Chat />
          </Grid>
          
        </Grid>
    </SoftBox>
  </DashboardLayout>
  )
}

export default ChatCard