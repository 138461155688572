import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import Card from "./components/Card";
import api from "utils/axios";
import PropTypes from "prop-types";
import moment from "moment";
import SoftSelect from "components/SoftSelect";
import { IconButton } from "material-ui-core";
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import { useSoftUIController } from "context";


function KanbanTâches() {
  const { projectID } = useParams();
  const navigate = useNavigate();
  const [taches, setTaches] = useState([]);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const token = localStorage.getItem("accessToken");

  const listeTaches = async () => {
    try {
      let endpoint;
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/get-tache-ByProjet-centre-formation/${projectID}`;
      } else {
        endpoint = `faculty/get-tache-ByProjet-faculty/${projectID}`;
      }
  
      const response = await api.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTaches(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };
  useEffect(() => {
    listeTaches();
  }, [projectID]);

  const [nameTache, setNameTache] = useState([]);
  const ListeProjet = async () => {
    try {
      let endpoint;
      // Determine the endpoint URL based on facility type
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/get-projects-centre-formation/${facility.scholarityConfigId}`;
      } else {
        endpoint = `faculty/get-projects-faculty/${facility.scholarityConfigId}`;
      }
  
      const response = await api.get(endpoint);
      const projects = response.data;
      const projectOptions = projects.map(project => ({
        value: project._id,
        label: project.nom,
      }));
      setNameTache(projectOptions);
    } catch (error) {
      console.error('Error fetching project names:', error);
    }
  };
  useEffect(() => {
    ListeProjet();
  }, []);

  const handleProjectChange = selectedOption => {
    navigate(`/suiviTache/kanbanTaches/${selectedOption.value}`);
  };

  const [responsables, setResponsables] = useState([]);
  const getAvatarResponsables = () => {
    api.get(`faculty/get-users-admin-editor/${facility._id}`).then((response) => {
      setResponsables(response.data);
    });
  };

  useEffect(() => {
    getAvatarResponsables();
  }, []);

  const cards = (stat) => {
    if (!Array.isArray(taches)) {
      return [];
    }

    const threeMonthsAgo = moment().subtract(3, "months").toDate();
    const cardsUtiliser = taches.filter((tache) => {
        if (tache.statut !== "Terminer") {
          return tache.statut === stat;
        } else {
          const dateLimite = moment(tache.dateLimite, "DD/MM/YYYY HH:mm:ss").toDate();
          const isWithin3Months = moment(dateLimite).isAfter(threeMonthsAgo);
          return isWithin3Months && tache.statut === stat;
        }
      })
      .map((tache) => ({
        id: tache._id,
        template: (
          <Card
            évaluation={{
              color:
                tache.evaluation === "Aucune"
                  ? "secondary"
                  : tache.evaluation === "Négative"
                    ? "error"
                    : "success",
              label: tache.evaluation,
            }}
            content={tache.nom}
            date={tache.dateLimite}
            priorité={{
              color:
                tache.priorite === "Haute"
                  ? "error"
                  : tache.priorite === "Moyenne"
                    ? "warning"
                    : "#30F2F2",
              label: tache.priorite,
            }}
            responsable={
              responsables
                .find((res) => res.email === tache.responsable)
                ?.imageUrl.startsWith("https")
                ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                : `${process.env.REACT_APP_BASE_URL}/${responsables.find((res) => res.email === tache.responsable)?.imageUrl
                }`
            }
          />
        ),
      }));
    return cardsUtiliser;
  };

  const status = {
    columns: [
      {
        id: "0",
        title: "A faire",
        cards: cards("A faire"),
      },
      {
        id: "1",
        title: "En cours",
        cards: cards("En cours"),
      },
      {
        id: "2",
        title: "Terminer",
        cards: cards("Terminer"),
      },
    ],
  };

  const [columns, setColumns] = useState(status.columns);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    let newStatus = "";
    switch (destination.droppableId) {
      case "0":
        newStatus = "A faire";
        break;
      case "1":
        newStatus = "En cours";
        break;
      case "2":
        newStatus = "Terminer";
        break;
    }

    const draggedTask = taches.find((task) => task._id === result.draggableId);

    if (!draggedTask) {
      return;
    }

    const updatedTaches = taches.map((task) =>
      task._id === draggedTask._id ? { ...task, statut: newStatus } : task
    );
    setTaches(updatedTaches);

    updateTaskStatus(draggedTask._id, newStatus);
  };

  const updateTaskStatus = (taskId, newStatus) => {
    const updatedTask = { statut: newStatus };
    let endpoint;
    // Determine the endpoint URL based on facility type
    if (facility?.type === "centreDeFormation") {
      endpoint = `training-company/update-tache-center-formation/${taskId}`;
    } else {
      endpoint = `faculty/update-tache-faculty/${taskId}`;
    }

    api
      .put(endpoint, updatedTask, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        console.error("Error updating task status:", error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      
                           <TurnLeftIcon     fontSize="large" color="purple" 
                             onClick={() => {
                               navigate("/suiviTache/ListeProjet");
                             }}
                           />
                          
                           
                          
                        
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        p={3}
      >
       <SoftTypography variant="h5" fontWeight="medium">
        Kanban Tache {nameTache.find(project => project.value === projectID)?.label}
       </SoftTypography>
       
        <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
          <SoftSelect
            placeholder="Choissiser un Projet"
            options={nameTache}
            size="medium"
            onChange={handleProjectChange}
          />
          <SoftButton
            variant="gradient"
            color="purple"
            size="small"
            onClick={() => navigate(`/suiviTache/listeTaches/${projectID}`)}
          >
            Liste Des Tache
          </SoftButton>
          <SoftButton
            variant="gradient"
            color="purple"
            size="small"
            onClick={() => navigate(`/suiviTache/kanbanTaches/ajouterTache/${projectID}`)}
          >
            Ajouter une tâche
          </SoftButton>
        </Stack>
      </Box>

      <Box>
        <SoftBox my={2}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container my={4}>
              {status.columns.map((column) => (
                <Grid
                  item
                  key={column.id}
                  sx={{
                    backgroundColor: "#EDF0F3",
                    flex: "1 1",
                    maxWidth: { md: "calc(50% - 20px)" },
                    minWidth: "330px",
                    margin: "10px",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <SoftTypography variant="h6">{column.title}</SoftTypography>
                  <Droppable droppableId={column.id}>
                    {(provided) => (
                      <Box ref={provided.innerRef} {...provided.droppableProps}>
                        {column.cards.map((card, index) => (
                          <Draggable key={card.id} draggableId={card.id} index={index}>
                            {(provided) => (
                              <Box
                                onClick={() => {
                                  navigate(`/suiviTache/listeTaches/editerTache/${card.id}`);
                                }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <SoftBox
                                  key={card.id}
                                  bgColor="white"
                                  color="text"
                                  borderRadius="10px"
                                  mt="20px"
                                  py="14px"
                                  px="14px"
                                  lineHeight="12px"
                                >
                                  {card.template}
                                </SoftBox>
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Grid>
              ))}
            </Grid>
          </DragDropContext>
        </SoftBox>
      </Box>
    </DashboardLayout>
  );
}

KanbanTâches.propTypes = {
  id: PropTypes.string.isRequired,
};

export default KanbanTâches;
