const form = {
    formId: "user",
    formField: {
      firstName: {
        name: "firstName",
        label: "Nom*",
        type: "text",
        placeholder: "Nom",
        errorMsg: "Nom est obligatoire.",
      },
      lastName: {
        name: "lastName",
        label: "Prénom*",
        type: "text",
        placeholder: "Prénom",
        errorMsg: "Prenom est obligatoire.",
      },
  
      email: {
        name: "email",
        label: "Email*",
        type: "email",
        placeholder: "Email",
        errorMsg: "Email est obligatoire .",
      },
      address: {
        name: "address",
        label: "Adresse",
        type: "text",
        placeholder: "Adresse",
        errorMsg: "Adresse est obligatoire.",
      },
      zipCode: {
        name: "zipCode",
        label: "Code Postal",
        type: "number",
        placeholder: "Code postal",
        errorMsg: "Code postal est obligatoire.",
      },
  
      siteWeb: {
        name: "siteWeb",
        label: "site web",
        type: "text",
        placeholder: "Site web",
      },
      passeport: {
        name: "passeport",
        label: "passeport",
        type: "text",
        placeholder: "Passeport",
        errorMsg: "Passeport est obligatoire.",
      },
      cin: {
        name: "cin",
        label: "Cin",
        type: "number",
        placeholder: "Cin",
        errorMsg: "Cin est obligatoire.",
      },
      rib: {
        name: "rib",
        label: "Rib",
        type: "number",
        placeholder: "Rib",
        errorMsg: "Rib est obligatoire.",
      },
      bank: {
        name: "bank",
        label: "nom de banque",
        type: "text",
        placeholder: "Nom de banque",
        errorMsg: "Nom banque est obligatoire.",
      },
      description: {
        name: "description",
        label: "Description",
        type: "text",
        placeholder: "Description",
        errorMsg: "Desription est obligatoire.",
      },
      phone: {
        name: "phone",
        label: "Telephone",
        type: "text",
        placeholder: "Phone",
        errorMsg: "Telephone est obligatoire.",
      },
      gender: {
        name: "gender",
        label: "Genre",
        type: "text",
        placeholder: "Genre",
        errorMsg: "Genre est obligatoire.",
      },
      cnssNumber: {
        name: "cnssNumber",
        label: "Numéro CNSS",
        type: "number",
        placeholder: "Numero CNSS",
        errorMsg: "Numéro cnss est obligatoire.",
      },
      occupation: {
        name: "occupation",
        label: "Occupation",
        type: "text",
        placeholder: "Occupation",
      },
      choix: {
        name: "choix",
        label: "Choix",
        type: "text",
        placeholder: "Choix",
      },
      responsable: {
        name: "responsable",
        label: "Responsable",
        type: "text",
        placeholder: "responsable",
      },
      
    },
  };
  
  export default form;
  