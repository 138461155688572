// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBadge from "components/SoftBadge";
import SoftTypography from "components/SoftTypography";
import { Box, CardContent, CardMedia } from "@mui/material";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import moment from "moment";
import SoftButton from "components/SoftButton";

function Card({ evaluation, responsable, nomRespo, id, image, nomEvent, categorie, date }) {
  const theme = useTheme();
  //action edit et suppression tâche
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  // Check if evaluation exists and has the expected structure
  const evaluationColor = evaluation?.color || "secondary";
  const evaluationLabel = evaluation?.label || "Neutral";

  return (
    <Box sx={{ display: "flex", flexDirection: "row"}}>
      <img src={`${process.env.REACT_APP_BASE_URL}/${image}`} alt="image Event" style={{ width:"100px" , height:"100px" , marginRight : "16px" }} />
      <Box display="block" alignItems="center" justifyContent="space-between">
        <SoftBadge size="xs" color={evaluationColor} badgeContent={evaluationLabel} container />
        <SoftTypography variant="body2" sx={{ color: theme.palette.text.primary }}>
          Nom : {nomEvent}
        </SoftTypography>
        <SoftTypography variant="body2" color="text" fontSize="14px">
          Categorie : {categorie}
        </SoftTypography>
        <SoftTypography variant="caption" color="black">
          Date Event : {date}
        </SoftTypography>
      </Box>
    </Box>
  );
}

// Typechecking props for the Card
Card.propTypes = {
  responsable: PropTypes.string,
  nomRespo: PropTypes.string,
  id: PropTypes.string,
  evaluation: PropTypes.shape({
    color: PropTypes.oneOf(["secondary", "success", "error"]).isRequired,
    label: PropTypes.oneOf(["Valider", "Non valider"]).isRequired,
  }),
  image: PropTypes.string,
  nomEvent: PropTypes.string,
  categorie: PropTypes.string,
  date: PropTypes.string,
};

export default Card;
