// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadgeDot from "components/SoftBadgeDot";
import PieChart from "examples/Charts/PieChart";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";


function PieCharte({ PieCharteData , title , sousTitle , sousNb , sousSousTitle , sousSousNb}) {
  return (
    <Card sx={{ overflow: "visible" }}>
        <SoftBox p={3}>
        <SoftTypography
        fontWeight="medium"
        fontSize="16px"
        color="black"
        >
            {title}
        </SoftTypography>
        <Grid container alignItems="center" pt="16px">
          <Grid item xs={6} height="225px">
            <PieChart chart={PieCharteData} height="100%" />
          </Grid>
          <Grid item xs={6} sx={{mt : "0px"}}>
            <SoftBox px={1}>
              {
                PieCharteData.labels.map((label, index) => (
                  <Box key={index} mb={0.5} sx={{ whiteSpace: 'nowrap' }} >
                    <SoftBadgeDot color={PieCharteData.datasets.backgroundColors[index]} size="sm" badgeContent={label} />
                  </Box>
                ))
              }
            </SoftBox>
          </Grid>
        </Grid>
        {
          sousTitle !== undefined &&
            <Typography variant="h6" fontSize="16px">Nombre total de {sousTitle} : {sousNb}</Typography>
        }
        {
          sousSousTitle !== undefined &&
          <Typography variant="h6" fontSize="16px">Nombre total de {sousSousTitle} : {sousSousNb}</Typography>
        }
      </SoftBox>

        {/* 
            <SoftBox width={{ xs: "100%", sm: "40%" }} textAlign="right" mt={{ xs: 2, sm: "auto" }}>
            <SoftButton color="light">read more</SoftButton>
            </SoftBox>
        */}
    </Card>
  );
}

PieCharte.propTypes = {
  title: PropTypes.string,
  PieCharteData : PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.shape({
      backgroundColors: PropTypes.arrayOf(PropTypes.string),
      data: PropTypes.arrayOf(PropTypes.number),
    }),
  }),
  sousTitle: PropTypes.string,
  sousNb: PropTypes.number,
  sousSousTitle: PropTypes.string,
  sousSousNb: PropTypes.number,
}


export default PieCharte;
