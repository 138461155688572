import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import api from "utils/axios";
import SoftButton from "components/SoftButton";
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import SoftBox from "components/SoftBox";

const AddModule = ({ handleFetch }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState([
    {
      code: "",
      name: "",
      filiere: "",
      niveau: "",
      parcour: "",
      sousPeriode: "",
    },
  ]);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState("");
  const [errorMessageCode, setErrorMessageCode] = useState("");
  const [errorMessageParcours, setErrorMessageParcours] = useState("");
  const [errorMessageLevel, setErrorMessageLevel] = useState("");
  const [optionsFilieres, setOptionsFilieres] = useState([]);
  const [optionsLevel, setOptionsLevel] = useState([]);
  const [optionsParcours, setOptionsParcours] = useState([]);
  const [optionsSousPeriode, setOptionsSousPeriode] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState();
  const [selectedSemester, setSelectedSemester] = useState();
  const [selectedParcour, setSelectedParcour] = useState();
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [errorFiliere, setErrorFiliere] = useState("");
  const [errorCoef, setErrorCoef] = useState("");
  const [errorMessageSousPeriode, setErrorMessageSousPeriode] = useState("");

  useEffect(() => {
    api.get(`/faculty/all-filiere/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsFilieres(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
          niveaux: item.niveaux,
        }))
      );
    });
    if (selectedParcour) {
      api
        .get(`/faculty/get-sous-period-by-parcours/${selectedParcour}`)
        .then((res) => {
          setOptionsSousPeriode(
            res.data.map((item) => ({
              value: item._id,
              label: item.name,
            }))
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setError("Resource not found:", error.response.data);
            // Handle 404 error gracefully (e.g., display a message to the user)
          } else {
            setError("API request failed:", error.message);
            // Handle other errors
          }
        });
    }
  }, [selectedParcour]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setErrorMessageCode("");
    setInputs([{ code: "", name: "", filiere: "", niveau: "", parcour: "", sousPeriode: "" }]);
    setErrorFiliere("");
    setErrorCoef("");
    setErrorMessageParcours("");
    setErrorMessageSousPeriode("");
    setErrorMessageLevel("");
  };
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[0][name] = value;
    setInputs(newInputs);
  };
  const handleAddModule = async () => {
    try {
      setLoading(true);
      const hasInvalidInputs = inputs.some((input) => input.name.length < 2);
      const hasInvalidInputsCode = inputs.some((input) => input.code.length < 2);
      const hasFiliere = inputs.some((input) => !input.filiere);
      const hasLevel = inputs.some((input) => !input.niveau);
      const hasParcours = inputs.some((input) => !input.parcour);
      const hasSousPeriode = inputs.some((input) => !input.sousPeriode);
      if (hasInvalidInputsCode) {
        setErrorMessageCode("La longueur minimale requise est de 2 caractères.");
      } else if (hasInvalidInputs) {
        setErrorMessage("La longueur minimale requise est de 2 caractères.");
      } else if (hasFiliere) {
        setErrorFiliere("La filière doit être sélectionné.");
      } else if (hasLevel) {
        setErrorMessageLevel("Le niveau doit être sélectionné.");
      } else if (hasParcours) {
        setErrorMessageParcours("le parcours doit être sélectionné.");
      } else if (hasSousPeriode) {
        setErrorMessageSousPeriode("La sous-période doit être sélectionnée.");
      } else {
        await api.post(
          `/faculty/create-module?scholarityConfigId=${facility.scholarityConfigId}`,
          inputs
        );
        setOpen(true);
        Swal.fire("Succès", "Le module a été ajouté avec succès.", "success").then(() => {
          setErrorMessage("");
          setErrorMessageCode("");
          setInputs([
            { code: "", name: "", filiere: "", niveau: "", parcour: "", sousPeriode: "" },
          ]);
          handleFetch();
          setOpen(false);
          setErrorFiliere("");
          setErrorCoef("");
          setErrorMessageParcours("");
          setErrorMessageSousPeriode("");
          setErrorMessageLevel("");
        });
      }
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddModule();
    }
  };
  return (
    <div>
      <SoftButton
        id="add"
        onClick={handleClickOpen}
        variant="gradient"
        color="purple"
        size="small"
        mb={{ xs: 2, sm: 0 }}
      >
        +Ajouter module
      </SoftButton>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose} onKeyDown={handleKeyPress}>
        <DialogTitle>Ajouter Module</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            Vous devez saisir le nom de votre module afin de l&apos;ajouter.
          </DialogContentText>

          {inputs.map((input, index) => (
            <>
              <Grid key={index} sx={{ mt: 1 }}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "flex-start",
                  }}
                >
                  <Grid item xs={12} sm={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Code du module*
                    </SoftTypography>
                    <SoftInput
                      name="code"
                      margin="normal"
                      id="code"
                      label="module"
                      type="text"
                      fullWidth
                      placeholder="code du module"
                      inputProps={{
                        minLength: 2,
                        maxLength: 50,
                      }}
                      autoFocus
                      variant="standard"
                      value={input.code}
                      onChange={(event) => handleInputChange(index, event)}
                      onBlur={() => {
                        // Update the error when the input loses focus
                        const newInputs = [...inputs];
                        newInputs[index].error = input?.code.length < 2;
                        setInputs(newInputs);
                      }}
                      error={input.error && input?.code.length < 2}
                      success={input?.code.length >= 2}
                    />
                    {input?.code?.length < 2 && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorMessageCode}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Nom du module*
                    </SoftTypography>
                    <SoftInput
                      name="name"
                      margin="normal"
                      id="name"
                      label="module"
                      type="text"
                      fullWidth
                      placeholder="nom du module"
                      inputProps={{
                        minLength: 2,
                        maxLength: 50,
                      }}
                      variant="standard"
                      value={input.name}
                      onChange={(event) => handleInputChange(index, event)}
                      onBlur={() => {
                        // Update the error when the input loses focus
                        const newInputs = [...inputs];
                        newInputs[index].error = input.name.length < 2;
                        setInputs(newInputs);
                      }}
                      error={input.error && input.name.length < 2}
                      success={input.name.length >= 2}
                    />
                    {input.name.length < 2 && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorMessage}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Filière*
                    </SoftTypography>
                    <Autocomplete
                      options={optionsFilieres}
                      title="selectionner filière"
                      defaultValue={null}
                      clearIcon={null}
                      getOptionLabel={(option) => option.label}
                      value={optionsFilieres.find((option) => option.value === inputs[0].filiere)}
                      onChange={(e, newValue) => {
                        if (newValue !== null && newValue !== undefined) {
                          const newInputs = [...inputs];
                          newInputs[0].filiere = newValue.value;                       
                          setSelectedParcour("");
                          setOptionsLevel(
                            newValue?.niveaux?.map((item) => ({
                              value: item._id,
                              label: item.name,
                              parcours: item.parcours,
                            }))
                          );
                          setInputs(newInputs);
                          setSelectedLevel(newValue.value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          value={inputs[0].filiere}
                          placeholder="Toutes les filières"
                        />
                      )}
                    />
                    {input.filiere === "" && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorFiliere}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Niveau*
                    </SoftTypography>
                    <Autocomplete
                      options={optionsLevel}
                      title="selectionner niveau"
                      defaultValue={null}
                      clearIcon={null}
                      getOptionLabel={(option) => option.label}
                      value={optionsLevel.find((option) => option.value === inputs[0].niveau)}
                      onChange={(e, newValue) => {
                        if (newValue !== null && newValue !== undefined) {
                          const newInputs = [...inputs];
                          newInputs[0].niveau = newValue.value;
                          newInputs[0].sousPeriode = "";
                          setOptionsSousPeriode([]);
                          setOptionsParcours(
                            newValue?.parcours?.map((item) => ({
                              value: item._id,
                              label: item.name,
                            }))
                          );                     
                          setInputs(newInputs);
                          setSelectedLevel(newValue.value);
                          setSelectedParcour("");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          //value={inputs[0].niveau}
                          placeholder="Toutes les niveaux"
                        />
                      )}
                    />
                    {input.niveau === "" && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorMessageLevel}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Parcours*
                    </SoftTypography>
                    <Autocomplete
                      options={optionsParcours}
                      defaultValue={null}
                      clearIcon={null}
                      getOptionLabel={(option) => option.label}
                      value={
                        optionsParcours.find((option) => option.value === inputs[0].parcour) || null
                      }
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          const newInputs = [...inputs];
                          newInputs[0].parcour = newValue.value;
                          setSelectedParcour(newValue.value);                        
                          setInputs(newInputs);
                          setSelectedSemester(newValue.value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          value={inputs[0].parcour}
                          placeholder="Toutes les parcours"
                        />
                      )}
                    />
                    {input.parcour === "" && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorMessageParcours}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Sous Périodes*
                    </SoftTypography>
                    <Autocomplete
                      options={optionsSousPeriode}
                      defaultValue={null}
                      clearIcon={null}
                      getOptionLabel={(option) => option.label}
                      value={
                        optionsSousPeriode.find(
                          (option) => option.value === inputs[0].sousPeriode
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          const newInputs = [...inputs];
                          newInputs[0].sousPeriode = newValue.value;
                          setInputs(newInputs);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          value={inputs[0].sousPeriode}
                          placeholder="Toutes les sous périodes"
                        />
                      )}
                    />
                    {input.sousPeriode === "" && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorMessageSousPeriode}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                </Grid>
              </Grid>
            </>
          ))}
        </DialogContent>

        <DialogActions>
          <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
            <SoftButton
              onClick={handleClose}
              sx={{ mt: 2 }}
              variant="outlined"
              color="dark"
              size="small"
            >
              Annuler
            </SoftButton>
            {loading ? (
              <CircularProgress />
            ) : (
              <SoftButton
                onClick={handleAddModule}
                sx={{ mt: 2 }}
                variant="gradient"
                color="customized"
                size="small"
              >
                Ajouter
              </SoftButton>
            )}
          </SoftBox>
        </DialogActions>
      </Dialog>
    </div>
  );
};
AddModule.propTypes = {
  handleFetch: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
export default AddModule;
