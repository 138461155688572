import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Card, Dialog, DialogContent, Grid, IconButton } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftAvatar from 'components/SoftAvatar';
import SoftTypography from 'components/SoftTypography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SoftPagination from 'components/SoftPagination';
import PropTypes from 'prop-types';
import ArticleIcon from '@mui/icons-material/Article';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import api from 'utils/axios';
import moment from 'moment';
import UpdateCours from './UpdateCours';
import { useSoftUIController } from 'context';
import axios from 'axios';
import CloseIcon from "@mui/icons-material/Close";
import {Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import FileViewer from 'react-file-viewer';

export default function Cours({ data, handleFetch }) {
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCours, setSelectedCours] = useState({});
    const [open, setOpen] = useState(false);
    const [fileModalOpen, setFileModalOpen] = useState(false);
    const [fileContent, setFileContent] = useState('');
    const itemsPerPage = 8;
    const [controller] = useSoftUIController();
    const user = controller?.user?.info;
    const facility = JSON.parse(localStorage.getItem("facility"));

    const getIconByFileType = (fileName) => {
        const fileExtension = fileName.split('.').pop().toLowerCase();
        if (fileExtension === 'pdf') {
            return <PictureAsPdfIcon fontSize="large" />;
        } else if (fileExtension === 'mp4') {
            return <VideoLibraryIcon fontSize="large" />;
        } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
            return <PermMediaIcon fontSize="large" />;
        } else if (fileExtension === 'pptx') {
            return <SlideshowIcon fontSize="large" />; // Added for PPTX icon
        } else {
            return <ArticleIcon fontSize="large" />;
        }
    };

    const downloadFile = async (file) => {
        try {
            const fileExtension = file.split('.').pop().toLowerCase();

            switch (fileExtension) {
                case 'pdf':
                    setFileContent({ url: file, type: 'pdf' });
                    setFileModalOpen(true);
                    break;
                case 'mp4':
                    setFileContent({ url: file, type: 'video' });
                    setFileModalOpen(true);
                    break;
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'gif':
                    setFileContent({ url: file, type: 'image' });
                    setFileModalOpen(true);
                    break;
                case 'xlsx':
                    setFileContent({ url: file, type: 'xlsx' });
                    setFileModalOpen(true);
                    break;
                case 'csv':
                    setFileContent({ url: file, type: 'csv'});
                    setFileModalOpen(true);
                    break;
                case 'docx':
                    setFileContent({ url: file, type: 'docx'});
                    setFileModalOpen(true);
                    break;
                    case 'doc':
                    setFileContent({ url: file, type: 'doc'});
                    setFileModalOpen(true);
                    break;
                case 'pptx':
                    setFileContent({ url: file, type: 'pptx'});
                    setFileModalOpen(true);
                    break;
                default:
                    const link = document.createElement("a");
                    link.href = file;
                    link.target = "_blank";
                    link.download = file;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    break;
            }
        } catch (error) {
            console.error("Error downloading file:", error);
            Swal.fire("Erreur!", "Le fichier n'a pas pu être téléchargé.", "error");
        }
    };
    
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFileModalClose = () => {
        setFileModalOpen(false);
        setFileContent('');
    };

    const deleteCours = async (id, event) => {
        event.stopPropagation();
        const newSwal = Swal.mixin({
            customClass: {
                confirmButton: "button button-success",
                cancelButton: "button button-error",
            },
            buttonsStyling: false,
        });
        newSwal
            .fire({
                title: "Êtes-vous sûr(e) ?",
                text: "Vous voulez supprimer ce cours ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Oui, supprimer!",
                cancelButtonText: "Annuler",
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let endpoint;
                        if (facility?.type === "centreDeFormation") {
                          endpoint = `training-company/delete-lesson/${id}`;
                        } else {
                          endpoint = `/faculty/lesson/delete-lesson/${id}`;
                        }  
                        const response = await api.delete(endpoint);
                        if (response) {
                            (await Swal.fire("Suppression !", "Le cours a été supprimée.", "success"))
                                .isConfirmed && handleFetch();
                        }
                    } catch (error) {
                        Swal.fire("Erreur!", "Le cours n'a pas été supprimée.", "error");
                    }
                }
            });
    };

    const deleteFile = async (id, filesToDelete) => {
        const newSwal = Swal.mixin({
            customClass: {
                confirmButton: "button button-success",
                cancelButton: "button button-error",
            },
            buttonsStyling: false,
        });
        newSwal
            .fire({
                title: "Êtes-vous sûr(e) ?",
                text: "Vous voulez supprimer ce fichier ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Oui, supprimer!",
                cancelButtonText: "Annuler",
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const response = await api.delete(`training-company/delete-files-from-lesson/${id}?filesToDelete[]=${filesToDelete}`);
                        if (response) {
                            (await Swal.fire("Suppression !", "Le fichier a été supprimée.", "success"))
                                .isConfirmed && handleFetch();
                        }
                    } catch (error) {
                        Swal.fire("Erreur!", "Le fichier n'a pas été supprimée.", "error");
                    }
                }
            });
    };

    const totalPages = Math.ceil(data?.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = data?.slice(startIndex, endIndex);
    const hasEditDeletePermission =
        user?.role === 'company-admin' ||
        user?.role === 'admin' ||
        user?.role === 'editor';
    return (
        <SoftBox mt={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                    {currentData && currentData.map((cours, index) => (
                        <Accordion
                            key={cours._id}
                            expanded={openAccordionIndex === index}
                            onChange={() => setOpenAccordionIndex(openAccordionIndex === index ? null : index)}
                            style={{ borderRadius: '12px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', marginBottom: '10px' }}
                        >
                            <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                                <Grid container spacing={1}>
                                    <Grid container spacing={1} item xs={12} md={12} lg={4} textAlign={{ xs: 'center', md: 'left' }}>
                                        <Grid item xs={12} md={4} container justifyContent="center" alignItems="center" textAlign="center">
                                            <SoftAvatar
                                                src={"https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"}
                                                variant="rounded"
                                                size="xl"
                                                shadow="sm"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <SoftBox height="100%" lineHeight={1}>
                                                <SoftTypography variant="h6" fontWeight="medium">
                                                    {cours.title}
                                                </SoftTypography>
                                                <SoftTypography variant="button" color="text" fontWeight="medium">
                                                    {cours.instructorId?.firstName} {cours.instructorId?.lastName}
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6}  textAlign={{ xs: 'center', md: 'left' }}>
                                        {openAccordionIndex !== index && (
                                            <SoftTypography fontWeight="400" color="secondary" fontSize="12px">
                                                {cours?.description?.substring(0, 50)}
                                            </SoftTypography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={2} display="flex" flexDirection="row"
                                        alignItems="flex-start" justifyContent={{ xs: 'center', md: 'flex-end' }}>
                                        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                                            <SoftBox display="flex" flexDirection="column" lineHeight={0}>
                                                {hasEditDeletePermission && (
                                                    <SoftBox display="flex" flexDirection="row" justifyContent="flex-end" lineHeight={0} style={{ marginBottom: '8px' }}>
                                                        <EditIcon color="purple" fontSize='medium' style={{ marginRight: "8px" }}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                handleClickOpen(cours); // Pass the entire cours object here
                                                                setSelectedCours(cours);
                                                            }}
                                                        />
                                                        <DeleteIcon color="error" fontSize='medium'
                                                            onClick={(event) => deleteCours(cours?._id, event)}
                                                        />
                                                    </SoftBox>
                                                )}
                                                <SoftTypography variant="button"
                                                    fontWeight="medium"
                                                    color="dark"
                                                    fontSize="10px">
                                                    Ajouter le {moment(cours.createdAt).format("DD/MM/YYYY HH:mm")}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography fontWeight="400" color="dark" fontSize="14px"  textAlign={{ xs: 'center', md: 'left' }}>
                                    {cours.description}
                                </Typography>
                                {cours?.studyMaterials?.map((file, index) => (
                                    <Card key={index} style={{
                                        padding: "15px", marginTop: "10px",
                                        boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.1)'
                                    }}>
                                        <Grid container spacing={1} item xs={12} md={12} lg={12} textAlign={{ xs: 'center', md: 'left' }}>
                                            <Grid onClick={() => downloadFile(file.name ? file.name : file)} style={{ cursor: 'pointer' }}
                                                container item xs={12} md={11}>
                                                <Grid item xs={12} md={1}>
                                                    {getIconByFileType(file.name ? file.name : file)}
                                                </Grid>
                                                <Grid item xs={12} md={10}>
                                                    <SoftBox height="100%" lineHeight={1}>
                                                        <SoftTypography variant="h6" fontWeight="medium">
                                                            {file}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                            {hasEditDeletePermission && (
                                                <Grid item xs={12} md={1}>
                                                    <SoftBox height="100%" lineHeight={1} mt={1}
                                                        textAlign={{ xs: 'center', md: 'right' }}>
                                                        <DeleteIcon color="error" fontSize="medium" style={{ cursor: 'pointer' }}
                                                            onClick={() => deleteFile(cours?._id, file)} />
                                                    </SoftBox>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Card>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card height="100%">
                        <SoftBox p={1.5}>
                            <SoftTypography variant="button" fontWeight="medium" color="dark" fontSize="18px">
                                A venir
                            </SoftTypography>
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={12} lg={6}>
                                    <SoftTypography variant="button" fontWeight="medium" color="dark" fontSize="14px">
                                        Cours Physique
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={6} md={12} lg={6}>
                                    <SoftTypography variant="button" fontWeight="medium" color="dark" fontSize="12px">
                                        17-11-2023 à 14:00H
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={12} lg={6}>
                                    <SoftTypography variant="button" fontWeight="medium" color="dark" fontSize="14px">
                                        Cours Algorithmique
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={6} md={12} lg={6}>
                                    <SoftTypography variant="button" fontWeight="medium" color="dark" fontSize="12px">
                                        17-11-2023 à 14:00H
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            {totalPages > 1 && (
                <SoftPagination>
                    {currentPage > 1 && (
                        <SoftPagination item onClick={prevPage}><ArrowBackIosIcon /></SoftPagination>
                    )}
                    {Array.from({ length: totalPages }, (_, index) => (
                        <SoftPagination item
                            key={index}
                            active={index + 1 === currentPage}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </SoftPagination>
                    ))}
                    {currentPage < totalPages && (
                        <SoftPagination item onClick={nextPage}><ArrowForwardIosIcon /></SoftPagination>
                    )}
                </SoftPagination>
            )}
            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
                <UpdateCours onClose={handleClose} selectedCours={selectedCours} handleFetch={handleFetch} />
            </Dialog>

            <Dialog open={fileModalOpen} onClose={handleFileModalClose} fullScreen>
                <IconButton
                    aria-label="close"
                    onClick={handleFileModalClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        "&:hover": {
                            backgroundColor: (theme) => theme.palette.grey[200],
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Dialog open={fileModalOpen} onClose={handleFileModalClose} fullScreen>
                    <IconButton
                        aria-label="close"
                        onClick={handleFileModalClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.grey[200],
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent style={{ textAlign: 'center', padding: '40px 20px' }}>
                        {fileContent && fileContent.type === 'video' && (
                            <div style={{ width: '100%', height: '100%', position: 'relative', marginTop: '20px', overflow: 'hidden', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <video src={`${process.env.REACT_APP_BASE_URL}/${fileContent.url}`} controls controlsList="nodownload" style={{ width: '100%', height: '100%', maxWidth: '100%', maxHeight: 'calc(100vh - 100px)', border: 'none', borderRadius: '10px', objectFit: 'contain' }} />
                            </div>
                        )}
                        {fileContent && fileContent.type === 'image' && (
                            <div style={{ width: '100%', height: '100%', position: 'relative', marginTop: '20px', overflow: 'hidden', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <img src={`${process.env.REACT_APP_BASE_URL}/${fileContent.url}`} alt="File Preview" style={{ width: '100%', height: '100%', maxWidth: '100%', maxHeight: 'calc(100vh - 100px)', border: 'none', borderRadius: '10px', objectFit: 'contain' }} />
                            </div>
                        )}
                        {fileContent && fileContent.type === 'pdf' && (
                            <div style={{ width: '100%', height: '100%', position: 'relative', marginTop: '20px', overflow: 'hidden', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                                    <Viewer fileUrl={`${process.env.REACT_APP_BASE_URL}/${fileContent.url}`} />
                                </Worker>
                               {/*  <FileViewer
                                    fileType={fileContent.type}
                                    filePath={fileContent.url}
                                /> */}

                            </div>
                        )}
                        {fileContent && fileContent.type === 'docx' && (
                            <div style={{ height: '100%', position: 'relative', marginTop: '20px', overflow: 'hidden', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <FileViewer
                                    fileType={fileContent.type}
                                    filePath={`${process.env.REACT_APP_BASE_URL}/${fileContent.url}`}
                                />
                            </div>
                        )}
                           {fileContent && fileContent.type === 'doc' && (
                            <div style={{ height: '100%', position: 'relative', marginTop: '20px', overflow: 'hidden', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <FileViewer
                                    fileType={fileContent.type}
                                    filePath={`${process.env.REACT_APP_BASE_URL}/${fileContent.url}`}
                                />
                            </div>
                        )}
                        {fileContent && fileContent.type === 'xlsx' && (
                            <div style={{ width: '100%', height: '100%', position: 'relative', marginTop: '20px', overflow: 'hidden', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <FileViewer
                                    fileType={fileContent.type}
                                    filePath={`${process.env.REACT_APP_BASE_URL}/${fileContent.url}`}
                                />
                            </div>
                        )}
                        {fileContent && fileContent.type === 'csv' && (
                            <div style={{ width: '100%', height: '100%', position: 'relative', marginTop: '20px', overflow: 'hidden', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <FileViewer
                                    fileType={fileContent.type}
                                    filePath={`${process.env.REACT_APP_BASE_URL}/${fileContent.url}`}
                                />
                            </div>
                        )}

                    </DialogContent>
                </Dialog>

            </Dialog>

        </SoftBox>
    );
}

Cours.propTypes = {
    data: PropTypes.array.isRequired,
    handleFetch: PropTypes.func.isRequired
};
