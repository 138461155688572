import { Card, Grid } from '@mui/material'
import SoftBox from 'components/SoftBox'
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SoftButton from 'components/SoftButton'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import { Form, Formik } from 'formik'
import DashboardNavbar from 'layouts/DashboardNavbar'
import Header from 'layouts/pages/profile/components/Header'
import React, { useState } from 'react'
import { useSoftUIController } from 'context'

import ConfigurationExamen from './components/ConfigurationExamen';
import InfoExamen from './components/InfoExamen';
import validations from '../shared/schemas/validations';
import form from '../shared/schemas/form';
import initialValues from '../shared/schemas/initialValues';


function getSteps() {
    return ["Configuration", "Examen"];
}
function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
        case 0:
            return <ConfigurationExamen formData={formData} />;
        case 1:
            return <InfoExamen formData={formData} />;
        default:
            return null;
    }
}

export default function AddExamen() {
    const [isLoading, setIsLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState(null);
    const [controller, dispatch] = useSoftUIController();
    const { facilityInfo } = controller;
    const steps = getSteps();
    const { formId, formField } = form;
    const currentValidation = validations[activeStep];
    const isLastStep = activeStep === steps.length - 1;

    const handleBack = () => setActiveStep(activeStep - 1);

    //add-Examen
    const submitForm = async (values, actions) => {

        actions.setSubmitting(false);
        actions.resetForm();

        setActiveStep(1);
    };

    const handleSubmit = (values, actions) => {
        if (isLastStep) {
            submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Header title={"Ajouter Examen"} />
            <SoftBox py={3} mb={20}>
                <Grid container justifyContent="center" sx={{ height: "100%" }}>
                    <Grid item xs={12} lg={12}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, isSubmitting }) => (
                                <Form
                                    id={formId}
                                    autoComplete="off">
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={2}>
                                            <SoftBox>
                                                {getStepContent(activeStep, {
                                                    values,
                                                    touched,
                                                    formField,
                                                    errors,
                                                })}
                                                <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                                                    {activeStep === 0 ? (
                                                        <SoftBox />
                                                    ) : (
                                                        <SoftButton id="btn-back-client" variant="outlined" color="dark" onClick={handleBack}>
                                                            Annuler
                                                        </SoftButton>
                                                    )}
                                                    {/* {
                          isLoading ? <CircularProgress /> : ( */}
                                                    <SoftButton
                                                        type="submit"
                                                        variant="gradient"
                                                        color="customized"
                                                        id="send"
                                                    >
                                                        {isLastStep ? "Ajouter" : "Suivant"}
                                                    </SoftButton>
                                                    {/*  )} */}
                                                </SoftBox>

                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </SoftBox>
        </DashboardLayout>
    )
}
