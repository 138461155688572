import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  titre: Yup.string()
    .required("Entrez le titre")
    .min(2, "La longueur minimale requise est de 2 caractères."),

  valeur: Yup.number()
    .required("Entrez le valeur")
    .test('is-positive', 'La valeur doit être supérieure à zéro', value => value > 0),
});

export default validationSchema;
