// @mui material components
import Card from "@mui/material/Card";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MoneyIcon from '@mui/icons-material/Money';
import DescriptionIcon from '@mui/icons-material/Description';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import SourceIcon from '@mui/icons-material/Source';
import SettingsIcon from '@mui/icons-material/Settings';
import PaymentsIcon from '@mui/icons-material/Payments';
import StyleIcon from '@mui/icons-material/Style';
import ArticleIcon from '@mui/icons-material/Article';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import GavelIcon from '@mui/icons-material/Gavel';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";



function Sidenav({ selectedSubPageId, changeSubPage }) {
  const sidenavItems = [
    { icon: <SettingsIcon fontSize="medium" />, label: "General", href: "profile" },
    { icon: <PriorityHighIcon fontSize="medium" />, label: "TVA", href: "basic-info" },
    { icon: <BrandingWatermarkIcon fontSize="medium" />, label: "Droit de timbre", href: "change-password" },
    { icon: <SourceIcon fontSize="medium" />, label: "Retenue à la source", href: "2fa" },
    { icon: <PriorityHighIcon fontSize="medium" />, label: "Conditions de réglement", href: "accounts" },
    { icon: <PaymentsIcon fontSize="medium" />, label: "Mode de règlement", href: "notifications" },
    { icon: <GavelIcon fontSize="medium" />, label: "Intérets de retard", href: "sessions" },
    { icon: <PriorityHighIcon fontSize="medium" />, label: "Type d'article", href: "delete-account" },
    { icon: <AccountBalanceWalletIcon fontSize="medium" />, label: "Comptes bancaire", href: "profile" },
    { icon: <MoneyIcon fontSize="medium" />, label: "Numérotation", href: "basic-info" },
    { icon: <StyleIcon fontSize="medium" />, label: "Thèmes des documents", href: "change-password" },
    { icon: <DescriptionIcon fontSize="medium" />, label: "Préférence pour les devis", href: "2fa" },
    { icon: <RequestPageIcon fontSize="medium" />, label: "Préférence pour facture", href: "accounts" },
    { icon: <ArticleIcon fontSize="medium" />, label: "Préférence pour inscriptions", href: "notifications" }
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;
  
    return (
      <SoftBox key={itemKey} component="li" pt={key === 0 ? 0 : 1} onClick={changeSubPage.bind(null, key)}>
        <SoftTypography
          variant="button"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),
            backgroundColor: key === selectedSubPageId ? light.main : null,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <SoftBox mr={1.5} lineHeight={1}>
            {icon}
          </SoftBox>
          {label}
        </SoftTypography>
      </SoftBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <SoftBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </SoftBox>
    </Card>
  );
}

Sidenav.defaultProps = {
  selectedSubPageId: 0,
  changeSubPage: () => {}
};

Sidenav.propTypes = {
  selectedSubPageId: PropTypes.number,
  changeSubPage: PropTypes.func,
};

export default Sidenav;
