import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import api from "utils/axios";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SoftInput from "components/SoftInput";
function ClassInfoCabinet({ classe }) {
  if (!classe) return null;
  const [loading, setLoading] = useState(false);
  const [optionsSubject, setOptionsSubject] = useState([]);
  const [errorMessageName,setErrorMessageName]=useState("");
  const [errorMessageStartDate,setErrorMessageStartDate]=useState("");
  const [errorMessageEndDate,setErrorMessageEndDate]=useState("");
  const [error, setError] = useState("");
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [name, setName] = useState("");
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const [inputs, setInputs] = useState([{instructor:"",subject:"",optionsInstructor:[], subjectError: '', instructorError: ''}]);

  useEffect(() => {
    if (classe) {
      setName(classe?.name)
      setDateDebut(classe?.startDate)
      setDateFin(classe?.endDate)
      setInputs(classe?.subjects_instructors?.map(item => ({
        subject: item.subject?._id,
        instructor: item.instructor?._id,
        optionsInstructor: []
      })));
    }
  }, [classe]);

  const navigate = useNavigate();

  const handleAddInput = () => {
    setInputs([...inputs,{instructor:"",subject:"",optionsInstructor:[], subjectError: '', instructorError: ''}]);
  };

  const handleFormSubmit = async (values) => {
    try {
      let hasError = false;
      // Validate each input for subject and instructor
    const newInputs = inputs?.map((input) => {
      const updatedInput = { ...input };
      if (!input.subject) {
        updatedInput.subjectError = "La matière est obligatoire.";
        hasError = true;
      } else {
        updatedInput.subjectError = "";
      }
      if (!input.instructor) {
        updatedInput.instructorError = "L'enseignant est obligatoire.";
        hasError = true;
      } else {
        updatedInput.instructorError = "";
      }
      return updatedInput;
    });
    setInputs(newInputs);
     // Check for duplicate subjects
     const duplicateSubject = inputs?.find(
      (input, index) =>
        index !== inputs?.findIndex((otherInput) => otherInput.subject === input.subject)
    );

    if (duplicateSubject) {
      const SubjectName = duplicateSubject?.subject;
      let correspondingName = null;       
      optionsSubject.forEach(option => {
        if (option.value === SubjectName) {
          correspondingName = option.label;
        }
      });        
      correspondingName !== null &&   Swal.fire(
        "Erreur!",
        `Le nom: "${correspondingName} "existe deja, Merci de saisir une autre matière !`,
        "error"
      );
      hasError = true;
    }
      switch(true) {
        case name.length < 2:
          setErrorMessageName("La longueur minimale requise est de 2 caractères.");
          break;
        case dateDebut.length === 0:
          setErrorMessageStartDate("La date de début est obligatoire.");
          break;
        case dateFin.length === 0:
          setErrorMessageEndDate("La date de Fin est obligatoire.");
          break;
        case hasError : 
          return;
        break;
        default:
      const requestData = inputs.map(({ subject, instructor }) => ({
        subject,
        instructor,
      }));
      setLoading(true);
        const response = await api.patch(`training-company/update-classe/${classe._id}`, {
            name,
            startDate: dateDebut,
            endDate: dateFin,
            subjects_instructors: requestData,
        });
        if (response.status === 200) {
          Swal.fire("Succès !", "Mise à jour classe réussie", "success").then(() => {
            // setLoading(false);
            navigate("/classes");
          });
        }
        break;
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        Swal.fire("Error", error.response.data.message, "error");
      } else {
        Swal.fire(
          "Error",
          "Une erreur s'est produite. Veuillez réessayer ultérieurement.",
          "error"
        ).then(() => navigate(0));
      }
    } finally {
      setLoading(false);
    }
  }; 
  const handleRemoveInput = (indexToRemove) => {
    setInputs((prevInputs) => {
      if (indexToRemove >= 0 && indexToRemove < prevInputs.length) {
        const newInputs = [...prevInputs];
        newInputs.splice(indexToRemove, 1);
        return newInputs;
      }
      return prevInputs;
    });
  };
  
  function addDays(dateString, days) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + days);
    // Check if the resulting date is valid
    if (!isNaN(date.getTime())) {
      return date.toISOString().split("T")[0];
    } else {
      //console.error('Invalid date after adding days:', dateString, days);
      return null;
    }
  }
  useEffect(() => {
    // Fetching subjects from the API
    api.get(`/training-company/all-subjects/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsSubject(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
          instructors: item.instructors,
        }))
      );
    });
     // Update optionsInstructor for each input based on their selected subject
     setInputs((prevInputs) =>
      prevInputs?.map((input) => {
        const subject = optionsSubject?.find((subject) => subject.value === input.subject);
        return {
          ...input,
          optionsInstructor: subject ? subject?.instructors?.map((instructor) => ({
            value: instructor._id,
            label: `${instructor.firstName} ${instructor.lastName}`,
          })) : [],
        };
      })
    );
  }, [classe,optionsSubject]);
  return (
    <>
      {Object.keys(classe).length > 0 &&(
          <>
            <SoftBox py={3} mb={20}>
              <Card sx={{ height: "100%" }}>
                <SoftBox p={2}>
                  <SoftBox lineHeight={0} p={1}>
                    <SoftTypography id="add-company-admin" variant="h5" fontWeight="bold">
                      Modifier Classe
                    </SoftTypography>
                  </SoftBox>
                  <Grid container justifyContent="center" sx={{ minHeight: "350px" }}>
                    <Grid item xs={12} lg={12}>
                      <Grid sx={{ mt: 2 }}>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            alignItems: "flex-start",
                          }}
                        >
                          <Grid item xs={12} sm={4}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                              sx={{ mb: 0.5 }}
                            >
                              Nom de classe*
                            </SoftTypography>
                            <SoftInput
                              name="name"
                              margin="normal"
                              id="name"
                              label="classe"
                              type="text"
                              fullWidth
                              placeholder="Nom de classe"
                              inputProps={{
                                minLength: 2,
                                maxLength: 50,
                              }}
                              autoFocus
                              variant="standard"
                              value={name}
                              onChange={(event) => setName(event.target.value)}                                             
                            />
                           {name?.length<2 && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorMessageName}
                      </Typography>
                    )} 
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SoftTypography
                              sx={{ mb: 1 }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              Date Début*
                            </SoftTypography>
                            <SoftInput
                              id="date-start"
                              name="startDate"
                              type="date"
                              value={dateDebut}
                              onChange={(event) => setDateDebut(event.target.value)}
                            />
                           {dateDebut?.length===0 && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorMessageStartDate}
                      </Typography>
                    )} 
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SoftTypography
                              sx={{ mb: 1 }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              Date Fin*
                            </SoftTypography>
                            <SoftInput
                              id="date-fin"
                              name="endDate"
                              type="date"
                              inputProps={
                                {
                                  min: addDays(dateDebut ,1),
                                }
                              }
                              value={dateFin}
                              onChange={(event) => setDateFin(event.target.value)}
                            />
                           {dateFin?.length===0 && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorMessageEndDate}
                      </Typography>
                    )} 
                          </Grid>

                          {inputs?.map((input, index) => (
                            <>
                              <Grid item xs={12} sm={inputs?.length > 1 ? 5.75 : 6}>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  textTransform="capitalize"
                                >
                                  Matière*
                                </SoftTypography>
                                <Autocomplete
                                  options={optionsSubject}
                                  id="Matière"
                                  title="matière"
                                  clearIcon={null}
                                  required={true}
                                  aria-required={true}
                                  getOptionLabel={(option) => option.label}
                                  //defaultValue={null}
                                  //value={optionsSubject?.length>0 && optionsSubject.find((option) => option.value === input?.subject?._id)}
                                  value={
                                    optionsSubject.length > 0 && optionsSubject.find(
                                      (option) => option.value === input.subject
                                    ) || null
                                  }
                                  onChange={(event, newValue) => {
                                    if (newValue !== null && newValue !== undefined) {
                                      const newInputs = [...inputs];
                                      newInputs[index].subject = newValue.value;
                                      newInputs[index].optionsInstructor = newValue?.instructors?.map((item) => ({
                                        value: item._id,
                                        label: `${item?.firstName} ${item?.lastName}`,
                                      })) || [];
                                      setInputs(newInputs);
                                    }
                                  }}
                                 /*  onChange={(event, newValue) => {
                                    if (newValue !== null && newValue !== undefined) {
                                      const newInputs = [...inputs];
                                      newInputs[index].subject = newValue.value;
                                      newInputs[index].optionsInstructor=newValue?.instructors?.map((item) => ({
                                          value: item._id,
                                          label: `${item?.firstName} ${item?.lastName}`,
                                        }))
                                      
                                      setInputs(newInputs);
                                    }
                                  }} */
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      required={true}
                                      placeholder="Tous les matières"
                                    />
                                  )}
                                />
                                {input?.subject?.length < 1 && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {input.subjectError}
                      </Typography>
                    )}  
                              </Grid>
                              <Grid item xs={12} sm={inputs?.length > 1 ? 5.75 : 6}>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  textTransform="capitalize"
                                  sx={{ mb: 0.5 }}
                                >
                                  Enseignant*
                                </SoftTypography>
                                <Autocomplete
                                  options={input?.optionsInstructor}
                                  clearIcon={null}
                                  getOptionLabel={(option) => option.label}
                                  required={true}
                                  aria-required={true}
                                  value={
                                    input.optionsInstructor.length > 0 && input.optionsInstructor.find(
                                      (option) => option.value === input.instructor
                                    ) || null
                                  }
                                  onChange={(event, newValue) => {
                                    if (newValue !== null && newValue !== undefined) {
                                      const newInputs = [...inputs];
                                      newInputs[index].instructor = newValue.value;
                                      setInputs(newInputs);
                                    }
                                  }}
                                 /* defaultValue={
                                  input?.optionsInstructor?.length>0 && input?.optionsInstructor?.find((option) => option.value === input?.instructor?._id || null)}  
                                   onChange={(event, newValue) => {
                                    if (newValue !== null && newValue !== undefined) {
                                      const newInputs = [...inputs];
                                      newInputs[index].instructor = newValue.value;
                                      setInputs(newInputs);
                                    }
                                  }} */
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      required={true}
                                      placeholder="Tous les enseignants"
                                    />
                                  )}
                                />{input?.instructor?.length < 1 && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {input.instructorError}
                      </Typography>
                    )}  
                              </Grid>
                               

                              <Grid item xs={12} sm={0.5} mt={4}>
                                {inputs?.length > 1 && (
                                  <IconButton>
                                    <RemoveCircleIcon
                                      color="error"
                                      fontSize="medium"
                                      onClick={() => handleRemoveInput(index)}
                                    />
                                  </IconButton>
                                )}
                              </Grid>
                            </>
                          ))}
                        </Grid>{" "}
                        <Stack
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ height: "100%" }}
                        >
                          <SoftButton
                            size="small"
                            sx={{
                              mt: inputs?.length > 1 ? 3 : -0.5,
                              borderWidth: "2px",
                              borderStyle: "dashed solid",
                              borderColor: "purple ",
                            }}
                            onClick={handleAddInput}
                          >
                            + ajouter autre matière
                          </SoftButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>

                  <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                    <SoftButton id="btn-cancel" variant="outlined" color="dark" size="small"  onClick={() => navigate("/classes")}>
                      Annuler
                    </SoftButton>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <SoftButton
                        id="btn-add"
                        type="submit"
                        variant="gradient"
                        color="purple"
                        size="small"
                        onClick={handleFormSubmit}
                      >
                        Modifier
                      </SoftButton>
                    )}
                  </SoftBox>
                </SoftBox>
              </Card>
            </SoftBox>
          </>
        )}
    </>
  );
}
ClassInfoCabinet.propTypes = {
  //title: PropTypes.string.isRequired,
  classe: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ClassInfoCabinet;
