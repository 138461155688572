
import api from "./axios";


const downloadFile = (file) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const link = document.createElement("a");
  link.href = `${baseUrl}/${file}`;
  link.target = "_blank";
  link.download = file;
  link.click();
};

// this function is for fetching countries
const fetchData = async () => {
  try {
    const response = await api.get("/world-countries-and-cities");
    const convertedData = [];
    for (const country in response.data) {
      const cities = response.data[country];
      cities.push("Other");
      cities.unshift("sélectionner une ville");
      const countryObj = {
        country: country,
        cities: cities,
      };
      convertedData.push(countryObj);
    }
    const otherCountryObj = {
      country: "Other",
      cities: [],
    };
    const selectObj = {
      country: "Sélectionner un pays",
      cities: ["sélectionner une ville"],
    };
    convertedData.push(otherCountryObj);
    convertedData.unshift(selectObj);
    return convertedData;
  } catch (error) {
    console.log("Error fetching data:", error);
    return null;
  }
};

const getCountryAdd = (info, data) => {
  if (info && info.country2 === "Other") {
    return null;
  } else {
    return data.map((item) => item.country);
  }
};

const getCitiesAdd = (info, data) => {
  if (info && (info.country2 || info.country2 === "Other" || info.city2 === "Other")) {
    return null;
  } else if (
    info &&
    ((info.country2 !== "" && info.country2 !== undefined) ||
      (info.country !== "" && info.country !== undefined))
  ) {
    return data && data.length > 0
      ? data.filter((item) => item.country === info.country).flatMap((item) => item.cities)
      : null;
  } else {
    return data && data.length > 0 && data[0]?.cities;
  }
};

const checkCountryExistsAdd = (info) => {
  return info && info.country2 === "Other" && info && info.country2 !== "";
};

const checkCitiesExistsAd = (info) => {
  return info && (info.country2 === "Other" || info.city2 === "Other" || info.country2);
};

const globalHandleChange = (e, setter, setSetter, setErr, type, setImage) => {

  if (e && e.target && e.target.name) {
    const { name, value, files } = e.target;

    if (
      name === "logoUrl" ||
      name === "imageUrl" ||
      name === "responsable.imageUrl" ||
      name === "student.imageUrl"
    ) {
      setOfImage(name, files, setSetter, setErr, type, setImage);
    } else if (name === "files" || name === "responsable.files" || name === "student.files") {
      setOfFile(name, files, setter, setSetter, setErr, type);
    } else if (name === "country2" || name === "city2") {
      setOfCountry(name, value, setSetter);
    } else {
      setSetter((prev) => ({ ...prev, [name]: value }));
    }
  }
};

export {
  fetchData,
  getCountryAdd,
  getCitiesAdd,
  checkCountryExistsAdd,
  checkCitiesExistsAd,
  globalHandleChange,
  downloadFile
};
