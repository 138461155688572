import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import SoftBox from 'components/SoftBox';
import { Avatar, Card, CircularProgress, FormControl, FormControlLabel, Grid, Icon, Radio, RadioGroup, Typography, Autocomplete, TextField, ListItem,Chip, } from '@mui/material';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import FormField from 'layouts/pages/account/components/FormField';
import SoftInput from 'components/SoftInput';
import api from 'utils/axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import SoftSelect from "components/SoftSelect";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import styled from "@emotion/styled";
import { downloadFile } from "utils/citysTn";
import { getCountryAdd } from "utils/citysTn";
import { fetchData } from "utils/citysTn";
import { ErrorMessage, Formik } from "formik";
import validations from "pages/enterprise/schema/enterprise-schemas/EnterpriseValidation";
import EnterpriseFormField from "pages/enterprise/schema/enterprise-schemas/EnterpriseFormField";

const EnterpriseAdminInfo = ({enterprise,getFunction}) => {
    const [companyAdmin,setCompanyAdmin] = useState({})
    const [isHovered, setIsHovered] = useState(false);
    const [logo,setLogo] = useState(null);
    const [logoUpdate,setLogoUpdate] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading,setLoading] = useState(false);
    const [filesToDelete, setFilesToDelete] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [info, setInfo] = useState({});
    const [files, setFiles] = useState([]);
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [data, setData] = useState([]);
    const [logoToDelete, setLogoToDelete] = useState(null);

    const initialValues = {
      CompanyAdminfirstName: companyAdmin.firstName || "",
      CompanyAdminlastName:companyAdmin.lastName||"",
      CompanyAdminemail:companyAdmin.email||"",
      CompanyAdmingender:companyAdmin.gender||"",
      CompanyAdminphone:companyAdmin.phoneNumber ||"",
    };

const handleFormSubmit = async (values) => {
  try {
    setLoading(true)
      const form = new FormData();
      form.append("imageUrl",logoUpdate ? logoUpdate : logo);
        form.append("firstName",values.CompanyAdminfirstName);
        form.append("lastName",values.CompanyAdminlastName);
        form.append("email",values.CompanyAdminemail);
        form.append("cin",companyAdmin.cin);
        form.append("siteWeb",companyAdmin.siteWeb);
        form.append("occupation",companyAdmin.occupation);
        form.append("address",companyAdmin.address);
        form.append("zipCode",companyAdmin.zipCode);
        form.append("bank",companyAdmin.bank);
        form.append("rib",companyAdmin.rib);
        form.append("birthday",companyAdmin.birthday);
        form.append("cnssNumber",companyAdmin.cnssNumber);
        form.append("phoneNumber",values.CompanyAdminphone);
        form.append("secondaryPhoneNumber",companyAdmin.secondaryPhoneNumber);
        form.append("gender",values.CompanyAdmingender);
        form.append("passeport",companyAdmin.passeport);
        form.append("comment",companyAdmin.comment);
        form.append("country", dataToSend.country ? dataToSend.country : country);
        form.append("city", dataToSend.city ? dataToSend.city : city);
        logoToDelete && form.append("imageUrlToDelete", logoToDelete);
        selectedOptions &&
      selectedOptions.length > 0 &&
      selectedOptions.forEach((item) => {
        form.append("files", item.value);
      });
      form.append("filesToDelete", JSON.stringify(filesToDelete));

      const response = await api.patch(`/userUpdate/${companyAdmin._id}`,form);
      if (response.status === 200) {
        Swal.fire("Succès !", "Mise à jour responsable réussie", "success").then(() => {
          setLoading(false);
          navigate(0);
        });
      }
    } catch (error) {
      setLoading(true);
      if (error.response && error.response.data && error.response.data.message) {
        Swal.fire("Error", error.response.data.message, "error").then(() => navigate(0));
      } else {
        Swal.fire(
          "Error",
          "Une erreur s'est produite. Veuillez réessayer ultérieurement.",
          "error"
        ).then(() => navigate(0));
      }
    }
  
};

    const [dataToSend, setDataToSend] = useState({
    country: "",
    city: "",
  });
  const company = enterprise ? enterprise : {};
  useEffect(() => {
    const fetchDataAsync = async () => {
      const data = await fetchData();
      setData(data);
    };
    fetchDataAsync();
  }, []);
  const handleFetchCompanyAdminInfo = async () => {
    try {
      const response = await api.get(`/get-user/${enterprise.enterpriseAdmin._id}`);
      setCompanyAdmin(response.data);
      setLogo(response.data.imageUrl);
      setFiles(response.data.files)
      setCountry(response.data.country)
      setCity(response.data.city)
      setDataToSend({country:response.data.country,city:response.data.city})
    } catch (error) {
      throw error
    }
  };
  

  useEffect(() => {
    if (enterprise && company) {
      handleFetchCompanyAdminInfo();
    }
  }, [enterprise, company]);


  const fileInputRef = useRef(null);
  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };
  const countries = getCountryAdd(info, data);
  
  const handleCountryChange = (selectedOption) => {
    const selectedCountry = selectedOption.value;
    const cities = data.find((country) => country.country === selectedCountry).cities;

    setDataToSend({ ...dataToSend, country: selectedCountry });
    setCityOptions(cities.map((city) => ({ value: city, label: city })));
  };

  const handleCityChange = (selectedOption) => {
    setDataToSend({ ...dataToSend, city: selectedOption.value });
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    const newOption = { value: file, label: file.name };

    // Check if the file is already selected
    const isSelected = selectedOptions.some((option) => option.label === newOption.label);

    if (!isSelected) {
      // If it's not selected, add it to both options and selectedOptions
      setOptions([...options, newOption]);
      setSelectedOptions([...selectedOptions, newOption]);
    }
  };
  const handleDeleteFile = (fileToDelete) => {
    // Remove the file from the 'files' array
    const updatedFiles = files.filter((file) => file !== fileToDelete);

    // Add the file to the 'filesToDelete' array
    setFilesToDelete([...filesToDelete, fileToDelete]);

    // Update the 'files' state
    setFiles(updatedFiles);
  };
  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // Specify the allowed file extensions
      const allowedExtensions = ["jpg", "jpeg", "png", "gif", "jfif"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        // Display an error message if the file extension is not allowed
        setErrorMessage(
          "Format de fichier non pris en charge. Veuillez sélectionner un fichier JPG, JPEG, PNG ou GIF."
        );
        setLogoUpdate(null); // Clear the logo
        return;
      }

      // Reset the error message if the file extension is allowed
      setErrorMessage("");
      setLogoUpdate(selectedFile);

      // Specify the maximum allowed file size in bytes (e.g., 2MB)
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      const maxWidth = 650; // Maximum width in pixels
      const maxHeight = 650; // Maximum height in pixels

      if (selectedFile.size > maxSize) {
        // Display an error message if the file size exceeds the limit
        setErrorMessage(
          "La taille du fichier est trop grande. La taille maximale autorisée est de 2 Mo."
        );
        setLogoUpdate(null); // Clear the logo
      } else {
        // Check image dimensions
        const img = new Image();
        img.src = URL.createObjectURL(selectedFile);

        img.onload = () => {
          if (img.width > maxWidth || img.height > maxHeight) {
            // Display an error message if the image dimensions exceed the limit
            setErrorMessage(
              `Les dimensions de l’image sont trop grandes. Les dimensions maximales autorisées sont ${maxWidth}x${maxHeight} pixels`
            );
            setLogoUpdate(null); // Clear the logo
          }
        };
      }
    }
  };
  const handleDeleteLogo = () => {
    // Handle deleting the logo here
    // You can implement this function as needed

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setLogoUpdate(null);
    setLogo(null);
    setLogoToDelete(companyAdmin?.imageUrl);
    if (logoUpdate === null) {
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const navigate = useNavigate()
  
  const currentDate = new Date();
  const monthsToSubtract = 1; // Set the number of months to subtract
  const minDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - monthsToSubtract,
    currentDate.getDate()
  );
  const minDateString = minDate.toISOString().split("T")[0];

  return (
<>
    {
  Object.keys(companyAdmin).length > 0 && ( 
    <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validations[2]}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <SoftBox p={3}>
          <SoftBox sx={{ display: "flex", justifyContent: "space-between" }}>
            <SoftTypography variant="h5">Informations du responsable</SoftTypography>
          </SoftBox>
          <Grid item xs={12} sm={13} container justifyContent="center">
            <SoftBox position="relative" height="max-content" mx="auto">
              <Avatar
                onClick={handleAvatarClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{
                  filter: isHovered ? "blur(2px)" : "none",
                  cursor: "pointer",
                  width: 100,
                  height: 100,
                  borderRadius: "16px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={
                  logo && !logoUpdate && !logoToDelete
                    ? `${process.env.REACT_APP_BASE_URL}/${logo}`
                    : logoUpdate
                    ? URL.createObjectURL(logoUpdate)
                    : ""
                }
                alt="profile picture"
                size="xl"
                variant="rounded"
              >
                Logo
              </Avatar>
              <SoftBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                <SoftButton
                  onClick={handleDeleteLogo}
                  variant="gradient"
                  color="error"
                  size="small"
                  iconOnly
                >
                  <Icon>delete</Icon>
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </Grid>
          <input
            type="file"
            // Specify the accepted file types
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={onFileChange}
            accept=".jpg, .jpeg, .png, .gif .jfif"
          />
          {errorMessage && (
            <Typography align="center" sx={{ mt: 2 }} variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
        </SoftBox>

        <SoftBox component="form" pb={3} px={3}>
          <Grid container spacing={3} mb={1}>
            <Grid item xs={12} sm={6}>
              <EnterpriseFormField
                id="firstname"
                inputProps={{
                  minLength:2,
                  maxLength: 30
                }}
                label="Nom*"
                placeholder="Nom"
                defaultValue={values.CompanyAdminfirstName}
                value={values.CompanyAdminfirstName}
                onBlur={handleBlur}
                onChange={handleChange}
                name="CompanyAdminfirstName"
                error={!!touched.CompanyAdminfirstName && !!errors.CompanyAdminfirstName}
                success={values.CompanyAdminfirstName?.length > 0 && !errors.CompanyAdminfirstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EnterpriseFormField
                id="lastname"
                inputProps={{
                  minLength:2,
                  maxLength: 30
                }}
                label="Prénom*"
                placeholder="Prénom"
                defaultValue={values.CompanyAdminlastName}
                value={values.CompanyAdminlastName}
                onBlur={handleBlur}
                onChange={handleChange}
                name="CompanyAdminlastName"
                error={!!touched.CompanyAdminlastName && !!errors.CompanyAdminlastName}
                success={values.CompanyAdminlastName?.length > 0 && !errors.CompanyAdminlastName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={1}>
            <Grid item xs={12} sm={6}>
              <EnterpriseFormField
                id="email"
                label="Email*"
                placeholder="Email"
                inputProps={{
                  type: "email" ,
                 maxLength: 50
               }}
               defaultValue={values.CompanyAdminemail}
                value={values.CompanyAdminemail}
                onBlur={handleBlur}
                onChange={handleChange}
                name="CompanyAdminemail"
                error={!!touched.CompanyAdminemail && !!errors.CompanyAdminemail}
                success={values.CompanyAdminemail?.length > 0 && !errors.CompanyAdminemail}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ mt: 2, minWidth: 120 }}>
                <SoftTypography
                  sx={{ ml: 0.5 }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Genre*
                </SoftTypography>
                <RadioGroup
                  id="gender"
                  sx={{ ml: 2, mt: 1 }}
                  row
                  defaultChecked={values.CompanyAdmingender}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  value={values.CompanyAdmingender}
                  defaultValue={values.CompanyAdmingender}
                  name="CompanyAdmingender"
                  onChange={(e) => {
                    handleChange({ target: { name: 'CompanyAdmingender', value:e.target.value } });
                  }}
                  error={!!touched.CompanyAdmingender && !!errors.CompanyAdmingender}
                  success={values.CompanyAdmingender?.length > 0 && !errors.CompanyAdmingender}
                >
                  <FormControlLabel
                    defaultValue="femme"
                    value="femme"
                    checked={values.CompanyAdmingender === "femme" && true}
                    control={<Radio />}
                    label="Femme"
                  />
                  <FormControlLabel
                    defaultValue="homme"
                    value="homme"
                    control={<Radio />}
                    label="Homme"
                    checked={values.CompanyAdmingender === "homme" && true}
                  />
                </RadioGroup>{" "}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={1}>
            <Grid item xs={12} sm={6}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Date de Naissance
              </SoftTypography>
              <SoftInput
                id="birthday"
                type="date"
                inputProps={{
                  max: minDateString,
                }}
                defaultValue={companyAdmin.birthday}
                value={companyAdmin.birthday}
                onChange={(e) => setCompanyAdmin({ ...companyAdmin, birthday: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                id="cin"
                label="Cin"
                placeholder="Cin"
                defaultValue={companyAdmin.cin}
                value={companyAdmin.cin}
                inputProps={{
                  maxLength: 20,
                  type:"number"
                }}
                onChange={(e) => setCompanyAdmin({ ...companyAdmin, cin: e.target.value })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={6}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Téléphone*
              </SoftTypography>
              <PhoneInput
                id="telephone"
                country={"tn"}
                enableSearch
                inputStyle={{
                  width: "100%",
                }}
                defaultValue={values.CompanyAdminphone}
                value={values.CompanyAdminphone}
                onChange={(value) => {
                  handleChange({ target: { name: 'CompanyAdminphone', value } });
                }}
                name="CompanyAdminphone"
                error={!!touched.CompanyAdminphone && !!errors.CompanyAdminphone}
                success={values.CompanyAdminphone?.length > 0 && !errors.CompanyAdminphone}
              />
               <SoftBox mt={0.75}>
              <SoftTypography component="div" variant="caption" color="error">
                <ErrorMessage name={"CompanyAdminphone"} />
              </SoftTypography>
            </SoftBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Deuxième téléphone
              </SoftTypography>
              <PhoneInput
                id="second-phone"
                country={"tn"}
                enableSearch
                inputStyle={{
                  width: "100%",
                }}
                defaultValue={companyAdmin.secondaryPhoneNumber}
                value={
                  companyAdmin.secondaryPhoneNumber === "undefined"
                    ? ""
                    : companyAdmin.secondaryPhoneNumber
                }
                onChange={(value) =>
                  setCompanyAdmin({ ...companyAdmin, secondaryPhoneNumber: value })
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={6}>
              <FormField
                id="passeport"
                label="Passeport"
                placeholder="Passeport"
                defaultValue={companyAdmin.passeport}
                value={companyAdmin.passeport === "undefined" ? "" : companyAdmin.passeport}
                onChange={(e) => setCompanyAdmin({ ...companyAdmin, passeport: e.target.value })}
                inputProps={{
                  maxLength: 30
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                id="siteweb"
                label="site web"
                placeholder="Site web"
                defaultValue={companyAdmin.siteWeb}
                value={companyAdmin.siteWeb}
                onChange={(e) => setCompanyAdmin({ ...companyAdmin, siteWeb: e.target.value })}
                inputProps={{
                  maxLength: 100
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={1}>
            <Grid item xs={12} sm={6}>
              <FormField
                id="occupation"
                label="Occupation"
                placeholder="Occupation"
                defaultValue={companyAdmin.occupation}
                value={companyAdmin.occupation}
                inputProps={{
                  maxLength: 30
                }}
                onChange={(e) => setCompanyAdmin({ ...companyAdmin, occupation: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormField
                id="num-cnss"
                label="Numéro CNSS"
                placeholder="Numéro CNSS"
                defaultValue={companyAdmin.cnssNumber}
                value={companyAdmin.cnssNumber}
                inputProps={{
                  maxLength: 30
                }}
                onChange={(e) => setCompanyAdmin({ ...companyAdmin, cnssNumber: e.target.value })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={6}>
              <SoftTypography
                sx={{ ml: 0.5 }}
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Pays
              </SoftTypography>
              <SoftSelect
                id="country"
                value={{ value: dataToSend.country, label: dataToSend.country }}
                options={countries?.map((country) => ({ value: country, label: country }))}
                onChange={handleCountryChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftTypography
                sx={{ ml: 0.5 }}
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Ville
              </SoftTypography>
              <SoftSelect
                id="city"
                options={cityOptions}
                value={{ value: dataToSend.city, label: dataToSend.city }}
                onChange={handleCityChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={6}>
              <FormField
                id="adresse"
                label="Adresse"
                placeholder="Adresse"
                defaultValue={companyAdmin.address}
                value={companyAdmin.address}
                inputProps={{
                  maxLength: 50
                }}
                onChange={(e) => setCompanyAdmin({ ...companyAdmin, address: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                id="code-postal"
                label="Code postal"
                placeholder="Code postal"
                defaultValue={companyAdmin.zipCode}
                value={companyAdmin.zipCode}
                onChange={(e) => setCompanyAdmin({ ...companyAdmin, zipCode: e.target.value })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={1}>
            <Grid item xs={12} sm={6}>
              <FormField
                id="bank"
                label="Nom De Banque"
                placeholder="Nom De Banque"
                defaultValue={companyAdmin.bank}
                value={companyAdmin.bank}
                inputProps={{
                  maxLength: 30
                }}
                onChange={(e) => setCompanyAdmin({ ...companyAdmin, bank: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                id="rib"
                label="Rib"
                placeholder="Rib"
                defaultValue={companyAdmin.rib}
                value={companyAdmin.rib}
                inputProps={{
                  maxLength: 20
                }}
                onChange={(e) => setCompanyAdmin({ ...companyAdmin, rib: e.target.value })}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 2, mr: "auto", display: "flow" }} item xs={12}>
            <SoftButton
              sx={{ mb: 1 }}
              component="label"
              onChange={handleFileUpload}
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Télécharger des fichiers
              <VisuallyHiddenInput type="file" />
            </SoftButton>
            <Autocomplete
              multiple
              id="tags-standard"
              placeholder="Télécharger des fichiers"
              options={options}
              value={selectedOptions}
              filterSelectedOptions
              freeSolo
              renderInput={(params) => (
                <TextField {...params} variant="standard" placeholder="Télécharger des fichiers" />
              )}
              onChange={(event, newValue) => {
                setSelectedOptions(newValue);
              }}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.label === value.label}
            />
          </Grid>
          <Grid item xs={12} md={6} mt={1} mb={1}>
            {files && files.length > 0 ? (
              files.map((file, index) => (
                <Grid
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0.5,
                    m: 0,
                  }}
                  component="ul"
                >
                  <ListItem>
                    <Chip size="small" label={file} onDelete={() => handleDeleteFile(file)} />
                    <BrowserUpdatedIcon
                      onClick={() => downloadFile(file.name ? file.name : file)}
                      fontSize="default"
                      sx={{ cursor: "pointer", marginLeft: 0.5 }}
                    />
                  </ListItem>
                </Grid>
              ))
            ) : (
              <Typography sx={{ p: 1 }} variant="body2">
                Aucun fichier
              </Typography>
            )}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} mt={1}>
              <FormField
                id="description"
                multiline
                rows={5}
                label="description"
                placeholder="description"
                defaultValue={companyAdmin.comment}
                inputProps={{
                  maxLength: 250
                }}
                value={companyAdmin.comment === "undefined" ? "" : companyAdmin.comment}
                onChange={(e) => setCompanyAdmin({ ...companyAdmin, comment: e.target.value })}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <Grid
          item
          xs={12}
          ml={3}
          sx={{ mr: 2 }}
          mb={2}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <SoftButton size="small" onClick={() => window.location.reload()} variant="outlined" color="dark">
            Annuler
          </SoftButton>

          {loading ? (
            <CircularProgress />
          ) : (
            <SoftButton size="small" type="submit" sx={{ ml: 2 }} color="customized" variant="gradient">
              Modifier
            </SoftButton>
          )}
        </Grid>
      </Card>
      </form>
        )}
      </Formik>
  )}
    </>
  );
};

EnterpriseAdminInfo.propTypes = {
  enterprise: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  getFunction: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default EnterpriseAdminInfo;
