import { useEffect, useState } from "react";
import { Box, Card, IconButton, Tooltip, CircularProgress, AppBar, Tabs, Tab, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { grey } from "@mui/material/colors";
import { Edit, Delete } from "@mui/icons-material";
import api from "utils/axios";
import moment from "moment";
import AjouterCharge from "./AjouterCharge";
import UpdateCharge from "./UpdateCharge";
import { useSoftUIController } from "context";

function ListCharge() {
  const [currentPage, setCurrentPage] = useState(1); // Track current page number
  const [financiersPerPage] = useState(10); // Number of charges per page
  const [typeValue, setTypeValue] = useState("Fixed");
  const [controller] = useSoftUIController();

  const [open, setOpen] = useState(false);
  const [selectedFinancier, setSelectedFinancier] = useState(null);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const [financiersByTypeFixed, setFinanciersByTypeFixed] = useState([]);
  const [financiersByTypeVariable, setFinanciersByTypeVariable] = useState([]);

  const handleSetTypeValue = (_, newValue) => {
    setTypeValue(newValue);
    setCurrentPage(1);
  };

  const handleFinanciers = () => {
    api.get("get-financiers").then((res) => {
      setFinanciersByTypeFixed(
        res.data.filter(
          (financier) => financier.type === "Fixed" && financier.creerPar === controller.user.info._id
        )
      );
      setFinanciersByTypeVariable(
        res.data.filter(
          (financier) => financier.type === "Variable" && financier.creerPar === controller.user.info._id
        )
      );
    });
  };

  const filteredFinanciersByType = typeValue === "Fixed" ? financiersByTypeFixed : financiersByTypeVariable;

  useEffect(() => {
    handleFinanciers();
  }, []);

  const handleSupprimer = (id) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr(e)",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await api.delete(`delete-financier/${id}`);
          handleFinanciers();
        }
      });
  };

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box mt="30px" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <SoftTypography variant="h5" sx={{ flexGrow: "1" }}>
          {typeValue === "Fixed" ? "Liste des Charges Fixes" : "Liste des Charges Variables"}
        </SoftTypography>
        <SoftButton variant="gradient" color="purple" onClick={() => setOpen(true)}>
          {typeValue === "Fixed" ? "Ajouter Charge Fixe" : "Ajouter Charge Variable"}
        </SoftButton>
      </Box>

      {open && (
        <AjouterCharge
          open={open}
          handleCloseModal={() => setOpen(false)}
          handleFinanciers={handleFinanciers}
          typeValue={typeValue}
        />
      )}

      <Box m={2}>
        <Grid container>
          <Grid item xs={12} sm={8} lg={4}>
            <AppBar
              position="static"
              sx={{
                my: "16px",
                "& .css-1pvfc2b-MuiTabs-root": {
                  bgcolor: `${grey[200]} !important`,
                  justifyContent: "flex-end",
                },
              }}
            >
              <Tabs
                orientation="horizontal"
                value={typeValue}
                onChange={handleSetTypeValue}
                TabIndicatorProps={{ style: { right: 0 } }}
                sx={{ "& .MuiTab-root": { fontSize: "0.875rem", textTransform: "none" } }}
              >
                <Tab value="Fixed" label="Fixed ( Mensuel )" />
                <Tab value="Variable" label="Variable" />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Box>

      <Card sx={{ my: 2 }}>
        <Box>
          <DataTable
            table={{
              columns: [
                { Header: "Type", accessor: "Type", align: "center" },
                { Header: "Titre", accessor: "Titre", align: "center" },
                { Header: "Valeur", accessor: "Valeur", align: "center" },
                { Header: "Date", accessor: "Date", align: "center" },
                { Header: "Action", accessor: "Action", align: "center" },
              ],
              rows: filteredFinanciersByType
                .slice((currentPage - 1) * filteredFinanciersByType.length, currentPage * filteredFinanciersByType.length)
                .map((financier) => ({
                  Type: financier.type,
                  Titre:
                    financier.titre.length > 20 ? financier.titre.substr(0, 20) + "..." : financier.titre,
                  Valeur: financier.valeur,
                  Date:
                    financier.date === undefined
                      ? moment(financier.createdAt).format("DD/MM/YYYY")
                      : moment(financier.date).format("DD/MM/YYYY"),
                  Action: (
                    <Box display="flex" alignItems="center">
                      <Box mx={2}>
                        <Tooltip title="Modifier">
                          <IconButton
                            sx={{ "&:hover": { bgcolor: grey[200] } }}
                            onClick={() => {
                              setSelectedFinancier(financier);
                              setOpenUpdateModal(true);
                            }}
                          >
                            <Edit fontSize="small" color="purple" />
                          </IconButton>
                        </Tooltip>
                        {selectedFinancier && (
                          <UpdateCharge
                            open={openUpdateModal}
                            handleCloseModal={() => setOpenUpdateModal(false)}
                            financierId={selectedFinancier._id}
                            handleFinanciers={handleFinanciers}
                          />

                        
                        )}
                      </Box>

                      <Tooltip title="Supprimer">
                        <IconButton
                          sx={{ "&:hover": { bgcolor: grey[200] } }}
                          onClick={() => handleSupprimer(financier._id)}
                        >
                          <Delete fontSize="small" color="purple" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ),
                })),
            }}
            entriesPerPage={{ defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }}
            canSearch
          />
        </Box>
      </Card>
    </DashboardLayout>
  );
}

export default ListCharge;
