import { CircularProgress, Grid, Menu, MenuItem } from '@mui/material'
import SoftBox from 'components/SoftBox'
import PlaceholderCard from 'examples/Cards/PlaceholderCard'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'layouts/DashboardNavbar'
import Header from 'layouts/pages/profile/components/Header'
import React, { useEffect, useState } from 'react'
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom'
import ComplexProjectCard from './components/Card'
import SoftPagination from 'components/SoftPagination'

export default function ListCardMatiere() {
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle
  const itemsPerPage = 8; // Nombre d'articles par page
  const totalPages = 2;
  //const totalPages = Math.ceil(data.length / itemsPerPage);
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  // TeamProfileCard dropdown menu handlers
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const navigate = useNavigate()
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  // Fonction pour passer à la page précédente
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
       <MenuItem onClick={() => navigate("/cours/matieres/123")}>
         <RemoveRedEyeIcon />   &nbsp; Voir plus</MenuItem> 
      <MenuItem > <EditIcon />  &nbsp; Modifier</MenuItem>
      <MenuItem > <DeleteIcon />&nbsp;   Supprimer</MenuItem>
    </Menu>
  );
  useEffect(() => {
    // change page title in the header
    document.title = "Cours";
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title={"Liste des matières"} searchVisible={true} ExportVisible={false} />
      <>
        <SoftBox pb={2}>
          <SoftBox mt={{ xs: 1, lg: 2 }} mb={1}>
            <Grid container spacing={3}>
                  <Grid
                                id="add-matiere"
                                onClick={() => navigate(0)}
                                item
                                xs={12}
                                md={20}
                                lg={4}
                                sx={{ cursor: "pointer" }}
                            >
                                <PlaceholderCard
                                    title={{ variant: "h5", text: "Ajouter Matière" }}
                                ></PlaceholderCard>
                            </Grid> 
                            <Grid item xs={12} md={6} lg={4}>
                <ComplexProjectCard
                  image={team3}
                  name={"Java"}
                  students={3}
                  level={"1"}
                  specialitie={"GL"}
                  //studentId={student?._id}
                  //departementId={student?.level?.speciality?.departement?.name}
                  dateTime="16.01.22"
                  members={[team1, team2, team3, team4]}
                  dropdown={{
                   action: (e) => {
                     // openSlackBotMenu;
                     setSlackBotMenu(e.currentTarget);
                     //setSelectedStudentId(student?._id);
                     //setSelectedStudentCode(student?.code);
                   },
                   menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                 }} 
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <ComplexProjectCard
                  image={team3}
                  name={"Java"}
                  students={3}
                  level={"1"}
                  specialitie={"GL"}
                  //studentId={student?._id}
                  //departementId={student?.level?.speciality?.departement?.name}
                  dateTime="16.01.22"
                  members={[team1, team2, team3, team4]}
                  dropdown={{
                   action: (e) => {
                     // openSlackBotMenu;
                     setSlackBotMenu(e.currentTarget);
                     //setSelectedStudentId(student?._id);
                     //setSelectedStudentCode(student?.code);
                   },
                   menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                 }} 
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <ComplexProjectCard
                  image={team3}
                  name={"Java"}
                  students={3}
                  level={"1"}
                  specialitie={"GL"}
                  //studentId={student?._id}
                  //departementId={student?.level?.speciality?.departement?.name}
                  dateTime="16.01.22"
                  members={[team1, team2, team3, team4]}
                  dropdown={{
                   action: (e) => {
                     // openSlackBotMenu;
                     setSlackBotMenu(e.currentTarget);
                     //setSelectedStudentId(student?._id);
                     //setSelectedStudentCode(student?.code);
                   },
                   menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                 }} 
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <ComplexProjectCard
                  image={team3}
                  name={"Java"}
                  students={3}
                  level={"1"}
                  specialitie={"GL"}
                  //studentId={student?._id}
                  //departementId={student?.level?.speciality?.departement?.name}
                  dateTime="16.01.22"
                  members={[team1, team2, team3, team4]}
                  dropdown={{
                   action: (e) => {
                     // openSlackBotMenu;
                     setSlackBotMenu(e.currentTarget);
                     //setSelectedStudentId(student?._id);
                     //setSelectedStudentCode(student?.code);
                   },
                   menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                 }} 
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <ComplexProjectCard
                  image={team3}
                  name={"Java"}
                  students={3}
                  level={"1"}
                  specialitie={"GL"}
                  //studentId={student?._id}
                  //departementId={student?.level?.speciality?.departement?.name}
                  dateTime="16.01.22"
                  members={[team1, team2, team3, team4]}
                  dropdown={{
                   action: (e) => {
                     // openSlackBotMenu;
                     setSlackBotMenu(e.currentTarget);
                     //setSelectedStudentId(student?._id);
                     //setSelectedStudentCode(student?.code);
                   },
                   menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                 }} 
                />
              </Grid>

             
            </Grid>

          </SoftBox>
        </SoftBox>
     <SoftPagination>
          {currentPage > 1 && (
            <SoftPagination item onClick={prevPage}><ArrowBackIosIcon /></SoftPagination>
          )}
          {Array.from({ length: totalPages }, (_, index) => (
            <SoftPagination item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </SoftPagination >
          ))}
          {currentPage < totalPages && (
            <SoftPagination item onClick={nextPage}><ArrowForwardIosIcon /> </SoftPagination>
          )}
    </SoftPagination> 
      </>
    </DashboardLayout>
  )
}