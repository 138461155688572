//import layouts
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";

import { Box, Card, Grid, Typography } from "@mui/material";

import { grey } from "@mui/material/colors";

//hooks
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";

//components
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";

import Swal from "sweetalert2";

import { Formik, Form, Field } from "formik";
import validationSchema from "../ListTaches/validerChamps/ValidationSchema";
import SelectInfo from "../ListTaches/data/SelectInfo";

//librarie utiliser pour créer facilement un champ de saisie de numéro de téléphone avec une validation et une mise en forme intégrées
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SoftSelect from "components/SoftSelect";
import api from "utils/axios";
import { useNavigate, useParams } from "react-router-dom";

function UpdateEntreprisePotentiel() {
  const navigate = useNavigate();
  const theme = useTheme();

  //phone number state
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleOnChangePhoneNumber = (value) => {
    setPhoneNumber(value);
  };

  const [fixNumber, setFixNumber] = useState("");
  const handleOnChangeFixNumber = (value, country) => {
    setFixNumber(value);
  };

  const [entreprisePotentiel, setEntreprisePotentiel] = useState({});
  
  const { id } = useParams();

  const getEntreprisePotentiel = (id) => {
    api.get(`tasks/get-entreprise-potentiel-by-id/${id}`).then((res) => {
      setEntreprisePotentiel(res.data);
    });
  };

  const initialValues = {
    nomEntr: entreprisePotentiel.nomEntreprise,
    adresse: entreprisePotentiel.adresse,
    emailCom: entreprisePotentiel.emailCommercial,
    matricule:entreprisePotentiel.matriculeFiscal,
  };

  useEffect(() => {
    getEntreprisePotentiel(id);
  }, []);

  const [emailResponsables, setEmailResponsables] = useState([]);
  const getEmailObservateursEtResponsables = () => {
    api.get("get-user-commercial?role=commercial").then((response) => {
      const responsablesEmail = response.data.map((responsableEmail) => responsableEmail.email);
      setEmailResponsables(responsablesEmail);
    });
  };

  useEffect(() => {
    getEmailObservateursEtResponsables();
  }, [setEmailResponsables]);

  const updateEntreprisePotentiel = () => {
    const updatedEntreprisePotentiel = {
      nomEntreprise: entreprisePotentiel.nomEntreprise,
      matriculeFiscal: entreprisePotentiel.matriculeFiscal,
      emailEntreprise: entreprisePotentiel.emailEntreprise,
      siteWeb: entreprisePotentiel.siteWeb,
      numTel: entreprisePotentiel.numTel,
      numFix: entreprisePotentiel.numFix,
      adresse: entreprisePotentiel.adresse,
      codePostal: entreprisePotentiel.codePostal,
      nomEtPrenomAgent: entreprisePotentiel.nomEtPrenomAgent,
      statut: entreprisePotentiel.statut,
      emailCommercial: entreprisePotentiel.emailCommercial,
      description: entreprisePotentiel.description,
    };
  
    if (
      entreprisePotentiel.nomEntreprise?.length > 1 &&
      entreprisePotentiel.adresse?.length >= 10 &&
      entreprisePotentiel.emailCommercial !== ""
    ) {
      if (
        entreprisePotentiel.matriculeFiscal === "" ||
        (entreprisePotentiel.matriculeFiscal.length >= 8 && entreprisePotentiel.matriculeFiscal.length <= 16)
      ) {
        api.put(`tasks/update-entreprise-potentiel/${id}`, updatedEntreprisePotentiel).then((res) => {
          Swal.fire({
            title: "Succès",
            text: "La mise à jour de l'entreprise potentielle a été effectuée avec succès",
            icon: "success",
            confirmButtonText: "ok",
          });
          navigate("/commercials/entreprisesPotentiel");
        });
      } else {
        Swal.fire({
          title: "Warning",
          text: "Saisir la Matricule fiscal correctement",
          icon: "warning",
          confirmButtonText: "ok",
        });
      }
    } else {
      Swal.fire({
        title: "Warning",
        text: "Veuillez ajouter tous les champs obligatoires",
        icon: "warning",
        confirmButtonText: "ok",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ errors, touched, setFieldValue, handleSubmit,handleChange, handleBlur }) => (
          <Form mt="16px" onSubmit={handleSubmit}>
            <Card
              sx={{
                border: `1px solid ${grey[200]}`,
                bgcolor: "white",
                borderRadius: "15px",
                p: "20px",
                mt: "16px",
                overflow: "visible",
              }}
            >
              <Box display="flex" alignItems="center" mt="16px">
              <Typography variant="h5" sx={{ flexGrow: "1" }} color={theme.palette.text.primary}>
                Editer Entreprise Potentielle
              </Typography>
              <SoftButton
                type="submit"
                variant="gradient"
                color="purple"
                onClick={() => {
                  updateEntreprisePotentiel();
                }}
              >
                Modifier
              </SoftButton>
            </Box>
              

              <Grid container mt="8px">
                <Grid item xs={12} md={6} sx={{ display: "block" }}>
                  <Box mr={{ sx: "0px", md: "8px" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "16px" }}
                      color={theme.palette.text.primary}
                    >
                      Nom entreprise*
                    </Typography>
                    <Field name="nomEntr">
                      {({ field }) => (
                        <>
                          <SoftInput
                            {...field}
                            variant="outlined"
                            fullWidth
                            placeholder="Nom Entreprise"
                            onBlur={handleBlur}
                            value={entreprisePotentiel.nomEntreprise}
                            error={entreprisePotentiel.nomEntreprise?.length <= 1}
                            success={entreprisePotentiel.nomEntreprise?.length > 1}
                            onChange={(event) => {
                              handleChange(event);
                              setEntreprisePotentiel({
                                ...entreprisePotentiel,
                                nomEntreprise: event.target.value,
                              });
                              setFieldValue("nomEntr", event.target.value);
                            }}
                          />

                          {entreprisePotentiel.nomEntreprise?.length <= 1 && (
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "400", fontSize: "12px" }}
                              color="error"
                            >
                              {errors.nomEntr}
                            </Typography>
                          )}
                        </>
                      )}
                    </Field>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block" }} mt={{ xs: "16px", md: "0px" }}>
                  <Box ml={{ sx: "0px", md: "8px" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "16px" }}
                      color={theme.palette.text.primary}
                    >
                      Matricule Fiscal
                    </Typography>
                    <Field name="matricule">
                      {({ field }) => (
                        <>
                          <SoftInput
                            {...field}
                            variant="outlined"
                            fullWidth
                            placeholder="Matricule Fiscal"
                            onBlur={handleBlur}
                            value={entreprisePotentiel.matriculeFiscal}
                            error={
                              !!entreprisePotentiel.matriculeFiscal &&
                              (entreprisePotentiel.matriculeFiscal.length < 8 || entreprisePotentiel.matriculeFiscal.length > 16)
                            }
                            success={
                              !entreprisePotentiel.matriculeFiscal ||
                              (entreprisePotentiel.matriculeFiscal.length >= 8 && entreprisePotentiel.matriculeFiscal.length <= 16)
                            }
                            onChange={(event) => {
                              handleChange(event);
                              setEntreprisePotentiel({
                                ...entreprisePotentiel,
                                matriculeFiscal: event.target.value,
                              });
                              setFieldValue("matricule", event.target.value);
                            }}
                          />

                          {entreprisePotentiel.matriculeFiscal?.length <= 1 && (
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "400", fontSize: "12px" }}
                              color="error"
                            >
                              {errors.matricule}
                            </Typography>
                          )}
                        </>
                      )}
                    </Field>
                  
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box mr={{ sx: "0px", md: "8px" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "16px" }}
                      color={theme.palette.text.primary}
                    >
                      Email entreprise
                    </Typography>
                    <SoftInput
                      variant="outlined"
                      fullWidth
                      placeholder="Email Entreprise"
                      value={entreprisePotentiel.emailEntreprise}
                      onChange={(event) => {
                        setEntreprisePotentiel({
                          ...entreprisePotentiel,
                          emailEntreprise: event.target.value,
                        });
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box ml={{ sx: "0px", md: "8px" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "16px" }}
                      color={theme.palette.text.primary}
                    >
                      Site Web
                    </Typography>
                    <SoftInput
                      variant="outlined"
                      fullWidth
                      placeholder="Site Web"
                      value={entreprisePotentiel.siteWeb}
                      onChange={(event) => {
                        setEntreprisePotentiel({
                          ...entreprisePotentiel,
                          siteWeb: event.target.value,
                        });
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box mr={{ sx: "0px", md: "8px" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "16px" }}
                      color={theme.palette.text.primary}
                    >
                      Numéro de Téléphone
                    </Typography>
                    <PhoneInput
                      country={"tn"}
                      value={entreprisePotentiel.numTel || phoneNumber}
                      onChange={(value) => {
                        handleOnChangePhoneNumber(value);
                        setEntreprisePotentiel({
                          ...entreprisePotentiel,
                          numTel: value,
                        });
                      }}
                      inputStyle={{
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box ml={{ sx: "0px", md: "8px" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "16px" }}
                      color={theme.palette.text.primary}
                    >
                      Numéro Fix
                    </Typography>
                    <PhoneInput
                      country={"tn"}
                      value={entreprisePotentiel.numFix || fixNumber}
                      onChange={(value) => {
                        handleOnChangeFixNumber(value);
                        setEntreprisePotentiel({
                          ...entreprisePotentiel,
                          numFix: value,
                        });
                      }}
                      inputStyle={{
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box mr={{ sx: "0px", md: "8px" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "16px" }}
                      color={theme.palette.text.primary}
                    >
                      Adresse*
                    </Typography>
                    <Field name="adresse">
                      {({ field }) => (
                        <>
                          <SoftInput
                            {...field}
                            variant="outlined"
                            fullWidth
                            placeholder="Adresse"
                            onBlur={handleBlur}
                            value={entreprisePotentiel.adresse}
                            error={entreprisePotentiel.adresse?.length < 10}
                            success={entreprisePotentiel.adresse?.length >= 10}
                            onChange={(event) => {
                              handleChange(event);
                              setEntreprisePotentiel({
                                ...entreprisePotentiel,
                                adresse: event.target.value,
                              });
                              setFieldValue("adresse", event.target.value);
                            }}
                          />
                          {entreprisePotentiel.adresse?.length < 10 && (
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "400", fontSize: "12px" }}
                              color="error"
                            >
                              {errors.adresse}
                            </Typography>
                          )}
                        </>
                      )}
                    </Field>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "block", mt: "16px" }}>
                  <Box ml={{ sx: "0px", md: "8px" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "16px" }}
                      color={theme.palette.text.primary}
                    >
                      Code Postal
                    </Typography>
                    <SoftInput
                      type="number"
                      variant="outlined"
                      fullWidth
                      placeholder="Code Postal"
                      value={entreprisePotentiel.codePostal}
                      onChange={(event) => {
                        handleChange(event);
                        setEntreprisePotentiel({
                          ...entreprisePotentiel,
                          codePostal: event.target.value,
                        });
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={4} sx={{ display: "block", mt: "16px" }}>
                  <Box mr={{ sx: "0px", md: "8px" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "16px" }}
                      color={theme.palette.text.primary}
                    >
                      Nom & Prénom Agent
                    </Typography>
                    <SoftInput
                      variant="outlined"
                      fullWidth
                      placeholder="Nom et Prénom agent"
                      value={entreprisePotentiel.nomEtPrenomAgent}
                      onChange={(event) => {
                        setEntreprisePotentiel({
                          ...entreprisePotentiel,
                          nomEtPrenomAgent: event.target.value,
                        });
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={4} sx={{ display: "block", mt: "16px" }}>
                  <Box ml={{ sx: "0px", mx: "8px" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "16px" }}
                      color={theme.palette.text.primary}
                    >
                      Statut
                    </Typography>
                    <SoftSelect
                      options={SelectInfo.statutEntreprise}
                      value={SelectInfo.statutEntreprise.find(
                        (stat) => stat.value === entreprisePotentiel.statut
                      )}
                      onChange={(event) => {
                        setEntreprisePotentiel({
                          ...entreprisePotentiel,
                          statut: event.value,
                        });
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={4} sx={{ display: "block", mt: "16px" }}>
                  <Box ml={{ sx: "0px", md: "8px" }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "16px" }}
                      color={theme.palette.text.primary}
                    >
                      Email commercial*
                    </Typography>
                    <Field name="emailCom">
                      {({ _ }) => (
                        <>
                          <SoftSelect
                            options={emailResponsables.map((emailResponsable) => {
                              return {
                                value: emailResponsable,
                                label: emailResponsable,
                              };
                            })}
                            value={{
                              value: entreprisePotentiel.emailCommercial,
                              label: entreprisePotentiel.emailCommercial,
                            }}
                            onChange={(event) => {
                              setEntreprisePotentiel({
                                ...entreprisePotentiel,
                                emailCommercial: event.value,
                              });
                            }}
                          />
                          {/* {entreprisePotentiel.emailCommercial === "" && !!touched.emailCom && (
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "400", fontSize: "12px" }}
                              color="error"
                            >
                              {errors.emailCom}
                            </Typography>
                          )} */}
                        </>
                      )}
                    </Field>
                  </Box>
                </Grid>

                <Grid item xs={12} sx={{ display: "block", mt: "16px" }}>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "16px" }}
                      color={theme.palette.text.primary}
                    >
                      Description
                    </Typography>
                    <SoftInput
                      multiline
                      rows={3}
                      placeholder="Description"
                      variant="outlined"
                      value={entreprisePotentiel.description}
                      onChange={(event) => {
                        setEntreprisePotentiel({
                          ...entreprisePotentiel,
                          description: event.target.value,
                        });
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Form>
        )}
      </Formik>
    </DashboardLayout>
  );
}

export default UpdateEntreprisePotentiel;
