import {
  Box, IconButton, Typography,
  useMediaQuery, Grid, Autocomplete, TextField,
  Radio, Dialog, DialogTitle, DialogContent,
  DialogActions, CircularProgress
} from "@mui/material";
import SoftBox from "components/SoftBox";
import {  Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import moment from "moment";
import api from "utils/axios";
import Swal from "sweetalert2";
import { useTheme } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useSoftUIController } from "context";
import { useParams } from "react-router-dom";
import * as Yup from 'yup';

function Presence({ classe, open, handleCloseModal, studentStatusArray, handleFetchHistorique }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [controller, _] = useSoftUIController();
  const facility = JSON.parse(localStorage.getItem("facility"));
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [optionsMatiere, setOptionsMatiere] = useState([]);
  const [dateDebutt, setDateDebutt] = useState("");
  const [heureDebutSysteme, setHeureDebutSysteme] = useState("");
  const [heureDebut, setHeureDebut] = useState("");
  const initialValues = {
    dateDebut: "",
    heureDebut: "",
    heureFin: "",
    matiere: null,
  };
  const validationSchema = Yup.object().shape({
    dateDebut: Yup.string().required('Date dèbut est obligatoire.'),
    heureDebut: Yup.string().required('Heure début est obligatoire.').test(
      'is-greater',
      'Heure début doit être égale ou après l\'heure actuelle moins 4 heures',
      function (value) {
        const heureDebutSysteme = moment().subtract(4, "hours").format("HH:mm");
        return moment(value, "HH:mm").isSameOrAfter(moment(heureDebutSysteme, "HH:mm"));
      }
    ),
    heureFin: Yup.string().required('Heure fin est obligatoire.').test(
      'is-greater',
      'Heure fin doit être après heure début',
      function (value) {
        const { heureDebut } = this.parent;
        return moment(value, "HH:mm").isAfter(moment(heureDebut, "HH:mm"));
      }
    ),
    matiere: Yup.string().required('Matière est obligatoire.'),
  });
  useEffect(() => {
    let endpoint;
    if (facility?.type === "centreDeFormation") {
      endpoint = `training-company/subject-by-class-and-instructor/${id}/${controller.user.info._id}`;
    } else {
      endpoint = `/faculty/subject-types-by-class-and-instructor/${id}/${controller.user.info._id}`;
    }
    api.get(endpoint).then((res) => {
      if (facility?.type === "centreDeFormation") {
        setOptionsMatiere(
          res?.data?.map((item) => ({
            value: item._id,
            label: item?.name,
          }))
        );
      } else {
        setOptionsMatiere(
          res?.data?.map((item) => ({
            value: item._id,
            label: `${item?.type} ${item?.subject?.name}`,
          }))
        );
      }
    });
  }, []);

  useEffect(() => {
    /*     if (dateDebutt !== "") {
          if (dateDebutt === moment().format("YYYY-MM-DD")) { */
    setHeureDebutSysteme(moment().format("HH:mm"));
    /* } else {
      setHeureDebutSysteme("00:00");
    }
  } */
  }, [/* dateDebutt */]);

  const handleFormSubmit = async (values) => {
    const info = {
      classeId: id,
      subjectId: values.matiere,
      sessionDate: values.dateDebut,
      startDate: values.heureDebut,
      endDate: values.heureFin,
      students: studentStatusArray
    };
    try {
      setLoading(true)
      let endpoint;
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/create-attendance?scholarityConfigId=${facility.scholarityConfigId}`;
      } else {
        endpoint = `/faculty/create-attendance?scholarityConfigId=${facility.scholarityConfigId}`;
      }
      const data = await api.post(endpoint, info);
      if (data.status === 200) {
        Swal.fire("success !", "Prèsence a été ajouté avec succès", "success").then(() => {
          setLoading(false)
          handleCloseModal()
          handleFetchHistorique()
        });
      }
    } catch (error) {
      setLoading(false)
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
        Swal.fire("Erreur!", error.response.data.message, "error")
      } else {
        Swal.fire("Erreur!", "Une erreur s'est produite. Veuillez réessayer ultérieurement.", "error")
      }
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleCloseModal}
    >
      <DialogTitle>
        Formulaire de validation de présence
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue }) => (
            <Form onSubmit={handleSubmit} style={{ marginTop: "8px" }}>
              <Grid container spacing={2} sx={{ position: "relative" }}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mr: { xs: "0px", md: "8px" } }}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Nom et Prénom
                    </SoftTypography>
                    <SoftInput
                      variant="outlined"
                      disabled
                      value={`${controller.user.info.firstName} ${controller.user.info.lastName}`}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mr: { xs: "0px", md: "8px" } }}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Classe
                    </SoftTypography>
                    <SoftInput
                      variant="outlined"
                      disabled
                      value={classe.name}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ position: "relative" }}>
                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Choix des matières :
                  </SoftTypography>
                  <Autocomplete
                    id="matiere"
                    options={optionsMatiere}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => setFieldValue("matiere", newValue.value)}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Radio
                          checked={selected}
                          onChange={() => setFieldValue("matiere", option.value)}
                        />
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Sélectionner le nom de matière"
                        variant="standard"
                      />
                    )}
                  />
                  {errors.matiere && touched.matiere && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 400, fontSize: "12px" }}
                      color="error"
                    >
                      {errors.matiere}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Date de la séance*
                  </SoftTypography>
                  <Field name="dateDebut">
                    {({ field }) => (
                      <SoftInput
                        {...field}
                        variant="outlined"
                        type="date"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          setDateDebutt(event.target.value);
                        }}
                        inputProps={{
                          min: moment(new Date()).format("YYYY-MM-DD"),
                        }}
                      />
                    )}
                  </Field>
                  {errors.dateDebut && touched.dateDebut && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 400, fontSize: "12px" }}
                      color="error"
                    >
                      {errors.dateDebut}
                    </Typography>
                  )}                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ position: "relative" }}>
                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Heure de Début*
                  </SoftTypography>
                  <Field name="heureDebut">
                    {({ field }) => (
                      <SoftInput
                        {...field}
                        variant="outlined"
                        type="time"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          setFieldValue("heureDebut", event.target.value);
                          setHeureDebut(event.target.value);
                        }}
                        inputProps={{
                          step: 900,
                          /*  min:  moment(heureDebutSysteme, "HH:mm").subtract(4, "hours").format("HH:mm"), */
                        }}
                      />
                    )}
                  </Field>
                  {errors.heureDebut && touched.heureDebut && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 400, fontSize: "12px" }}
                      color="error"
                    >
                      {errors.heureDebut}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Heure de Fin*
                  </SoftTypography>
                  <Field name="heureFin">
                    {({ field }) => (
                      <SoftInput
                        {...field}
                        variant="outlined"
                        type="time"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          setFieldValue("heureFin",
                            event.target.value);
                        }}
                        inputProps={{
                          step: 900,
                          /* 
                                                    min: moment(heureDebut, "HH:mm").add(1, "minutes").format("HH:mm"), */
                        }}
                      />
                    )}
                  </Field>
                  {errors.heureFin && touched.heureFin && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 400, fontSize: "12px" }}
                      color="error"
                    >
                      {errors.heureFin}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <DialogActions>
                <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                  <SoftButton
                    id="btn-cancel"
                    onClick={handleCloseModal}
                    variant="outlined"
                    color="dark"
                  >
                    Annuler
                  </SoftButton>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <SoftButton
                      type="submit"
                      variant="gradient"
                      color="purple"
                    >
                      Envoyer
                    </SoftButton>)}
                </SoftBox>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
Presence.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  studentStatusArray: PropTypes.array.isRequired,
  handleFetchHistorique: PropTypes.func.isRequired,
  classe: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default Presence;