import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";

import Typography from "@mui/material/Typography";
import { Box, Card, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import { AddCircle, Edit } from "@mui/icons-material";


import { useEffect, useState } from "react";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SoftBadgeDot from "components/SoftBadgeDot";
import api from "utils/axios";
import DataTable from "examples/Tables/DataTable";

function EntreprisesPotentiel() {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1); // Track current page number
  const [entreprisesPotentiel, setEntreprisesPotentiel] = useState([]);
  const [loading, setLoading] = useState(false);


  const fetchEntreprisesPtentielles = async () => {
    setLoading(true);
    try {
      const response = await api.get("tasks/get-entreprises-potentiel");
      setEntreprisesPotentiel(response.data);
    } catch (error) {
      console.error("Error fetching potential companies:", error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchEntreprisesPtentielles();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      
      <Card sx={{ my: 2 }}>
        {loading ? (
          <Box sx={{ mt: 13, textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Box my="16px" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mx:"24px" }}>
              <Typography variant="h5" sx={{ flexGrow: "1" }}>
                Liste des Entreprises Potentielles
              </Typography>
              <Box>
                <SoftButton
                  variant="outlined"
                  color="purple"
                  size="small"
                  sx={{ mr : "8px"}}
                >
                  export
                </SoftButton>
                <SoftButton
                  variant="gradient"
                  color="purple"
                  size="small"
                  onClick={() => navigate("/commercials/entreprisesPotentiel/ajouterEntreprisePotentiel")}
                >
                  Ajouter une Entreprise Potentielle
                </SoftButton>
              </Box>
            </Box>

            <Box>
            <DataTable
                table={{
                  columns: [
                    { Header: "Nom Entreprise", accessor: "nomEntreprise", width: "15%" },
                    { Header: "Matricule Fiscale", accessor: "matriculeFiscal", width: "15%", align : "center" },
                    { Header: "Tel Mobile", accessor: "telMobile", width: "15%", align : "center" },
                    { Header: "Nom Prenom Agent", accessor: "nomPrenomAgent", width: "20%", align : "center" },
                    { Header: "Email Commercial", accessor: "emailCommercial", width: "15%" },
                    { Header: "Statut", accessor: "statut", width: "10%" },
                    { Header: "Action", accessor: "action", width: "10%", align : "center" },
                  ],
                  rows: entreprisesPotentiel
                    .slice((currentPage - 1) * entreprisesPotentiel.length, currentPage * entreprisesPotentiel.length)
                    .map((entreprisePotentiel) => ({
                      nomEntreprise: entreprisePotentiel.nomEntreprise || "_",
                      matriculeFiscal: entreprisePotentiel.matriculeFiscal || "_",
                      telMobile: entreprisePotentiel.numTel ? `+${entreprisePotentiel.numTel}` : "_",
                      nomPrenomAgent: entreprisePotentiel.nomEtPrenomAgent || "_",
                      emailCommercial: entreprisePotentiel.emailCommercial,
                      statut: (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {entreprisePotentiel.statut === "Neutral" ? (
                            <SoftBadgeDot color="secondary" size="xs" badgeContent="neutral" />
                          ) : entreprisePotentiel.statut === "Positive" ? (
                            <SoftBadgeDot color="success" size="xs" badgeContent="positive" />
                          ) : (
                            <SoftBadgeDot color="error" size="xs" badgeContent="negative" />
                          )}
                        </Box>
                      ),
                      action: (
                        <Box display="flex" alignItems="center">
                          <Box mx={2}>
                            <Tooltip title="Modifier">
                              <IconButton
                                sx={{ "&:hover": { bgcolor: grey[200] } }}
                                onClick={() => {
                                  navigate(
                                    `/commercials/entreprisesPotentiel/editerEntreprisePotentiel/${entreprisePotentiel._id}`
                                  );
                                }}
                              >
                                <Edit fontSize="small" color="purple" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Tooltip title="Ajouter entreprise réelle">
                            <IconButton
                              sx={{ "&:hover": { bgcolor: grey[200] } }}
                              onClick={() => {
                                const newSwal = Swal.mixin({
                                  customClass: {
                                    confirmButton: "button button-warning",
                                    cancelButton: "button button-success",
                                  },
                                  buttonsStyling: false,
                                });

                                if (entreprisePotentiel.statut === "Positive") {
                                  newSwal
                                    .fire({
                                      title: "Êtes-vous sûr(e)",
                                      text: "Voulez-vous ajouter une entreprise?",
                                      icon: "question",
                                      showCancelButton: true,
                                      confirmButtonText: "Ajouter une entreprise",
                                      cancelButtonText: "Annuler",
                                    })
                                    .then(async (result) => {
                                      if (result.isConfirmed) {
                                        navigate(`/companies/add-enterprise?data=${entreprisePotentiel}`);
                                      }
                                    });
                                } else {
                                  Swal.fire({
                                    title: "Warning",
                                    text: "Le statut de l'entreprise potentielle n'est pas positif",
                                    icon: "error",
                                    confirmButtonText: "OK",
                                  });
                                }
                              }}
                            >
                              <AddCircle fontSize="small" color="purple" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ),
                    })),
                }}
                entriesPerPage={{ defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }}
                canSearch
              />
            </Box>
          </Box>
        )}
      </Card>
      
    </DashboardLayout>
  );
}

export default EntreprisesPotentiel;
