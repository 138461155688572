import { useTheme } from "@emotion/react";
import { Box, Button, Card, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftEditor from "components/SoftEditor";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Field, Form, Formik } from "formik";
import DashboardNavbar from "layouts/DashboardNavbar";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { grey } from "material-ui-core/colors";
import SoftSelect from "components/SoftSelect";
import { useEffect, useState } from "react";
import validationSchema from "./validerChamps/validationSchema";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { DateRange } from "@mui/icons-material";
import Image from "./components/Image";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import api from "utils/axios";
import { useNavigate, useParams } from "react-router-dom";
import SelectData from "../data/SelectData";
import MDEditor from "components/SoftEditor";
import TextEditor from "./components/TextEditor";
import Swal from "sweetalert2";
import moment from "moment";

function UpdateEvent() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [updateEvent, setUpdateEvent] = useState([]);

  const { id } = useParams();
  const getEvent = (id) => {
    api.get(`get-event/${id}`).then((response) => {
      setUpdateEvent(response.data);
    });
  };

  const [showEndDateAndTime, setShowEndDateAndTime] = useState(false);
  const handleToggleEndDateAndTime = () => {
    setShowEndDateAndTime(!showEndDateAndTime);
  };

  useEffect(() => {
    getEvent(id);
  }, [id]);

  useEffect(() => {
    setShowEndDateAndTime(updateEvent.dateFin !== undefined);
  }, [updateEvent.dateFin]);

  const initialValues = {
    evaluation: updateEvent.evaluation,
  };

  //pour le payment
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (updateEvent.estGratuit !== isChecked) {
      setIsChecked(updateEvent.estGratuit);
    }
  }, [updateEvent.estGratuit]);

  //dialog
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [handleClick, setHandleClick] = useState(false);

  const updatedEvent = (id) => {
    if (updateEvent.evaluation !== undefined) {
      api
        .put(`update-event/${id}`, {
          statut: "Terminer",
          evaluation: updateEvent.evaluation,
        })
        .then((res) => {
          navigate("/events/kanbanEvents");
          Swal.fire({
            title: "Succès",
            text: "La mise à jour de l'évaluation d'évènement réussie",
            icon: "success",
            confirmButtonText: "ok",
          });
        })
    } else {
      Swal.fire({
        title: "Warning",
        text: "Ajouter l'évaluation de la demande d'évènement.",
        icon: "warning",
        confirmButtonText: "ok",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ handleSubmit, handleChange, handleBlur }) => (
          <Form onSubmit={handleSubmit} style={{ marginTop: "8px" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="h5" sx={{ flexGrow: "1" }}>Editer Event</Typography>
              <SoftButton
                type="submit"
                variant="gradient"
                color="purple"
                onClick={() => {
                  updatedEvent(id);
                  setHandleClick(true);
                }}
              >
                Modifier
              </SoftButton>
            </Box>
            <Card
              sx={{
                border: `1px solid ${grey[200]}`,
                bgcolor: "white",
                borderRadius: "15px",
                p: "20px",
                mt: "16px",
                overflow: "visible",
              }}
            >
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${updateEvent.image}`}
                alt="image évènement"
                style={{ display: "block", margin: "auto", width: "400px", height: "250px" }}
              />
              <Grid container spacing={2}>

              {updateEvent.descriptionDemande !== "undefined" &&
                <Grid item xs={12}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Description demande
                  </SoftTypography>

                  <SoftInput
                    disabled
                    multiline
                    rows={3}
                    variant="standard"
                    value={updateEvent.descriptionDemande}
                    success={updateEvent.descriptionDemande?.length >0}
                  />
                </Grid>
              }

                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: "16px" }}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Evaluation
                    </SoftTypography>
                    <SoftSelect
                      options={SelectData.evaluation.map((evaluation) => {
                        return {
                          value: evaluation.value,
                          label: evaluation.label,
                        };
                      })}
                      value={
                        updateEvent.evaluation !== undefined
                          ? {
                              value: updateEvent.evaluation,
                              label: updateEvent.evaluation,
                            }
                          : null
                      }
                      size="medium"
                      placeholder="Evaluation de l'évènement"
                      onChange={(event) => {
                        setUpdateEvent({ ...updateEvent, evaluation: event.value });
                      }}
                    />

                    {updateEvent.evaluation === undefined && handleClick === true && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "400", fontSize: "12px" }}
                        color="error"
                      >
                        Entrez evaluation de l&apos;évènement
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Nom de l&apos;événement*
                  </SoftTypography>
                  <Field name="nom">
                    {({ _ }) => (
                      <SoftInput
                        value={updateEvent.nom}
                        success={updateEvent.nom !== ""}
                        disabled
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Catégorie de l&apos;événement*
                  </SoftTypography>
                  <Field name="categorie">
                    {({ _ }) => (
                      <SoftInput
                        value={updateEvent.categorie}
                        success={updateEvent.categorie !== ""}
                        disabled
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Date de Début*
                  </SoftTypography>
                  <Field name="dateDebut">
                    {({ _ }) => (
                      <SoftInput
                        value={moment(updateEvent.dateDebut).format("DD/MM/YYYY")}
                        success={updateEvent.dateDebut !== ""}
                        disabled
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Heure de Début*
                  </SoftTypography>
                  <Field name="heureDebut">
                    {({ _ }) => (
                      <SoftInput
                        value={updateEvent.heureDebut}
                        success={updateEvent.heureDebut !== ""}
                        disabled
                      />
                    )}
                  </Field>
                </Grid>
                {updateEvent.dateFin !== undefined && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Date de Fin*
                      </SoftTypography>
                      <Field name="dateFin">
                        {({ _ }) => (
                          <SoftInput
                            value={moment(updateEvent.dateFin).format("DD/MM/YYYY")}
                            success={updateEvent.dateFin !== ""}
                            disabled
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Heure de Fin*
                      </SoftTypography>
                      <Field name="heureFin">
                        {({ field }) => (
                          <SoftInput
                            value={updateEvent.heureFin}
                            success={updateEvent.heureFin !== ""}
                            disabled
                          />
                        )}
                      </Field>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6} mt={2}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Lieu de l&apos;événement*
                  </SoftTypography>
                  <Field name="lieu">
                    {({ _ }) => (
                      <SoftInput
                        value={updateEvent.lieu}
                        success={updateEvent.lieu !== ""}
                        disabled
                      />
                    )}
                  </Field>
                </Grid>
                {updateEvent.lieu === "En présentiel" && (
                  <Grid item xs={12} sm={6} mt={2}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Lieu précis de l&apos;événement*
                    </SoftTypography>
                    <Field name="lieuPrecis">
                      {({ _ }) => (
                        <SoftInput
                          value={updateEvent.lieuPrecis}
                          success={updateEvent.lieuPrecis !== ""}
                          disabled
                        />
                      )}
                    </Field>
                  </Grid>
                )}
                {updateEvent.lieu === "En ligne" && (
                  <Grid item xs={12} sm={6} mt={2}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Lien de l&apos;événement*
                    </SoftTypography>
                    <Field name="lien">
                      {({ _ }) => (
                        <SoftInput
                          value={updateEvent.lien}
                          success={updateEvent.lien !== ""}
                          disabled
                        />
                      )}
                    </Field>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} mt={2}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Capaciter des personnes dans l&apos;événement*
                    </SoftTypography>
                        <SoftInput
                        value={updateEvent.capacite}
                        success={updateEvent.capacite !== 0}
                        disabled
                        />
                  </Grid>
                <Grid item xs={12} sm={12}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Ajouter des organisateurs
                  </SoftTypography>
                  <SoftInput
                    placeholder={
                      updateEvent.coOrganisateurs?.length === 0
                        ? "Aucune coOrganisteur choisi"
                        : null
                    }
                    isMulti
                    value={updateEvent.coOrganisateurs}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    description de l&apos;événement*
                  </SoftTypography>
                  <TextEditor value={updateEvent.description} readOnly={true} />
                </Grid>
                <Grid item xs={6} display="flex">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5, mr: { xs: "100px", md: "200px" } }}
                  >
                    Événement :
                  </SoftTypography>
                  <FormControlLabel control={<Checkbox checked={!isChecked} />} label="Payant" />
                </Grid>
                {!isChecked && (
                  <Grid item xs={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Prix participation :
                    </SoftTypography>
                    <Field name="prixParticipant">
                      {({ _ }) => (
                        <SoftInput
                          value={updateEvent.prix}
                          success={updateEvent.prix !== ""}
                          disabled
                        />
                      )}
                    </Field>
                  </Grid>
                )}
                {isChecked && <Grid item xs={6}></Grid>}
                <Grid item xs={12} sm={updateEvent.recurrenceEvent === "Jamais" ? 12 : 6}>
                  <SoftTypography variant="caption" color="text">
                    Récurrence de l&apos;événement
                  </SoftTypography>
                  <SoftInput
                    value={updateEvent.recurrenceEvent}
                    success={updateEvent.recurrenceEvent !== ""}
                    disabled
                  />
                </Grid>
                {updateEvent.recurrenceEvent === "Personnalisé" && (
                  <Grid item xs={12} sm={6} sx={{ display: "block" }}>
                    <Box ml={{ sx: "0px", md: "8px", position: "relative" }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Dates sélectionnées
                      </SoftTypography>
                      <Field name="datesSelectionner">
                        {({ _ }) => (
                          <SoftInput
                            isMulti
                            value={updateEvent.datesRecurrenceEvent}
                            success={updateEvent.datesRecurrenceEvent !== ""}
                            disabled
                          />
                        )}
                      </Field>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Card>
          </Form>
        )}
      </Formik>
    </DashboardLayout>
  );
}

export default UpdateEvent;
