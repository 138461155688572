import React, { useState, useRef, useEffect } from "react";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import { Avatar, Grid, Typography } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DeleteIcon from "@mui/icons-material/Delete"; // Import de l'icône de suppression

import { useTheme } from "@emotion/react";
import styles from "components/SoftSelect/styles";

import PropTypes from "prop-types";

function Image({ setImage, existingImageUrl }) {
  // Déclaration des états pour la gestion du croppage de l'image
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const [isHovered, setIsHovered] = useState(false);
  const [logo, setlogo] = useState(null);
  const [logoUpdate, setlogoUpdate] = useState(null);

  useEffect(() => {
    if (existingImageUrl) {
      setImageSrc(existingImageUrl);
    }
  }, [existingImageUrl]);

  // Fonction pour gérer le changement de fichier image
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const handleDeletelogo = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setImageSrc(null);
    setlogo(null);
    setImage("");
  };

  const fileInputRef = useRef(null);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImage(file);
      let imageDataUrl = await readFile(file);

      setImageSrc(imageDataUrl);
    }
  };

  const theme = useTheme();

  return (
    <SoftBox p={3}>
      {imageSrc ? (
        <Grid item xs={12} sm={13} container justifyContent="center">
          <SoftBox
            position="relative"
            height="max-content"
            mx="auto"
            sx={{
              cursor: "pointer",
              width: 750,
              height: 200,
              borderRadius: "16px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Cropper
              image={imageSrc}
              crop={crop}
              aspect={4 / 3}
              onCropChange={setCrop}
            />

            <SoftBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
              <SoftButton
                id="btn-delete"
                onClick={handleDeletelogo}
                variant="gradient"
                color="error"
                size="small"
                iconOnly
              >
                <DeleteIcon />
              </SoftButton>
            </SoftBox>
          </SoftBox>
          
        </Grid>
      ) : (
        <Grid item xs={12} sm={13} container justifyContent="center">
          <SoftBox position="relative" height="max-content" mx="auto">
            <Avatar
              name="image"
              id="img-profile"
              onClick={() => fileInputRef.current.click()}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              sx={{
                filter: isHovered ? "blur(2px)" : "none",
                cursor: "pointer",
                width: { xs: "100%", md: 750 },
                height: 200,
                borderRadius: "16px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src=""
              alt="title"
              size="xl"
              variant="rounded"
            >
              Télécharger Image
            </Avatar>

            <SoftBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
              <SoftButton
                id="btn-delete"
                onClick={handleDeletelogo}
                variant="gradient"
                color="error"
                size="small"
                iconOnly
              >
                <DeleteIcon />
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Grid>
      )}
      <input
        name="image"
        id="img"
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={onFileChange}
        accept=".jpg, .jpeg, .png, .gif .jfif"
      />
    </SoftBox>
  );
}

Image.propTypes = {
  setImage: PropTypes.func,
  existingImageUrl: PropTypes.string,
};

export default Image;
