import Stack from "@mui/material/Stack";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Checkbox,
  CircularProgress,
  Slide,
  AppBar,
  Tab,
  Tabs,
} from "@mui/material";
import Presence from "./Presence";
import api from "utils/axios";
import { useParams } from "react-router-dom";
import DataTable from "examples/Tables/DataTable";
import SoftTypography from "components/SoftTypography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PresenceActions from "./PresenceActions";
import { useSoftUIController } from "context";
function FichePresence({ classe }) {
  const { id } = useParams();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [historique, setHistorique] = useState([]);
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [loading, setLoading] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const myRef = useRef(null);
  const [controller, dispatch] = useSoftUIController();
 const user = controller.user.info;
  const facility = JSON.parse(localStorage.getItem("facility"));
  const hasEditDeletePermissionBtn =
  user?.role === 'company-admin' ||
  user?.role === 'instructor' ||
  user?.role === 'admin' ||
  user?.role === 'editor';
  const hasEditDeletePermission =
  user?.role === 'instructor'; 
   const [tabValue, setTabValue] = useState(user?.role === 'instructor' ? "Prèsence":"Historique");
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleFetch = async () => {
    try {
      setLoading(true);
      let endpoint;
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/classes/${id}/students`;
      } else {
        endpoint = `/faculty/classes/${id}/students`;
      }
      const response = await api.get(endpoint);
    // Assuming response?.data?.students is an array of student objects
    const studentsWithStatus = response?.data?.students?.map(student => ({
      ...student,
      statusP: "present"
    }));
    setData(studentsWithStatus);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const handleFetchHistorique = async () => {
    try {
      setLoading(true);
      let endpoint;
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/attendance-by-class/${id}/${user._id}`;
      } else {
        endpoint = `/faculty/attendance-by-class/${id}/${user._id}`;
      }
      const response = await api.get(endpoint);
      setHistorique( response.data);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetch();
    handleFetchHistorique()
  }, [id]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleStatusPChange = (studentId) => {
    setData(prevData =>
      prevData.map(student =>
        student._id === studentId ? { ...student, statusP : student.statusP === "present" ? "absent" : "present" } : student
      )
    );
  };
  const studentStatusArray = data?.map(student => ({
    student: student._id,
    presence: student.statusP
  }));
  return (
    <SoftBox>
      <>
        <Grid>
          <SoftBox mb={1}>
            <Grid container>
              <Grid item xs={12} sm={6} lg={3}>
                <AppBar position="static">
                  <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                    {hasEditDeletePermission&&(<Tab id="Prèsence" value="Prèsence" label="Prèsence" />)}
                    <Tab id="Historique" value="Historique" label="Historique" />
                  </Tabs>
                </AppBar>
              </Grid>
            </Grid>
            <Grid marginLeft={172}></Grid>
          </SoftBox>
        </Grid>
        {loading ? (
          <SoftBox sx={{ mt: 13, textAlign: "center" }}>
            <CircularProgress />
          </SoftBox>
        ) : tabValue === "Historique" ? (
          <div>
            <Slide direction="left" in={!showDetails} mountOnEnter unmountOnExit>
              <div ref={myRef}>
                {!showDetails && (
                  <DataTable
                    table={{
                      columns: [
                        { Header: "Nom et Prènom ", accessor: "fullName", width: "15%" },
                        { Header: "Telephone", accessor: "tel", width: "15%" },
                        { Header: "Matière", accessor: "subjectName", width: "15%" },
                        { Header: "date/heure", accessor: "dateheure", width: "20%" }, 
                        { Header: "action", accessor: "action", width: "10%" },

                      ],
                      rows:
                      historique &&
                        historique?.map((presence,index) => ({
                          fullName: `${presence?.instructor?.firstName} ${presence?.instructor?.lastName}`,
                          tel: presence?.instructor?.phoneNumber ? `+${ presence?.instructor?.phoneNumber}` : "",
                          subjectName:facility?.type === "centreDeFormation" ? presence?.subjectId?.name : `${presence?.subjectId?.type} ${presence?.subjectId?.subject?.name}`,
                          dateheure: `${presence?.sessionDate}/${presence?.startDate}`,
                          action: (
                            <PresenceActions
                            index={index}
                              key={presence?._id}
                              presence={presence}
                              setDetailsData={setDetailsData}
                              setShowDetails={setShowDetails}
                              handleFetch={handleFetch}
                              handleFetchHistorique={handleFetchHistorique}
                            />
                          ),
                        })),
                    }}
                    entriesPerPage={
                      afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false
                    }
                    canSearch={afficheFilter}
                  />
                )}
              </div>
            </Slide>
            {showDetails && (
              <>
                <ArrowBackIcon
                  onClick={() => setShowDetails(false)}
                  sx={{ cursor: "pointer", lineHeight: 0 }}
                />
                <Slide direction="left" in={showDetails} mountOnEnter unmountOnExit>
                  <div>
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Nom et Prènom ", accessor: "fullName", width: "25%" },
                          { Header: "Telephone", accessor: "tel", width: "25%" },
                          { Header: "Statut", accessor: "statut", width: "25%" },
                         // { Header: "action", accessor: "action", width: "10%" },
                        ],
                        rows: detailsData
                          ? detailsData?.students?.map((p) => ({                            
                            fullName: `${p?.student?.firstName} ${p?.student?.lastName}`,
                            tel: p?.student?.phoneNumber ? `+${p?.student?.phoneNumber}` : "",
                            statut: p?.presence,                  
                            }))
                          : [],
                      }}
                      entriesPerPage={
                        afficheFilter ? { defaultValue: 10, entries: [5, 7, 10, 15, 20, 25] } : false
                      }
                      canSearch={afficheFilter}
                    />
                  </div>
                </Slide>
              </>
            )}
          </div>
        ) : (
          <div>
            <Slide direction="left" in={!showDetails} mountOnEnter unmountOnExit>
              <div ref={myRef}>
                {!showDetails && (
                  <DataTable
                    table={{
                      columns: [
                        { Header: "Nom et Prènom ", accessor: "fullName", width: "15%" },
                        { Header: "Telephone", accessor: "tel", width: "15%" },
                        { Header: "Statut", accessor: "statut", width: "15%" },
                        { Header: "action", accessor: "action", width: "10%" },
                      ],
                      rows:
                        data &&
                        data?.map((student,index) => ({
                          fullName: `${student.firstName} ${student.lastName}`,
                          tel: student?.phoneNumber ? `+${student?.phoneNumber}` : "",
                          statut: student?.statusP,
                          action: (
                            <SoftBox display="flex" alignItems="center">
                              <SoftBox>
                                <SoftTypography
                                  variant="body1"
                                  color="purple"
                                  sx={{ cursor: "pointer", lineHeight: 0 }}
                                >
                                  <Checkbox
                                  id={`btn-check-presence-${index}`}
                                    checked={student?.statusP==="absent"}
                                    onChange={() => handleStatusPChange(student._id)}
                                    color="primary"
                                  />
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          ),
                        })),
                    }}
                    entriesPerPage={
                      afficheFilter ? { defaultValue: 10, entries: [5, 7, 10, 15, 20, 25] } : false
                    }
                    canSearch={afficheFilter}
                  />
                )}
              </div>
            </Slide>
          </div>
        )}
        {tabValue === "Prèsence" && (
        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
        <Stack spacing={1} direction="row" sx={{ marginLeft: 'auto' }}>
          <SoftButton id="btn-suivant" variant="gradient" color="purple" size="small" onClick={handleOpenModal}>
            Suivant
          </SoftButton>
          {open && <Presence studentStatusArray={studentStatusArray} handleFetch={handleFetch} classe={classe} open={open} handleCloseModal={handleCloseModal} handleFetchHistorique={handleFetchHistorique}/>}
        </Stack>
      </SoftBox>
        )}
      </>
    </SoftBox>
  );
}
FichePresence.propTypes = {
  classe: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
export default FichePresence;
