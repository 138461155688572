import React, { useEffect, useState } from "react";
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import api from "utils/axios";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSoftUIController } from "context";
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
function SubjectActionsCabinet({
  subject,
  handleFetch,
  setShowDetails,
  setDetailsData,
  instructor,
}) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(subject.name);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageInstructor, setErrorMessageInstructor] = useState("");
  const [controller] = useSoftUIController();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState(subject.instructors);
  const [leftSearchQuery, setLeftSearchQuery] = useState("");
  const [rightSearchQuery, setRightSearchQuery] = useState("");
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const facility = JSON.parse(localStorage.getItem("facility"));
  // Fonction pour comparer les objets
  function comparerObjets(objet1, objet2) {
    return objet1._id === objet2._id;
  }
  useEffect(() => {
      setRight(subject.instructors);
        setLeft(
          instructor.filter((objet1) => !right.some((objet2) => comparerObjets(objet1, objet2)))
        );
  }, []);
  const instructorIds=right?.map((r) => r._id);

  const handleUpdate = async () => {
    try {
      setLoading(true);
      if (name.length < 2) {
        // Set the error message
        setErrorMessage("La longueur minimale requise est de 2 caractères.");
      } else if (instructorIds?.length === 0) {
        // Set the error message
        setErrorMessageInstructor("Sélectionner au moins un enseignant.");
      } else {
        const response = await api.put(`training-company/update-subject/${subject._id}`, { name,instructors:instructorIds });
        setName(response.data.name);
        Swal.fire("Success!", "Mise à jour matière réussie", "success");
        handleFetch();
        setOpen(false);
      }
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setName(subject.name);
    setRight(subject.instructors);
      setLeft(
        instructor.filter((objet1) => !right.some((objet2) => comparerObjets(objet1, objet2)))
      );
  };

  const showAlert = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous voulez supprimer cette matière ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            let endpoint;
            if (facility?.type === "centreDeFormation") {
              endpoint = `training-company/delete-subject/${subject._id}`;
            } else {
              endpoint = `/faculty/delete-subject/${subject._id}`;
            }
            const response = await api.delete(endpoint);
            if (response) {
              (await Swal.fire("Suppression !", "La matière a été supprimée.", "success"))
                .isConfirmed && handleFetch();
            }
          } catch (error) {
            Swal.fire("Erreur!", "La matière n'a pas été supprimée.", "error");
          }
        }
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const handleSearch = (e, side) => {
    const { value } = e.target;
    if (side === "left") {
      setLeftSearchQuery(value);
    } else {
      setRightSearchQuery(value);
    }
  };

  const filterItems = (items, query) => {
    return items.filter(
      (item) =>
        item.firstName.toLowerCase().includes(query.toLowerCase()) ||
        item.lastName.toLowerCase().includes(query.toLowerCase())
    );
  };

  const customList = (items, side) => (
    <Paper sx={{ width: 250, height: 230, overflow: "auto", marginTop: "10px" }}>
      <Grid item xs={12} md={12} lg={12}>
        <SoftBox p={1}>
          <SoftBox>
            <SoftInput
              size="medium"
              icon={{ component: "search", direction: "left" }}
              placeholder="Rechercher..."
              value={side === "left" ? leftSearchQuery : rightSearchQuery}
              onChange={(e) => handleSearch(e, side)}
            />
          </SoftBox>
        </SoftBox>
      </Grid>
      <List dense component="div" role="list">
        {filterItems(items, side === "left" ? leftSearchQuery : rightSearchQuery).map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItemButton key={value} role="listitem" onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.firstName} ${value.lastName}`} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <SoftBox display="flex" alignItems="center">
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Modifier" placement="top">
            <Icon onClick={() => setOpen(true)} id="edit-enterprise-page">
              edit
            </Icon>
          </Tooltip>
          <Dialog fullWidth maxWidth="md" open={open} onKeyDown={handleKeyPress}>
            <DialogTitle>Modifier matière</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.grey[200],
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <DialogContentText>
                Vous devez saisir le nouveau nom de votre matière.
              </DialogContentText>
              <Grid>
                <SoftTypography variant="caption" fontWeight="bold" color="text">
                  Nom du matière*
                </SoftTypography>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <SoftInput
                    name="name"
                    margin="normal"
                    id="name"
                    label="matière"
                    type="text"
                    fullWidth
                    placeholder="Nom du matière"
                    inputProps={{
                      minLength: 2,
                      maxLength: 50,
                    }}
                    variant="standard"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={name.length < 2}
                    success={name.length >= 2}
                    autoFocus
                  />
                  {name.length < 2 && (
                    <SoftTypography m={0.75} variant="caption" color="error">
                      {errorMessage}
                    </SoftTypography>
                  )}
                </Grid>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mt: 3 }}
                >
                  Nom des enseignants*
                </SoftTypography>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                  <Grid item>{customList(left, "left")}</Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                      >
                        &gt;
                      </Button>
                      <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                      >
                        &lt;
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>{customList(right, "right")}</Grid>
                </Grid>
                {instructorIds.length < 1 && (
                    <SoftTypography m={0.75} variant="caption" color="error">
                      {errorMessageInstructor}
                    </SoftTypography>
                  )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                <SoftButton
                  onClick={handleClose}
                  sx={{ mt: 2 }}
                  size="small"
                  variant="outlined"
                  color="dark"
                >
                  Annuler
                </SoftButton>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <SoftButton
                    onClick={handleUpdate}
                    sx={{ mt: 2 }}
                    variant="gradient"
                    color="customized"
                    size="small"
                  >
                    Modifier
                  </SoftButton>
                )}
              </SoftBox>
            </DialogActions>
          </Dialog>
        </SoftTypography>
      </SoftBox>
      {controller?.user?.info?.role === "company-admin" && (
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Supprimer" placement="left">
            <Icon id="delete-enterprise" onClick={showAlert}>
              delete
            </Icon>
          </Tooltip>
        </SoftTypography>
      )}
    </SoftBox>
  );
}
SubjectActionsCabinet.propTypes = {
  subject: PropTypes.object.isRequired,
  setDetailsData: PropTypes.array.isRequired,
  setShowDetails: PropTypes.bool.isRequired,
  handleFetch: PropTypes.func.isRequired,
  instructor: PropTypes.array.isRequired,
};

export default SubjectActionsCabinet;
