import { Close } from "@mui/icons-material";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import SoftSelect from "components/SoftSelect";
import SoftTypography from 'components/SoftTypography';

import PropTypes from "prop-types";
import SelectData from "./data/SelectData";
import SoftInput from "components/SoftInput";
import { Field, Form, Formik } from "formik";
import validationSchema from "./validerChamps/ValidationSchema";
import { useEffect, useState } from "react";
import SoftButton from "components/SoftButton";
import api from "utils/axios";
import Swal from "sweetalert2";
import { useTheme } from "@emotion/react";
import { CircularProgress} from "@mui/material";

function UpdateDemande({ open, handleCloseModal, demandeId, handleDemandes }) {
  const theme = useTheme();

  const [demande, setDemande] = useState({});
  const handleDemande = (demandeId) => {
    api.get(`get-demande/${demandeId}`).then((res) => {
      setDemande(res.data);
    });
  };

  useEffect(() => {
    if (demandeId) {
      handleDemande(demandeId);
    }
  }, [demandeId]);

  const [userRole , setUserRole] = useState("")
  const [userFullName, setUserFullName] = useState("");
  const [niveauName , setNiveauName] = useState('');
  const [filiereName , setFiliereName] = useState('');
  const handleUser = (id) => {
    api.get(`get-user/${id}`).then((res) => {
      setUserFullName(res.data.firstName + " " + res.data.lastName);
      setUserRole(res.data.role);
      if(res.data.role === "student"){
        setNiveauName(res.data.parcour.niveau.name);
        api.get(`faculty/one-filiere/${res.data.parcour.niveau.filiere}`).then((res)=>{
          setFiliereName(res.data.name);
        });
      }
    });
  };

  useEffect(() => {
    if (demande.userId) {
      handleUser(demande.userId);
    }
  }, [demande.userId]);

  const initialValues = {
    type: demande.type,
    description: demande.description,
    evaluation: demande.evaluation,
  };


  const updateDemandeDocument = (demandeId) => {
    const updateDemande = {
      type: demande.type,
      description: demande.description,
      evaluation: demande.evaluation,
      reponse: demande.reponse,
      statut: "Terminer",
    };
    if (demande.evaluation !== undefined) {
      api
        .put(`update-demande/${demandeId}`, updateDemande)
        .then((res) => {
          handleDemandes();
          handleCloseModal();
          Swal.fire({
            title: "Succès",
            text: "La mise à jour de la demande de document a été effectuée avec succès",
            icon: "success",
            confirmButtonText: "ok",
          });
        })
    } else {
      Swal.fire({
        title: "Warning",
        text: "Ajouter l'évaluation de la demande",
        icon: "warning",
        confirmButtonText: "ok",
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      sx={{
        "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
          bgcolor: "rgba(0,0,0,0.03) !important",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          height: userRole === "student" ? "96vh" : "70vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: "2px 2px 2px 2px grey",
          p: "24px 32px",
        }}
      >
        <Formik initialValues={initialValues} validationSchema={validationSchema}>
          {({ errors,touched, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
            <Form onSubmit={handleSubmit} style={{ marginTop: "8px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="h5" sx={{ flexGrow: "1" }}>Editer Demande</Typography>
                
                <IconButton onClick={handleCloseModal}>
                  <Close
                    fontSize="24px !important"
                    sx={{
                      "&:hover": {
                        bgcolor: grey[100],
                        borderRadius: "50%",
                      },
                    }}
                  />
                </IconButton>
              </Box>

              {userRole ==="student" &&
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ mr: { xs: "0px", md: "8px" }, mt: { xs: "16px", md: "0px" } }}>
                    <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >Nom et Prénom</SoftTypography>

                      <SoftInput
                        variant="outlined"
                        value={userFullName}
                        disabled
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ ml: { xs: "0px", md: "8px" }, mt: { xs: "16px", md: "0px" } }}>
                    <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >Filière Etudiant</SoftTypography> 

                      <SoftInput
                        variant="outlined"
                        value={filiereName}
                        disabled
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ mr: { xs: "0px", md: "8px" }, mt:"16px" }}>
                    <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >Niveau Etudiant</SoftTypography>

                      <SoftInput
                        variant="outlined"
                        value={niveauName}
                        disabled
                      />
                    </Box>
                  </Grid>
                </Grid>
              }

              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mr: { xs: "0px", md: "8px" }, mt: "16px" }}>
                  <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mb: 0.5 }}
                >Type</SoftTypography>

                    <Field name="type">
                      {({ field }) => (
                        <SoftInput value={demande.type} success={demande.type !== ""} disabled />
                      )}
                    </Field>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ ml: { xs: "0px", md: "8px" }, mt: "16px" }}>
                  <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >Évaluation*</SoftTypography>
                      <Field name="evaluation">
                      {({ _ }) => (
                        <SoftSelect
                        options={SelectData.evaluation.map((evaluation) => {
                          return {
                            value: evaluation.value,
                            label: evaluation.label,
                          };
                        })}
                        value={
                          demande.evaluation !== undefined
                            ? {
                                value: demande.evaluation,
                                label: demande.evaluation,
                              }
                            : null
                        }
                        onBlur={handleBlur}
                        size="medium"
                        placeholder="Choisir l'évaluation de demande"
                        onChange={(event) => {
                          setDemande({ ...demande, evaluation: event.value });
                        }}
                      />
                      )}
                    </Field>
                    

                    {demande.evaluation === undefined && !!touched.evaluation  && (
                     <Typography
                        color="error"
                        variant="body2"
                        sx={{ fontWeight: "400", fontSize: "12px" }}
                      >
                        Entrez l&apos;évaluation de demande
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Box sx={{ mt: "16px", width: "100%" }}>
              <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >Description</SoftTypography>
                <Field name="description">
                  {({ field }) => (
                    <SoftInput
                      {...field}
                      multiline
                      rows={3}
                      variant="outlined"
                      error={demande.description?.length < 10}
                      success={demande.description?.length >= 10}
                      onBlur={handleBlur}
                      disabled
                      value={demande.description}
                      onChange={(event) => {
                        handleChange(event);
                        setDemande({ ...demande, description: event.target.value });
                        setFieldValue("description", event.target.value);
                      }}
                    />
                  )}
                </Field>
                {demande.description?.length < 10 && (
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "400", fontSize: "12px" }}
                    color="error"
                  >
                    {errors.description}
                  </Typography>
                )}
              </Box>

              <Box sx={{ mt: "16px", width: "100%" }}>
              <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >réponse</SoftTypography>
                <SoftInput
                  multiline
                  rows={3}
                  placeholder="Reponse"
                  variant="outlined"
                  value={demande.reponse}
                  onChange={(event) => {
                    setDemande({ ...demande, reponse: event.target.value });
                  }}
                />
              </Box>

              <Box
                sx={{
                  mt: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SoftButton
                   sx={{ mt: 2 }}
                   variant="outlined"
                   color="dark"
                   size="small"
                  onClick={handleCloseModal}
                >
                  Annuler
                </SoftButton>
                <SoftButton
                  type='submit'
                 sx={{ mt: 2 }}
                 variant="gradient"
                 color="purple"
                 size="small"
                  onClick={() => {
                    updateDemandeDocument(demandeId);
                  }}
                >
                  Modifier
                </SoftButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

UpdateDemande.propTypes = {
  open: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  demandeId: PropTypes.string,
  handleDemandes: PropTypes.func,
};

export default UpdateDemande;
