const SelectInfo = {
  statut: [
    { value: "A faire", label: "A faire" },
    { value: "En cours", label: "En cours" },
    { value: "Terminer", label: "Terminer" },
  ],
  priorite: [
    { value: "Haute", label: "Haute" },
    { value: "Moyenne", label: "Moyenne" },
    { value: "Faible", label: "Faible" },
  ],
};
export default SelectInfo;
