import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import "react-phone-input-2/lib/style.css";

import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SoftButton from "components/SoftButton";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import api from "utils/axios";
import EnterpriseFormField from "pages/enterprise/schema/enterprise-schemas/EnterpriseFormField";
import { ErrorMessage, Formik } from "formik";
import validations from "../../schemas/validations";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SoftInput from "components/SoftInput";

function ClassInfo({ classe }) {
  if (!classe) return null;
  const [loading, setLoading] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(classe?.parcour?.niveau?._id);
  const [optionsLevels, setOptionsLevels] = useState([]);
  const [optionsStudents, setOptionsStudents] = useState([]);
  const [selectedFiliere, setSelectedFiliere] = useState(classe?.parcour?.niveau?.filiere);
  const [selectedParcour, setSelectedParcour] = useState(classe?.parcour?._id);
  const [selectedPeriode, setSelectedPeriode] = useState(classe?.periode);
  const [optionsFilieres, setOptionsFilieres] = useState([]);
  const [optionsLevel, setOptionsLevel] = useState([]);
  const [optionsParcours, setOptionsParcours] = useState([]);
  const [optionsPeriodes, setOptionsPeriodes] = useState([]);
  const [error, setError] = useState("");
  const [errorMessageParcours, setErrorMessageParcours] = useState("");
  const [errorMessageLevel, setErrorMessageLevel] = useState("");
  const facility = JSON.parse(localStorage.getItem("facility"));
  const navigate = useNavigate();
  
  useEffect(() => {
    if (classe) {
      setSelectedLevel(classe?.parcour?.niveau?._id);
      setSelectedParcour(classe?.parcour?._id);
      setSelectedFiliere(classe?.parcour?.niveau?.filiere);
      setSelectedPeriode(classe?.periode);
    }
  }, [classe]);
  useEffect(() => {
    api.get(`/faculty/all-filiere/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsFilieres(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
          niveaux: item.niveaux,
        }))
      );
    });
    if (selectedFiliere) {
      api
        .get(`/faculty/get-levels-by-filiere/${selectedFiliere}`)
        .then((res) => {
          setOptionsLevel(
            res?.data?.map((item) => ({
              value: item._id,
              label: item.name,
            }))
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setError("Resource not found:", error.response.data);
            // Handle 404 error gracefully (e.g., display a message to the user)
          } else {
            setError("API request failed:", error.message);
            // Handle other errors
          }
        });
    }
    if (selectedLevel) {
      api
        .get(`/faculty/get-parcours-by-level/${selectedLevel}`)
        .then((res) => {
          setOptionsParcours(
            res?.data?.map((item) => ({
              value: item._id,
              label: item.name,
            }))
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setError("Resource not found:", error.response.data);
            // Handle 404 error gracefully (e.g., display a message to the user)
          } else {
            setError("API request failed:", error.message);
            // Handle other errors
          }
        });
    }
    api
      .get(`/faculty/all-periodes/${facility.scholarityConfigId}`)
      .then((res) => {
        setOptionsPeriodes(
          res.data.map((item) => ({
            value: item._id,
            label: item.name,
          }))
        );
      })
      .catch((err) => {
        throw err;
      });
  }, [selectedFiliere, selectedLevel, selectedParcour]);

  const initialValues = {
    name: classe.name || "",
    abilityMaximum: classe.abilityMaximum || "",
    groupNumber: classe.groupNumber || "",
  };

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      if (selectedFiliere !== null && selectedLevel === null) {
        setErrorMessageLevel("le niveau doit être sélectionné.");
      } else if (selectedFiliere !== null && selectedLevel !== null && selectedParcour === null) {
        setErrorMessageParcours("le parcours doit être sélectionné.");
      } else if (
        selectedFiliere !== null &&
        selectedLevel !== null &&
        selectedParcour !== null &&
        selectedPeriode === null
      ) {
        setErrorMessageSousPeriode("la periode doit être sélectionné.");
      } else {
        const response = await api.patch(`/faculty/update-classe/${classe._id}`, {
          name: values.name,
          abilityMaximum: values.abilityMaximum,
          groupNumber: values.groupNumber,
          parcour: selectedParcour,
          periode: selectedPeriode,
        });
        if (response.status === 200) {
          Swal.fire("Succès !", "Mise à jour classe réussie", "success").then(() => {
            // setLoading(false);
            navigate("/classes");
          });
        }
      }
    } catch (error) {
      //setLoading(true);
      if (error.response && error.response.data && error.response.data.message) {
        Swal.fire("Error", error.response.data.message, "error");
        //.then(() => navigate(0));
      } else {
        Swal.fire(
          "Error",
          "Une erreur s'est produite. Veuillez réessayer ultérieurement.",
          "error"
        ).then(() => navigate(0));
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {Object.keys(classe).length > 0 &&
        (
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={validations[0]}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Card id="basic-info" sx={{ overflow: "visible" }}>
                  <SoftBox p={3}>
                    <SoftTypography variant="h5">Modifier classe</SoftTypography>
                  </SoftBox>
                  <SoftBox component="form" pb={3} px={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <EnterpriseFormField
                          id="name"
                          name="name"
                          defaultValue={values.name}
                          value={values.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Nom de classe*"
                          placeholder="Nom de classe"
                          inputProps={{
                            minLength: 2,
                            maxLength: 30,
                          }}
                          autoFocus
                          error={!!touched.name && !!errors.name}
                          success={values.name?.length > 0 && !errors.name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <EnterpriseFormField
                          id="abilityMaximum"
                          name="abilityMaximum"
                          defaultValue={values.abilityMaximum}
                          value={values.abilityMaximum}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Maximum De Capacité*"
                          placeholder="Maximum De Capacité"
                          inputProps={{
                            minLength: 2,
                            maxLength: 30,
                          }}
                          error={!!touched.abilityMaximum && !!errors.abilityMaximum}
                          success={values.abilityMaximum > 0 && !errors.abilityMaximum}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <EnterpriseFormField
                          id="nbreGroupe"
                          name="groupNumber"
                          defaultValue={values.groupNumber}
                          value={values.groupNumber}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="nombre des groupes"
                          placeholder="nombre des groupes"
                          inputProps={{
                            minLength: 2,
                            maxLength: 30,
                          }}
                          error={!!touched.groupNumber && !!errors.groupNumber}
                          success={values.groupNumber >= 1 && !errors.groupNumber}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                          sx={{ mb: 0.5 }}
                        >
                          Filière*
                        </SoftTypography>
                        <Autocomplete
                          id="niveau"
                          options={optionsFilieres}
                          title="Select filiere"
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          required={true}
                          aria-required={true}
                          value={
                            optionsFilieres.find((option) => option.value === selectedFiliere) ||
                            null
                          }
                          onChange={(e, newValue) => {
                            if (newValue !== null && newValue !== undefined) {
                              if (selectedFiliere !== "") {
                                setOptionsParcours([]);
                                //setSelectedParcours(null)
                                setSelectedLevel(null);
                                setSelectedParcour(null);
                                //setOptionsSousPeriode([]);
                                // setSelectedSousPeriode(null)
                                //setParcours("")
                                setOptionsLevel(
                                  newValue?.niveaux?.map((item) => ({
                                    value: item._id,
                                    label: item.name,
                                    parcours: item.parcours,
                                  }))
                                );
                              }
                              setSelectedFiliere(newValue.value);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required={true}
                              variant="standard"
                              placeholder="Tous les niveaux"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                          sx={{ mb: 0.5 }}
                        >
                          niveau*
                        </SoftTypography>
                        <Autocomplete
                          id="niveau"
                          options={optionsLevel}
                          title="Select niveau"
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          required={true}
                          aria-required={true}
                          value={
                            optionsLevel.find((option) => option.value === selectedLevel) || null
                          }
                          onChange={(e, newValue) => {
                            if (newValue !== null && newValue !== undefined) {
                              if (selectedLevel) {
                                setSelectedSemester(null);
                                setSelectedSousSemester(null);
                              }
                            }
                            setSelectedLevel(newValue.value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required={true}
                              variant="standard"
                              placeholder="Tous les niveaux"
                            />
                          )}
                        />
                        {selectedFiliere !== null && selectedLevel === null && (
                          <Typography mt={0.75} variant="caption" color="error">
                            {errorMessageLevel}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                          sx={{ mb: 0.5 }}
                        >
                          Parcour*
                        </SoftTypography>
                        <Autocomplete
                          id="niveau"
                          options={optionsParcours}
                          title="Select niveau"
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          required={true}
                          aria-required={true}
                          value={
                            optionsParcours.find((option) => option.value === selectedParcour) ||
                            null
                          }
                          onChange={(e, newValue) => {
                            if (newValue !== null && newValue !== undefined) {
                              if (selectedParcour) {
                                setSelectedSemester(null);
                                setSelectedSousSemester(null);
                              }
                              setSelectedParcour(newValue.value);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required={true}
                              value={selectedParcour}
                              variant="standard"
                              placeholder="Tous les niveaux"
                            />
                          )}
                        />
                        {selectedFiliere !== null &&
                          selectedLevel !== null &&
                          selectedParcour === null && (
                            <Typography mt={0.75} variant="caption" color="error">
                              {errorMessageParcours}
                            </Typography>
                          )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Périodes
                        </SoftTypography>
                        <Autocomplete
                          id="période"
                          options={optionsPeriodes}
                          title="période"
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          required={true}
                          aria-required={true}
                          value={
                            optionsPeriodes.find((option) => option.value === selectedPeriode) ||
                            null
                          }
                          onChange={(e, newValue) => {
                            if (newValue !== null && newValue !== undefined) {
                              if (selectedPeriode) {
                                setSelectedSousSemester(null);
                              }
                              setSelectedPeriode(newValue.value);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required={true}
                              variant="standard"
                              placeholder="Toutes les périodes"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <Grid
                    item
                    xs={12}
                    ml={3}
                    sx={{ mr: 2 }}
                    mb={2}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <SoftButton
                      onClick={() => navigate("/classes")}
                      color="dark"
                      variant="outlined"
                    >
                      Annuler
                    </SoftButton>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <SoftButton
                        type="submit"
                        sx={{ ml: 2 }}
                        color="customized"
                        variant="gradient"
                      >
                        Modifier
                      </SoftButton>
                    )}
                  </Grid>
                </Card>
              </form>
            )}
          </Formik>
        )}
    </>
  );
}
ClassInfo.propTypes = {
  //title: PropTypes.string.isRequired,
  classe: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ClassInfo;
