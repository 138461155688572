import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";

import api from "utils/axios";
import { useSoftUIController } from "context";
import { useSocket } from "context/SocketContext";
import CommentInput from "./CommentInput"; 

function Post() {
  const { borderWidth, borderColor } = borders;
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [content, setContent] = useState("");
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [controller] = useSoftUIController();
  const { socket } = useSocket();
  const [commentContents, setCommentContents] = useState({});
  const userId = controller?.user?.info?._id;

  function addDays(dateString, days) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + days);

    // Check if the resulting date is valid
    if (!isNaN(date.getTime())) {
      const formattedDate = date.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const formattedTime = date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
      return `${formattedDate} ${formattedTime}`;
    } else {
      console.error("Invalid date after adding days:", dateString, days);
      return null;
    }
  }
  function timeAgo(timestamp) {
    const currentDate = new Date();
    const previousDate = new Date(timestamp);

    const timeDifference = currentDate - previousDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days + "d ago";
    } else if (hours > 0) {
      return hours + "h ago";
    } else if (minutes > 0) {
      return minutes + "m ago";
    } else {
      return seconds + "s ago";
    }
  }
  const handleFetch = async () => {
    try {
      const response = await api.get(`/social-media/get-all-posts?facilityId=${facility?._id}`);
      setPosts(response.data.posts);
    } catch (error) {
      throw error
    }
  };

  const handleCommentUpdate = async (postId) => {
    try {
      // Fetch the updated post with comments
      const response = await api.get(`/social-media/post/${postId}`);
      const updatedPost = response.data.post;

      // Update the state with the new comment
      setPosts((prevPosts) => prevPosts.map((post) => (post._id === postId ? updatedPost : post)));
    } catch (error) {
      throw error
    }
  };

  // Function to update comment content for a specific post
  const updateCommentContent = (postId, newContent) => {
    setCommentContents((prevContents) => ({
      ...prevContents,
      [postId]: newContent,
    }));
  };

  // Function to add a comment for a specific post
  const addComment = async (postId) => {
    try {
      const content = commentContents[postId];

      if (content && content.trim() !== "") {
        const response = await api.post(`/social-media/post/add-comment`, {
          postId: postId,
          content: content,
        });

        if (response.status === 201) {
          socket.emit("comments", postId);
          updateCommentContent(postId, ""); // Clear the input
        }
      }
    } catch (error) {
      throw error
    }
  };

  useEffect(() => {
    if (socket) {
      // Listen for new comments from the server
      socket.on("new comments", (postId) => {
        // Update the state with the new comment
        handleCommentUpdate(postId);
      });
      socket.on("like", ({ postId, userId }) => {
        // Update the state to reflect the new like
        handleCommentUpdate(postId);
      });
    }
  }, [socket]);

  
  const addLike = async (postId) => {
    try {
      const response = await api.post(`/social-media/like/${postId}`);
      if (response.status === 201 || response.status === 200) {
        const likedPostIndex = posts.findIndex((post) => post._id === postId);
  
        if (likedPostIndex !== -1) {
          const isLikedByUser = posts[likedPostIndex].likes.includes(userId);
  
          // Toggle the like: add or remove based on the current state
          const updatedLikes = isLikedByUser
            ? posts[likedPostIndex].likes.filter((id) => id !== userId)
            : [...posts[likedPostIndex].likes, userId];
  
          // Update local state to reflect the new like status
          setPosts((prevPosts) =>
            prevPosts.map((post) =>
              post._id === postId ? { ...post, likes: updatedLikes } : post
            )
          );
  
          // Emit 'like' event to notify other clients
          socket.emit("like", { postId, userId: controller?.user?.info?._id });
        }
      }
    } catch (error) {
      throw error
    }
  };
  

  

  useEffect(() => {
    handleFetch();
  }, []);
  function isStringInArray(str, arr) {
    // Check if arr is defined and not null
    if (arr && Array.isArray(arr)) {
      return arr.includes(str);
    }
    // If arr is not defined or not an array, return false
    return false;
  }

  return (
    <>
      <>
        {posts?.map((post) => (
          <Card key={post?._id} style={{ marginBottom: "20px" }}>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection={{ xs: "column", sm: "row" }}
              borderBottom={`${borderWidth[1]} solid ${borderColor}`}
              py={2}
              px={3}
            >
              <SoftAvatar
                src={
                  post?.userInfo?.imageUrl?.startsWith("https")
                    ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                    : `${process.env.REACT_APP_BASE_URL}/${post?.userInfo?.imageUrl}`
                }
                alt="profile-image"
                variant="rounded"
              />
              <SoftBox mx={2} lineHeight={1}>
                <SoftTypography component="a" href="#" variant="button" fontWeight="regular">
                  {post?.userInfo?.firstName} {post?.userInfo?.lastName}
                </SoftTypography>
                <SoftTypography component="div" variant="button" color="text" fontWeight="regular">
                  {addDays(post?.createdAt, 1)}
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 2, sm: 0 }}>
                {controller?.user?.info?._id !== post?.userInfo?._id && (
                  <SoftButton variant="gradient" color="info" size="small">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; suivre
                  </SoftButton>
                )}
              </SoftBox>
            </SoftBox>
            <SoftBox p={3}>
              <SoftBox mb={3}>
                <SoftTypography variant="body2" color="text">
                  {post?.description}
                </SoftTypography>
              </SoftBox>
              {post?.image && (
                <SoftBox
                  component="img"
                  src={
                    post?.image && `${process.env.REACT_APP_BASE_URL}/social-media/${post?.image}`
                  }
                  shadow="lg"
                  borderRadius="xl"
                  width="100%"
                />
              )}
              <SoftBox mt={3} mb={1} px={1}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox display="flex" alignItems="center" mr={2}>
                        <SoftTypography
                          onClick={() => addLike(post?._id)}
                          variant="body2"
                          color="text"
                          style={{
                            color: isStringInArray(controller?.user?.info?._id, post?.likes)
                              ? "#7F47E6"
                              : "",
                          }}
                        >
                          <Icon>thumb_up</Icon>&nbsp;
                        </SoftTypography>

                        <SoftTypography variant="button" fontWeight="regular" color="text">
                          {post?.likes?.length}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" alignItems="center" mr={2}>
                        <SoftTypography component="a" href="#" variant="body2" color="text">
                          <Icon>mode_comment</Icon>&nbsp;
                        </SoftTypography>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                          {post?.comments?.length}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
                <Divider />
                {post?.comments?.map((comment) => (
                  <SoftBox key={comment?._id} display="flex" mt={3} alignItems="center">
                    <SoftBox flexShrink={0}>
                      <SoftAvatar
                        src={
                          comment?.userInfo?.imageUrl &&
                          `${process.env.REACT_APP_BASE_URL}/${comment?.userInfo?.imageUrl}`
                        }
                        alt={"image"}
                      />
                    </SoftBox>
                    <SoftBox flexGrow={1} ml={2}>
                      <SoftTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                        {comment?.userInfo?.firstName} {comment?.userInfo?.lastName}
                      </SoftTypography>
                      <SoftBox mt={1} mb={2} lineHeight={0.75}>
                        <SoftTypography variant="button" fontWeight="regular" color="text" mr={2}>
                          {comment?.content}
                        </SoftTypography>
                        <SoftTypography variant="button" fontWeight="regular" color="primary">
                          {timeAgo(comment?.createdAt)}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                ))}
                <SoftBox display="flex" alignItems="center" mt={3}>
                  <SoftBox flexShrink={0} mr={2}>
                    <SoftAvatar
                      src={
                        controller?.user?.info?.imageUrl?.startsWith("https")
                          ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                          : `${process.env.REACT_APP_BASE_URL}/${controller?.user?.info?.imageUrl}`
                      }
                      alt="profile picture"
                    />
                  </SoftBox>
                  <SoftBox flexGrow={1}>
                    <CommentInput
                      postId={post?._id}
                      content={commentContents[post?._id] || ""}
                      onContentChange={(newContent) => updateCommentContent(post?._id, newContent)}
                      onAddComment={() => addComment(post?._id)}
                    />
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        ))}
      </>
    </>
  );
}

export default Post;
