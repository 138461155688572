import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import { CircularProgress, Grid, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import api from 'utils/axios';
import Swal from "sweetalert2";
import SoftBox from "components/SoftBox";
import comparerSansEspacesEtCases from "utils/compareEspace";

export default function AddBloc({ handleFetch }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [inputs, setInputs] = useState([{ name: "" }]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setInputs([{ name: "" }]);
  };
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  };
  const handleAddInput = () => {
    setInputs([...inputs, { name: "" }]);
  };
  const handleRemoveInput = (indexToRemove) => {
    const newInputs = [...inputs];
    if (indexToRemove >= 0 && indexToRemove < newInputs.length) {
      // Remove the input at the specified index
      newInputs.splice(indexToRemove, 1);
      setInputs(newInputs);
    }
  }
  const handleAddBloc = async () => {
    try {
      setLoading(true);
      // Validate the inputs before submitting
      const hasInvalidInputs = inputs.some((input) => input.name.length < 2);
      if (hasInvalidInputs) {
        // Set the error message
        setErrorMessage("La longueur minimale requise est de 2 caractères.");
      } else {
        // Check for duplicate names
        const duplicateName = inputs.find(
          (input, index) =>
            index !== inputs.findIndex((otherInput) => comparerSansEspacesEtCases( otherInput.name,input.name))
        );
        if (duplicateName) {
          Swal.fire(
            "Erreur!",
            `Le nom du bloc existe déjà : ${duplicateName.name}. Merci de saisir un autre nom !`,
            "error"
          );
        } else {
          // Perform the API request only if there are no duplicate names
          await api.post(`/faculty/create-bloc?scholarityConfigId=${facility.scholarityConfigId}`, inputs);
          setOpen(true);
          Swal.fire("Success!", "Le bloc ajouté avec succès", "success").then(() => {
            setErrorMessage("");
            setInputs([{ name: "" }]);
            handleFetch();
            setOpen(false);
          });
        }
      }
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddBloc();
    }
  };
  return (
    <div>
      <SoftButton
        id="add" onClick={handleClickOpen} variant="gradient"
        color="purple"
        size="small"
        mb={{ xs: 2, sm: 0 }}
      >
        +Ajouter Bloc
      </SoftButton>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} onKeyDown={handleKeyPress}>
        <DialogTitle>Ajouter Bloc </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            Vous devez saisir le nom  du bloc afin de l&apos;ajouter.
          </DialogContentText>
          {inputs.map((input, index) => (
            <>
              <Grid key={index} sx={{ mt: 1 }}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mb: 0.5 }}
                >
                  Nom du bloc*
                </SoftTypography>
                <Grid sx={{ display: "flex", justifyContent: "space-around" }}>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    <SoftInput
                      name="name"
                      margin="normal"
                      id="name"
                      label="bloc"
                      type="text"
                      fullWidth
                      placeholder="Nom du bloc"
                      inputProps={{
                        minLength: 2,
                        maxLength: 70,
                      }}
                      autoFocus
                      variant="standard"
                      value={input.name}
                      onChange={(event) => handleInputChange(index, event)}
                      onBlur={() => {
                        // Update the error when the input loses focus
                        const newInputs = [...inputs];
                        newInputs[index].error = input.name.length < 2;
                        setInputs(newInputs);
                      }}
                      error={input.error && input.name.length < 2}
                      success={input.name.length >= 2}
                    />
                    {input.name.length < 2 && (
                      <Typography m={0.75} variant="caption" color="error">
                        {errorMessage}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={0.5}>
                    {inputs.length > 1 && (
                      <IconButton>
                        <RemoveCircleIcon
                          color="error"
                          fontSize="medium"
                          onClick={() => handleRemoveInput(index)}
                        />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))}
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <SoftButton
              size="small"
              sx={{
                mt: 2,
                borderWidth: "2px",
                borderStyle: "dashed solid",
                borderColor: "purple ",
              }}
              onClick={handleAddInput}
            >
              +ajouter plus
            </SoftButton>
          </Stack>
        </DialogContent>

        <DialogActions>
          <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
            <SoftButton
              onClick={handleClose}
              sx={{ mt: 2 }}
              variant="outlined"
              color="dark"
              size="small"
            >
              Annuler
            </SoftButton>
            {loading ? (
              <CircularProgress />
            ) : (
              <SoftButton
                onClick={handleAddBloc}
                sx={{ mt: 2 }}
                variant="gradient"
                color="purple"
                size="small"
              >
                Ajouter
              </SoftButton>
            )}
          </SoftBox>
        </DialogActions>
      </Dialog>
    </div>
  );
}
AddBloc.propTypes = {
  handleFetch: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
