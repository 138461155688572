import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import React, { useEffect, useState } from "react";
import EventCalendar from "../shared/Calendar";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import api from "utils/axios";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { pink } from "material-ui-colors";
import Swal from "sweetalert2";
import { Dialog } from "@mui/material";
import UpdateSeanceDialog from "./components/seance-components/UpdateSeanceDialog";

export default function UpdateEmploi() {
  const [open, setOpen] = useState(false);
  const [seances, setSeances] = useState([]);
  const [seance, setSeance] = useState("");
  const [optionsMatiere, setOptionsMatiere] = useState([]);
  const [optionsEnseignant, setOptionsEnseignant] = useState([]);
  const [optionsSalle, setOptionsSalle] = useState([]);
  const [optionsClasses, setOptionsClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const { id } = useParams();
  const handleClose = () => {
    setOpen(false);
  };
  const handleFetch = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/get-all-sessions/${id}`);
      setSeances(response?.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };
  useEffect(() => {
    if (seance) {
      api.get(`/faculty/subject-types/${seance?.planing?.prof}`).then((res) => {
        setOptionsMatiere(
          res.data.map((item) => ({
            value: item._id,
            label: `${item?.type} ${item?.subject?.name}`,
          }))
        );
      });
    }
    api.get(`/faculty/all-salles/${facility.scholarityConfigId}`).then((res) => {
      setOptionsSalle(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
        }))
      );
    });
    api.get(`/faculty/get-all-classes/${facility.scholarityConfigId}`).then((res) => {
      setOptionsClasses(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
        }))
      );
    });
  }, [seance]);
  useEffect(() => {
    handleFetch();
  }, []);

  const eventContent = (arg) => {
    const cardStyle = {
      padding: "8px 8px",
      backgroundColor: "#7f47e8",
      color: "#333",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "1em",
      marginTop: "4px",
      height: "98%",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      position: "relative", // Ajout de position relative pour positionner les icônes
      display: "flex", // Use flexbox
      justifyContent: "space-between", // Align items with space between
      flexDirection: "column",
    };

    const titleStyle = {
      fontSize: "0.9em",
      lineHeight: "1",
      color: "#fff",
      fontWeight: "bold",
      display: "flex", // Add display flex
      justifyContent: "space-between", // Add space between the two elements
    };

    const timeTextStyle = {
      fontSize: "0.7em",
      color: "#fff",
      fontStyle: "italic",
      display: "flex",
      justifyContent: "space-between",
    };
    const instructorStyle = {
      fontSize: "0.7em", // Adjust the size as needed
      color: "#ffcc00", // Adjust the color as needed
    };
    const iconStyle = {
      position: "absolute",
      top: "-9px", // Ajuster la position verticale des icônes
      right: "10px", // Ajuster la position horizontale des icônes
      display: "flex",
      alignItems: "center",
    };
    const handleEditClick = () => {
      setSeance(arg.event.extendedProps.seance);
      setOpen(true);
    };

    const handleDeleteClick = async () => {
      const newSwal = Swal.mixin({
        customClass: {
          confirmButton: "button button-success",
          cancelButton: "button button-error",
        },
        buttonsStyling: false,
      });
      newSwal
        .fire({
          title: "Êtes-vous sûr(e) ?",
          text: "Vous voulez supprimer cette séance ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui, supprimer!",
          cancelButtonText: "Annuler",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await api.delete(`/delete-session/${arg.event.id}`);
              if (response) {
                (await Swal.fire("Suppression !", "La séance a été supprimée.", "success"))
                  .isConfirmed && handleFetch();
              }
            } catch (error) {
              Swal.fire("SErreur!", "La séeance n'a pas été supprimée.", "error");
            }
          }
        });
    };
    return (
      <div style={cardStyle}>
        <div style={iconStyle}>
          <span style={{ marginRight: "10px" }}>
            {" "}
            {/* Icône de suppression */}
            <DeleteIcon sx={{ color: pink[500] }} onClick={handleDeleteClick} />
          </span>
          <span>
            {" "}
            {/* Icône de modification */}
            <EditIcon sx={{ color: "blueviolet" }} onClick={handleEditClick} />
          </span>
        </div>
        <div style={titleStyle}>
          <span>{arg.event.title}</span>
          <span>{arg.event.extendedProps.groupe && arg.event.extendedProps.groupe}</span>
        </div>
        <div style={timeTextStyle}>{arg.timeText}</div>
        {/*  <div style={instructorStyle}>
          {arg.event.extendedProps.instructor}
        </div> */}
        <div style={timeTextStyle}>
          <span>{arg.event.extendedProps.salle}</span>
          <span>
            {arg.event.extendedProps.seance.classes.map((classe, index) => (
              <React.Fragment key={index}>
                {index > 0 && ", "}
                {classe.name}
              </React.Fragment>
            ))}
          </span>
          <span>
            {arg.event.extendedProps.week != "toute" && `  ${arg.event.extendedProps.week}`}
            {
              arg.event.extendedProps.parQuinzaine === true && `    /15`
              //: arg.event.extendedProps.subjectType
            }
          </span>
        </div>
      </div>
    );
  };

  const convertSeancesToEvents = () => {
    return seances.map((seance) => ({
      id: seance?._id,
      title: `${seance.subjectType.type} ${seance.subjectType.subject.name}`,
      daysOfWeek: [new Date(seance.day).getDay().toString()],
      start: `${seance.day}T${seance.startTime}`,
      end: `${seance.day}T${seance.endTime}`,
      allDay: false,
      startTime: seance.startTime,
      endTime: seance.endTime,
      extendedProps: {
        instructor: `${seance?.instructor?.firstName} ${seance?.instructor?.lastName}`,
        //subjectType: `${seance?.subjectType.subject.name}`,
        salle: `${seance?.salle?.name}`,
        parQuinzaine: seance?.parQuinzaine,
        groupe: seance?.groupe,
        week: seance?.week,
        seance: seance,
      },
    }));
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <EventCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "timeGridWeek,dayGridMonth,timeGridDay",
        }}
        //selectable={true}
        initialView="dayGridMonth"
        validRange={{ start: seances[0]?.planing?.startDate, end: seances[0]?.planing?.endDate }}
        events={convertSeancesToEvents()}
        selectMirror={true}
        slotLabelFormat={{
          hour: "2-digit",
          minute: "2-digit",
          hour12: false, // Format en 24 heures
        }}
        locale={frLocale}
        slotMinTime={seances[0]?.planing?.startTime || "08:00"} // Customize the start time, e.g., 8:00 AM
        slotMaxTime={seances[0]?.planing?.endTime || "18:00"} // Customize the end time, e.g., 6:00 PM
        eventContent={eventContent} // Apply custom content to events
        eventDisplay="block" // Ensure events are block elements
        eventBackgroundColor="purple" // Set default background color for events
        eventBorderColor="purple" // Set default border color for events
        slotEventOverlap={false}
        slotLabelContent={(slotInfo) => {
          const hourHeight = 60; // Set the desired height for each hour
          return (
            <div style={{ height: `${hourHeight}px`, display: "flex", alignItems: "flex-end" }}>
              {slotInfo.text}
            </div>
          );
        }}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        PaperProps={{ style: { backgroundColor: "#EEF1F4" } }}
      >
        <UpdateSeanceDialog
          handleClose={handleClose}
          seance={seance}
          optionsMatiere={optionsMatiere}
          optionsClasses={optionsClasses}
          optionsSalle={optionsSalle}
          handleFetch={handleFetch}
        />
      </Dialog>
    </DashboardLayout>
  );
}
