import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import Header from "layouts/pages/profile/components/Header";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import validations from "pages/resource-management/classe/schemas/validations";
import form from "pages/resource-management/classe/schemas/form";
import initialValues from "pages/resource-management/classe/schemas/initialValues";
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "utils/axios";
import Swal from "sweetalert2";
import ClassInfo from "./components/ClassInfo";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

function getStepContent(stepIndex, formData) {
  return <ClassInfo formData={formData} />;
}

function NewClass() {
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const [errorMessageName, setErrorMessageName] = useState("");
  const [errorMessageStartDate, setErrorMessageStartDate] = useState("");
  const [errorMessageEndDate, setErrorMessageEndDate] = useState("");
  const [name, setName] = useState("");
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const [inputs, setInputs] = useState([{ instructor: "", subject: "", optionsInstructor: [], subjectError: '', instructorError: '' }]);
  const [optionsSubject, setOptionsSubject] = useState([]);
  const navigate = useNavigate();
  const handleAddInput = () => {
    setInputs([...inputs, { instructor: "", subject: "" }]);
  };
  const handleRemoveInput = (indexToRemove) => {
    const newInputs = [...inputs];
    if (indexToRemove >= 0 && indexToRemove < newInputs.length) {
      // Remove the input at the specified index
      newInputs.splice(indexToRemove, 1);
      setInputs(newInputs);
    }
  };
  //----------------submitFormCabinet
  const submitFormCabinet = async () => {
    try {
      let hasError = false;
      // Validate each input for subject and instructor
      const newInputs = inputs.map((input) => {
        const updatedInput = { ...input };
        if (!input.subject) {
          updatedInput.subjectError = "La matière est obligatoire.";
          hasError = true;
        } else {
          updatedInput.subjectError = "";
        }
        if (!input.instructor) {
          updatedInput.instructorError = "L'enseignant est obligatoire.";
          hasError = true;
        } else {
          updatedInput.instructorError = "";
        }
        return updatedInput;
      });
      setInputs(newInputs);
      // Check for duplicate subjects
      const duplicateSubject = inputs.find(
        (input, index) =>
          index !== inputs.findIndex((otherInput) => otherInput.subject === input.subject)
      );
      if (duplicateSubject) {
        const SubjectName = duplicateSubject.subject;
        let correspondingName = null;
        optionsSubject.forEach(option => {
          if (option.value === SubjectName) {
            correspondingName = option.label;
          }
        });
        correspondingName !== null && Swal.fire(
          "Erreur!",
          `Le nom: "${correspondingName} "existe deja, Merci de saisir une autre matière !`,
          "error"
        );
        hasError = true;
      }
      switch (true) {
        case name.length < 2:
          setErrorMessageName("La longueur minimale requise est de 2 caractères.");
          break;
        case dateDebut.length === 0:
          setErrorMessageStartDate("La date de début est obligatoire.");
          break;
        case dateFin.length === 0:
          setErrorMessageEndDate("La date de Fin est obligatoire.");
          break;
        case hasError:
          return;
          break;
        default:
          const requestData = inputs.map(({ subject, instructor }) => ({
            subject,
            instructor,
          }));
          setIsLoading(true);
          const data = await api.post(`training-company/create-classe?scholarityConfigId=${facility.scholarityConfigId}`, {
            name,
            startDate: dateDebut,
            endDate: dateFin,
            subjects_instructors: requestData,
          });
          if (data.status === 201) {
            Swal.fire("Succès !", "La classe a été ajouté avec succès", "success").then(() => {
              setIsLoading(false);
              navigate("/classes");
            });
          }
          break;
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        Swal.fire("Error", error.response.data.message, "error");
      } else {
        setError("Une erreur s'est produite. Veuillez réessayer ultérieurement.");
      }
    }
  };
  //----------------submitFormFaculty
  const submitForm = async (values, actions) => {
    const info = {
      name: values.name,
      abilityMaximum: values.abilityMaximum,
      periode: values.periode,
      parcour: values.parcour,
      groupNumber: values.groupNumber,
    };
    const finalData = [
      {
        name: info.name,
        abilityMaximum: info.abilityMaximum,
        periode: info.periode,
        parcour: info.parcour,
        groupNumber: info.groupNumber || 1,
      },
    ];
    try {
      setIsLoading(true);
      const data = await api.post(`faculty/create-classe?scholarityConfigId=${facility.scholarityConfigId}`, finalData);
      if (data.status === 201) {
        Swal.fire("Succès !", "La classe a été ajouté avec succès", "success").then(() => {
          setIsLoading(false);
          navigate("/classes");
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        Swal.fire("Error", error.response.data.message, "error");
      } else {
        setError("Une erreur s'est produite. Veuillez réessayer ultérieurement.");
      }
    }
    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  function addDays(dateString, days) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + days);
    // Check if the resulting date is valid
    if (!isNaN(date.getTime())) {
      return date.toISOString().split("T")[0];
    } else {
      //console.error('Invalid date after adding days:', dateString, days);
      return null;
    }
  }

  useEffect(() => {
    // change page title in the header
    document.title = "ajouter classe";
  }, []);

  useEffect(() => {
    // Fetching subjects from the API
    api.get(`training-company/all-subjects/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsSubject(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
          instructors: item.instructors,
        }))
      );
    });
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="Ajouter une classe" searchVisible={false} ExportVisible={false} />
      {facility?.type === "centreDeFormation" ? (
        <>
          <SoftBox py={3} mb={20}>
            <Card sx={{ height: "100%" }}>
              <SoftBox p={2}>
                <SoftBox lineHeight={0} p={1}>
                  <SoftTypography id="add-company-admin" variant="h5" fontWeight="bold">
                    Ajouter Classe
                  </SoftTypography>
                </SoftBox>
                <Grid container justifyContent="center" sx={{ minHeight: "350px" }}>
                  <Grid item xs={12} lg={12}>
                    <Grid sx={{ mt: 2 }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          alignItems: "flex-start",
                        }}
                      >
                        <Grid item xs={12} sm={4}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                            sx={{ mb: 0.5 }}
                          >
                            Nom de classe*
                          </SoftTypography>
                          <SoftInput
                            name="name"
                            margin="normal"
                            id="name"
                            label="classe"
                            type="text"
                            fullWidth
                            placeholder="Nom de classe"
                            inputProps={{
                              minLength: 2,
                              maxLength: 50,
                            }}
                            autoFocus
                            variant="standard"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                          />
                          {name.length < 2 && (
                            <Typography mt={0.75} variant="caption" color="error">
                              {errorMessageName}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <SoftTypography
                            sx={{ mb: 1 }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Date Début*
                          </SoftTypography>
                          <SoftInput
                            id="date-start"
                            name="startDate"
                            type="date"
                            value={dateDebut}
                            onChange={(event) => setDateDebut(event.target.value)}
                          />
                          {dateDebut.length === 0 && (
                            <Typography mt={0.75} variant="caption" color="error">
                              {errorMessageStartDate}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <SoftTypography
                            sx={{ mb: 1 }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Date Fin*
                          </SoftTypography>
                          <SoftInput
                            id="date-fin"
                            name="endDate"
                            type="date"
                            inputProps={{
                              min: addDays(dateDebut, 1),
                            }}
                            value={dateFin}
                            onChange={(event) => setDateFin(event.target.value)}
                          />
                          {dateFin.length === 0 && (
                            <Typography mt={0.75} variant="caption" color="error">
                              {errorMessageEndDate}
                            </Typography>
                          )}
                        </Grid>
                        {inputs.map((input, index) => (
                          <>
                            <Grid item xs={12} sm={inputs.length > 1 ? 5.75 : 6}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                Matière*
                              </SoftTypography>
                              <Autocomplete
                                options={optionsSubject}
                                id="Matière"
                                title="matière"
                                clearIcon={null}
                                required={true}
                                aria-required={true}
                                getOptionLabel={(option) => option.label}
                                defaultValue={null}
                                onChange={(event, newValue) => {
                                  if (newValue !== null && newValue !== undefined) {
                                    const newInputs = [...inputs];
                                    newInputs[index].subject = newValue.value;
                                    newInputs[index].optionsInstructor = newValue?.instructors?.map((item) => ({
                                      value: item._id,
                                      label: `${item?.firstName} ${item?.lastName}`,
                                    }))

                                    setInputs(newInputs);
                                  }
                                }}
                                // value={optionsSubject.find((option) => option.value === selectedParcour)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    //value={inputs[0].blabla}
                                    required={true}
                                    placeholder="Tous les matières"
                                  />
                                )}
                              />
                              {input.subject.length < 1 && (
                                <Typography mt={0.75} variant="caption" color="error">
                                  {input.subjectError}
                                </Typography>
                              )}
                            </Grid>

                            <Grid item xs={12} sm={inputs.length > 1 ? 5.75 : 6}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                                sx={{ mb: 0.5 }}
                              >
                                Enseignant*
                              </SoftTypography>
                              <Autocomplete
                                options={inputs[index].optionsInstructor}
                                clearIcon={null}
                                getOptionLabel={(option) => option.label}
                                required={true}
                                aria-required={true}
                                value={
                                  inputs[index].optionsInstructor?.find((option) => option.value === input?.instructor || null)
                                }
                                onChange={(event, newValue) => {
                                  if (newValue !== null && newValue !== undefined) {
                                    const newInputs = [...inputs];
                                    newInputs[index].instructor = newValue.value;
                                    setInputs(newInputs);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    required={true}
                                    placeholder="Tous les enseignants"
                                  />
                                )}
                              />
                              {input.instructor.length < 1 && (
                                <Typography mt={0.75} variant="caption" color="error">
                                  {input.instructorError}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={0.5} mt={4}>
                              {inputs.length > 1 && (
                                <IconButton>
                                  <RemoveCircleIcon
                                    id={`btn-delete-${index}`}
                                    color="error"
                                    fontSize="medium"
                                    onClick={() => handleRemoveInput(index)}
                                  />
                                </IconButton>
                              )}
                            </Grid>
                          </>
                        ))}
                      </Grid>{" "}
                      <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ height: "100%" }}
                      >
                        <SoftButton
                          id="btn-ajouter-plus"
                          size="small"
                          sx={{
                            mt: inputs.length > 1 ? 3 : -0.5,
                            borderWidth: "2px",
                            borderStyle: "dashed solid",
                            borderColor: "purple ",
                          }}
                          onClick={handleAddInput}
                        >
                          + ajouter autre matière
                        </SoftButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>

                <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                  <SoftButton id="btn-cancel" variant="outlined" color="dark" size="small" onClick={() => navigate("/classes")}>
                    Annuler
                  </SoftButton>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <SoftButton
                      id="btn-add"
                      type="submit"
                      variant="gradient"
                      color="purple"
                      size="small"
                      onClick={submitFormCabinet}
                    >
                      Ajouter
                    </SoftButton>
                  )}
                </SoftBox>
              </SoftBox>
            </Card>
          </SoftBox>
        </>
      ) : (
        <SoftBox py={3} mb={20}>
          <Grid container justifyContent="center" sx={{ height: "100%" }}>
            <Grid item xs={12} lg={12}>
              <Formik
                initialValues={initialValues}
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, isSubmitting }) => (
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <SoftBox p={2}>
                        <SoftBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                          })}
                          <SoftBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <SoftButton
                              id="btn-cancel"
                              variant="outlined"
                              color="dark"
                              size="small"
                              onClick={() => navigate("/classes")}
                            >
                              Annuler
                            </SoftButton>
                            {isLoading ? (
                              <CircularProgress />
                            ) : (
                              <SoftButton
                                id="btn-add"
                                type="submit"
                                variant="gradient"
                                color="purple"
                                size="small"
                              >
                                Ajouter
                              </SoftButton>
                            )}
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </SoftBox>
      )}
    </DashboardLayout>
  );
}

export default NewClass;
