import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// react-phone-input
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import {
  Autocomplete,
  Avatar,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Icon,
  ListItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import SoftButton from "components/SoftButton";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import api from "utils/axios";
import SoftInput from "components/SoftInput";
import styled from "@emotion/styled";
import { getCountryAdd } from "utils/citysTn";
import { fetchData } from "utils/citysTn";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import { downloadFile } from "utils/citysTn";
import EnterpriseFormField from "pages/enterprise/schema/enterprise-schemas/EnterpriseFormField";
import { ErrorMessage, Field, Formik } from "formik";
import validations from "../../schema/validations";

function StudentInfo({ user, title }) {
  const [info, setInfo] = useState({});
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [siteWeb, setSiteWeb] = useState("");
  const [occupation, setOccupation] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [bank, setBank] = useState("");
  const [rib, setRib] = useState("");
  const [birthday, setBirthday] = useState("");
  const [cnssNumber, setCnssNumber] = useState("");
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
  const [passeport, setPasseport] = useState("");
  const [comment, setComment] = useState("");
  const [country, setCountry] = useState(user?.country || "");
  const [city, setCity] = useState(user?.city || "");
  const [logo, setLogo] = useState(null);
  const [logoToDelete, setLogoToDelete] = useState(null);
  const [logoUpdate, setLogoUpdate] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataToSend, setDataToSend] = useState({
    country: "",
    city: "",
  });
  const [optionsFilieres, setOptionsFilieres] = useState([]);
  const [optionsParcours, setOptionsParcours] = useState([]);
  const [optionsLevel, setOptionsLevel] = useState([]);
  const [selectedFiliere, setSelectedFiliere] = useState(user?.parcour?.niveau?.filiere);
  const [selectedLevel, setSelectedLevel] = useState(user?.parcour?.niveau?._id);
  const [selectedParcour, setSelectedParcour] = useState(user?.parcour?._id);
  const initialValues = {
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    email: user.email || "",
    phone: user.phoneNumber || "",
    gender: user.gender || "",
    cin: user.cin || "",
    responsable:user.responsable ||"",
    filiere:user?.parcour?.niveau?.filiere ||"",
    niveau:user?.parcour?.niveau?._id ||"",
    parcour:user?.parcour?._id ||"",
  };
  const [choixValue, setChoixValue] = useState("");
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [optionsResp, setOptionsResp] = useState([]);
  const [selectedResp, setSelectedResp] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    // Initialize user
    if (user) {
      setSiteWeb(user.siteWeb);
      setOccupation(user.occupation);
      setAddress(user.address);
      setZipCode(user.zipCode);
      setRib(user.rib);
      setBank(user.bank);
      setBirthday(user.birthday);
      setSecondaryPhoneNumber(user.secondaryPhoneNumber);
      setCnssNumber(user.cnssNumber);
      setComment(user.comment);
      setPasseport(user.passeport);
      setCountry(user.country);
      setCity(user.city);
      setFiles(user.files);
      setDataToSend({ country: user.country, city: user.city });
      setSelectedResp(user.responsable);
      setChoixValue(user.responsable ? "choix1" : "choix2");
      setSelectedFiliere(user?.parcour?.niveau?.filiere);
      setSelectedLevel(user?.parcour?.niveau?._id);
      setSelectedParcour(user?.parcour?._id);
    }
  }, [user]);
  useEffect(() => {
    api.get(`/faculty/all-filiere/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsFilieres(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
          niveaux: item.niveaux,
        }))
      );
    });
    if (selectedFiliere) {
      api
        .get(`/faculty/get-levels-by-filiere/${selectedFiliere}`)
        .then((res) => {
          setOptionsLevel(
            res?.data?.map((item) => ({
              value: item._id,
              label: item.name,
            }))
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setError("Resource not found:", error.response.data);
            // Handle 404 error gracefully (e.g., display a message to the user)
          } else {
            setError("API request failed:", error.message);
            // Handle other errors
          }
        });
    }
    if (selectedLevel) {
      api
        .get(`/faculty/get-parcours-by-level/${selectedLevel}`)
        .then((res) => {
          setOptionsParcours(
            res?.data?.map((item) => ({
              value: item._id,
              label: item.name,
            }))
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setError("Resource not found:", error.response.data);
            // Handle 404 error gracefully (e.g., display a message to the user)
          } else {
            setError("API request failed:", error.message);
            // Handle other errors
          }
        });
    }
    if (selectedParcour) {
      api
        .get(`/faculty/get-sous-period-by-parcours/${selectedParcour}`)
        .then((res) => {
          setOptionsSousPeriode(
            res.data.map((item) => ({
              value: item._id,
              label: item.name,
            }))
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setError("Resource not found:", error.response.data);
            // Handle 404 error gracefully (e.g., display a message to the user)
          } else {
            setError("API request failed:", error.message);
            // Handle other errors
          }
        });
    }
  }, [selectedFiliere, selectedLevel, selectedParcour]);
  const fileInputRef = useRef(null);
  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };
  const countries = getCountryAdd(info, data);
  const handleCountryChange = (selectedOption) => {
    const selectedCountry = selectedOption.value;
    const cities = data.find((country) => country.country === selectedCountry).cities;

    setDataToSend({ ...dataToSend, country: selectedCountry });
    setCityOptions(cities.map((city) => ({ value: city, label: city })));
  };
  const handleCityChange = (selectedOption) => {
    setDataToSend({ ...dataToSend, city: selectedOption.value });
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    const newOption = { value: file, label: file.name };
    // Check if the file is already selected
    const isSelected = selectedOptions.some((option) => option.label === newOption.label);
    if (!isSelected) {
      // If it's not selected, add it to both options and selectedOptions
      setOptions([...options, newOption]);
      setSelectedOptions([...selectedOptions, newOption]);
    }
  };
  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Specify the allowed file extensions
      const allowedExtensions = ["jpg", "jpeg", "png", "gif", "jfif"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        // Display an error message if the file extension is not allowed
        setErrorMessage(
          "Format de fichier non pris en charge. Veuillez sélectionner un fichier JPG, JPEG, PNG ou GIF."
        );
        setLogoUpdate(null); // Clear the logo
        return;
      }
      // Reset the error message if the file extension is allowed
      setErrorMessage("");
      setLogoUpdate(selectedFile);
      // Specify the maximum allowed file size in bytes (e.g., 2MB)
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      const maxWidth = 650; // Maximum width in pixels
      const maxHeight = 650; // Maximum height in pixels

      if (selectedFile.size > maxSize) {
        // Display an error message if the file size exceeds the limit
        setErrorMessage(
          "La taille du fichier est trop grande. La taille maximale autorisée est de 2 Mo."
        );
        setLogoUpdate(null); // Clear the logo
      } else {
        // Check image dimensions
        const img = new Image();
        img.src = URL.createObjectURL(selectedFile);
        img.onload = () => {
          if (img.width > maxWidth || img.height > maxHeight) {
            // Display an error message if the image dimensions exceed the limit
            setErrorMessage(
              `Les dimensions de l’image sont trop grandes. Les dimensions maximales autorisées sont ${maxWidth}x${maxHeight} pixels`
            );
            setLogoUpdate(null); // Clear the logo
          }
        };
      }
    }
  };
  const currentDate = new Date();
  const monthsToSubtract = 1; // Set the number of months to subtract
  const minDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - monthsToSubtract,
    currentDate.getDate()
  );
  const minDateString = minDate.toISOString().split("T")[0];
  const handleDeleteLogo = () => {
    // Handle deleting the logo here
    // You can implement this function as needed
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setLogoUpdate(null);
    setLogo(null);
    setLogoToDelete(user?.imageUrl);
    if (logoUpdate === null) {
    }
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      const form = new FormData();
      form.append("imageUrl", logoUpdate ? logoUpdate : logo);
      form.append("firstName", values.firstName);
      form.append("lastName", values.lastName);
      form.append("email", values.email);
      form.append("cin", values.cin);
      form.append("siteWeb", siteWeb);
      if (selectedParcour!==undefined) {
        form.append("parcour", selectedParcour);
      }
      form.append("occupation", occupation);
      form.append("address", address);
      form.append("zipCode", zipCode);
      form.append("bank", bank);
      form.append("rib", rib);
      form.append("birthday", birthday);
      form.append("cnssNumber", cnssNumber);
      form.append("phoneNumber", values.phone);
      form.append("secondaryPhoneNumber", secondaryPhoneNumber);
      form.append("gender", values.gender);
      form.append("passeport", passeport);
      form.append("comment", comment);
      form.append("country", dataToSend.country ? dataToSend.country : country);
      form.append("city", dataToSend.city ? dataToSend.city : city);
      logoToDelete && form.append("imageUrlToDelete", logoToDelete);
      selectedOptions &&
        selectedOptions.length > 0 &&
        selectedOptions.forEach((item) => {
          form.append("files", item.value);
        });
      form.append("filesToDelete", JSON.stringify(filesToDelete));
      let response;
      if (choixValue === "choix1") {
        response = await api.patch(`/userUpdate/${user._id}`, form);
        if (selectedResp) {
          response = await api.patch(`/change-student-responsable/${user._id}`, {
            responsable: selectedResp,
          });
        }
      } else if (choixValue === "choix2") {
        await api.patch(`/remove-responsable-from-student/${user._id}`, form);
        response = await api.patch(`/userUpdate/${user._id}`, form);
      }
  
      if (response && response.status >= 200 && response.status < 300) {
        Swal.fire("Succès !", "Mise à jour étudiant réussie", "success").then(() => {
          setLoading(false);
          navigate(0);
          /* navigate("/users/students"); */
        });
      }
    } catch (error) {
      setLoading(false); // Set loading to false on error
      if (error.response && error.response.data && error.response.data.message) {
        Swal.fire("Error", error.response.data.message, "error").then(() => navigate(0));

      } else {
        Swal.fire(
          "Error",
          "Une erreur s'est produite. Veuillez réessayer ultérieurement.",
          "error"
        ).then(() => navigate(0));

      }
    }
  };
  useEffect(() => {
    const fetchDataAsync = async () => {
      const data = await fetchData();
      setData(data);
    };
    fetchDataAsync();
  }, []);
  const handleDeleteFile = (fileToDelete) => {
    // Remove the file from the 'files' array
    const updatedFiles = files.filter((file) => file !== fileToDelete);
    // Add the file to the 'filesToDelete' array
    setFilesToDelete([...filesToDelete, fileToDelete]);
    // Update the 'files' state
    setFiles(updatedFiles);
  };
  const handleChoixChange = (event) => {
    setChoixValue(event.target.value);
  };
  const handleFetch = async () => {
    try {
      const response = await api.get(`/get-all-users-by-role/${facility._id}?role=responsable`);
      setOptionsResp(
        response.data.map((item) => ({
          value: item._id,
          label: item.firstName,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);
  return (
    <>
      {(Object.keys(user).length > 0 ) && (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={validations[0]}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Card id="basic-info" sx={{ overflow: "visible" }}>
                <SoftBox p={3}>
                  <SoftBox sx={{ display: "flex", justifyContent: "space-between" }}>
                    <SoftTypography variant="h5">Les informations</SoftTypography>
                  </SoftBox>
                  <Grid item xs={12} sm={13} container justifyContent="center">
                    <SoftBox position="relative" height="max-content" mx="auto">
                      <Avatar
                        id="img-profile"
                        onClick={handleAvatarClick}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        sx={{
                          filter: isHovered ? "blur(2px)" : "none",
                          cursor: "pointer",
                          width: 100,
                          height: 100,
                          borderRadius: "16px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        src={
                          !logo && !logoUpdate && !logoToDelete
                            ? `${process.env.REACT_APP_BASE_URL}/${user?.imageUrl}`
                            : logoUpdate
                            ? URL.createObjectURL(logoUpdate)
                            : ""
                        }
                        alt={title}
                        size="xl"
                        variant="rounded"
                      />

                      <SoftBox
                        alt="spotify logo"
                        position="absolute"
                        right={0}
                        bottom={0}
                        mr={-1}
                        mb={-1}
                      >
                        <SoftButton
                          id="btn-delete"
                          onClick={handleDeleteLogo}
                          variant="gradient"
                          color="error"
                          size="small"
                          iconOnly
                        >
                          <Icon>delete</Icon>
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <input
                    id="img"
                    type="file"
                    // Specify the accepted file types
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={onFileChange}
                    accept=".jpg, .jpeg, .png, .gif .jfif"
                  />
                  {errorMessage && (
                    <Typography align="center" sx={{ mt: 2 }} variant="body2" color="error">
                      {errorMessage}
                    </Typography>
                  )}
                </SoftBox>
                <SoftBox component="form" px={3}>
                  <Grid container ml={1} mt={2}>
                    <Grid item xs={12} sm={12}>
                      <SoftTypography
                        variant="h5"
                        fontWeight="large"
                        textTransform="capitalize"
                        mb={1.5}
                      >
                        choisir votre responsable :
                      </SoftTypography>
                      <Grid ml={1} mb={1}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-label="choix"
                            id="choix"
                            name="choix"
                            value={choixValue}
                            onChange={handleChoixChange}
                          >
                            <FormControlLabel
                              value="choix1"
                              control={<Radio />}
                              label="Avec responsable"
                            />
                            <FormControlLabel
                              value="choix2"
                              control={<Radio />}
                              label="Sans responsable"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  {choixValue === "choix1" && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Nom du responsable*
                        </SoftTypography>
                        {optionsResp.length > 0 && (
                          <Field
                            name="responsable"
                            validate={(value) => {
                              if (!value) {
                                return "Responsable est obligatoire";
                              }
                            }}
                          >
                            {({ field, form }) => (
                              <>
                                <Autocomplete
                                  options={optionsResp}
                                  id="responsable"
                                  title="responsable"
                                  clearIcon={null}
                                  aria-required={true}
                                  getOptionLabel={(option) => option.label}
                                  value={optionsResp.find(
                                    (option) => option.value === selectedResp 
                                  )}
                                  onChange={(event, newValue) => {
                                    if (newValue !== null && newValue !== undefined) {
                                      form.setFieldValue("responsable", newValue.value);
                                      setSelectedResp(newValue.value);
                                    }
                                  }}
                                  onBlur={() => form.setFieldTouched("responsable", true)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      placeholder="Tous les responsables"
                                    />
                                  )}
                                />
                                <SoftTypography
                                  component="div"
                                  variant="caption"
                                  color="error"
                                  mt={0.75}
                                >
                                  {!selectedResp
                                    ? form.errors.responsable
                                    : null}
                                </SoftTypography>
                              </>
                            )}
                          </Field>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </SoftBox>
                <SoftBox component="form" pb={3} px={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <EnterpriseFormField
                        id="firstname"
                        name="firstName"
                        defaultValue={values.firstName}
                        value={values.firstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Nom*"
                        placeholder="Nom"
                        inputProps={{
                          minLength: 2,
                          maxLength: 30,
                        }}
                        error={!!touched.firstName && !!errors.firstName}
                        success={values.firstName?.length > 0 && !errors.firstName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <EnterpriseFormField
                        id="lastname"
                        name="lastName"
                        defaultValue={values.lastName}
                        value={values.lastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Prénom*"
                        placeholder="Prénom"
                        inputProps={{
                          minLength: 2,
                          maxLength: 30,
                        }}
                        error={!!touched.lastName && !!errors.lastName}
                        success={values.lastName?.length > 0 && !errors.lastName}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Date de Naissance
                      </SoftTypography>
                      <SoftInput
                        id="birthday"
                        sx={{ mb: 1 }}
                        type="date"
                        inputProps={{
                          max: minDateString,
                        }}
                        defaultValue={birthday}
                        value={birthday}
                        onChange={(e) => setBirthday(e.target.value)}
                      />
                    </Grid>{" "}
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ my: 2, minWidth: 120 }}>
                        <SoftTypography
                          sx={{ ml: 0.5 }}
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Genre*
                        </SoftTypography>
                        <RadioGroup
                          name="gender"
                          id="gender"
                          sx={{ ml: 2 }}
                          row
                          defaultChecked={values.gender}
                          defaultValue={values.gender}
                          value={values.gender}
                          onChange={(e) => {
                            handleChange({ target: { name: "gender", value: e.target.value } });
                          }}
                          aria-labelledby="demo-row-radio-buttons-group-label"
                        >
                          <FormControlLabel
                            defaultValue="homme"
                            value="femme"
                            checked={values.gender === "femme" && true}
                            control={<Radio />}
                            label="Femme"
                          />
                          <FormControlLabel
                            defaultValue="femme"
                            value="homme"
                            control={<Radio />}
                            label="Homme"
                            checked={values.gender === "homme" && true}
                          />
                        </RadioGroup>{" "}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <EnterpriseFormField
                        id="email"
                        name="email"
                        defaultValue={values.email}
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Email*"
                        placeholder="Email"
                        inputProps={{
                          type: "email",
                          maxLength: 50,
                        }}
                        error={!!touched.email && !!errors.email}
                        success={values.email?.length > 0 && !errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <EnterpriseFormField
                        id="cin"
                        name="cin"
                        defaultValue={values.cin}
                        value={values.cin}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Cin/passeport*"
                        placeholder="Cin/Passeport"
                        inputProps={{
                          type: "text",
                          maxLength: 50,
                        }}
                        error={!!touched.cin && !!errors.cin}
                        success={values.cin?.length > 0 && !errors.cin}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} mb={2}>
                    <Grid item xs={12} sm={facility?.type!=="faculty"? 12 : 6 }>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Téléphone*
                      </SoftTypography>
                      <PhoneInput
                        id="telephone"
                        name="phone"
                        country={"tn"}
                        enableSearch
                        inputStyle={{
                          width: "100%",
                        }}
                        placeholder="+216 735 631 620"
                        defaultValue={values.phone}
                        value={values.phone}
                        onBlur={handleBlur}
                        onChange={(value) => {
                          handleChange({ target: { name: "phone", value } });
                        }}
                        error={!!touched.phone && !!errors.phone}
                        success={values.phone?.length > 0 && !errors.phone}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name="phone" />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>

                    {facility?.type==="faculty" &&( 
                       <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Filière*
                      </SoftTypography>
                       <Field
                        name="filiere"
                        validate={(value) => {
                          if (!value) {
                            return "Filière est obligatoire";
                          }
                        }}
                      >
                        {({ field, form }) => (
                          <>
                            <Autocomplete
                              options={optionsFilieres}
                              id="filiere"
                              title="filiere"
                              clearIcon={null}
                              aria-required={true}
                              getOptionLabel={(option) => option.label}
                              value={optionsFilieres.length > 0 && optionsFilieres.find(
                                (option) => option.value === selectedFiliere
                              )}
                              onChange={(event, newValue) => {
                                if (newValue !== null && newValue !== undefined) {
                                  form.setFieldValue("filiere", newValue.value); 
                                  setSelectedFiliere(newValue.value);
                                  form.setFieldValue("parcour", "");
                                  form.setFieldValue("niveau", "");
                                  setSelectedParcour('')
                                  setSelectedLevel('')         
                                }
                              }}
                              onBlur={() => form.setFieldTouched("filiere", true)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  //value={selectedFiliere}
                                  placeholder="Tous les filières"
                                />
                              )}
                            />
                            <SoftTypography
                              component="div"
                              variant="caption"
                              color="error"
                              mt={0.75}
                            >
                              {!selectedFiliere
                                ? form.errors.filiere
                                : null}
                            </SoftTypography>
                          </>
                        )}
                      </Field>
                    </Grid>)}
                  </Grid>
                  {facility?.type==="faculty" &&( <Grid container spacing={3} mb={1}>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Niveau*
                      </SoftTypography>
                     <Field
                        name="niveau"
                        validate={(value) => {
                          if (!value) {
                            return "Niveau est obligatoire";
                          }
                        }}
                      >
                        {({ field, form }) => (
                          <>
                            <Autocomplete
                              options={optionsLevel}
                              id="niveau"
                              title="niveau"
                              clearIcon={null}
                              aria-required={true}
                              getOptionLabel={(option) => option.label}
                              value={optionsLevel.length > 0 && optionsLevel.find((option) => option.value === selectedLevel)}
                              onChange={(event, newValue) => {
                                if (newValue !== null && newValue !== undefined) {
                                  form.setFieldValue("niveau", newValue.value);
                                  setSelectedLevel(newValue.value);
                                  form.setFieldValue("parcour", "");
                                  setSelectedParcour('')
                                }
                              }}
                              onBlur={() => form.setFieldTouched("niveau", true)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  value={selectedLevel}
                                  placeholder="Tous les niveaux"
                                />
                              )}
                            />
                            <SoftTypography
                              component="div"
                              variant="caption"
                              color="error"
                              mt={0.75}
                            >
                              {!selectedLevel
                                ? form.errors.niveau
                                : null}
                            </SoftTypography>
                          </>
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Parcour*
                      </SoftTypography>     
                      <Field
                        name="parcour"
                        validate={(value) => {
                          if (!value) {
                            return "Parcours est obligatoire";
                          }
                        }}
                      >
                        {({ field, form }) => (
                          <>
                            <Autocomplete
                              options={optionsParcours}
                              id="parcour"
                              title="parcour"
                              clearIcon={null}
                              aria-required={true}
                              getOptionLabel={(option) => option.label}
                              value={optionsParcours.length > 0 && optionsParcours.find(
                                (option) => option.value === selectedParcour
                              )}
                              onChange={(event, newValue) => {
                                if (newValue !== null && newValue !== undefined) {
                                  form.setFieldValue("parcour", newValue.value);
                                  setSelectedParcour(newValue.value);
                                }
                              }}
                              onBlur={() => form.setFieldTouched("parcour", true)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  value={selectedParcour}
                                  placeholder="Tous les parcours"
                                />
                              )}
                            />
                            <SoftTypography
                              component="div"
                              variant="caption"
                              color="error"
                              mt={0.75}
                            >
                              {!selectedParcour
                                ? form.errors.parcour
                                : null}
                            </SoftTypography>
                          </>
                        )}
                      </Field>
                    </Grid>
                  </Grid>)}

                  <Grid container spacing={3} mb={2}>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Pays
                      </SoftTypography>
                      <SoftSelect
                        id="country"
                        value={{ value: dataToSend.country, label: dataToSend.country }}
                        options={countries?.map((country) => ({ value: country, label: country }))}
                        onChange={handleCountryChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Ville
                      </SoftTypography>
                      <SoftSelect
                        id="city"
                        options={cityOptions}
                        value={{ value: dataToSend.city, label: dataToSend.city }}
                        onChange={handleCityChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} mb={2}>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        id="adresse"
                        label="Adresse"
                        placeholder="Adresse"
                        defaultValue={address}
                        value={address}
                        inputProps={{
                          maxLength: 50,
                        }}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Grid>{" "}
                    <Grid item xs={12} sm={6}>
                      <FormField
                        id="code-postal"
                        label="Code postal"
                        placeholder="Code postal"
                        type="number"
                        defaultValue={zipCode}
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid sx={{ mt: 2, mr: "auto", display: "flow" }} item xs={12}>
                    <SoftButton
                      sx={{ mb: 1 }}
                      component="label"
                      onChange={handleFileUpload}
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                    >
                      Télécharger des fichiers
                      <VisuallyHiddenInput type="file" />
                    </SoftButton>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      placeholder="Télécharger des fichiers"
                      options={options}
                      value={selectedOptions}
                      filterSelectedOptions
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Télécharger des fichiers"
                        />
                      )}
                      onChange={(event, newValue) => {
                        setSelectedOptions(newValue);
                      }}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} mt={1}>
                    {files && files.length > 0 ? (
                      files.map((file, index) => (
                        <Grid
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            listStyle: "none",
                            p: 0.5,
                            m: 0,
                          }}
                          component="ul"
                        >
                          <ListItem>
                            <Chip
                              size="small"
                              label={file}
                              onDelete={() => handleDeleteFile(file)}
                            />
                            <BrowserUpdatedIcon
                              onClick={() => downloadFile(file.name ? file.name : file)}
                              fontSize="default"
                              sx={{ cursor: "pointer", marginLeft: 0.5 }}
                            />
                          </ListItem>
                        </Grid>
                      ))
                    ) : (
                      <Typography sx={{ p: 1 }} variant="body2">
                        Aucun fichier
                      </Typography>
                    )}
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} mt={1}>
                      <FormField
                        id="description"
                        multiline
                        rows={5}
                        label="description"
                        placeholder="description"
                        defaultValue={comment}
                        value={comment}
                        inputProps={{
                          maxLength: 250,
                        }}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </SoftBox>
                <Grid
                  item
                  xs={12}
                  ml={3}
                  sx={{ mr: 2 }}
                  mb={2}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <SoftButton
                    onClick={() => window.location.reload()}
                    color="dark"
                    variant="outlined"
                  >
                    Annuler
                  </SoftButton>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <SoftButton type="submit" sx={{ ml: 2 }} color="customized" variant="gradient">
                      Modifier
                    </SoftButton>
                  )}
                </Grid>
              </Card>
            </form>
          )}
        </Formik>
      )}
    </>
  );
}
StudentInfo.propTypes = {
  title: PropTypes.string.isRequired,

  user: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default StudentInfo;
