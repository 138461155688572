// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import SoftSelect from "components/SoftSelect";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// react-phone-input
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// NewUser page components
import EnterpriseFormField from "pages/enterprise/schema/enterprise-schemas/EnterpriseFormField";
import { useState, useEffect, useRef } from "react";
import { Autocomplete, Avatar, TextField, Typography, styled } from "@mui/material";

import { getCountryAdd } from "utils/citysTn";
import { fetchData } from "utils/citysTn";
import SoftButton from "components/SoftButton";
import Icon from "@mui/material/Icon";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ErrorMessage, d } from "formik";

function EnterpriseInfo({ formData }) {
  const { formField, errors, touched, values } = formData;

  const {
    companyName,
    serialNumber,
    email,
    siteWeb,
    phone,
    country,
    city,
    secondaryPhone,
    physiqualAddress,
    postCode,
    description,
    CheckedStatus,
    logoUrl,
    files,
  } = formField;

  const {
    companyName: name,
    serialNumber: serialNumberX,
    email: emailX,
    siteWeb: siteWebX,
    phone: phoneX,
    secondaryPhone: secondaryPhoneX,
    physiqualAddress: physiqualAddressX,
    postCode: postCodeX,
    description: descriptionX,
    country: countryX,
    city:cityX,
    CheckedStatus: CheckedStatusX,
    logoUrl:logoUrlX

  } = values;

  const [logo, setLogo] = useState(logoUrlX);
  console.log("errors", errors)
  values.logoUrl = logo;
  const [info, setInfo] = useState({});
  const [data, setData] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [desc, setDesc] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [secondCompanyPhone, setSecondCompanyPhone] = useState(secondaryPhoneX);
  const [companyPhone, setCompanyPhone] = useState(phoneX);


  const [cresh, setCresh] = useState(false);
  const [jardinDenfent, setJardinDenfent] = useState(false);
  const [ecolePrimaire, setEcolePrimaire] = useState(false);
  const [college, setCollege] = useState(false);
  const [lycee, setLycee] = useState(false);
  const [faculty, setfaculty] = useState(false);
  const [centreDeFormation, setCentreDeFormation] = useState(false);
  const [finalSelect, setFinalSelect] = useState(false);


  values.files = selectedOptions;
  values.options = options;
  // Function to handle file upload
  const handleFileUpload = (event) => {

    const file = event.target.files[0]; // Get the uploaded file
    const newOption = { value: file, label: file.name };

    // Check if the file is already selected
    const isSelected = selectedOptions.some((option) => option.label === newOption.label);

    if (!isSelected) {
      // If it's not selected, add it to both options and selectedOptions
      setOptions([...options, newOption]);
      setSelectedOptions([...selectedOptions, newOption]);
    }
  };

  const countries = getCountryAdd(info, data);

  const [dataToSend, setDataToSend] = useState({
    country: countryX ?countryX : "",
    city: cityX ? cityX : "",
  });
  values.city = dataToSend.city;
  values.phone = companyPhone;
  values.secondaryPhone = secondCompanyPhone;
  values.country = dataToSend.country;

  const handleCountryChange = (selectedOption) => {
    console.log("country", selectedOption)
    const selectedCountry = selectedOption.value;
    const cities = data.find((country) => country.country === selectedCountry).cities;

    setDataToSend({ ...dataToSend, country: selectedCountry });
    setCityOptions(cities.map((city) => ({ value: city, label: city })));
  };

  const handleCityChange = (selectedOption) => {
    setDataToSend({ ...dataToSend, city: selectedOption.value });
  };
  const fileInputRef = useRef(null);

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };
  console.log("hello",values.files)
  const dataToSendValues = {
    companyName: name,
    serialNumber: serialNumberX,
    email: emailX,
    siteWeb: siteWebX,
    phone: phoneX,
    secondaryPhone: secondaryPhoneX,
    physiqualAddress: physiqualAddressX,
    postCode: postCodeX,
    description: desc,
    country: dataToSend.country,
    city: dataToSend.city,
    logo: logo,
    files: selectedOptions.map((val) => val.value),
  };

  let arr = [];
  const handleFileChange = (e) => {
    const files = e.target.files;
    // You can process the files here if needed, e.g., upload to a server.
    // For now, we'll just store them in the files array.
    arr.push(files);
  };
  console.log(arr);
  console.log(dataToSendValues);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // Specify the allowed file extensions
      const allowedExtensions = ["jpg", "jpeg", "png", "gif", "jfif"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        // Display an error message if the file extension is not allowed
        setErrorMessage("Format de fichier non pris en charge. Veuillez sélectionner un fichier JPG, JPEG, PNG ou GIF.");
        setLogo(null); // Clear the logo
        return;
      }

      // Reset the error message if the file extension is allowed
      setErrorMessage("");
      setLogo(selectedFile);

      // Specify the maximum allowed file size in bytes (e.g., 2MB)
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      const maxWidth = 650; // Maximum width in pixels
      const maxHeight = 650; // Maximum height in pixels

      if (selectedFile.size > maxSize) {
        // Display an error message if the file size exceeds the limit
        setErrorMessage("La taille du fichier est trop grande. La taille maximale autorisée est de 2 Mo.");
        setLogo(null); // Clear the logo
      } else {
        // Check image dimensions
        const img = new Image();
        img.src = URL.createObjectURL(selectedFile);

        img.onload = () => {
          if (img.width > maxWidth || img.height > maxHeight) {
            // Display an error message if the image dimensions exceed the limit
            setErrorMessage(
              `Les dimensions de l'image sont trop grandes. Les dimensions maximales autorisées sont de ${maxWidth}x${maxHeight} pixels.`
            );
            setLogo(null); // Clear the logo
          }
        };
      }
    }
  };
  const handleUpdateLogo = () => {
    fileInputRef.current.click();
  };

  const handleDeleteLogo = () => {
    // Handle deleting the logo here
    // You can implement this function as needed
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setLogo(null);
  };
  const handleRemoveOption = (optionToRemove) => {
    // Remove the selected option from selectedOptions
    const updatedSelectedOptions = selectedOptions.filter(
      (option) => option.label !== optionToRemove.label
    );

    setSelectedOptions(updatedSelectedOptions);
  };
  // <<<<<<< navbar-sidebar
  useEffect(() => {
    const fetchDataAsync = async () => {
      const data = await fetchData();
      setData(data);
    };
    fetchDataAsync();
  }, []);
  return (
    <SoftBox>
    
    <SoftBox lineHeight={0}>
        <SoftTypography id="add-company-admin" variant="h5" fontWeight="bold">
          Informations de l&apos;entreprise
        </SoftTypography>
        
      </SoftBox>

      <Grid item xs={12} sm={13} container justifyContent="center">
        <SoftBox position="relative" height="max-content" mx="auto">
          <Avatar
            onClick={handleAvatarClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
              filter: isHovered ? "blur(2px)" : "none",
              cursor: "pointer",
              width: 100,
              height: 100,
              borderRadius: "16px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={logo ? URL.createObjectURL(logo) : ""}
            value={logo}
            alt="profile picture"
            size="xl"
            variant="rounded"
          >
            Logo
          </Avatar>
          <SoftBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
            <SoftButton
              onClick={handleDeleteLogo}
              variant="gradient"
              color="error"
              size="small"
              iconOnly
            >
              <Icon>delete</Icon>
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </Grid>
      <input
        id="logo-enterprise"
        type="file"
        // Specify the accepted file types
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={onFileChange}
        accept=".jpg, .jpeg, .png, .gif .jfif"
      />

      {errorMessage && (
        <Typography align="center" sx={{ mt: 2 }} variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
              id="name-enterprise"
              type={companyName.type}
              label={companyName.label}
              name={companyName.name}
              value={name}
              
              inputProps={{
                minLength:2,
                maxLength: 30
              }}
              placeholder={companyName.placeholder}
              error={errors.companyName && touched.companyName}
              success={name.length > 0 && !errors.companyName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
              id="serial-number-enterprise"
              type={serialNumber.type}
              label={`${serialNumber.label}`}
              name={serialNumber.name}
              value={serialNumberX}
              
              placeholder={serialNumber.placeholder}
              error={errors.serialNumber && touched.serialNumber}
              success={serialNumberX.length > 0 && !errors.serialNumber}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
              id="email-enterprise"
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailX}
              
              inputProps={{
                maxLength: 50
              }}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailX.length > 0 && !errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
              id="site-web-enterprise"
              type={siteWeb.type}
              label={siteWeb.label}
              name={siteWeb.name}
              value={siteWebX}
              inputProps={{
                maxLength: 100
              }}
              placeholder={siteWeb.placeholder}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} >
          <Grid item xs={12} sm={6}>
            <SoftTypography

              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Numéro de Téléphone*
            </SoftTypography>
            <PhoneInput
             inputProps={{
              id: 'phone-enterprise'
          }}
             // id="phone-enterprise"
              name={"phone"}
              country={"tn"}
              value={companyPhone}
              enableSearch
              
              inputStyle={{
                width: "100%",
              }}
              error={errors.phone && touched.phone}
              success={phoneX.length > 0 && !errors.phone}
              onChange={(value) => {
                setCompanyPhone(value);
              }}
            />
            <SoftBox mt={0.75}>
              <SoftTypography component="div" variant="caption" color="error">
                <ErrorMessage name={phone.name} />
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6} mb={0.75} >
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Deuxième Téléphone
            </SoftTypography>
            <PhoneInput
              id="secondary-phone-enterprise"
              country={"tn"}
              value={secondCompanyPhone}
              enableSearch
              inputStyle={{
                width: "100%",
              }}
              onChange={(value) => {
                setSecondCompanyPhone(value);
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} mb={0.75} >
          <Grid item xs={12} sm={6}>
            <SoftTypography  component="label" variant="caption" fontWeight="bold">
               pays*
            </SoftTypography>
            <SoftSelect
              inputId="enterprise-country"
              name="country"
              circular={true}
              size={"small"}
              variant={"gradient"}
              value={dataToSend.country&&{ value: dataToSend.country, label: dataToSend.country }}
              options={countries.map((country) => ({ value: country, label: country }))}
              onChange={handleCountryChange}
            />
             <SoftBox mt={0.75}>
              <SoftTypography component="div" variant="caption" color="error">
                <ErrorMessage name={country.name} />
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6} mb={0.75}>
            <SoftTypography component="label" 
            variant="caption" fontWeight="bold">
               ville*
            </SoftTypography>
            <SoftSelect
              inputId="enterprise-city"
              name="city"
              circular={true}
              variant={"gradient"}
              value={dataToSend.city&&{ value: dataToSend.city, label: dataToSend.city }}
              size={"small"}
              options={cityOptions}
              onChange={handleCityChange}
            />
            <SoftBox mt={0.75}>
              <SoftTypography component="div" variant="caption" color="error">
                <ErrorMessage name={city.name} />
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={0.75}>
          <Grid item xs={12} sm={6}>
            <EnterpriseFormField
              id="physiqual-address-enterprise"
              type={physiqualAddress.type}
              label={physiqualAddress.label}
              name={physiqualAddress.name}
              value={physiqualAddressX}
             // 
              inputProps={{
                maxLength: 50
              }}
              placeholder={physiqualAddress.placeholder}
              error={errors.physiqualAddress && touched.physiqualAddress}
              success={physiqualAddressX.length > 0 && !errors.physiqualAddress}
            />
          </Grid>
          <Grid item xs={12} sm={6} mb={0.75}>
            <EnterpriseFormField
              id="post-code-enterprise"
              type={postCode.type}
              label={`${postCode.label}`}
              name={postCode.name}
              value={postCodeX}
              
              placeholder={postCode.placeholder}
              error={errors.postCode && touched.postCode}
              success={postCodeX.length > 0 && !errors.postCode}
            />
          </Grid>
        </Grid>
        <Grid sx={{ mr: "auto", display: "flow", }} mb={0.75} item xs={12}>
          <SoftButton
            id="upload-files-enterprise"
            sx={{ mb: 1 }}
            component="label"
            onChange={handleFileUpload}
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Télécharger des fichiers
            <VisuallyHiddenInput type="file" />
          </SoftButton>
          <Autocomplete mb={0.75}
            multiple
            id="tags-standard"
            placeholder="Télécharger des fichiers"
            options={options}
            value={selectedOptions}
            filterSelectedOptions
            freeSolo
            renderInput={(params) => (
              <TextField {...params} variant="standard" placeholder="Télécharger des fichiers" />
            )}
            onChange={(event, newValue) => {
              setSelectedOptions(newValue);
            }}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.label === value.label}
          />
        </Grid>
        <Grid container spacing={3} mb={0.75}>
          <Grid sx={{}} item xs={12}>
            <EnterpriseFormField
              id="description-enterprise"
              type={description.type}
              label={description.label}
              name={description.name}
              value={descriptionX}
              inputProps={{
                maxLength: 250
              }}
              placeholder={description.placeholder}
              multiline
              rows={5}
            />
          </Grid>
        </Grid>

      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
EnterpriseInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default EnterpriseInfo;
