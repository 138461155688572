import React, { useEffect, useState } from "react";
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import api from "utils/axios";
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSoftUIController } from "context";

function LevelActions({ level, handleFetch, setDetailsData, setShowDetails }) {
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(level.name);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [optionsSpeciality, setOptionsSpeciality] = useState([]);
  const [controller, dispatch] = useSoftUIController();
  const [sousPeriodes, setsousPeriodes] = useState(level?.sousPeriodes || []);
  const [errorMessageSp, setErrorMessageSp] = useState("");

  const [OptionsSP, setOptionsSP] = useState([])

  useEffect(() => {
    api.get(`/faculty/all-sp/${facility.scholarityConfigId}`).then((res) => {
          setOptionsSP(
            res.data.map((item) => {
              return {
                value: item._id,
                label: item.name,
              };
            })
          );
        });
      }, []);
 
  const [speciality, setSpeciality] = useState(level?.speciality?.code || "");

  const handleUpdate = async () => {
    try {
      setLoading(true);

      if (sous.length===0) {
        setErrorMessageSp("vous avez sélectionné une sous période au moins");
      }else {
        const response = await api.patch(`/faculty/update-levels-by-sp/${level._id}`, { sousPeriodes: sous });
        setOpen(false);
        Swal.fire("Success!", "le niveau modifié avec succès", "success");
        setErrorMessageSp('');
        handleFetch();
      }
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setErrorMessageSp('')
    setName(level.name);
  };
  const handleSpecialityChange = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      const allOptions = optionsSpeciality.map((spec) => spec.label);
      const initialValues = name;
      const splittedValue = initialValues.split("-");
      const lastValue = splittedValue[splittedValue?.length - 1];

      if (allOptions.includes(lastValue)) {
        const updatedName = splittedValue.slice(0, -1).join("-");
        setName(`${updatedName}-${newValue.label}`);
      } else {
        setName(`${initialValues}-${newValue.label}`);
      }

      setSpeciality(newValue.value);
    }
  };

  const showAlert = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous voulez supprimer ce niveau ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(`/delete-level/${level.code}`);
            if (response) {
              (await Swal.fire("Suppression !", "Le niveau a été supprimée.", "success"))
                .isConfirmed && handleFetch();
            }
          } catch (error) {
            Swal.fire("SErreur!", "Le niveau n'a pas été supprimée.", "error");
          }
        }
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };
  const sous = (sousPeriodes[0]?.sousPeriode ?? sousPeriodes[0]?.value) || sousPeriodes?.map(item => item._id)
  return (
    <SoftBox display="flex" alignItems="center">
      {/* <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Consulter" placement="left">
          <VisibilityIcon
            onClick={() => {
              setDetailsData(level);
              setShowDetails(true);
            }}
          />
        </Tooltip>
      </SoftTypography> */}
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Modifier" placement="top">
            <Icon onClick={() => setOpen(true)} id="edit-enterprise-page">
              edit
            </Icon>
          </Tooltip>
          <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={handleClose}
            onKeyDown={handleKeyPress}
          >
            <DialogTitle>Modifier Niveau</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.grey[200],
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <DialogContentText>
                Vous devez saisir le nouveau nom de votre niveau.
              </DialogContentText>
              <Grid sx={{ mt: 1 }}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "flex-start",
                  }}
                >
                    <Grid item xs={12} sm={12}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      sous-période
                    </SoftTypography>
                    <Autocomplete
                    multiple
                    options={OptionsSP}
                    getOptionLabel={(option) => option?.label }
                    clearIcon={null}
                    defaultValue={level?.sousPeriodes?.map((sousPeriode) => OptionsSP?.find((option) => option?.value === sousPeriode?._id))|| null}
                    onChange={(event, newValue) => {
                        setsousPeriodes(newValue);
                        const updatedInputs = [...sousPeriodes];
                        if (updatedInputs?.length > 0) {
                          updatedInputs[0].sousPeriode = newValue.map((value) => value.value);
                          setsousPeriodes(updatedInputs);
                        }
                      }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Veuillez sélectionner les sous periodes"
                      />
                    )}
                    isOptionEqualToValue={(option, value) => option?.label === value?.label}
                  />
                   {sous?.length ===0 && (
                    <Typography mt={0.75} variant="caption" color="error">
                      {errorMessageSp}
                    </Typography>
                  )}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">

                <SoftButton
                  onClick={handleClose}
                  sx={{ mt: 2 }}
                  variant="outlined"
                  color="dark" size="small"
                >

                  Annuler
                </SoftButton>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <SoftButton
                    onClick={handleUpdate}
                    sx={{ mt: 2 }}
                    variant="gradient"
                    color="customized" size="small"
                  >
                    Modifier
                  </SoftButton>
                )}
              </SoftBox>
            </DialogActions>
          </Dialog>
        </SoftTypography>
      </SoftBox>
      {/* {controller?.user?.info?.role === "company-admin" && (
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="supprimer" placement="left">
            <Icon id="delete-enterprise" onClick={showAlert}>
              delete
            </Icon>
          </Tooltip>
        </SoftTypography>
      )} */}
    </SoftBox>
  );
}
LevelActions.propTypes = {
  level: PropTypes.object.isRequired,
  handleFetch: PropTypes.func.isRequired,
  setShowDetails: PropTypes.bool.isRequired,
  setDetailsData: PropTypes.array.isRequired,
};

export default LevelActions;
