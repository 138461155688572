import { Card, CircularProgress, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Form, Formik } from "formik";
import DashboardNavbar from "layouts/DashboardNavbar";
import React, {  useState } from "react";
import CommercialInfo from "./components/CommercialInfo";
import form from "components/shared-users/schemas/form";
import Swal from "sweetalert2";
import validations from "components/shared-users/schemas/validations";
import initialValues from "components/shared-users/schemas/initialValues";
import api from "utils/axios";
import { useNavigate } from "react-router-dom";
import Header from "layouts/pages/profile/components/Header";

function getStepContent(stepIndex, formData) {
  return <CommercialInfo formData={formData} />;
}
export default function NewCommercial() {
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { formId, formField } = form;
  const navigate = useNavigate();
  const currentValidation = validations[activeStep];

  const submitForm = async (values, actions) => {
    const form = new FormData();
    const info = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      address: values.address,
      zipCode: values.zipCode,
      siteWeb: values.siteWeb,
      passeport: values.passeport,
      cin: values.cin,
      rib: values.rib,
      bank: values.bank,
      comment: values.description,
      phoneNumber: values.phone,
      secondaryPhoneNumber: values.secondaryPhoneNumber,
      gender: values.gender,
      files: values.files,
      occupation: values.occupation,
      imageUrl: values.imageUrl,
      birthday: values.birthday,
      cnssNumber: values.cnssNumber,
      country: values.country,
      city: values.city,
      isFormateur: values.isFormateur,
      role: "commercial",
    };

    try {
      form.append("firstName", info.firstName);
      form.append("lastName", info.lastName);
      form.append("email", info.email);
      form.append("cin", info.cin);
      form.append("role", info.role);
      form.append("siteWeb", info.siteWeb);
      form.append("occupation", info.occupation);
      form.append("address", info.address);
      form.append("zipCode", info.zipCode);
      form.append("bank", info.bank);
      form.append("rib", info.rib);
      form.append("birthday", info.birthday);
      form.append("cnssNumber", info.cnssNumber);
      form.append("phoneNumber", info.phoneNumber);
      form.append("secondaryPhoneNumber", info.secondaryPhoneNumber);
      form.append("gender", info.gender);
      form.append("country", info.country);
      form.append("city", info.city);
      form.append("passeport", info.passeport);
      form.append("comment", info.comment);
      form.append("imageUrl", info.imageUrl);
      form.append("isFormateur", info.isFormateur);
      info.files &&
        info.files.length > 0 &&
        info.files.forEach((item) => {
          form.append("files", item.value);
        });
      setIsLoading(true)
      const data = await api.post("/add-One-user", form);
      if (data.status === 201) {
        Swal.fire("success !", "Le commercial a été ajouté avec succès", "success").then(() => {
          setIsLoading(false)
          navigate("/commercials/commercial");
        });
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
        Swal.fire("Erreur!", error.response.data.message, "error")
      } else {
        Swal.fire("Erreur!", "Une erreur s'est produite. Veuillez réessayer ultérieurement.", "error")
      }
    }

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };
  if (error) {
    Swal.fire("Error!", error === "dublicate inputs" ? "L' adresse Mail est déjà utilisée." : error, "error").then(() => {
      setError(null);
    })

  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title={"Ajouter un commercial"} />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <SoftButton id="btn-cancel" onClick={() => navigate('/commercials/commercial')} variant="outlined" color="dark" size="small">
                            Annuler
                          </SoftButton>
                          {
                            isLoading ? <CircularProgress /> : (
                              <SoftButton id="btn-add" type="submit" variant="gradient" color="purple" size="small">
                                Ajouter
                              </SoftButton>)
                          }

                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>

    </DashboardLayout>
  );
}
