import { AppBar, Box, IconButton, Modal, Typography, useMediaQuery } from "@mui/material";
import SoftBox from "components/SoftBox";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import SoftTypography from 'components/SoftTypography';
import validationSchema from "./validerChamps/ValidationSchema";
import { Close } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import moment from "moment";
import api from "utils/axios";
import Swal from "sweetalert2";
import { useTheme } from "@mui/styles";
import { useState } from "react";

function AjouterCharge({ open, handleCloseModal, handleFinanciers, typeValue }) {
  const theme = useTheme();

  const [titre, setTitre] = useState("");
  const [valeur, setValeur] = useState(0);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const initialValues = {
    titre: "",
    valeur: undefined,
  };

  const createFinancier = () => {
    if (
      typeValue !== "" &&
      titre !== "" &&
      valeur > 0 &&
      date !== ""
    ) {
      api
        .post("/create-financier", {
          type: typeValue,
          titre: titre,
          valeur: valeur,
          date: date,
        })
        .then((response) => {
          handleFinanciers();
          handleCloseModal();
          Swal.fire({
            title: "Succès",
            text: "Ajouter Charge réussie",
            icon: "success",
            confirmButtonText: "ok",
          });
        });
    } else {
      if(valeur < 0){
        Swal.fire({
          title: "Warning",
          text: "Veuillez ajouter une valeur positive",
          icon: "warning",
          confirmButtonText: "ok",
        });
      } else {
        Swal.fire({
          title: "Warning",
          text: "Veuillez ajouter tous les champs obligatoires.",
          icon: "warning",
          confirmButtonText: "ok",
        });
      }
    }
  };

  return (
    <Modal 
      open={open}
      onClose={handleCloseModal}
      sx={{
        "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
          bgcolor: "rgba(0,0,0,0.03) !important",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          height: "61.5%",
          overflowY: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: "2px 2px 2px 2px grey",
          p: "24px 32px",
        }}
      >
        <Formik initialValues={initialValues} validationSchema={validationSchema}>
          {({ errors, touched, handleSubmit, handleChange, handleBlur }) => (
            <Form onSubmit={handleSubmit} style={{ marginTop: "8px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="h5" sx={{ flexGrow: "1" }}>
                {typeValue === "Fixed" ? "Ajouter Charge Fixe" : "Ajouter Charge Variable"}</Typography>                
                <IconButton onClick={handleCloseModal}>
                  <Close
                    fontSize="24px !important"
                    sx={{
                      "&:hover": {
                        bgcolor: grey[100],
                        borderRadius: "50%",
                      },
                    }}
                  />
                </IconButton>
              </Box>
              <Box sx={{ mt: "16px", width: "100%" }}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mb: 0.5 }}
                >
                  Titre
                </SoftTypography>
                <Field name="titre">
                  {({ field }) => (
                    <SoftInput
                      {...field}
                      placeholder="Titre"
                      variant="outlined"
                      error={!!errors.titre && !!touched.titre}
                      success={field.value.length >= 2 && !!touched.titre}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        handleChange(event);
                        setTitre(event.target.value);
                      }}
                    />
                  )}
                </Field>
                {!!errors.titre && !!touched.titre && (
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "400", fontSize: "12px" }}
                    color="error"
                  >
                    {errors.titre}
                  </Typography>
                )}
              </Box>
              <Box sx={{ mt: "16px", width: "100%" }}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mb: 0.5 }}
                >
                  Valeur
                </SoftTypography>
                <Field name="valeur">
                  {({ field }) => (
                    <SoftInput
                      {...field}
                      type="number"
                      placeholder="en DT"
                      variant="outlined"
                      error={!!errors.valeur && !!touched.valeur}
                      success={valeur > 0 && !!touched.valeur}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        handleChange(event);
                        setValeur(event.target.value);
                      }}
                    />
                  )}
                </Field>
                {valeur <= 0 && !!touched.valeur && (
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "400", fontSize: "12px" }}
                    color="error"
                  >
                    {errors.valeur}
                  </Typography>
                )}
              </Box>
              <Box sx={{ mt: "16px", width: "100%" }}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mb: 0.5 }}
                >
                  Date
                </SoftTypography>
                <SoftInput
                  type="date"
                  value={date}
                  variant="outlined"
                  onChange={(event) => {
                    setDate(event.target.value);
                  }}
                />
              </Box>
              <Box
                sx={{
                  mt: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SoftButton
                  type="reset"
                  sx={{ mt: 2 }}
                  variant="outlined"
                  color="dark"
                  size="small"
                  onClick={handleCloseModal}
                >
                  Annuler
                </SoftButton>
                <SoftButton
                  type="submit"
                  sx={{ mt: 2 }}
                  variant="gradient"
                  color="purple"
                  size="small"
                  onClick={createFinancier}
                >
                  Ajouter
                </SoftButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

AjouterCharge.propTypes = {
  open: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleFinanciers: PropTypes.func,
  typeValue: PropTypes.string.isRequired,
};

export default AjouterCharge;
