// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import ArrowCircleDownSharpIcon from '@mui/icons-material/ArrowCircleDownSharp';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
function ProfilesListInvitation({ title, profiles }) {
  const renderProfiles = profiles.map(({ image, name, description, action }) => (
    <SoftBox key={name} component="li" display="flex" alignItems="center" py={1} mb={1}  
    sx={{ backgroundColor:"#F3F2F8",padding:"10px",borderRadius:"10px" }}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt="something here" variant="rounded" shadow="md" />
      </SoftBox>
      <SoftBox
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="text">
          {description}
        </SoftTypography>
      </SoftBox>
      <SoftBox ml="auto">
        {action.type === "internal" ? (
          <SoftBox  variant="text" color="info" display="flex" alignItems="center">
            <DoneIcon  style={{color:"#7F47E6",marginRight:"10px",cursor:"pointer"}}/>
            <ClearIcon  style={{color:"#7F47E6",marginRight:"5px",cursor:"pointer"}}/>
          </SoftBox>
        ) : (
          <SoftBox
            target="_blank"
            rel="noreferrer"
            variant="text"
            color={action.color}
          >
            <DoneIcon  style={{color:"#7F47E6",marginRight:"10px",cursor:"pointer"}}/>
            <ClearIcon  style={{color:"#7F47E6",marginRight:"5px",cursor:"pointer"}}/>
          </SoftBox>
        )}
      </SoftBox>
    </SoftBox>
  ));

  return (
    <Card sx={{ height: "100%"}}>
      <SoftBox pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </SoftBox>
      </SoftBox>
      <SoftBox display="flex" alignItems="center" 
      justifyContent="flex-end" mb={2} mr={2} style={{color:"#767676",cursor:"pointer"}}>
         <SoftTypography variant="h6" fontWeight="400" style={{color:"#767676"}} mr={0.5}>
          Voir plus
      </SoftTypography>
      <ArrowCircleDownSharpIcon/>
      </SoftBox>
     
    </Card>
  );
}

// Typechecking props for the ProfilesList
ProfilesListInvitation.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProfilesListInvitation;
