import SoftBox from "components/SoftBox";
import React, { useEffect, useState } from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { ErrorMessage, useFormikContext } from "formik";
import api from "utils/axios";

export default function SalleInfo({ formData }) {
  const [error, setError] = useState("");
  const [optionsBlocs, setOptionsBlocs] = useState([]);
  const [optionsSallesCours, setOptionsSallesCours] = useState([]);
  const [optionsSallesTd, setOptionsSallesTd] = useState([]);
  const [optionsSallesTp, setOptionsSallesTp] = useState([]);
  const [optionsSallesMp, setOptionsSallesMp] = useState([]);
  const [selectedBlocCours, setSelectedPBlocCours] = useState();
  const [selectedBlocTd, setSelectedPBlocTd] = useState();
  const [selectedBlocTp, setSelectedPBlocTp] = useState();
  const [selectedBlocMp, setSelectedPBlocMp] = useState();
  const { formField, errors, touched, values } = formData;
  const { setFieldError, setFieldValue } = useFormikContext();
  const facility = JSON.parse(localStorage.getItem("facility"));
  const { bloccours, sallecours, bloctd, salletd, bloctp, salletp, blocmp, sallemp } = formField;
  const {
    checkedCours: checkedCoursV,
    checkedTd: checkedTdV,
    checkedTp: checkedTpV,
    checkedMp: checkedMpV,
    bloccours: bloccoursV,
    sallecours: sallecoursV,
    bloctd: bloctdV,
    salletd: salletdV,
    bloctp: bloctpV,
    salletp: salletpV,
    blocmp: blocmpV,
    sallemp: sallempV,
    vhtd: vhtdV,
    durationtd: durationtdV,
    vhtp: vhtpV,
    durationtp: durationtpV,
    vhmp: vhmpV,
    durationmp: durationmpV,
  } = values;
  const [inputs, setInputs] = useState([
    {
      bloccours: bloccoursV,
      sallecours: sallecoursV,
      bloctd: bloctdV,
      salletd: salletdV,
      bloctp: bloctpV,
      salletp: salletpV,
      blocmp: blocmpV,
      sallemp: sallempV,
    },
  ]);
  values.bloccours = inputs[0].bloccours;
  values.sallecours = inputs[0].sallecours;
  values.bloctd = inputs[0].bloctd;
  values.salletd = inputs[0].salletd;
  values.bloctp = inputs[0].bloctp;
  values.salletp = inputs[0].salletp;
  values.blocmp = inputs[0].blocmp;
  values.sallemp = inputs[0].sallemp;

  useEffect(() => {
    // Fetch optionsBlocs
    const fetchOptionsBlocs = async () => {
      try {
        const res = await api.get(`/faculty/all-blocs/${facility?.scholarityConfigId}`);
        const options = res.data.map((item) => ({ value: item._id, label: item.name }));
        setOptionsBlocs(options);
      } catch (error) {
        handleError(error);
      }
    };
    // Fetch optionsSalles based on selectedBlocCours
    const fetchOptionsSalles = async (selectedBloc, setOptionsSalles) => {
      if (selectedBloc) {
        try {
          const res = await api.get(
            `/faculty/salles/${facility?.scholarityConfigId}/${selectedBloc}`
          );
          const options = res.data.map((item) => ({ value: item._id, label: item.name }));
          setOptionsSalles(options);
        } catch (error) {
          handleError(error);
        }
      }
    };

    fetchOptionsBlocs();
    fetchOptionsSalles(selectedBlocCours, setOptionsSallesCours);
    fetchOptionsSalles(selectedBlocTd, setOptionsSallesTd);
    fetchOptionsSalles(selectedBlocTp, setOptionsSallesTp);
    fetchOptionsSalles(selectedBlocMp, setOptionsSallesMp);
  }, [
    facility?.scholarityConfigId,
    selectedBlocCours,
    selectedBlocTd,
    selectedBlocTp,
    selectedBlocMp,
  ]);

  return (
    <SoftBox mt={1.625}>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        {values.categorie === "Matiere" && (
          <>
            {checkedCoursV && (
              <>
                {" "}
                <Grid item xs={12} sm={2}>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={6} sm={6}>
                      <SoftTypography
                        variant="caption"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        COURS
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      {/*  <Checkbox
                    defaultChecked={checkedCoursV}
                    onChange={() => {
                      setFieldValue("checkedCours", !checkedCoursV);
                      //setCheckedCours(!checkedCours);
                       //setCheckedPcExamen(!checkedPcExamen);
                                  //setCheckedPcDs1(!checkedPcDs1);
                                 // setCheckedPcDs2(!checkedPcDs2);
                                 // setCheckedPcCc(!checkedPcCc); 
                    }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  /> */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        bloc*
                      </SoftTypography>
                      <Autocomplete
                        options={optionsBlocs}
                        title="Select bloc"
                        clearIcon={null}
                        getOptionLabel={(option) => option.label}
                        disabled={!checkedCoursV}
                        value={
                          (optionsBlocs.length > 0 &&
                            optionsBlocs.find((option) => option.value === values.bloccours)) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          if (newValue !== null && newValue !== undefined) {
                            const newInputs = [...inputs];
                            newInputs[0].bloccours = newValue.value;
                            setSelectedPBlocCours(newValue.value);
                            setInputs(newInputs);
                            setFieldError("bloccours", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            value={bloccoursV}
                            placeholder="Tous les blocs"
                            error={errors.bloccours && touched.bloccours}
                            success={bloccoursV !== "" && !errors.bloccours}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={bloccours.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Salles*
                      </SoftTypography>
                      <Autocomplete
                        multiple
                        options={optionsSallesCours}
                        title="Select salle"
                        clearIcon={null}
                        getOptionLabel={(option) => option.label}
                        disabled={!checkedCoursV}
                        value={optionsSallesCours.find(
                          (option) => option.value === inputs[0].sallecours
                        )}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            const newInputs = [...inputs];
                            newInputs[0].sallecours = newValue.map((value) => value.value) || null;
                            setInputs(newInputs);
                            setFieldError("sallecours", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            value={sallecoursV}
                            placeholder="Tous les salles"
                            error={errors.sallecours && touched.sallecours}
                            success={sallecoursV !== "" && !errors.sallecours}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={sallecours.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {checkedTdV && (
              <>
                <Grid item xs={12} sm={2}>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={6} sm={6}>
                      <SoftTypography
                        variant="caption"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        TD
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      {/*  <Checkbox
                    defaultChecked={checkedTdV}
                    onChange={() => {
                      setFieldValue("checkedTd", !checkedTdV);
                      if (!checkedTd) {
                        setFieldError("vhtd", errors.vhtd);
                        setFieldError("durationtd", errors.durationtd);
                      } else {
                        setFieldError("vhtd", undefined);
                        setFieldError("durationtd", undefined);
                      }
                      //setCheckedPcTd(!checkedPcTd);
                    }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  /> */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        bloc*
                      </SoftTypography>
                      <Autocomplete
                        options={optionsBlocs}
                        title="Select bloc"
                        clearIcon={null}
                        getOptionLabel={(option) => option.label}
                        disabled={!checkedTdV}
                        value={
                          (optionsBlocs.length > 0 &&
                            optionsBlocs.find((option) => option.value === values.bloctd)) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          if (newValue !== null && newValue !== undefined) {
                            const newInputs = [...inputs];
                            newInputs[0].bloctd = newValue.value;
                            setSelectedPBlocTd(newValue.value);
                            setInputs(newInputs);
                            setFieldError("bloctd", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            value={bloctdV}
                            placeholder="Tous les blocs"
                            error={errors.bloctd && touched.bloctd}
                            success={bloctdV !== "" && !errors.bloctd}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={bloctd.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Salles*
                      </SoftTypography>
                      <Autocomplete
                        multiple
                        options={optionsSallesTd}
                        title="Select salle"
                        clearIcon={null}
                        getOptionLabel={(option) => option.label}
                        disabled={!checkedTdV}
                        value={optionsSallesTd.find((option) => option.value === values.salletd)}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            const newInputs = [...inputs];
                            newInputs[0].salletd = newValue.map((value) => value.value) || null;
                            setInputs(newInputs);
                            setFieldError("salletd", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Tous les salles"
                            error={errors.salletd && touched.salletd}
                            success={salletdV !== "" && !errors.salletd}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={salletd.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {checkedTpV && (
              <>
                <Grid item xs={12} sm={2}>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={6} sm={6}>
                      <SoftTypography
                        variant="caption"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        TP
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      {/*  <Checkbox
                    defaultChecked={checkedTpV}
                    onChange={() => {
                      setFieldValue("checkedTp", !checkedTpV);
                      //setCheckedPcTp(!checkedPcTp);
                    }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  /> */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        bloc*
                      </SoftTypography>
                      <Autocomplete
                        options={optionsBlocs}
                        title="Select bloc"
                        clearIcon={null}
                        getOptionLabel={(option) => option.label}
                        disabled={!checkedTpV}
                        value={
                          (optionsBlocs.length > 0 &&
                            optionsBlocs.find((option) => option.value === values.bloctp)) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          if (newValue !== null && newValue !== undefined) {
                            const newInputs = [...inputs];
                            newInputs[0].bloctp = newValue.value;
                            setSelectedPBlocTp(newValue.value);
                            setInputs(newInputs);
                            setFieldError("bloctp", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            value={bloctpV}
                            placeholder="Tous les blocs"
                            error={errors.bloctp && touched.bloctp}
                            success={bloctpV !== "" && !errors.bloctp}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={bloctp.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Salles*
                      </SoftTypography>
                      <Autocomplete
                        multiple
                        options={optionsSallesTp}
                        title="Select salle"
                        clearIcon={null}
                        getOptionLabel={(option) => option.label}
                        disabled={!checkedTpV}
                        value={optionsSallesTp.find((option) => option.value === values.salletp)}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            const newInputs = [...inputs];
                            newInputs[0].salletp = newValue.map((value) => value.value) || null;
                            setInputs(newInputs);
                            setFieldError("salletp", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Tous les salles"
                            error={errors.salletp && touched.salletp}
                            success={salletpV !== "" && !errors.salletp}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={salletp.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {checkedMpV && (
              <>
                <Grid item xs={12} sm={2}>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={6} sm={6}>
                      <SoftTypography
                        variant="caption"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        Mini projet
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      {/* <Checkbox
                    defaultChecked={checkedMpV}
                    onChange={() => {
                      setFieldValue("checkedMp", !checkedMpV);
                      // setCheckedPcMp(!checkedPcMp);
                    }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  /> */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        bloc*
                      </SoftTypography>
                      <Autocomplete
                        options={optionsBlocs}
                        title="Select bloc"
                        clearIcon={null}
                        getOptionLabel={(option) => option.label}
                        disabled={!checkedMpV}
                        value={
                          (optionsBlocs.length > 0 &&
                            optionsBlocs.find((option) => option.value === values.blocmp)) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          if (newValue !== null && newValue !== undefined) {
                            const newInputs = [...inputs];
                            newInputs[0].blocmp = newValue.value;
                            setSelectedPBlocMp(newValue.value);
                            setInputs(newInputs);
                            setFieldError("blocmp", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            value={blocmpV}
                            placeholder="Tous les blocs"
                            error={errors.blocmp && touched.blocmp}
                            success={blocmpV !== "" && !errors.blocmp}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={blocmp.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Salles*
                      </SoftTypography>
                      <Autocomplete
                        multiple
                        options={optionsSallesMp}
                        title="Select salle"
                        clearIcon={null}
                        getOptionLabel={(option) => option.label}
                        disabled={!checkedMpV}
                        value={optionsSallesMp.find((option) => option.value === sallemp)}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            const newInputs = [...inputs];
                            newInputs[0].sallemp = newValue.map((value) => value.value) || null;
                            setInputs(newInputs);
                            setFieldError("sallemp", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            //value={inputs[0].blabla}
                            placeholder="Tous les salles"
                            error={errors.sallemp && touched.sallemp}
                            success={sallempV !== "" && !errors.sallemp}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={sallemp.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </SoftBox>
  );
}
SalleInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
