import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import api from "utils/axios";
import { ErrorMessage, useFormikContext } from "formik";
import FormFieldSubject from "../schema/FormFieldSubject";
export default function SubjectInfo({ formData }) {
  const [optionsFilieres, setOptionsFilieres] = useState([]);
  const [optionsParcours, setOptionsParcours] = useState([]);
  const [optionsLevels, setOptionsLevels] = useState([]);
  const [optionsModules, setOptionsModules] = useState([]);
  const [selectedParcours, setSelectedParcours] = useState();
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [optionsCategories, setOptionsCategories] = useState([
    { value: "Matiere", label: "Matière" },
    { value: "PFE", label: "PFE" },
    { value: "PFA", label: "PFA" },
    { value: "Stage", label: "Stage" },
    { value: "PI", label: "PI" },
  ]);
  const { setFieldError } = useFormikContext();
  const { formField, errors, touched, values } = formData;
  const { code, name, filiere, niveau, parcour, module, coefficient, credit, categorie } =
    formField;
  const {
    code: codeV,
    name: nameV,
    filiere: filiereV,
    niveau: niveauV,
    parcour: parcourV,
    module: moduleV,
    coefficient: coefficientV,
    credit: creditV,
    categorie: categorieV,
  } = values;
  const [inputs, setInputs] = useState([
    {
      filiere: filiereV,
      level: niveauV,
      parcour: parcourV,
      module: moduleV,
      category: "Matiere" || categorieV,
    },
  ]);
  values.filiere = inputs[0].filiere;
  values.niveau = inputs[0].level;
  values.parcour = inputs[0].parcour;
  values.module = inputs[0].module;
  values.categorie = inputs[0].category;
  useEffect(() => {
    api.get(`/faculty/all-filiere/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsFilieres(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
          niveaux: item.niveaux,
        }))
      );
    });
    if (selectedParcours) {
      api
        .get(`/faculty/get-all-modules-by-parcour/${selectedParcours}`)
        .then((res) => {
          setOptionsModules(
            res?.data?.map((item) => ({
              value: item._id,
              label: item.name,
            }))
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setError("Resource not found:", error.response.data);
            // Handle 404 error gracefully (e.g., display a message to the user)
          } else {
            setError("API request failed:", error.message);
            // Handle other errors
          }
        });
    }
  }, [selectedParcours]);
  return (
    <SoftBox mt={1.625}>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        <Grid item xs={12} sm={6}>
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="medium"
            color="text"
            sx={{ mb: 0.5 }}
          >
            catégories*
          </SoftTypography>
          <Autocomplete
            options={optionsCategories}
            title="Select categories"
            clearIcon={null}
            required={true}
            getOptionLabel={(option) => option.label}
            value={
              optionsCategories.length > 0 &&
              optionsCategories.find((option) => option.value === inputs[0].category)
            }
            onChange={(event, newValue) => {
              if (newValue !== null) {
                const newInputs = [...inputs];
                newInputs[0].category = newValue.value;
                setInputs(newInputs);
                // You might want to set newInputs back to your state, depending on the context.
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={true}
                variant="standard"
                placeholder="Tous les catégories"
              />
            )}
            disableClearable={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}>
          <FormFieldSubject
            name={code.name}
            margin="normal"
            id="code"
            label={code.label}
            type="text"
            fullWidth
            placeholder="code de la matière"
            inputProps={{
              minLength: 2,
              maxLength: 50,
            }}
            variant="standard"
            value={codeV}
            error={errors.code && touched.code}
            success={codeV.length > 0 && !errors.code}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormFieldSubject
            name={name.name}
            margin="normal"
            id="name"
            label={name.label}
            type="text"
            fullWidth
            placeholder="nom de la matière"
            inputProps={{
              minLength: 2,
              maxLength: 50,
            }}
            variant="standard"
            value={nameV}
            error={errors.name && touched.name}
            success={nameV.length > 0 && !errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="medium"
            color="text"
            sx={{ mb: 0.5 }}
          >
            Filière*
          </SoftTypography>
          <Autocomplete
            name={filiere.name}
            options={optionsFilieres}
            title="selectionner filière"
            clearIcon={null}
            getOptionLabel={(option) => option.label}
            value={
              optionsFilieres.length > 0 &&
              optionsFilieres.find((option) => option.value === inputs[0].filiere)
            }
            onChange={(e, newValue) => {
              if (newValue !== null && newValue !== undefined) {
                const newInputs = [...inputs];
                newInputs[0].filiere = newValue.value;
                setOptionsLevels(
                  newValue?.niveaux?.map((item) => ({
                    value: item._id,
                    label: item.name,
                    parcours: item.parcours,
                  }))
                );
                setInputs(newInputs);
                setFieldError("filiere", undefined);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={filiere.name}
                variant="standard"
                value={inputs[0].filiere}
                placeholder="Toutes les filières"
                error={errors.filiere && touched.filiere}
                success={filiereV !== "" && !errors.filiere}
              />
            )}
          />
          <SoftBox mt={0.75}>
            <SoftTypography component="div" variant="caption" color="error">
              <ErrorMessage name={filiere.name} />
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="medium"
            color="text"
            sx={{ mb: 0.5 }}
          >
            Niveau*
          </SoftTypography>
          <Autocomplete
            options={optionsLevels}
            title="selectionner niveau"
            clearIcon={null}
            getOptionLabel={(option) => option.label}
            value={
              (optionsLevels.length > 0 &&
                optionsLevels.find((option) => option.value === inputs[0].level)) ||
              null
            }
            onChange={(e, newValue) => {
              if (newValue !== null && newValue !== undefined) {
                const newInputs = [...inputs];
                newInputs[0].level = newValue.value;
                setOptionsParcours(
                  newValue?.parcours?.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))
                );
                setInputs(newInputs);
                setFieldError("niveau", undefined);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                value={inputs[0].level}
                placeholder="Toutes les niveaux"
                error={errors.niveau && touched.niveau}
                success={niveauV !== "" && !errors.niveau}
              />
            )}
          />
          <SoftBox mt={0.75}>
            <SoftTypography component="div" variant="caption" color="error">
              <ErrorMessage name={niveau.name} />
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="medium"
            color="text"
            sx={{ mb: 0.5 }}
          >
            Parcours*
          </SoftTypography>
          <Autocomplete
            options={optionsParcours}
            defaultValue={null}
            clearIcon={null}
            getOptionLabel={(option) => option.label}
            value={
              (optionsParcours.length > 0 &&
                optionsParcours.find((option) => option.value === inputs[0].parcour)) ||
              null
            }
            onChange={(event, newValue) => {
              if (newValue !== null) {
                const newInputs = [...inputs];
                newInputs[0].parcour = newValue.value;
                setInputs(newInputs);
                setSelectedParcours(newValue.value);
                setFieldError("parcour", undefined);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Toutes les parcours"
                error={errors.parcour && touched.parcour}
                success={parcourV !== "" && !errors.parcour}
              />
            )}
          />
          <SoftBox mt={0.75}>
            <SoftTypography component="div" variant="caption" color="error">
              <ErrorMessage name={parcour.name} />
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="medium"
            color="text"
            sx={{ mb: 0.5 }}
          >
            Module*
          </SoftTypography>
          <Autocomplete
            options={optionsModules}
            title="Select module"
            clearIcon={null}
            required={true}
            getOptionLabel={(option) => option.label}
            value={
              (optionsModules.length > 0 &&
                optionsModules.find((option) => option.value === inputs[0].module)) ||
              null
            }
            onChange={(event, newValue) => {
              if (newValue !== null) {
                const newInputs = [...inputs];
                newInputs[0].module = newValue.value;
                setInputs(newInputs);
                setFieldError("module", undefined);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Tous les modules"
                error={errors.module && touched.module}
                success={moduleV !== "" && !errors.module}
              />
            )}
          />
          <SoftBox mt={0.75}>
            <SoftTypography component="div" variant="caption" color="error">
              <ErrorMessage name={module.name} />
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormFieldSubject
            id="coeff"
            name={coefficient.name}
            label={coefficient.label}
            type="number"
            margin="normal"
            variant="standard"
            fullWidth
            placeholder="Coefficient"
            value={coefficientV}
            error={errors.coefficient && touched.coefficient}
            success={coefficientV >= 1 && !errors.coefficient}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormFieldSubject
            id="credit"
            name={credit.name}
            label={credit.label}
            type="number"
            margin="normal"
            variant="standard"
            fullWidth
            placeholder="credit"
            value={creditV}
            error={errors.credit && touched.credit}
            success={creditV > 0 && !errors.credit}
          />
        </Grid>
      </Grid>
    </SoftBox>
  );
}
SubjectInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
