import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import SoftBox from "components/SoftBox";
import PlanTable from "./components/PlanTable";
import PlanTitle from "./components/PlanTitle";
import { Box, Card, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";

const PlanCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (location.state && location.state.data) {
      setData(location.state.data);
    }
  }, [location]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox display="flex" p={2}></SoftBox>
      {loading ? (
        <SoftBox sx={{ mt: 13, textAlign: "center" }}>
          <CircularProgress />
        </SoftBox>
      ) : (
        <>
          <ArrowBackIcon
            onClick={() => {
              navigate("/plan/plan");
            }}
            sx={{ cursor: "pointer", lineHeight: 0, m: 1, ml: 2 }}
          />
          <Card>
            <Box sx={{ padding: 0.5, m: 1 }}>
              <PlanTitle data={data} />
              <PlanTable data={data} />
            </Box>
          </Card>
        </>
      )}
    </DashboardLayout>
  );
};

export default PlanCard;
