import { Card } from "@mui/material";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import React, { useEffect, useState } from "react";
import SoftInput from "components/SoftInput";

import api from "utils/axios";
import { useSoftUIController } from "context";
import { setChat } from "context";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


const ListChat = () => {
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [controller, dispatch] = useSoftUIController();
  const userId = controller.user.info._id;
  const [notification, setNotification] = useState([]);


  const getChatUser = async () => {
    try {
      setLoading(true);

      const response = await api.get("/get-chats");
      setChats(response.data);
    } catch (error) {
      setLoading(false);
      throw error
    }
  };

  useEffect(() => {
    getChatUser();
  }, []);
  useEffect(() => {
    if (chats.length > 0) {
      const firstUser = chats[0].users.find((user) => user._id !== userId);
      openedChat(firstUser._id, firstUser);
    }
  }, [chats, userId]);


  const openedChat = async (selectedUser, selectedUserId) => {
    try {
      const response = await api.post("/create-chat", { id: selectedUserId });
      setChat(dispatch, {
       
        receiver: {
          chat: response.data,
          chatId: response.data._id,
          fullName: selectedUserId.firstName + " " + selectedUserId.lastName,
          userId: selectedUserId._id,
          image: selectedUserId?.imageUrl?.startsWith("https")
          ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
          : `${process.env.REACT_APP_BASE_URL}/${selectedUserId.imageUrl}`,
        },
      });
      setSelectedUserId(selectedUser);
    } catch (error) {
      throw error
    }
  };

  useEffect(() => {
    api.get("/get-all-notif").then((res) => {
      setNotification(res.data);
    }).catch((err) => {
      throw err
    })
  }, [notification.length, controller.notifications]);

  const handleUpdateNotif = async (notifId) => {
    try {
      await api.patch(`/update-notif/${notifId}`, {
        status: "read"
      });
      notification.map((notif) => {
        if (notif._id === notifId) {
          notif.status = "read";
        }
      });
      setNotification(notification);
    } catch (error) {
     throw error
    }
  }

  const renderChats = chats.map(({ _id, users, latestMessage }) => {
    return (
      <React.Fragment key={_id}>
        {users.map((user) => (
          user._id !== userId && (
            <SoftBox
              key={user._id}
              component="li"
              display="flex"
              alignItems="center"
              p={0.75}
              my={1}
              sx={{
                background: user._id === selectedUserId
                  ? 'linear-gradient(90deg, #FF0080 0%, #7928CA 100%)'
                  : 'transparent',
                borderRadius: '8px',
                cursor: 'pointer',
                wordWrap: "break-word",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                
              }}
              onClick={() => {
                openedChat(user._id, user);
              }}
            >
             <SoftBox mr={2} position="relative" height="max-content">
                <SoftAvatar
                  src={ user?.imageUrl?.startsWith("https")
                    ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                    : `${process.env.REACT_APP_BASE_URL}/${user?.imageUrl}`}  
                  alt="something here"
                  variant="rounded"
                  shadow="md"
                />
                <SoftBox alt="spotify logo" position="absolute"
              right={0} top={-10} mr={-1} mb={-1}>
              <FiberManualRecordIcon sx={{
                  color: controller.connectedUsers.some(
                    (connectedUser) => connectedUser.userId === user._id && connectedUser.status === "online"
                  )
                    ? "#0CB40C"
                    : "#C20D0D",
                }} />
           
          </SoftBox>
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
              >
                <SoftTypography
                  variant="button"
                  color={user._id === selectedUserId ? "white" : "inherit"}
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {user.firstName} {user.lastName}
                </SoftTypography>
                <SoftTypography variant="caption" color={user._id === selectedUserId ? "white" : "text"} mb={1} >
                  {latestMessage.text}
                </SoftTypography>
                <SoftTypography variant="caption" color={user._id === selectedUserId ? "white" : "primary"}>
                  
                </SoftTypography>
              </SoftBox>
              <SoftBox ml="auto"></SoftBox>
            </SoftBox>
          )
        ))}
      </React.Fragment>
    );
  });
  return (
    <Card
      sx={{ height: "100%", minHeight: "500px", maxHeight: "500px", backgroundColor: "#E9ECEF" }}
    >
      <SoftBox p={1.5}>
        <SoftTypography variant="h6" fontWeight="bold">
          Amis
        </SoftTypography>
        <SoftBox pr={2} my={1.5}>
          <SoftInput
            placeholder="Type here..."
            icon={{ component: "search", direction: "left", color: "primary" }}
          />
        </SoftBox>
        <SoftBox>
          <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {renderChats}
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
};
ListChat.propTypes = {};
export default ListChat;
