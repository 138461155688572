import React, { useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import Header from './components/update-enterprise-components/Header';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'layouts/DashboardNavbar';
import { Card, Grid } from '@mui/material';
import EnterpriseInfo from './components/update-enterprise-components/EnterpriseInfo';
import SoftBox from 'components/SoftBox';


import SpaceShip from "examples/Icons/SpaceShip";
import Document from "examples/Icons/Document";
import Cube from "examples/Icons/Cube";
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import SoftTypography from 'components/SoftTypography';
import EnterpriseAdminInfo from './components/update-enterprise-components/EnterpriseAdminInfo';
import LicenceModifier from './components/update-enterprise-components/LicenceModifier';
import PasswordChange from './components/update-enterprise-components/PasswordChange';
import Parametre from './components/update-enterprise-components/Parametre';
import api from 'utils/axios';
import Etablisement from './components/update-enterprise-components/Etablisement';
import { useSoftUIController } from "context";

const UpdateEnterprise = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [enterprise, setEnterprise] = useState({});
  const [selectedItem, setSelectedItem] = useState(location.hash === '#licence' ? 3 : 0);
  const [{ user }, dispatch] = useSoftUIController();

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };
  
  const handleFetchEnterpriseById = async (idToFetch) => {
    try {
      if (idToFetch) {
        const response = await api.get(`/get-enterprise/${idToFetch}`);
        const fetchedEnterprise = response.data;
        setEnterprise(fetchedEnterprise);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const userRole = user?.info?.role;
    if (userRole !== 'super-admin' && userRole !== 'commercial') {
      console.warn('UNAUTHORIZED PAGE');
      navigate('/');
    }

    if (id !== undefined) {
      handleFetchEnterpriseById(id);
    }
  }, [id]);

  const sidenavItems = [
    { icon: <SpaceShip />, label: "enterprise", href: "/company-update" },
    { icon: <Document />, label: "Configuration", href: "/facility" },
    { icon: <Shop />, label: "Admin Enterprise", href: "/manager" },
    { icon: <Document />, label: "Licence", href: "licence" },
    { icon: <Cube />, label: " mot de passe", href: "/change-password" },
    { icon: <Office />, label: "Parametre de compte", href: "/account" },
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, index) => {
    const itemKey = `item-${index}`;
    const isSelected = index === selectedItem;
   
    return (
      <SoftBox key={itemKey} component="li" onClick={() => handleItemClick(index)} pt={index === 0 ? 0 : 1}>
         <SoftTypography
          component="a"
          href={`#${href}`}
          variant="button"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
            backgroundColor: index === selectedItem ? '#e0e0e0' : 'transparent',
          })}
        >
          <SoftBox mr={1.5} lineHeight={1}>
            {icon}
          </SoftBox>
          {label}
        </SoftTypography>
      </SoftBox>
    );
  });
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox mt={9}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              {/* START SIDE NAV*/ }
            <Card
              sx={{
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                position: "sticky",
                top: "1%",
              }}
            >
              <SoftBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={2}
                m={0}
                sx={{ listStyle: "none" }}
              >
                {renderSidenavItems}
              </SoftBox>
            </Card>
            {/* END SIDE NAV*/ }
            </Grid>
            
            <Grid item xs={12} lg={9}>
              <SoftBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Header enterprise={id && enterprise} />
                  </Grid>
                  <Grid item xs={12}>
                  {
                  selectedItem === 0 ? <EnterpriseInfo enterprise={enterprise}getFunction={handleFetchEnterpriseById} /> :
                  selectedItem === 1 ? <Etablisement enterprise={enterprise}getFunction={handleFetchEnterpriseById} /> :
                  selectedItem === 2 ? <EnterpriseAdminInfo enterprise={enterprise}getFunction={handleFetchEnterpriseById} /> : 
                  selectedItem === 3 ? <LicenceModifier id={enterprise.licenceId} /> : 
                  selectedItem === 4 ? <PasswordChange enterprise={enterprise}getFunction={handleFetchEnterpriseById} /> :
                  selectedItem === 5 ? <Parametre enterprise={enterprise} /> :""
                }
                  </Grid>
                </Grid>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </DashboardLayout>
    </>
  )
}

export default UpdateEnterprise;
