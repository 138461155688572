import { CircularProgress, Grid, Menu, MenuItem } from '@mui/material'
import SoftBox from 'components/SoftBox'
import PlaceholderCard from 'examples/Cards/PlaceholderCard'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'layouts/DashboardNavbar'
import Header from 'layouts/pages/profile/components/Header'
import React, { useEffect, useState } from 'react'
import breakpoints from "assets/theme/base/breakpoints";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// @mui material components
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate } from 'react-router-dom'
import api from 'utils/axios'
import Swal from 'sweetalert2'
import SoftPagination from 'components/SoftPagination'
import ComplexProjectCard from 'components/shared-users/Cards'
import SoftSnackbar from 'components/SoftSnackbar'
import { useSoftUIController } from 'context'

export default function ListCardsClient() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("B2B");
  const [data, setData] = useState([]);
  const [errorSB, setErrorSB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle
  const itemsPerPage = 8; // Nombre d'articles par page
  const navigate = useNavigate()
  const facility = JSON.parse(localStorage.getItem("facility"));
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  // Extraction des données de la page actuelle
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  const [slackBotMenu, setSlackBotMenu] = useState(null);

  // TeamProfileCard dropdown menu handlers
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const [controller] = useSoftUIController();
  const userLogin = controller?.user?.info;

  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler"
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(`/facilities/${facility._id}/users/${selectedClientId}`);
            if (response) {
              (await Swal.fire("Supprimé !", "Le responsable a été supprimé.", "success"))
                .isConfirmed && handleFetch();
              closeSlackBotMenu()
            }
          } catch (error) {
            Swal.fire("Une erreur s'est produite !", "Le responsable n'a pas été supprimé.", "error");
            closeSlackBotMenu()
          }
        }
      });
  };
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Fonction pour passer à la page précédente
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={() => navigate(`/administration/responsables/view/${selectedClientId}`)}> <RemoveRedEyeIcon />   &nbsp; Voir plus</MenuItem>
      <MenuItem onClick={() => navigate(`/administration/responsables/${selectedClientId}`)}> <EditIcon />  &nbsp; Modifier</MenuItem>
      <MenuItem
        onClick={showAlert}
      > <DeleteIcon />&nbsp;   Supprimer</MenuItem>
    </Menu>
  );

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const handleFetch = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/get-responsable-by-type/${facility._id}?responsableType=${tabValue}`);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };
  useEffect(() => {
    // change page title in the header
    document.title = "Responsable";
  }, []);
  useEffect(() => {
    handleFetch();
  }, [tabValue]);

  const renderErrorSB = (
    <SoftSnackbar
      color="error"
      icon="warning"
      title="Ajouter un responsable"
      content="Vous avez atteint le nombre maximum d'utilisateurs"
      dateTime="11 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );
  const licence = localStorage.getItem("licence");
  if (!licence) return;
  const parsedLicence = JSON.parse(licence);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title={"Liste des responsables"} searchVisible={true} ExportVisible={true} />
      <Grid>
        <SoftBox mt={2}>
          <Grid container>
            <Grid item xs={12} sm={8} lg={2}>
              <AppBar position="static">
                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                  <Tab id="tab-b2b-client" value="B2B" label="B2B" />
                  <Tab id="tab-b2c-client" value="B2C" label="B2C" />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
          <Grid marginLeft={172}>
          </Grid>
        </SoftBox>
      </Grid>
      {loading ? (
        <SoftBox
          sx={{
            mt: 13,
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </SoftBox>
      ) : (
        <>
          <SoftBox pb={2}>
            <SoftBox mt={{ xs: 1, lg: 2 }} mb={1}>
              <Grid container spacing={3}>
              {userLogin?.role !== "instructor"  && userLogin?.role !== "responsable"  && (
                <Grid
                  id="add-client"
                  onClick={() => parsedLicence.remainingUser === 0 ? openErrorSB() : navigate(`${tabValue === "B2B" ? "/administration/responsables/add-responsable-B2B" : "/administration/responsables/add-responsable-B2C"}`)}
                  item
                  xs={12}
                  md={20}
                  lg={4}
                  sx={{ cursor: "pointer" }}
                >
                  <PlaceholderCard
                    title={{ variant: "h5", text: `${tabValue === "B2B" ? "Ajouter responsable B2B" : "Ajouter  responsable B2C"}` }}
                  ></PlaceholderCard>
                </Grid>
                    )}

                {currentData &&
                  currentData?.map((client) => (
                    <Grid key={client._id} item xs={12} md={6} lg={4}>
                      <ComplexProjectCard
                        image={client?.imageUrl?.startsWith("https")
                          ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                          : `${process.env.REACT_APP_BASE_URL}/${client?.imageUrl}`}
                        title={`${client.firstName} ${client.lastName}`}
                        phone={client?.phoneNumber}
                        email={client?.email}
                        description=""
                        userId={client?._id}
                        selectedId={client?._id}
                        etat={client?.etat}
                        disabled ={(userLogin?.role === "responsable"|| userLogin?.role === "instructor") ? true : false}
                        dropdown={{
                          action: (e) => {
                            setSlackBotMenu(e.currentTarget);
                            setSelectedClientId(client._id);
                          },
                          menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </SoftBox>
          </SoftBox>
          {totalPages > 1 && (<SoftPagination>
            {currentPage > 1 && (
              <SoftPagination item onClick={prevPage}><ArrowBackIosIcon /></SoftPagination>
            )}
            {Array.from({ length: totalPages }, (_, index) => (
              <SoftPagination item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </SoftPagination >
            ))}
            {currentPage < totalPages && (
              <SoftPagination item onClick={nextPage}><ArrowForwardIosIcon /> </SoftPagination>
            )}
          </SoftPagination>)}
        </>
      )}
      {renderErrorSB}
    </DashboardLayout>
  )
}
