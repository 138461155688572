import { useState } from "react";

import { Box, TableHead, Typography } from "@mui/material";
import api from "utils/axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";

function PlanTable({  data }) {
  const facility = JSON.parse(localStorage.getItem("facility"));

  const getLast6Characters = (code) => code?.slice(-6);

  console.log(data, "data");

  return (
    <>
      <TableContainer component={Box} sx={{ padding: 1 }}>
        <Typography variant="h6" sx={{ textAlign: "center", margin: 1 }}>
          Unités obligatoires
        </Typography>
        {facility.type === "faculty" && (
          <Table
            aria-label="simple table"
            sx={{
              minWidth: 650,
              fontWeight: 700,
            }}
          >
            <TableHead sx={{ display: "contents" }}>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid black",
                    color: "#8392ab",
                    fontSize: "0.62rem",
                    padding: 0,
                    textAlign: "center",
                    fontWeight: 700,
                  }}
                >
                  Sous Période
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid black",
                    color: "#8392ab",
                    fontSize: "0.62rem",
                    padding: 0.5,
                    textAlign: "center",
                    fontWeight: 700,
                  }}
                >
                  Code&nbsp;UE
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid black",
                    color: "#8392ab",
                    fontSize: "0.62rem",
                    padding: 0.5,

                    textAlign: "center",
                    fontWeight: 700,
                  }}
                >
                  Libellé
                </TableCell>

                <TableCell
                  sx={{
                    border: "1px solid black",
                    color: "#8392ab",
                    fontSize: "0.62rem",
                    padding: 0.5,
                    textAlign: "center",
                    fontWeight: 700,
                  }}
                >
                  Cr
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid black",
                    color: "#8392ab",
                    fontSize: "0.62rem",
                    padding: 0.5,
                    textAlign: "center",
                    fontWeight: 700,
                  }}
                >
                  Coef
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid black",
                    color: "#8392ab",
                    fontSize: "0.62rem",
                    padding: 0,
                    textAlign: "center",
                    fontWeight: 700,
                  }}
                  align="center"
                >
                  Elements d&apos;enseignements
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {data?.parcours?.map((item) => (
              item?.modules?.map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    sx={{
                      border: "1px solid black",
                      textAlign: "center",
                      fontSize: "0.62rem",
                      color: "#c53528",
                      fontWeight: 700,
                
                    }}
                    component="th"
                    scope="row"
                  >
                    {row?.sousPeriode?.name || "_"}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid black",
                      textAlign: "center",
                      fontSize: "0.62rem",
                      padding: 0,
                      fontWeight: 700,
                    }}
                  >
                    {getLast6Characters(row?.code) || "_"}
                  </TableCell>

                  <TableCell
                    sx={{
                      border: "1px solid black",

                      fontSize: "0.62rem",
                      padding: 0.1,
                      textAlign: "center",
                      fontWeight: 700,
                    }}
                  >
                    {row?.name || "_"}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid black",

                      fontSize: "0.62rem",
                      padding: 0,
                      textAlign: "center",
                      fontWeight: 700,
                    }}
                  >
                    {row?.credit || "_"}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid black",
                      fontSize: "0.62rem",
                      padding: 0,
                      textAlign: "center",
                      fontWeight: 700,
                    }}
                  >
                    {row?.coefficient || "_"}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid black",
                      fontSize: "0.62rem",
                      padding: 0,
                      textAlign: "center",
                      fontWeight: 700,
                    }}
                  >
                    <>
                      <Table sx={{ minWidth: 650, borderBottom: "none" }} aria-label="simple table" >
                        <TableHead sx={{ display: "contents" }}>
                          <TableRow>
                            <TableCell
                              sx={{
                                border: "1px solid black",
                                color: "#8392ab",
                                fontSize: "0.62rem",
                                padding: 0,
                                textAlign: "center",
                                borderTop: "none",
                                borderLeft: "none",
                                fontWeight: 700,
                              }}
                            >
                              Code&nbsp;UE
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "1px solid black",
                                color: "#8392ab",
                                fontSize: "0.62rem",
                                padding: 0,
                                textAlign: "center",
                                borderTop: "none",

                                fontWeight: 700,
                              }}
                            >
                              Libellé
                            </TableCell>

                            <TableCell
                              sx={{
                                border: "1px solid black",
                                color: "#8392ab",
                                fontSize: "0.62rem",
                                padding: 0,
                                textAlign: "center",
                                borderTop: "none",
                                fontWeight: 700,
                              }}
                            >
                              Coef
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "1px solid black",
                                color: "#8392ab",
                                fontSize: "0.62rem",
                                padding: 0,
                                textAlign: "center",
                                borderTop: "none",
                                fontWeight: 700,
                              }}
                            >
                              Cr
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "1px solid black",
                                color: "#8392ab",
                                fontSize: "0.62rem",
                                padding: 0,
                                textAlign: "center",
                                borderTop: "none",
                                fontWeight: 700,
                              }}
                            >
                              TP
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "1px solid black",
                                color: "#8392ab",
                                fontSize: "0.62rem",
                                padding: 0,
                                textAlign: "center",
                                borderTop: "none",
                                fontWeight: 700,
                              }}
                            >
                              TD
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "1px solid black",
                                color: "#8392ab",
                                fontSize: "0.62rem",
                                padding: 0,
                                textAlign: "center",
                                borderTop: "none",
                                fontWeight: 700,
                              }}
                            >
                              C
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "1px solid black",
                                color: "#8392ab",
                                fontSize: "0.62rem",
                                padding: 0,
                                textAlign: "center",
                                borderTop: "none",
                                fontWeight: 700,
                              }}
                            >
                              CI
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "1px solid black",
                                color: "#8392ab",
                                fontSize: "0.62rem",
                                padding: 0,
                                textAlign: "center",
                                borderTop: "none",
                                borderRight: "none",
                                fontWeight: 700,
                              }}
                            >
                              Autre
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody >
                          {row?.subjects?.map((sub) => (
                            <TableRow
                              key={sub?.code}
                              sx={{
                                color: "#8392ab",
                                fontSize: "0.62rem",
                                padding: 0,
                                textAlign: "center",
                                fontWeight: 700,
                              }}
                            >
                              <TableCell
                                sx={{
                                  border: "1px solid black",
                                  borderLeft: "none",
                                  borderBottom: "none",
                                  fontSize: "0.62rem",
                                  padding: 0,
                                  textAlign: "center",
                                  fontWeight: 700,
                                  width: "10%",
                                }}
                              >
                                {getLast6Characters(sub?.code) || "_"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid black",
                                  borderBottom: "none",
                                  fontSize: "0.62rem",
                                  padding: 0,
                                  textAlign: "center",
                                  width: "30%",
                                  fontWeight: 700,
                                }}
                              >
                                {sub?.name || "_"}
                              </TableCell>

                              <TableCell
                                sx={{
                                  border: "1px solid black",
                                  borderBottom: "none",
                                  fontSize: "0.62rem",
                                  padding: 0,
                                  textAlign: "center",
                                  width: "10%",
                                  fontWeight: 700,
                                }}
                              >
                                {sub?.coefficient || "_"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid black",
                                  borderBottom: "none",
                                  fontSize: "0.62rem",
                                  padding: 0,
                                  textAlign: "center",
                                  width: "10%",
                                  fontWeight: 700,
                                }}
                              >
                                {sub?.credit || "_"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid black",
                                  borderBottom: "none",
                                  fontSize: "0.62rem",
                                  padding: 0,
                                  textAlign: "center",
                                  width: "8%",
                                  fontWeight: 700,
                                }}
                              >
                                {sub?.type?.[2]?.duration || "_"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid black",
                                  borderBottom: "none",
                                  fontSize: "0.62rem",
                                  padding: 0,
                                  textAlign: "center",
                                  width: "8%",
                                  fontWeight: 700,
                                }}
                              >
                                 {sub?.type?.[1]?.duration || "_"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid black",
                                  borderBottom: "none",
                                  fontSize: "0.62rem",
                                  padding: 0,
                                  textAlign: "center",
                                  width: "8%",
                                  fontWeight: 700,
                                }}
                              >
                                 {sub?.type?.[0]?.duration || "_"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid black",
                                  borderBottom: "none",
                                  fontSize: "0.62rem",
                                  padding: 0,
                                  textAlign: "center",
                                  width: "8%",
                                  fontWeight: 700,
                                }}
                              >
                                {"_"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid black",
                                  borderBottom: "none",
                                  fontSize: "0.62rem",
                                  padding: 0,
                                  textAlign: "center",
                                  borderRight: "none",
                                  fontWeight: 700,
                                  width: "10%",
                                }}
                              >
                                {"_"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </>
                  </TableCell>
                </TableRow>
               ))
               ))}
            </TableBody>
          </Table>
        ) }
      </TableContainer>
    </>
  );
}

export default PlanTable;

PlanTable.propTypes = {
  data: PropTypes.object.isRequired,
};
