import { Box, CircularProgress, IconButton, Modal, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import validationSchema from "./validerChamps/ValidationSchema";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import api from "utils/axios";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import {
  Card,
  Grid,
} from "@mui/material";

import SoftSelect from "components/SoftSelect";
import SoftBox from "components/SoftBox";
import { Close } from "@mui/icons-material";
import { grey } from "@mui/material/colors";


function UpdateProject({ open, handleCloseModal, projectID, handleprojects }) {
  const facility = JSON.parse(localStorage.getItem("facility"));
  const token = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(true); // New loading state

  const [emailcollaborateurs, setEmailObservateurs] = useState([]);
  const [emailResponsables, setEmailResponsables] = useState([]);
  const [projet, setProjet] = useState({});
  useEffect(() => {
    const getEmailObservateursEtResponsables = async () => {
      try {
        const response = await api.get(`faculty/get-emails-faculty/${facility._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const emails = response.data.emails;
        setEmailObservateurs(emails);
        setEmailResponsables(emails);
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    };

    getEmailObservateursEtResponsables();
  }, [facility._id, token]);

  useEffect(() => {
    const handleproject = async (projectID) => {
      setLoading(true); 
      try {
        let endpoint;
        // Determine the endpoint URL based on facility type
        if (facility?.type === "centreDeFormation") {
          endpoint = `training-company/get-project-centre-formation/${projectID}`;
        } else {
          endpoint = `faculty/get-project-faculty/${projectID}`;
        }
        const res = await api.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProjet(res.data);
      } catch (error) {
        console.error("Error fetching project data:", error);
      } finally {
        setLoading(false); 
      }
    };

    if (projectID) {
      handleproject(projectID);
    }
  }, [projectID, token]);

  const initialValues = {
    nom: projet.nom || '',
    description: projet.description || '',
    collaborateur: projet.collaborateursEmail || [],
    responsable: projet.responsable || '',
  };
  const UpdateProjet = async () => {
    try {
      let endpoint;
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/update-project-centre-formation/${projectID}`;
      } else {
        endpoint = `faculty/update-project-faculty/${projectID}`;
      }
  
      await api.put(endpoint, projet);
      Swal.fire({
        title: "Succès",
        text: "Édition du projet réussie",
        icon: "success",
        confirmButtonText: "OK",
      });
      handleprojects();
      handleCloseModal();
    } catch (error) {
      Swal.fire({
        title: "Avertissement",
        text: "Le nom est déja existant",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Modal 
      open={open}

      onClose={handleCloseModal}
      sx={{
        "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
          bgcolor: "rgba(0,0,0,0.03) !important",
        },
      }}
    >
    
    <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          height: "57%",
          overflowY: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: "1px 1px 1px 1px grey",
          p: "24px 32px",
        }}
      >
          {loading?(<SoftBox
              sx={{
                mt: 22,
                textAlign: "center",
              }}
            >
              <CircularProgress />
            </SoftBox>):(
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ errors, touched, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
            <Form onSubmit={handleSubmit} style={{ marginTop: "16px" }}>
              
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="h5" sx={{ flexGrow: "1" }}>
                  Update {projet.nom}
                </Typography>

                <IconButton onClick={handleCloseModal}>
                  <Close
                    fontSize="24px !important"
                    sx={{
                      "&:hover": {
                        bgcolor: grey[100],
                        borderRadius: "50%",
                      },
                    }}
                  />
                </IconButton>
              </Box>

              <Grid container>
                <Grid container xs={12} md={6}>
                  <Grid item xs={12}>
                    <Box mr={{ xs:"0px" , md :"8px"}}>
                      <Typography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                        Titre du Projet*
                      </Typography>
                      <Field name="titre">
                        {({ field }) => (
                          <SoftInput
                            {...field}
                            variant="outlined"
                            error={projet.nom?.length < 2}
                            success={projet.nom?.length >= 2}
                            value={projet.nom}
                            onBlur={handleBlur}
                            onChange={(event)=> {
                              handleChange(event);
                              setProjet({ ...projet, nom: event.target.value });
                              handleprojects("titre", event.target.value);
                            }}
                          />
                        )}
                      </Field>
                      {projet.nom?.length < 2 && (
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "400", fontSize: "12px" }}
                          color="error"
                        >
                          {errors.titre}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box mt="8px" mr={{ xs:"0px" , md :"8px"}}>
                      <Typography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                        Collaborateurs
                      </Typography>

                      <SoftSelect
                        options={emailcollaborateurs
                          .filter(
                            (emailcollaborateurs) =>
                              !projet.collaborateursEmail || !projet.collaborateursEmail.includes(emailcollaborateurs)
                          )
                          .map((emailcollaborateurs) => {
                            return{
                              value: emailcollaborateurs,
                              label: emailcollaborateurs,
                            }
                          })}
                        size="medium"
                        isMulti
                        getOptionLabel={(option) => option.label}
                        defaultValue={projet.collaborateursEmail?.map((collaborateur) => ({
                          value: collaborateur,
                          label: collaborateur,
                        })) || []}
                        onChange={(selectedOptions) => {
                          // Get the values of the selected options
                          const selectedValues = selectedOptions
                            .map((option) => option.value)
                            //.concat(values.collaborateur ? values.collaborateur[0] : projet.collaborateur[0]);
                          
                          // Update the collaborateur state in projet and values
                          setProjet({ ...projet, collaborateursEmail: selectedValues });
                          }}
                        isOptionEqualToValue={(option, value) => option.value === value}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box mt="8px" mr={{ xs:"0px" , md :"8px"}}>
                      <Typography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        sx={{ mb: 0.5 }}
                      >
                        Responsable*
                      </Typography>
                      <Field name="responsable">
                        {({ field }) => (
                          <SoftSelect
                            {...field}
                            options={emailResponsables.map((emailResponsable) => ({
                              value: emailResponsable,
                              label: emailResponsable,
                            }))}
                            value={{
                              value: projet.responsable,
                              label: projet.responsable,
                            }}
                            onBlur={field.onBlur}
                            onChange={(selectedOption) => {
                              const value = selectedOption ? selectedOption.value : '';
                              setProjet({ ...projet, responsable: value });
                              field.onChange({
                                target: {
                                  name: field.name,
                                  value,
                                },
                              });
                            }}
                          />
                        )}
                      </Field>
                      {errors.responsable && touched.responsable && (
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "400", fontSize: "12px" }}
                          color="error"
                        >
                          {errors.responsable}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box ml={{ xs: "0px", md: "8px" }}>
                    <Typography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                    >
                      Description
                    </Typography>
                    <SoftInput
                      multiline
                      rows={9}
                      variant="outlined"
                      value={projet.description}
                      onChange={(event) => {
                        setProjet({ ...projet, description: event.target.value });
                        }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box
                sx={{
                  mt: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SoftButton
                  type="reset"
                  sx={{ mt: 2 }}
                  variant="outlined"
                  color="dark"
                  size="small"
                  onClick={handleCloseModal}
                >
                  Annuler
                </SoftButton>
                <SoftButton
                  sx={{ mt: 2 }}
                  variant="gradient"
                  color="purple"
                  size="small"
                  onClick={() => {
                    UpdateProjet();
                  }}
                >
                  Modifier
                </SoftButton>
              </Box>
            </Form>
          )}
        </Formik>
      )}
      </Box>
     
    </Modal>

  );
} 

UpdateProject.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  projectID: PropTypes.string.isRequired,
  handleprojects: PropTypes.func.isRequired,
};
export default UpdateProject;