import { Grid, Menu, MenuItem } from '@mui/material'
import SoftBox from 'components/SoftBox'
import PlaceholderCard from 'examples/Cards/PlaceholderCard'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'layouts/DashboardNavbar'
import Header from 'layouts/pages/profile/components/Header'
import React, { useState } from 'react'
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom'
import CardPlanning from '../shared/CardPlanning'

export default function ListCardExamen() {
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  // TeamProfileCard dropdown menu handlers
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const navigate = useNavigate()
  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem > <RemoveRedEyeIcon />   &nbsp; Voir plus</MenuItem>
      <MenuItem > <EditIcon />  &nbsp; Modifier</MenuItem>
      <MenuItem > <DeleteIcon />&nbsp;   Supprimer</MenuItem>
    </Menu>
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title={"Liste des Examens"} searchVisible={true} ExportVisible={true} />
      <>
        <SoftBox pb={2}>
          <SoftBox mt={{ xs: 1, lg: 2 }} mb={1}>
            <Grid container spacing={3}>
              <Grid
                id="add-examen"
                onClick={() => navigate("/planning/examens/add-examen")}
                item
                xs={12}
                md={20}
                lg={4}
                sx={{ cursor: "pointer" }}
              >
                <PlaceholderCard
                  title={{ variant: "h5", text: "Ajouter Examen" }}
                ></PlaceholderCard>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <CardPlanning
                  image={team3}
                  name="Examen"
                  students={3}
                  level={"1"}
                  specialitie={"GL"}
                  description=""
                  occupation={"student.occupation"}
                  dateTime="16.01.22"
                  members={[team1, team2, team3, team4]}
                  dropdown={{
                    action: (e) => {
                      setSlackBotMenu(e.currentTarget);
                    },
                    menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CardPlanning
                  image={team3}
                  name="Examen"
                  students={3}
                  level={"1"}
                  specialitie={"GL"}
                  description=""
                  occupation={"student.occupation"}
                  dateTime="16.01.22"
                  members={[team1, team2, team3, team4]}
                  dropdown={{
                    action: (e) => {
                      setSlackBotMenu(e.currentTarget);
                    },
                    menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CardPlanning
                  image={team3}
                  name="Examen"
                  students={3}
                  level={"1"}
                  specialitie={"GL"}
                  description=""
                  occupation={"student.occupation"}
                  dateTime="16.01.22"
                  members={[team1, team2, team3, team4]}
                  dropdown={{
                    action: (e) => {
                      setSlackBotMenu(e.currentTarget);
                    },
                    menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CardPlanning
                  image={team3}
                  name="Examen"
                  students={3}
                  level={"1"}
                  specialitie={"GL"}
                  description=""
                  occupation={"student.occupation"}
                  dateTime="16.01.22"
                  members={[team1, team2, team3, team4]}
                  dropdown={{
                    action: (e) => {
                      setSlackBotMenu(e.currentTarget);
                    },
                    menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                  }}
                />
              </Grid>
            </Grid>

          </SoftBox>
        </SoftBox>
        {/*  {totalPages>1 && (<SoftPagination>
          {currentPage > 1 && (
            <SoftPagination item onClick={prevPage}><ArrowBackIosIcon /></SoftPagination>
          )}
          {Array.from({ length: totalPages }, (_, index) => (
            <SoftPagination item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </SoftPagination >
          ))}
          {currentPage < totalPages && (
            <SoftPagination item onClick={nextPage}><ArrowForwardIosIcon /> </SoftPagination>
          )}
    </SoftPagination>)} */}
      </>
    </DashboardLayout>
  )
}
