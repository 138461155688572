import { withStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";

const CustomSwitch = withStyles((theme) => ({
  root: {
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    "&$checked": {
      color: `${theme.palette.common.white} !important`,
      "& + $track": {
        backgroundColor: "green !important", 
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "green !important", 
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    borderRadius: 16 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "red !important", 
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
     disabled={props.disabled} 
    id="switch-btn"
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default CustomSwitch;
