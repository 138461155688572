import { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import AddSemester from "./AddSemester";
import SemesterActions from "./components/SemesterActions";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { Box, CircularProgress, Slide } from "@mui/material";
import api from "utils/axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stack from "@mui/material/Stack";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";
import SoftSelect from "components/SoftSelect";

function SemesterTable() {
  const [loading, setLoading] = useState(false);
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [data, setData] = useState([]);
  const [optionsDepartement, setOptionsDepartement] = useState([]);
  const [optionsFiliere, setOptionsFiliere] = useState([]);
  const [optionsParcours, setOptionsParcours] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const myRef = useRef(null);
  const facility = JSON.parse(localStorage.getItem("facility"));

  useEffect(() => {
    // change page title in the header
    document.title = "sous periode";
  }, []);
  const formatDate = (dateString) => {
    const options = {day: "numeric", month: "numeric"};
    return new Date(dateString).toLocaleDateString(undefined, options);
};

  const handleFetch = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/faculty/all-sp/${facility?.scholarityConfigId}`);
      setData(response.data);
    } catch (error) {
      throw error
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetch();
  }, []);
  const getLast6Characters = (code) => code?.slice(-6);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "flex-start", sm: "space-between" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Liste des sous périodes
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
              <SoftButton variant="outlined" color="purple" size="small" mr={2}>
                export
              </SoftButton>
              <AddSemester handleFetch={handleFetch} />
            </Stack>
          </SoftBox>
          {loading ? (
            <SoftBox sx={{ mt: 13, textAlign: "center" }}>
              <CircularProgress />
            </SoftBox>
          ) : (
            <div>
              <Slide direction="right" in={!showDetails} mountOnEnter unmountOnExit>
                <div ref={myRef}>
                  {!showDetails && (
                    <>
                     <SoftBox p={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={0} lg={4.5}></Grid>
                          <Grid item xs={12} lg={2.5}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography size="small" component="label" variant="caption" fontWeight="bold">
                                Departement
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect
                              //defaultValue={frameOptions[3]}
                              options={optionsDepartement}
                              size="small"  
                            />
                          </Grid>
                          <Grid item xs={12} lg={2.5}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography size="small" component="label" variant="caption" fontWeight="bold">
                                Filière
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect size="small" options={optionsFiliere} />
                          </Grid>
                          <Grid item xs={12} lg={2.5}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography size="small" component="label" variant="caption" fontWeight="bold">
                                Parcours
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect size="small" options={optionsParcours} />
                          </Grid>
                        </Grid>
                      </SoftBox>
                    <DataTable
                      table={{
                        columns: [
                          { Header: "nom sous période", accessor: "name" },
                          { Header: "Date Début", accessor: (row) => formatDate(row.startDate) },
                          { Header: "Date fin", accessor: (row) => formatDate(row.endDate) },
                          { Header: "Type", accessor: "type"},
                          { Header: "nombre des modules", accessor: "modules" },
                          { Header: "action", accessor: "action", width: "12%" },
                        ],
                        rows:
                          data &&
                          data?.map((semester) => ({
                            name: semester?.name,
                            startDate: semester?.start || "-",
                            endDate: semester?.end || "-",
                            type: semester?.type,
                            modules: semester?.modules?.length, 
                            action: (
                              <SemesterActions
                                key={semester.code}
                                semester={semester}
                                handleFetch={handleFetch}
                                setShowDetails={setShowDetails}
                                setDetailsData={setDetailsData}
                              />
                            ),
                          })),
                      }}
                      entriesPerPage={
                        afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false
                      }
                      canSearch={afficheFilter}
                    />
                    </>
                  )}
                </div>
              </Slide>
              {showDetails && (
                <>
                  <ArrowBackIcon
                    onClick={() => setShowDetails(false)}
                    sx={{ cursor: "pointer", lineHeight: 0, mt: 3, ml: 2 }}
                  />
                  <Slide direction="left" in={showDetails} mountOnEnter unmountOnExit>
                    <div>
                      <SoftBox lineHeight={1}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5, ml: 3 }}
                        >
                          Nom du sous période : {detailsData?.name}
                        </SoftTypography>
                      </SoftBox>
                      <DataTable
                        table={{
                          columns: [
                            {
                              Header: "code module",
                              accessor: (row) => getLast6Characters(row.code),
                              width: "30%",
                            },
                            { Header: "Nom du module", accessor: "moduleName" },
                            { Header: "nom de matière", accessor: "subjects" },
                          ],
                          rows:detailsData && detailsData.modules
                          ? detailsData.modules.map((module) => ({
                            code:module.code,
                            moduleName: module.name,
                            subjects: module.subjects.length,
                          })): [], 
                        }}
                        entriesPerPage={
                          afficheFilter
                            ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }
                            : false
                        }
                        canSearch={afficheFilter}
                      />
                    </div>
                  </Slide>
                </>
              )}
            </div>
          )}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default SemesterTable;
