const form = {
  formId: "subject",
  formField: {
    //----------------subject
    code: {
      name: "code",
      label: "Code*",
      type: "text",
      placeholder: "code du matière",
      errorMsg: "Code du matière est obligatoire .",
    },
    name: {
      name: "name",
      label: "Nom de la matière*",
      type: "text",
      placeholder: "Nom de la matière",
      errorMsg: "Nom de la matière est obligatoire.",
    },
    filiere: {
      name: "filiere",
      label: "Filiere*",
      type: "text",
      placeholder: "filiere",
      errorMsg: "filiere est obligatoire.",
    },
    niveau: {
      name: "niveau",
      label: "Niveau*",
      type: "text",
      placeholder: "niveau",
      errorMsg: "niveau est obligatoire.",
    },
    parcour: {
      name: "parcour",
      label: "Parcour*",
      type: "text",
      placeholder: "parcour",
      errorMsg: "parcour est obligatoire.",
    },
    module: {
      name: "module",
      label: "Module*",
      type: "text",
      placeholder: "module",
      errorMsg: "module est obligatoire.",
    },
    coefficient: {
      name: "coefficient",
      label: "Coefficient*",
      type: "number",
      placeholder: "coefficient",
      errorMsg: "coefficient est obligatoire.",
    },
    credit: {
      name: "credit",
      label: "Credit*",
      type: "number",
      placeholder: "credit",
      errorMsg: "credit est obligatoire.",
    },
    categorie: {
      name: "categorie",
      label: "Categorie*",
      type: "text",
      placeholder: "categorie",
      errorMsg: "categorie est obligatoire.",
    },
    //----------------types
    idCours: {
      name: "idCours",
      label: "checkedCours*",
      type: "number",
      placeholder: "checkedCours",
      errorMsg: "checkedCours est obligatoire.",
    },
    idTd: {
      name: "idTd",
      label: "checkedCours*",
      type: "number",
      placeholder: "checkedCours",
      errorMsg: "checkedCours est obligatoire.",
    },
    idTp: {
      name: "idTp",
      label: "checkedCours*",
      type: "number",
      placeholder: "checkedCours",
      errorMsg: "checkedCours est obligatoire.",
    },
    idMp: {
      name: "idMp",
      label: "checkedCours*",
      type: "number",
      placeholder: "checkedCours",
      errorMsg: "checkedCours est obligatoire.",
    },
    checkedCours: {
      name: "checkedCours",
      label: "checkedCours*",
      type: "number",
      placeholder: "checkedCours",
      errorMsg: "checkedCours est obligatoire.",
    },
    checkedTd: {
      name: "checkedTd",
      label: "checkedTd*",
      type: "number",
      placeholder: "checkedTd",
      errorMsg: "checkedTd est obligatoire.",
    },
    checkedTp: {
      name: "checkedTp",
      label: "checkedTp*",
      type: "number",
      placeholder: "checkedTp",
      errorMsg: "checkedTp est obligatoire.",
    },
    checkedMp: {
      name: "checkedMp",
      label: "checkedMp*",
      type: "number",
      placeholder: "checkedMp",
      errorMsg: "checkedMp est obligatoire.",
    },
    vhcours: {
      name: "vhcours",
      label: "Volume horaire cours*",
      type: "number",
      placeholder: "Volume horaire cours",
      errorMsg: "Volume horaire cours est obligatoire.",
    },
    durationcours: {
      name: "durationcours",
      label: "Durée du cours*",
      type: "number",
      placeholder: "Durée du cours",
      errorMsg: "Durée du cours est obligatoire.",
    },
    vhtd: {
      name: "vhtd",
      label: "Volume horaire td*",
      type: "number",
      placeholder: "Volume horaire td",
      errorMsg: "Volume horaire td est obligatoire.",
    },
    durationtd: {
      name: "durationtd",
      label: "Durée du td*",
      type: "number",
      placeholder: "Durée du td",
      errorMsg: "Durée du td est obligatoire.",
    },
    vhtp: {
      name: "vhtp",
      label: "Volume horaire tp*",
      type: "number",
      placeholder: "Volume horaire tp",
      errorMsg: "Volume horaire tp est obligatoire.",
    },
    durationtp: {
      name: "durationtp",
      label: "Durée du tp*",
      type: "number",
      placeholder: "Durée du tp",
      errorMsg: "Durée du tp est obligatoire.",
    },
    vhmp: {
      name: "vhmp",
      label: "Volume horaire mp*",
      type: "number",
      placeholder: "Volume horaire mp",
      errorMsg: "Volume horaire mp est obligatoire.",
    },
    durationmp: {
      name: "durationmp",
      label: "Durée du mp*",
      type: "number",
      placeholder: "Durée du mp",
      errorMsg: "Durée du mp est obligatoire.",
    },
    //---------------------salle
    bloccours: {
      name: "bloccours",
      label: "Bloc cours*",
      type: "text",
      placeholder: "bloc cours",
      errorMsg: "bloc cours est obligatoire.",
    },
    sallecours: {
      name: "sallecours",
      label: "salle du cours*",
      type: "text",
      placeholder: "salle du cours",
      errorMsg: "salle du cours est obligatoire.",
    },
    bloctd: {
      name: "bloctd",
      label: "Bloc td*",
      type: "text",
      placeholder: "bloc td",
      errorMsg: "bloc td est obligatoire.",
    },
    salletd: {
      name: "salletd",
      label: "salle du td*",
      type: "text",
      placeholder: "salle du td",
      errorMsg: "salle du td est obligatoire.",
    },
    bloctp: {
      name: "bloctp",
      label: "Bloc tp*",
      type: "text",
      placeholder: "bloc tp",
      errorMsg: "bloc tp est obligatoire.",
    },
    salletp: {
      name: "salletp",
      label: "salle du tp*",
      type: "text",
      placeholder: "salle du tp",
      errorMsg: "salle du tp est obligatoire.",
    },
    blocmp: {
      name: "blocmp",
      label: "Bloc mp*",
      type: "text",
      placeholder: "bloc mp",
      errorMsg: "bloc mp est obligatoire.",
    },
    sallemp: {
      name: "sallemp",
      label: "salle du mp*",
      type: "text",
      placeholder: "salle du mp",
      errorMsg: "salle du mp est obligatoire.",
    },
    //---------------------------------categories
    checkedPcExamen: {
      name: "checkedPcExamen",
      label: "checkedCours*",
      type: "number",
      placeholder: "checkedCours",
      errorMsg: "checkedCours est obligatoire.",
    },
    checkedPcDs1: {
      name: "checkedPcDs1",
      label: "checkedCours*",
      type: "number",
      placeholder: "checkedCours",
      errorMsg: "checkedCours est obligatoire.",
    },
    checkedPcDs1: {
      name: "checkedPcDs1",
      label: "checkedCours*",
      type: "number",
      placeholder: "checkedCours",
      errorMsg: "checkedCours est obligatoire.",
    },
    checkedPcDs2: {
      name: "checkedPcDs2",
      label: "pourcentage examen*",
      type: "number",
      placeholder: "pourcentage examen",
      errorMsg: "pourcentage examen est obligatoire.",
    },
    checkedPcCc: {
      name: "checkedPcCc",
      label: "pourcentage examen*",
      type: "number",
      placeholder: "pourcentage examen",
      errorMsg: "pourcentage examen est obligatoire.",
    },
    checkedPcTd: {
      name: "checkedPcTd",
      label: "pourcentage examen*",
      type: "number",
      placeholder: "pourcentage examen",
      errorMsg: "pourcentage examen est obligatoire.",
    },
    checkedPcTp: {
      name: "checkedPcTp",
      label: "pourcentage examen*",
      type: "number",
      placeholder: "pourcentage examen",
      errorMsg: "pourcentage examen est obligatoire.",
    },
    checkedPcMp: {
      name: "checkedPcMp",
      label: "pourcentage examen*",
      type: "number",
      placeholder: "pourcentage examen",
      errorMsg: "pourcentage examen est obligatoire.",
    },
    pcexamen: {
      name: "pcexamen",
      label: "pourcentage examen*",
      type: "number",
      placeholder: "pourcentage examen",
      errorMsg: "pourcentage examen est obligatoire.",
    },
    pctd: {
      name: "pctd",
      label: "pourcentage td*",
      type: "number",
      placeholder: "pourcentage td",
      errorMsg: "pourcentage td est obligatoire.",
    },
    pctp: {
      name: "pctp",
      label: "pourcentage tp*",
      type: "number",
      placeholder: "pourcentage tp",
      errorMsg: "pourcentage tp est obligatoire.",
    },
    pcmp: {
      name: "pcmp",
      label: "pourcentage mini projet*",
      type: "number",
      placeholder: "pourcentage mini projet",
      errorMsg: "pourcentage mp est obligatoire.",
    },
    pcds1: {
      name: "pcds1",
      label: "pourcentage ds1*",
      type: "number",
      placeholder: "pourcentage ds1",
      errorMsg: "pourcentage ds1 est obligatoire.",
    },
    pcds2: {
      name: "pcds2",
      label: "pourcentage ds2*",
      type: "number",
      placeholder: "pourcentage ds2",
      errorMsg: "pourcentage ds2 est obligatoire.",
    },
    pccc: {
      name: "pccc",
      label: "pourcentage cc*",
      type: "number",
      placeholder: "pourcentage cc",
      errorMsg: "pourcentage cc est obligatoire.",
    },
    pourcentage: {
      name: "pourcentage",
      label: "pourcentage cc*",
      type: "number",
      placeholder: "pourcentage cc",
      errorMsg: "pourcentage cc est obligatoire.",
    },
    //--------------------------instructor
    instructorCours: {
      name: "instructorCours",
      label: "instructorCours*",
      type: "number",
      placeholder: "instructorCours",
      errorMsg: "instructorCours est obligatoire.",
    },
    instructorTd: {
      name: "instructorTd",
      label: "instructorTd*",
      type: "number",
      placeholder: "instructorTd",
      errorMsg: "instructorTd est obligatoire.",
    },
    instructorTp: {
      name: "instructorTp",
      label: "instructorTp*",
      type: "number",
      placeholder: "instructorTp",
      errorMsg: "instructorTp est obligatoire.",
    },
    instructorMp: {
      name: "instructorMp",
      label: "instructorMp*",
      type: "number",
      placeholder: "instructorMp",
      errorMsg: "instructorMp est obligatoire.",
    },
  },
};

export default form;
