import { Grid, Menu, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import React, { useEffect, useState } from "react";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import coursImg from "assets/images/cours.png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate, useParams } from "react-router-dom";
import SoftPagination from "components/SoftPagination";
import api from "utils/axios";
import SoftTypography from "components/SoftTypography";
import ComplexProjectCard from "../matiere/components/Card";
import { useSoftUIController } from "context";
export default function CoursInfo({ classe }) {
  const facility = JSON.parse(localStorage.getItem("facility"));
 
  const [data, setData] = useState([]);
  const [selectedSubjectId, setSelectedSubjectId] = useState("");
  const [selectedSubjectName, setSelectedSubjectName] = useState("");
  const [selectedIns, setSelectedIns] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [controller] = useSoftUIController();
  const { id } = useParams()
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle
  const itemsPerPage = 9; // Nombre d'articles par page
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  // TeamProfileCard dropdown menu handlers
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const navigate = useNavigate();
  const user = controller?.user?.info;
  const isDesktop = () => {
    return typeof window !== 'undefined' && typeof window.__TAURI__ !== 'undefined';
  };
  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem id="btn-show" onClick={() => {
        if (user?.role === "student" && !isDesktop()) {
          window.location.href = `studdify://open?token=${user?.email}`;
        } else {
          navigate(`/classes/${id}/matieres/${selectedSubjectId}`, { state: { subjectName: selectedSubjectName, selectedIns: selectedIns, selectedType: selectedType } });
        }
      }}>
        <RemoveRedEyeIcon /> &nbsp; Voir plus
      </MenuItem>
      {/*   <MenuItem id="btn-edit">
        <EditIcon /> &nbsp; Modifier
      </MenuItem>
      <MenuItem id="btn-delete">
        <DeleteIcon />
        &nbsp; Supprimer
      </MenuItem> */}
    </Menu>
  );
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  // Fonction pour passer à la page précédente
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleFetch = async () => {
    setLoading(true);
    try {
      let endpoint;
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/subjects-by-class-and-role/${id}/${controller?.user?.info?._id}`;
      } else {
        endpoint = `/faculty/subjects-types-by-class-and-role/${id}/${controller?.user?.info?._id}`;
      }
      const response = await api.get(endpoint);
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetch();
  }, []);
  const totalPages = Math.ceil(data?.length / itemsPerPage);
  // Extraction des données de la page actuelle
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data?.slice(startIndex, endIndex);
  console.log(currentData)
  return (
    <>
      <SoftBox lineHeight={1} mb={2}>
        <SoftTypography variant="h5" fontWeight="medium">
          Liste des matiéres
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={2}>
        <SoftBox mb={1}>
          <Grid container spacing={3}>
            {currentData &&
              currentData?.map((subject) => (
                <Grid key={subject?._id} item xs={12} md={6} lg={6}>
                  <ComplexProjectCard
                    image={coursImg}
                    name={facility?.type === "centreDeFormation" ? subject?.subject?.name : `${subject?.type} ${subject?.subject?.name}`}
                    students={subject?.profs?.length}
                    level={"1"}
                    specialitie={"GL"}
                    instructorName={`${subject?.instructor?.firstName} ${subject?.instructor?.lastName}`}
                    //studentId={student?._id}
                    //departementId={student?.level?.speciality?.departement?.name}
                    //dateTime="16.01.22"
                    members={facility?.type === "centreDeFormation" ? [team1] : [team1, team2, team3, team4]}
                    dropdown={{
                      action: (e) => {
                        // openSlackBotMenu;
                        setSlackBotMenu(e.currentTarget);
                        setSelectedSubjectId(facility?.type === "centreDeFormation" ? subject?.subject?._id : subject?._id);
                        setSelectedSubjectName(subject?.subject?.name);
                        setSelectedIns(subject?.instructor?._id)
                        setSelectedType(subject?.type)
                      },
                      menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        </SoftBox>
      </SoftBox>
      {totalPages > 1 && (<SoftPagination>
        {currentPage > 1 && (
          <SoftPagination item onClick={prevPage}>
            <ArrowBackIosIcon />
          </SoftPagination>
        )}
        {Array.from({ length: totalPages }, (_, index) => (
          <SoftPagination
            item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </SoftPagination>
        ))}
        {currentPage < totalPages && (
          <SoftPagination item onClick={nextPage}>
            <ArrowForwardIosIcon />{" "}
          </SoftPagination>
        )}
      </SoftPagination>
      )}
    </>
  );
}
CoursInfo.propTypes = {
  classe: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
