import React, { useState } from 'react';
import { Card, Grid, TextField, Box, Typography, Autocomplete } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'layouts/DashboardNavbar';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import api from 'utils/axios';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Line } from 'rc-progress';
import styled from '@emotion/styled';
import FormField from 'layouts/pages/account/components/FormField';
import { useSoftUIController } from 'context';

export default function AddCours() {
  const [loading, setLoading] = useState(false);
  const [fileProgress, setFileProgress] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const { id, subjectId } = useParams();
  const paramName = location?.state?.paramName || '';
  const selectedIns = location?.state?.selectedIns || '';
  const facility = JSON.parse(localStorage.getItem('facility'));
  const [controller] = useSoftUIController();

  const formik = useFormik({
    initialValues: {
      titre: '',
      description: '',
      selectedOptions: []
    },
    validationSchema: Yup.object({
      titre: Yup.string().required('Titre obligatoire.'),
      description: Yup.string().required('Description obligatoire.')
    }),
    onSubmit: async (values) => {
      setLoading(true);
      let endpoint;
      if (facility?.type === 'centreDeFormation') {
        endpoint = `training-company/create-lesson?scholarityConfigId=${facility?.scholarityConfigId}`;
      } else {
        endpoint = `/faculty/lesson/create-lesson?scholarityConfigId=${facility?.scholarityConfigId}`;
      }

      const form = new FormData();
      form.append('title', values.titre);
      form.append('description', values.description);
      form.append('subjectId', subjectId);
      form.append('instructorId', selectedIns);
      form.append('classeId', id);
      values.selectedOptions.forEach((item) => {
        form.append('support', item.value);
      });

      try {
        const response = await api.post(endpoint, form);
        if (response.status === 200 || response.status === 201) {
          Swal.fire('Success!', 'Le cours a été ajouté avec succès', 'success').then(() => {
            setLoading(false);
            navigate(-1);
          });
        } else {
          throw new Error('Upload failed');
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.message) {
          Swal.fire('Error!', error.response.data.message, 'error');
        } else {
          Swal.fire("Error!", "Une erreur s'est produite. Veuillez réessayer ultérieurement.", "error");
        }
      }
    }
  });

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    const existingLabels = new Set(formik.values.selectedOptions.map(option => option.label));
    const newOptions = [];
  
    for (let file of files) {
      // Check if the file is a video and its size is greater than 150MB
      if (file.type.startsWith('video/') && file.size >= 150 * 1024 * 1024) {
        const compressedFile = {
          value: file,
          label: `${file.name} (compressed)`
        };
        newOptions.push(compressedFile);
      } else if (!existingLabels.has(file.name)) {
        const fileOption = { value: file, label: file.name };
        newOptions.push(fileOption);
      }
    }
  
    if (newOptions.length > 0) {
      formik.setFieldValue('selectedOptions', [...formik.values.selectedOptions, ...newOptions]);
  
      const uploadPromises = newOptions.map(option => uploadFileWithRetry(option));
      await Promise.all(uploadPromises);
    }
  };

  const uploadFileWithRetry = async (fileOption, retries = 3, delay = 1000) => {
    const formData = new FormData();
  
    // Check if the file is a video and compressed
    if (fileOption.label.endsWith('(compressed)')) {
      const compressedFile = await compressVideo(fileOption.value);
      formData.append('support', compressedFile);
    } else {
      formData.append('support', fileOption.value);
    }
  
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setFileProgress((prevProgress) => ({
          ...prevProgress,
          [fileOption.label]: { progress: percentCompleted, status: 'uploading' }
        }));
      }
    };
  
    const upload = () => api.post('training-company/upload-file', formData, config);
  
    for (let attempt = 0; attempt <= retries; attempt++) {
      try {
        const response = await upload();
        console.log(response);
        setFileProgress((prevProgress) => ({
          ...prevProgress,
          [fileOption.label]: { progress: 100, status: 'success' }
        }));
        return;
      } catch (error) {
        if (attempt < retries) {
          await new Promise(resolve => setTimeout(resolve, delay));
        } else {
          setFileProgress((prevProgress) => ({
            ...prevProgress,
            [fileOption.label]: { progress: 0, status: 'error' }
          }));
        }
      }
    }
  };
  const compressVideo = async (videoFile) => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(videoFile);
  
      videoElement.onloadedmetadata = () => {
        const canvas = document.createElement('canvas');
        canvas.width = videoElement.videoWidth * 0.85; // Reduce width by 15%
        canvas.height = videoElement.videoHeight * 0.85; // Reduce height by 15%
  
        const ctx = canvas.getContext('2d');
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  
        canvas.toBlob((blob) => {
          const compressedFile = new File([blob], `${videoFile.name}_compressed`, {
            type: 'video/mp4',
            lastModified: Date.now()
          });
          resolve(compressedFile);
        }, 'video/mp4', 0.8); // Adjust quality (0.8 is 80% quality)
      };
  
      videoElement.onerror = (error) => {
        reject(error);
      };
  
      videoElement.load();
    });
  };
    
  
  
  // const uploadFile = (fileOption) => {
  //   const formData = new FormData();
  //   formData.append('support', fileOption.value);
    
  //   const config = {
  //     onUploadProgress: (progressEvent) => {
  //       const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //       setFileProgress((prevProgress) => ({
  //         ...prevProgress,
  //         [fileOption.label]: { progress: percentCompleted, status: 'uploading' }
  //       }));
  //     }
  //   };
  
  //   return api.post('training-company/upload-file', formData, config)
  //     .then((response) => {
  //       console.log(response);
  //       setFileProgress((prevProgress) => ({
  //         ...prevProgress,
  //         [fileOption.label]: { progress: 100, status: 'success' }
  //       }));
  //     })
  //     .catch((error) => {
  //       setFileProgress((prevProgress) => ({
  //         ...prevProgress,
  //         [fileOption.label]: { progress: 0, status: 'error' }
  //       }));
  //     });
  // };
  
  const deleteFile = (fileLabel) => {
    formik.setFieldValue('selectedOptions', formik.values.selectedOptions.filter((option) => option.label !== fileLabel));
    setFileProgress((prevProgress) => {
      const newProgress = { ...prevProgress };
      delete newProgress[fileLabel];
      return newProgress;
    });
  };

  const VisuallyHiddenInput = styled('input')({ 
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
  });

  const isAllFilesUploaded = formik.values.selectedOptions.every(
    (fileOption) => fileProgress[fileOption.label]?.progress === 100
  );

  function LinearProgressWithLabel(props) {
    const { value, status, fileLabel, onDelete,disabled } = props;
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <Line percent={value} strokeWidth={1} strokeColor={status === 'success' ? 'green' : status === 'error' ? 'red' : 'blue'} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          {status === 'uploading' && (
            <Typography variant='body2' color='text.secondary'>
              {`${Math.round(value)}%`}
            </Typography>
          )}
          {status === 'success' && <CheckCircleIcon  style={{ color: 'green' }} />}
          {status === 'error' && <CancelIcon style={{ color: 'red' }} />}
        </Box>
        <Box sx={{ minWidth: 35 }}>
        <CancelIcon onClick={() => !disabled && onDelete(fileLabel)} style={{ cursor: disabled ? 'default' : 'pointer', color: disabled ? 'gray' : 'inherit' }} />
        </Box>
      </Box>
    );
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    fileLabel: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired

  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={2}>
        <Card          sx={{ height: '100%' }}>
          <SoftBox p={3}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container>
                <Grid item xs={4} sm={4} lg={4}>
                  <SoftBox
                    style={{ fontSize: '23px' }}
                    display='flex'
                    alignItems='center'
                    justifyContent={{ xs: 'center', md: 'center', lg: 'flex-start' }}>
                    <ArrowBackIcon 
                      style={{ marginRight: '12px', color: '#7F47E6', cursor: 'pointer' }} 
                      onClick={() => !loading && navigate(-1)} 
                    />
                    {paramName}
                  </SoftBox>
                </Grid>
                <Grid item xs={4} sm={4} lg={4} overflow={{ xs: 'hidden', md: 'hidden' }}></Grid>
                <Grid item xs={4} sm={4} lg={4}>
                  <SoftBox display='flex' justifyContent={{ xs: 'center', md: 'flex-end' }}>
                    <SoftButton
                      variant='gradient'
                      style={{ backgroundColor: '#7F47E6', color: '#FFFFFF', minWidth: '150px' }}
                      id='demo-customized-button'
                      aria-controls='demo-customized-menu'
                      aria-haspopup='true'
                      aria-expanded='true'
                      disableElevation
                      disabled={loading || !isAllFilesUploaded}
                      type='submit'>
                      <UpgradeIcon style={{ marginRight: '8px' }} />
                      Publier
                    </SoftButton>
                  </SoftBox>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} mt={2}>
                <FormField
                  id='titre'
                  label='Titre'
                  placeholder='Titre'
                  name='titre'
                  value={formik.values.titre}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.titre && Boolean(formik.errors.titre)}
                  helperText={formik.touched.titre && formik.errors.titre}
                  inputProps={{ maxLength: 50 }}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} md={12} mt={2}>
                <FormField
                  id='description'
                  multiline
                  rows={5}
                  disabled={loading}
                  label='Description'
                  placeholder='Description'
                  name='description'
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>
              <Grid sx={{ mt: 2, mr: 'auto', display: 'flow' }} item xs={12}>
                <SoftButton 
                  sx={{ mb: 1 }} 
                  component='label' 
                  onChange={handleFileUpload} 
                  variant='contained' 
                  startIcon={<CloudUploadIcon />} 
                  disabled={loading}
                >
                  Télécharger des fichiers
                  <VisuallyHiddenInput type='file' multiple />
                </SoftButton>
                <Autocomplete
                  multiple
                  id='tags-standard'
                  options={formik.values.selectedOptions}
                  value={formik.values.selectedOptions}
                  filterSelectedOptions
                  freeSolo
                  renderInput={(params) => <TextField {...params} variant='standard' placeholder='Télécharger des fichiers' />}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('selectedOptions', newValue);
                  }}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  disabled={loading}
                />
              </Grid>
            </form>
            {formik.values.selectedOptions.map((fileOption) => (
              <div key={fileOption.label}>
                <p>{fileOption.label}</p>
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel
                    value={fileProgress[fileOption.label]?.progress || 0}
                    status={fileProgress[fileOption.label]?.status || 'uploading'}
                    fileLabel={fileOption.label}
                    onDelete={deleteFile}
                    disabled={loading}
                  />
                </Box>
              </div>
            ))}
          </SoftBox>
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

