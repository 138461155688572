import { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from 'uuid';

import { setPurchase as setPurchasesGlobaly, useSoftUIController } from "context";

import Grid from "@mui/material/Grid";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import FormField from "pages/billing-settings/components/FormField";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import Purchase from "pages/billings-new/Purchase";
import SoftButton from 'components/SoftButton';
import Payee from './Payee';



const ArticleRow = ({ type, articlesType, quantity = 1, unitPriceHT, tva, discount, description, totalHT, totalTTC, index, applyTva, tvaName, duplicatePurchase, removePurchase, changePurchaseInput, tvaList, typeBill }) => {
  const [controller, dispatch] = useSoftUIController();
  const [purchase, setPurchase] = useState(new Purchase(type, 1, unitPriceHT, tva, discount, description, applyTva, tvaName));

  const [open, setOpen] = useState(false);
  const handleCloseModal =  () =>{
    setOpen(false);
  }
  const handleOpenModal =  () =>{
    setOpen(true);
  }
  // add the purchase to the list in the global context api...
  // useEffect(() => {
  //   setPurchasesGlobaly(dispatch, { actionType: 'modifiy', index, newValue: purchase });
  // }, []);

  // delete the purchase from the list in the NewQuotation Component & the global context api...
  const deletePurchaseHandler = () => {
    // setPurchasesGlobaly(dispatch, { actionType: 'delete', index });
    removePurchase(index);
  };

  const tvaNameValMap = {'Aucun TVA': 0};
  for (const { name, value } of tvaList) {
    tvaNameValMap[name] = value;
  }


  console.debug("article row - ", purchase);
  function getDefaultTvaLabel () {
    if (purchase.tva === 0) return 'Aucun TVA : (0%)';
    for(const { name, value } of tvaList) {
      if (name === purchase.tvaName) {
        return `${name} : (${value}%)`;
      }
    }
  }


  return (
    <>
      <Grid item xs={12} sm={1}>
      {/* <SoftBadge badgeContent={index+1} size="lg" container /> */}
      <SoftBadge badgeContent={index+1} size="lg" container color={"success"} />
      </Grid>
      <Grid item xs={12} sm={3} >
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {"Type d'article"}
            </SoftTypography>
          </SoftBox>
          <SoftSelect
            key={`articleType-${index}-${uuidv4()}`}
            // options={['Licence', 'Consulting', 'Inscription'].map(article => ({ value: article, label: article }))}
            options={articlesType.map(article => ({ value: article, label: article }))}

            defaultValue={{ label : purchase.type }}
            onChange={(e) => {
              const newArticleType = e.value;
              setPurchase((prevState) => {
                const newPurchase = prevState.deepCopy();
                newPurchase.type = newArticleType;
                setPurchasesGlobaly(dispatch, { actionType: 'modifiy', index, newValue: newPurchase });
                return newPurchase;
              });
              changePurchaseInput(index, "type", newArticleType);
            }}
          />
        </SoftBox>
      </Grid>
      <Grid item xs={0} sm={8}></Grid>
      <Grid item xs={0} sm={1}></Grid>
      { index === 0 &&
        <Grid item xs={0} sm={3}>
          {/* dateDebut input */}
          <FormField
            key={`date-debut-${index}-${uuidv4()}`}
            label="Date début"
            inputProps={{ type: "date" }}
            // defaultValue={purchase.unitPriceHT}
            // onBlur = { (e) => {
            //   const newDateDebut = +e.target.value;
            //   setPurchase(prevState => {
            //     const newPurchase = prevState.deepCopy();
            //     newPurchase.unitPriceHT = newUnitPrice;
            //     setPurchasesGlobaly(dispatch, { actionType: 'modifiy', index, newValue: newPurchase });
            //     return newPurchase;
            //   });
            //   changePurchaseInput(index, "unitPriceHT", newUnitPrice);
            // }}
          />
        </Grid>
      }
      <Grid item xs={0} sm={3}>
        <FormField
          key={`date-fin-${index}-${uuidv4()}`}
          label="Date Fin"
          inputProps={{ type: "date" }}
          // defaultValue={purchase.unitPriceHT}
          // onBlur = { (e) => {
          //   const newDateDebut = +e.target.value;
          //   setPurchase(prevState => {
          //     const newPurchase = prevState.deepCopy();
          //     newPurchase.unitPriceHT = newUnitPrice;
          //     setPurchasesGlobaly(dispatch, { actionType: 'modifiy', index, newValue: newPurchase });
          //     return newPurchase;
          //   });
          //   changePurchaseInput(index, "unitPriceHT", newUnitPrice);
          // }}
        />
      </Grid>
      {index !== 0 ? <Grid item xs={0} sm={8}></Grid> : <Grid item xs={0} sm={5}></Grid>}

      <Grid item xs={0} sm={1}></Grid>
      {/* <Grid item xs={12} sm={1} > */}
        {/* quantity input */}
        {/* <FormField
          key={`quantity-${index}-${uuidv4()}`}
          label="Quantité"
          inputProps={{ type: "number", min: 1 }}
          defaultValue={purchase.quantity}
          onBlur = {(e) => {
            const newQuantityVal = +e.target.value;
            setPurchase(prevState => {
              const newPurchase = prevState.deepCopy();
              newPurchase.quantity = newQuantityVal;
              setPurchasesGlobaly(dispatch, { actionType: 'modifiy', index, newValue: newPurchase });
              return newPurchase;
            });
            changePurchaseInput(index, "quantity", newQuantityVal); // global update (for quantity)
          }}
        />
      </Grid> */}
      <Grid item xs={12} sm={2} >
        {/* unitPriceHT input */}
        <FormField
          key={`unit-price-ht-${index}-${uuidv4()}`}
          label="Prix HT"
          inputProps={{ type: "number", min: 0 }}
          defaultValue={purchase.unitPriceHT}
          onBlur = { (e) => {
            const newUnitPrice = +e.target.value;
            setPurchase(prevState => {
              const newPurchase = prevState.deepCopy();
              newPurchase.unitPriceHT = newUnitPrice;
              setPurchasesGlobaly(dispatch, { actionType: 'modifiy', index, newValue: newPurchase });
              return newPurchase;
            });
            changePurchaseInput(index, "quantity", 1);
            changePurchaseInput(index, "unitPriceHT", newUnitPrice);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={2} >
        {/* tva selection instead of input---------------------------------------- */}
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {"TVA (%)"}
            </SoftTypography>
          </SoftBox>
          <SoftSelect
            key={`tva-${index}-${uuidv4()}`}
            disabled={!applyTva}
            options={[ { value: 'Aucun TVA', label: 'Aucun TVA : (0%)' }, ...tvaList.map(({name, value}) => ({ value: name, label: `${name} : (${value}%)`})) ]}
            defaultValue={{ label: getDefaultTvaLabel() }}
            onChange={(e) => {
              const newTvaName = e.value;
              console.debug(newTvaName, ': value : ', tvaNameValMap[newTvaName]);

              setPurchase(prevState => {
                const newPurchase = prevState.deepCopy();
                newPurchase.tva = tvaNameValMap[newTvaName];
                newPurchase.tvaName = newTvaName;
                setPurchasesGlobaly(dispatch, { actionType: 'modifiy', index, newValue: newPurchase });
                return newPurchase;
              });
              changePurchaseInput(index, "tva", tvaNameValMap[newTvaName]);
              changePurchaseInput(index, "tvaName", newTvaName);
            }}
          />

        </SoftBox>

      </Grid>
      <Grid item xs={12} sm={2} >
        {/* discount input */}
        <FormField
          key={`discount-${index}-${uuidv4()}`}
          label="Réduction (%)"
          inputProps={{ type: "number", min: 0 }}
          defaultValue={purchase.discount}
          onBlur = {(e) => {
            const newDiscountVal = +e.target.value;
            setPurchase((prevState) => {
              const newPurchase = prevState.deepCopy();
              newPurchase.discount = newDiscountVal;
              setPurchasesGlobaly(dispatch, { actionType: 'modifiy', index, newValue: newPurchase });
              return newPurchase;
            });
            changePurchaseInput(index, "discount", newDiscountVal);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        {/* totalHT input */}
        <FormField
          key={`total-ht-${index}-${uuidv4()}`}
          label="Total HT"
          inputProps={{ type: "number", min: 0 }}
          value={purchase.totalHT}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        {/* totalTTC input */}
        <FormField
          key={`total-ttc-${index}-${uuidv4()}`}
          label="Total TTC"
          inputProps={{ type: "number", min: 0 }}
          value={purchase.totalTTC}
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={1} mt={{xs :"0px" ,sm:"50px"}}>
        { (typeBill === "facture" || typeBill === "insc") &&
          <SoftButton variant="gradient" color="success" onClick={handleOpenModal}>Payée</SoftButton>
        }  
      </Grid>

      {open &&(
        <Payee open={open} handleCloseModal={handleCloseModal} />
      )}
      <Grid item xs={12} sm={1}></Grid>
      <Grid item xs={12} sm={8}>
        {/* description input */}
        <FormField
          key={`description-${index}-${uuidv4()}`}
          label="Description"
          defaultValue={purchase.description}
          onBlur = {(e) => {
            const newDesc = e.target.value;
            setPurchase((prevState) => {
              const newPurchase = prevState.deepCopy();
              newPurchase.description = newDesc;
              return newPurchase;
            });
            changePurchaseInput(index, "description", newDesc);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3} key={`buttons-${index}-${uuidv4()}`}>
        {/* copy-delete icons */}
        <SoftBox display="flex" flexDirection="row" justifyContent="end" alignItems="end" height="100%">
            <ContentCopyIcon sx={{cursor: "pointer"}} fontSize="large" onClick = {duplicatePurchase} />
            <HighlightOffIcon sx={{cursor: "pointer"}} fontSize="large" onClick = {deletePurchaseHandler} />
        </SoftBox>
      </Grid>
    </>

  )
};



ArticleRow.defaultProps = {
  type: "Licence",
  quantity: 1,
  unitPriceHT: 0,
  tva: 0,
  discount: 0,
  description: "",
  totalHT: 0,
  totalTTC: 0,
  index: 0,
  applyTva: false,
  tvaName: "Aucun TVA",
  duplicatePurchase: new Function(),
  removePurchase: new Function(),
  changePurchaseInput: new Function(),
  tvaList: [{name: '', value: 0}],
};

// Typechecking props for BaseLayout
ArticleRow.propTypes = {
  type: PropTypes.string,
  articlesType: PropTypes.array,
  quantity: PropTypes.number,
  unitPriceHT: PropTypes.number,
  tva: PropTypes.number,
  discount: PropTypes.number,
  description: PropTypes.string,
  totalHT: PropTypes.number,
  totalTTC: PropTypes.number,
  index: PropTypes.number,
  applyTva: PropTypes.bool,
  tvaName: PropTypes.string,
  duplicatePurchase: PropTypes.func,
  removePurchase: PropTypes.func,
  changePurchaseInput: PropTypes.func,
  tvaList: PropTypes.array,
  typeBill : PropTypes.string,
};



export default ArticleRow;





