import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSoftUIController } from "context";
import api from "utils/axios";
import SoftButton from "components/SoftButton";
import Slider from '@mui/material/Slider';
import axios from 'axios';
import { getOrientation } from 'get-orientation'
import { styles } from './styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from "@emotion/react";
import Swal from 'sweetalert2';

import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  Grid,
  Button,
  IconButton,
  TextField,
  InputLabel,
  Typography,
  Avatar,
  Icon,
  linearProgressClasses,

} from "@mui/material";
import { Calendar } from 'react-multi-date-picker';
import { MultiDatePicker } from 'react-multi-date-picker';
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { Box } from "material-ui-core";
import SoftEditor from "components/SoftEditor";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import CloseIcon from "@mui/icons-material/Close";
import SoftBox from "components/SoftBox";
import styled from "@emotion/styled";
import { getCroppedImg, getRotatedImage } from './components/canvasUtils'
import Cropper from "react-easy-crop";
import SoftSelect from "components/SoftSelect";
import link from "assets/theme/components/link";


const AddEvent = () => {



  const theme = useTheme();
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels)
  }
  const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
  }
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }
  // State pour suivre les dates sélectionnées
  const [selectedDates, setSelectedDates] = useState([]);
  // Fonction pour gérer la sélection de dates
  const handleDateSelection = (dates) => {
    // Mettre à jour l'état avec les nouvelles dates sélectionnées
    setSelectedDates(dates);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    // Réinitialisez les dates sélectionnées lorsqu'une nouvelle option est sélectionnée
    setSelectedDates([]);
  };
  const [logoToDelete, setlogoToDelete] = useState(null); // Initialiser logoToDelete avec une valeur null
  const [selectedlogo, setSelectedlogo] = useState(null);
  const [recurrencyFrequency, setrecurrencyFrequency] = useState([
    { value: 'jamais', label: 'jamais' },
    { value: 'Personnalisé', label: 'Personnalisé' },])
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inputs, setInputs] = useState([
    { imageSrc: "",
      image: "",
      name: "",
      category: null,
      startingDate: "",
      startingHour: "",
      endingDate: "",
      endindHour: "",
      isPaid: false,
      price: "",
      attendanceMode: null,
      venue: "",
      coOrganizers: "",
      recurrencyFrequency: "jamais",
      description: "",
      reuccurenceEndDates:""
    }
  ]);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handlelogoUpload = (event) => {
    const file = event.target.files[0];
    setSelectedlogo(file);
  };
  const fileInputRef = useRef(null);
  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handlePaidChange = (event) => {
    setIsPaid(event.target.checked);
    if (event.target.checked) {
      // Si l'utilisateur coche "payant", on décoche "gratuit"
      setIsFree(false);
    }
  };
  const [isFree, setIsFree] = useState(true);
  const handleFreeChange = (event) => {
    setIsFree(event.target.checked);
    if (event.target.checked) {
      // Si l'utilisateur coche "gratuit", on décoche "payant"
      setIsPaid(false);
    }
  };
  const handleToggleEndDateAndTime = () => {
    setShowEndDateAndTime(!showEndDateAndTime);
  }
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleDateAndTimeEnd = () => {
    setShowEndDate(true);
  };
  const handleDeletelogo = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    } setImageSrc(null)
    setlogoUpdate(null);
    setlogo(null);
    if (logoUpdate === null) {
    }
  };
  const [isPaid, setIsPaid] = useState(false);
  const [showEndDateAndTime, setShowEndDateAndTime] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([])
  const [optionscategory, setOptionscategory] = useState([
    { value: 'Arts et culture', label: 'Événement artistique ou culturel' },
    { value: 'En ligne', label: 'Événement en ligne' },
    { value: 'Sportifs', label: 'Événement sportif' },
    { value: 'Club', label: 'Club' },
    { value: 'Conférence', label: 'Conférence' },
    { value: 'sortie', label: 'Sortie scolaire/d\'étude' },
    { value: 'Réunion parental', label: 'Réunion parents' },
    { value: 'Autre', label: 'Autre' }])
  const [errorMessage, setErrorMessage] = useState("");
  const [description, setdescription] = useState("");
  const [dateDeDebut, setDateDeDebut] = useState(""); // Déclaration de la variable dateDeDebut
  const [dateDeFin, setDateDeFin] = useState(""); // Déclaration de la variable dateDeFin
  const [heureDeDebut, setHeureDeDebut] = useState(""); // Déclaration de la variable heureDeDebut
  const [heureDeFin, setHeureDeFin] = useState("");
  const [showEndDate, setShowEndDate] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [Errorname, setErrorname] = useState("");
  const [Errorcategory, setErrorcategory] = useState("");
  const [ErrorstartingDate, setErrorstartingDate] = useState("");
  const [ErrorstartingHour, setErrorstartingHour] = useState("");
  const [ErrorendingDate, setErrorendingDate] = useState("");
  const [ErrorendindHour, setErrorendindHour] = useState("");
  const [ErrorattendanceMode, setErrorattendanceMode] = useState("");
  const [Errorvenue, setErrorvenue] = useState("");
  const [ErrorPrice, setErrorPrice] = useState("");
  const [Errordescription, setErrordescription] = useState("");
  const [selectedrecurrencyFrequency, setselectedrecurrencyFrequency] = useState("")
  const facility = JSON.parse(localStorage.getItem("facility"));
 
  useEffect(() => {
    api.get(`/get-all-users-by-role/${facility._id}?role=responsable`).then((res) => {
      console.log(res, "tyhjukl")

      setOptions(
        res.data.map((item) => {
          return {
            value: item._id,
            label: `${item.firstName} ${item.lastName}`,
          };
        })
      );
    });
  }, []);
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    const newOption = { value: file, label: file.name };
    // Check if the file is already selected
    const isSelected = selectedOptions.some((option) => option.label === newOption.label);
    if (!isSelected) {
      // If it's not selected, add it to both options and selectedOptions
      setOptions([...options, newOption]);
      setSelectedOptions([...selectedOptions, newOption]);
    }
  };
  const [optionsattendanceMode, setoptionsattendanceMode] = useState([
    { value: "En présentiel", label: "En présentiel" },
    { value: "En ligne", label: "En ligne" },
  ])
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[0][name] = value;
    setInputs(newInputs);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setInputs([{
      imageSrc: "",
      image: "",
      name: "",
      category: null,
      startingDate: "",
      startingHour: "",
      endingDate: "",
      endindHour: "",
      attendanceMode: null,
      isPaid: false,
      price: "",
      venue: "",
      description: "",
      coOrganizers: "",
      recurrencyFrequency: "jamais",
      reuccurenceEndDate :""
    }]);
    //setImageSrc(null)

  };
  
  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }
  const onClose = () => {
    setCroppedImage(null)
  }
  const [imageSrc, setImageSrc] = React.useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [logoUpdate, setlogoUpdate] = useState(null); // Définissez logoUpdate comme une fonction et initialisez-la avec une valeur null
  const [logo, setlogo] = useState(null); // Initialisez logo avec une valeur null
  const [isHovered, setIsHovered] = useState(false);
  const [ff, setFF] = useState(false);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddEvent();
    }
  };
  var filename = '';
  const onFileChange = async (e) => {
    console.log(e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      console.log(imageDataUrl);
      try {
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
      } catch (e) {
        console.warn('failed to detect the orientation');
      }
      // Here you should set the filename correctly
      // Make sure the filename is set when a file is uploaded
      filename = file.name; // Set the filename here
      setImageSrc(imageDataUrl);
    }
  };
  
  
    const data = {
        "name":inputs[0].name,
        "image":filename,
        "description":description,
        "category":inputs[0].category,
        "startingDate":inputs[0].startingDate,
        "startingHour": inputs[0].startingHour,
        "endingDate": inputs[0].endingDate,
        "endindHour": inputs[0].endindHour,
        "status":true,
        "attendanceMode":inputs[0].attendanceMode, 
        "venue": inputs[0].venue,
        "price":inputs[0].price,
        "reuccurenceEndDate":selectedDates
    
    }




    
  const handleAddEvent = async () => {
   
   // try {
      // Valider les entrées avant de soumettre
      // const hasInvalidName = inputs.some(input => input.name.length < 2);
      // const hasInvalidStartingDate = inputs.some(input => !input.startingDate);
      // const hasInvalidCategory = inputs.some(input => !input.category);
      // const hasInvalidStartingHour = inputs.some(input => !input.startingHour);
      // const hasInvalidEndingDate = inputs.some(input => !input.endingDate);
      // const hasInvalidEndingHour = inputs.some(input => !input.endingHour);
      // const hasInvalidAttendanceMode = inputs.some(input => !input.attendanceMode);
      // const hasInvalidVenue = inputs.some(input => !input.venue);
      // const hasInvalidDescription = inputs.some(input => !input.description);
      // const hasInvalidPrice = inputs.some(input => input.isPaid && !input.price);
      // Ajoutez vos propres validations ici selon vos besoins...
  
     /*  if (hasInvalidName) {
        setErrorName("Le nom de l'événement doit avoir au moins 2 caractères.");
      } else if (hasInvalidCategory) {
        setErrorCategory("Le type doit être sélectionné.");
      } else if (hasInvalidStartingDate) {
        setErrorStartingDate("La date de début est requise.");
      } else if (hasInvalidStartingHour) {
        setErrorStartingHour("L'heure de début est requise.");
      } else if (hasInvalidAttendanceMode) {
        setErrorAttendanceMode(inputs[0].attendanceMode === "En présentiel" ? "Le lieu de l'évènement est requis." : "Le lieu de l'évènement doit être sélectionné");
      } else if (hasInvalidVenue) {
        setErrorVenue("Le lieu de l'évènement est requis.");
      } else if (description === "<p></p>") {
        setErrorDescription("La description est requise.");
      } else if (hasInvalidPrice) {
        setErrorPrice("Le prix est requis pour les événements payants.");
      } else { */
        // Construire les données à envoyer à l'API
        const requestData = inputs.map(input => ({
          image: input.image,
          /* imageSrc: input.imageSrc, */
          name: input.name,
          category: input.category,
          startingDate: input.startingDate,
          startingHour: input.startingHour,
          endingDate: input.endingDate,
          endingHour: input.endingHour,
          location: input.location,
          isPaid: input.isPaid,
          price: input.price,
          venue: input.venue,
          description: input.description,
          link: input.link,
          coOrganizers: input.coOrganizers,
          recurrencyFrequency: input.recurrencyFrequency,
          recurrenceEndDate: input.reuccurenceEndDates
        }));
        
        
        api.post('/events/create', data)
          .then(response => {
            // Handle the response here
            console.log(response.data);
          })
          .catch(error => {
            // Handle any errors that occurred during the request
            console.error(error);
          });
        

  
        // Afficher une boîte de dialogue de succès
       /*  Swal.fire("Succès", "L'événement a été ajouté avec succès", "success").then(() => {
          // Réinitialiser les états après le succès
          setInputs([{
            imageSrc: "",
            image: "",
            name: "",
            category: null,
            startingDate: "",
            startingHour: "",
            endingDate: "",
            endingHour: "",
            isPaid: false,
            price: "",
            attendanceMode: null,
            venue: "",
            coOrganizers: "",
            recurrencyFrequency: "jamais",
            description: "",
            recurrenceEndDates: ""
          }]);
          setErrorName("");
          setErrorCategory("");
          setErrorStartingDate("");
          setErrorStartingHour("");
          setErrorEndingDate("");
          setErrorEndingHour("");
          setErrorAttendanceMode("");
          setErrorVenue("");
          setErrorDescription("");
          setErrorPrice("");
          // Réinitialiser d'autres erreurs et états selon vos besoins...
        });
      } */
   /*  } catch (error) {
      console.log(error);
      // Gérer les erreurs de l'API
      Swal.fire("Erreur!", error.response?.data?.message || "Une erreur s'est produite lors de l'ajout de l'événement", "error");
    } finally {
      // Indiquer que le chargement est terminé
      setLoading(false);
    } */
  };
  
  console.log("inputs--------", inputs, imageSrc);

  return (
    <div>

      <SoftButton
        id="add"
        onClick={handleClickOpen}
        variant="gradient"
        color="purple"
        size="small"
        mb={{ xs: 2, sm: 0 }}
      >
        +  Créer Événement
      </SoftButton>
      <Dialog maxWidth="lg" fullWidth open={open}
        onClose={handleClose}
        onKeyDown={handleKeyPress}
      >
        {
          selectedrecurrencyFrequency === "Personnalisé" && (
            <>
              <ArrowBackIcon
                onClick={() => {
                  const newInputs = [...inputs];
                  newInputs[0].recurrencyFrequency = "";
                  setInputs(newInputs);
                }}
                sx={{ cursor: "pointer", lineHeight: 0, mt: 3, ml: 2 }}
              />
              <DialogTitle>Calendrier de l’évènement personnalisé</DialogTitle>

            </>

          )
        }
        {
          selectedrecurrencyFrequency !== "Personnalisé" && (
            <>
              <DialogTitle>Créer Événement</DialogTitle>
            </>
          )
        }

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent style={{ minHeight: "500px" }}>

          {selectedrecurrencyFrequency !== "Personnalisé" ? (<>
            {inputs?.map((input, index) => (
              <Box key={index}>
                {imageSrc ? (
                  <SoftBox p={3}>
                    <Grid item xs={12} sm={13} container justifyContent="center">
                      <SoftBox position="relative" height="max-content" mx="auto"
                        sx={{
                          // filter: isHovered ? "blur(2px)" : "none",
                          cursor: "pointer",
                          width: 750,
                          height: 200,
                          borderRadius: "16px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}>
                        <Cropper
                          image={imageSrc}
                          crop={crop}
                          rotation={rotation}
                          zoom={zoom}
                          aspect={4 / 3}
                          onCropChange={setCrop}
                          onRotationChange={setRotation}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}

                        />

                        {/* <ImgDialog img={croppedImage} onClose={onClose} /> */}
                        <SoftBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                          <SoftButton
                            id="btn-delete"
                            onClick={handleDeletelogo}
                            variant="gradient"
                            color="error"
                            size="small"
                            iconOnly
                          >
                            <Icon>delete</Icon>
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>

                    </Grid>
                    <div className={styles.controls}>
                      <div className={styles.sliderContainer}>
                        <Typography
                          variant="overline"
                          styles={{ root: styles.sliderLabel }}
                        >
                          Rotation
                        </Typography>
                        <Slider
                          value={rotation}
                          min={0}
                          max={360}
                          step={1}
                          aria-labelledby="Rotation"
                          styles={{ root: styles.slider }}
                          onChange={(e, rotation) => setRotation(rotation)}
                        />
                      </div>
                      <div className={styles.sliderContainer}>
                        <Typography
                          variant="overline"
                          classes={{ root: styles.sliderLabel }}
                        >
                          Zoom
                        </Typography>
                        <Slider
                          value={zoom}
                          min={1}
                          max={3}
                          step={0.1}
                          aria-labelledby="Zoom"
                          classes={{ root: styles.slider }}
                          onChange={(e, zoom) => setZoom(zoom)}
                        />
                      </div>
                    </div>
                  </SoftBox>
                ) : (
                  <SoftBox p={3}>
                    <Grid item xs={12} sm={13} container justifyContent="center">
                      <SoftBox position="relative" height="max-content" mx="auto">
                        <Avatar
                          name="image"
                          id="img-profile"
                          onClick={handleAvatarClick}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          sx={{
                            filter: isHovered ? "blur(2px)" : "none",
                            cursor: "pointer",
                            width: 750,
                            height: 200,
                            borderRadius: "16px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          src={
                            !logo && !logoUpdate && !logoToDelete
                              ? ""
                              : logoUpdate
                                ? URL.createObjectURL(logoUpdate)
                                : ""
                          }
                          alt={"title"}
                          size="xl"
                          variant="rounded"
                        >  Télécharger Image
                        </Avatar>

                        <SoftBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                          <SoftButton
                            id="btn-delete"
                            onClick={handleDeletelogo}
                            variant="gradient"
                            color="error"
                            size="small"
                            iconOnly
                          >
                            <Icon>delete</Icon>
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                    <input
                      name="image"
                      id="img"
                      type="file"
                      // Specify the accepted file types
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={onFileChange}
                      accept=".jpg, .jpeg, .png, .gif .jfif"
                    />
                    {errorMessage && (
                      <Typography align="center" sx={{ mt: 2 }} variant="body2" color="error">
                        {errorMessage}
                      </Typography>
                    )}
                  </SoftBox>)}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Nom de l&apos;événement*
                    </SoftTypography>
                    <SoftInput
                      name="name"
                      margin="normal"
                      id="code"
                      label="module"
                      type="text"
                      fullWidth
                      value={inputs[0].name}
                      placeholder="enter le nom de l'événement"
                      inputProps={{
                        minLength: 2,
                        maxLength: 50,
                      }}
                      autoFocus
                      variant="standard"
                      onChange={(event) => handleInputChange(index, event)}
                      error={input.error && inputs[0].name.length < 2}
                      success={inputs[0].name.length >= 2}

                    />
                    {inputs[0].name.length < 2 && (
                      <SoftTypography m={0.75} variant="caption" color="error">
                        {Errorname}
                      </SoftTypography>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography
                      value={inputs[0].category}
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}

                    >
                      Catégorie de l&apos;événement*
                    </Typography>
                    <Autocomplete
                      options={optionscategory}
                      defaultValue={optionscategory.find(
                        (option) => option.value === inputs[0].category
                      )}
                      clearIcon={null}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          const newInputs = [...inputs];
                          newInputs[0].category = newValue.value;
                          setInputs(newInputs);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Type d'événement"
                        />
                      )}
                    />
                    {inputs[0].category === null && (
                      <SoftTypography m={0.75} variant="caption" color="error">
                        {Errorcategory}
                      </SoftTypography>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Date de Début*
                    </SoftTypography>

                    <SoftInput
                      name="startingDate"
                      required={true}
                      id="birthday"
                      type="date"
                      value={inputs[0].startingDate}
                      onChange={(event) => handleInputChange(index, event)}

                    />
                    {inputs[0].startingDate === "" && (
                      <SoftTypography m={0.75} variant="caption" color="error">
                        {ErrorstartingDate}
                      </SoftTypography>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Heure de Début*
                    </SoftTypography>
                    <SoftInput
                      name="startingHour"
                      id="time-emploi"
                      type="time"
                      value={inputs[0].startingHour}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 900,
                      }}
                      onChange={(event) => handleInputChange(index, event)}

                    />
                    {inputs[0].startingHour === "" && (
                      <SoftTypography m={0.75} variant="caption" color="error">
                        {ErrorstartingHour}
                      </SoftTypography>
                    )}
                  </Grid>

                  {/* Bouton pour afficher les champs de date et heure de fin */}
                  <Grid item xs={12} sm={12}>
                    <Button onClick={handleToggleEndDateAndTime}>+ Date et heure de fin</Button>
                    {/* Affichage conditionnel des champs de date et heure de fin */}
                  </Grid>
                  {showEndDateAndTime && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Date de Fin*
                        </SoftTypography>
                        <SoftInput
                          name="endingDate"
                          required={true}
                          id="birthday-end"
                          type="date"
                          value={inputs[0].endingDate}
                          onChange={(event) => handleInputChange(index, event)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Heure de Fin*
                        </SoftTypography>
                        <SoftInput
                          name="endindHour"
                          id="time-end"
                          type="time"
                          value={inputs[0].endindHour}
                          defaultValue="17:00"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 900,
                          }}
                          onChange={(event) => handleInputChange(index, event)}
                        />
                      </Grid>
                    </>
                  )}
                  {!inputs[0].attendanceMode ? (
                    <>
                      <Grid item xs={12} sm={6} mt={2}>
                        <InputLabel htmlFor="event-location">
                          <Typography
                            component="label"
                            variant="caption"
                            fontWeight="medium"
                            color="text"
                            sx={{ mb: 0.5 }}
                          >
                            Lieu de l&apos;événement*
                          </Typography>
                        </InputLabel>
                        <Autocomplete
                          name="attendanceMode"
                          options={optionsattendanceMode}
                          title="Select type "
                          clearIcon={null}
                          getOptionLabel={(option) => option.label}
                          defaultValue={optionsattendanceMode.find(
                            (option) => option.value === inputs[0].attendanceMode
                          )}

                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              const newInputs = [...inputs];
                              newInputs[0].attendanceMode = newValue.value;
                              setInputs(newInputs);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              value={inputs[0].attendanceMode}
                              placeholder="Est-ce En présentiel ou en ligne ?"
                            />
                          )}
                        />
                        {inputs[0].attendanceMode === null && (
                          <SoftTypography m={0.75} variant="caption" color="error">
                            {ErrorattendanceMode}
                          </SoftTypography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}></Grid>
                    </>
                  ) : (
                    <Grid item xs={12} sm={6} mt={2}>
                      <InputLabel htmlFor="event-location">
                        <Typography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Lieu de l&apos;événement*
                        </Typography>
                      </InputLabel>
                      <Autocomplete
                        name="place"
                        options={optionsattendanceMode}
                        title="Select type "
                        clearIcon={null}
                        getOptionLabel={(option) => option.label}
                        value={optionsattendanceMode.find((option) => option.value === inputs[0].attendanceMode)}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            const newInputs = [...inputs];
                            newInputs[0].attendanceMode = newValue.value;
                            setInputs(newInputs);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            value={inputs[0].type}
                            placeholder="Type"
                          />
                        )}
                      // onChange={(event) => handleInputChange(index, event)}
                      />

                    </Grid>)}

                  {inputs[0].attendanceMode === 'En ligne' && (
                    <Grid item xs={12} sm={6} mt={3}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Lien de l&apos;événement*
                      </SoftTypography>
                      <SoftInput
                        name="venue"
                        margin="normal"
                        value={inputs[0].link}
                        id="code"
                        label="module"
                        type="text"
                        fullWidth
                        placeholder="lien"
                        inputProps={{
                          minLength: 2,
                          maxLength: 50,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Type"
                          />
                        )}
                        variant="standard"
                        onChange={(event) => handleInputChange(index, event)}
                        //error={inputs[0].venue.length < 2}
                        success={inputs[0].venue.length >= 2}

                      />
                      {inputs[0].venue === "" && (
                        <SoftTypography m={0.75} variant="caption" color="error">
                          {Errorvenue}
                        </SoftTypography>
                      )}
                    </Grid>
                  )}

                  {inputs[0].attendanceMode === 'En présentiel' && (
                    <Grid item xs={12} sm={6} mt={2} >
                      <InputLabel htmlFor="event-location-details">
                        <Typography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Lieu précis de l&apos;événement*
                        </Typography>
                      </InputLabel>
                      <TextField
                        name="place"
                        fullWidth
                        value={inputs[0].venue}
                        onChange={(event) => {
                          const newInputs = [...inputs];
                          newInputs[0].venue = event.target.value;
                          setInputs(newInputs);
                        }}
                        id="event-location-details"
                        placeholder="Lieu précis"
                        InputLabelProps={{
                          shrink: true,
                        }}

                        error={inputs[0].venue.length < 2}
                        success={inputs[0].venue.length >= 2}

                      />
                      {inputs[0].venue === "" && (
                        <SoftTypography m={0.75} variant="caption" color="error">
                          {Errorvenue}
                        </SoftTypography>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Ajouter des coorganisateurs
                    </SoftTypography>
                    <SoftSelect
                      name="coOrganizers"
                      options={options}
                      margin="normal"
                      value={options.find((option) => option.value === inputs[0].coOrganizers)}
                      id="code"
                      label="module"
                      type="text"
                      fullWidth
                      placeholder="écrire ici"
                      inputProps={{
                        minLength: 2,
                        maxLength: 50,
                      }}

                      variant="standard"
                      onChange={(event) => {
                        console.log(event);
                        const newInputs = [...inputs];
                        newInputs[0].coOrganizers = event.value;
                        setInputs(newInputs);
                      }}

                    />

                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      description de l&apos;événement*
                    </SoftTypography>
                    <SoftEditor

                      value={(event) => {
                        setdescription(event)
                      }}


                    />
                    {description === "<p></p>" && (
                      <SoftTypography m={0.75} variant="caption" color="error">
                        {Errordescription}
                      </SoftTypography>
                    )}



                  </Grid>

                  <Grid item xs={12} sm={6}></Grid>

                  <Grid container item xs={12} sm={12}>
                    <Grid item xs={3}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                        fullWidth
                      >
                        Événement :
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={<Checkbox checked={inputs[0].isPaid}
                          onChange={(e, newValue) => {
                            if (newValue !== null && newValue !== undefined) {
                              const newInputs = [...inputs];
                              newInputs[0].isPaid = !newInputs[0].isPaid

                              setInputs(newInputs);
                            }
                          }}
                        />}
                        label="Payant"
                        fontWeight="medium"
                        color="text"
                      />
                    </Grid>
                    {inputs[0].isPaid && (
                      <Grid item xs={4}>
                        <TextField
                          name="price"
                          component="label"
                          value={inputs[0].price}
                          fontWeight="medium"
                          color="text"
                          variant="standard"
                          placeholder="Prix de la participation"
                          fullWidth
                          type="number"
                          onChange={(event) => handleInputChange(index, event)}
                          error={inputs[0].price.trim() === ''}
                        />
                        {inputs[0].price.trim() === '' && (
                          <SoftTypography m={0.75} variant="caption" color="error">
                            Veuillez saisir le prix de la participation.
                          </SoftTypography>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="caption" color="text">
                      Récurrence de l&apos;événement
                    </Typography>
                    <Autocomplete
                      options={recurrencyFrequency}
                      value={recurrencyFrequency.find((option) => option.value === inputs[0].recurrencyFrequency)}

                      clearIcon={null}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          const newInputs = [...inputs];
                          newInputs[0].recurrencyFrequency = newValue.value
                          setselectedrecurrencyFrequency(newValue.value)
                          setInputs(newInputs);
                          setSelectedDates([])
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} >
                    <Grid item xs={3}>
                      <SoftTypography
                        name="reuccurenceEndDate"
                        component="label"
                        value={inputs[0].reuccurenceEndDates}
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                        fullWidth
                      >
                        Dates sélectionnées
                      </SoftTypography>

                    </Grid>
                    {error && (
                      <Grid item xs={12} sm={12} display="flex" justifyContent="center">
                        <div style={{ color: 'red' }}>{error}</div>
                      </Grid>
                    )}
                    <div>
                      {selectedDates.map(date => (
                        <div key={date.toString()}>
                          {new Date(date).toLocaleString('fr-FR')}
                        </div>
                      ))}
                    </div>

                  </Grid>

                  <DialogActions>

                  </DialogActions>
                </Grid>
                <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                  <SoftButton onClick={() => {
                    const newInputs = [...inputs];
                    newInputs[0].recurrencyFrequency = "";
                    setInputs(newInputs);
                  }} sx={{ mt: 2 }} variant="outlined" color="dark" size="small">
                    Annuler
                  </SoftButton>
                  {/* {loading ? (
                    <CircularProgress />
                  ) : ( */}
                  <SoftButton
                    onClick={handleAddEvent}
                    sx={{ mt: 2 }}
                    variant="gradient"
                    color="customized"
                    size="small"
                  >
                    Ajouter
                  </SoftButton>
                  {/* )} */}
                </SoftBox>

              </Box>
            ))}
          </>) : (
            <Box>

              {/* Calendrier annuel */}
              <Grid item xs={12} sm={12} display="flex" justifyContent={"center"} >
                <Calendar
                  multiple={true} // Activer le mode multiple
                  type="datetime-local"
                  format="DD/MM/YYYY HH:mm:ss"
                  fontWeight="medium"
                  color="text"
                  variant="standard"
                  plugins={[
                    <TimePicker key="timePicker" position="bottom" />,
                    <DatePanel key="datePannel" />,
                  ]}
                  onChange={handleDateSelection} // Gestionnaire de changement de sélection de dates
                />
             </Grid>

              <Grid item xs={12} sm={12} >

                <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                  <SoftButton
                    onClick={() => {
                      setselectedrecurrencyFrequency("")
                    }}
                    sx={{ mt: 2 }} variant="outlined" color="dark" size="small">
                    Annuler
                  </SoftButton>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <SoftButton
                      onClick={() => {
                        setselectedrecurrencyFrequency("")
                      }}
                      sx={{ mt: 2 }}
                      variant="gradient"
                      color="customized"
                      size="small"
                    >
                      Valider
                    </SoftButton>
                  )}
                </SoftBox>



              </Grid>
            </Box>)}
        </DialogContent>

      </Dialog>
    </div>
  );
};
AddEvent.propTypes = {
  token: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
export default AddEvent;
