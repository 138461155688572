import SoftBox from "components/SoftBox";
import React, { useEffect, useState } from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { ErrorMessage, useFormikContext } from "formik";
import api from "utils/axios";

export default function SalleInfoUpdate({ formData }) {
  const [optionsBlocs, setOptionsBlocs] = useState([]);
  const [optionsSalles, setOptionsSalles] = useState([]);
  const { formField, errors, touched, values } = formData;
  const [optionsSallesCours, setOptionsSallesCours] = useState(values.sallecours);
  const [optionsSallesTd, setOptionsSallesTd] = useState(values.salletd);
  const [optionsSallesTp, setOptionsSallesTp] = useState(values.salletp);
  const [optionsSallesMp, setOptionsSallesMp] = useState(values.sallemp);
  const [selectedBlocCours, setSelectedPBlocCours] = useState(values.bloccours);
  const [selectedBlocTd, setSelectedPBlocTd] = useState(values.bloctd);
  const [selectedBlocTp, setSelectedPBlocTp] = useState(values.bloctp);
  const [selectedBlocMp, setSelectedPBlocMp] = useState(values.blocmp);
  const { setFieldError, setFieldValue } = useFormikContext();
  const facility = JSON.parse(localStorage.getItem("facility"));
  const { bloccours, sallecours, bloctd, salletd, bloctp, salletp, blocmp, sallemp } = formField;
  const {
    checkedCours: checkedCoursV,
    checkedTd: checkedTdV,
    checkedTp: checkedTpV,
    checkedMp: checkedMpV,
    bloccours: bloccoursV,
    sallecours: sallecoursV,
    bloctd: bloctdV,
    salletd: salletdV,
    bloctp: bloctpV,
    salletp: salletpV,
    blocmp: blocmpV,
    sallemp: sallempV,
    vhtd: vhtdV,
    durationtd: durationtdV,
    vhtp: vhtpV,
    durationtp: durationtpV,
    vhmp: vhmpV,
    durationmp: durationmpV,
  } = values;
  const [inputs, setInputs] = useState([
    {
      bloccours: bloccoursV,
      sallecours: sallecoursV,
      bloctd: bloctdV,
      salletd: salletdV,
      bloctp: bloctpV,
      salletp: salletpV,
      blocmp: blocmpV,
      sallemp: sallempV,
    },
  ]);
  values.bloccours = inputs[0].bloccours;
  values.sallecours = inputs[0].sallecours;
  values.bloctd = inputs[0].bloctd;
  values.salletd = inputs[0].salletd;
  values.bloctp = inputs[0].bloctp;
  values.salletp = inputs[0].salletp;
  values.blocmp = inputs[0].blocmp;
  values.sallemp = inputs[0].sallemp;

  useEffect(() => {
    //setOptionsSallesCours(values.sallecours)
    setOptionsSallesTd(values.salletd);
    setOptionsSallesTp(values.salletp);
    setOptionsSallesMp(values.sallemp);
  }, []);

  useEffect(() => {
    // Fetch optionsBlocs
    const fetchOptionsBlocs = async () => {
      try {
        const res = await api.get(`/faculty/all-blocs/${facility?.scholarityConfigId}`);
        const options = res.data.map((item) => ({ value: item._id, label: item.name }));
        setOptionsBlocs(options);
      } catch (error) {
        handleError(error);
      }
    };
    // Fetch optionsSalles based on selectedBlocCours
    const fetchOptionsSalles = async (selectedBloc, setOptionsSalles) => {
      if (selectedBloc) {
        try {
          const res = await api.get(
            `/faculty/salles/${facility?.scholarityConfigId}/${selectedBloc}`
          );
          const options = res.data.map((item) => ({ value: item._id, label: item.name }));
          setOptionsSalles(options);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchOptionsBlocs();
    fetchOptionsSalles(selectedBlocCours, setOptionsSallesCours);
    fetchOptionsSalles(selectedBlocTd, setOptionsSallesTd);
    fetchOptionsSalles(selectedBlocTp, setOptionsSallesTp);
    fetchOptionsSalles(selectedBlocMp, setOptionsSallesMp);
  }, [selectedBlocCours, selectedBlocTd, selectedBlocTp, selectedBlocMp]);
  return (
    <SoftBox mt={1.625}>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        {values.categorie === "Matiere" && (
          <>
            {checkedCoursV && (
              <>
                {" "}
                <Grid item xs={12} sm={2}>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={6} sm={6}>
                      <SoftTypography
                        variant="caption"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        COURS
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        bloc*
                      </SoftTypography>
                      <Autocomplete
                        options={optionsBlocs}
                        title="Select bloc"
                        clearIcon={null}
                        getOptionLabel={(option) => option.label}
                        disabled={!checkedCoursV}
                        value={
                          (optionsBlocs.length > 0 &&
                            optionsBlocs.find((option) => option.value === values.bloccours)) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          if (newValue !== null && newValue !== undefined) {
                            const newInputs = [...inputs];
                            newInputs[0].bloccours = newValue.value;
                            newInputs[0].sallecours = 'h';
                            setSelectedPBlocCours(newValue.value);
                            setInputs(newInputs);
                            setFieldError("bloccours", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            value={bloccoursV}
                            placeholder="Tous les blocs"
                            error={errors.bloccours && touched.bloccours}
                            success={bloccoursV !== "" && !errors.bloccours}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={bloccours.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Salles*
                      </SoftTypography>
                      <Autocomplete
                        multiple
                        options={optionsSallesCours}
                        title="Select salle"
                        clearIcon={null}
                        getOptionLabel={(option) => option?.label || ""}
                        disabled={!checkedCoursV}
                        value={
                          optionsSallesCours.length > 0 &&
                          optionsSallesCours !== "h" &&
                          values?.sallecours !== "h" &&
                          values.sallecours.some(
                            (s) => !optionsSallesCours.some((option) => option.value === s?.value)
                          )
                            ? []
                            : (optionsSallesCours.length > 0 &&
                                optionsSallesCours !== "h" &&
                                values?.sallecours !== "h" &&
                                values.sallecours.map((s) =>
                                  optionsSallesCours.find((option) => option.value === s?.value)
                                )) ||
                              []
                        }
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            const newInputs = [...inputs];
                            newInputs[0].sallecours = newValue.map((value) => value) || null;
                            setInputs(newInputs);
                            setFieldError("sallecours", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            //value={sallecoursV}
                            placeholder="Tous les salles"
                            error={errors.sallecours && touched.sallecours}
                            success={sallecoursV !== "" && !errors.sallecours}
                          />
                        )}
                      />

                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={sallecours.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {checkedTdV && (
              <>
                <Grid item xs={12} sm={2}>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={6} sm={6}>
                      <SoftTypography
                        variant="caption"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        TD
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        bloc*
                      </SoftTypography>
                      <Autocomplete
                        options={optionsBlocs}
                        title="Select bloc"
                        clearIcon={null}
                        getOptionLabel={(option) => option.label}
                        disabled={!checkedTdV}
                        value={
                          (optionsBlocs.length > 0 &&
                            optionsBlocs.find((option) => option.value === values.bloctd)) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          if (newValue !== null && newValue !== undefined) {
                            const newInputs = [...inputs];
                            newInputs[0].bloctd = newValue.value;
                            newInputs[0].salletd = 'h';
                            setSelectedPBlocTd(newValue.value);
                            setInputs(newInputs);
                            setFieldError("bloctd", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            value={bloctdV}
                            placeholder="Tous les blocs"
                            error={errors.bloctd && touched.bloctd}
                            success={bloctdV !== "" && !errors.bloctd}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={bloctd.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Salles*
                      </SoftTypography>
                      <Autocomplete
                        multiple
                        options={optionsSallesTd}
                        title="Select salle"
                        clearIcon={null}
                        getOptionLabel={(option) => option?.label || ""}
                        disabled={!checkedTdV}
                        value={
                          optionsSallesTd.length > 0 &&
                          optionsSallesTd !== "h" &&
                          values?.salletd !== "h" &&
                          values.salletd.some(
                            (s) => !optionsSallesTd.some((option) => option.value === s?.value)
                          )
                            ? []
                            : (optionsSallesTd.length > 0 &&
                                optionsSallesTd !== "h" &&
                                values?.salletd !== "h" &&
                                values.salletd.map((s) =>
                                  optionsSallesTd.find((option) => option.value === s?.value)
                                )) ||
                              []
                        }
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            const newInputs = [...inputs];
                            newInputs[0].salletd = newValue.map((value) => value) || null;
                            setInputs(newInputs);
                            setFieldError("salletd", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Tous les salles"
                            error={errors.salletd && touched.salletd}
                            success={salletdV !== "" && !errors.salletd}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={salletd.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    {/*  )} */}
                  </Grid>
                </Grid>
              </>
            )}
            {checkedTpV && (
              <>
                <Grid item xs={12} sm={2}>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={6} sm={6}>
                      <SoftTypography
                        variant="caption"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        TP
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        bloc*
                      </SoftTypography>
                      <Autocomplete
                        options={optionsBlocs}
                        title="Select bloc"
                        clearIcon={null}
                        getOptionLabel={(option) => option.label}
                        disabled={!checkedTpV}
                        value={
                          (optionsBlocs.length > 0 &&
                            optionsBlocs.find((option) => option.value === values.bloctp)) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          if (newValue !== null && newValue !== undefined) {
                            const newInputs = [...inputs];
                            newInputs[0].bloctp = newValue.value;
                            newInputs[0].salletp = 'h';
                            setSelectedPBlocTp(newValue.value);
                            setInputs(newInputs);
                            setFieldError("bloctp", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            value={bloctpV}
                            placeholder="Tous les blocs"
                            error={errors.bloctp && touched.bloctp}
                            success={bloctpV !== "" && !errors.bloctp}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={bloctp.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Salles*
                      </SoftTypography>
                      <Autocomplete
                        multiple
                        options={optionsSallesTp}
                        title="Select salle"
                        clearIcon={null}
                        getOptionLabel={(option) => option?.label || ""}
                        disabled={!checkedTpV}
                        value={
                          optionsSallesTp.length > 0 &&
                          optionsSallesTp !== "h" &&
                          values?.salletp !== "h" &&
                          values.salletp.some(
                            (s) => !optionsSallesTp.some((option) => option.value === s?.value)
                          )
                            ? []
                            : (optionsSallesTp.length > 0 &&
                                optionsSallesTp !== "h" &&
                                values?.salletp !== "h" &&
                                values.salletp.map((s) =>
                                  optionsSallesTp.find((option) => option.value === s?.value)
                                )) ||
                              []
                        }
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            const newInputs = [...inputs];
                            newInputs[0].salletp = newValue?.map((value) => value) || null;
                            setInputs(newInputs);
                            setFieldError("salletp", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Tous les salles"
                            error={errors.salletp && touched.salletp}
                            success={salletpV !== "" && !errors.salletp}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={salletp.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {checkedMpV && (
              <>
                <Grid item xs={12} sm={2}>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={6} sm={6}>
                      <SoftTypography
                        variant="caption"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        Mini projet
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        bloc*
                      </SoftTypography>
                      <Autocomplete
                        options={optionsBlocs}
                        title="Select bloc"
                        clearIcon={null}
                        getOptionLabel={(option) => option.label}
                        disabled={!checkedMpV}
                        value={
                          (optionsBlocs.length > 0 &&
                            optionsBlocs.find((option) => option.value === values.blocmp)) ||
                          null
                        }
                        onChange={(event, newValue) => {
                          if (newValue !== null && newValue !== undefined) {
                            const newInputs = [...inputs];
                            newInputs[0].blocmp = newValue.value;
                            newInputs[0].sallemp = 'h';
                            setSelectedPBlocMp(newValue.value);
                            setInputs(newInputs);
                            setFieldError("blocmp", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            value={blocmpV}
                            placeholder="Tous les blocs"
                            error={errors.blocmp && touched.blocmp}
                            success={blocmpV !== "" && !errors.blocmp}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={blocmp.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Salles*
                      </SoftTypography>
                      <Autocomplete
                        multiple
                        options={optionsSallesMp}
                        title="Select salle"
                        clearIcon={null}
                        getOptionLabel={(option) => option?.label || ""}
                        disabled={!checkedMpV}
                        value={
                          optionsSallesMp.length > 0 &&
                          optionsSallesMp !== "h" &&
                          values?.sallemp !== "h" &&
                          values?.sallemp?.some(
                            (s) => !optionsSallesMp?.some((option) => option.value === s?.value)
                          )
                            ? []
                            : (optionsSallesMp.length > 0 &&
                                optionsSallesMp !== "h" &&
                                values?.sallemp !== "h" &&
                                values?.sallemp?.map((s) =>
                                  optionsSallesMp?.find((option) => option.value === s?.value)
                                )) ||
                              []
                        }
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            const newInputs = [...inputs];
                            newInputs[0].sallemp = newValue.map((value) => value) || null;
                            setInputs(newInputs);
                            setFieldError("sallemp", undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Tous les salles"
                            error={errors.sallemp && touched.sallemp}
                            success={sallempV !== "" && !errors.sallemp}
                          />
                        )}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={sallemp.name} />
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </SoftBox>
  );
}
SalleInfoUpdate.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
