import { useEffect, useRef, useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
// @mui core components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
// react-phone-input
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// DatahandleFileUpload
import {
  Autocomplete,
  Avatar,
  FormControl,
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { getCountryAdd } from "utils/citysTn";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import { fetchData } from "utils/citysTn";
import { ErrorMessage, Field} from "formik";
import styled from "@emotion/styled";
import EnterpriseFormField from "pages/enterprise/schema/enterprise-schemas/EnterpriseFormField";
import api from "utils/axios";

function StudentInfo({ formData }) {
  const [info, setInfo] = useState({});
  const [data, setData] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [image, setImage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedGender, setSelectedGender] = useState("");
  const [studentPhone, setStudentPhone] = useState("");
  const [studentSecondPhone, setStudentSecondPhone] = useState("");
  const [description, setDescription] = useState("");
  const [dateNaissance, setDateNaissance] = useState("");
  const [choixValue, setChoixValue] = useState("choix1");
  const [selectedResp, setSelectedResp] = useState("");
  const [optionsFilieres, setOptionsFilieres] = useState([]);
  const [optionsLevel, setOptionsLevel] = useState([]);
  const [optionsParcours, setOptionsParcours] = useState([]);
  const [selectedParcour, setSelectedParcour] = useState();
  const [selectedLevel, setSelectedLevel] = useState();
  const [selectedFiliere, setSelectedFiliere] = useState();
  const [error, setError] = useState("");

  useEffect(() => {
    api.get(`/faculty/all-filiere/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsFilieres(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
          niveaux: item.niveaux,
        }))
      );
    });  
  }, []);

  useEffect(() => {
    setSelectedResp("");
  }, [choixValue, setSelectedResp]);

  const facility = JSON.parse(localStorage.getItem("facility"));
  const [optionsResp, setOptionsResp] = useState([]);
  const { formField, values, errors, touched } = formData;
  const {
    firstName,
    lastName,
    email,
    address,
    zipCode,
    siteWeb,
    passeport,
    cin,
    rib,
    bank,
    phone,
    gender,
    cnssNumber,
    occupation,
  } = formField;
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    address: addressV,
    zipCode: zipCodeV,
    siteWeb: siteWebV,
    passeport: passeportV,
    cin: cinV,
    rib: ribV,
    bank: bankV,
    description: descriptionV,
    phone: phoneV,
    gender: genderV,
    cnssNumber: cnssNumberV,
    occupation: occupationV,
  } = values;
  const fileInputRef = useRef(null);
  // Function to handle file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    const newOption = { value: file, label: file.name };
    // Check if the file is already selected
    const isSelected = selectedOptions.some((option) => option.label === newOption.label);
    if (!isSelected) {
      // If it's not selected, add it to both options and selectedOptions
      setOptions([...options, newOption]);
      setSelectedOptions([...selectedOptions, newOption]);
    }
  };
  const countries = getCountryAdd(info, data);
  const [dataToSend, setDataToSend] = useState({
    country: "",
    city: "",
  });
  values.phone = studentPhone;
  values.secondaryPhoneNumber = studentSecondPhone;
  values.description = description;
  values.gender = selectedGender;
  values.city = dataToSend.city;
  values.country = dataToSend.country;
  values.birthday = dateNaissance;
  values.imageUrl = image;
  values.files = selectedOptions;
  values.options = options;
  values.choix = choixValue;
  values.responsable = selectedResp;
  values.parcour=selectedParcour

  const handleCountryChange = (selectedOption) => {
    const selectedCountry = selectedOption.value;
    const cities = data.find((country) => country.country === selectedCountry).cities;
    setDataToSend({ ...dataToSend, country: selectedCountry });
    setCityOptions(cities.map((city) => ({ value: city, label: city })));
  };
  const handleCityChange = (selectedOption) => {
    setDataToSend({ ...dataToSend, city: selectedOption.value });
  };
  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };
  const handleDeleteImage = () => {
    // Handle deleting the image here
    // You can implement this function as needed
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setImage(null);
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Specify the allowed file extensions
      const allowedExtensions = ["jpg", "jpeg", "png", "gif", "jfif"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        // Display an error message if the file extension is not allowed
        setErrorMessage(
          "Format de fichier non pris en charge. Veuillez sélectionner un fichier JPG, JPEG, PNG ou GIF."
        );
        setImage(null); // Clear the logo
        return;
      }
      // Reset the error message if the file extension is allowed
      setErrorMessage("");
      setImage(selectedFile);
      // Specify the maximum allowed file size in bytes (e.g., 2MB)
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      const maxWidth = 650; // Maximum width in pixels
      const maxHeight = 650; // Maximum height in pixels

      if (selectedFile.size > maxSize) {
        // Display an error message if the file size exceeds the limit
        setErrorMessage(
          "La taille du fichier est trop grande. La taille maximale autorisée est de 2 Mo."
        );
        setImage(null); // Clear the logo
      } else {
        // Check image dimensions
        const img = new Image();
        img.src = URL.createObjectURL(selectedFile);

        img.onload = () => {
          if (img.width > maxWidth || img.height > maxHeight) {
            // Display an error message if the image dimensions exceed the limit
            setErrorMessage(
              `Les dimensions de l’image sont trop grandes. Les dimensions maximales autorisées sont ${maxWidth}x${maxHeight} pixels.`
            );
            setImage(null); // Clear the logo
          }
        };
      }
    }
  };
  useEffect(() => {
    const fetchDataAsync = async () => {
      const data = await fetchData();
      setData(data);
    };
    fetchDataAsync();
  }, []);
  const currentDate = new Date();
  const monthsToSubtract = 1; // Set the number of months to subtract
  const minDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - monthsToSubtract,
    currentDate.getDate()
  );
  const minDateString = minDate.toISOString().split("T")[0];
  const handleChoixChange = (event) => {
    setChoixValue(event.target.value);
  };
  const handleFetch = async () => {
    try {
      const response = await api.get(`/get-all-users-by-role/${facility._id}?role=responsable`);
      setOptionsResp(
        response.data.map((item) => ({
          value: item._id,
          label: item.firstName,
        }))
      );
    } catch (error) {
     throw error;
    }
  };
  useEffect(() => {
    handleFetch();
  }, []);
  return (
    <>
      <SoftBox>
        <SoftBox>
          <SoftBox lineHeight={0}>
            <SoftTypography id="add-company-admin" variant="h5" fontWeight="bold">
              Les informations
            </SoftTypography>
          </SoftBox>
          <Grid item xs={12} sm={13} container justifyContent="center">
            <SoftBox position="relative" height="max-content" mx="auto">
              <Avatar
                id="img-profile"
                onClick={handleAvatarClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{
                  filter: isHovered ? "blur(2px)" : "none",
                  cursor: "pointer",
                  width: 100,
                  height: 100,
                  borderRadius: "16px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={image ? URL.createObjectURL(image) : ""}
                alt="profile picture"
                size="xl"
                variant="rounded"
              />
              <SoftBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                <SoftButton
                  id="delete-img"
                  onClick={handleDeleteImage}
                  variant="gradient"
                  color="error"
                  size="small"
                  iconOnly
                >
                  <Icon>delete</Icon>
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </Grid>
          <input
            type="file"
            // Specify the accepted file types
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={onFileChange}
            accept=".jpg, .jpeg, .png, .gif .jfif"
          />
          {errorMessage && (
            <Typography align="center" sx={{ mt: 2 }} variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
          <Grid container ml={1} mt={2}>
            <Grid item xs={12} sm={12}>
              <SoftTypography variant="h5" fontWeight="large" textTransform="capitalize" mb={1.5}>
                choisir votre responsable :
              </SoftTypography>
              <Grid ml={1} mb={1}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-label="choix"
                    id="choix"
                    name="choix"
                    value={choixValue}
                    onChange={handleChoixChange}
                  >
                    <FormControlLabel value="choix1" control={<Radio />} label="Avec responsable" />
                    <FormControlLabel
                      value="choix2"
                      control={<Radio />}
                      label="Sans responsable "
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {choixValue === "choix1" && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Nom du responsable*
                </SoftTypography>
                <Field
                  name="responsable"
                  validate={(value) => (value ? undefined : "Responsable est obligatoire")}
                >
                  {({ field, form }) => (
                    <Autocomplete
                      options={optionsResp}
                      id="responsable"
                      title="responsable"
                      clearIcon={null}
                      aria-required={true}
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(option, value) => option.value === value.value}
                      value={optionsResp.find((option) => option.value === selectedResp)}
                      onChange={(event, newValue) => {
                        if (newValue !== null && newValue !== undefined) {
                          form.setFieldValue("responsable", newValue.value);
                          setSelectedResp(newValue.value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Tous les responsables"
                        />
                      )}
                    />
                  )}
                </Field>
                <SoftTypography component="div" variant="caption" color="error" mt={0.75}>
                  <ErrorMessage name="responsable" />
                </SoftTypography>
              </Grid>
            </Grid>
          )}

          <SoftBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="firstname"
                  type={firstName.type}
                  label={firstName.label}
                  name={firstName.name}
                  value={firstNameV}
                  inputProps={{
                    minLength: 2,
                    maxLength: 30,
                  }}
                  placeholder={firstName.placeholder}
                  error={errors.firstName && touched.firstName}
                  success={firstNameV.length > 0 && !errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="lastname"
                  type={lastName.type}
                  label={lastName.label}
                  name={lastName.name}
                  value={lastNameV}
                  inputProps={{
                    minLength: 2,
                    maxLength: 30,
                  }}
                  placeholder={lastName.placeholder}
                  error={errors.lastName && touched.lastName}
                  success={lastNameV.length > 0 && !errors.lastName}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftTypography
                  sx={{ mb: 1 }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Date de Naissance
                </SoftTypography>
                <SoftInput
                  id="birthday"
                  type="date"
                  inputProps={{
                    max: minDateString,
                  }}
                  onChange={(e) => setDateNaissance(e.target.value)}
                />
              </Grid>{" "}
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ m: 2, minWidth: 120 }}>
                  <SoftTypography
                    sx={{ mb: 1 }}
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Genre*
                  </SoftTypography>
                  <RadioGroup
                    id="gender"
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name={gender.name}
                    onChange={(e) => setSelectedGender(e.target.value)}
                    success={genderV.length > 0 && !errors.gender}
                  >
                    <FormControlLabel value="femme" control={<Radio />} label="Femme" />
                    <FormControlLabel value="homme" control={<Radio />} label="Homme" />
                  </RadioGroup>{" "}
                  <SoftBox mt={0.75}>
                    <SoftTypography component="div" variant="caption" color="error">
                      <ErrorMessage name={gender.name} />
                    </SoftTypography>
                  </SoftBox>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="email"
                  type={email.type}
                  label={email.label}
                  name={email.name}
                  value={emailV}
                  inputProps={{
                    maxLength: 50,
                  }}
                  placeholder={email.placeholder}
                  error={errors.email && touched.email}
                  success={emailV.length > 0 && !errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="cin/passeport"
                  type={cin.type}
                  label={cin.label}
                  name={cin.name}
                  value={cinV}
                  inputProps={{
                    maxLength: 20,
                  }}
                  placeholder={cin.placeholder}
                  error={errors.cin && touched.cin}
                  success={cinV.length > 0 && !errors.cin}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={facility?.type!=="faculty"? 12 : 6 }>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  téléphone*
                </SoftTypography>
                <PhoneInput
                  id="tel"
                  country={"tn"}
                  value={studentPhone}
                  enableSearch
                  inputStyle={{
                    width: "100%",
                  }}
                  onChange={(value) => {
                    setStudentPhone(value);
                  }}
                />
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={phone.name} />
                  </SoftTypography>
                </SoftBox>
              </Grid>{" "}
             {facility?.type==="faculty" &&( 
             <Grid item xs={12} sm={6}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mb: 0.5 }}
                >
                  Filière*
                </SoftTypography>
                <Field
                  name="filiere"
                  validate={(value) => (value ? undefined : "Filiere est obligatoire")}
                >
                  {({ field, form }) => (
                    <Autocomplete
                      options={optionsFilieres}
                      id="filiere"
                      title="filiere"
                      clearIcon={null}
                      aria-required={true}
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(option, value) => option.value === value.value}
                      value={optionsFilieres.find((option) => option.value === selectedFiliere)||null}
                      onChange={(event, newValue) => {
                        if (newValue !== null && newValue !== undefined) {
                          form.setFieldValue("filiere", newValue.value);
                          form.setFieldValue("niveau","");
                          form.setFieldValue("parcour","");
                          setSelectedLevel("")
                          setSelectedParcour("")
                          setOptionsLevel(
                            newValue?.niveaux?.map((item) => ({
                              value: item._id,
                              label: item.name,
                              parcours: item.parcours,
                            }))
                          );
                          setSelectedFiliere(newValue.value);                     
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params}  variant="standard" placeholder="Tous les Filières" />
                      )}
                    />
                  )}
                </Field>
                <SoftTypography component="div" variant="caption" color="error" mt={0.75}>
                  <ErrorMessage name="filiere" />
                </SoftTypography>
              </Grid>)}
            </Grid>
            {facility?.type==="faculty" && ( 
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mb: 0.5 }}
                >
                  Niveau*
                </SoftTypography>
                <Field
                  name="niveau"
                  validate={(value) => (value ? undefined : "Niveau est obligatoire")}
                >
                  {({ field, form }) => (
                    <Autocomplete
                      options={optionsLevel}
                      id="niveau"
                      title="niveau"
                      clearIcon={null}
                      aria-required={true}
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(option, value) => option.value === value.value}
                      value={optionsLevel.find((option) => option.value === selectedLevel)||null}
                      onChange={(event, newValue) => {
                        if (newValue !== null && newValue !== undefined) {
                          form.setFieldValue("niveau", newValue.value);
                          form.setFieldValue("parcour","");
                          setSelectedParcour("")
                          setOptionsParcours(
                            newValue?.parcours?.map((item) => ({
                              value: item._id,
                              label: item.name,
                            }))
                          );             
                          setSelectedLevel(newValue.value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params}  variant="standard" placeholder="Tous les Niveaux" />
                      )}
                    />
                  )}
                </Field>
                <SoftTypography component="div" variant="caption" color="error" mt={0.75}>
                  <ErrorMessage name="niveau" />
                </SoftTypography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mb: 0.5 }}
                >
                  Parcours*
                </SoftTypography>
                <Field
                  name="parcour"
                  validate={(value) => (value ? undefined : "Parcours est obligatoire")}
                >
                  {({ field, form }) => (
                    <Autocomplete
                      options={optionsParcours}
                      id="parcour"
                      title="parcour"
                      clearIcon={null}
                      aria-required={true}
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(option, value) => option.value === value.value}
                      value={optionsParcours.find((option) => option.value === selectedParcour)||null}
                      onChange={(event, newValue) => {
                        if (newValue !== null && newValue !== undefined) {
                          form.setFieldValue("parcour", newValue.value);
                          setSelectedParcour(newValue.value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" placeholder="Tous les Parcours" />
                      )}
                    />
                  )}
                </Field>
                <SoftTypography component="div" variant="caption" color="error" mt={0.75}>
                  <ErrorMessage name="parcour" />
                </SoftTypography>
              </Grid>
            </Grid>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Pays
                </SoftTypography>
                <SoftSelect
                  id="pays"
                  options={countries.map((country) => ({ value: country, label: country }))}
                  onChange={handleCountryChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftTypography id="ville" component="label" variant="caption" fontWeight="bold">
                  Ville
                </SoftTypography>
                <SoftSelect options={cityOptions} onChange={handleCityChange} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="adresse"
                  type={address.type}
                  label={address.label}
                  name={address.name}
                  value={addressV}
                  inputProps={{
                    maxLength: 50,
                  }}
                  placeholder={address.placeholder}
                  error={errors.address && touched.address}
                  success={addressV.length > 0 && !errors.address}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="code-postal"
                  type={zipCode.type}
                  label={zipCode.label}
                  name={zipCode.name}
                  value={zipCodeV}
                  placeholder={zipCode.placeholder}
                  error={errors.zipCode && touched.zipCode}
                  success={zipCodeV.length > 0 && !errors.zipCode}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}></Grid>
            <Grid container spacing={3}>
              <Grid sx={{ mt: 2, mr: "auto", display: "flow" }} item xs={12}>
                <SoftButton
                  id="btn-upload"
                  sx={{ mb: 1, mt: 1 }}
                  component="label"
                  onChange={handleFileUpload}
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Télécharger des fichiers
                  <VisuallyHiddenInput type="file" />
                </SoftButton>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  placeholder="Télécharger des fichiers "
                  options={options}
                  value={selectedOptions}
                  filterSelectedOptions
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Télécharger des fichiers"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setSelectedOptions(newValue);
                  }}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid mt={1.5} item xs={12} md={12}>
                <FormField
                  id="description"
                  multiline
                  rows={5}
                  label="description"
                  placeholder="description"
                  inputProps={{
                    maxLength: 250,
                  }}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </>
  );
}
// typechecking props for StudentInfo
StudentInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  choixValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setSelectedResp: PropTypes.func.isRequired,
};
export default StudentInfo;
