import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import { CircularProgress,Autocomplete,TextField, Grid, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import api from 'utils/axios';
import Swal from "sweetalert2";
import SoftBox from "components/SoftBox";

export default function AddSemester({ handleFetch }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [inputs, setInputs] = useState([{ name: "",start: "", end: "",type:"" }]);
  const [errorStartDate, setErrorStartDate] = useState("");
  const [errorEndDate, setErrorEndDate] = useState("");
  const [errorType, setErrorType] = useState(null);

  const [optionsTypes, setOptionsTypes] = useState([
    { value: "semestre", label: "Semestre" },
    { value: "trimestre", label: "Trimestre" },
  ]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setInputs([{ name: "",start: "", end: "",type:"" }]);
    setErrorStartDate("")
    setErrorEndDate("")
    setErrorType("")
  };
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  };
  const handleAddInput = () => {
    setInputs([...inputs, { name: "",start: "", end: "",type:"" }]);
  };
  const handleRemoveInput = (indexToRemove) => {
    const newInputs = [...inputs];
    if (indexToRemove >= 0 && indexToRemove < newInputs.length) {
      // Remove the input at the specified index
      newInputs.splice(indexToRemove, 1);
      setInputs(newInputs);
    }
  }
  const handleAddSousPeriode = async () => {
    try {
      setLoading(true);
      // Validate the inputs before submitting
      const hasInvalidInputs = inputs.some((input) => input.name.length < 2);
      const hasInvalidStartDate = inputs.some((input) => !input.start);
      const hasInvalidEndDate = inputs.some((input) => !input.end);
      const hasInvalType = inputs.some((input) => !input.type)
      if (hasInvalidInputs) {
        // Set the error message
        setErrorMessage("La longueur minimale requise est de 2 caractères.");
      } else if (hasInvalType) {
        // Set the error message
        setErrorType("Un type doit être sélectionnée.");
      }else if (hasInvalidStartDate) {
        setErrorStartDate("Veuillez sélectionner une date de début.");
      } else if (hasInvalidEndDate) {
        setErrorEndDate("Veuillez sélectionner une date de fin.");
      } else { 
        // Check for duplicate names
        const duplicateName = inputs.find(
          (input, index) =>
            index !== inputs.findIndex((otherInput) => otherInput.name.toLowerCase() === input.name.toLowerCase())
        );
        if (duplicateName) {
          Swal.fire(
            "Erreur!",
            `Le nom du sous periode existe déjà : ${duplicateName.name}. Merci de saisir un autre nom !`,
            "error"
          );
        } else {
          // Perform the API request only if there are no duplicate names
          await api.post(`/faculty/create-sp?scholarityConfigId=${facility.scholarityConfigId}`, inputs);
          setOpen(true);
          Swal.fire("Success!", "La sous periode ajouté avec succès", "success").then(() => {
            setErrorMessage("");
            setInputs([{ name: "",start: "", end: "",type:"" }]);
            handleFetch();
            setOpen(false);
            setErrorStartDate("")
            setErrorEndDate("")
            setErrorType("")
          });
        }
      }
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddSousPeriode();
    }
  };
  function addDays(dateString, days) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + days);
    // Check if the resulting date is valid
    if (!isNaN(date.getTime())) {
      return date.toISOString().split('T')[0];
    } else {
      //console.error('Invalid date after adding days:', dateString, days);
      return null;
    }
  }
  console.log(inputs);
  return (
    <div>
      <SoftButton
        id="add" onClick={handleClickOpen} variant="gradient"
        color="purple"
        size="small"
        mb={{ xs: 2, sm: 0 }}
      >
        +Ajouter Sous Periode
      </SoftButton>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose} onKeyDown={handleKeyPress}>
        <DialogTitle>Ajouter sous période </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            Vous devez saisir le nom  du sous période afin de l&apos;ajouter.
          </DialogContentText>
          {inputs.map((input, index) => (
            <Grid key={index} sx={{ mt: 2 }}>
              <Grid container spacing={2} sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                alignItems: "flex-start",
              }}>
                <Grid item xs={12}  sm={ 6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Nom du sous période*
                    </SoftTypography>
                    <SoftInput
                      name="name"
                      margin="normal"
                      id="name"
                      label="période"
                      type="text"
                      fullWidth
                      placeholder="Nom du sous période"
                      inputProps={{
                        minLength: 2,
                        maxLength: 50,
                      }}
                      autoFocus
                      variant="standard"
                      value={input.name}
                      onChange={(event) => handleInputChange(index, event)}
                      onBlur={() => {
                        // Update the error when the input loses focus
                        const newInputs = [...inputs];
                        newInputs[index].error = input.name.length < 2;
                        setInputs(newInputs);
                      }}
                      error={input.error && input.name.length < 2}
                      success={input.name.length >= 2}
                    />
                    {input.name.length < 2 && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorMessage}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={ 6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Type*
                  </SoftTypography>
                  <Autocomplete
                    id={`size-small-standard-${index}`}
                    options={optionsTypes}
                    title="Sélectionner uu type"
                    aria-required={true}
                    required={true}
                    getOptionLabel={(option) => option.label}
                    defaultValue={null}
                    clearIcon={null}
                    placeholder="Veuillez
                    sélectionner le
                    type "
                    value={optionsTypes.find((option) => option.value === input.type) || null}
                    onChange={(event, newValue) => {
                      if (newValue !== null && newValue !== undefined) {
                        const newInputs = [...inputs];
                        newInputs[index].type = newValue.value;
                        setInputs(newInputs);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required={true}
                        //value={input.facilityType}
                        placeholder="Veuillez
                        sélectionner le type"
                      />
                    )}
                  />
                  {input.type === "" && (
                    <Typography mt={0.75} variant="caption" color="error">
                      {errorType}
                    </Typography>
                  )}
                </Grid>
                  <Grid item xs={12}  sm={6}>
                <SoftTypography
                  sx={{ mb: 1 }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="text"
                >
                  Date Début*
                </SoftTypography>
                <SoftInput
                  id="date-start"
                  name="start"
                  type="date"
                  onChange={(event) => handleInputChange(index, event)}
                />
                 {(input.start === "" )&& (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorStartDate}
                      </Typography>
                    )}
              </Grid>
              <Grid item xs={12}  sm={6}>
                <SoftTypography
                  sx={{ mb: 1 }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="text"
                >
                  Date Fin*
                </SoftTypography>
                <SoftInput
                  id="date-fin"
                  name="end"
                  type="date"
                  inputProps={{
                    min: addDays(input.start, 1),
                  }}              
                  onChange={(event) => handleInputChange(index, event)}
                />
                  {input.end ==="" && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorEndDate}
                      </Typography>
                    )}
              </Grid>
              
               {/*  <Grid item xs={12} sm={0.5} mt={3.5}>
                  {inputs.length > 1 && (
                    <IconButton>
                      <RemoveCircleIcon
                        color="error"
                        fontSize="small"
                        onClick={() => handleRemoveInput(index)}
                      />
                    </IconButton>
                  )}
                </Grid> */}
              </Grid>
            </Grid>
          ))}
      {/*     <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <SoftButton
              size="small"
              sx={{
                mt: 2,
                borderWidth: "2px",
                borderStyle: "dashed solid",
                borderColor: "purple ",
              }}
              onClick={handleAddInput}
            >
              +ajouter plus
            </SoftButton>
          </Stack> */}
        </DialogContent>

        <DialogActions>
          <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
            <SoftButton
              onClick={handleClose}
              sx={{ mt: 2 }}
              variant="outlined"
              color="dark"
              size="small"
            >
              Annuler
            </SoftButton>

            {loading ? (
              <CircularProgress />
            ) : (
              <SoftButton
                onClick={handleAddSousPeriode}
                sx={{ mt: 2 }}
                variant="gradient"
                color="purple"
                size="small"
              >
                Ajouter
              </SoftButton>
            )}
          </SoftBox>
        </DialogActions>
      </Dialog>
    </div>
  );
}
AddSemester.propTypes = {
  handleFetch: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
