import * as Yup from "yup";
import checkout from "components/shared-users/schemas/form";

const {
  formField: { firstName, lastName, email,  phone, gender },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string()
      .required(firstName.errorMsg)
      .min(2, "La longueur minimale requise est de 2 caractères.")
      .max(30, "La longueur maximale autorisée est de 30 caractères.")
      .matches(
        /^(?!.*\s\s)[\p{L}\s]{2,30}$/u,
        "Veuillez entrer un nom valide."
      ),
    [lastName.name]: Yup.string()
      .required(lastName.errorMsg) 
      .min(2, "La longueur minimale requise est de 2 caractères.")
      .max(30, "La longueur maximale autorisée est de 30 caractères.")
      .matches(
        /^(?!.*\s\s)[\p{L}\s]{2,30}$/u,
        "Veuillez entrer un prénom valide."
      ),
    [email.name]: Yup.string()
    .required(email.errorMsg)
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Veuillez entrer une adresse e-mail valide."
    )
    .max(50, "La longueur maximale autorisée est de 50 caractères."),
    [phone.name]: Yup.string()
      .required(phone.errorMsg)
      .min(8, "La longueur minimale requise est de 8 caractères."),
    [gender.name]: Yup.string().oneOf(["homme", "femme"], "Required").required(gender.errorMsg),
  }),
];

export default validations;
