import { useState, useRef } from "react";
import PropTypes from "prop-types";


// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

// Settings page components
import FormField from "pages/billing-settings/components/FormField";

import api from "utils/axios.js";

function Numbering({ numbering, configsId, setConfigs }) {

  const [minCtrDigits, setMinCtrDigits] = useState(numbering.minCmpDigits);
  const [inputsValidity, setInputsValidity] = useState({isMinCtrDigitValid: true, devisCtrStartValidity: true, factureCtrStartValidity: true, inscCtrStartValidity: true});
  const { current: updates } = useRef({}); // request body
  const [isLoad, setIsLoad] = useState(false);
  const [alert, setAlert] = useState({show: false, message: "", type:""});


  const clearAlert = (ms) => {
    setTimeout(() => {
      setAlert({ show: false, message: "", typ: ""});
    }, ms);
  };

  const handleSubmition = (e) => {
    setIsLoad(true);
    api.patch(`/update-invoices-config/${configsId}`, updates).then((response) => {
      // update the UI
      setConfigs(prevState => {
        const newState = {...prevState};
        for (const [key, value] of Object.entries(updates)) {
          const [outerKey, innerKey] = key.split('.');
          newState[outerKey][innerKey] = value;  
        }
        return newState;
      });
      setAlert({ show: true, message: "la configuration des factures a été mise à jour avec succès", type: "success"});
    }).catch((error) => {
      console.debug(error);
      setAlert({ show: true, message: error.message, type: "error"});
    }).finally(() => {
      setIsLoad(false);
      clearAlert(10_000);
    });

  };

  return (
    <Card id="numeration-card" sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5"> Préférences pour la numérotation </SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>

          {/* ---------------------------------------- format + result view ------------------------------------------------------ */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {/* --------------------------------- format */}
              <Grid item xs={12} sm={6}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <FormField
                    label= "format de la numérotation"
                    defaultValue={numbering.format}
                    readOnly={true}
                  />

                </SoftBox>
              </Grid>
              {/* --------------------------------- result view */}
              <Grid item xs={12} sm={6}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <FormField
                    label= "Aperçu du résultat"
                    value={`F${numbering.counterYear?.toString()?.slice(2)}${'1'.padStart(Math.min(14, minCtrDigits), '0')}`}
                    readOnly={true}
                  />
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>

          {/* ------------------------------------------------- documentation ---------------------------------------------------- */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <SoftTypography variant="button">Afficher la documentation</SoftTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SoftTypography variant="body2">
                      {"<doc> une lettre ou plusieurs lettres représentant le type de document (F pour les factures, D pour les devis, IN inscription)."}
                    </SoftTypography>
                    <SoftTypography variant="body2">
                      {`<aa> est l'année avec deux chiffres donc ${numbering.counterYear?.toString()?.slice(2)}.`}
                    </SoftTypography>
                    <SoftTypography variant="body2">
                      {`<cmp> Le compteur, voir les champs ci-dessous pour le configurer.`}
                    </SoftTypography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
          
          {/* ---------------------------------------- min counter digits ------------------------------------------------------ */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftTypography
                  component="label"
                  variant="h6"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {" Réinitialisation du compteur: Tous les ans"}
                </SoftTypography>
              </Grid>
              {/* --------------------------------- counter min digits */}
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Taille minimale du compteur"
                  inputProps={{ type: "number", min: 1, max: 14 }} defaultValue={minCtrDigits}
                  onChange = {
                    (e) => {
                      const value = e.target.value;
                      const isValid = /^[+]?([.]\d+|\d+([.]\d+)?)$/.test(value) && 1 <= +value && +value <= 14;
                      if (isValid) {
                        updates["numbering.minCmpDigits"] = +(e.target.value);
                        setMinCtrDigits(+(e.target.value));
                      } else delete updates["numbering.minCmpDigits"];
                      setInputsValidity((prevState) => ({
                        ...prevState,
                        isMinCtrDigitValid: isValid
                      }));
                    }
                  }
                  error={!inputsValidity.isMinCtrDigitValid}
                />
              </Grid>
            </Grid>
          </Grid>


          {/* ---------------------------------------- Counters Start At ------------------------------------------------------ */}
          <Grid item xs={12}>{<SoftTypography variant="h5"> Compteurs </SoftTypography>}</Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  disabled = {numbering.devisCounter > 0}
                  label="Débuter le compteur pour les devis à"
                  inputProps={{ type: "number", min: 1, max: 32000 }} defaultValue={numbering.devisCmpStart}
                  onChange = {
                    (e) => {
                      const isValid = /^[+]?([.]\d+|\d+([.]\d+)?)$/.test(e.target.value);
                      if (isValid) updates["numbering.devisCmpStart"] = +(e.target.value);
                      else delete updates["numbering.devisCmpStart"];
                      setInputsValidity((prevState) => ({
                        ...prevState,
                        devisCtrStartValidity: isValid
                      }));
                    }
                  }
                  error={!inputsValidity.devisCtrStartValidity}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  disabled = {numbering.factureCounter > 0}
                  label="Débuter le compteur pour les factures à"
                  inputProps={{ type: "number", min: 1, max: 32000 }} defaultValue={numbering.factureCmpStart}
                  onChange = {
                    (e) => {
                      const isValid = /^[+]?([.]\d+|\d+([.]\d+)?)$/.test(e.target.value);
                      if (isValid) updates["numbering.factureCmpStart"] = +(e.target.value);
                      else delete updates["numbering.factureCmpStart"];
                      setInputsValidity((prevState) => ({
                        ...prevState,
                        factureCtrStartValidity: isValid
                      }));
                    }
                  }
                  error={!inputsValidity.factureCtrStartValidity}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  disabled = {numbering.inscriptionCounter > 0}
                  label="Débuter le compteur pour les inscriptions à"
                  inputProps={{ type: "number", min: 1, max: 32000 }} defaultValue={numbering.inscriptionCmpStart}
                  onChange = {
                    (e) => {
                      const isValid = /^[+]?([.]\d+|\d+([.]\d+)?)$/.test(e.target.value);
                      if (isValid) updates["numbering.inscriptionCmpStart"] = +(e.target.value);
                      else delete updates["numbering.inscriptionCmpStart"];
                      setInputsValidity((prevState) => ({
                        ...prevState,
                        inscCtrStartValidity: isValid
                      }));
                    }
                  }
                  error={!inputsValidity.inscCtrStartValidity}
                />
              </Grid>
            </Grid>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            { alert.show && <Alert severity={alert.type}> {alert.message} </Alert> }
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox
              display="flex"
              justifyContent="end"
            >
              {
                isLoad 
                ? <CircularProgress/>
                : <SoftButton
                  variant="gradient"
                  color="customized"
                  size="medium"
                  onClick={handleSubmition}
                >
                  Enregistrer
                </SoftButton>
              }
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

Numbering.defaultProps = {
  numbering: {
    format:"",
    minCmpDigits: 0,
    devisCmpStart: 1,
    factureCmpStart: 1,
    bonCommandeCmpStart: 1,
    inscriptionCmpStart: 1,
    devisCounter: 0,
    factureCounter: 0,
    bonCommandeCounter: 0,
    inscriptionCounter: 0,
    counterYear: new Date().getFullYear()
  },
  configsId: "",
  setConfigs: new Function(),
};

// Typechecking props for BaseLayout
Numbering.propTypes = {
  numbering: PropTypes.object,
  configsId: PropTypes.string,
  setConfigs: PropTypes.func,
};

export default Numbering;
