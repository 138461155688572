import { Card, CircularProgress, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Form, Formik } from "formik";
import DashboardNavbar from "layouts/DashboardNavbar";
import React, { useEffect, useState } from "react";
import StudentInfo from "./components/StudentInfo";
import Swal from "sweetalert2";


import api from "utils/axios";
import { useNavigate } from "react-router-dom";
import Header from "layouts/pages/profile/components/Header";
import { useSoftUIController } from "context";

import form from "./schema/form";
import validations from "./schema/validations";
import initialValues from "./schema/initialValues";




function getStepContent(_, formData) {

  return <StudentInfo
    formData={formData} />;
}

export default function NewStudent() {
  // ---------------------this for check the size limit of the company
  const [errorLimitSize, setErrorLimitSize] = useState(null);
  const [limitSize, setLimitSize] = useState();
  const [limitUsers, setLimitUsers] = useState();
  // ---------------------this for check the size limit of the company
  const [controller, _] = useSoftUIController();
  const { user } = controller;


  const facility = JSON.parse(localStorage.getItem("facility"));
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { formId, formField } = form;
  const navigate = useNavigate();
  const currentValidation = validations[activeStep];
  const handleValidate = async (values, _) => {
    try {
      //setIsLoading(true);
      const { data } = await api.post("/validation", {
        type: "USER_VALIDATION",
        payload: {
          email: values.email,
        }
      });
      //setIsLoading(false);
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.email, "error").then(() => {
        // setIsLoading(false);
      })
      setIsLoading(false);
    }
  }

  const submitForm = async (values, _) => {
    if (values.files.length === 0 && errorLimitSize) setErrorLimitSize(null);
    // check if the image is selected and the company dosent have a limit of size
    if (errorLimitSize && values.files && values.files.length > 0) return Swal.fire("Erreur!", errorLimitSize, "error");
    const form = new FormData();
    const info = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      address: values.address,
      zipCode: values.zipCode,
      cin: values.cin,
      rib: values.rib,
      bank: values.bank,
      comment: values.description,
      phoneNumber: values.phone,

      gender: values.gender,
      files: values.files,
      imageUrl: values.imageUrl,
      birthday: values.birthday,
      cnssNumber: values.cnssNumber,
      country: values.country,
      city: values.city,
      responsable: values.responsable,
      ...(values.parcour!=="" && { parcour: values.parcour }),
      role: "student",
    };

    try {
      form.append("firstName", info.firstName);
      form.append("lastName", info.lastName);
      form.append("email", info.email);
      form.append("cin", info.cin);
      form.append("role", info.role);
      form.append("address", info.address);
      form.append("zipCode", info.zipCode);
      form.append("bank", info.bank);
      form.append("rib", info.rib);
      form.append("birthday", info.birthday);
      form.append("cnssNumber", info.cnssNumber);
      form.append("phoneNumber", info.phoneNumber);
      form.append("gender", info.gender);
      form.append("country", info.country);
      form.append("city", info.city);
      if (info.parcour) {
        form.append("parcour", info.parcour);
      }
      form.append("comment", info.comment);
      form.append("imageUrl", info.imageUrl);
      info.files &&

        info.files.length > 0 &&
        info.files.forEach((item) => {
          form.append("files", item.value);
        });
      setIsLoading(true)
      const query = user.info.role === "company-admin" ? `?facilityId=${facility._id}` : "";
      let data;
      if (values.choix === "choix1") {
        data = await api.post(`/add-one-student-in-specific-responsable/${values.responsable}${query}`, form);
      } else if (values.choix === "choix2") {
        data = await api.post(`/add-One-user${query}`, form);
      }

      if (data.status === 201) {
        Swal.fire("Succès !", "L'étudiant a été ajouté avec succès", "success").then(() => {
          setIsLoading(false)
          navigate("/administration/students");
        });
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.data.message === "dublicate inputs") {
          setError("L'adresse mail est déjà utilisé");
          Swal.fire("Error!", "L'adresse mail est déjà utilisé", "error");
        } else {
          setError(error.response.data.message);
          Swal.fire("Error!", error.response.data.message, "error");
        }
      } else {
        setError("Une erreur s'est produite. Veuillez réessayer ultérieurement.");
      }
    }
    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    handleValidate(values, actions);
    submitForm(values, actions);
  };

  /**
 * This function check if the enterprise has reached the limit size of data
 * @author med-aziz-guennichi
 * @returns {Promise<void>}
 */
  const handleCheckSizeLimit = async () => {
    try {
      const { data } = await api.get(
        `/get-files-size-enterprise/${JSON.parse(localStorage.getItem("licence")).enterpriseId}`
      );
      // if the Megabytes is equal to the limit of the enterprise or if it's greater than the limit
      if (data.Megabytes === limitSize || data.Megabytes > limitSize) {
        setErrorLimitSize("You dont have access to push another file");
      }
    } catch (error) {
      throw error;
    }
  };

  // Check if the limit size is set and if it's the case get the limit size
  // from the localStorage and set it to the state
  // We also need to trigger the check if the limit size is reached or not
  useEffect(() => {
    if (localStorage.getItem("licence")) {
      const licence = JSON.parse(localStorage.getItem("licence"));
      // If there is no licence, we return and not proceed
      if (!licence) return;
      // Set the limit size to the state
      setLimitSize(licence.remainingDataSizeMb);
      // Set the limit users to the state
      setLimitUsers(licence.remainingUser);
      // Check if the limit size is reached or not, if it's the case
      // we display an error message to the user
      handleCheckSizeLimit();
    }
  }, [limitSize, limitUsers]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title={"Ajouter un Étudiant"} />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, _ }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>

                    <SoftBox p={2}>

                      <SoftBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <SoftButton id="btn-cancel" onClick={() => navigate('/administration/students')} variant="outlined" color="dark">
                            Annuler
                          </SoftButton>
                          {
                            isLoading ? <CircularProgress /> : (

                              <SoftButton disabled={limitUsers === 0} id="btn-add" type="submit" variant="gradient" color="customized">
                                Ajouter
                              </SoftButton>)
                          }

                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>

    </DashboardLayout>
  );
}
