// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import React, { useRef, useEffect, useState } from 'react';
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import EmailIcon from "@mui/icons-material/Email";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SendIcon from "@mui/icons-material/Send";
import ListIconCardStudent from "./ListIconCardStudent";
import { Tooltip } from "@mui/material";

function StudentCard({ image, student, etat, title, phone, dateTime, description, members, dropdown, setOpen }) {
    const cardRef = useRef(null);
    const [cardWidth, setCardWidth] = useState(0);

    const emailRef = useRef(null);
    useEffect(() => {
        const updateEmailStyle = () => {
            if (emailRef.current) {
                const cardWidth = cardRef.current.offsetWidth;
                const emailText = emailRef.current.textContent || emailRef.current.innerText;
                const maxEmailLength = Math.floor((cardWidth / 10));
                const truncatedEmail = emailText.length > maxEmailLength
                    ? `${emailText.slice(0, maxEmailLength)}...`
                    : emailText;
                emailRef.current.textContent = truncatedEmail;
            }
        };

        window.addEventListener('resize', updateEmailStyle);

        // Initial update
        updateEmailStyle();

        return () => {
            window.removeEventListener('resize', updateEmailStyle);
        };
    }, []);

    const renderMembers = members.map((member, key) => {
        const memberKey = `member-${key}`;
        return (
            <SoftAvatar
                key={memberKey}
                src={member}
                alt="member profile"
                size="xs"
                sx={({ borders: { borderWidth }, palette: { white } }) => ({
                    border: `${borderWidth[2]} solid ${white.main}`,
                    cursor: "pointer",
                    position: "relative",

                    "&:not(:first-of-type)": {
                        ml: -1.25,
                    },

                    "&:hover, &:focus": {
                        zIndex: "10",
                    },
                })}
            />
        );
    });
    const isActive = etat ? etat.etat : null;
    return (
        <Card >
            <SoftBox p={2}  >
                <SoftBox display="flex" alignItems="center" sx={{ p: 1, }}    >
                    <SoftBox
                        display="flex"
                        alignItems="center"
                        sx={{
                            p: 0.3,
                            border: `3px solid ${etat === "active" ? "#0CB40C" : etat === "enAttente" ? "#66686B" : "#C20D0D"}`,

                            borderRadius: '1rem',
                        }}
                    >
                        <SoftAvatar
                            src={image}
                            alt={title}
                            size="xl"
                            variant="rounded"
                        />  </SoftBox>
                    <SoftBox ml={1} lineHeight={0}>

                        <SoftBox lineHeight={0}>
                            <Tooltip title={title || ""}>
                                <SoftTypography variant="h6"
                                    textTransform="capitalize"
                                    fontWeight="medium"
                                    sx={{
                                        wordWrap: "break-word",
                                        maxWidth: "100%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 1,
                                    }}
                                >
                                    {title}
                                </SoftTypography>
                            </Tooltip>
                            <SoftTypography variant="body2" color="text" textTransform="capitalize" fontWeight="medium">
                                {phone}
                            </SoftTypography>
                        </SoftBox>
                        {members.length > -1 ? <SoftBox display="flex">{renderMembers}</SoftBox> : null}
                    </SoftBox>

                    {dropdown && (
                        <SoftTypography
                            color="secondary"
                            onClick={dropdown.action}
                            sx={{
                                ml: "auto",
                                alignSelf: "flex-start",
                                py: 1.25,
                            }}
                        >
                            <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                                more_vert
                            </Icon>
                        </SoftTypography>
                    )}
                    {dropdown.menu}
                </SoftBox>

                <SoftBox my={2} lineHeight={1}>
                    <SoftTypography variant="button" fontWeight="regular" color="text">
                        {description}
                    </SoftTypography>
                </SoftBox>
                <Divider />
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    {members.length > 0 ? (
                        <SoftBox display="flex" flexDirection="column" lineHeight={0}>
                            <SoftTypography variant="button" fontWeight="medium">
                                {members.length}
                            </SoftTypography>
                            <SoftTypography variant="button" fontWeight="medium" color="secondary">
                                Participants
                            </SoftTypography>
                        </SoftBox>
                    ) : null}
                    {dateTime ? (
                        <SoftBox display="flex" flexDirection="column" lineHeight={0}>
                            <SoftTypography variant="button" fontWeight="medium">
                                {dateTime}
                            </SoftTypography>
                            <SoftTypography variant="button" fontWeight="medium" color="secondary">
                                <EmailIcon />
                                <SendIcon />
                            </SoftTypography>

                        </SoftBox>
                    ) : null}

                    <SoftBox display="flex" flexDirection="column" lineHeight={10} >
                    </SoftBox>
                    <ListIconCardStudent
                        student={student}
                        etat={etat}
                        setOpen={setOpen}

                    />
                </SoftBox>
            </SoftBox>
        </Card>

    );
}

// Setting default values for the props of ComplexProjectCard
StudentCard.defaultProps = {
    color: "dark",
    dateTime: "",
    members: [],
    dropdown: false,
};

// Typechecking props for the ProfileInfoCard
StudentCard.propTypes = {

    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ]),
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    dateTime: PropTypes.string,
    description: PropTypes.node.isRequired,
    members: PropTypes.arrayOf(PropTypes.string),
    dropdown: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            action: PropTypes.func,
            menu: PropTypes.node,
        }),
    ]),
};
StudentCard.propTypes = {
    student: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    etat: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    setOpen: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,

};
export default StudentCard;