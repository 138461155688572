import React, { useState } from "react";
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import api from "utils/axios";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSoftUIController } from "context";

function SemesterActions({ semester, handleFetch, setShowDetails, setDetailsData }) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(semester.name);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [controller] = useSoftUIController();
  const [inputs, setInputs] = useState([{ name: semester?.name }]);
  const [startDate, setStartDate] = useState(semester?.start);
  const [endDate, setEndDate] = useState(semester?.end);
  const [type, setType] = useState(semester?.type);
  const [errorStartDate, setErrorStartDate] = useState("");
  const [errorEndDate, setErrorEndDate] = useState("");
  const [errorMessageType, setErrorMessageType] = useState("");

  const [optionsTypes, setOptionsTypes] = useState([
    { value: "semestre", label: "Semestre" },
    { value: "trimestre", label: "Trimestre" },
  ]);
  const handleUpdate = async () => {
    try {
      setLoading(true);
      const hasInvalidInputs = inputs.some((input) => input.name.length < 2);
      if (hasInvalidInputs) {
        setErrorMessage("La longueur minimale requise est de 2 caractères.");
      } else if (!startDate) {
        setErrorStartDate("Veuillez sélectionner une date de début.");
      } else if (!endDate) {
       setErrorEndDate("Veuillez sélectionner une date de fin.");
      } else {
        const inputsWithDates = {
          ...inputs[0],
          start: startDate,
          end: endDate, 
          type:type
        };
        const response = await api.patch(`/faculty/update-sp/${semester._id}`,  inputsWithDates);
        setName(response.data.name);
        Swal.fire("Success!", "Mise à jour du sous période réussie", "success");
        handleFetch();
        setOpen(false);
        setErrorMessage("");
        setInputs([{ name: "" }]);
      }
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setName(semester.name);
  };
  const showAlert = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous voulez supprimer ce sous période ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(`/faculty/delete-sp/${semester._id}`);
            if (response) {
              (await Swal.fire("Suppression !", "La sous période a été supprimée.", "success"))
                .isConfirmed && handleFetch();
            }
          } catch (error) {
            Swal.fire("Erreur!", "La sous période n'a pas été supprimée.", "error");
          }
        }
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[0][name] = value;
    setInputs(newInputs);
  };
  function addDays(dateString, days) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + days);
    // Check if the resulting date is valid
    if (!isNaN(date.getTime())) {
      return date.toISOString().split('T')[0];
    } else {
      //console.error('Invalid date after adding days:', dateString, days);
      return null;
    }
  }
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Consulter" placement="left">
          <VisibilityIcon
            onClick={() => {
              setDetailsData(semester);
              setShowDetails(true);
            }}
          />
        </Tooltip>
      </SoftTypography>
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Modifier" placement="top">
            <Icon onClick={() => setOpen(true)} id="edit-enterprise-page">
              edit
            </Icon>
          </Tooltip>
          <Dialog fullWidth open={open} maxWidth="lg" onKeyDown={handleKeyPress}>
            <DialogTitle>Modifier sous période</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.grey[200],
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <DialogContentText>
                Vous devez saisir le nouveau nom de votre sous période.
              </DialogContentText>
              {inputs.map((input, index) => (
                <>
                  <Grid key={index} sx={{ mt: 1 }}>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "flex-start",
                      }}
                    >
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5 }}
                        >
                          Nom du sous période*
                        </SoftTypography>
                        <SoftInput
                          name="name"
                          margin="normal"
                          id="name"
                          label="période"
                          type="text"
                          fullWidth
                          placeholder="Nom du sous période"
                          inputProps={{
                            minLength: 2,
                            maxLength: 50,
                          }}
                          autoFocus
                          variant="standard"
                          value={inputs[0].name}
                          onChange={(event) => handleInputChange("hello", event)}
                          error={input.name.length < 2}
                          success={input.name.length >= 2}
                        />
                        {input.name.length < 2 && (
                          <Typography mt={0.75} variant="caption" color="error">
                            {errorMessage}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={ 6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Type*
                  </SoftTypography>
                  <Autocomplete
                    id={`size-small-standard-${index}`}
                    options={optionsTypes}
                    title="Sélectionner uu type"
                    aria-required={true}
                    required={true}
                    getOptionLabel={(option) => option.label}
                    defaultValue={null}
                    clearIcon={null}
                    placeholder="Veuillez
                    sélectionner le
                    type "
                    value={optionsTypes.find((option) => option.value === type) || null}
                    onChange={(event, newValue) => {
                      if (newValue !== null && newValue !== undefined) {
                        setType(newValue.value)                     
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required={true}
                        //value={input.facilityType}
                        placeholder="Veuillez
                        sélectionner le type"
                      />
                    )}
                  />
                  {type === "" && (
                    <Typography mt={0.75} variant="caption" color="error">
                      {errorMessageType}
                    </Typography>
                  )}
                </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          sx={{ mb: 1 }}
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          color="text"
                          textTransform="capitalize"
                        >
                          Date Début*
                        </SoftTypography>
                        <SoftInput
                          id="date-start"
                          type="date"
                          defaultValue={startDate}
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(e.target.value);
                          }}
                        />
                    {(startDate==="")&& (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorStartDate}
                      </Typography>
                    )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          sx={{ mb: 1 }}
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          color="text"
                          textTransform="capitalize"
                        >
                          Date Fin*
                        </SoftTypography>
                        <SoftInput
                          id="date-fin"
                          type="date"
                          inputProps={{
                            min: addDays(startDate, 1),
                          }}
                          defaultValue={endDate}
                          value={endDate}
                          onChange={(e) => {
                            setEndDate(e.target.value);
                          }}
                        />
                         {(endDate==="")&& (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorEndDate}
                      </Typography>
                    )}
                      </Grid> 
                    </Grid>
                  </Grid>
                </>
              ))}
            </DialogContent>
            <DialogActions>
              <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                <SoftButton onClick={handleClose} sx={{ mt: 2 }} size="small" variant="outlined" color="dark">
                  Annuler
                </SoftButton>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <SoftButton
                    onClick={handleUpdate}
                    sx={{ mt: 2 }}
                    variant="gradient"
                    color="customized"
                    size="small"
                  >
                    Modifier
                  </SoftButton>
                )}
              </SoftBox>
            </DialogActions>
          </Dialog>
        </SoftTypography>
      </SoftBox>
      {controller?.user?.info?.role === "company-admin" && (
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Supprimer" placement="left">
            <Icon id="delete-enterprise" onClick={showAlert}>
              delete
            </Icon>
          </Tooltip>
        </SoftTypography>
      )}
    </SoftBox>
  );
}
SemesterActions.propTypes = {
  semester: PropTypes.object.isRequired,
  setDetailsData: PropTypes.array.isRequired,
  setShowDetails: PropTypes.bool.isRequired,
  handleFetch: PropTypes.func.isRequired,
};

export default SemesterActions;
