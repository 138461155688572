
import PropTypes from "prop-types";

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";



function Header({enterprise}) {
  const [visible, setVisible] = useState(true);

  const handleSetVisible = () => setVisible(!visible);
  return (
    <Card id="profile">
      <SoftBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SoftAvatar
          src={`${process.env.REACT_APP_BASE_URL}/${enterprise?.logoUrl}`}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {enterprise?.name}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
                CEO / {enterprise?.enterpriseAdmin?.firstName} {enterprise?.enterpriseAdmin?.lastName}
              </SoftTypography>
            </SoftBox>
          </Grid>
          
        </Grid>
      </SoftBox>
    </Card>
  );
}
// typechecking props for UserInfo
Header.propTypes = {
    enterprise: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  };
export default Header;
