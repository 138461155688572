import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import api from "utils/axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSoftUIController } from "context";
import { useNavigate } from "react-router-dom";
const SubjectActions = ({ subject, handleFetch, setShowDetails, setDetailsData }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const navigate = useNavigate();
  const handleDelete = async () => {
    try {
      setLoading(true);
      const newSwal = Swal.mixin({
        customClass: {
          confirmButton: "button button-success",
          cancelButton: "button button-error",
        },
        buttonsStyling: false,
      });
      newSwal
        .fire({
          title: "Êtes-vous sûr(e) ?",
          text: "Vous voulez supprimer cette matière ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui, supprimer!",
          cancelButtonText: "Annuler",
        })
        .then((resut) => {
          if (resut.isConfirmed) {
            api
              .delete(`/faculty/delete-subject/${subject._id}`)
              .then(() => {
                newSwal
                  .fire("Succès", "La matière a été supprimée avec succès", "success")
                  .then(() => {
                    setOpen(false);
                    handleFetch();
                  });
              })
              .catch(() => {
                newSwal.fire("Erreur!", "La matière n'a pas été supprimée", "error");
              });
          }
        });
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Consulter" placement="left">
          <VisibilityIcon
            onClick={() => {
              setDetailsData(subject);
              setShowDetails(true);
            }}
          />
        </Tooltip>
      </SoftTypography>
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Modifier" placement="top">
            <Icon
              onClick={() => navigate(`/plan/subject/${subject?._id}`)}
              id="edit-enterprise-page"
            >
              edit
            </Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
      {controller?.user?.info?.role === "company-admin" && (
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="supprimer" placement="left">
            <Icon id="delete-enterprise" onClick={handleDelete}>
              delete
            </Icon>
          </Tooltip>
        </SoftTypography>
      )}
    </SoftBox>
  );
};
SubjectActions.propTypes = {
  subject: PropTypes.object.isRequired,
  handleFetch: PropTypes.func.isRequired,
  setShowDetails: PropTypes.bool.isRequired,
  setDetailsData: PropTypes.array.isRequired,
};
export default SubjectActions;
