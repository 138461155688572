import React, { useState } from 'react'
import PropTypes from "prop-types";
import { Autocomplete, Box, FormControlLabel, Grid, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import SoftBox from 'components/SoftBox';

import { FormControl } from 'material-ui-core';

const CommonComponentWeek = ({
    typeSalle,
    setMatiere,
    setTypeSalle,
    optionsMatiere,
    optionsSalle,
    optionsEnseignant
}) => {
    return (
        <>
            <SoftBox>
                <>
                    <Grid item xs={12} sm={12}>
                        <Stack spacing={3}>
                            <SoftBox>
                                <SoftTypography
                                    variant="caption"
                                    fontWeight="medium"
                                    textTransform="capitalize"
                                >
                                    Choix des matières :
                                </SoftTypography>
                                <Autocomplete
                                    id={`size-small-standard`}
                                    options={optionsMatiere}
                                    aria-required={true}
                                    required={true}
                                    clearIcon={null}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        setMatiere(newValue.value);
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Radio
                                                checked={selected}
                                                onChange={() => {/* handle option selection */ }}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField placeholder="Sélectionner le nom de matière" sx={{ mb: 2 }} {...params} required={true} variant="standard" />
                                    )}
                                />
                            </SoftBox>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <SoftTypography
                            variant="caption"
                            fontWeight="medium"
                            textTransform="capitalize"
                        >
                            Type d&lsquo;examen :
                        </SoftTypography>
                        <Grid ml={1}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-form-control-label-placement"
                                    name="position"
                                >
                                    <FormControlLabel value="ecrit" control={<Radio />} label="Ecrit" />
                                    <FormControlLabel value="pratique" control={<Radio />} label="Pratique" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <SoftTypography
                            variant="caption"
                            fontWeight="medium"
                            textTransform="capitalize"
                        >
                            La salle :
                        </SoftTypography>
                        {/*   <Grid ml={1} mb={2}>
              <FormControl>
              <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              onChange={(e)=>setTypeSalle(e.target.value)}
              defaultValue="présentiel" 
            >
              <FormControlLabel value="présentiel" control={<Radio />} label="Présentiel" />
              <FormControlLabel value="enligne" control={<Radio />} label="En ligne" />
              </RadioGroup>
            </FormControl>
            </Grid> */}

                    </Grid>
                    <Grid container spacing={3} mb={2}>

                        <Grid item xs={12} sm={12}>
                            {/*  {typeSalle ==="enligne" ? ( 
     <SoftInput
            margin="normal"
            id="outlined-basic"
           label="Jour"
           type="Text"
           placeholder="Taper votre lien"
           inputProps={{
            min:"30",
           max:"540" ,
           step:"30"
          }}
           variant="standard"
               />):
       (  */}
                            <Grid item xs={12} sm={12}>
                                <Stack spacing={3}>
                                    <SoftBox>
                                        <Autocomplete
                                            id={`size-small-standard`}
                                            options={optionsSalle}
                                            aria-required={true}
                                            required={true}
                                            clearIcon={null}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, newValue) => {
                                                console.log(newValue);
                                            }}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Radio
                                                        checked={selected}
                                                        onChange={() => {/* handle option selection */ }}
                                                    />
                                                    {option.label}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField placeholder="Sélectionner la salle" sx={{ mb: 2 }} {...params} required={true} variant="standard" />
                                            )}
                                        />
                                    </SoftBox>
                                </Stack>
                            </Grid>
                            {/*    )} */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Stack spacing={3}>
                            <SoftBox>
                                <SoftTypography
                                    variant="caption"
                                    fontWeight="medium"
                                    textTransform="capitalize"
                                >
                                    Choix des Enseignants :
                                </SoftTypography>
                                <Autocomplete
                                    id={`size-small-standard`}
                                    options={optionsEnseignant}
                                    aria-required={true}
                                    required={true}
                                    clearIcon={null}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        console.log(newValue);
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Radio
                                                checked={selected}
                                                onChange={() => {/* handle option selection */ }}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField placeholder="Sélectionner le nom de l'enseignant" sx={{ mb: 2 }} {...params} required={true} variant="standard" />
                                    )}
                                />
                            </SoftBox>
                        </Stack>
                    </Grid>
                </>
            </SoftBox>
        </>
    );
};
CommonComponentWeek.propTypes = {
    typeSalle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    setMatiere: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    setTypeSalle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    optionsMatiere: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    optionsSalle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    optionsEnseignant: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
export default CommonComponentWeek;


