import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import SoftButton from "components/SoftButton";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
// import AjouterDemande from "../AjouterDemande";
// import UpdateDemande from "../UpdateDemande";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Card from "./components/Card";
import api from "utils/axios";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import Swal from "sweetalert2";
// import UpdateDemandeByStudent from "../UpdateDemandeByStudent";
import SoftSelect from "components/SoftSelect";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSoftUIController } from "context";

function KanbanEvents() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [controller, _] = useSoftUIController();

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const facility = JSON.parse(localStorage.getItem("facility"));

  const handleEvents = () => {
    api.get(`get-events/${controller.user.info._id}/${facility._id}`).then((response) => {
      const sortedEvents = response.data.eventsByPersonneConnecter.sort((eventA, eventB) => {
        const dateA = new Date(eventA.dateDebut);
        const dateB = new Date(eventB.dateDebut);
        return dateB - dateA;
      });
      setEvents(sortedEvents);
      setLoading(false);
    });
  };

  useEffect(() => {
    handleEvents();
  }, []);

  const cards = (stat) => {
    let filteredEvents = [];

    // Apply combined filters
    filteredEvents = events.filter((event) => {
      // Filter by role
      const roleFilter =
        controller.user.info.role === "student" ? event.creerPar === controller.user.info._id : true;
      // Filter by statut
      const statutFilter = event.statut === stat;

      // Return true if all filters pass, otherwise false
      return statutFilter && roleFilter;
    });

    const cardsUtiliser = filteredEvents.map((event) => ({
      id: event._id,
      template: (
        <Card
          evaluation={{
            color:
              event.evaluation === "Valider"
                ? "success"
                : event.evaluation === "Non valider"
                ? "error"
                : "secondary",
            label: event.evaluation !== undefined ? event.evaluation : "Neutral",
          }}
          id={event._id}
          nomEvent={event.nom.length >= 16 ? event.nom.substring(0,16) + "..." : event.nom}
          image={event.image}
          categorie={event.categorie}
          date={moment(event.dateDebut).format("DD/MM/YYYY") + " " + event.heureDebut}
        />
      ),
    }));

    return cardsUtiliser;
  };

  const status = {
    columns: [
      {
        id: "0",
        title: "A faire",
        cards: cards("A faire"),
      },
      {
        id: "1",
        title: "En cours",
        cards: cards("En cours"),
      },
      {
        id: "2",
        title: "Terminer",
        cards: cards("Terminer"),
      },
    ],
  };

  const [columns, setColumns] = useState(status.columns);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Do nothing if the item is dropped outside the list
    if (!destination) return;

    // Do nothing if the item is dropped in the same place
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // Determine the new status based on the destination.droppableId
    let newStatus = "";
    switch (destination.droppableId) {
      case "0":
        newStatus = "A faire";
        break;
      case "1":
        newStatus = "En cours";
        break;
      case "2":
        newStatus = "Terminer";
        break;
    }

    // Find the dragged task using its ID
    const draggedEvent = events.find((event) => event._id === result.draggableId);

    if (!draggedEvent) {
      return;
    }

    // Check if the evaluation is Neutral and the destination status is Terminer
    if (
      draggedEvent.evaluation === undefined &&
      destination.droppableId === "2" // Terminer column
    ) {
      Swal.fire({
        title: "Warning",
        text: "Veuillez sélectionner le statut de l'évènement.",
        icon: "warning",
        confirmButtonText: "ok",
      });
    } else {
      if (
        draggedEvent.statut === "Terminer" &&
        (draggedEvent.evaluation === "Valider" || draggedEvent.evaluation === "Non valider") &&
        destination.droppableId !== source.droppableId
      ) {
        // Show a confirmation alert indicating that the card cannot be moved
        Swal.fire({
          title: "Warning",
          text: "L'évaluation a déjà été choisie.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
        newStatus = "Terminer";
      }
      // Update the task status locally
      const updatedEvents = events.map((event) =>
        event._id === draggedEvent._id ? { ...event, statut: newStatus } : event
      );
      setEvents(updatedEvents);

      // Update the task status in the backend
      updateEventstatus(draggedEvent._id, newStatus);
    }
  };

  const updateEventstatus = (eventId, newStatus) => {
    const updateEvent = { statut: newStatus };
    api.put(`update-event/${eventId}`, updateEvent);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box display="flex" mt="16px" alignItems="center">
        <Typography variant="h5" sx={{ flexGrow: "1" }} >
          Kanban des événements
        </Typography>
        {(controller.user.info.role === "admin" || controller.user.info.role === "editor" || controller.user.info.role === "company-admin") && (
          <SoftButton
            variant="gradient"
            color="purple"
            onClick={() => {
              navigate("/events/kanbanEvents/ajouterEvent");
            }}
          >
            Ajouter Évènement
          </SoftButton>
        )}
      </Box>

      {(controller.user.info.role === "admin" || controller.user.info.role === "editor" || controller.user.info.role === "company-admin") &&
        (loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "500px",
            }}
          >
            <CircularProgress color="primary" sx={{ mr: 1 }} />
            <span style={{ fontSize: "1.2rem", color: "primary" }}>En cours</span>
          </Box>
        ) : (
          <DragDropContext
            sx={{ overflow: "visible", zIndex: 1 }}
            onDragEnd={(result) => {
              onDragEnd(result, status.columns, setColumns);
            }}
          >
            <Grid container my={2}>
              {status.columns.map((column) => (
                <Grid
                  item
                  key={column.id}
                  sx={{
                    backgroundColor: "#EDF0F3",
                    flex: "1 1", // flexGrow, flexShrink
                    maxWidth : {md :"calc(50% - 20px)"},
                    minWidth:"330px",
                    margin: "10px",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <SoftTypography variant="h6">{column.title}</SoftTypography>
                  <Droppable droppableId={column.id}>
                    {(provided) => (
                      <Box ref={provided.innerRef} {...provided.droppableProps}>
                        {column.cards.map((card,index) => (
                          <Draggable key={card.id} draggableId={card.id} index={index}>
                            {(provided) => (
                              <>
                                <Box
                                  onClick={() => {
                                    navigate(`/events/kanbanEvents/updateEvent/${card.id}`);
                                  }}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <SoftBox
                                    // key={card.id}
                                    // width={{ xs: "calc(330px - 40px)", md: "calc(382px - 40px)" }}
                                    bgColor="white"
                                    color="text"
                                    borderRadius="10px"
                                    mt="20px"
                                    py="14px"
                                    px="14px"
                                    lineHeight="12px"
                                  >
                                    {card.template}
                                  </SoftBox>
                                </Box>
                              </>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Grid>
              ))}
            </Grid>
          </DragDropContext>
        ))}

      {controller.user.info.role === "student" &&
        (loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "500px",
            }}
          >
            <CircularProgress color="primary" sx={{ mr: 1 }} />
            <span style={{ fontSize: "1.2rem", color: "primary" }}>En cours</span>
          </Box>
        ) : (
          <Grid container my={2}>
            {status.columns.map((column) => (
              <Grid
                item
                key={column.id}
                sx={{
                  backgroundColor: "#EDF0F3",
                  flex: "1 1", // flexGrow, flexShrink
                  maxWidth : {md :"calc(50% - 20px)"},
                  minWidth:"330px",
                  margin: "10px",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <SoftTypography variant="h6">{column.title}</SoftTypography>
                {column.cards.map((card) => (
                  <Box
                    key={card.id}
                    onClick={() => {
                      column.title === "A faire"
                        ? navigate(`/events/kanbanEvents/updateEventByStudent/${card.id}`) //update event by student
                        : navigate(`/events/kanbanEvents/voirEvent/${card.id}`) //get information of event
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <SoftBox
                      // width={{ xs: "calc(330px - 40px)", md: "calc(382px - 40px)" }}
                      bgColor="white"
                      color="text"
                      borderRadius="10px"
                      mt="20px"
                      py="14px"
                      px="14px"
                      lineHeight="12px"
                    >
                      {card.template}
                    </SoftBox>
                  </Box>
                ))}
              </Grid>
            ))}
          </Grid>
        ))}
    </DashboardLayout>
  );
}
export default KanbanEvents;
