import InvoiceDetails from "pages/billings-display/InvoiceDetails";
import NewQuotations from "pages/billings-new/NewQuotations";
import NewBill from "pages/billings-new/NewBill";
import UpdateUser from "pages/settings/UpdateUser";
import AddEvent from "pages/events/AddEvent";
import UpdateEventByStudent from "pages/demandes/events/UpdateEventByStudent";
import EventView from "pages/demandes/events/EventView";
import UpdateEvent from "pages/demandes/events/UpdateEvent";
import AjouterEvent from "pages/demandes/events/AjouterEvent";
import UpdateEntreprisePotentiel from "pages/commercial/EntreprisesPotentiel/UpdateEntreprisePotentiel";
import AjouterEntreprisePotentiel from "pages/commercial/EntreprisesPotentiel/AjouterEntreprisePotentiel";
import UpdateTache from "pages/commercial/ListTaches/UpdateTache";
import AjouterTache from "pages/commercial/ListTaches/AjouterTache";
import AjouterTâche from "pages/suiviTâche/AjouterTâche";
import UpdateTâche from "pages/suiviTâche/UpdateTâche";
import ListeTâches from "pages/suiviTâche/ListeTâches";
import KanbanTâches from "pages/suiviTâche/kanban";
import DesktopLogin from "pages/authentication/DesktopLogin";

const routes = [
  {
    name: "ShowFactures",
    key: "show-factures",
    route: "/billing/factures/:invoiceId",
    component: <InvoiceDetails type="facture" />,
  },
  {
    name: "ShowQuotations",
    key: "show-quotations",
    route: "/billing/quotations/:invoiceId",
    component: <InvoiceDetails type="quotations" />,
  },
  {
    name: "ShowFactures",
    key: "show-factures",
    route: "/billing/inscriptions/:invoiceId",
    component: <InvoiceDetails type="inscription" />,
  },
  {
    name: "ShowFactures",
    key: "show-factures",
    route: "/billing/preinscriptions/:invoiceId",
    component: <InvoiceDetails type="inscription" />,
  },
  {
    name: "NewQuotations",
    key: "new-quotations",
    route: "/billing/new-quotations",
    component: <NewQuotations />
  },
  {
    name: "NewBill",
    key: "new-bill",
    route: "/billing/new-bill",
    component: <NewBill type="facture" />
  },
  {
    name: "NewInscription",
    key: "new-insc",
    route: "/billing/new-insc",
    component: <NewBill type="insc" />
  },
  {
    name: "NewInscription",
    key: "new-insc",
    route: "/billing/new-pre-insc",
    component: <NewBill type="pre-insc" />
  },
  {
    name:"Settings",
    key:"settings",
    route:"/settings/:id",
    component:<UpdateUser />
  },
  {
    name:"AddEvent",
    key:"AddEvent",
    route:"/ajouter-evenement",
    component:<AddEvent />
  },
  {
    name: "Ajouter Tache",
    key: "ajouterTache",
    route: "/commercials/listTaches/ajouterTache",
    component: <AjouterTache />,
  },
  {
    name: "Ajouter Tache",
    key: "ajouterTache",
    route: "/commercials/kanban/ajouterTache",
    component: <AjouterTache />,
  },
  {
    name: "Editer Tache",
    key: "editerTache",
    route: "/commercials/listTaches/editerTache/:id",
    component: <UpdateTache />,
  },
  {
    name: "Ajouter Entreprise Potentiel",
    key: "ajouterEntreprisePotentiel",
    route: "/commercials/entreprisesPotentiel/ajouterEntreprisePotentiel",
    component: <AjouterEntreprisePotentiel />,
  },
  {
    name: "Editer Entreprise Potentiel",
    key: "editerEntreprisePotentiel",
    route: "/commercials/entreprisesPotentiel/editerEntreprisePotentiel/:id",
    component: <UpdateEntreprisePotentiel />,
  },
  {
    name: "Ajouter Event",
    key: "ajouterEvent",
    route: "/events/listEvents/ajouterEvent",
    component: <AjouterEvent />,
  },
  {
    name: "Ajouter Event",
    key: "ajouterEvent",
    route: "/events/kanbanEvents/ajouterEvent",
    component: <AjouterEvent />,
  },
  {
    name: "Ajouter Event",
    key: "ajouterEvent",
    route: "/events/kanbanEvents/ajouterEvent/:id",
    component: <AjouterEvent />,
  },
  {
    name: "Update Event",
    key: "updateEvent",
    route: "/events/listEvents/updateEvent/:id",
    component: <UpdateEvent />,
  },
  {
    name: "Update Event",
    key: "updateEvent",
    route: "/events/kanbanEvents/updateEvent/:id",
    component: <UpdateEvent />,
  },
  {
    name: "Update Event By Student",
    key: "updateEventByStudent",
    route: "/events/kanbanEvents/updateEventByStudent/:id",
    component: <UpdateEventByStudent />,
  },
  {
    name: "Voir Event By Student",
    key: "voirEvent",
    route: "/events/listEvents/voirEvent/:id",
    component: <EventView />,
  },
  {
    name: "Voir Event By Student",
    key: "voirEvent",
    route: "/events/kanbanEvents/voirEvent/:id",
    component: <EventView />,
  },
  {
    name: "Ajouter Tâche",
    key: "ajouterTache",
    route: "/suiviTache/listeTaches/ajouterTache/:projectID",
    component: <AjouterTâche />,
  },
  {
    name:"Desktop Login",
    key:"desktop-login",
    route:"/desktop-login",
    component:<DesktopLogin />
  },
  {
    name: "Ajouter Tâche",
    key: "ajouterTache",
    route: "/suiviTache/kanbanTaches/ajouterTache/:projectID",
    component: <AjouterTâche />,
  },
  {
    name: "Editer Tâche",
    key: "editerTache",
    route: "/suiviTache/listeTaches/editerTache/:id",
    component: <UpdateTâche />,
  },
 
      {
        name: "Liste Tâches",
        key: "listeTaches",
        route: "/suiviTache/listeTaches/:projectID",
        component: <ListeTâches />,
      },
      {
        name: "Kanban Tâches",
        key: "kanbanTaches",
        route: "/suiviTache/kanbanTaches/:projectID",
        component: <KanbanTâches />,
      }
   
 
];



export default routes;
