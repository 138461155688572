import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useEffect, useState } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import DashboardNavbar from "layouts/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Card from "./components/Card";
import api from "utils/axios";


import moment from "moment";

function Kanban() {
  const navigate = useNavigate();
  const [taches, setTaches] = useState([]);

  const listeTaches = () => {
    api.get("tasks/get-tasks").then((response) => {
      setTaches(response.data);
    });
  };

  useEffect(() => {
    listeTaches();
  }, []);

  const [responsables, setResponsables] = useState([]);

  const getAvatarResponsables = () => {
    api.get("get-user-commercial?role=commercial").then((response) => {
      setResponsables(response.data);
    });
  };

  useEffect(() => {
    getAvatarResponsables();
  }, []);

  // Map taches to cards outside status object
  const cards = (stat) => {
    const threeMonthsAgo = moment().subtract(3, 'months').toDate();
    const cardsUtiliser = taches
      .filter((tache) => {
        if (tache.statut !== "Terminer") {
          return tache.statut === stat;
        } else {
          const dateLimite = moment(tache.dateLimite, "DD/MM/YYYY HH:mm:ss").toDate();
          const isWithin3Months = moment(dateLimite).isAfter(threeMonthsAgo);
          if(isWithin3Months){
            return tache.statut === stat;
          }
          return false;
        }
      })
      .map((tache) => ({
        id: tache._id,
        template: (
          <Card
            évaluation={{
              color:
                tache.evaluation === "Aucune"
                  ? "secondary"
                  : tache.evaluation === "Négative"
                  ? "error"
                  : "success",
              label: tache.evaluation,
            }}
            content={tache.nom}
            date={tache.dateLimite}
            priorité={{
              color:
                tache.priorite === "Haute"
                  ? "error"
                  : tache.priorite === "Moyenne"
                  ? "warning"
                  : "#30F2F2",
              label: tache.priorite,
            }}
            responsable={
              responsables
                .find((res) => res.email === tache.responsable)?.imageUrl.startsWith("https")
                ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                : `${process.env.REACT_APP_BASE_URL}/${
                    responsables.find((res) => res.email === tache.responsable)?.imageUrl
                  }`
            }
          />
        ),
      }));
    return cardsUtiliser;
  };

  const status = {
    columns: [
      {
        id: "0",
        title: "A faire",
        cards: cards("A faire"),
      },
      {
        id: "1",
        title: "En cours",
        cards: cards("En cours"),
      },
      {
        id: "2",
        title: "Terminer",
        cards: cards("Terminer"),
      },
    ],
  };

  const [columns, setColumns] = useState(status.columns);

  const token = localStorage.getItem("accessToken")
  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Do nothing if the item is dropped outside the list
    if (!destination) return;

    // Do nothing if the item is dropped in the same place
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // Determine the new status based on the destination.droppableId
    let newStatus = "";
    switch (destination.droppableId) {
      case "0":
        newStatus = "A faire";
        break;
      case "1":
        newStatus = "En cours";
        break;
      case "2":
        newStatus = "Terminer";
        break;
    }

    // Find the dragged task using its ID
    const draggedTask = taches.find((task) => task._id === result.draggableId);

    if (!draggedTask) {
      return;
    }

    // Update the task status locally
    const updatedTaches = taches.map((task) =>
      task._id === draggedTask._id ? { ...task, statut: newStatus } : task
    );
    setTaches(updatedTaches);

    // Update the task status in the backend
    updateTaskStatus(draggedTask._id, newStatus);
  };

  const updateTaskStatus = (taskId, newStatus) => {
    const updatedTask = { statut: newStatus };
      api.put(`tasks/update-task/${taskId}`, updatedTask, {
        headers: {
          'Authorization': `Bearer ${token}`
        },        
      });
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box >
        
      <SoftBox my={2}>
        
        
        <Box display="flex" alignItems="center" justifyContent="space-between" mx={2}>
          <Typography variant="h5" sx={{ flexGrow: "1" }}>
            Kanban des Tâches
          </Typography>
          <SoftButton
            variant="gradient"
            color="purple"
            size="medium"
            onClick={() => navigate("/commercials/kanban/ajouterTache")}
          >
            Ajouter une tâche
          </SoftButton>
        </Box>

        <DragDropContext
          onDragEnd={(result) => {
            onDragEnd(result, status.columns, setColumns);
          }}
        >
          <Grid container my={4}>
            {status.columns.map((column) => (
              <Grid
                item
                key={column.id}
                sx={{
                  backgroundColor: "#EDF0F3",
                  flex: "1 1", // flexGrow, flexShrink
                  maxWidth : {md :"calc(50% - 20px)"},
                  minWidth:"330px",
                  margin: "10px",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <SoftTypography variant="h6">{column.title}</SoftTypography>
                <Droppable droppableId={column.id}>
                  {(provided) => (
                    <Box ref={provided.innerRef} {...provided.droppableProps}>
                      {column.cards.map((card, index) => (
                        <Draggable key={card.id} draggableId={card.id} index={index}>
                          {(provided) => (
                            <>
                              <Box
                                onClick={() => {
                                  navigate(`/commercials/listTaches/editerTache/${card.id}`);
                                }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <SoftBox
                                  key={card.id}
                                  // width={{ xs: "calc(330px - 40px)", md: "calc(382px - 40px)" }}
                                  // flex={1}
                                  bgColor="white"
                                  color="text"
                                  borderRadius="10px"
                                  mt="20px"
                                  py="14px"
                                  px="14px"
                                  lineHeight="12px"
                                >
                                  {card.template}
                                </SoftBox>
                              </Box>
                            </>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Grid>
            ))}
        
            </Grid>
          </DragDropContext>
        </SoftBox>
      </Box>
    </DashboardLayout>
  );
};

export default Kanban;
