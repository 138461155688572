const form = {
  formId: "client",
  formField: {
    firstName: {
      name: "firstName",
      label: "Nom* ",
      type: "text",
      placeholder: "Nom",
      errorMsg: "Nom est obligatoire.",
    },
    lastName: {
      name: "lastName",
      label: "Prénom*",
      type: "text",
      placeholder: "Prénon",
      errorMsg: "Prénom est obligatoire.",
    },
    birthday: {
      name: "birthday",
      label: "Birthday",
      type: "text",
      placeholder: "22.01.2023",
      errorMsg: "Date de naissance est obligatoire .",
    },
    email: {
      name: "email",
      label: "Email*",
      type: "email",
      placeholder: "Email",
      errorMsg: "Email est obligatoire .",
    },
    address: {
      name: "address",
      label: "addresse",
      type: "text",
      placeholder: "Adresse",
      errorMsg: "Adresse est obligatoire.",
    },
    zipCode: {
      name: "zipCode",
      label: "code Postal",
      type: "number",
      placeholder: "Code postal",
      errorMsg: "Code Postale est obligatoire.",
    },

    siteWeb: {
      name: "siteWeb",
      label: "site Web",
      type: "text",
      placeholder: "Site web",
    },
    passeport: {
      name: "passeport",
      label: "passeport",
      type: "text",
      placeholder: "Passeport",
      errorMsg: "Passeport est obligatoire.",
    },
    cin: {
      name: "cin",
      label: "cin",
      type: "number",
      placeholder: "Cin",
      errorMsg: "cin  est obligatoire.",
    },
    rib: {
      name: "rib",
      label: "rib",
      type: "number",
      placeholder: "rib",
      errorMsg: "rib est obligatoire.",
    },
    bank: {
      name: "bank",
      label: "nom de banque",
      type: "text",
      placeholder: "nom de Banque",
      errorMsg: "nom banque est obligatoire.",
    },
    description: {
      name: "description",
      label: "description",
      type: "text",
      placeholder: "description",
      errorMsg: "desription est obligatoire.",
    },
    phone: {
      name: "phone",
      label: "Phone",
      type: "text",
      placeholder: "Phone",
      errorMsg: "Téléphone est obligatoire.",
    },
    gender: {
      name: "gender",
      label: "gender",
      type: "text",
      placeholder: "genre",
      errorMsg: "genre est obligatoire.",
    },
    cnssNumber: {
      name: "cnssNumber",
      label: "Numéro CNSS",
      type: "number",
      placeholder: "Numéro CNSS",
      errorMsg: "Num cnss est obligatoire.",
    },
    occupation: {
      name: "occupation",
      label: "occupation",
      type: "text",
      placeholder: "occupation",
      errorMsg: "Occupation est obligatoire.",
    },
    coporationName: {
      name: "coporationName",
      label: "Nom de société*",
      type: "text",
      placeholder: "Nom de société",
      errorMsg: "Nom de société est obligatoire.",
    },
    country: {
      name: "country",
      label: "country",
      type: "text",
      placeholder: "country",
      errorMsg: "Pays est obligatoire.",
    },
    city: {
      name: "city",
      label: "Ville",
      type: "text",
      placeholder: "Ville de société",
      errorMsg: "Ville est obligatoire.",
    },
    coporationSerialNumber: {
      name: "coporationSerialNumber",
      label: "Matricule Fiscal*",
      type: "text",
      placeholder: "Matricule fiscal de société",
      errorMsg: "Matricule fiscal de société est obligatoire.",
    },
  },
};

export default form;
