import { Card, Grid } from '@mui/material'
import SoftBox from 'components/SoftBox'
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SoftButton from 'components/SoftButton'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import { Form, Formik } from 'formik'
import DashboardNavbar from 'layouts/DashboardNavbar'
import Header from 'layouts/pages/profile/components/Header'
import React, { useState } from 'react'
import ConfigurationEmploi from './components/ConfigurationEmploi'
import InfoEmploi from './components/InfoEmploi'
import form from '../shared/schemas/form';
import validations from '../shared/schemas/validations';
import initialValues from '../shared/schemas/initialValues';
import Swal from 'sweetalert2';
import api from 'utils/axios';
import { useNavigate } from 'react-router-dom';

function getSteps() {
  return ["Configuration", "Emploi"];
}
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <ConfigurationEmploi formData={formData} />;
    case 1:
      return <InfoEmploi formData={formData} />;
    default:
      return null;
  }
}
export default function AddEmploi() {
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(null);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const navigate = useNavigate()
  const handleBack = () => setActiveStep(activeStep - 1);
  //add-Emploi
  const submitForm = async (values, actions) => {
    Swal.fire("Succès !", "L'emploi a été ajouté avec succès", "success").then(() => {
      actions.setSubmitting(false);
      actions.resetForm();
      navigate("/planning/emplois")
    });
    setActiveStep(1);
  };
  //add-Emploi-Config
  const submitFormConfig = async (values, actions) => {
    const filteredJours = values?.jours?.filter(jour => jour.checked === true)
    const configEmploi = {
      startDate: values?.dateDebut,
      endDate: values?.dateFin,
      startTime: values?.heureDebut,
      endTime: values?.heureFin,
      days: filteredJours?.map(jour => jour.label)
    }
    try {
      setIsLoading(true);      
      const data = await api.post(`/add-planing/${values?.instructorId}`, configEmploi);
      if (data?.status === 201) {
        values.planningId = data?.data?._id
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        Swal.fire("Erreur!", error.response.data.message, "error")
      } else {
        Swal.fire("Erreur!", "Une erreur s'est produite. Veuillez réessayer ultérieurement.", "error")
      }
    }
    setActiveStep(1);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      submitFormConfig(values, actions)
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title={"Ajouter un emploi"} />
      <SoftBox mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form
                  id={formId}
                  autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <SoftBox />
                          ) : (
                            <SoftButton id="btn-back-client" disabled variant="outlined" color="dark" onClick={handleBack}>
                              Annuler
                            </SoftButton>
                          )}
                        
                          <SoftButton
                            type="submit"
                            variant="gradient"
                            color="customized"
                            id="send"
                          >
                            {isLastStep ? "Valider" : "Suivant"}
                          </SoftButton>
                        </SoftBox>

                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  )
}
