import * as Yup from "yup";
import checkout from "pages/resource-management/subject/schema/form";

const {
  formField: {
    code,
    name,
    filiere,
    niveau,
    parcour,
    module,
    coefficient,
    credit,
    categorie,
    vhcours,
    durationcours,
    vhtd,
    durationtd,
    vhtp,
    durationtp,
    vhmp,
    durationmp,
    pourcentage,
    bloccours,
    sallecours,
    bloctd,
    salletd,
    bloctp,
    salletp,
    blocmp,
    sallemp,
    pcexamen,
    pctd,
    pctp,
    pcmp,
    pcds1,
    pcds2,
    pccc,
    instructorCours,
    instructorTd,
    instructorTp,
    instructorMp,
  },
} = checkout;
const validations = [
  //---------------------subject
  Yup.object().shape({
    [code.name]: Yup.string()
      .required(code.errorMsg)
      .min(2, "La longueur minimale requise est de 2 caractères.")
      .max(30, "La longueur maximale autorisée est de 30 caractères."),
    [name.name]: Yup.string()
      .required(name.errorMsg)
      .min(2, "La longueur minimale requise est de 2 caractères.")
      .max(30, "La longueur maximale autorisée est de 30 caractères."),
    [filiere.name]: Yup.string().required(filiere.errorMsg),
    [niveau.name]: Yup.string().required(niveau.errorMsg),
    [parcour.name]: Yup.string().required(parcour.errorMsg),
    [module.name]: Yup.string().required(module.errorMsg),
    [coefficient.name]: Yup.number()
      .required(coefficient.errorMsg)
      .typeError("La valeur doit être un nombre")
      .integer("La valeur doit être un nombre entier")
      .min(1, "La valeur doit être supérieure ou égale à 1"),
    [credit.name]: Yup.number()
      .required(credit.errorMsg)
      .typeError("La valeur doit être un nombre")
      .integer("La valeur doit être un nombre entier")
      .min(1, "La valeur doit être supérieure ou égale à 0"),
  }),
  //-------------------types
  Yup.object().shape({
    [vhcours.name]: Yup.number()
      .required(vhcours.errorMsg)
      .typeError("La valeur doit être un nombre")
      .integer("La valeur doit être un nombre entier")
      .min(1, "La valeur doit être supérieure ou égale à 1"),
    [durationcours.name]: Yup.number()
      .required(durationcours.errorMsg)
      .typeError("La valeur doit être un nombre")
      .integer("La valeur doit être un nombre entier")
      .min(1, "La valeur doit être supérieure ou égale à 1"),
    [vhtd.name]: Yup.number()
      .typeError("La valeur doit être un nombre")
      .integer("La valeur doit être un nombre entier")
      .min(1, "La valeur doit être supérieure ou égale à 1"),
    [durationtd.name]: Yup.number()
      .typeError("La valeur doit être un nombre")
      .integer("La valeur doit être un nombre entier")
      .min(1, "La valeur doit être supérieure ou égale à 1"),
    [vhtp.name]: Yup.number()
      .typeError("La valeur doit être un nombre")
      .integer("La valeur doit être un nombre entier")
      .min(1, "La valeur doit être supérieure ou égale à 1"),
    [durationtp.name]: Yup.number()
      .typeError("La valeur doit être un nombre")
      .integer("La valeur doit être un nombre entier")
      .min(1, "La valeur doit être supérieure ou égale à 1"),
    [vhmp.name]: Yup.number()
      .typeError("La valeur doit être un nombre")
      .integer("La valeur doit être un nombre entier")
      .min(1, "La valeur doit être supérieure ou égale à 1"),
    [durationmp.name]: Yup.number()
      .typeError("La valeur doit être un nombre")
      .integer("La valeur doit être un nombre entier")
      .min(1, "La valeur doit être supérieure ou égale à 1"),
  }),
  //----------------------salle
  Yup.object().shape({
    [bloccours.name]: Yup.string().min(5, "Le bloc cours est obligatoire."),
    [sallecours.name]: Yup.array().typeError("La salle cours est obligatoire."),
    [bloctd.name]: Yup.string().min(5, "Le bloc Td est obligatoire."),
    [salletd.name]: Yup.array().typeError("La salle Td est obligatoire."),
    [bloctp.name]: Yup.string().min(5, "Le bloc Tp est obligatoire."),
    [salletp.name]: Yup.array().typeError("La salle Tp est obligatoire."),
    [blocmp.name]: Yup.string().min(5, "Le bloc Mp est obligatoire."),
    [sallemp.name]: Yup.array().typeError("La salle Mp est obligatoire."),
  }),
  Yup.object().shape({
    [pourcentage.name]: Yup.number()
      .min(100, "La somme des pourcentages doit être  égale à 100%")
      .max(100, "La somme des pourcentages doit être égale à 100%"),
  }),
  Yup.object().shape({
    [instructorCours.name]: Yup.array().of(Yup.string()),
    [instructorTd.name]: Yup.array().of(Yup.string()),
    [instructorTp.name]: Yup.array().of(Yup.string()),
    [instructorMp.name]: Yup.array().of(Yup.string()),
  }),
];

export default validations;
