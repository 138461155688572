import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  IconButton,
  Stack, 
  Typography,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CloseIcon from "@mui/icons-material/Close";
import api from "utils/axios";
import Swal from "sweetalert2";
import SoftBox from "components/SoftBox";

export default function AddFormation({ token }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorType, setErrorType] = useState(null);
  const [inputs, setInputs] = useState([{ name: "", nbrNiveaux: "",type:"" }]);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [nbrNiveauxError, setNbrNiveauxError] = useState("");
  
  const [optionsTypes, setOptionsTypes] = useState([
    { value: "semestre", label: "Semestre" },
    { value: "trimestre", label: "Trimestre" },
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setNbrNiveauxError("");
    setErrorType(""),
    setInputs([{ name: "", nbrNiveaux: "",type:"" }]);
  };
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  };
  const handleAddInput = () => {
    setInputs([...inputs, { name: "", nbrNiveaux: "",type:"" }]);
  };
  const handleRemoveInput = (indexToRemove) => {
    const newInputs = [...inputs];
    if (indexToRemove >= 0 && indexToRemove < newInputs.length) {
      // Remove the input at the specified index
      newInputs.splice(indexToRemove, 1);
      setInputs(newInputs);
    }
  };
  const handleAddSpeciality = async () => {
    try {
      setLoading(true);
      // Validate the inputs before submitting
      const hasInvalidInputs = inputs.some((input) => input.name.length < 2)
      const hasInvalnbrNiveaux = inputs.some((input) => input.nbrNiveaux < 1 || input.nbrNiveaux > 10 || !Number.isInteger(Number(input.nbrNiveaux)));
      const hasInvalType = inputs.some((input) => !input.type)
      if (hasInvalidInputs) {
        // Set the error message
        setErrorMessage("La longueur minimale requise est de 2 caractères");
      }
      else if (hasInvalnbrNiveaux) {
        // Set the error message
        setNbrNiveauxError("Le nombre de niveaux doit être entre 1 et 10 et n'accepte pas les virgules.");
      }  else if (hasInvalType) {
        // Set the error message
        setErrorType("Un type doit être sélectionnée.");
      }
      else {
        const duplicateName = inputs.find(
          (input, index) =>
            index !== inputs.findIndex((otherInput) => otherInput.name.toLowerCase() === input.name.toLowerCase())
        );
        if (duplicateName) {
          // Set the error message for duplicate name
          Swal.fire(
            "Erreur!",
            `Le nom du formation existe déjà : ${duplicateName.name}. Merci de saisir un autre nom !`,
            "error"
          );
        } else {
          await api.post(`/faculty/create-formation?scholarityConfigId=${facility.scholarityConfigId}`, inputs);
          setOpen(true);      
          setLoading(false);
          Swal.fire("Success!", "La formation ajoutée avec succès", "success").then(() => {
            setErrorMessage("");
            setNbrNiveauxError(""),
            setErrorType(""),
              setInputs([{ name: "", nbrNiveaux: "",type:"" }]);
            token();
            setOpen(false);
          });
        }
      }
    } catch (error) {
      setError(error.response.data.message);
      Swal.fire("Erreur!", error.response.data.message, "error")
    } finally {
      setLoading(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddSpeciality();
    }
  };
  return (
    <div>
      <SoftButton
        id="add" onClick={handleClickOpen} variant="gradient"
        color="purple"
        size="small"
        mb={{ xs: 2, sm: 0 }}
      >
        +Ajouter formation
      </SoftButton>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose} onKeyDown={handleKeyPress}>
        <DialogTitle >Ajouter formation</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            Vous devez saisir les informations de formation afin de l&apos;ajouter.
          </DialogContentText>
          {inputs.map((input, index) => (
            <Grid key={index} sx={{ mt: 1 }}>
              {facility?.type === "faculty" ? (
                <Grid container spacing={1} sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "flex-start",
                }}>
                  <Grid item xs={12} sm={inputs.length > 1 ? 3.75 : 4}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Nom de formation*
                    </SoftTypography>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                      }}
                    >
                      <SoftInput
                        name="name"
                        margin="normal"
                        id="name"
                        label="foramtion"
                        type="text"
                        fullWidth
                        placeholder="Nom de formation"
                        inputProps={{
                          minLength: 2,
                          maxLength: 50,
                        }}
                        autoFocus
                        variant="standard"
                        value={input.name}
                        onChange={(event) => handleInputChange(index, event)}
                        onBlur={() => {
                          // Update the error when the input loses focus
                          const newInputs = [...inputs];
                          newInputs[index].error = input.name.length < 2;
                          setInputs(newInputs);
                        }}
                        error={input.error && input.name.length < 2}
                        success={input.name.length >= 2}
                      />
                      {input.name.length < 2 && (
                        <Typography mt={0.75} variant="caption" color="error">
                          {errorMessage}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={inputs.length > 1 ? 3.75 : 4}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Nombre de niveau*
                    </SoftTypography>
                    <SoftInput
                      id="nbrNiveaux"
                      name="nbrNiveaux"
                      type="number"
                      margin="normal"
                      variant="standard"
                      fullWidth
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      placeholder=" Nombre de niveau"    
                      value={input.nbrNiveaux}
                      error={input.error && (input.nbrNiveaux < 1 || input.nbrNiveaux > 10 || !Number.isInteger(Number(input.nbrNiveaux)))}      
                      success={input.nbrNiveaux >= 1 && input.nbrNiveaux <= 10 && Number.isInteger(Number(input.nbrNiveaux))}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                    {(input.nbrNiveaux ==="" || input.nbrNiveaux < 1 || input.nbrNiveaux > 10 || !Number.isInteger(Number(input.nbrNiveaux))) && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {nbrNiveauxError}
                      </Typography>
                    )}

                  </Grid>
                  <Grid item xs={12} sm={inputs.length > 1 ? 3.75 : 4}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Type*
                  </SoftTypography>
                  <Autocomplete
                    id={`size-small-standard-${index}`}
                    options={optionsTypes}
                    title="Sélectionner uu type"
                    aria-required={true}
                    required={true}
                    getOptionLabel={(option) => option.label}
                    defaultValue={null}
                    clearIcon={null}
                    placeholder="Veuillez
                    sélectionner le
                    type "
                    value={optionsTypes.find((option) => option.value === input.type) || null}
                    onChange={(event, newValue) => {
                      if (newValue !== null && newValue !== undefined) {
                        const newInputs = [...inputs];
                        newInputs[index].type = newValue.value;
                        setInputs(newInputs);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required={true}
                        //value={input.facilityType}
                        placeholder="Veuillez
                        sélectionner le type"
                      />
                    )}
                  />
                  {input.type === "" && (
                    <Typography mt={0.75} variant="caption" color="error">
                      {errorType}
                    </Typography>
                  )}
                </Grid>
                  <Grid item xs={12} sm={0.5} mt={3.5}>
                    {inputs.length > 1 && (
                      <IconButton>
                        <RemoveCircleIcon
                          color="error"
                          fontSize="small"
                          onClick={() => handleRemoveInput(index)}
                        />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>) : (
                <Grid key={index} sx={{ mt: 1 }}>
                </Grid>)}
            </Grid>
          ))}
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <SoftButton
              size="small"
              sx={{

                mt: 2,

                borderWidth: "2px",
                borderStyle: "dashed solid",
                borderColor: "purple ",
              }}
              onClick={handleAddInput}
            >
              +ajouter plus
            </SoftButton>
          </Stack>
        </DialogContent>
        <DialogActions>
          <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
            <SoftButton
              onClick={handleClose}
              sx={{ mt: 2 }}
              variant="outlined"
              color="dark"
              size="small"
            >
              Annuler
            </SoftButton>
            {loading ? (
              <CircularProgress />
            ) : (
              <SoftButton
                onClick={handleAddSpeciality}
                sx={{ mt: 2 }}
                variant="gradient"
                color="purple"
                size="small"
              >
                Ajouter
              </SoftButton>
            )}
          </SoftBox>
        </DialogActions>
      </Dialog>
    </div>
  );
}
AddFormation.propTypes = {
  token: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
