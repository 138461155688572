import React, { useEffect, useState } from 'react';
import api from 'utils/axios';
import { Grid, CircularProgress } from '@mui/material';
import ComplexProjectCard from "components/shared-users/Cards";
import SoftBox from 'components/SoftBox';
import PlaceholderCard from 'examples/Cards/PlaceholderCard';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'layouts/DashboardNavbar';
import Header from 'layouts/pages/profile/components/Header';
import breakpoints from "assets/theme/base/breakpoints";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate } from 'react-router-dom';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import Swal from "sweetalert2";
import SoftPagination from "components/SoftPagination"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SoftSnackbar from 'components/SoftSnackbar';
import { useSoftUIController } from 'context';

export default function ListCardsCollaborateur() {
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("admin");
  const [errorSB, setErrorSB] = useState(false);
  const navigate = useNavigate();
  const facility = JSON.parse(localStorage.getItem("facility"));
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [controller] = useSoftUIController();
  const userLogin = controller?.user?.info;


  // TeamProfileCard dropdown menu handlers
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const [selectedUserId, setselectedUserId] = useState(null);
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler"
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(`/facilities/${facility._id}/users/${selectedUserId}`);
            if (response) {
              (await Swal.fire("Supprimé !", "le compte a été supprimé.", "success"))
                .isConfirmed && handleFetch("admin");
              closeSlackBotMenu()
            }
          } catch (error) {
            Swal.fire("Une erreur s'est produite !", "le compte n'a pas été supprimé.", "error");
            closeSlackBotMenu()
          }
        }
      });
  };
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Fonction pour passer à la page précédente
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={() => navigate(`/administration/collaborator/view/${selectedUserId}`)}> <RemoveRedEyeIcon />   &nbsp; Voir plus</MenuItem>
      <MenuItem onClick={() => navigate(`/administration/collaborator/${selectedUserId}`)}> <EditIcon />  &nbsp; Modifier</MenuItem>
      <MenuItem onClick={showAlert}> <DeleteIcon />&nbsp;   Supprimer</MenuItem>
    </Menu>
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const handleFetch = async (role) => {
    try {
      setLoading(true);
      const response = await api.get(`/get-all-users-by-role/${facility._id}?role=${role}`);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === "admin") {
      handleFetch("admin");
    } else if (newValue === "editeur") {
      handleFetch("editor");
    }
  };

  useEffect(() => {
    handleFetch("admin");
  }, []);

  useEffect(() => {
    document.title = "commercial";
  }, []);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  const renderErrorSB = (
    <SoftSnackbar
      color="error"
      icon="warning"
      title="Ajouter un responsable"
      content="Vous avez atteint le nombre maximum d'utilisateurs"
      dateTime="11 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );
  const licence = localStorage.getItem("licence");
  if (!licence) return;
  const parsedLicence = JSON.parse(licence);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title={"Liste des collaborateurs"} searchVisible={true} ExportVisible={true} />

      <Grid>
        <SoftBox mt={2}>
          <Grid container>
            <Grid item xs={12} sm={8} lg={2}>
              <AppBar position="static">
                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                  <Tab value="admin" label="Admin" />
                  <Tab value="editeur" label="Editeur" />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
        </SoftBox>
      </Grid>
      <SoftBox pb={2}>
        <SoftBox mt={{ xs: 1, lg: 2 }} mb={1}>
          <Grid container spacing={3} justifyContent="flex-start" alignItems="stretch">
            {/* PlaceholderCard */}
            {((userLogin?.role === "admin" && tabValue !== "admin")|| (userLogin?.role === "editor" && tabValue !== "editeur" && tabValue !== "admin")|| (userLogin?.role === "company-admin"))  && (
              <Grid
                onClick={() => parsedLicence?.remainingUser === 0 ? openErrorSB() : navigate(`${tabValue === "admin" ? "/administration/collaborator/add-collaborator-admin" : "/administration/collaborator/add-collaborator-editor"}`)}
                item
                xs={12}
                md={6}
                lg={4}
                sx={{ cursor: "pointer" }}
              >
                <PlaceholderCard
                  title={{ variant: "h5", text: `${tabValue === "editeur" ? "Ajouter editeur" : "Ajouter  Admin"}` }}
                />
              </Grid>
            )}
            {loading ? (
              <SoftBox
                sx={{
                  mt: 13,
                  textAlign: "center",
                }}
              >
                <CircularProgress />
              </SoftBox>
            ) : (<>
              {/* ComplexProjectCard */}
              {currentData &&
                currentData?.map((user) => (
                  <Grid key={user?._id} item xs={12} md={6} lg={4}>
                    {tabValue === "editeur" ? (
                      <ComplexProjectCard
                        image={user?.imageUrl?.startsWith("https")
                          ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                          : `${process.env.REACT_APP_BASE_URL}/${user?.imageUrl}`}
                        title={`${user.firstName} ${user.lastName}`}
                        phone={user.phoneNumber}
                        email={user.email}
                        description=""
                        occupation={user.occupation}
                        userId={user?._id}
                        etat={user?.etat}
                        disabled ={userLogin?.role === "editor" ? true : false}
                        dropdown={{
                          action: (e) => {
                            // openSlackBotMenu;
                            setSlackBotMenu(e.currentTarget);
                            setselectedUserId(user._id);
                          },
                          menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                        }}
                      />
                    ) : (
                      <ComplexProjectCard
                        image={user?.imageUrl?.startsWith("https")
                          ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                          : `${process.env.REACT_APP_BASE_URL}/${user?.imageUrl}`}
                        title={`${user.firstName} ${user.lastName}`}
                        phone={user.phoneNumber}
                        email={user.email}
                        description=""
                        occupation={user.occupation}
                        userId={user?._id}
                        etat={user?.etat}
                        disabled ={userLogin?.role === "admin" ? true : false}
                        dropdown={{
                          action: (e) => {
                            // openSlackBotMenu;
                            setSlackBotMenu(e.currentTarget);
                            setselectedUserId(user._id);
                          },
                          menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                        }}
                      />
                    )}
                  </Grid>
                ))}</>)}
          </Grid>

        </SoftBox>

      </SoftBox>
      {totalPages > 1 && (<SoftPagination>
        {currentPage > 1 && (
          <SoftPagination item onClick={prevPage}><ArrowBackIosIcon /></SoftPagination>
        )}
        {Array.from({ length: totalPages }, (_, index) => (
          <SoftPagination item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </SoftPagination >
        ))}
        {currentPage < totalPages && (
          <SoftPagination item onClick={nextPage}><ArrowForwardIosIcon /> </SoftPagination>
        )}
      </SoftPagination>
      )}
      {renderErrorSB}
    </DashboardLayout>
  );
}
