import { Box, Card, CardContent, CircularProgress, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import React, { useEffect, useState } from "react";
import SoftButton from "components/SoftButton";
import { useNavigate, useParams } from "react-router-dom";
import api from "utils/axios";


import ProfileInfoCard from "components/shared-users/ProfileInfoCard";
import FileDownloadIcon from "@mui/icons-material/FileDownload";



import { downloadFile } from "../../utils/citysTn";
import SoftAvatar from "components/SoftAvatar";
import Header from "layouts/pages/profile/components/Header";

export default function CommercialView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [commercial, setCommercial] = useState({});
  const [loading, setLoading] = useState(false);

  const [imgsViewer, setImgsViewer] = useState(false);


  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const handleFetchCommercialById = async (id) => {
    try {
      setLoading(true);
      if (id) {
        const response = await api.get(`/get-user/${id}`);
        const fetchCommercial = response.data;
        setLoading(false);
        setCommercial(fetchCommercial);
      }
    } catch (error) {
      setLoading(true);
      throw error
    }
  };
  useEffect(() => {
    if (id !== undefined) {
      handleFetchCommercialById(id);
    }
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Header title="Informations de profil"  />
      {loading ? (
        <SoftBox
          sx={{
            mt: 25,
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </SoftBox>
      ) : (
        <>
          <Card sx={{ height: "100%" }}>
            <SoftBox p={1.5} mt={2} ml={1.5}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <SoftAvatar
                    src={
                      `${process.env.REACT_APP_BASE_URL}/${commercial?.imageUrl}`
                    }
                    variant="rounded"
                    size="xl"
                    shadow="sm"
                  />
                </Grid>
                <Grid item>
                  <SoftBox height="100%" mt={0.5} lineHeight={1}>
                    <SoftTypography variant="h5" fontWeight="medium">
                      {commercial?.firstName} {commercial?.lastName}
                    </SoftTypography>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      {commercial?.role}
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} xl={6}>
                  <ProfileInfoCard
                    info={{
                      email: commercial?.email,
                      "Date de Naissance": commercial?.birthday,
                      genre: commercial?.gender,
                      cin: commercial?.cin,
                      "Numéro Téléphone": commercial?.phoneNumber
                        ? `+${commercial.phoneNumber}`
                        : "",
                      "Deuxième Téléphone": commercial?.secondaryPhoneNumber
                        ? `+${commercial.secondaryPhoneNumber}`
                        : "",
                      adresse: commercial?.address,
                      "code Postal": commercial?.zipCode,
                      pays: commercial?.country,
                      ville: commercial?.city,
                    }}
                  />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <ProfileInfoCard
                    info={{
                      "Site Web": commercial?.siteWeb,
                      Occupation: commercial?.occupation,
                      passeport: commercial?.passeport,
                      "Nom De Banque": commercial?.bank,
                      rib: commercial?.rib,
                      "Numéro CNSS": commercial?.cnssNumber,
                      fichier: (
                        <SoftBox component="ul" ml={-3} pl={2}>
                          {commercial?.files?.map((file, index) => (
                            <SoftBox
                              component="li"
                              color="text"
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              fontSize="1rem"
                              lineHeight={2}
                              key={index}
                              mb={2}
                              mt={1}
                              sx={{
                                p: 0.75,
                                borderBottom: `1px solid black`,
                                backgroundColor: index % 2 === 0 ? "#F2F2F2" : "transparent",
                              }}
                            >
                              <SoftTypography variant="button" color="text" fontWeight="regular"  
                              sx={{
                                whiteSpace: "nowrap", 
                                overflow: "hidden",
                                textOverflow: "ellipsis", 
                                maxWidth: "90%", 
                              }}>
                                {file}
                              </SoftTypography>
                              <FileDownloadIcon
                                onClick={() => downloadFile(file.name ? file.name : file)}
                                fontSize="small"
                                sx={{ cursor: "pointer" }}
                              />
                            </SoftBox>
                          ))}
                        </SoftBox>
                      ),
                      description: (commercial?.comment ?
                        (<Box variant="outlined">
                        <Box >
                          <SoftTypography
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            sx={{ textAlign: "justify" }}
                          >
                            {commercial?.comment}
                          </SoftTypography>
                        </Box>
                      </Box>) : ""
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              < SoftBox mt={3}>
            <Grid
              item
              xs={12}
              lg={12}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
              container
            >
              <SoftButton
                id="btn-cancel"
                onClick={() => navigate("/commercials/commercial")}
                variant="gradient"
                color="purple"
                size="small"
              >
                Retour
              </SoftButton>
            </Grid>
          </SoftBox>
            </SoftBox>
          </Card>
         
        </>
      )}
    </DashboardLayout>
  );
}
