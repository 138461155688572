import { createContext, useContext, useReducer, useMemo, useEffect } from "react";
import '../App.css';

// prop-types is a library for typechecking of props
import PropTypes, { object } from "prop-types";

import Purchase from "pages/billings-new/Purchase"; // delete me 
import { useNavigate, useLocation } from "react-router-dom";

// The Soft UI Dashboard PRO React main context
const SoftUI = createContext(null);

// Setting custom name for the context which is visible on react dev tools
SoftUI.displayName = "SoftUIContext";

// Soft UI Dashboard PRO React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "LOGIN": {
      return { ...state, user: action.value };
    }
    case "FACILITY": {
      return { ...state, facilityInfo: action.value };
    }
    case "CHAT": {
      return { ...state, chat: action.value };
    }
    case "NOTIFICATION": {
      return { ...state, notifications: action.value }; // GET => /get-all-notifications
    }
    case "LOGOUT": {
      return { ...state, user: {}, facilityInfo: {} };
    }
    case "CONNECTED": {
      return { ...state, connectedUsers: action.value };
    }
    case "PURCHASE": { // index, key, newValue

      const { actionType, index, newValue } = action.value;

      if (actionType === 'modifiy')
        state.purchases[index] = newValue;
      else if (actionType === 'delete')
        state.purchases.splice(index, 1);
      else if (actionType === 'clear') {
        state.purchases = [];
      }

      return { ...state };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Soft UI Dashboard PRO React context provider
function SoftUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: true,
    sidenavColor: "purple",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    user: {},
    facilityInfo: {},
    purchases: [],
    chat: { open: false, receiver: {} },
    notifications: [],
    connectedUsers: []
  };

  const [controller, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("refreshToken") && !sessionStorage.getItem("refreshToken") && !localStorage.getItem("accessToken") && !controller.user.info) {
      if (
        location.pathname !== "/" &&
        location.pathname !== "/forget-password" &&
        
        !location.pathname.startsWith("/reset-password/") &&
        !location.pathname.startsWith("/set-password")
      ) {
        navigate("/");
      }
    }
  }, [controller.user]);
  useEffect(() => {
    const handleKeyUp = (e) => {
      const keyCode = e.keyCode ? e.keyCode : e.which;
      if (keyCode === 44 && controller.user?.info?.role === "student") {
        hideContent();
        stopPrntScr();
      }
    };

    const hideContent = () => {
      document.body.style.visibility = 'hidden';
      setTimeout(() => {
        document.body.style.visibility = 'visible';
      }, 1000);
    };

    const stopPrntScr = () => {
      const inpFld = document.createElement("input");
      inpFld.setAttribute("value", ".");
      inpFld.style.height = "0px";
      inpFld.style.width = "0px";
      inpFld.style.border = "0px";
      document.body.appendChild(inpFld);
      inpFld.select();
      document.execCommand("copy");
      inpFld.remove();
    };

    const AccessClipboardData = () => {
      try {
        window.clipboardData.setData('text', "Access Restricted");
      } catch (err) {
        // Handle error if necessary
      }
    };

    document.addEventListener("keyup", handleKeyUp);
    const intervalId = setInterval(AccessClipboardData, 300);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      clearInterval(intervalId);
    };
  }, [controller.user]);

  return <SoftUI.Provider value={value}>
    {children}
  </SoftUI.Provider>;
}

// Soft UI Dashboard PRO React custom hook for using context
function useSoftUIController() {
  const context = useContext(SoftUI);

  if (!context) {
    throw new Error("useSoftUIController should be used inside the SoftUIControllerProvider.");
  }

  return context;
}

// Typechecking props for the SoftUIControllerProvider
SoftUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setUser = (dispatch, value) => dispatch({ type: "LOGIN", value });
const setFacility = (dispatch, value) => dispatch({ type: "FACILITY", value });
const setLogout = (dispatch) => dispatch({ type: "LOGOUT" });
const setChat = (dispatch, value) => dispatch({ type: "CHAT", value })
const setNotification = (dispatch, value) => dispatch({ type: "NOTIFICATION", value });
const setPurchase = (dispatch, value) => dispatch({ type: "PURCHASE", value });
const setConnectedUsers = (dispatch, value) => dispatch({ type: "CONNECTED", value });

export {
  SoftUIControllerProvider,
  useSoftUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setUser,
  setFacility,
  setPurchase,
  setLogout,
  setChat,
  setNotification,
  setConnectedUsers
};
