
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import api from "utils/axios";


function EnterpriseAction({enterprise}) {
    const navigate = useNavigate();
    const showAlert = () => {
        const newSwal = Swal.mixin({
          customClass: {
            confirmButton: "button button-success",
            cancelButton: "button button-error",
          },
          buttonsStyling: false,
        });
    
        newSwal
          .fire({
            title: "Êtes-vous sûr(e)",
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui, supprimer !",
            cancelButtonText:"Annuler"

          })
          .then(async(result) => {
            if (result.isConfirmed) {
                try {
                    const response = await api.delete(`/delete-enterprise/${enterprise._id}`)
                    if(response){
                        (await Swal.fire("Supprimé !", "Enterprise a été supprimé.", "success")).isConfirmed && navigate(0);
                    }
                } catch (error) {
                    Swal.fire("Something went wrong!", "Enterprise has not been deleted.", "error");
                }
            }
          });
      };
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Modfier" placement="top">
        <Icon id="edit-enterprise-page" onClick={()=>navigate(`/companies/${enterprise._id}`)}>edit</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
      <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Supprimer" placement="left">
            <Icon id={`delete-enterprise-${enterprise._id}`} onClick={showAlert}>delete</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}
EnterpriseAction.propTypes = {
    enterprise: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array, PropTypes.string]).isRequired,
    handleFetch: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array, PropTypes.string]).isRequired,
  };
  

export default EnterpriseAction;
