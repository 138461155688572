/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================
​
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
​
Coded by www.creative-tim.com
​
 =========================================================
​
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from "react";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// react-router-dom components
import { Box, Badge, Stack } from "@mui/material";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import SchoolIcon from '@mui/icons-material/School';
import Menu from "@mui/material/Menu";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import SoftButton from "components/SoftButton";
// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import DefaultItem from "examples/Items/DefaultItem";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React example components
import SidenavCollapse from "layouts/Sidenav/SidenavCollapse";
import SidenavList from "layouts/Sidenav/SidenavList";
import SidenavItem from "layouts/Sidenav/SidenavItem";
import { setFacility, setLogout, setUser } from "context";

// Custom styles for the Sidenav
import SidenavRoot from "layouts/Sidenav/SidenavRoot";
import sidenavLogoLabel from "layouts/Sidenav/styles/sidenav";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setMiniSidenav } from "context";

import logoLight from "assets/images/logoLight.png";
const useStyles = makeStyles({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',


    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: "30px",

    },

    'html, body': {
      scrollBehavior: 'smooth',
    },
  },
});
function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const classes = useStyles();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [openNestedCollapses, setOpenNestedCollapses] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const itemName = pathname.split("/").slice(1)[1];
  const handleOpenMenuFacility = (event) => setOpenMenuFacility(event.currentTarget);

  const [openMenuFacility, setOpenMenuFacility] = useState(false);

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            name={name}
            active={key === itemName}
            open={openNestedCollapse === name}
            onClick={() =>
              openNestedCollapse === name
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(name)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });
  // Render the all the collpases from the routes.js
  const renderCollapsess = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            name={name}
            active={key === itemName}
            open={openNestedCollapses === name}
            onClick={() =>
              openNestedCollapses === name
                ? setOpenNestedCollapses(false)
                : setOpenNestedCollapses(name)
            }
          >
            {renderCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });
  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapsess(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                key={key}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() =>
                  openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)
                }
              >
                {collapse ? renderCollapsess(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        }
      }  else if (type === "title") {
        returnValue = (
          <SoftTypography
            key={key}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </SoftTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} />;
      } 

      return returnValue;
    }
  );
  const renderMenuFacilities = () => {

    if (controller.user.info.role === "company-admin") {
      return (
        <Menu
          anchorEl={openMenuFacility}
          anchorReference={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={Boolean(openMenuFacility)}
          onClose={handleCloseMenuFacility}
          sx={{ mt: 1 }}
        >
          {controller?.user?.info?.ownedFacilities
            .filter((facility) => facility._id !== facilityParsed?._id)
            .map((facility) => (
              <div key={facility._id}>
                <Box
                  sx={{
                    cursor: "pointer",
                    '&:hover': { backgroundColor: "lightgray" },
                    marginBottom: "2px",
                  }}
                >
                  <DefaultItem
                    color="primary"
                    icon={<AssuredWorkloadIcon />}
                    title={facility.name}
                    description={facility.type}
                    sx={{
                      cursor: "pointer",
                      '&:hover': { backgroundColor: "lightgray" },
                    }}
                    onClick={() => handleChangeFacility(facility)}
                  />
                </Box>
                <Divider sx={{ margin: "0" }} />
              </div>
            ))}
        </Menu>

      )
    }
  }
  const handleCloseMenuFacility = () => setOpenMenuFacility(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    setLogout(dispatch);
    localStorage.clear();
    sessionStorage.clear();
    navigate(0);
  };
  let facilityInfo;
  let facilityParsed;
  if (controller?.user?.info?.role === "company-admin") {
    facilityInfo = localStorage.getItem("facility");
    facilityParsed = JSON.parse(facilityInfo);
  }
  const handleChangeFacility = async (facility) => {
    // Mettre à jour l'état facility
    await setFacility(dispatch, facility);
  
    // Enregistrer l'information de l'installation dans le stockage local
    localStorage.setItem("facility", JSON.stringify(facility));
  
    // Fermer le menu des installations (si ouvert)
    handleCloseMenuFacility();
  
    // Naviguer vers "/dashboard"
    navigate(0);
    //window.location.reload();
    // Actualiser la page
  
  };
  

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <SoftBox pt={3} pb={1} px={4} textAlign="center">
        <SoftBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SoftTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SoftTypography>
        </SoftBox>
        <SoftBox component={NavLink}to={"/dashboards/default"} display="flex" alignItems="center">
          {brand && <SoftBox component="img" src={logoLight} alt="square Logo" width="2rem" />}
          <SoftBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <SoftTypography component="h6" variant="button" fontWeight="medium">
              {brandName}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>

      {
        controller?.user?.info?.role === "company-admin" && (
          <SoftButton 
          //width={{ xs:"1%" ,sm:"80%",md:"80%"}}
         // height={{ xs:"510%" ,sm:"10%",md:"10%"}}
         
            style={{
              padding:"25px",
             width: "88%",
             height: "5.5%",
              marginTop: "41px",
              marginLeft: "17px",
              cursor: "pointer",
              ...(miniSidenav && { width: "50px", padding: "0" }),
            }}
            aria-controls="notification-menu"
            aria-haspopup="true"
            variant="gradient"
            onClick={handleOpenMenuFacility}
          >
            {miniSidenav ? (
              <Icon sx={{ fontWeight: "bold", }}> <AssuredWorkloadIcon fontSize="default" /></Icon>
            ) : (
              <Grid container display="flex" alignItems="center">
              <Grid item xs={2} sm={2} md={2}  //fontSize="default"
              >
                {/* Bloc 1: 30% */}
                <Icon sx={{ fontWeight: "bold" }}>
                  <AssuredWorkloadIcon fontSize="default" />
                </Icon>
              </Grid>
              <Grid item xs={8} sm={8} md={8} container flexDirection="column">
                {/* Bloc 2: 60% */}
                <SoftTypography
                  marginLeft={{ sm: "5px" ,xs:"10px"}}
                  variant="button"
                  fontWeight="medium"
                  display="block"
                  textAlign={{ sm: "left" ,xs: "left"}}
                  marginRight={{ //xs: "40px", 
                  sm: "0px" }}
                 
                >
                  {facilityParsed?.name}
                </SoftTypography>
                
                <SoftTypography
                  marginLeft={{ xs: "10%", sm: "5px" }}
                  marginRight={{ xs: "70px", sm: "0px" }}
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  display="block"
                  textAlign={{ sm: "left" }}
                  sx={{ whiteSpace: 'pre-line' }} 
                >
                  {facilityParsed?.type}
                </SoftTypography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} >
                {/* Bloc 3: 10% */}
                <KeyboardArrowDownIcon  sx={{ marginLeft: { sm: "30px", md: "30px", xs: "25px" } }} 
                />
              </Grid>
            </Grid>
            


            )}
          </SoftButton>
        )
      }



      <Divider />
      <List>{renderRoutes}</List>
      {controller?.user?.info?.role === "company-admin" && renderMenuFacilities()}

    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;