import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import Box from "@mui/material/Box";
import CommonComponentWeek from "./CommonComponentWeek";
import CloseIcon from "@mui/icons-material/Close";
import api from "utils/axios";
import Swal from "sweetalert2";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
export default function SeanceDialog({
  handleClose,
  instructorId,
  heureDebut,
  enseignant,
  jour,
  planningId,
  handleFetch,
}) {
  const [visible, setVisible] = useState(false);
  const [parQuinzaine, setParQuinzaine] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibleSemaine, setVisiblSemaine] = useState(false);
  const [groupe, setGroupe] = useState("");
  const [week, setWeek] = useState("toute");
  const [typeSalle, setTypeSalle] = useState("Presentiel");
  const [subjectType, setSubjectType] = useState("Cours");
  const [value, setValue] = useState(0);
  const [valueGroupe, setValueGroupe] = useState(0);
  const [optionsMatiere, setOptionsMatiere] = useState([]);
  const [optionsClasses, setOptionsClasses] = useState([]);
  const [optionsSalle, setOptionsSalle] = useState([]);
  const [salle, setSalle] = useState("");
  const [salleLink, setSalleLink] = useState("");
  const [matiere, setMatiere] = useState("");
  const [classe, setClasse] = useState("");
  const [debutSeance, setDebutSeance] = useState(heureDebut);
  const [finSeance, setFinSeance] = useState("");
  const [duree, setDuree] = useState("30");
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [errorMatiere, setErrorMatiere] = useState("");
  const [errorSalle, setErrorSalle] = useState("");
  const [errorClasse, setErrorClasse] = useState("");

  /*   const handleSetVisible = () => setVisible(!visible);
  const handleSetVisibleSemaine = () => setVisiblSemaine(!visibleSemaine); */

  // Function to calculate end time based on start time and duration
  const calculateEndTime = () => {
    const debutArray = debutSeance.split(":"); // Splitting the start time
    const debutHours = parseInt(debutArray[0]);
    const debutMinutes = parseInt(debutArray[1]);
    const dureeMinutes = parseInt(duree);
    let finHours = debutHours + Math.floor((debutMinutes + dureeMinutes) / 60);
    let finMinutes = (debutMinutes + dureeMinutes) % 60;
    // Formatting the end time with leading zeros if necessary
    finHours = ("0" + finHours).slice(-2);
    finMinutes = ("0" + finMinutes).slice(-2);
    const formattedEndTime = `${finHours}:${finMinutes}`;
    setFinSeance(formattedEndTime); // Update state with the calculated end time
  };

  useEffect(() => {
    calculateEndTime();
  }, [debutSeance, duree]);

  useEffect(() => {
    api.get(`/faculty/subject-types/${instructorId}`).then((res) => {
      setOptionsMatiere(
        res?.data?.map((item) => ({
          value: item._id,
          label: `${item?.type} ${item?.subject?.name}`,
        }))
      );
    });
    api.get(`/faculty/get-all-classes/${facility.scholarityConfigId}`).then((res) => {
      setOptionsClasses(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
        }))
      );
    });
    api.get(`/faculty/all-salles/${facility.scholarityConfigId}`).then((res) => {
      setOptionsSalle(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
        }))
      );
    });
  }, []);

  const handleAddSeance = async () => {
    try {
      setLoading(true);
      const seanceInfo = {
        duree: duree,
        day: jour,
        startTime: debutSeance,
        endTime: finSeance,
        week: week,
        parQuinzaine: parQuinzaine,
        //"subject": matiere,
        groupe: groupe,
        subjectType: matiere,
        salleType: typeSalle,
        classes: classe,
        ...(typeSalle === "Presentiel" ? { salle: salle } : { salleLink: salleLink }),
      };
      if (matiere === "") {
        setErrorMatiere("Veuillez sélectionner la matière.");
      } else if (salle === "" && typeSalle === "Presentiel") {
        setErrorSalle("Veuillez sélectionner la salle.");
      } else if (classe === "") {
        setErrorClasse("Veuillez sélectionner la classe.");
      } else {
        const response = await api.post(`/add-session/${planningId}`, seanceInfo);
        if (response.status === 201) {
          Swal.fire("Succès !", "La séance a été ajouté avec succès", "success").then(() => {
            setLoading(false);
            handleFetch();
            handleClose();
          });
        }
      }
    } catch (error) {
      setLoading(true);
      if (error.response && error.response.data && error.response.data.message) {
        Swal.fire("Error", error.response.data.message, "error");
      } else {
        Swal.fire(
          "Error",
          "Une erreur s'est produite. Veuillez réessayer ultérieurement.",
          "error"
        );
      }
    }
  };
  return (
    <>
      <DialogTitle>Ajouter Séance</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <SoftBox mt={1.625}>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={6}>
              <SoftTypography
                sx={{ mb: 1 }}
                component="label"
                variant="caption"
                fontWeight="medium"
              >
                Durée
              </SoftTypography>
              <SoftInput
                name="Durée"
                margin="normal"
                id="outlined-basic"
                label="Durée"
                type="number"
                defaultValue="30"
                inputProps={{
                  min: "30",
                  max: "540",
                  step: "30",
                }}
                variant="standard"
                onChange={(e) => setDuree(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftTypography
                sx={{ mb: 1 }}
                component="label"
                variant="caption"
                fontWeight="medium"
              >
                Jour
              </SoftTypography>
              <SoftInput
                name="Jour"
                margin="normal"
                id="outlined-basic"
                label="Jour"
                type="Text"
                defaultValue={jour}
                disabled
                inputProps={{
                  min: "30",
                  max: "540",
                  step: "30",
                }}
                variant="standard"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SoftTypography
                sx={{ mb: 1 }}
                component="label"
                variant="caption"
                fontWeight="medium"
              >
                Début de la séance
              </SoftTypography>
              <SoftInput
                id="time-emploi"
                type="time"
                defaultValue={heureDebut || "08:00"}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 900,
                }}
                onChange={(e) => setDebutSeance(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftTypography
                sx={{ mb: 1 }}
                component="label"
                variant="caption"
                fontWeight="medium"
              >
                Fin de la séance
              </SoftTypography>
              <SoftInput
                id="time-emploi"
                type="time"
                disabled
                value={finSeance}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 900,
                }}
                onChange={(e) => setFinSeance(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={6}></Grid>
            {!visible && !visibleSemaine && (
              <Grid item xs={12} sm={6} mt={2}>
                <SoftInput
                  name="classe"
                  margin="normal"
                  id="outlined-basic"
                  label="Jour"
                  type="Text"
                  defaultValue={enseignant}
                  disabled
                  variant="standard"
                  placeholder="*Nom de l'enseignant par defaut"
                />
              </Grid>
            )}
            {visible && (
              <Grid item xs={12} sm={12}>
                <Stack spacing={3}>
                  <SoftBox>
                    <SoftTypography
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      Choix des classes :
                    </SoftTypography>

                    <Autocomplete
                      id={`size-small-standard`}
                      // defaultValue={classes}
                      options={optionsClasses}
                      aria-required={true}
                      required={true}
                      multiple={true}
                      clearIcon={null}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {}}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            checked={selected}
                            // You may adjust the onChange event according to your needs
                            // This example uses the Autocomplete's onChange event
                            onChange={() => {
                              /* handle option selection */
                            }}
                          />
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          placeholder="Sélectionner le nom de classe"
                          sx={{ mb: 2 }}
                          {...params}
                          required={true}
                          variant="standard"
                        />
                      )}
                    />
                  </SoftBox>
                </Stack>
              </Grid>
            )}
          </Grid>
          {/*  {visibleSemaine && ( */}
          <Box
            sx={{ width: "100%" }}
            style={{ backgroundColor: "white", borderRadius: "16px", padding: "15px" }}
          >
            <Box>
              <CommonComponentWeek
                typeSalle={typeSalle}
                setSubjectType={setSubjectType}
                setSalle={setSalle}
                setSalleLink={setSalleLink}
                setMatiere={setMatiere}
                setTypeSalle={setTypeSalle}
                optionsMatiere={optionsMatiere}
                optionsSalle={optionsSalle}
                optionsClasses={optionsClasses}
                setClasse={setClasse}
                parQuinzaine={parQuinzaine}
                setParQuinzaine={setParQuinzaine}
                groupe={groupe}
                setGroupe={setGroupe}
                week={week}
                setWeek={setWeek}
                errorMatiere={errorMatiere}
                matiere={matiere}
                errorSalle={errorSalle}
                salle={salle}
                errorClasse={errorClasse}
                classe={classe}
              />
            </Box>
          </Box>
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <SoftButton onClick={handleClose} sx={{ mt: 2, ml: 2 }} variant="outlined" color="dark">
          Annuler
        </SoftButton>
        <SoftButton onClick={handleAddSeance} sx={{ mt: 2 }} variant="gradient" color="customized">
          Enregistrer
        </SoftButton>
      </DialogActions>
    </>
  );
}
SeanceDialog.propTypes = {
  handleClose: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  enseignant: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  instructorId: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  jour: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  planningId: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  handleFetch: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  heureDebut: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
