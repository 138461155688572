// page path => /reset-password/:id/:token
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "utils/axios";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


// Image
import lock from "assets/images/illustrations/customized-lock-2.png";
import { passwordRegEx } from "utils/regex";


function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [alert, setAlert] = useState({show: false, message: '', type: ''});
  const [password, setPassword] = useState({ value: '', showPass: false, isValid: true });
  const [passwordValidation, setPasswordValidation] = useState({ value: '', showPass: false, isValid: true });
  const [successPassChange, setSuccessPassChange] = useState(false);
  const {id, token} = useParams();

  useEffect(() => {
    // change page title in the header
    document.title = "changement mot de passe";
  }, []);
  
  // password input changes handler function
  const handlePasswordInputChanges = (e) => {
    setPassword((prevState) => ({
      ...prevState,
      value: e.target?.value
    }));
  }

  // handle the onBlur action of the email input (email validation process)
  const handlePasswordValidation = () => {
    // pattern must and only have lower, upper, number, specials, space allowed but not required, with a length between: 8 - 50
    const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=~`+[{\]}\\|:;"'<,>.?/])[a-zA-Z\d!@#$%^&*()\-_=~`+[{\]}\\|:;"'<,>.?/\s]{8,50}$/;
    setPassword((prevState) => ({
      ...prevState,
      isValid: passwordRegEx.test(prevState.value)
    }));
  };

  // password validation input changes handler function
  const handlePasswordValidationChanges = (e) => {
    setPasswordValidation((prevState) => ({
      ...prevState,
      value: e.target?.value
    }));
  };

  // handle the onBlur action of the email input (email validation process)
  const handleCheckPasswordValidation = () => {
    // pattern must and only have lower, upper, number, specials, space allowed but not required, with a length between: 8 - 50
    const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=~`+[{\]}\\|:;"'<,>.?/])[a-zA-Z\d!@#$%^&*()\-_=~`+[{\]}\\|:;"'<,>.?/\s]{8,50}$/;
    setPasswordValidation((prevState) => ({
      ...prevState,
      isValid: passwordRegEx.test(prevState.value)
    }));
  };

  // toggle the password visibility
  const changePasswordVisibility = (passwordInputNumber) => {
    if (passwordInputNumber === 1) {
      setPassword((prevState) => ({
        ...prevState,
        showPass: !prevState.showPass
      }));
    } else if (passwordInputNumber === 2) {
      setPasswordValidation((prevState) => ({
        ...prevState,
        showPass: !prevState.showPass
      }));
    }
  }

  // send button handler
  const sendButtonHandler = () => {
    setIsLoading(true);
    api.post(`/reset-password/${id}/${token}`, {
      newPassword: password.value
    }).then((response) => {
      setSuccessPassChange(true);

      /* redirect after 10 seconds...
      / setAlert({ show: true, message: "Réinitialisation de votre mot de passe avec succès. On va vous rediriger vers la page d'authentification dans quelques secondes...", type: 'success'});
      / setTimeout(() => { navigate('/'); }, 10_000);
      */
    }).catch((error) => {
      if (error.code === 'ERR_NETWORK') {
        setAlert({ show: true, message: 'Problem coté server 500', type: 'error'});
      } else {
        setAlert({ show: true, message: (error.response?.data?.message || error.message), type: 'error'});
      }
      setTimeout(() => {
        setAlert({ show: false, message: '', type: ''});
      }, 10_000);
    }).finally(() => {
      setIsLoading(false);
    });
  }


  // enter keypress effects
  document.addEventListener("keypress", function(event) {
    const pressedKey = event.key;
    if (!isLoading && pressedKey === 'Enter' && passwordRegEx.test(password.value) && passwordRegEx.test(passwordValidation.value) && (password.value === passwordValidation.value)) {
      sendButtonHandler();
    }
  });

  return (
    <IllustrationLayout
      showDefaultNavBar={false}
      title = {successPassChange ? "Votre Mot de passe a été Récupère" : "Modifier votre mot de passe"}
      description = {
        successPassChange ? "Veuillez vérifier votre boite de réception et cliquer sur le lien reçu pour réinitialiser votre mot de passe" : "Rafraîchissez la sécurité de votre compte"
      }
      illustration={{
        image: lock,
        title: "Proservices Square",
        description: "Renforcez la sécurité de votre compte en choisissant un nouveau mot de passe robuste.",
      }}
    >
      <SoftBox component="form" role="form">
        {
        !successPassChange && (
        <>
          <SoftBox mb={2} id="reset-password-box">
            <SoftInput
              onBlur = {handlePasswordValidation}
              error = {!password.isValid}
              onChange={handlePasswordInputChanges}
              type={password.showPass ? "text" : "password"}
              placeholder="................."
              size="large"
              icon = {
                {
                component: (
                  password.showPass 
                  ?
                  <Tooltip title="Masquer le mot de passe">
                    <VisibilityOffIcon color = "customized" onClick={changePasswordVisibility.bind(null, 1)} sx={{ cursor: "pointer" }} id="reset-password-visibility-icon" /> 
                  </Tooltip>
                  :
                  <Tooltip title="Afficher le mot de passe">
                    <VisibilityIcon color = "customized" onClick={changePasswordVisibility.bind(null, 1)} sx={{ cursor: "pointer" }} id="reset-password-visibility-icon" />
                  </Tooltip>
                  ), direction: "right"
                }
              }
              autoComplete = "current-password"
              id="reset-password-input"
            />
          </SoftBox>
          <SoftBox>
            <SoftInput
              onBlur = {handleCheckPasswordValidation}
              onChange = {handlePasswordValidationChanges}
              error = {!passwordValidation.isValid}
              type={passwordValidation.showPass ? "text" : "password"}
              size="large"
              icon = {
                {
                  component: (
                    passwordValidation.showPass
                    ?
                    <Tooltip title="Masquer le mot de passe">
                      <VisibilityOffIcon color = "customized" onClick={changePasswordVisibility.bind(null, 2)} sx={{ cursor: "pointer" }} id="reset-password-visibility-icon" />
                    </Tooltip>
                    :
                    <Tooltip title="Afficher le mot de passe">
                      <VisibilityIcon color = "customized" onClick={changePasswordVisibility.bind(null, 2)} sx={{ cursor: "pointer" }} id="reset-password-visibility-icon" />
                    </Tooltip>
                  ), direction: "right" 
                }
              }
              id="reset-password-verification-input"
            />
          </SoftBox>
        </>
        )}
        <SoftBox mt={4} mb={1} display="flex" justifyContent="center">
        {
          (() => {
            if (successPassChange) {
              return (<SoftButton variant="gradient" color="customized" size="large" fullWidth id="login-button" onClick={() => navigate('/')}> Se connecter </SoftButton>);
            } else {
              return (
                isLoading
                ? <CircularProgress />
                : <SoftButton
                    disabled = {
                      (!passwordRegEx.test(password.value) && !passwordRegEx.test(passwordValidation.value))
                      || (password.value !== passwordValidation.value)
                    }
                    variant="gradient"
                    color="customized"
                    style={{ width: '100%', height: '45px' }}                 
                     
                    id="reset-password-button"
                    onClick={sendButtonHandler}
                  >
                    Enregistrer
                  </SoftButton>
              )
            }
          })()
        }
        </SoftBox>
        { alert.show && <SoftTypography m={4} color={alert.type} id="error-alert" fontWeight="bold" fontSize={15} style={{ textAlign: 'center' }} > { alert.message } </SoftTypography> }
        { alert.type === 'success' && <LinearProgress color="success" id="reset-password-sucess-progress-bar" /> }
      </SoftBox>
    </IllustrationLayout>
  );
}

export default ResetPassword;
