const form = {
    formId: "emploi",
    formField: {
      enseignant: {
        name: "enseignant",
        label: "Choix de l'enseignant*",
        type: "text",
        placeholder: "Choix de l'enseignant",
        errorMsg: "L'enseignant est obligatoire.",
      },
      instructorId: {
        name: "instructorId",
        label: "code de classe*",
        type: "text",
        placeholder: "code de classe",
        errorMsg: "code de classe est obligatoire.",
      },
      optionsClasses: {
        name: "optionsClasses",
        label: "Classes",
        type: "text",
        placeholder: "classes",
        errorMsg: "Choix de classe est obligatoire.",
      },
      jours: {
        name: "jours",
        label: "Jours",
        type: "text",
        placeholder: "Jours",
        errorMsg: "Jours est obligatoire.",
      },
      dateDebut: {
        name: "dateDebut",
        label: "Date de début",
        type: "text",
        placeholder: "Date de début",
        errorMsg: "Date de début est obligatoire.",
      },
      dateFin: {
        name: "dateFin",
        label: "Date de fin",
        type: "text",
        placeholder: "Date de fin",
        errorMsg: "Date de fin est obligatoire.",
      },
      heureDebut: {
        name: "heureDebut",
        label: "Heure de début",
        type: "text",
        placeholder: "Heure de début",
        errorMsg: "Heure de début est obligatoire.",
      },
      heureFin: {
        name: "heureFin",
        label: "Heure de fin",
        type: "text",
        placeholder: "Heure de fin",
        errorMsg: "Heure de fin est obligatoire.",
      },
      planningId: {
        name: "planningId",
        label: "planningId",
        type: "text",
        placeholder: "planningId",
        errorMsg: "planningId est obligatoire.",
      },
    },
  };
  export default form; 
