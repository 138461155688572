import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard PRO React example components

// Soft UI Dashboard PRO React icons
import SmsIcon from '@mui/icons-material/Sms';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images

import curved0 from "assets/images/curved-images/curved0.jpg";
import DashboardNavbar from "layouts/DashboardNavbar";
import { Icon } from "@mui/material";
import SoftButton from "components/SoftButton";
import { useSoftUIController } from "context";

function Header() {
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);
    const [controller] = useSoftUIController();

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        /** 
         The event listener that's calling the handleTabsOrientation function when resizing the window.
        */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    return (
        <SoftBox position="relative">
            <DashboardNavbar
                absolute light
            />
            <SoftBox
                display="flex"
                alignItems="center"
                position="relative"
                minHeight="18.75rem"
                borderRadius="xl"
                sx={{

                    backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0.6),
                            rgba(gradients.info.state, 0.6)
                        )}, url(${curved0})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                }}
            />
            <Card
                sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <Grid container spacing={3} alignItems="center">
                    <Grid item position="relative" height="max-content" >
                        <SoftAvatar
                            src={controller?.user?.info?.imageUrl?.startsWith("https")
                            ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                            : `${process.env.REACT_APP_BASE_URL}/${controller?.user?.info?.imageUrl}`}
                            alt="profile-image"
                            variant="rounded"
                            size="xl"
                            shadow="sm"
                        />
                        <SoftBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                            <SoftButton variant="gradient" color="light" size="small" iconOnly>
                                <Icon>edit</Icon>
                            </SoftButton>
                        </SoftBox>
                    </Grid>
                    <Grid item>
                        <SoftBox height="100%" mt={0.5} lineHeight={1}>
                            <SoftTypography variant="h5" fontWeight="medium">
                               {controller?.user?.info?.firstName} {controller?.user?.info?.lastName} 
                            </SoftTypography>
                            <SoftTypography variant="button" color="text" fontWeight="medium">
                            {controller?.user?.info?.role}
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
                        <AppBar position="static">
                            <Tabs
                                orientation={tabsOrientation}
                                value={tabValue}
                                onChange={handleSetTabValue}
                                sx={{ background: "transparent" }}
                            >
                                <Tab label="Message" icon={<SmsIcon />} />
                                <Tab label="Paramètre" icon={<SettingsIcon />} />
                                <Tab label="Mon Profile" icon={<PersonIcon />} />
                            </Tabs>
                        </AppBar>
                    </Grid>
                </Grid>
            </Card>
        </SoftBox>
    );
}

export default Header;
