// CommentInput.jsx
import React from "react";
import PropTypes from "prop-types"; 
import SoftInput from "components/SoftInput";


function CommentInput({ content, onContentChange, onAddComment }) {
  return (
    

        <SoftInput
          value={content}
          placeholder="Ecrivez votre commentaire"
          onChange={(e) => onContentChange(e.target.value)}
          multiline
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              onAddComment();
            }
          }}
        />
  
  );
}

// Add PropTypes validation
CommentInput.propTypes = {
  postId: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onContentChange: PropTypes.func.isRequired,
  onAddComment: PropTypes.func.isRequired,
};

export default CommentInput;
