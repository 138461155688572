const form = {
  formId: "enterprise",
  formField: {
    companyName: {
      name: "companyName",
      label: "Nom*",
      type: "text",
      placeholder: "Nom",
      errorMsg: "Nom est obligatoire",
    },
    serialNumber: {
      name: "serialNumber",
      label: "matricule fiscal*",
      type: "text",
      placeholder: "Matricule fiscal",
      errorMsg: "Matricule fiscal est obligatoire",
    },
    email: {
      name: "email",
      label: "Email*",
      type: "text",
      placeholder: "Email",
      errorMsg: "Email est obligatoire",
    },
    siteWeb: {
      name: "siteWeb",
      label: "Site web",
      type: "text",
      placeholder: "Site web",
      errorMsg: "Site web est obligatoire",
    },
    phone: {
      name: "phone",
      label: "Téléphone",
      type: "text",
      placeholder: "Téléphone",
      errorMsg: "Téléphone est obligatoire",
    },
    secondaryPhone: {
      name: "secondaryPhone",
      label: "Deuxième Téléphone",
      type: "text",
      placeholder: "Deuxième Téléphone",
      errorMsg: "Deuxième Téléphone est obligatoire",
    },
    logoUrl: {
      name: "logoUrl",
      label: "Photo",
      type: "file",
      placeholder: "Photo",
      errorMsg: "Photo est obligatoire",
    },
    physiqualAddress: {
      name: "physiqualAddress",
      label: "adresse*",
      type: "text",
      placeholder: "adresse",
      errorMsg: "Adresse est obligatoire",
    },
    postCode: {
      name: "postCode",
      label: "Code postal*",
      type: "text",
      placeholder: "Code postal",
      errorMsg: "Code postal est obligatoire",
    },
    country: {
      name: "country",
      label: "pays",
      type: "text",
      placeholder: "pays",
      errorMsg: "Pays est obligatoire",
    },
    city: {
      name: "city",
      label: "ville",
      type: "text",
      placeholder: "ville",
      errorMsg: "Ville est obligatoire",
    },
    description: {
      name: "description",
      label: "Description",
      type: "text",
      placeholder: "Description",
      errorMsg: "Description  est obligatoire",
    },
    files: {
      name: "files",
      label: "ficher",
      type: "file",
      placeholder: "ficher",
      errorMsg: "Ficher code est obligatoire",
    },
    facilityName: {
      name: "facilityName",
      label: "Nom*",
      type: "text",
      placeholder: "Nom",
      errorMsg: "Nom est obligatoire",
    },

    facilitySerialNumber:{
      name: "facilitySerialNumber",
      label: "matricule fiscal*",
      type: "text",
      placeholder: "matricule fiscal",
      errorMsg: "Matricule fiscal est obligatoire",
    },
    facilityType:{
      name: "facilityType",
      label: "Type d'etablissement",
      type: "text",
      placeholder: "Type d'etablissement",
      errorMsg: "Etablissement Type est obligatoire",
    },
    CompanyAdminfirstName: {
      name: "CompanyAdminfirstName",
      label: "Nom*",
      type: "text",
      placeholder: "Nom",
      errorMsg: "Nom est obligatoire",
    },
    CompanyAdminlastName: {
      name: "CompanyAdminlastName",
      label: "Prénom*",
      type: "text",
      placeholder: "Prénom",
      errorMsg: "Prénom est obligatoire",
    },
    CompanyAdmingender: {
      name: "CompanyAdmingender",
      label: "Genre*",
      type: "text",
      placeholder: "Genre",
      errorMsg: "Genre est obligatoire",
    },
    CompanyAdminemail: {
      name: "CompanyAdminemail",
      label: "email*",
      type: "text",
      placeholder: "Email",
      errorMsg: "Email est obligatoire",
    },
    CompanyAdminphone: {
      name: "CompanyAdminphone",
      label: "Téléphone*",
      type: "text",
      placeholder: "Téléphone",
      errorMsg: "Téléphone est obligatoire",
    },
    CompanyAdminSecondaryphone: {
      name: "CompanyAdminSecondaryphone",
      label: "Deuxième Téléphone",
      type: "text",
      placeholder: "Deuxième Téléphone",
      errorMsg: "Deuxième Téléphone est obligatoire",
    },
    CompanyAdmincin: {
      name: "CompanyAdmincin",
      label: "CIN",
      type: "number",
      placeholder: "CIN",
      errorMsg: "cin est obligatoire",
    },
    CompanyAdminsiteWeb: {
      name: "CompanyAdminsiteWeb",
      label: "Site web",
      type: "text",
      placeholder: "Site web",
      errorMsg: "site Web est obligatoire",
    },
    CompanyAdminbirthday: {
      name: "CompanyAdminbirthday",
      label: "Date de naissance",
      type: "text",
      placeholder: "Date de naissance",
      errorMsg: "Date de naissance est obligatoire",
    },
    CompanyAdminoccupation: {
      name: "CompanyAdminoccupation",
      label: "Occupation",
      type: "text",
      placeholder: "Occupation",
      errorMsg: "Occupation est obligatoire",
    },
    CompanyAdminimageUrl: {
      name: "CompanyAdminimageUrl",
      label: "Photo",
      type: "file",
      placeholder: "Photo",
      errorMsg: "Photo est obligatoire",
    },
    CompanyAdminaddress: {
      name: "CompanyAdminaddress",
      label: "Adresse",
      type: "text",
      placeholder: "Adresse",
      errorMsg: "Address est obligatoire",
    },
    CompanyAdminpassport: {
      name: "CompanyAdminpassport",
      label: "Passeport",
      type: "text",
      placeholder: "Passeport",
      errorMsg: "Passeport est obligatoire",
    },
    CompanyAdminzipCode: {
      name: "CompanyAdminzipCode",
      label: "Code Postal",
      type: "number",
      placeholder: "Code Postal",
      errorMsg: "Code Postal est obligatoire",
    },
    CompanyAdminbank: {
      name: "CompanyAdminbank",
      label: "Banque",
      type: "text",
      placeholder: "Banque",
      errorMsg: "Banque est obligatoire",
    },
    CompanyAdminrib: {
      name: "CompanyAdminrib",
      label: "RIB",
      type: "number",
      placeholder: "RIB",
      errorMsg: "Rib est obligatoire",
    },
    CompanyAdmincnssNumber: {
      name: "CompanyAdmincnssNumber",
      label: "Numéro CNSS ",
      type: "number",
      placeholder: "Numéro CNSS ",
      errorMsg: "Numéro CNSS est obligatoire",
    },
    CompanyAdminfiles: {
      name: "CompanyAdminfiles",
      label: "Fichiers",
      type: "file",
      placeholder: "fichers",
      errorMsg: "Fichers code est obligatoire",
    },
    CheckedStatus:{
      name: "CheckedStatus",
      errorMsg: "Checked Status est obligatoire",
    },
    CompanyAdminDescription: {
      name: "CompanyAdminDescription",
      label: "Description",
      type: "text",
      placeholder: "Description",
      errorMsg: "Description code est obligatoire",
    },

  },
};
export default form;
