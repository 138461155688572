// page path => / (root)

import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // react-router-dom components
import Switch from "@mui/material/Switch"; // @mui material componentst
import Tooltip from '@mui/material/Tooltip';
import api from "utils/axios";
import Grid from "@mui/material/Grid";
import { shell } from '@tauri-apps/api';

import { emailRegex } from "utils/regex";
import { useSoftUIController, setUser, setFacility } from "context"; // Soft UI Dashboard PRO React context

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftAlert from "components/SoftAlert";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import mapChart from "assets/images/illustrations/map-chart.png";

import EmailIcon from '@mui/icons-material/Email';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import io from "socket.io-client";


function SignInIllustration() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [bottomAlert, setBottomAlert] = useState({ show: false, message: '' }); // bottom alert
  const [controller, dispatch] = useSoftUIController();
  const socket = io(process.env.REACT_APP_SOCKET_URL);

  const isDesktop = () => {
    return typeof window !== 'undefined' && typeof window.__TAURI__ !== 'undefined';
  };
  const sessionId = localStorage.getItem('sessionId');

  useEffect(() => {
    const handelLoginDesktop = async () => {
      setIsLoading(true);
      try {
        if (isDesktop) {
          const email = localStorage.getItem("token");
          if (!email) {
            return;
          }
          const response = await api.post("/login-desktop-app", { email });
          const { accessToken, refreshToken } = response.data;
          localStorage.setItem('refreshToken', refreshToken);
          sessionStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('accessToken', accessToken);
          const userData = atob(accessToken.split('.')[1]);
          const parsedData = JSON.parse(userData);
          localStorage.setItem('sessionId', parsedData.sessionId);
          if (parsedData.role === "company-admin" || parsedData.role === "admin" || parsedData.role === "editor") {
            localStorage.setItem("licence", JSON.stringify(parsedData.licenceInfo))
            }
          if (parsedData.role === "company-admin") {
            setFacility(dispatch, parsedData.ownedFacilities[0]);
            localStorage.setItem("facility", JSON.stringify(parsedData.ownedFacilities[0]))
          } else if (parsedData.role === "admin" || parsedData.role === "editor") {
            setFacility(dispatch, parsedData.facilityInfo);
            localStorage.setItem("facility", JSON.stringify(parsedData.facilityInfo));
          }
          // update the access token each 14 minuts...
          const intervalId = setInterval(() => {
            api.get('/refresh-token', {
              headers: { 'Authorization': `Basic ${refreshToken}` }
            }).then((response) => {
              const { refreshToken, accessToken } = response.data;
              // save the user information in the context api and redirect...
              const userInfo = atob(accessToken.split('.')[1]);
              // dispatch: info: JSON.parse(atob(accessToken)), accessToken: full-coded-string, refreshToken: full-coded-string
              setUser(dispatch, { info: JSON.parse(userInfo), accessToken, refreshToken });


              localStorage.setItem('accessToken', accessToken);
            }).catch((error) => {
              if (error.code === 'ERR_BAD_REQUEST') {
                // invalid refresh token, clear the storage (session & local)
                sessionStorage.removeItem('refreshToken');
                localStorage.removeItem('refreshToken');
                navigate('/'); // navigate to the signin page
              } else if (error.code === 'ERR_NETWORK') {
                navigate('/error-500');
              }
              clearInterval(intervalId);
            });
          }, 840000);

          const userInfo = atob(accessToken.split('.')[1]);
          // dispatch: info: JSON.parse(atob(accessToken)), accessToken: full-coded-string, refreshToken: full-coded-string
          setUser(dispatch, { info: JSON.parse(userInfo), accessToken, refreshToken });
          // dispatch facility info
          const parsingInfo = JSON.parse(userInfo);
          localStorage.setItem('sessionId', parsingInfo.sessionId);
          socket.emit("newConnection", parsingInfo._id);
          if (parsingInfo.role === "company-admin" || parsingInfo.role === "admin" || parsingInfo.role === "editor") {
            localStorage.setItem("licence", JSON.stringify(parsingInfo.licenceInfo))
          }
          if (parsingInfo.role === "company-admin") {
            setFacility(dispatch, parsingInfo.ownedFacilities[0]);
            localStorage.setItem("facility", JSON.stringify(parsingInfo.ownedFacilities[0]))
          } else {
            setFacility(dispatch, parsingInfo.facilityInfo);
            localStorage.setItem("facility", JSON.stringify(parsingInfo.facilityInfo));
          }
          // navigate to dashboard
          // navigate('/dashboards/default');
          window.location.href = "/dashboards/default";
        }
      } catch (error) {
        console.log(error);
        if (error.code === 'ERR_BAD_REQUEST') {
          setBottomAlert({ show: true, message: error.response.data.message });
        } else if (error.code === 'ERR_NETWORK') {
          setBottomAlert({ show: true, message: "erreur côté serveur 500" });
        }
        setIsLoading(false);
        // hide the error message alert after 5 seconds
        setTimeout(() => {
          setBottomAlert((prevState) => ({ ...prevState, show: false }));
        }, 10000);
      } finally {
        setIsLoading(false);
      }
    }
    handelLoginDesktop();
  }, [window.process]);

  const [credentials, setCredentials] = useState({
    emailValue: '',
    isValidEmail: false,
    isEmailTouched: false, // is email input touched
    passValue: '',
    isValidPass: false,
    isPassTouched: false // is password input touched
  });

  useEffect(() => {
    // change page title in the header
    document.title = "se connecter";
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);


  // email input changes handler function
  const handleEmailInputChanges = (e) => {
    setCredentials((prevState) => ({
      ...prevState,
      emailValue: e.target?.value
    }));
  }

  // password input changes handler function
  const handlePasswordInputChanges = (e) => {
    setCredentials((prevState) => ({
      ...prevState,
      passValue: e.target?.value
    }));
  }

  // handle the email input (email validation process)
  const handleEmailValidation = () => {
    setCredentials((prevState) => ({
      ...prevState,
      isEmailTouched: true,
      isValidEmail: emailRegex.test(prevState.emailValue)
    }));
  }

  // handle the password input (password validation process)
  const handlePasswordValidation = () => {
    setCredentials((prevState) => ({
      ...prevState,
      isPassTouched: true,
      isValidPass: prevState.passValue.length > 7
    }));
  }

  // handle the password visibility
  const changePasswordVisibility = () => {
    setShowPass(prevState => !prevState);
  }

  //------------------------------------------------------------------------------------------------------------------------sign in button click handler function
  const handleSignIn = () => {
    setIsLoading(true);
    api.post('/login', {
      email: credentials.emailValue,
      password: credentials.passValue,
      sessionId
    }).then((response) => {
      const { accessToken, refreshToken } = response.data;
      // save the data in the local storage (or the session storage) & into the context api...
      if (rememberMe) {
        localStorage.setItem('refreshToken', refreshToken);
      } else {
        localStorage.setItem('refreshToken', refreshToken);
        sessionStorage.setItem('refreshToken', refreshToken);
      }
      localStorage.setItem('accessToken', accessToken);
      const userData = atob(accessToken.split('.')[1]);
      const parsedData = JSON.parse(userData);
      if (parsedData.role === "company-admin" || parsedData.role === "admin" || parsedData.role === "editor") {
        localStorage.setItem("licence", JSON.stringify(parsedData.licenceInfo))
      }
      if (parsedData.role === "company-admin") {
        setFacility(dispatch, parsedData.ownedFacilities[0]);
        localStorage.setItem("facility", JSON.stringify(parsedData.ownedFacilities[0]))
      } else if (parsedData.role === "admin" || parsedData.role === "editor") {
        setFacility(dispatch, parsedData.facilityInfo);
        localStorage.setItem("facility", JSON.stringify(parsedData.facilityInfo));
      }

      // update the access token each 14 minuts...
      const intervalId = setInterval(() => {
        api.get('/refresh-token', {
          headers: { 'Authorization': `Basic ${refreshToken}` }
        }).then((response) => {
          const { refreshToken, accessToken } = response.data;
          // save the user information in the context api and redirect...
          const userInfo = atob(accessToken.split('.')[1]);
          // dispatch: info: JSON.parse(atob(accessToken)), accessToken: full-coded-string, refreshToken: full-coded-string
          setUser(dispatch, { info: JSON.parse(userInfo), accessToken, refreshToken });


          localStorage.setItem('accessToken', accessToken);
        }).catch((error) => {
          if (error.code === 'ERR_BAD_REQUEST') {
            // invalid refresh token, clear the storage (session & local)
            sessionStorage.removeItem('refreshToken');
            localStorage.removeItem('refreshToken');
            navigate('/'); // navigate to the signin page
          } else if (error.code === 'ERR_NETWORK') {
            navigate('/error-500');
          }
          clearInterval(intervalId);
        });
      }, 840000);

      const userInfo = atob(accessToken.split('.')[1]);
      // dispatch: info: JSON.parse(atob(accessToken)), accessToken: full-coded-string, refreshToken: full-coded-string
      setUser(dispatch, { info: JSON.parse(userInfo), accessToken, refreshToken });
      // dispatch facility info
      const parsingInfo = JSON.parse(userInfo);
      socket.emit("newConnection", parsingInfo._id);
      if (parsingInfo.role === "company-admin" || parsingInfo.role === "admin" || parsingInfo.role === "editor") {
        localStorage.setItem("licence", JSON.stringify(parsingInfo.licenceInfo))
      }
      if (parsingInfo.role === "company-admin") {
        setFacility(dispatch, parsingInfo.ownedFacilities[0]);
        localStorage.setItem("facility", JSON.stringify(parsingInfo.ownedFacilities[0]))
      } else {
        setFacility(dispatch, parsingInfo.facilityInfo);
        localStorage.setItem("facility", JSON.stringify(parsingInfo.facilityInfo));
      }
      // navigate to dashboard
      // navigate('/dashboards/default');
      window.location.href = "/dashboards/default";
    }).catch((error) => {

      if (error.code === 'ERR_BAD_REQUEST') {
        setBottomAlert({ show: true, message: error.response.data.message });
      } else if (error.code === 'ERR_NETWORK') {
        setBottomAlert({ show: true, message: "erreur côté serveur 500" });
      }
      setIsLoading(false);
      // hide the error message alert after 5 seconds
      setTimeout(() => {
        setBottomAlert((prevState) => ({ ...prevState, show: false }));
      }, 10000);
    })
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------- JSX
  return (
    <IllustrationLayout
      showDefaultNavBar={false}
      title="Accéder à votre compte Square"
      description="Veuillez saisir votre adresse e-mail et votre mot de passe pour se conecter"
      illustration={{
        image: mapChart,
        title: '"Rejoignez-nous pour une aventure éducative"',
        description:
          "Connectez-vous pour explorer, apprendre et grandir ensemble ! Chaque connexion est une étape vers le succés.",
      }}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2} id="email-box">
          <SoftInput
            success={credentials.isEmailTouched && credentials.isValidEmail}
            error={credentials.isEmailTouched && !credentials.isValidEmail}
            onChange={(e) => {
              handleEmailInputChanges(e);
              handleEmailValidation(e);
            }
            }
            type="email"
            disabled={isLoading || isDesktop()}
            placeholder="Enter your email address"
            size="large"
            icon={{ component: <EmailIcon color="purple" id="icon-id" />, direction: "right" }}
            autoComplete="username"
            id="sign-in-email-input"
          />
        </SoftBox>
        <SoftBox mb={2} id="password-box">
          <SoftInput
            disabled={isLoading || isDesktop()}
            error={credentials.isPassTouched && !credentials.isValidPass}
            onChange={(e) => {
              handlePasswordInputChanges(e);
              handlePasswordValidation(e);
            }
            }
            type={showPass ? "text" : "password"}
            placeholder="................."
            size="large"
            icon={
              {
                component: (
                  showPass
                    ?
                    <Tooltip title="Masquer le mot de passe">
                      <VisibilityOffIcon color="purple" onClick={changePasswordVisibility} sx={{ cursor: "pointer" }} id="visibility-icon" />
                    </Tooltip>
                    :
                    <Tooltip title="Afficher le mot de passe">
                      <VisibilityIcon color="purple" onClick={changePasswordVisibility} sx={{ cursor: "pointer" }} id="visibility-icon" />
                    </Tooltip>
                ), direction: "right"
              }}
            autoComplete="current-password"
            id="sign-in-password-input"
          />
        </SoftBox>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <SoftTypography
              variant="button"
              fontWeight="regular"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none" }}
              id="remember-me-switch"
            >
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              &nbsp;&nbsp;Rester connecté{"  "}
            </SoftTypography>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end" alignItems="center">
            <SoftTypography
              component={Link}
              to="/forget-password"
              variant="button"
              color="purple"
              fontWeight="medium"
              textGradient
              id="reset-password-link"
            >
              Mot de passe oublié ?
            </SoftTypography>
          </Grid>
        </Grid>

        <SoftBox mt={4} mb={1} display="flex" justifyContent="center" alignItems="center">
          {
            isLoading
              ? <CircularProgress />
              : <SoftButton
                onClick={!isDesktop() ? handleSignIn : async() => {
                  try {
                    await shell.open('https://studiffy.com/desktop-login');
                } catch (error) {
                    console.error('Failed to open URL:', error);
                }
                }}
                variant="gradient"
                color="purple"
                style={{ width: '100%', height: '45px' }}
                fullWidth
                id="sign-in-button"
                disabled={!isDesktop() && !credentials.isValidEmail || !isDesktop() && !credentials.isValidPass}
              >
                {isDesktop() ? "Se connecter avec l'application de web" : "Se connecter"}
              </SoftButton>
          }
        </SoftBox>
        {bottomAlert.show && <SoftTypography fontWeight="bold" fontSize={15} style={{ textAlign: 'center' }} color="error" id="error-alert">{bottomAlert.message}</SoftTypography>
        }
       {isDesktop() && <SoftTypography fontWeight="bold" fontSize={15} style={{ textAlign: 'center' }} color="error" id="error-alert">Assurez-vous que votre navigateur par défaut est le même que celui dans lequel vous vous êtes connecté à Studiffy</SoftTypography>}
      </SoftBox>
    </IllustrationLayout>
  );
}

export default SignInIllustration;
