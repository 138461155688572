import React, { useState } from 'react'
import PropTypes from "prop-types";
import { Autocomplete, Checkbox, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Radio, Stack, TextField, Typography } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftBox from 'components/SoftBox';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';//import CommonComponentWeek from './CommonComponentWeek';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import SwitchButton from 'pages/planning/shared/SwitchButton';
import CommonComponentWeek from './CommonComponentWeek';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function ExamenDialog({ handleClose, classe }) {
    const [visible, setVisible] = useState(false);
    const [visibleSemaine, setVisiblSemaine] = useState(false);
    const [groupe, setGroupe] = useState("toute la classe");
    const [matiere, setMatiere] = useState("");
    const [typeSalle, setTypeSalle] = useState("");
    const [classes, setClasses] = useState([]);
    const [value, setValue] = useState(0);
    const [valueGroupe, setValueGroupe] = useState(0);


    const handleChangeGroupe = (event, newValue) => {
        setValueGroupe(newValue);
    };

    console.log(classes, groupe, matiere);
    console.log(typeSalle);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [options, setOptions] = useState([
        { value: "classe 1", label: "Classe 1" },
        { value: "classe 2", label: "Classe 2" },
        { value: "classe 3", label: "Classe 3" },
        { value: "classe 4", label: "classe 4" },
    ]);
    const [optionsSalle, setOptionsSalle] = useState([
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
    ]);
    const [optionsGroupe, setOptionsGroupe] = useState([
        { value: "toute la classe", label: "Toute la classe" },
        { value: "2 groupes", label: "2 Groupes" },
    ]);
    const [optionsMatiere, setOptionsMatiere] = useState([
        { value: "matiere 1", label: "Matiere 1" },
        { value: "matiere 2", label: "Matiere 2" },
    ]);
    const [optionsEnseignant, setOptionsEnseignant] = useState([
        { value: "enseignant 1", label: "Hassen" },
        { value: "enseignant 2", label: "Amal" },
        { value: "enseignant 3", label: "Imed" },
    ]);

    const handleSetVisible = () => setVisible(!visible);
    const handleSetVisibleSemaine = () => setVisiblSemaine(!visibleSemaine);

    return (
        <>
            <DialogTitle>Ajouter Examen</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <SoftBox mt={1.625}>
                    <Grid container spacing={3} mb={2}>
                        <Grid item xs={12} sm={6}>
                            <SoftTypography
                                sx={{ mb: 1 }}
                                component="label"
                                variant="caption"
                                fontWeight="medium"
                            >
                                Durée
                            </SoftTypography>
                            <SoftInput
                                name="Durée"
                                margin="normal"
                                id="outlined-basic"
                                label="Durée"
                                type="number"
                                defaultValue="30"
                                inputProps={{
                                    min: "30",
                                    max: "540",
                                    step: "30"
                                }}
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SoftTypography
                                sx={{ mb: 1 }}
                                component="label"
                                variant="caption"
                                fontWeight="medium"
                            >
                                Jour
                            </SoftTypography>
                            <SoftInput
                                name="Jour"
                                margin="normal"
                                id="outlined-basic"
                                label="Jour"
                                type="Text"
                                defaultValue="Lundi"
                                disabled
                                inputProps={{
                                    min: "30",
                                    max: "540",
                                    step: "30"
                                }}
                                variant="standard"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} >
                        <Grid item xs={12} sm={6}>
                            <SoftTypography
                                sx={{ mb: 1 }}
                                component="label"
                                variant="caption"
                                fontWeight="medium"
                            >
                                Début d&lsquo;examen
                            </SoftTypography>
                            <SoftInput
                                id="time-emploi"
                                type="time"
                                defaultValue="08:00"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 900,
                                }}
                                onChange={(e) => setHeureDeDebut(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SoftTypography
                                sx={{ mb: 1 }}
                                component="label"
                                variant="caption"
                                fontWeight="medium"
                            >
                                Fin d&lsquo;examen
                            </SoftTypography>
                            <SoftInput
                                id="time-emploi"
                                type="time"
                                disabled
                                defaultValue="18:00"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 900,
                                }}
                                onChange={(e) => setHeureDeFin(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    {!visibleSemaine && (<Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }} mt={2}>
                        <SoftBox
                            display="flex"
                            justifyContent={{ md: `${visible ? "flex-end" : "flex-start"}` }}
                            alignItems="center"
                            lineHeight={1}
                        >
                            <SoftBox mx={1}>
                                <SwitchButton checked={visible} onChange={handleSetVisible} />
                            </SoftBox>
                            <SoftTypography variant="caption" fontWeight="regular" sx={{ color: "#7F47E6", fontWeight: "400", fontSize: "16px" }}>
                                Examen commune pour les classes
                            </SoftTypography>
                        </SoftBox>
                    </Grid>)}
                    {!visible && (<Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }} mt={2}>
                        <SoftBox
                            display="flex"
                            justifyContent={{ md: `${visibleSemaine ? "flex-end" : "flex-start"}` }}
                            alignItems="center"
                            lineHeight={1}
                        >
                            <SoftBox mx={1}>
                                <SwitchButton checked={visibleSemaine} onChange={handleSetVisibleSemaine} />
                            </SoftBox>
                            <SoftTypography variant="caption" fontWeight="regular" sx={{ color: "#7F47E6", fontWeight: "400", fontSize: "16px" }}>
                                Toutes les semaines
                            </SoftTypography>
                        </SoftBox>
                    </Grid>)}
                    <Grid container spacing={3} mb={2} >
                        <Grid item xs={12} sm={6}>
                        </Grid>
                        {!visible && !visibleSemaine && (<Grid item xs={12} sm={6}>
                            <SoftInput
                                name="classe"
                                margin="normal"
                                id="outlined-basic"
                                label="Jour"
                                type="Text"
                                defaultValue={classe}
                                disabled
                                variant="standard"
                                placeholder="*Nom de la classe par defaut"
                            />
                        </Grid>
                        )}
                        {visible && (<Grid item xs={12} sm={12}>
                            <Stack spacing={3}>
                                <SoftBox>
                                    <SoftTypography
                                        variant="caption"
                                        fontWeight="medium"
                                        textTransform="capitalize"
                                    >
                                        Choix des classes :
                                    </SoftTypography>

                                    <Autocomplete
                                        id={`size-small-standard`}
                                        // defaultValue={classes}
                                        options={options}
                                        aria-required={true}
                                        required={true}
                                        multiple={true}
                                        clearIcon={null}
                                        getOptionLabel={(option) => option.label}
                                        onChange={(event, newValue) => {
                                            console.log(newValue);
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    checked={selected}
                                                    // You may adjust the onChange event according to your needs
                                                    // This example uses the Autocomplete's onChange event
                                                    onChange={() => {/* handle option selection */ }}
                                                />
                                                {option.label}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField placeholder="Sélectionner le nom de classe" sx={{ mb: 2 }} {...params} required={true} variant="standard" />
                                        )}
                                    />
                                </SoftBox>
                            </Stack>
                        </Grid>)}
                    </Grid>

                    {visibleSemaine && (<Box sx={{ width: '100%' }} style={{ backgroundColor: 'white', borderRadius: "16px" }}>
                        <Box >
                            <CustomTabPanel value={value} index={0} >
                                <Grid item xs={12} sm={12} mb={4}>
                                    <Stack spacing={3}>
                                        <SoftBox>
                                            <SoftTypography
                                                variant="caption"
                                                fontWeight="medium"
                                                textTransform="capitalize"
                                            >
                                                Choix des groupes:
                                            </SoftTypography>
                                            <Autocomplete
                                                id={`size-small-standard`}
                                                options={optionsGroupe}
                                                aria-required={true}
                                                required={true}
                                                clearIcon={null}
                                                getOptionLabel={(option) => option.label}
                                                value={optionsGroupe.find((option) => option.value === groupe)}
                                                onChange={(event, newValue) => {
                                                    setGroupe(newValue?.value || "");
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Radio
                                                            checked={selected}

                                                            onChange={() => {/* handle option selection */ }}
                                                        />
                                                        {option.label}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField placeholder="Sélectionner le choix de groupe" sx={{ mb: 2 }} {...params} required={true} variant="standard" />
                                                )}
                                            />
                                        </SoftBox>
                                    </Stack>
                                </Grid>
                                <Divider variant="fullWidth" style={{ backgroundColor: '#666666', width: "50%", height: '1px' }} />

                                {groupe === "toute la classe" && (
                                    <CommonComponentWeek
                                        typeSalle={typeSalle}
                                        setMatiere={setMatiere}
                                        setTypeSalle={setTypeSalle}
                                        optionsMatiere={optionsMatiere}
                                        optionsSalle={optionsSalle}
                                        optionsEnseignant={optionsEnseignant}

                                    />
                                )}
                                {groupe === "2 groupes" && (
                                    <Box mt={2}>
                                        <Tabs value={valueGroupe} onChange={handleChangeGroupe}
                                            aria-label="secondary tabs example"
                                            variant="scrollable"
                                            visibleScrollbar="false"
                                            style={{ width: '60%' }}

                                        >
                                            <Tab label="Groupe 1" style={{ borderBottom: valueGroupe === 0 ? '2px solid #7F47E6' : 'inherit' }} />
                                            <Tab label="Groupe 2" style={{ borderBottom: valueGroupe === 1 ? '2px solid #7F47E6' : 'inherit' }} />
                                        </Tabs>
                                        <CustomTabPanel value={valueGroupe} index={0}>
                                            <CommonComponentWeek
                                                typeSalle={typeSalle}
                                                setMatiere={setMatiere}
                                                setTypeSalle={setTypeSalle}
                                                optionsMatiere={optionsMatiere}
                                                optionsSalle={optionsSalle}
                                                optionsEnseignant={optionsEnseignant}
                                            />
                                        </CustomTabPanel>
                                        <CustomTabPanel value={valueGroupe} index={1}>
                                            <CommonComponentWeek
                                                typeSalle={typeSalle}
                                                setMatiere={setMatiere}
                                                setTypeSalle={setTypeSalle}
                                                optionsMatiere={optionsMatiere}
                                                optionsSalle={optionsSalle}
                                                optionsEnseignant={optionsEnseignant}
                                            />
                                        </CustomTabPanel>
                                    </Box>
                                )}
                            </CustomTabPanel>

                        </Box>
                    </Box>)}

                </SoftBox>
            </DialogContent>
            <DialogActions>
                <SoftButton onClick={handleClose} sx={{ mt: 2, ml: 2 }} variant="outlined" color="dark">Annuler</SoftButton>
                <SoftButton sx={{ mt: 2 }} variant="gradient" color="customized" >Enregistrer</SoftButton>
            </DialogActions>
        </>
    )
}
ExamenDialog.propTypes = {
    handleClose: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    classe: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};