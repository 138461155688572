import { Autocomplete, Avatar, Card, Chip, CircularProgress, FormControl, FormControlLabel, Grid, Icon, ListItem, Radio, RadioGroup, TextField, Typography, styled } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import SoftSelect from 'components/SoftSelect';
import SoftTypography from 'components/SoftTypography';
import FormField from 'layouts/pages/account/components/FormField';
import React, { useEffect, useRef, useState } from 'react'
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PropTypes from "prop-types";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import { downloadFile } from "utils/citysTn";

// react-phone-input
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from 'react-router-dom';
import { getCountryAdd } from 'utils/citysTn';
import { fetchData } from 'utils/citysTn';
import Swal from 'sweetalert2';
import api from 'utils/axios';
import { ErrorMessage, Formik } from 'formik';
import validations from '../../schema/validations';
import EnterpriseFormField from 'pages/enterprise/schema/enterprise-schemas/EnterpriseFormField';

export default function ResponsableB2C({ client, title }) {
  const [info, setInfo] = useState({});
  const [data, setData] = useState([]);
  const [logoToDelete, setLogoToDelete] = useState(null);
  const [cin, setCin] = useState("");
  const [siteWeb, setSiteWeb] = useState("");
  const [occupation, setOccupation] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [bank, setBank] = useState("");
  const [rib, setRib] = useState("");
  const [birthday, setBirthday] = useState("");
  const [cnssNumber, setCnssNumber] = useState("");
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
  const [passeport, setPasseport] = useState("");
  const [comment, setComment] = useState("");
  const [country, setCountry] = useState(client?.country || "");
  const [city, setCity] = useState(client?.country || "");
  const [logo, setLogo] = useState(null);
  const [logoUpdate, setLogoUpdate] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [files, setFiles] = useState([])
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const initialValues = {
    firstName: client.firstName || "",
    lastName: client.lastName || "",
    email: client.email || "",
    gender: client.gender || "",
    phone: client.phoneNumber || "",
  };

  useEffect(() => {
    // Initialize client
    if (client) {
      setCin(client.cin);
      setSiteWeb(client.siteWeb);
      setOccupation(client.occupation);
      setAddress(client.address);
      setZipCode(client.zipCode);
      setRib(client.rib);
      setBank(client.bank);
      setBirthday(client.birthday);
      setSecondaryPhoneNumber(client.secondaryPhoneNumber);
      setCnssNumber(client.cnssNumber);
      setComment(client.comment);
      setPasseport(client.passeport);
      setCountry(client.country);
      setCity(client.city);
      setFiles(client.files)
      setDataToSend({ country: client.country, city: client.city })
    }
  }, [client]);
  const fileInputRef = useRef(null);
  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };
  const countries = getCountryAdd(info, data);
  const [dataToSend, setDataToSend] = useState({
    country: "",
    city: "",
  });
  const handleCountryChange = (selectedOption) => {
    const selectedCountry = selectedOption.value;
    const cities = data.find((country) => country.country === selectedCountry).cities;

    setDataToSend({ ...dataToSend, country: selectedCountry });
    setCityOptions(cities.map((city) => ({ value: city, label: city })));
  };

  const handleCityChange = (selectedOption) => {
    setDataToSend({ ...dataToSend, city: selectedOption.value });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    const newOption = { value: file, label: file.name };

    // Check if the file is already selected
    const isSelected = selectedOptions.some((option) => option.label === newOption.label);

    if (!isSelected) {
      // If it's not selected, add it to both options and selectedOptions
      setOptions([...options, newOption]);
      setSelectedOptions([...selectedOptions, newOption]);
    }
  };
  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // Specify the allowed file extensions
      const allowedExtensions = ["jpg", "jpeg", "png", "gif", "jfif"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        // Display an error message if the file extension is not allowed
        setErrorMessage("Format de fichier non pris en charge. Veuillez sélectionner un fichier JPG, JPEG, PNG ou GIF.");
        setLogoUpdate(null); // Clear the logo
        return;
      }

      // Reset the error message if the file extension is allowed
      setErrorMessage("");
      setLogoUpdate(selectedFile);

      // Specify the maximum allowed file size in bytes (e.g., 2MB)
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      const maxWidth = 650; // Maximum width in pixels
      const maxHeight = 650; // Maximum height in pixels

      if (selectedFile.size > maxSize) {
        // Display an error message if the file size exceeds the limit
        setErrorMessage("La taille du fichier est trop grande. La taille maximale autorisée est de 2 Mo.");
        setLogoUpdate(null); // Clear the logo
      } else {
        // Check image dimensions
        const img = new Image();
        img.src = URL.createObjectURL(selectedFile);

        img.onload = () => {
          if (img.width > maxWidth || img.height > maxHeight) {
            // Display an error message if the image dimensions exceed the limit
            setErrorMessage(
              `Les dimensions de l'image sont trop grandes. Les dimensions maximales autorisées sont de ${maxWidth}x${maxHeight} pixels.`
            );
            setLogoUpdate(null); // Clear the logo
          }
        };
      }
    }
  };
  const handleDeleteLogo = () => {
    // Handle deleting the logo here
    // You can implement this function as needed
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setLogoUpdate(null);
    setLogo(null);
    setLogoToDelete(client?.imageUrl);
    if (logoUpdate === null) {
    }
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleFormSubmit = async (values) => {
    setLoading(true)
    try {
      const form = new FormData();
      form.append("imageUrl", logoUpdate ? logoUpdate : logo);
      form.append("firstName", values.firstName);
      form.append("lastName", values.lastName);
      form.append("email", values.email);
      form.append("cin", cin);
      form.append("siteWeb", siteWeb);
      form.append("occupation", occupation);
      form.append("address", address);
      form.append("zipCode", zipCode);
      form.append("bank", bank);
      form.append("rib", rib);
      form.append("birthday", birthday);
      form.append("cnssNumber", cnssNumber);
      form.append("phoneNumber", values.phone);
      form.append("secondaryPhoneNumber", secondaryPhoneNumber);
      form.append("gender", values.gender);
      form.append("passeport", passeport);
      form.append("comment", comment);
      form.append("country", dataToSend.country ? dataToSend.country : country);
      form.append("city", dataToSend.city ? dataToSend.city : city);
      logoToDelete && form.append("imageUrlToDelete", logoToDelete);
      selectedOptions &&
        selectedOptions.length > 0 &&
        selectedOptions.forEach((item) => {
          form.append("files", item.value);
        });
      form.append("filesToDelete", JSON.stringify(filesToDelete));
      const response = await api.patch(`/userUpdate/${client._id}`, form);
      if (response.status === 200) {
        Swal.fire("success !", "Mise à jour responsable réussie", "success").then(() => {
          setLoading(false)
          navigate(0);
        });
      }
    } catch (error) {
      setLoading(true)
      if (error.response && error.response.data && error.response.data.message) {
        Swal.fire("Error", error.response.data.message, "error").then(() => navigate(0));
      } else {
        Swal.fire("Error", "Une erreur s'est produite. Veuillez réessayer ultérieurement.", "error").then(() => navigate(0));
      }
    }
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      const data = await fetchData();
      setData(data);
    };
    fetchDataAsync();
  }, []);

  const handleDeleteFile = (fileToDelete) => {
    // Remove the file from the 'files' array
    const updatedFiles = files.filter(file => file !== fileToDelete);

    // Add the file to the 'filesToDelete' array
    setFilesToDelete([...filesToDelete, fileToDelete]);

    // Update the 'files' state
    setFiles(updatedFiles);
  };
  const currentDate = new Date();
  const monthsToSubtract = 1; // Set the number of months to subtract
  const minDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - monthsToSubtract,
    currentDate.getDate()
  );
  const minDateString = minDate.toISOString().split("T")[0];
  return (
    <>
      {
        Object.keys(client).length > 0 && (
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={validations[0]}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit
            }) => (
              <form onSubmit={handleSubmit}>
                <Card id="basic-info" sx={{ overflow: "visible" }}>
                  <SoftBox p={3}>
                    <SoftBox sx={{ display: "flex", justifyContent: "space-between" }}>
                      <SoftTypography variant="h5">Informations responsable B2C</SoftTypography>
                    </SoftBox>
                    <Grid item xs={12} sm={13} container justifyContent="center">
                      <SoftBox position="relative" height="max-content" mx="auto"  >
                        <Avatar
                          id="img-profile"
                          onClick={handleAvatarClick}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          sx={{
                            filter: isHovered ? "blur(2px)" : "none",
                            cursor: "pointer",
                            width: 100,
                            height: 100,
                            borderRadius: "16px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          src={
                            !logo && !logoUpdate && !logoToDelete
                              ? `${process.env.REACT_APP_BASE_URL}/${client?.imageUrl}`
                              : logoUpdate
                                ? URL.createObjectURL(logoUpdate)
                                : ""
                          }
                          alt={title}
                          size="xl"
                          variant="rounded"
                        />

                        <SoftBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                          <SoftButton
                            id="btn-delete"
                            onClick={handleDeleteLogo}
                            variant="gradient"
                            color="error"
                            size="small"
                            iconOnly
                          >
                            <Icon>delete</Icon>
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                    <input
                      id="img-client"
                      type="file"
                      // Specify the accepted file types
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={onFileChange}
                      accept=".jpg, .jpeg, .png, .gif .jfif"
                    />
                    {errorMessage && (
                      <Typography align="center" sx={{ mt: 2 }} variant="body2" color="error">
                        {errorMessage}
                      </Typography>
                    )}
                  </SoftBox>

                  <SoftBox component="form" pb={3} px={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <EnterpriseFormField
                          id="firstname-responsable"
                          label="Nom*"
                          placeholder="Nom"
                          inputProps={{
                            minLength: 2,
                            maxLength: 30
                          }}
                          defaultValue={values.firstName}
                          value={values.firstName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="firstName"
                          error={!!touched.firstName && !!errors.firstName}
                          success={values.firstName?.length > 0 && !errors.firstName}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <EnterpriseFormField
                          id="lastname-responsable"
                          label="Prénom*"
                          placeholder="Prénom"
                          inputProps={{
                            minLength: 2,
                            maxLength: 30
                          }}
                          defaultValue={values.lastName}
                          value={values.lastName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="lastName"
                          error={!!touched.lastName && !!errors.lastName}
                          success={values.lastName?.length > 0 && !errors.lastName}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Date de Naissance
                        </SoftTypography>
                        <SoftInput
                          id="birthday-client"
                          sx={{ mb: 1 }}
                          type="date"
                          inputProps={{
                            max: minDateString,
                          }}
                          defaultValue={birthday}
                          value={birthday}
                          onChange={(e) => setBirthday(e.target.value)}
                        />
                      </Grid>{" "}
                      <Grid item xs={12} sm={6}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <SoftTypography
                            sx={{ mb: 1 }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Genre*
                          </SoftTypography>
                          <RadioGroup
                            id="gender-client"
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            value={values.gender}
                            defaultValue={values.gender}
                            name="gender"
                            onChange={(e) => {
                              handleChange({ target: { name: 'gender', value: e.target.value } });
                            }}
                            error={!!touched.gender && !!errors.gender}
                            success={values.gender?.length > 0 && !errors.gender}
                          >
                            <FormControlLabel value="femme" control={<Radio />} label="Femme" />
                            <FormControlLabel value="homme" control={<Radio />} label="Homme" />
                          </RadioGroup>{" "}
                        </FormControl>
                      </Grid></Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} >
                        <EnterpriseFormField
                          id="email-client"
                          label="Email*"
                          placeholder="Email"
                          inputProps={{ type: "email", maxLength: 50 }}
                          defaultValue={values.email}
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="email"
                          error={!!touched.email && !!errors.email}
                          success={values.email?.length > 0 && !errors.email}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <EnterpriseFormField
                          id="cin-client"
                          type="number"
                          label="Cin"
                          name="cin"
                          placeholder="Cin"
                          defaultValue={cin}
                          value={cin}
                          inputProps={{
                            maxLength: 20
                          }}
                          onChange={(e) => setCin(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Téléphone*
                        </SoftTypography>
                        <PhoneInput
                          id="telephone-client"
                          country={"tn"}
                          enableSearch
                          inputStyle={{
                            width: "100%",
                          }}
                          defaultValue={values.phone}
                          value={values.phone}
                          onChange={(value) => {
                            handleChange({ target: { name: 'phone', value } });
                          }}
                          name="phone"
                          error={!!touched.phone && !!errors.phone}
                          success={values.phone?.length > 0 && !errors.phone}
                        />
                        <SoftBox mt={0.75}>
                          <SoftTypography component="div" variant="caption" color="error">
                            <ErrorMessage name={"phone"} />
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Deuxième téléphone
                        </SoftTypography>
                        <PhoneInput
                          id="second-phone-client"
                          country={"tn"}
                          enableSearch
                          inputStyle={{
                            width: "100%",
                          }}
                          defaultValue={secondaryPhoneNumber}
                          value={secondaryPhoneNumber}
                          onChange={(value) => setSecondaryPhoneNumber(value)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} mb={1}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          id="passeport-client"
                          label="Passeport"
                          placeholder="Passeport"
                          defaultValue={passeport}
                          value={passeport}
                          onChange={(e) => setPasseport(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          id="siteweb-client"
                          label="site web"
                          placeholder="Site web"
                          defaultValue={siteWeb}
                          value={siteWeb}
                          onChange={(e) => setSiteWeb(e.target.value)}
                          inputProps={{
                            maxLength: 100
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} mb={2} >
                      <Grid item xs={12} sm={6}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Pays
                        </SoftTypography>
                        <SoftSelect
                          id="country-client"
                          value={{ value: dataToSend.country, label: dataToSend.country }}
                          options={countries?.map((country) => ({ value: country, label: country }))}
                          onChange={handleCountryChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Ville
                        </SoftTypography>
                        <SoftSelect id="city-client" options={cityOptions}
                          value={{ value: dataToSend.city, label: dataToSend.city }} onChange={handleCityChange} />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          id="adresse-client"
                          label="Adresse"
                          placeholder="Adresse"
                          defaultValue={address}
                          value={address}
                          inputProps={{
                            maxLength: 50
                          }}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          id="code-postal-client"
                          type="number"
                          label="Code postal"
                          placeholder="Code postal"
                          defaultValue={zipCode}
                          value={zipCode}
                          onChange={(e) => setZipCode(e.target.value)}
                        />
                      </Grid>{" "}

                    </Grid>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          id="bank-client"
                          label="banque"
                          placeholder="Banque"
                          defaultValue={bank}
                          value={bank}
                          inputProps={{
                            maxLength: 30
                          }}
                          onChange={(e) => setBank(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          id="rib-client"
                          type="number"
                          label="rib"
                          placeholder="rib"
                          defaultValue={rib}
                          value={rib}
                          inputProps={{
                            maxLength: 20
                          }}
                          onChange={(e) => setRib(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} mb={1}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          id="num-cnss-client"
                          type="number"
                          label="Numéro CNSS"
                          placeholder="Numéro CNSS"
                          defaultValue={cnssNumber}
                          value={cnssNumber}
                          inputProps={{
                            maxLength: 30
                          }}
                          onChange={(e) => setCnssNumber(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          id="occupation-client"
                          label="occupation"
                          placeholder="occupation"
                          defaultValue={occupation}
                          value={occupation}
                          inputProps={{
                            maxLength: 30
                          }}
                          onChange={(e) => setOccupation(e.target.value)}
                        />
                      </Grid>

                    </Grid>
                    <Grid sx={{ mt: 2, mr: "auto", display: "flow" }} item xs={12}>
                      <SoftButton
                        sx={{ mb: 1 }}
                        component="label"
                        onChange={handleFileUpload}
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                      >
                        Télécharger des fichiers
                        <VisuallyHiddenInput type="file" />
                      </SoftButton>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        placeholder="Télécharger des fichiers"
                        options={options}
                        value={selectedOptions}
                        filterSelectedOptions
                        freeSolo
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" placeholder="Télécharger des fichiers" />
                        )}
                        onChange={(event, newValue) => {
                          setSelectedOptions(newValue);
                        }}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} mt={1}>
                      {files && files.length > 0 ? (
                        files.map((file, index) => (
                          <Grid
                            key={index}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              listStyle: "none",
                              p: 0.5,
                              m: 0,
                            }}
                            component="ul"
                          >
                            <ListItem >
                              <Chip size="small" label={file} onDelete={() => handleDeleteFile(file)} />
                              <BrowserUpdatedIcon
                                onClick={() => downloadFile(file.name ? file.name : file)}
                                fontSize="default"
                                sx={{ cursor: "pointer", marginLeft: 0.5 }}
                              />
                            </ListItem>
                          </Grid>
                        ))
                      ) : (
                        <Typography sx={{ p: 1 }} variant="body2">
                          Aucun fichier
                        </Typography>
                      )}
                    </Grid>
                    <Grid container spacing={3} >
                      <Grid item xs={12} md={12} mt={1}>
                        <FormField
                          id="description-client"
                          multiline
                          rows={5}
                          label="description"
                          placeholder="description"
                          defaultValue={comment}
                          value={comment}
                          inputProps={{
                            maxLength: 250
                          }}
                          onChange={(e) => setComment(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <Grid item
                    xs={12}
                    ml={3}
                    sx={{ mr: 2 }}
                    mb={2}
                    display={"flex"}
                    justifyContent={"space-between"}>
                    <SoftButton onClick={() => navigate("/administration/responsables")} color="dark" variant="outlined" size="small">Annuler</SoftButton>
                    {loading ? <CircularProgress /> : <SoftButton type="submit" sx={{ ml: 2 }} color="purple" variant="gradient" size="small">Modifier</SoftButton>}
                  </Grid>
                </Card>
              </form>
            )}
          </Formik>
        )}
    </>
  )
}
ResponsableB2C.propTypes = {
  title: PropTypes.string.isRequired,
  client: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

