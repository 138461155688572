import { DialogActions, DialogContent, DialogTitle, IconButton, Grid, Autocomplete, TextField, Typography, ListItem, Chip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';
import PropTypes from "prop-types";
import { useSoftUIController } from 'context';
import styled from "@emotion/styled";
import FormField from 'layouts/pages/account/components/FormField';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from 'utils/axios';
import * as Yup from 'yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Line } from 'rc-progress';
import Box from '@mui/material/Box';

export default function UpdateCours({ onClose, selectedCours, handleFetch }) {
    const [loading, setIsLoading] = useState(false);
    const { id, subjectId } = useParams();
    const facility = JSON.parse(localStorage.getItem("facility"));
    const [controller] = useSoftUIController();
    const [files, setFiles] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);
    const [fileProgress, setFileProgress] = useState({});
    const initialValues = {
        titre: selectedCours?.title || "",
        description: selectedCours?.description || "",
        selectedOptions: [],
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            titre: Yup.string().required('Titre obligatoire.'),
            description: Yup.string().required('Description obligatoire.'),
        }),
        onSubmit: async (values) => {
            const form = new FormData();
            try {
                form.append("title", values?.titre);
                form.append("description", values?.description);
                form.append("subjectId", subjectId);
                form.append("instructorId", controller?.user?.info?._id);
                form.append("classeId", id);
                values?.selectedOptions &&
                    values?.selectedOptions?.length > 0 &&
                    values?.selectedOptions?.forEach((item) => {
                        form.append("support", item.value);
                    });
                filesToDelete &&
                    filesToDelete?.length > 0 &&
                    filesToDelete?.forEach((item) => {
                        form.append("filesToDelete", item);
                    });
                setIsLoading(true);
                const data = await api.patch(`training-company/update-lesson/${selectedCours?._id}`, form);
                if (data.status === 200) {
                    Swal.fire("Succès !", "Le cours a été modifié avec succès", "success").then(() => {
                        handleFetch();
                        setIsLoading(false);
                        onClose();
                    });
                }
            } catch (error) {
                setIsLoading(false);
                if (error.response && error.response.data && error.response.data.message) {
                    Swal.fire("Error!", error.response.data.message, "error");
                } else {
                    Swal.fire("Error!", "Une erreur s'est produite. Veuillez réessayer ultérieurement.", "error");
                }
            }
        },
    });

    useEffect(() => {
        if (selectedCours) {
            setFiles(selectedCours?.studyMaterials);
        }
    }, [selectedCours]);

    const handleDeleteFile = (fileToDelete) => {
        const updatedFiles = files.filter((file) => file !== fileToDelete);
        setFilesToDelete([...filesToDelete, fileToDelete]);
        setFiles(updatedFiles);
    };

    const handleFileUpload = (event) => {
        const files = event.target.files;
        const newOptions = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const newOption = { value: file, label: file.name };

            const isSelected = formik.values.selectedOptions.some((option) => option.label === newOption.label);

            if (!isSelected) {
                newOptions.push(newOption);
            }
        }

        if (newOptions.length > 0) {
            formik.setFieldValue('selectedOptions', [...formik.values.selectedOptions, ...newOptions]);
            newOptions.forEach((option) => {
                uploadFile(option);
            });
        }
    };

    const uploadFile = (fileOption) => {
        const formData = new FormData();
        formData.append('support', fileOption.value);
        const config = {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setFileProgress((prevProgress) => ({
              ...prevProgress,
              [fileOption.label]: { progress: percentCompleted, status: 'uploading' }
            }));
          }
        };
    
        api.post('training-company/upload-file', formData, config)
          .then((response) => {
            console.log(response)
            setFileProgress((prevProgress) => ({
              ...prevProgress,
              [fileOption.label]: { progress: 100, status: 'success' }
            }));
          })
          .catch((error) => {
            setFileProgress((prevProgress) => ({
              ...prevProgress,
              [fileOption.label]: { progress: 0, status: 'error' }
            }));
          });
      };

    const deleteFile = (fileLabel) => {
        formik.setFieldValue('selectedOptions', formik.values.selectedOptions.filter(option => option.label !== fileLabel));
        setFileProgress(prevProgress => {
            const newProgress = { ...prevProgress };
            delete newProgress[fileLabel];
            return newProgress;
        });
    };

    const VisuallyHiddenInput = styled("input")({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1,
    });
    const isAllFilesUploaded = formik.values.selectedOptions.every(
        (fileOption) => fileProgress[fileOption.label]?.progress === 100
      );
    function LinearProgressWithLabel(props) {
        const { value, status, fileLabel, onDelete,disabled } = props;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <Line percent={value} strokeWidth={1} strokeColor={status === 'success' ? 'green' : status === 'error' ? 'red' : 'blue'} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              {status === 'uploading' && (
                <Typography variant='body2' color='text.secondary'>
                  {`${Math.round(value)}%`}
                </Typography>
              )}
              {status === 'success' && <CheckCircleIcon  style={{ color: 'green' }} />}
              {status === 'error' && <CancelIcon style={{ color: 'red' }} />}
            </Box>
            <Box sx={{ minWidth: 35 }}>
            <CancelIcon onClick={() => !disabled && onDelete(fileLabel)} style={{ cursor: disabled ? 'default' : 'pointer', color: disabled ? 'gray' : 'inherit' }} />
            </Box>
          </Box>
        );
      }
    
      LinearProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        fileLabel: PropTypes.string.isRequired,
        onDelete: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired
    
      };

    return (
        <>
            <DialogTitle>Modifier Cours </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                    "&:hover": {
                        backgroundColor: (theme) => theme.palette.grey[200],
                    },
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <SoftBox p={3}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid item xs={12} sm={6}>
                            <FormField
                                id="titre"
                                label="Titre"
                                placeholder="Titre"
                                name="titre"
                                disabled={loading}
                                value={formik.values.titre}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.titre && Boolean(formik.errors.titre)}
                                helperText={formik.touched.titre && formik.errors.titre}
                                inputProps={{
                                    maxLength: 50
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} mt={2}>
                            <FormField
                                id="description"
                                multiline
                                rows={5}
                                disabled={loading}
                                label="Description"
                                placeholder="Description"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                        </Grid>
                        <Grid sx={{ mt: 2, mr: "auto", display: "flow" }} item xs={12}>
                            <SoftButton
                                sx={{ mb: 1 }}
                                component="label"
                                onChange={handleFileUpload}
                                variant="contained"
                                startIcon={<CloudUploadIcon />}
                                disabled={loading}
                            >
                            Télécharger des fichiers
                                <VisuallyHiddenInput type="file" multiple />
                            </SoftButton>
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                options={formik.values.selectedOptions}
                                value={formik.values.selectedOptions}
                                filterSelectedOptions
                                freeSolo
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder="Télécharger des fichiers"
                                    />
                                )}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue('selectedOptions', newValue);
                                }}
                                getOptionLabel={(option) => option?.label}
                                //isOptionEqualToValue={(option, value) => option?.label === value?.label}
                                disabled={loading}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mt={1}>
                            {files && files?.length > 0 && (
                                files?.map((file, index) => (
                                    <Grid
                                        key={index}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexWrap: "wrap",
                                            listStyle: "none",
                                            p: 0.5,
                                            m: 0,
                                        }}
                                        component="ul"
                                    >
                                        <ListItem>
                                            <Chip
                                              disabled={loading}
                                                size="small"
                                                label={file}
                                                onDelete={() => handleDeleteFile(file)}
                                            />
                                        </ListItem>
                                    </Grid>
                                ))
                            ) }
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            {formik.values.selectedOptions.map((fileOption, index) => (
                                <Box key={index} sx={{ mb: 2 }}>
                                    <Typography variant="body2">{fileOption.label}</Typography>
                                    <LinearProgressWithLabel
                                          value={fileProgress[fileOption.label]?.progress || 0}
                                          status={fileProgress[fileOption.label]?.status || 'uploading'}
                                          fileLabel={fileOption.label}
                                          onDelete={deleteFile}
                                          disabled={loading}
                                    />
                                </Box>
                            ))}
                        </Grid>
                    </form>
                </SoftBox>
            </DialogContent>
            <DialogActions>
                <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                    <SoftButton
                        onClick={onClose}
                        sx={{ mt: 2 }}
                        variant="outlined"
                        color="dark"
                        size="small"
                    >
                        Annuler
                    </SoftButton>
                    <SoftButton
                        type="submit"
                        onClick={formik.handleSubmit}
                        sx={{ mt: 2 }}
                        variant="gradient"
                        color="purple"
                        size="small"
                        disabled={loading || !isAllFilesUploaded}
                    >
                        Modifier
                    </SoftButton>
                </SoftBox>
            </DialogActions>
        </>
    );
}

UpdateCours.propTypes = {
    onClose: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    handleFetch: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    selectedCours: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};