import PropTypes from "prop-types";

// @mui material components
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AddTask, Delete, Edit, MoreVert, RemoveRedEye } from "@mui/icons-material";
import { useState } from "react";
import SoftTypography from "components/SoftTypography";
import { useSoftUIController } from "context";

// Custom styles for ComplexProjectCard
function ComplexProjectCard({
  id,
  image,
  nomEvent,
  categorie,
  date,
  participant,
  capacite,
  dateDebut,
  onAddParticipant,
  onDelete,
}) {

  const navigate = useNavigate();
  const [controller, _] = useSoftUIController();

  const handleDeleteClick = () => {
    onDelete(); // Call onDelete function
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if(capacite === undefined || capacite === null) {
    capacite = 0;
  }

  return (
    <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <IconButton onClick={handleClick} sx={{ position: "absolute", top: "10px", right: "10px" }}>
        <MoreVert />
      </IconButton>
      <CardMedia
        component="img"
        height="194"
        image={`${process.env.REACT_APP_BASE_URL}/${image}`}
        alt="Image Event"
        loading="lazy"
      />
      <CardContent sx={{ flex: "1 0 auto" }}>
        <Typography variant="body2" color="text.secondary">
          Nom : {nomEvent}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Categorie : {categorie}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Date Event : {date}
        </Typography>
      </CardContent>
      <CardActions sx={{ pl: "15px", justifyContent: "space-between" }}>
        {controller.user.info.role === "student"  && new Date(dateDebut) >= new Date() && participant <= capacite  && (
          <Tooltip title="Participer">
            <IconButton onClick={onAddParticipant}>
              <AddTask sx={{ color: "green" }} />
            </IconButton>
          </Tooltip>
        )}
        <Box
          sx={{
            ml: controller.user.info.role !== "student" && "auto",
            pb: controller.user.info.role !== "student" && "10px",
          }}
        >
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="medium"
            color="text"
            sx={{ pr: "15px" }}
          >
            Nombre des Participants : {participant}
          </SoftTypography>
        </Box>
      </CardActions>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => navigate(`/events/listEvents/voirEvent/${id}`)}>
          <RemoveRedEye /> &nbsp; Voir plus
        </MenuItem>
        {(controller.user.info.role === "admin" || controller.user.info.role === "editor"  || controller.user.info.role === "company-admin" )&& (
          <Box>
            {/* <MenuItem onClick={() => navigate(`/events/listEvents/updateEvent/${id}`)}>
              <Edit /> &nbsp; Modifier
            </MenuItem> */}
            <MenuItem onClick={handleDeleteClick}>
              <Delete />
              &nbsp; Supprimer
            </MenuItem>
          </Box>
        )}
      </Menu>
    </Card>
  );
}

// Typechecking props for the ProfileInfoCard
ComplexProjectCard.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  nomEvent: PropTypes.string,
  categorie: PropTypes.string,
  date: PropTypes.string,
  participant: PropTypes.number,
  capacite: PropTypes.number, 
  dateDebut:PropTypes.string,
  onAddParticipant: PropTypes.func,
  onDelete: PropTypes.func,
};
export default ComplexProjectCard;
