import Close from "@mui/icons-material/Close";
import { Box, Checkbox, FormControlLabel, Grid, IconButton, Modal, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import FormField from "pages/billing-settings/components/FormField";
import PropTypes from "prop-types";
import { useState } from "react";

function Payee({open, handleCloseModal}){
    const [typePaiement, setTypePaiement] = useState("Espèce");
    return(
        <Modal
            open={open}
            onClose={handleCloseModal}
            sx={{
                "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                bgcolor: "rgba(0,0,0,0.03) !important",
                },
            }}
            >
            <Box
                sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "20%",
                height: typePaiement === "Espèce" ? "36%" : typePaiement === "Chèque" ? "55%" : "38%",
                overflowY: "auto",
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow: "2px 2px 2px 2px grey",
                p: "24px 32px",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography variant="h5" sx={{ flexGrow: "1" }}>
                        Ajouter Paiement
                    </Typography>                
                    <IconButton onClick={handleCloseModal}>
                        <Close
                        fontSize="24px !important"
                        sx={{
                            "&:hover": {
                            bgcolor: grey[100],
                            borderRadius: "50%",
                            },
                        }}
                        />
                    </IconButton>
                </Box>
                <Grid item xs={12}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                        >
                        {"Type de paiement"}
                        </SoftTypography>
                    </SoftBox>
                    <SoftSelect
                        // key={`paiementType-${index}-${uuidv4()}`}
                        options={['Espèce', 'Chèque', 'Virement bancaire'].map(article => ({ value: article, label: article }))}
                        defaultValue={{ label : 'Espèce' }}
                        // onChange={(e) => {
                        //   const newPaiementType = e.value;
                        //   setPurchase((prevState) => {
                        //     const newPurchase = prevState.deepCopy();
                        //     newPurchase.type = newPaiementType;
                        //     setPurchasesGlobaly(dispatch, { actionType: 'modifiy', index, newValue: newPurchase });
                        //     return newPurchase;
                        //   });
                        //   changePurchaseInput(index, "type", newPaiementType);
                        // }}
                        onChange={(e)=>{
                            setTypePaiement(e.value);
                        }}
                    />
                </Grid>
                {
                    typePaiement === "Chèque" &&
                    <Box>
                        <Grid item xs={12} mt="16px">
                            <FormField
                                // key={`unit-price-ht-${index}-${uuidv4()}`}
                                label="N° chèque"
                                inputProps={{ type: "number", min: 0 }}
                                defaultValue={0}
                                onBlur = { (e) => {
                                    // const newUnitPrice = +e.target.value;
                                    // setPurchase(prevState => {
                                    // const newPurchase = prevState.deepCopy();
                                    // newPurchase.unitPriceHT = newUnitPrice;
                                    // setPurchasesGlobaly(dispatch, { actionType: 'modifiy', index, newValue: newPurchase });
                                    // return newPurchase;
                                    // });
                                    // changePurchaseInput(index, "quantity", 1);
                                    // changePurchaseInput(index, "unitPriceHT", newUnitPrice);
                                }}
                                />
                        </Grid>
                        <Grid item xs={12} mt="16px">
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                                >
                                {"Versable/Non"}
                                </SoftTypography>
                            </SoftBox>
                            <SoftSelect 
                                // key={`paiementType-${index}-${uuidv4()}`}
                                options={["Versable", "Non versable"].map(vers => ({ value: vers, label: vers }))}
                                defaultValue={{ label : 'Versable' }}
                                // onChange={(e) => {
                                //   const newPaiementType = e.value;
                                //   setPurchase((prevState) => {
                                //     const newPurchase = prevState.deepCopy();
                                //     newPurchase.type = newPaiementType;
                                //     setPurchasesGlobaly(dispatch, { actionType: 'modifiy', index, newValue: newPurchase });
                                //     return newPurchase;
                                //   });
                                //   changePurchaseInput(index, "type", newPaiementType);
                                // }}
                                onChange={(e)=>{
                                setTypePaiement(e.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} mt="16px" ml="16px">
                            <FormControlLabel control={<Checkbox />} label="Chèque de garantie" />
                        </Grid>
                    </Box>
                }

                {
                    typePaiement === "Virement bancaire" &&
                    <Box>
                        <Grid item xs={12} mt="16px">
                            <FormField
                                // key={`unit-price-ht-${index}-${uuidv4()}`}
                                label="N° virement"
                                inputProps={{ type: "number", min: 0 }}
                                defaultValue={0}
                                onBlur = { (e) => {
                                    // const newUnitPrice = +e.target.value;
                                    // setPurchase(prevState => {
                                    // const newPurchase = prevState.deepCopy();
                                    // newPurchase.unitPriceHT = newUnitPrice;
                                    // setPurchasesGlobaly(dispatch, { actionType: 'modifiy', index, newValue: newPurchase });
                                    // return newPurchase;
                                    // });
                                    // changePurchaseInput(index, "quantity", 1);
                                    // changePurchaseInput(index, "unitPriceHT", newUnitPrice);
                                }}
                                />
                        </Grid>
                    </Box>
                }
                <Box sx={{ mt:typePaiement ==="Espèce" ? "74px" : "16px" , display:"flex", justifyContent:"flex-end"}}>
                    <SoftButton variant="gradient" color="success">Payer</SoftButton>
                </Box>
            </Box>
        </Modal>
    )
}

Payee.propTypes = {
    open: PropTypes.bool,
    handleCloseModal: PropTypes.func,
};

export default Payee;