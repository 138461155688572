import { CircularProgress, Grid, Menu, MenuItem } from '@mui/material'
import SoftBox from 'components/SoftBox'
import PlaceholderCard from 'examples/Cards/PlaceholderCard'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'layouts/DashboardNavbar'
import Header from 'layouts/pages/profile/components/Header'
import React, { useEffect, useState } from 'react'
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import emploiImg from "assets/images/emploi.png"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom'
import CardPlanning from '../shared/CardPlanning'
import api from 'utils/axios'
import SoftPagination from 'components/SoftPagination'
import Swal from 'sweetalert2'

export default function ListCardEmploi() {
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEmploiId, setSelectedEmploiId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle
  const itemsPerPage = 8; // Nombre d'articles par page
  const facility = JSON.parse(localStorage.getItem("facility"));
  const totalPages = Math.ceil(data.length / itemsPerPage);
  // Extraction des données de la page actuelle
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);
  // TeamProfileCard dropdown menu handlers
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const navigate = useNavigate()
  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={() => navigate(`/planning/emplois/view/${selectedEmploiId}`)}> <RemoveRedEyeIcon />   &nbsp; Voir plus</MenuItem>
      <MenuItem onClick={() => navigate(`/planning/emplois/${selectedEmploiId}`)}> <EditIcon />  &nbsp; Modifier</MenuItem>
      <MenuItem onClick={showAlert}> <DeleteIcon />&nbsp;   Supprimer</MenuItem>
    </Menu>
  );

  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(
              `/delete-planing/${selectedEmploiId}`
            );
            if (response) {
              (await Swal.fire("Supprimé !", "L'emploi a été supprimé.", "success"))
                .isConfirmed && handleFetch();
              closeSlackBotMenu();
            }
          } catch (error) {
            Swal.fire("Une erreur s'est produite !", "L'emploi n'a pas été supprimé.", "error");
            closeSlackBotMenu();
          }
        }
      });
  };
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  // Fonction pour passer à la page précédente
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleFetch = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/all-planings/${facility?._id}`);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };
  useEffect(() => {
    // change page title in the header
    document.title = "emploi";
  }, []);
  useEffect(() => {
    handleFetch();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title={"Liste des Emplois"} searchVisible={true} ExportVisible={true} />
      {loading ? (
        <SoftBox
          sx={{
            mt: 13,
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </SoftBox>
      ) : (
        <>
          <SoftBox pb={2}>
            <SoftBox mt={{ xs: 1, lg: 2 }} mb={1}>
              <Grid container spacing={3}>
                <Grid
                  id="add-emploi"
                  onClick={() => navigate("/planning/emplois/add-emploi")}
                  item
                  xs={12}
                  md={20}
                  lg={4}
                  sx={{ cursor: "pointer" }}
                >
                  <PlaceholderCard
                    title={{ variant: "h5", text: "Ajouter Emploi" }}
                  ></PlaceholderCard>
                </Grid>
                {currentData &&
                  currentData?.map((emploi) => (
                    <Grid key={emploi?._id} item xs={12} md={6} lg={4}>
                      <CardPlanning
                        image={emploiImg}
                        name={`${emploi?.prof?.firstName} ${emploi?.prof?.lastName}`}
                        students={3}
                        //departement={emploi?.classe?.semester?.period?.level?.speciality?.departement?.name}
                        //level={emploi?.classe?.semester?.period?.level?.name}
                        //specialitie={emploi?.classe?.semester?.period?.level?.speciality?.name}
                        description=""
                        startDate={emploi?.startDate}
                        endDate={emploi?.endDate}
                        members={[team1, team2, team3, team4]}
                        dropdown={{
                          action: (e) => {
                            setSlackBotMenu(e.currentTarget);
                            setSelectedEmploiId(emploi._id);
                          },
                          menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>

            </SoftBox>
          </SoftBox>
          {totalPages > 1 && (<SoftPagination>
            {currentPage > 1 && (
              <SoftPagination item onClick={prevPage}><ArrowBackIosIcon /></SoftPagination>
            )}
            {Array.from({ length: totalPages }, (_, index) => (
              <SoftPagination item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </SoftPagination >
            ))}
            {currentPage < totalPages && (
              <SoftPagination item onClick={nextPage}><ArrowForwardIosIcon /> </SoftPagination>
            )}
          </SoftPagination>)}
        </>
      )}
    </DashboardLayout>
  )
}
