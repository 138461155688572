import { Card, Grid, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import api from "utils/axios";
import { useEffect, useState } from "react";
import PieCharte from "./components/PieChart";
import BarChart from "./components/BarChart";
import { useSoftUIController } from "context";
import MiniStatisticsCard from "./components/MiniStatisticsCard";
import ReactApexChart from 'react-apexcharts';
import { green, red } from "@mui/material/colors";
import DefaultLineChart from "./components/LineChart";
import Table from "examples/Tables/Table";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
function Kpi(){
  const [controller, _] = useSoftUIController();
  
  const [nbEntreprisesPotentiel , setNbEntreprisesPotentiel] = useState(0);
  const handleNbEnterprisesPotentiel = ()=>{
    api.get("tasks/get-entreprises-potentiel-count").then((res)=>{
      setNbEntreprisesPotentiel(res.data.countEntreprisesPotentiel)
    })
  }

  const [nbEntreprises , setNbEntreprises] = useState(0);
  const handleNbEntreprises = ()=>{
    api.get("get-entreprises-count").then((res)=>{
      setNbEntreprises(res.data.countEntreprises)
    })
  }

  const [nbEntreprisesActive , setNbEntreprisesActive] = useState(0);
  const [nbEntreprisesDescative , setNbEntreprisesDescative] = useState(0);
  const handleNbEntreprisesActiveAndDesactive = ()=>{
    api.get("get-entreprises-active-desactive-count").then((res)=>{
      setNbEntreprisesActive(res.data.nbEntreprisesActive)
      setNbEntreprisesDescative(res.data.nbEntreprisesDésactive)

    })
  }

  const [totalGainCommercials , setTotalGainCommercials] = useState(0);

  const handleTotalGainSuperAdmin = ()=>{
    api.get("get-total-gain-commercials-per-year").then((res)=>{
      setTotalGainCommercials(res.data.currentYearGain);
    })
  }

  const [chargeTotalForSuperAdmin, setChargeTotalForSuperAdmin] = useState(0);
  const handleChargeTotal = () =>{
    api.get("get-total-charge-cette-annee").then((res)=>{
      setChargeTotalForSuperAdmin(res.data.currentYearChargeForSuperAdmin);
    })
  }

  const [chargeTotalForSuperAdminCurrentMonth, setChargeTotalForSuperAdminCurrentMonth] = useState(0);
  const handleChargeTotalCurrentMonth = () =>{
    api.get("get-total-charge-cette-mois").then((res)=>{
      setChargeTotalForSuperAdminCurrentMonth(res.data.currentMonthChargeForSuperAdmin);
    })
  }

  const [chiffreAffaireSuperAdmin, setChiffreAffaireSuperAdmin] = useState("");
  const handleChiffreAffaireSuperAdmin = () =>{
    api.get("get-chiffre-affaires-super-admin").then((res)=>{
      setChiffreAffaireSuperAdmin(res.data.chiffreAffairesSuperAdminCurrentYear)
    })
  }

  const [percentageEspaceDeStockageUtiliser , setPercentageEspaceDeStockageUtiliser] = useState(0);
  const HandlePercentageOfEspaceDeStockageUtiliser = ()=>{
    api.get("get-espace-stockage-restant").then((res)=>{
      setPercentageEspaceDeStockageUtiliser(res.data.pourcentageStockageRestant)
    })
  }

  const [chargeMonthlyForSuperAdmin, setChargeMonthlyForSuperAdmin] = useState([]);
  const [percentageChargeMonthlyForSuperAdmin, setPercentageChargeMonthlyForSuperAdmin] = useState("");
  const handleChargeMonthly = ()=>{
    api.get("get-total-charge-chaque-mois-cette-annee").then((res)=>{
      setChargeMonthlyForSuperAdmin(res.data.monthlyChargesForSuperAdmin);
      setPercentageChargeMonthlyForSuperAdmin(res.data.percentageDifference)
    })
  }

  const [gainMonthlyForSuperAdmin, setGainMonthlyForSuperAdmin] = useState([]);

  const [gainCurrentMonthlyForSuperAdmin, setGainCurrentMonthlyForSuperAdmin] = useState(0);
  const [percentageGainCurrentMonthlyForSuperAdmin, setPercentageGainCurrentMonthlyForSuperAdmin] = useState("");
  const handleGainMonthly = ()=>{
    api.get("get-total-gain-commercials-per-month").then((res)=>{
      setGainMonthlyForSuperAdmin(res.data.monthlyGains);
      setGainCurrentMonthlyForSuperAdmin(res.data.currentMonthGain);
      setPercentageGainCurrentMonthlyForSuperAdmin(res.data.percentageDifference);
    })
  }

  const [chiffreAffaireMonthlyForSuperAdmin , setChiffreAffaireMonthlyForSuperAdmin] = useState([]);
  const [percentageChiffreAffairePerMonthSuperAdmin, setPercentageChiffreAffairePerMonthSuperAdmin] = useState("");
  const [chiffreAffairePerMonth, setChiffreAffairePerMonth] = useState(0);
  const handleChiffreAffaireMonthlyForSuperAdmin = ()=>{
    api.get("get-chiffre-affaires-super-admin-per-month").then((res)=>{
      setChiffreAffaireMonthlyForSuperAdmin(res.data.chiffreAffairesSuperAdminCurrentYear)
      setChiffreAffairePerMonth(res.data.chiffreAffairesSuperAdminCurrentMonth);
      setPercentageChiffreAffairePerMonthSuperAdmin(res.data.percentageDifference)
    })
  }

  const [chargeDaylyForSuperAdmin, setChargeDaylyForSuperAdmin] = useState([]);
  const handleChargeDayly = (month)=>{
    api.get(`get-total-charge-chaque-jour-cette-mois/${month}`).then((res)=>{
      setChargeDaylyForSuperAdmin(res.data.chargesPerDayForSuperAdmin)
    })
  }

  const [gainDaylyForSuperAdmin, setGainDaylyForSuperAdmin] = useState([]);
  const handleGainDayly = (month)=>{
    api.get(`get-total-gain-commercials-per-day/${month}`).then((res)=>{
      setGainDaylyForSuperAdmin(res.data)
    })
  }

  const [chiffreAffaireDaylyForSuperAdmin , setChiffreAffaireDaylyForSuperAdmin] = useState([]);
  const handleChiffreAffaireDaylyForSuperAdmin = (month)=>{
    api.get(`get-chiffre-affaires-super-admin-per-day/${month}`).then((res)=>{
      setChiffreAffaireDaylyForSuperAdmin(res.data)
    })
  }

  const [moisChart, setMoisChart] = useState("January");

  useEffect(()=>{
    handleChargeDayly(moisChart);
    handleGainDayly(moisChart);
    handleChiffreAffaireDaylyForSuperAdmin(moisChart);
  },[moisChart]);

  const [gainMonthlyForCommercial, setGainMonthlyForCommercial] = useState([]);
  const handleGainMonthlyForCommercial = (commercialName)=>{
    const selectedCommercial = commercials.find(commercial => commercial.firstName + " " + commercial.lastName === commercialName);
  
    // Vérification si l'objet commercial a été trouvé
    if (selectedCommercial) {
      const commercialId = selectedCommercial._id;

      api.get(`get-total-gain-commercial-per-month/${commercialId}`).then((res)=>{
        setGainMonthlyForCommercial(res.data.monthlyGains);
      })
    }
  }

  const [gainDaylyForCommercial, setGainDaylyForCommercial] = useState([]);
  const handleGainDaylyForCommercial = (month , commercialName)=>{
    const selectedCommercial = commercials.find(commercial => commercial.firstName + " " + commercial.lastName === commercialName);
  
    // Vérification si l'objet commercial a été trouvé
    if (selectedCommercial) {
      const commercialId = selectedCommercial._id;
      api.get(`get-total-gain-commercial-per-day/${month}/${commercialId}`).then((res)=>{
        setGainDaylyForCommercial(res.data);
      })
    }
  }

  const [periodeBarChart, setPeriodeBarChart] = useState("Annuel");
  const [moisBarChart, setMoisBarChart] = useState("January");
  const [commercialName , setCommercialName]= useState("");

  useEffect(()=>{
    handleGainDayly(moisBarChart)
  },[moisBarChart]);

  useEffect(()=>{
    if(commercialName !== ""){
      handleGainMonthlyForCommercial(commercialName);
      handleGainDaylyForCommercial(moisBarChart , commercialName);
    }
  },[moisBarChart , commercialName]);

  const [commercials, setCommercials] = useState([]);
  const handleCommercials = ()=>{
    api.get("get-user-commercial?role=commercial").then((res)=>{
      setCommercials(res.data)
    })
  }

  let filteredDatasets = [];
  if (commercialName === "" && periodeBarChart === "Annuel") {
    filteredDatasets = [
      {
        label: "Gain Super Admin",
        color: "secondary",
        data: gainMonthlyForSuperAdmin,
      }
    ];
  } else if (commercialName === "" && periodeBarChart === "Mensuel") {
    filteredDatasets = [
      {
        label: "Gain Par Jour Super Admin",
        color: "secondary",
        data: gainDaylyForSuperAdmin,
      }
    ];
  } else if (commercialName !== "" && periodeBarChart === "Annuel") {
    filteredDatasets = [
      {
        label: "Gain Commercial",
        color: "secondary",
        data: gainMonthlyForCommercial,
      }
    ];
  } else if (commercialName !== "" && periodeBarChart === "Mensuel") {
    filteredDatasets = [
      {
        label: "Gain Par Jour Commercial",
        color: "secondary",
        data: gainDaylyForCommercial,
      }
    ];
  }

  const [facturesTotalCurrentYearSuperAdmin, setFacturesTotalCurrentYearSuperAdmin] = useState("");
  const handleFacturesTotalCurrentYear = ()=>{
    api.get("get-facture-current-year-count").then((res)=>{
      setFacturesTotalCurrentYearSuperAdmin(res.data.countFacturesCurrentYearSuperAdmin)
    })
  }

  const [facturesTotalCurrentMonthSuperAdmin, setFacturesTotalCurrentMonthSuperAdmin] = useState("");
  const handleFacturesTotalCurrentMonth = ()=>{
    api.get("get-facture-current-month-count").then((res)=>{
      setFacturesTotalCurrentMonthSuperAdmin(res.data.countFactureCurrentMonthSuperAdmin)
    })
  }

  const [devisTotalCurrentYearSuperAdmin, setDevisTotalCurrentYearSuperAdmin] = useState("");
  const handleDevisTotalCurrentYear = ()=>{
    api.get("get-devis-current-year-count").then((res)=>{
      setDevisTotalCurrentYearSuperAdmin(res.data.countDevisCurrentYearSuperAdmin)
    })
  }

  const [devisTotalCurrentMonthSuperAdmin, setDevisTotalCurrentMonthSuperAdmin] = useState("");
  const handleDevisTotalCurrentMonth = ()=>{
    api.get("get-devis-current-month-count").then((res)=>{
      setDevisTotalCurrentMonthSuperAdmin(res.data.countDevisCurrentMonthsSuperAdmin)
    })
  }

  const [dateDevis, setDateDevis] = useState("Année Actuelle")
  const handleChangeDateDevis = (newValue) => {
    setDateDevis(newValue); // Update the date value
  };

  const [dateFacture, setDateFacture] = useState("Année Actuelle")
  const handleChangeDateFacture = (newValue) => {
    setDateFacture(newValue); // Update the date value
  };


  const [dateChiffreAffaire, setDateChiffreAffaire] = useState("Année Actuelle")
  const handleChangeDateChiffreAffaire = (newValue) => {
    setDateChiffreAffaire(newValue); // Update the date value
  };

  const [dateGain, setDateGain] = useState("Année Actuelle")
  const handleChangeDateGain = (newValue) => {
    setDateGain(newValue); // Update the date value
  };

  const [dateCharge, setDateCharge] = useState("Année Actuelle")
  const handleChangeDateCharge = (newValue) => {
    setDateCharge(newValue); // Update the date value
  };

  const [nbEntreprisesPotentielTransformerEnEntreprise , setNbEntreprisesPotentielTransformerEnEntreprise] = useState(0);
  const handleNbEnterprisesPotentielTransformerEnEntreprise = ()=>{
    api.get("tasks/get-nombre-entreprises-potentiel-transformer-en-entreprise").then((res)=>{
      setNbEntreprisesPotentielTransformerEnEntreprise(res.data.countEntreprisesPotentielTransformerEnEntreprise)
    })
  }

  const [nbTaches , setNbTaches] = useState(0);
  const handleNbTaches = ()=>{
    api.get("tasks/get-taches-count").then((res)=>{
      setNbTaches(res.data.countTaches)
    })
  }


  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
        const rowsData = await Promise.all(
          commercials.map(async (commercial) => {
            const taches_a_faire = await handleNbTachesAFaireDeCommercial(commercial.email);
            const taches_en_cours = await handleNbTachesEnCoursDeCommercial(commercial.email);
            const taches_terminer = await handleNbTachesTerminerDeCommercial(commercial.email);
            const entreprises_affecter = await handleEntreprisesContacterDeCommercial(commercial._id);
            const entreprises_potentiel_affecter = await handleNbProspectsContacterDeCommercial(commercial.email);
            const entreprises_potentiel_transformer_client = await handleTauxFermetureAffairesDeCommercial(commercial.email);

            
            return {
              nom: [`${process.env.REACT_APP_BASE_URL}/${commercial?.imageUrl}`, commercial.firstName + " " + commercial.lastName],
              taches_a_faire: taches_a_faire,
              taches_en_cours: taches_en_cours,
              taches_terminer: taches_terminer,
              entreprises_affecter: entreprises_affecter,
              ep_affecter : entreprises_potentiel_affecter,
              conversion_prospects_en_clients: entreprises_potentiel_transformer_client
            };
          })
        );
        setRows(rowsData);
    };

    fetchData();
  }, [commercials]); // Empty dependency array to fetch data once when component mounts

  const handleNbTachesAFaireDeCommercial = async (responsable) => {
    try {
      const response = await api.get("tasks/get-taches-statut-count-de-tous-commercials?statut=A faire");
      const tasksAFaireCountByCommercialArray = response.data.tasksAFaireCountByCommercialArray;
      const result = tasksAFaireCountByCommercialArray.find((item) => item.responsableEmail === responsable);
      return result ? result.countTasksParCommercial : 0;
    } catch (error) {
      return 0;
    }
  };

  const handleNbTachesEnCoursDeCommercial = async (responsable) => {
    try {
      const response = await api.get("tasks/get-taches-statut-count-de-tous-commercials?statut=En cours");
      const tasksEnCoursCountByCommercialArray = response.data.tasksAFaireCountByCommercialArray;
      const result = tasksEnCoursCountByCommercialArray.find((item) => item.responsableEmail === responsable);
      return result ? result.countTasksParCommercial : 0;
    } catch (error) {
      return 0;
    }
  };

  const handleNbTachesTerminerDeCommercial = async (responsable) => {
    try {
      const response = await api.get("tasks/get-taches-statut-count-de-tous-commercials?statut=Terminer");
      const tasksTerminerCountByCommercialArray = response.data.tasksAFaireCountByCommercialArray;
      const result = tasksTerminerCountByCommercialArray.find((item) => item.responsableEmail === responsable);
      return result ? result.countTasksParCommercial : 0;
    } catch (error) {
      return 0;
    }
  };

  
  const handleNbProspectsContacterDeCommercial = async (emailCommercial) => {
    try {
      const response = await api.get(`tasks/get-prospects-contacter-count-de-commercial?email=${emailCommercial}`);
      return response.data.countProspectsContactesParCommercial;
    } catch (error) {
      return 0;
    }
  };
  
  const handleEntreprisesContacterDeCommercial = async (idCommercial) => {
    try {
      const response = await api.get(`get-entreprises-contacter-count-de-commercial?id=${idCommercial}`);
      return response.data.countEntreprisesContactesParCommercial;
    } catch (error) {
      return 0; 
    }
  };
  
  const handleTauxFermetureAffairesDeCommercial = async (emailCommercial) => {
    try {
      const response = await api.get(`tasks/get-taux-fermeture-affaires-de-commercial?email=${emailCommercial}`);
      return response.data.tauxDeFermetureDesAffairesDeCommercial;
    } catch (error) {
      return 0;
    }
  };

  const [nbTachesAFaire , setNbTachesAFaire] = useState(0);
  const handleNbTachesAFaire = ()=>{
    api.get("tasks/get-taches-a-faire-count").then((res)=>{
      setNbTachesAFaire(res.data.countTachesAFaire)
    })
  }

  const [nbTachesEnCours , setNbTachesEnCours] = useState(0);
  const handleNbTachesEnCours = ()=>{
    api.get("tasks/get-taches-en-cours-count").then((res)=>{
      setNbTachesEnCours(res.data.countTachesEnCours)
    })
  }

  const [nbTachesTerminer , setNbTachesTerminer] = useState(0);
  const handleNbTachesTerminer = ()=>{
    api.get("tasks/get-taches-terminer-count").then((res)=>{
      setNbTachesTerminer(res.data.countTachesTerminer)
    })
  }

  useEffect(()=>{
    handleCommercials();

    handleNbEnterprisesPotentiel();
    handleNbEntreprises();
    handleNbEntreprisesActiveAndDesactive();

    handleTotalGainSuperAdmin();
    handleGainMonthly();

    handleChargeTotal();

    handleChargeTotalCurrentMonth();
    handleChargeMonthly();

    handleChiffreAffaireSuperAdmin();
    handleChiffreAffaireMonthlyForSuperAdmin();

    HandlePercentageOfEspaceDeStockageUtiliser();

    handleNbEnterprisesPotentielTransformerEnEntreprise();
    
    handleFacturesTotalCurrentYear();
    handleFacturesTotalCurrentMonth();
    handleDevisTotalCurrentYear();
    handleDevisTotalCurrentMonth();
    
    handleNbTaches();
    handleNbTachesAFaire();
    handleNbTachesEnCours();
    handleNbTachesTerminer();
  },[]);

  const [chartData, setChartData] = useState(null);
  useEffect(() => {
    const newInitialData = {
      series: [percentageEspaceDeStockageUtiliser],
      options: {
        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                formatter: function () {
                  return undefined;
                },
              },
              value: {
                offsetY: 76,
                fontSize: '22px',
                color: 'undefined',
                formatter: function (val) {
                  return val + '%';
                },
              },
            },
          },
        },
        stroke: {
          dashArray: 4,
        },
      },
    };
  
    // Set fill colors based on the updated series value
    newInitialData.options.fill = {
      colors: percentageEspaceDeStockageUtiliser <= 70 ? [`${green[500]}`] : percentageEspaceDeStockageUtiliser >= 90 ? [`${red[800]}`] : [`${red[500]}`],
    };
  
    setChartData(newInitialData);
  }, [percentageEspaceDeStockageUtiliser]);

    return(
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3} mt="16px">
              <Grid item xs={12} md={6} lg={3}>
                <MiniStatisticsCard

                  title={{ text: "Entreprises Potentielle" }}
                  count={nbEntreprisesPotentiel}

                  icon={{ color: "dark", component: "apartment" }}
                />
              </Grid> 

              <Grid item xs={12} md={6} lg={3}>
                <MiniStatisticsCard
                  title={{ text: "Entreprises Total" }}
                  count={nbEntreprises}

                  icon={{ color: "dark", component: "apartment" }}
                />
              </Grid> 

              <Grid item xs={12} md={6} lg={3}>
                <MiniStatisticsCard
                  title={{ text: "Entreprises Active" }}
                  count={nbEntreprisesActive}

                  icon={{ color: "dark", component: "apartment" }}
                />
              </Grid> 

              <Grid item xs={12} md={6} lg={3}>
                <MiniStatisticsCard
                  title={{ text: "Entreprises Désactive" }}
                  count={nbEntreprisesDescative}


                  icon={{ color: "dark", component: "apartment" }}
                />
              </Grid> 

              <Grid container spacing={3} xs={12} lg={9} ml="0px" mt="0px">
                <Grid item xs={12} lg={4}>
                  <MiniStatisticsCard

                    title={{ text: "CA" }}
                    date={dateChiffreAffaire}
                    onChangeDate={handleChangeDateChiffreAffaire}
                    percentage={dateChiffreAffaire === "Mois Actuel" ? percentageChiffreAffairePerMonthSuperAdmin : undefined}
                    count={dateChiffreAffaire === "Année Actuelle" ? chiffreAffaireSuperAdmin + ' DT' : chiffreAffairePerMonth + ' DT' }
                    icon={{ color: "primary", component: "paid" }}
                  />
                </Grid> 

                <Grid item xs={12} lg={4}>
                  <MiniStatisticsCard

                    title={{ text: "Gain" }}
                    date={dateGain}
                    onChangeDate={handleChangeDateGain}
                    count={dateGain === "Année Actuelle" ? totalGainCommercials + " DT" : gainCurrentMonthlyForSuperAdmin + " DT"}
                    percentage={dateGain === "Mois Actuel" ? percentageGainCurrentMonthlyForSuperAdmin : undefined}
                    icon={{ color: "primary", component: "paid" }}
                  />
                </Grid> 

                <Grid item xs={12} lg={4}>
                  <MiniStatisticsCard

                    title={{ text: "Charge" }}
                    date={dateCharge}
                    onChangeDate={handleChangeDateCharge}
                    isCharge={true}
                    count={dateCharge === "Année Actuelle" ? chargeTotalForSuperAdmin + " DT" : chargeTotalForSuperAdminCurrentMonth + " DT"}
                    percentage={dateCharge === "Mois Actuel" ? percentageChargeMonthlyForSuperAdmin : undefined}
                    icon={{ color: "primary", component: "paid" }}
                  />
                </Grid> 
                <Grid item xs={12}>
                  <DefaultLineChart
                    title="Suivi des Indicateurs Financiers"
                    LineCharteData={{
                      labels: ["Jan", "Fev" , "Mar" , "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                      datasets: [
                        {
                          label: "Chiffre Affaires",
                          color: "primary",
                          data: chiffreAffaireMonthlyForSuperAdmin,
                        },
                        {
                          label: "Gain",
                          color: "success",
                          data: gainMonthlyForSuperAdmin,
                        },
                        {
                          label: "Charge",
                          color: "error",
                          data: chargeMonthlyForSuperAdmin
                        },
                      ],
                    }}
                    typeChartResponse={false}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} lg={3}>
                {
                  chartData !== null &&
                    <Card id="chart" sx={{p:3}}>
                      <Typography variant="h6"  color="initial">Espace de stockage</Typography>
                      <ReactApexChart options={chartData.options} series={chartData.series} type="radialBar" height={350} />
                    </Card>
                }
              </Grid>

              <Grid item xs={12}>
                <DefaultLineChart
                  title="Analyse de la Performance Financière"
                  LineCharteData={{
                    labels: ["Jan", "Fev" , "Mar" , "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                    datasets: [
                      {
                        label: "Chiffre Affaires",
                        color: "primary",
                        data: chiffreAffaireMonthlyForSuperAdmin
                      },
                      {
                        label: "Gain",
                        color: "success",
                        data: gainMonthlyForSuperAdmin,
                      },
                      {
                        label: "Charge",
                        color: "error",
                        data: chargeMonthlyForSuperAdmin
                      },
                      {
                        label: "Chiffre Affaires Par jour",
                        color: "primary",
                        data: chiffreAffaireDaylyForSuperAdmin
                      },
                      {
                        label: "Gain Par jour",
                        color: "success",
                        data: gainDaylyForSuperAdmin,
                      },
                      {
                        label: "Charge Par jour",
                        color: "error",
                        data: chargeDaylyForSuperAdmin
                      },
                    ],
                  }}
                  height="300px"
                  display="block"
                  typeChartResponse={true}
                  moisChart={moisChart}
                  setMoisChart={setMoisChart}
                />
              </Grid>

              <Grid item xs={12}>
                <BarChart 
                  title="Gain Par Commercial"
                  verticalBarChartData={{
                    labels:["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                    datasets: filteredDatasets,
                  }}
                  moisBarChart={moisBarChart}
                  setMoisBarChart={setMoisBarChart}
                  commercialsName={commercials.map((commercial)=>{
                    return {value : commercial.firstName + " " + commercial.lastName , label : commercial.firstName + " " + commercial.lastName}
                  })}
                  periodeBarChart={periodeBarChart}
                  setPeriodeBarChart={setPeriodeBarChart}
                  commercialName={commercialName}
                  setCommercialName={setCommercialName}
                />
              </Grid>

              <Grid container xs={12} lg={6} spacing={3} m="0px">
                <Grid item xs={12} lg={6}>
                  <MiniStatisticsCard
                    title={{ text: "Factures" }}
                    date={dateFacture}
                    onChangeDate={handleChangeDateFacture}
                    count={dateFacture === "Année Actuelle" ? facturesTotalCurrentYearSuperAdmin : facturesTotalCurrentMonthSuperAdmin}
                    // percentage={{ color: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? "error" : "success", text: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? percentageChargeTotalForSuperAdmin : `+${percentageChargeTotalForSuperAdmin}` }}
                    icon={{ color: "warning", component: "assignment" }}
                  />
                </Grid> 

                <Grid item xs={12} lg={6}>
                  <MiniStatisticsCard
                    title={{ text: "Devis" }}
                    date={dateDevis}
                    onChangeDate={handleChangeDateDevis}
                    count={dateDevis === "Année Actuelle" ? devisTotalCurrentYearSuperAdmin : devisTotalCurrentMonthSuperAdmin}
                    // percentage={{ color: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? "error" : "success", text: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? percentageChargeTotalForSuperAdmin : `+${percentageChargeTotalForSuperAdmin}` }}
                    icon={{ color: "warning", component: "assignment" }}
                    
                  />
                </Grid> 

                <Grid item xs={12} lg={6}>
                  <MiniStatisticsCard
                    title={{ text: "Factures Payées" }}
                    count={0}
                    // percentage={{ color: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? "error" : "success", text: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? percentageChargeTotalForSuperAdmin : `+${percentageChargeTotalForSuperAdmin}` }}
                    icon={{ color: "warning", component: "assignment" }}
                  />
                </Grid> 

                <Grid item xs={12} lg={6}>
                  <MiniStatisticsCard
                    title={{ text: "Devis Active" }}
                    count={0}
                    // percentage={{ color: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? "error" : "success", text: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? percentageChargeTotalForSuperAdmin : `+${percentageChargeTotalForSuperAdmin}` }}
                    icon={{ color: "warning", component: "assignment" }}
                  />
                </Grid> 

                <Grid item xs={12} lg={6}>
                  <MiniStatisticsCard
                    title={{ text: "Factures Non payées" }}
                    count={0}
                    // percentage={{ color: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? "error" : "success", text: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? percentageChargeTotalForSuperAdmin : `+${percentageChargeTotalForSuperAdmin}` }}
                    icon={{ color: "warning", component: "assignment" }}
                  />
                </Grid> 

                <Grid item xs={12} lg={6}>
                  <MiniStatisticsCard
                    title={{ text: "Devis Désactive" }}
                    count={0}
                    // percentage={{ color: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? "error" : "success", text: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? percentageChargeTotalForSuperAdmin : `+${percentageChargeTotalForSuperAdmin}` }}
                    icon={{ color: "warning", component: "assignment" }}
                  />
                </Grid> 
              </Grid>

              <Grid item xs={12} lg={6}>
                <PieCharte 
                  title="Nombres Des Taches Par Statut"
                  PieCharteData={{
                    labels: ["Taches A faire" , "Taches En cours", "Taches Terminer"],
                    datasets: {
                      backgroundColors: ["light", "success", "primary","warning", "dark", "secondary", "error", "info"],
                      data: [nbTachesAFaire, nbTachesEnCours, nbTachesTerminer]
                    },
                  }}
                  sousTitle={"tâches"}
                  sousNb={nbTaches}/>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <SoftBox pt={3} px={3}>
                    <SoftTypography variant="h6" fontWeight="medium">
                      Commercials Information
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox py={1}>
                    <Table
                      columns={[
                        { name: "nom", align: "left" },
                        { name: "taches_a_faire", align: "center" },
                        { name: "taches_en_cours", align: "center" },
                        { name: "taches_terminer", align: "center" },
                        { name: "entreprises_affecter", align: "center" },
                        { name: "ep_affecter", align: "center" },
                        { name: "conversion_prospects_en_clients", align: "center" },
                        
                      ]}
                      rows={rows}
                    />
                  </SoftBox>
                </Card>
              </Grid>            

              

              {/* {controller.user.info.role === "super-admin" &&
                <>

                </>
              } */}
          </Grid>
        </DashboardLayout>
    )
}

export default Kpi;