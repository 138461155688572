import * as Yup from "yup";
import checkout from "pages/enterprise/schema/enterprise-schemas/EnterpriseSchema";
const {
  formField: {
    companyName,
    serialNumber,
    email,
    phone,
    physiqualAddress,
    postCode,
    country,
    city,
    CompanyAdminfirstName,
    CompanyAdminlastName,
    CompanyAdminemail,
    CompanyAdminphone,
    facilityName,
    CompanyAdmingender,
  },
} = checkout;
const atLeastOneSelected = (array) => {
  return array.length > 0;
};
const validations = [
    // formik, yup => formik = <form> & yup for validation
  Yup.object().shape({
    companyName: Yup.string()
      .required(companyName.errorMsg)
      .min(2, "La longueur minimale requise est de 2 caractères.")
      .max(30, "La longueur maximale autorisée est de 30 caractères."),
    serialNumber: Yup.string()
      .required(serialNumber.errorMsg)
      .min(8, "La longueur minimale requise est de 8 caractères.")
      .max(16, "La longueur maximale autorisée est de 16 caractères."), 
    email: Yup.string()
      .required(email.errorMsg)
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Veuillez entrer une adresse e-mail valide."
      )
      .max(50, "La longueur maximale autorisée est de 50 caractères."),
    phone: Yup.string().required(phone.errorMsg)
    .min(10, "La longueur minimale requise est de 10 caractères."),
    country:Yup.string().required(country.errorMsg),
    city:Yup.string().required(city.errorMsg),
    physiqualAddress: Yup.string()
      .required(physiqualAddress.errorMsg)
      .max(50, "La longueur maximale autorisée est de 50 caractères."), 
    postCode: Yup.string()
      .matches(/^[0-9]+$/gi, "La valeur doit etre un nombre")
      .required(postCode.errorMsg)
      .min(4, "La longueur minimale requise est de 4 caractères."), 
  }),
   Yup.object().shape({
    facilityName: Yup.array().required(facilityName.errorMsg)
    }),
  Yup.object().shape({
    CompanyAdminfirstName: Yup.string()
      .required(CompanyAdminfirstName.errorMsg)
      .min(2, "La longueur minimale requise est de 2 caractères.")
      .max(30, "La longueur maximale autorisée est de 30 caractères.")
      .matches(
        /^(?!.*\s\s)[\p{L}\s]{2,30}$/u,
        "Veuillez entrer un nom valide."
      ),
    CompanyAdminlastName: Yup.string()
      .required(CompanyAdminlastName.errorMsg)
      .min(2, "La longueur minimale requise est de 2 caractères.")
      .max(30, "La longueur maximale autorisée est de 30 caractères.")
      .matches(
        /^(?!.*\s\s)[\p{L}\s]{2,30}$/u,
        "Veuillez entrer un prénom valide."
      )
      ,
       CompanyAdminemail: Yup.string()
      .required(CompanyAdminemail.errorMsg)
      .min(2, "La longueur minimale requise est de 2 caractères.")
      .max(50, "La longueur maximale autorisée est de 50 caractères.")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Veuillez entrer une adresse e-mail valide."
      ) ,
    CompanyAdminphone: Yup.string()
      .required(CompanyAdminphone.errorMsg)
      .min(10, "La longueur minimale requise est de 10 caractères."),
    CompanyAdmingender: Yup.string()
      .oneOf(["homme", "femme"], "Required")
      .required(CompanyAdmingender.errorMsg), 
  }),
];

export default validations;
