import { useEffect, useRef, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
// Data
import { Box, CircularProgress, Slide, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "utils/axios";
import Header from "layouts/pages/profile/components/Header";
import AddSpeciality from "./Addformation";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import ForamtionActions from "./components/FormationActions";

function FormationTable() {
  const [loading, setLoading] = useState(false);
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [data, setData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const myRef = useRef(null);

  const facility = JSON.parse(localStorage.getItem("facility"));
  useEffect(() => {
    document.title = "formation";
  }, []);
  const getLast6Characters = (code) => code?.slice(-6);
  const handleFetch = async () => {
    setLoading(true)
    try {
      const response = await api.get(`/faculty/get-all-formations/${facility.scholarityConfigId}`);
      setData(response.data);   
    } catch (error) {
      throw error
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    handleFetch();
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Liste des formations
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>

              <SoftButton variant="outlined" color="purple" size="small" mr={2}>
                export
              </SoftButton>
              <AddSpeciality token={handleFetch} />
            </Stack>
          </SoftBox>
          {loading ? (
            <SoftBox
              sx={{
                mt: 13,
                textAlign: "center",
              }}
            >
              <CircularProgress />
            </SoftBox>
          ) : (
            <div>
              <Slide direction="right" in={!showDetails} mountOnEnter unmountOnExit>
                <div ref={myRef}>
                  {!showDetails && (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Code", accessor: (row) => getLast6Characters(row.code), width: "15%" },
                          { Header: "Nom de formation", accessor: "FormationName" },
                          { Header: "Type", accessor: "type"},
                          { Header: " Niveau d'étude", accessor: "nbrNiveaux" },
                          { Header: "Nombre de filiére", accessor: "filieres" },
                          { Header: "action", accessor: "action", width: "12%" },
                        ],
                        rows:
                          data &&
                          data.map((formation, index) => ({
                            code: formation?.code,
                            FormationName:formation?.name,
                            type: formation?.type,
                            nbrNiveaux: formation?.nbrNiveaux,
                           filieres: formation?.filieres?.length,
                            action: (
                              <ForamtionActions
                                key={formation._id}
                                formation={formation}
                                handleFetch={handleFetch}
                                setShowDetails={setShowDetails}
                                setDetailsData={setDetailsData}
                              />
                            ),
                          })),
                      }}
                      entriesPerPage={
                        afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false
                      }
                      canSearch={afficheFilter ? true : false}
                    />
                  )}
                </div>
              </Slide>
              {showDetails && (
                <>
                  <ArrowBackIcon
                    onClick={() => setShowDetails(false)}
                    sx={{ cursor: "pointer", lineHeight: 0, mt: 3, ml: 2 }}
                  />
                  <Slide direction="left" in={showDetails} mountOnEnter unmountOnExit>
                    <div>
                      <SoftBox lineHeight={1}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5, ml: 3 }}
                        >
                           {detailsData?.name}
                        </SoftTypography>
                      </SoftBox>
                      <DataTable
                        table={{
                          columns: [
                            { Header: "code", accessor: (row) => getLast6Characters(row.code), width: "20%" },
                            { Header: "Niveau", accessor: "level", width: "30%" },
                            { Header: "Filière", accessor: "filiere", width: "3"},
                          ],
                          rows: detailsData &&   detailsData?.filieres?.flatMap((filiere) =>
                          filiere?.niveaux?.map((niv) => ({
                            code:filiere?.code,
                              level: niv?.name,
                              filiere:filiere?.name
                            })
                          )
                        )
                          
                        }}
                        entriesPerPage={afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false}
                        canSearch={afficheFilter}
                      />
                    </div>
                  </Slide>
                </>
              )}
            </div>
          )}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}
export default FormationTable;