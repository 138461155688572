import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
// Custom styles for CardPlanning

function addDaysAndGetYear(dateString, days) {
  const date = new Date(dateString);
  date.setDate(date.getDate() + days);
  // Check if the resulting date is valid
  if (!isNaN(date.getTime())) {
    const year = date.getFullYear();
    return year;
  } else {
    return null;
  }
}

function CardPlanning({ image, name, dateTime,level, specialitie, members, dropdown,departement,startDate,endDate }) {
  const renderMembers = members.map((member, key) => {
    const memberKey = `member-${key}`;
    return (
      <SoftAvatar
        key={memberKey}
        src={member}
        alt="member profile"
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          "&:not(:first-of-type)": {
            ml: -1.25,
          },
          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    );
  });
  return (
    <Card>
      <SoftBox p={1.75}>
        <SoftBox display="flex" alignItems="flex-start" justifyContent="space-between">
          <SoftAvatar src={image} alt={name} size="xl" variant="rounded" />
          <SoftBox ml={1} lineHeight={0}>
            <SoftBox mb={1.5} lineHeight={0} >
              <SoftTypography variant="h5" textTransform="capitalize" fontWeight="medium">
                {name}
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" alignItems="center" lineHeight={0}>
              {members.length > -1 ? (
                <SoftBox display="flex" sx={{ mr: 0.5 }}>
                  {renderMembers}
                </SoftBox>
              ) : null}
              <SoftTypography
                variant="button"
                fontWeight="medium"
                color="secondary"
                fontSize="12px"
              >
                Classes
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox
            sx={{
              ml: "auto",
              alignSelf: "flex-start",
              py: 0.25,
            }}
            display="flex"
          >
            {(startDate && endDate) && (
              <SoftBox
                display="flex"
                flexDirection="column"
                lineHeight={1}
                ml={2}
                alignItems="flex-start"
                mt={0.25}
              >
                <SoftTypography variant="button" fontWeight="bold">
                   {addDaysAndGetYear(startDate, 30)} - {addDaysAndGetYear(endDate, 30)} 
                </SoftTypography>
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color="secondary"
                  fontSize="12px"
                >
                  Année Scolaire
                </SoftTypography>
              </SoftBox>
            )}
            {dropdown && (
              <SoftTypography color="secondary" onClick={dropdown.action}>
                <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                  more_vert
                </Icon>
              </SoftTypography>
            )}
            {dropdown.menu}
          </SoftBox>
        </SoftBox>
        <SoftBox my={2} lineHeight={1}>
 {/*  <SoftTypography variant="button" fontWeight="medium" color="secondary" sx={{ textAlign: 'center' }}>
    Dép : {departement} &nbsp; Spé: {specialitie} &nbsp; Niveau : {level}
  </SoftTypography> */}
</SoftBox>
        {/* <Divider />
        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
          {students > -1 ? (
            <SoftBox display="flex" flexDirection="column" lineHeight={0}>
              <SoftTypography variant="button" fontWeight="medium">
                {students}
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="medium" color="secondary">
                Etudiants
              </SoftTypography>
            </SoftBox>
          ) : null}
        
</SoftBox> */}
      </SoftBox>
    </Card>
  );
}
// Setting default values for the props of CardPlanning
CardPlanning.defaultProps = {
  color: "dark",
  dateTime: "",
  members: [],
  dropdown: false,
};
// Typechecking props for the ProfileInfoCard
CardPlanning.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  level: PropTypes.number,
  specialitie: PropTypes.string,
  description: PropTypes.node.isRequired,
  departement: PropTypes.node.isRequired,
  members: PropTypes.arrayOf(PropTypes.string),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};
export default CardPlanning;