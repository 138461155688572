import { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import AddModule from "./AddModule";
import ModuleActions from "./components/ModuleActions";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { Box, CircularProgress, Slide } from "@mui/material";
import api from "utils/axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stack from '@mui/material/Stack';
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";
import SoftSelect from "components/SoftSelect";

function ModuleTable() {
  const [loading, setLoading] = useState(false);
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [data, setData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [optionsDepartement, setOptionsDepartement] = useState([]);
  const [optionsFiliere, setOptionsFiliere] = useState([]);
  const [optionsParcours, setOptionsParcours] = useState([]);
  const myRef = useRef(null);
  const facility = JSON.parse(localStorage.getItem("facility"));
  useEffect(() => {
    // change page title in the header
    document.title = "module";
  }, []);
  const handleFetch = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/faculty/get-all-modules/${facility.scholarityConfigId}`);
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetch();
  }, []);
  const getLast6Characters = (code) => code?.slice(-6);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Liste des modules
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
              <SoftButton variant="outlined" color="purple" size="small" mr={2}>
                export
              </SoftButton>
              <AddModule handleFetch={handleFetch} />
            </Stack>
          </SoftBox>
          {loading ? (
            <SoftBox sx={{ mt: 13, textAlign: "center" }}>
              <CircularProgress />
            </SoftBox>
          ) : (
            <div>
              <Slide direction="right" in={!showDetails} mountOnEnter unmountOnExit>
                <div ref={myRef}>
                  {!showDetails && (
                    <>
                      <SoftBox p={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={0} lg={4.5}></Grid>
                          <Grid item xs={12} lg={2.5}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography size="small" component="label" variant="caption" fontWeight="bold">
                                Departement
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect
                              //defaultValue={frameOptions[3]}
                              options={optionsDepartement}
                              size="small"                           
                            />
                          </Grid>
                          <Grid item xs={12} lg={2.5}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography size="small"  component="label" variant="caption" fontWeight="bold">
                                Filière
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect size="small"  options={optionsFiliere} />
                          </Grid>
                          <Grid item xs={12} lg={2.5}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography size="small" component="label" variant="caption" fontWeight="bold">
                                Parcours
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect size="small" options={optionsParcours} />
                          </Grid>
                        </Grid>
                      </SoftBox>
                    <DataTable
                      table={{
                        columns: [
                          { Header: "code", accessor: "code"},
                          { Header: "Nom du module", accessor: "name" },
                          { Header: "Nbre matiere", accessor: "matieres" },
                          { Header: "Coefficient module", accessor: "coeff" },
                          { Header: "Credit module", accessor: "credit" },
                          { Header: "Sous Période", accessor: "sousperiode" },
                          { Header: "action", accessor: "action", width: "12%" },
                        ],
                        rows:
                          data &&
                          data?.map((module) => ({
                            code: module?.code,
                            name:module?.name || "-",
                            level: module?.level?.name,
                            sousperiode: module?.sousPeriode?.name,
                            coeff: module?.coefficient,
                            credit: module?.credit ,
                            matieres: module?.subjects?.length,
                            action: (
                              <ModuleActions
                                key={module.code}
                                module={module}
                                handleFetch={handleFetch}
                                setShowDetails={setShowDetails}
                                setDetailsData={setDetailsData}
                              />
                            ),
                          })),
                      }}
                      entriesPerPage={
                        afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false
                      }
                      canSearch={afficheFilter}
                    />
                    </>
                  )}
                </div>
              </Slide>
              {showDetails && (
                <>
                  <ArrowBackIcon
                    onClick={() => setShowDetails(false)}
                    sx={{ cursor: "pointer", lineHeight: 0, mt: 3, ml: 2 }}
                  />
                  <Slide direction="left" in={showDetails} mountOnEnter unmountOnExit>
                    <div>
                      <SoftBox lineHeight={1}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                          sx={{ mb: 0.5, ml: 3 }}
                        >
                          Nom du module : {detailsData?.name}
                        </SoftTypography>
                      </SoftBox>
                      <DataTable
                        table={{
                          columns: [
                            { Header: "code", accessor: "code", width: "15%" },
                            { Header: "Nom Matière", accessor: "matierName" },
                            { Header: "Coefficient", accessor: "coeff" },
                            { Header: "Credit", accessor: "credit" },
                            { Header: "type", accessor: "type" },
                          ],
                          rows:
                             detailsData && detailsData?.subjects
                              ? detailsData.subjects?.map((subject) => ({
                                code: subject?.code,
                                matierName: subject?.name,
                                coeff: subject?.coefficient,
                                credit: subject?.credit,
                                type:subject.type.map((t,index) => (
                                  <div key={t._id}>
                                    <ul>                                 
                                        <li key={index}>{t.typeName} : {t.duration}h </li>   
                                    </ul>                  
                                  </div>
                                )),
              
                                })) :
                            [],
                        }}
                        entriesPerPage={
                          afficheFilter
                            ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }
                            : false
                        }
                        canSearch={afficheFilter}
                      />
                    </div>
                  </Slide>
                </>
              )}
            </div>
          )}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}
export default ModuleTable;
