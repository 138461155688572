import { useEffect, useState } from "react";
import { Box, Card, IconButton, Tooltip, CircularProgress, Slide, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useTheme } from "@emotion/react";
import { green, grey, red } from "@mui/material/colors";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Delete,
  DragHandle,
  Edit,
  KeyboardArrowDown,
  KeyboardDoubleArrowUp,
} from "@mui/icons-material";
import api from "utils/axios";
import RemainingTime from "./TempsLimite/RemainingTime";

function ListTaches() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1); // Track current page number
  const [taches, setTaches] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("accessToken");

  const listeTaches = async () => {
    setLoading(true);
    try {
      const response = await api.get("tasks/get-tasks");
      setTaches(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listeTaches();
  }, []);

  const deleteTache = async (id) => {
    try {
      await api.delete(`tasks/delete-task/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      listeTaches();
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const getLast6Characters = (code) => code?.slice(-6);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ my: 2 }}>
        {loading ? (
          <Box sx={{ mt: 13, textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            p={3}
          >
            <SoftTypography variant="h5" fontWeight="medium">
              Liste des Tâches
            </SoftTypography>
            <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
              <SoftButton variant="outlined" color="purple" size="small">
                export
              </SoftButton>
              <SoftButton
                variant="gradient"
                color="purple"
                size="small"
                onClick={() => navigate("/commercials/listTaches/ajouterTache")}
              >
                Ajouter une tâche
              </SoftButton>
            </Stack>
          </Box>
          <DataTable
            table={{
              columns: [
                { Header: "Nom", accessor: "nom", width: "10%"},
                { Header: "Responsable", accessor: "responsable", width: "15%" },
                { Header: "Temps Restant", accessor: "tempsRestant", width: "10%" , align : "center" },
                { Header: "Priorité", accessor: "priorite", width: "10%" },
                { Header: "Statut", accessor: "statut", width: "10%" },
                { Header: "Entreprise", accessor: "entreprise", width: "10%" , align : "center" },
                { Header: "Date Limite", accessor: "dateLimite", width: "15%" },
                { Header: "Évaluation", accessor: "evaluation", width: "10%" },
                { Header: "Action", accessor: "action", width: "10%" },
              ],
              rows: taches.slice((currentPage - 1) * taches.length, currentPage * taches.length).map((tache) => ({
                nom: tache.nom,
                responsable: tache.responsable,
                tempsRestant: tache.statut !== "Terminer" ? (
                  <Box color={true ? `${red[500]} !important` : `${green[500]} !important`}>
                    <RemainingTime dateLimite={tache.dateLimite} />
                  </Box>
                ) : (
                  "_"
                ),
                priorite: (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {tache.priorite === "Haute" ? (
                      <KeyboardDoubleArrowUp color="error" />
                    ) : tache.priorite === "Moyenne" ? (
                      <DragHandle color="warning" />
                    ) : (
                      <KeyboardArrowDown color="info" />
                    )}
                    <Typography variant="body" sx={{ ml: "8px" }}>
                      {tache.priorite}
                    </Typography>
                  </Box>
                ),
                statut: tache.statut,
                entreprise: tache.entreprise === "" ? "_" : tache.entreprise,
                dateLimite: tache.dateLimite,
                evaluation: (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {tache.evaluation === "Aucune" ? (
                      <SoftBadgeDot color="secondary" size="xs" badgeContent="Aucune" />
                    ) : tache.evaluation === "Positive" ? (
                      <SoftBadgeDot color="success" size="xs" badgeContent="Positive" />
                    ) : (
                      <SoftBadgeDot color="error" size="xs" badgeContent="Négative" />
                    )}
                  </Box>
                ),
                action: (
                  <Box display="flex" alignItems="center">
                    <Box mx={2}>
                      <Tooltip title="Modifier">
                        <IconButton
                          sx={{ "&:hover": { bgcolor: grey[200] } }}
                          onClick={() => {
                            navigate(`/commercials/listTaches/editerTache/${tache._id}`);
                          }}
                        >
                          <Edit fontSize="small" color="purple" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Tooltip title="Supprimer">
                      <IconButton
                        sx={{ "&:hover": { bgcolor: grey[200] } }}
                        onClick={() => {
                          const newSwal = Swal.mixin({
                            customClass: {
                              confirmButton: "button button-success",
                              cancelButton: "button button-error",
                            },
                            buttonsStyling: false,
                          });

                          newSwal
                            .fire({
                              title: "Êtes-vous sûr(e)",
                              text: "Vous ne pourrez pas revenir en arrière !",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonText: "Oui, supprimer !",
                              cancelButtonText: "Annuler",
                            })
                            .then(async (result) => {
                              if (result.isConfirmed) {
                                deleteTache(tache._id);
                                Swal.fire({
                                  title: "Succès",
                                  text: "Suppression de la tâche réussie",
                                  icon: "success",
                                  confirmButtonText: "ok",
                                });
                              }
                            });
                        }}
                      >
                        <Delete fontSize="small" color="purple" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ),
              })),
            }}
            entriesPerPage={{ defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }}
            canSearch
          />
        </Box>
        )}
      </Card>
    </DashboardLayout>
  );
}

export default ListTaches;
