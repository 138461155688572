import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Autocomplete, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import api from "utils/axios";
import Swal from "sweetalert2";
import SoftBox from "components/SoftBox";

export default function AddParcours({ handleFetch, showDetails }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageFiliere, setErrorMessageFiliere] = useState("");
  const [errorMessageLevel, setErrorMessageLevel] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inputs, setInputs] = useState([
    { speciality: "", filiere: "",tronc:"", niveaux: "", optionsLevel: [] },
  ]);
  const [options, setOptions] = useState([]);
  const [optionsFilieres, setOptionsFilieres] = useState([]);
  const [optionsTroncCommun, setOptionsTroncCommun] = useState([]);
  const [departementId, setDepartementId] = useState("");
  const [selectedFiliere, setSelectedFiliere] = useState("");
  const facility = JSON.parse(localStorage.getItem("facility"));
  useEffect(() => {
    // Fetching filieres from the API
    api.get(`/faculty/all-filiere/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsFilieres(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
          niveaux: item.niveaux,
          departement: item.departement._id,
        }))
      );
    });
    if (departementId) {
      api
        .get(`/faculty/get-specialities-by-departement/${departementId}`)
        .then((res) => {
          setOptions(
            res.data.map((item) => {
              return {
                value: item._id,
                label: item.name,
              };
            })
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setError("Resource not found:", error.response.data);
            // Handle 404 error gracefully (e.g., display a message to the user)
          } else {
            setError("API request failed:", error.message);
            // Handle other errors
          }
        });
        api
        .get(`/faculty/get-troncs-by-departement/${departementId}`)
        .then((res) => {
          setOptionsTroncCommun(
            res.data.map((item) => {
              return {
                value: item._id,
                label: item.name,
              };
            })
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setError("Resource not found:", error.response.data);
            // Handle 404 error gracefully (e.g., display a message to the user)
          } else {
            setError("API request failed:", error.message);
            // Handle other errors
          }
        });       
    }
  }, [departementId, selectedFiliere]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setErrorMessageFiliere("");
    setDepartementId("");
    setErrorMessageLevel("");
    setInputs([{ speciality: "", filiere: "",tronc:"", niveaux: "", optionsLevel: [] }]);
  };
  const handleAddParcours = async () => {
    try {
      setLoading(true);
      // Validate the inputs before submitting
      const hasInvalidFiliere = inputs.some((input) => !input.filiere);
      const hasInvalidSpeciality = inputs.some((input) => !input.speciality);
      const hasInvalidLevel = inputs.some((input) => !input.niveaux);
      if (hasInvalidFiliere) {
        setErrorMessageFiliere("Une filière doit être sélectionnée.");
      } else if (hasInvalidSpeciality) {
        // Set the error message
        setErrorMessage("Une spécialité doit être sélectionnée.");
      } else if (hasInvalidLevel) {
        setErrorMessageLevel("Au moins un Niveau doit être sélectionné.");
      } else {
        const requestData = inputs.map(({ speciality, niveaux,tronc }) => ({
          tronc,
          speciality,
          niveaux,
        }));
        await api.post(
          `/faculty/create-parcours?scholarityConfigId=${facility?.scholarityConfigId}`,
          requestData
        );
        setOpen(true);
        Swal.fire("Success!", "Le parcours ajoutée avec succès", "success").then(() => {
          setErrorMessage("");
          setErrorMessageFiliere("");
          setDepartementId("");
          setErrorMessageLevel("");
          setInputs([{ speciality: "", filiere: "",tronc:"", niveaux: "", optionsLevel: [] }]);
          handleFetch();
          setOpen(false);
        });
      }
    } catch (error) {
      setError(error.response.data.message);
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddParcours();
    }
  };
  return (
    <div>
      <SoftButton
        id="add"
        onClick={handleClickOpen}
        variant="gradient"
        color="purple"
        size="small"
        mb={{ xs: 2, sm: 0 }}
      >
        +Ajouter parcours
      </SoftButton>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} onKeyDown={handleKeyPress}>
        <DialogTitle>Ajouter un parcours</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            Vous devez saisir le nom du parcours afin de l&apos;ajouter.
          </DialogContentText>
          {inputs.map((input, index) => (
            <Grid key={index} sx={{ mt: 2 }}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "flex-start",
                }}
              >
                <Grid item xs={12} sm={inputs.length > 1 ? 3.75 : 6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Filière*
                  </SoftTypography>
                  <Autocomplete
                    id={`size-small-standard-${index}`}
                    options={optionsFilieres}
                    title="Sélectionner une filière"
                    aria-required={true}
                    required={true}
                    getOptionLabel={(option) => option.label}
                    defaultValue={null}
                    clearIcon={null}
                    disabled={showDetails}
                    placeholder="Veuillez
                    sélectionner la
                    filière "
                    value={optionsFilieres.find((option) => option.value === input.filiere) || null}
                    onChange={(event, newValue) => {
                      if (newValue !== null && newValue !== undefined) {
                        const newInputs = [...inputs];
                        newInputs[index].filiere = newValue.value;
                        newInputs[index].niveaux = "";
                        newInputs[index].optionsLevel = newValue?.niveaux?.map((item) => ({
                          value: item._id,
                          label: item.name,
                        }));
                        setDepartementId(newValue?.departement);
                        setSelectedFiliere(newValue.value);
                        setInputs(newInputs);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required={true}
                        //value={input.facilityType}
                        placeholder="Veuillez
                        sélectionner la
                        filière "
                      />
                    )}
                  />
                  {input.filiere === "" && (
                    <Typography mt={0.75} variant="caption" color="error">
                      {errorMessageFiliere}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={inputs.length > 1 ? 3.75 : 6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Nom du tronc commun
                  </SoftTypography>
                  <Autocomplete
                    id="specialite"
                    options={optionsTroncCommun}
                    title="Sélectionner tronc commun"
                    aria-required={true}
                    required={true}
                    getOptionLabel={(option) => option.label}
                    defaultValue={null}
                    clearIcon={null}
                    disabled={showDetails}
                    value={optionsTroncCommun.find((option) => option.value === input.tronc) || null}
                    onChange={(event, newValue) => {
                      if (newValue !== null && newValue !== undefined) {
                        const newInputs = [...inputs];
                        newInputs[index].tronc = newValue.value;
                        setInputs(newInputs);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required={true}
                        placeholder="Veuillez
                        sélectionner
                        votre tronc commun"
                      />
                    )}
                  />              
                </Grid>{" "}
                <Grid item xs={12} sm={inputs.length > 1 ? 3.75 : 6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Nom spécialité*
                  </SoftTypography>
                  <Autocomplete
                    id="specialite"
                    options={options}
                    title="Sélectionner Spécialité"
                    aria-required={true}
                    required={true}
                    getOptionLabel={(option) => option.label}
                    defaultValue={null}
                    clearIcon={null}
                    disabled={showDetails}
                    value={options.find((option) => option.value === input.speciality) || null}
                    onChange={(event, newValue) => {
                      if (newValue !== null && newValue !== undefined) {
                        const newInputs = [...inputs];
                        newInputs[index].speciality = newValue.value;
                        setInputs(newInputs);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required={true}
                        value={input.facilityType}
                        placeholder="Veuillez
                        sélectionner
                        votre spécialité"
                      />
                    )}
                  />
                  {input.speciality === "" && (
                    <Typography mt={0.75} variant="caption" color="error">
                      {errorMessage}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={inputs.length > 1 ? 3.75 : 6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Niveau*
                  </SoftTypography>
                  <Autocomplete
                    title="Select niveau"
                    multiple
                    fullWidth
                    clearIcon={null}
                    options={inputs[index]?.optionsLevel}
                    value={
                      inputs[index].optionsLevel?.filter((option) =>
                        input?.niveaux.includes(option.value)
                      ) || []
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Veuillez
                      sélectionner
                      le/les niveaux de
                      votre parcours"
                      />
                    )}
                    onChange={(event, newValue) => {
                      // Clone the inputs state
                      const updatedInputs = [...inputs];
                      // Check if there's at least one input object
                      if (updatedInputs.length > 0) {
                        updatedInputs[index].niveaux = newValue.map((value) => value.value) || null;
                        // Set the updated state
                        setInputs(updatedInputs);
                      }
                    }}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                  />
                  {input.niveaux === "" && (
                    <Typography mt={0.75} variant="caption" color="error">
                      {errorMessageLevel}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
            <SoftButton
              size="small"
              onClick={handleClose}
              sx={{ mt: 2 }}
              variant="outlined"
              color="dark"
            >
              Annuler
            </SoftButton>
            {loading ? (
              <CircularProgress />
            ) : (
              <SoftButton
                size="small"
                onClick={handleAddParcours}
                sx={{ mt: 2 }}
                variant="gradient"
                color="purple"
              >
                Ajouter
              </SoftButton>
            )}
          </SoftBox>
        </DialogActions>
      </Dialog>
    </div>
  );
}
AddParcours.propTypes = {
  handleFetch: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  showDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
