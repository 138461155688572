import { useTheme } from "@emotion/react";
import { Box, Button, Card, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftEditor from "components/SoftEditor";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Field, Form, Formik } from "formik";
import DashboardNavbar from "layouts/DashboardNavbar";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { grey } from "material-ui-core/colors";
import SoftSelect from "components/SoftSelect";
import { useEffect, useState } from "react";
import validationSchema from "./validerChamps/validationSchema";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

import { DateRange } from "@mui/icons-material";
import Image from "./components/Image";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import api from "utils/axios";
import { useNavigate, useParams } from "react-router-dom";
import SelectData from "../data/SelectData";
import MDEditor from "components/SoftEditor";
import TextEditor from "./components/TextEditor";
import Swal from "sweetalert2";

function UpdateEventByStudent() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [statut, setStatut] = useState("A faire");

  const [updateEvent, setUpdateEvent] = useState([]);

  const { id } = useParams();
  const getEvent = (id) => {
    api.get(`get-event/${id}`).then((response) => {
      setUpdateEvent(response.data);
    });
  };

  useEffect(() => {
    if (updateEvent.image !== null) {
      setImage(updateEvent.image);
      // setUpdateEvent({ ...updateEvent, image: image });
    }
  }, [updateEvent.image]);

  const [showEndDateAndTime, setShowEndDateAndTime] = useState(false);
  const handleToggleEndDateAndTime = () => {
    setShowEndDateAndTime(!showEndDateAndTime);
  };

  useEffect(() => {
    getEvent(id);
  }, [id]);

  useEffect(() => {
    setShowEndDateAndTime(updateEvent.dateFin !== undefined);
  }, [updateEvent.dateFin]);

  const initialValues = {
    nom: updateEvent.nom,
    categorie: updateEvent.categorie,
    dateDebut: updateEvent.dateDebut,
    heureDebut: updateEvent.heureDebut,
    dateFin: updateEvent.dateFin,
    heureFin: updateEvent.heureFin,
    lieu: updateEvent.lieu,
    lieuPrecis: updateEvent.lieuPrecis,
    capacite: updateEvent.capacite,
    lien: updateEvent.lien,
    description: updateEvent.description,
    prixParticipant: updateEvent.prix,
    datesSelectionner: updateEvent.datesRecurrenceEvent,
  };

  //pour le payment
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (updateEvent.estGratuit !== isChecked) {
      setIsChecked(updateEvent.estGratuit);
    }
  }, [updateEvent.estGratuit]);

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setIsChecked(!checked);
    // Update the estGratuit property in updateEvent
    setUpdateEvent({ ...updateEvent, estGratuit: !checked });
  };

  //dialog
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [fullNamesStudent, setFullNamesStudent] = useState([]);
  const getFullNamesStudent = () => {
    api.get("get-user-commercial?role=student").then((response) => {
      setFullNamesStudent(
        response.data.map((student) => student.firstName + " " + student.lastName)
      );
    });
  };

  useEffect(() => {
    getFullNamesStudent();
  }, []);

  const updatedEvent = (id) => {
    if (
      updateEvent.nom?.length >= 2 &&
      updateEvent.categorie !== "" &&
      updateEvent.dateDebut !== "" &&
      updateEvent.heureDebut != "" &&
      updateEvent.lieu !== "" &&
      updateEvent.description !== "" &&
      JSON.parse(updateEvent.description).blocks[0].text.trim() !== "" &&
      ((updateEvent.lieu === "En présentiel" && updateEvent.lieuPrecis.length >= 3) ||
        (updateEvent.lieu === "En ligne" && updateEvent.lien.length >= 10))
    ) {
      let fd = new FormData();
      fd.append("image", image);
      fd.append("nom", updateEvent.nom);
      fd.append("categorie", updateEvent.categorie);
      fd.append("dateDebut", updateEvent.dateDebut);
      fd.append("heureDebut", updateEvent.heureDebut);
      if (updateEvent.dateFin !== undefined) {
        fd.append("dateFin", updateEvent.dateFin);
        fd.append("heureFin", updateEvent.heureFin);
      }
      fd.append("lieu", updateEvent.lieu);
      if (updateEvent.lieu === "En présentiel") {
        fd.append("lieuPrecis", updateEvent.lieuPrecis);
        fd.append("capacite", updateEvent.capacite === null ? 0 : updateEvent.capacite);
      }
      if (updateEvent.lieu === "En ligne") {
        fd.append("lien", updateEvent.lien);
      }
      fd.append("coOrganisateurs", updateEvent.coOrganisateurs);
      fd.append("description", updateEvent.description);
      fd.append("estGratuit", updateEvent.estGratuit);
      if (updateEvent.estGratuit === false) {
        fd.append("prix", updateEvent.prix);
      } else {
        fd.append("prix", 0);
      }
      fd.append("recurrenceEvent", updateEvent.recurrenceEvent);
      if (updateEvent.recurrenceEvent === "Personnalisé") {
        fd.append("datesRecurrenceEvent", updateEvent.datesRecurrenceEvent);
      } else {
        fd.append("datesRecurrenceEvent", []);
      }
      fd.append("statut", "A faire");

      api.put(`update-event/${id}`, fd).then((res) => {
        navigate("/events/kanbanEvents");
        Swal.fire({
          title: "Succès",
          text: "La mise à jour de la demande d'événement a été effectuée avec succès",
          icon: "success",
          confirmButtonText: "ok",
        });
      });
    } else {
      Swal.fire({
        title: "Warning",
        text: "Veuillez ajouter des informations correctes",
        icon: "warning",
        confirmButtonText: "ok",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ errors, touched, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
          <Form onSubmit={handleSubmit} style={{ marginTop: "8px" }}>
            <Box display="flex" alignItems="center" mt="16px">
              <Typography variant="h5" sx={{ flexGrow: "1" }} color={theme.palette.text.primary}>
                Editer Event
              </Typography>
              <SoftButton
                type="submit"
                variant="gradient"
                color="purple"
                onClick={() => {
                  updatedEvent(id);
                }}
              >
                Modifier
              </SoftButton>
            </Box>
            <Card
              sx={{
                border: `1px solid ${grey[200]}`,
                bgcolor: "white",
                borderRadius: "15px",
                p: "20px",
                mt: "16px",
                overflow: "visible",
              }}
            >
              <Image
                setImage={setImage}
                existingImageUrl={`${process.env.REACT_APP_BASE_URL}/${image}`}
              />
              <Grid container spacing={2}>

              {updateEvent.descriptionDemande !== "undefined" &&
                <Grid item xs={12}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Description demande
                  </SoftTypography>

                  <SoftInput
                    disabled
                    multiline
                    rows={3}
                    variant="standard"
                    value={updateEvent.descriptionDemande}
                    success={updateEvent.descriptionDemande?.length >0}
                  />
                </Grid>
              }
              
                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Nom de l&apos;événement*
                  </SoftTypography>
                  <Field name="nom">
                    {({ field }) => (
                      <SoftInput
                        {...field}
                        variant="standard"
                        placeholder="enter le nom de l'événement"
                        error={updateEvent.nom?.length < 2}
                        success={updateEvent.nom?.length >= 2}
                        onBlur={handleBlur}
                        value={updateEvent.nom}
                        onChange={(event) => {
                          setUpdateEvent({ ...updateEvent, nom: event.target.value });
                          setFieldValue("nom", event.target.value);
                        }}
                      />
                    )}
                  </Field>
                  {updateEvent.nom?.length < 2 && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "12px" }}
                      color="error"
                    >
                      {errors.nom}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Catégorie de l&apos;événement*
                  </SoftTypography>
                  <Field name="categorie">
                    {({ field }) => (
                      <SoftSelect
                        options={SelectData.categorie}
                        value={{
                          value: updateEvent.categorie,
                          label: updateEvent.categorie,
                        }}
                        placeholder="Type d'événement"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setUpdateEvent({ ...updateEvent, categorie: event.value });
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Date de Début*
                  </SoftTypography>

                  <Field name="dateDebut">
                    {({ field }) => (
                      <SoftInput
                        {...field}
                        variant="outlined"
                        type="date"
                        onBlur={handleBlur}
                        value={updateEvent.dateDebut}
                        onChange={(event) => {
                          setUpdateEvent({ ...updateEvent, dateDebut: event.target.value });
                          setFieldValue("dateDebut", event.target.value);
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Heure de Début*
                  </SoftTypography>
                  <Field name="heureDebut">
                    {({ field }) => (
                      <SoftInput
                        {...field}
                        variant="outlined"
                        type="time"
                        onBlur={handleBlur}
                        value={updateEvent.heureDebut}
                        onChange={(event) => {
                          setUpdateEvent({ ...updateEvent, heureDebut: event.target.value });
                          setFieldValue("heureDebut", event.target.value);
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Button onClick={handleToggleEndDateAndTime}>+ Date et heure de fin</Button>
                </Grid>
                {showEndDateAndTime && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Date de Fin*
                      </SoftTypography>
                      <Field name="dateFin">
                        {({ field }) => (
                          <SoftInput
                            {...field}
                            variant="outlined"
                            type="date"
                            value={updateEvent.dateFin}
                            onChange={(event) => {
                              setUpdateEvent({ ...updateEvent, dateFin: event.target.value });
                              setFieldValue("dateFin", event.target.value);
                            }}
                          />
                        )}
                      </Field>
                      {updateEvent.dateFin === undefined && !!touched.dateFin && (
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "400", fontSize: "12px" }}
                          color="error"
                        >
                          {errors.dateFin}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Heure de Fin*
                      </SoftTypography>
                      <Field name="heureFin">
                        {({ field }) => (
                          <SoftInput
                            {...field}
                            variant="outlined"
                            type="time"
                            value={updateEvent.heureFin}
                            onChange={(event) => {
                              setUpdateEvent({ ...updateEvent, heureFin: event.target.value });
                              setFieldValue("heureFin", event.target.value);
                            }}
                          />
                        )}
                      </Field>
                      {updateEvent.heureFin === undefined && !!touched.heureFin && (
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "400", fontSize: "12px" }}
                          color="error"
                        >
                          {errors.heureFin}
                        </Typography>
                      )}
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6} mt={2}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Lieu de l&apos;événement*
                  </SoftTypography>

                  <Field name="lieu">
                    {({ field }) => (
                      <SoftSelect
                        options={SelectData.lieuEvent}
                        placeholder="Est-ce En présentiel ou en ligne ?"
                        value={{
                          value: updateEvent.lieu,
                          label: updateEvent.lieu,
                        }}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setUpdateEvent({ ...updateEvent, lieu: event.value });
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                {updateEvent.lieu === "En présentiel" && (
                  <Grid item xs={12} sm={6} mt={2}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Lieu précis de l&apos;événement*
                    </SoftTypography>
                    <Field name="lieuPrecis">
                      {({ field }) => (
                        <SoftInput
                          {...field}
                          variant="outlined"
                          placeholder="Lieu précis"
                          value={updateEvent.lieuPrecis}
                          error={updateEvent.lieuPrecis?.length < 3 && !!touched.lieuPrecis}
                          success={updateEvent.lieuPrecis?.length >= 3}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            setUpdateEvent({ ...updateEvent, lieuPrecis: event.target.value });
                          }}
                        />
                      )}
                    </Field>
                    {updateEvent.lieuPrecis?.length < 3 && !!touched.lieuPrecis && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "400", fontSize: "12px" }}
                        color="error"
                      >
                        {errors.lieuPrecis}
                      </Typography>
                    )}
                  </Grid>
                )}
                {updateEvent.lieu === "En ligne" && (
                  <Grid item xs={12} sm={6} mt={2}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Lien de l&apos;événement*
                    </SoftTypography>
                    <Field name="lien">
                      {({ field }) => (
                        <SoftInput
                          {...field}
                          variant="outlined"
                          placeholder="lien"
                          value={updateEvent.lien}
                          error={updateEvent.lien?.length < 10 && !!touched.lien}
                          success={updateEvent.lien?.length >= 10}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            setUpdateEvent({ ...updateEvent, lien: event.target.value });
                          }}
                        />
                      )}
                    </Field>
                    {updateEvent.lien?.length < 10 && !!touched.lien && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "400", fontSize: "12px" }}
                        color="error"
                      >
                        {errors.lien}
                      </Typography>
                    )}
                  </Grid>
                )}
                <Grid item xs={12} sm={6} mt={2}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Capaciter des personnes dans l&apos;événement*
                  </SoftTypography>
                  <Field name="capacite">
                    {({ field }) => (
                      <SoftInput
                        {...field}
                        type="number"
                        variant="standard"
                        placeholder="capacite"
                        value={updateEvent.capacite}
                        error={updateEvent.capacite === 0 && !!touched.capacite}
                        success={updateEvent.capacite > 0}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          setUpdateEvent({ ...updateEvent, capacite: event.target.value });
                        }}
                      />
                    )}
                  </Field>
                  {updateEvent.capacite === 0 && !!touched.capacite && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "12px" }}
                      color="error"
                    >
                      {errors.capacite}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Ajouter des organisateurs
                  </SoftTypography>
                  <SoftSelect
                    options={fullNamesStudent.map((fullName) => ({
                      value: fullName,
                      label: fullName,
                    }))}
                    placeholder="Choisir les coorganisateurs"
                    size="medium"
                    isMulti
                    value={
                      updateEvent.coOrganisateurs
                        ? updateEvent.coOrganisateurs.map((coOrganisteurs) => {
                            return {
                              value: coOrganisteurs,
                              label: coOrganisteurs,
                            };
                          })
                        : []
                    }
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map((option) => option.value);

                      setUpdateEvent({ ...updateEvent, coOrganisateurs: selectedValues });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    description de l&apos;événement*
                  </SoftTypography>
                  <TextEditor
                    value={updateEvent.description}
                    onChange={(event) => {
                      setUpdateEvent({ ...updateEvent, description: event });
                    }}
                  />
                  {updateEvent.description === "<p></p>" && !!touched.description && (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "400", fontSize: "12px" }}
                      color="error"
                    >
                      {errors.description}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} display="flex">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5, mr: { xs: "100px", md: "200px" } }}
                  >
                    Événement :
                  </SoftTypography>
                  <FormControlLabel
                    control={<Checkbox checked={!isChecked} onChange={handleCheckboxChange} />}
                    label="Payant"
                  />
                </Grid>
                {!isChecked && (
                  <Grid item xs={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Prix participation :
                    </SoftTypography>
                    <Field name="prixParticipant">
                      {({ field }) => (
                        <SoftInput
                          {...field}
                          variant="standard"
                          placeholder="Prix de la participation"
                          type="number"
                          value={updateEvent.prix}
                          error={updateEvent.prix === 0 && !!touched.prixParticipant}
                          success={updateEvent.prix > 0}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            handleChange(event);

                            setUpdateEvent({ ...updateEvent, prix: event.target.value });
                          }}
                        />
                      )}
                    </Field>
                    {updateEvent.prix === 0 && !!touched.prixParticipant && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "400", fontSize: "12px" }}
                        color="error"
                      >
                        {errors.prixParticipant}
                      </Typography>
                    )}
                  </Grid>
                )}
                {isChecked && <Grid item xs={6}></Grid>}
                <Grid item xs={12} sm={updateEvent.recurrenceEvent === "Jamais" ? 12 : 6}>
                  <SoftTypography variant="caption" color="text">
                    Récurrence de l&apos;événement
                  </SoftTypography>
                  <SoftSelect
                    options={SelectData.recurenceEvent}
                    value={SelectData.recurenceEvent.find(
                      (recur) => recur.value === updateEvent.recurrenceEvent
                    )}
                    onChange={(event) => {
                      setUpdateEvent({ ...updateEvent, recurrenceEvent: event.value });
                    }}
                  />
                </Grid>
                {updateEvent.recurrenceEvent === "Personnalisé" && (
                  <Grid item xs={12} sm={6} sx={{ display: "block" }}>
                    <Box ml={{ sx: "0px", md: "8px", position: "relative" }}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Dates sélectionnées
                      </SoftTypography>

                      <Field name="datesSelectionner">
                        {({ field }) => (
                          <Box sx={{ position: "relative", width: "100%" }}>
                            <DatePicker
                              placeholder="jj / mm / aaaa --:--"
                              multiple
                              format="DD/MM/YYYY HH:mm:ss"
                              plugins={[
                                <TimePicker key="timePicker" position="bottom" />,
                                <DatePanel key="datePannel" />,
                              ]}
                              style={{
                                width: "100%",
                                position: "absolute",
                                right: "0px",
                                left: "0px",
                                top: "0px",
                                padding: "20px 40px 20px 12px",
                                cursor: "pointer",
                                borderRadius: "10px",
                              }}
                              value={updateEvent.datesRecurrenceEvent}
                              onChange={(selectedOptions) => {
                                setUpdateEvent({
                                  ...updateEvent,
                                  datesRecurrenceEvent: selectedOptions.map((option) =>
                                    option.format()
                                  ),
                                });
                              }}
                            />
                            <Box
                              sx={{
                                position: "absolute",
                                top: "70%",
                                right: "12px",
                                transform: "translateY(-50%)",
                              }}
                            >
                              <DateRange
                                sx={{ width: "16px ! important", height: "16px !important" }}
                              />
                            </Box>
                          </Box>
                        )}
                      </Field>
                      {updateEvent.datesRecurrenceEvent == "" && !!touched.datesSelectionner && (
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "400", fontSize: "12px", mt: "10px" }}
                          color="error"
                        >
                          {errors.datesSelectionner}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Card>
          </Form>
        )}
      </Formik>
    </DashboardLayout>
  );
}

export default UpdateEventByStudent;
