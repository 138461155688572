import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import api from "utils/axios";

import PropTypes from "prop-types";
import { Card, Grid } from '@mui/material';

import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftSelect from 'components/SoftSelect';
import Switch from "@mui/material/Switch";
import LoadingAnimation from "components/LoadingAnimation";
import FormField from "pages/billing-settings/components/FormField";
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSoftUIController } from "context";

const ALERT_INIT_STATE = { show: false, message: "", type:"" };


const LicenceModifier = ({ id }) => {
  const [{ user }, dispatch] = useSoftUIController();
  const [licence, setLicence] = useState(null);
  const [addPeriodDialog, setAddPeriodDialog] = useState({ show: false, startAt: Math.floor(Date.now() / 1000), endAt: Math.floor((Date.now() / 1000) + 24 * 60 * 60 ), error: null });
  const [bytes, setBytes] = useState(null);
  const [usersCount, setUsersCount] = useState(null);
  const [support, setSupport] = useState(null);
  const [periodList, setPeriodList] = useState(null);
  let { current: toBeAlteredPeriod } = useRef({});
  const [tab, setTab] = useState(1);
  const navigate = useNavigate();
  const [alert, setAlert] = useState(ALERT_INIT_STATE);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);


  const clearAlert = (ms) => {
    setTimeout(() => {
      setAlert(ALERT_INIT_STATE);
    }, ms);
  };

  const setConfigStates = (configs) => {
    setLicence(configs);
    setBytes({ size: configs.remainingDataSizeMb, unit: 'inMB' });
    setUsersCount(Number(configs.remainingUser));
    setSupport(configs.support);
    setPeriodList(configs.activePeriods?.map(({ startAt, endAt}) => ({ startAt, endAt, error: false, toDelete: false })));
  }

  useEffect(() => {
    if (!id) return;
    const userRole = user?.info?.role;

    if (userRole !== 'super-admin' && userRole !== 'commercial') {
      console.warn(`user with role = '${userRole}' is UNAUTHORIZED...`);
      navigate('/');
    }

    api.get(`get-licence-by-id/${id}`).then(({ data }) => {
      
      console.debug(data);
      setConfigStates(data);

    }).catch((error) => {
      console.error(error);
    }).finally(() => {
    })

  }, [id]);

  const addPeriodDialogHandler = (show) => setAddPeriodDialog((prev) => ({
    ...prev,
    show
  }));

  const addPeriodStartAtHandler = (date) => {
    setAddPeriodDialog((prev) => ({
      ...prev,
      startAt: Math.floor(new Date(date).getTime() / 1000)
    }))
  };
  
  const addPeriodEndAtHandler = (date) => {
    setAddPeriodDialog((prev) => ({
      ...prev,
      endAt: Math.floor(new Date(date).getTime() / 1000)
    }))
  };


  const changeBytesUnitHandler = (unit) => {
    if (bytes.unit === unit) return;
    if (unit === 'inGB') setBytes(prev => ({ size: (prev.size / 1024 ), unit }));
    else if (unit === 'inMB') setBytes(prev => ({ size: (prev.size * 1024 ), unit }));
  };

  const submitHandler = () => {
    setDisableSaveBtn(true);
    const changes = [];

    // storage changees check (if it has been modified then include that difference in a requests )
    if (bytes.size !== (bytes.unit === 'inMB' ? licence.remainingDataSizeMb : (licence.remainingDataSizeMb / 1024))) {
      if (bytes.unit === 'inGB') {
        changes.push(
          { 
            action: bytes.size > (licence.remainingDataSizeMb / 1024) ? 'INC_STORAGE' : 'DEC_STORAGE', 
            payload: { inGB: +Math.abs(bytes.size - (licence.remainingDataSizeMb / 1024)).toFixed(3) }
          }
        );
      } else {
        changes.push(
          { 
            action: bytes.size > licence.remainingDataSizeMb ? 'INC_STORAGE' : 'DEC_STORAGE', 
            payload: { inMB: +Math.abs(bytes.size - licence.remainingDataSizeMb).toFixed(3) }
          }
        );
      }
    }

    // users count check (if it has been modified then include that diffenrece in a request )
    if (usersCount !== licence.remainingUser) {
      changes.push(
        {
          action:( usersCount - licence.remainingUser > 0) ? 'INC_USERS' : 'DEC_USERS',
          payload: { usersCount: Math.abs(usersCount - licence.remainingUser) }
        }
      );
    }

    // support changes check
    if (support !== licence.support) {
      changes.push({ action: 'SUPPORT', payload : { setSupport: support } });
    }

    // altered periods check
    let periodsValidity = true;

    for (const { error } of periodList) if (error) periodsValidity = false;

    if (periodsValidity) {
      for (const key in toBeAlteredPeriod) {
        changes.push(
          {
            action: "ALTER_PERIOD",
            payload: {
              index: Number(key),
              startAt: toBeAlteredPeriod[key].startAt,
              endAt: toBeAlteredPeriod[key].endAt
            }
          }
        )
      }
    }


    api.patch(`update-enterprise-licence/${id}`, changes).then(({data}) => {
      const configs = data?.updated;
      for (const key in toBeAlteredPeriod) delete toBeAlteredPeriod[key]; // clean up
      setConfigStates(configs);
      setAlert({ show: true, message: "la configuration de la licence a été mise à jour avec succès", type: "success"});
    }).catch((error) => {
      setAlert({ show: true, message: error.message, type: "error"});
    }).finally(() => {
      setDisableSaveBtn(false);
      clearAlert(5_000);
    });


    console.log("submit...", changes);
  }

  const setNewPeriodHandler = () => {
    if (addPeriodDialog.startAt < Math.floor(Date.now() / 1000) || addPeriodDialog.endAt < Math.floor(Date.now() / 1000) || addPeriodDialog.startAt >= addPeriodDialog.endAt) {
      return setAddPeriodDialog(prevState => ({...prevState, error: 'début du period et/ou la fin a un "timestamp" dans le passée.'}));
    }

    const reservedPeriodsTimeLine = [
      ...periodList,
      { startAt: addPeriodDialog.startAt, endAt: addPeriodDialog.endAt }
    ];


    reservedPeriodsTimeLine.sort((p1, p2) => (p1.startAt - p2.startAt));
    
    for (let i = 1; i < reservedPeriodsTimeLine.length; i += 1) {
      if (reservedPeriodsTimeLine[i-1].endAt > reservedPeriodsTimeLine[i].startAt) {
        // error overlappping periods
        // show error message into the dialog...
        return setAddPeriodDialog(prevState => ({...prevState, error: 'périodes superposées'}));
      }
    }

    // send the changes and clear the state
    api.patch(`update-enterprise-licence/${id}`, { action : 'SET_NEW_PERIOD', payload: { startAt: addPeriodDialog.startAt, endAt: addPeriodDialog.endAt }})
    .then(({ data }) => {
      setConfigStates(data.updated);
      setAddPeriodDialog({ show: false, startAt: Math.floor(Date.now() / 1000), endAt: Math.floor((Date.now() / 1000) + 24 * 60 * 60 ), error: null });
    }).catch((error) => {
      console.log(error);
      setAddPeriodDialog(prevState => ({...prevState, error: error.message }));
    });
  };


  const alterPeriodHandler = ({ index, startAt, endAt }) => {

    // guard : we can not alter start or end of periods in the past.
    if (startAt && periodList[index].startAt <= Math.floor(Date.now() / 1000)) return;
    if (endAt && periodList[index].endAt <= Math.floor(Date.now() / 1000)) return;

    // guard : if there are no changes return
    if ((startAt && periodList[index].startAt === startAt) || (endAt && periodList[index].endAt === endAt)) return;

    setPeriodList((prevState) => {
      const newState = [...prevState];
      // guard : if startAt and endAt both are false values (NaN) - not a number (invalid date like 31/06/2026)
      if (!startAt && !endAt) {
        newState[index].error = true;
        return newState;
      }

      if (startAt) newState[index].startAt = startAt;
      if (endAt) newState[index].endAt = endAt;

      if (index === 0) {
        // first period verification
        newState[0].error = (startAt && newState[0].startAt <= Math.floor(Date.now() / 1000)) || (newState[0].endAt > (newState.length > 1 ? newState[1].startAt : Infinity));
      } else if (index < newState.length - 1) {
        newState[index].error = (newState[index-1].endAt > newState[index].startAt) || (newState[index].endAt > newState[index+1].startAt);
      } else {
        // last period verification
        newState[index].error = newState[index-1].endAt > newState[index].startAt;
      }

      // user set the start of the period after the end of it... (he can do this by writing the date manully with the keyboard)
      if (newState[index].startAt > newState[index].endAt) {
        console.warn('start after end of the period index = ', index);
        newState[index].error = true;
      }

      if (!newState[index].error) {
        // if there are now error with this altered period log it index to be altered.
        if (!toBeAlteredPeriod[index]) toBeAlteredPeriod[index] = {};
        if (startAt) toBeAlteredPeriod[index].startAt = startAt; // mark it as to be changed period
        if (endAt) toBeAlteredPeriod[index].endAt = endAt;
      }

      return newState;
    });

  };


  const deletePeriodHandler = (index) => {
    if (!index || index >= periodList.length) return;

    api.patch(`update-enterprise-licence/${id}`, {
      action: "ALTER_PERIOD",
      payload: {
        index: Number(index),
        startAt: licence.activePeriods[index].startAt,
        endAt: licence.activePeriods[index].startAt
      }
    }).then(({ data }) => {
      
      delete toBeAlteredPeriod[index]; // the api have delete it succussfully so delete it from the to be altered periods if it has ben added
      setAlert({ show: true, message: "la period d'activation a été supprimer avec succès", type: "success"});

      setConfigStates(data.updated);
    }).catch((error) => {
      setAlert({ show: true, message: error.message, type: "error"});
    }).finally(() => {
      clearAlert(5_000);
    });
  }

  return (
    <Card id="basic-info" sx={{ overflow: "scroll" }}>
      <AppBar position="static">
        <Tabs orientation={"horizontal"} value={tab} onChange={(e, v) => setTab(prevTab => prevTab === 1 ? 2 : 1)}>
          <Tab value={1} label="licence configuration" />
          <Tab value={2} label="history" />
        </Tabs>
      </AppBar>
      <Dialog open={addPeriodDialog.show} onBackdropClick={addPeriodDialogHandler.bind(null, false)}>
        <DialogTitle>{"Créer une nouvelle période"}</DialogTitle>
        <DialogContent>
          <SoftBox my={1}>
            <SoftTypography variant="h6"> début </SoftTypography>
            <SoftInput
              type = "date"
              onChange={ e => addPeriodStartAtHandler(e.target.value) }
              inputProps={{
                min: unixTimestampToStrDate(),
                max: unixTimestampToStrDate(addPeriodDialog.endAt)
              }}
              defaultValue={unixTimestampToStrDate(addPeriodDialog.startAt)}
              size="small"
            />
          </SoftBox>
          <SoftBox my={1}>
            <SoftTypography variant="h6"> fin </SoftTypography>
            <SoftInput
              type="date"
              onChange={ e => addPeriodEndAtHandler(e.target.value) }
              inputProps={{
                min: unixTimestampToStrDate(addPeriodDialog.startAt)
              }}
              defaultValue={unixTimestampToStrDate(addPeriodDialog.endAt)}
              size="small"
            />
          </SoftBox>
          { addPeriodDialog.error && <Alert severity="error">{ addPeriodDialog.error }</Alert> }
        </DialogContent>
        <DialogActions>
          <Button onClick = { addPeriodDialogHandler.bind(null, false) }> Cancel </Button>
          <Button onClick={setNewPeriodHandler}> Enregistrer </Button>
        </DialogActions>
      </Dialog>

      <SoftBox p={3}>
        <SoftTypography variant="h5">
          { tab === 1 ? `Mettre à jour la configuration de la licence` : `history`}
        </SoftTypography>
      </SoftBox>
      <SoftBox p={3}>
        { 
          tab === 1 ?
          <SoftBox sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid container spacing={3}>
            {
              (!periodList || !bytes || usersCount === null || support === null)
              ? <LoadingAnimation />
              : (
                <>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <FormField
                        label="remaining users"
                        inputProps={{ type: "number", min: 1, max: 32000 }} defaultValue={usersCount}
                        onChange = {
                          (e) => {
                            setUsersCount(Number(e.target.value));
                          }
                        }
                        // error={!inputsValidity.devisCtrStartValidity}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormField
                        label="remaining storage"
                        inputProps={{ type: "number", min: 1, max: 32000, step: 0.1 }} value={bytes.size}
                        onChange = {
                          (e) => {
                            setBytes(prev => ({ ...prev, size: +e.target.value }));
                          }
                        }
                        // error={!inputsValidity.factureCtrStartValidity}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            unity
                          </SoftTypography>
                        </SoftBox>
                        <SoftSelect
                          options={[{label: 'MB', value: 'inMB'}, { label: 'GB', value: 'inGB'}]}
                          defaultValue={ bytes.unit === 'inMB' ? {label: 'MB', value: 'inMB'} : { label: 'GB', value: 'inGB'}}
                          onChange={(e) => changeBytesUnitHandler(e.value)}
                        />
                      </SoftBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <SoftBox
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      width={{ xs: "100%", sm: "auto" }}
                      mt={{ xs: 1, sm: 0 }}
                      >
                        <SoftBox mr={1}>
                          <Switch defaultChecked={support} onChange={(e) => setSupport(e.target.checked) } />
                        </SoftBox>
                        <SoftBox lineHeight={0} mx={1}>
                          <SoftTypography variant="button" color="text" fontWeight="regular">
                            Support
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} mt={3}>
                    <Grid item xs={12} sm={4}>
                      <SoftTypography variant="h5" >
                        Durées de licence activées
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12} sm={4} display="flex" justifyContent="flex-end">
                      <SoftButton
                        variant="gradient"
                        color="customized"
                        size="small"
                        onClick={addPeriodDialogHandler.bind(null, true)}
                      >
                        Nouvelle période
                      </SoftButton>
                    </Grid>
                  </Grid>
                  {/* periods list ... */}
                  {
                    periodList.map(({ startAt, endAt, error, toDelete }, index) => {
                      return (
                        <Grid key={`${index}-${startAt}-${endAt}`} container spacing={2} mt={2}>
                          <Grid item xs={12} sm={4}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              {`Début du periode ${index+1}`}
                            </SoftTypography>
                            <SoftInput
                              id="pn-start"
                              type="date"
                              inputProps={{
                                min: unixTimestampToStrDate(Math.max(Math.floor(Date.now() / 1000), (index > 0 ? periodList[index-1].endAt : 0))),
                                max: unixTimestampToStrDate(endAt)
                              }}
                              defaultValue={unixTimestampToStrDate(startAt)}
                              // value={companyAdmin.birthday}
                              onBlur={(e) => alterPeriodHandler({ index, startAt: Math.floor((new Date(e.target.value)).getTime() / 1000) })}
                              error={error}
                              readOnly = {(Math.floor(Date.now() / 1000) > startAt ) || toDelete }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              {`Fin du periode ${index+1}`}
                            </SoftTypography>
                            <SoftInput
                              id="pn-end"
                              type="date"
                              inputProps={{
                                min: unixTimestampToStrDate(Math.max(Math.floor(Date.now() / 1000), startAt)),
                                max: (index < periodList.length - 1) ? unixTimestampToStrDate(periodList[index+1].startAt) : undefined
                              }}
                              defaultValue={unixTimestampToStrDate(endAt)}
                              // value={companyAdmin.birthday}
                              onBlur={(e) => alterPeriodHandler({ index, endAt: Math.floor((new Date(e.target.value)).getTime() / 1000) }) }
                              error = {error}
                              readOnly = {(Math.floor(Date.now() / 1000) > endAt) || toDelete }
                            />
                          </Grid>
                          {
                            (Math.floor(Date.now() / 1000) < startAt) &&
                            <Grid item xs={12} sm={4}>
                              <SoftBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                                <DeleteIcon fontSize="large"  sx={{ color: "red", cursor: 'pointer' }} onClick={deletePeriodHandler.bind(null, index)} />
                                {/* <SoftBox>
                                  <Checkbox checked={toDelete} onChange={deletePeriodHandler.bind(null, index)} /> supprimer
                                </SoftBox> */}
                              </SoftBox>
                            </Grid>
                          }
                        </Grid>
                      );
                    })
                  }
                </Grid>
                </>
              )
            }
            <Grid container mt={2} spacing={2}>
              <Grid item xs={12} sm={6}>
                {
                  alert.show &&
                  <SoftBox pl={3}>
                    <Alert severity={alert.type}> {alert.message} </Alert>
                  </SoftBox>
                }
              </Grid>
              <Grid item xs={12} sm={2}>
                <SoftBox
                  display="flex"
                  justifyContent="end"
                >  
                {
                  <SoftButton
                    variant="gradient"
                    color="customized"
                    size="medium"
                    onClick={submitHandler}
                    disabled={disableSaveBtn}
                  >
                    Enregistrer
                  </SoftButton>
                }
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
          </SoftBox>
          :
          licence?.history?.map((action, index) => (
            <SoftTypography variant="h6" key={Math.random()}> 
              {`${index+1} - [${new Date(action.timestamp*1000).toLocaleString('fr-FR')}] ${action.description}.`}
            </SoftTypography>
            )
          )
        }
      </SoftBox>
    </Card>
  );
};

LicenceModifier.propTypes = {
  id: PropTypes.string.isRequired,
};


function unixTimestampToStrDate (timestamp = null) {
  const date = timestamp !== null ? new Date(timestamp * 1000) : new Date();
  return `${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
}

export default LicenceModifier;
