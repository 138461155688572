const SelectData = {
  type: [
    { value: "Révision de note", label: "Révision de note" },
    { value: "Attestation de présence", label: "Attestation de présence" },
    { value: "Attestation d'inscription", label: "Attestation d'inscription" },
    { value: "Relever de notes", label: "Relever de notes" },
    { value: "Carte étudiant", label: "Carte étudiant" },
    { value: "Attestation de réussite", label: "Attestation de réussite" },
    { value: "Réclamation", label: "Réclamation" },
    { value: "Report d'inscription", label: "Report d'inscription" },
    { value: "Demande de départ", label: "Demande de départ" },
    { value: "Événement", label: "Événement" },
    { value: "Autre", label: "Autre" },
  ],
  evaluation: [
    { value: "Valider", label: "Valider" },
    { value: "Non valider", label: "Non valider" },
  ],
  categorie: [
    { value: "Événement artistique ou culturel", label: "Événement artistique ou culturel" },
    { value: "Événement en ligne", label: "Événement en ligne" },
    { value: "Événement sportif", label: "Événement sportif" },
    { value: "Club", label: "Club" },
    { value: "Conférence", label: "Conférence" },
    { value: "Sortie scolaire/d'étude", label: "Sortie scolaire/d'étude" },
    { value: "Réunion parents", label: "Réunion parents" },
    { value: "Autre", label: "Autre" },
  ],
  lieuEvent: [
    { value: "En présentiel", label: "En présentiel" },
    { value: "En ligne", label: "En ligne" },
  ],
  recurenceEvent: [
    { value: "Jamais", label: "Jamais" },
    { value: "Personnalisé", label: "Personnalisé" },
  ],
};
export default SelectData;
