import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect, useState } from "react";
import {
  AppBar,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from "@mui/material";
import DashboardNavbar from "layouts/DashboardNavbar";
import SoftBox from "components/SoftBox";
import breakpoints from "assets/theme/base/breakpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ComplexProjectCard from "./components/Cards";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import SoftPagination from "components/SoftPagination";
import Header from "layouts/pages/profile/components/Header";
import api from "utils/axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SoftSnackbar from "components/SoftSnackbar";
import { useSoftUIController } from "context";

function AllStudents() {
  const facilityStorage = localStorage.getItem("facility");
  const facility = facilityStorage && JSON.parse(facilityStorage);
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const navigate = useNavigate();
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [errorSB, setErrorSB] = useState(false);
  const [tabValue, setTabValue] = useState("all");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const handleSetTabValue = (_, newValue) => setTabValue(newValue);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [controller] = useSoftUIController();
  const userLogin = controller?.user?.info;

  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(
              `/facilities/${facility._id}/users/${selectedStudentId}`
            );
            if (response) {
              (await Swal.fire("Supprimé !", "L'étudiant a été supprimé.", "success"))
                .isConfirmed && handleFetch();
              closeSlackBotMenu();
            }
          } catch (error) {
            Swal.fire("Une erreur s'est produite !", "L'étudiant n'a pas été supprimé.", "error");
            closeSlackBotMenu();
          }
        }
      });
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={() => navigate(`/administration/students/view/${selectedStudentId}`)}>
        {" "}
        <RemoveRedEyeIcon /> &nbsp; Voir plus
      </MenuItem>
      <MenuItem onClick={() => navigate(`/administration/students/${selectedStudentId}`)}>
        {" "}
        <EditIcon /> &nbsp; Modifier
      </MenuItem>
      <MenuItem onClick={showAlert}>
        {" "}
        <DeleteIcon />
        &nbsp; Supprimer
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleFetch = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/get-all-students-with-and-without-responsable/${facility._id}`
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };

  useEffect(() => {
    document.title = "étudiant";
  }, []);

  const filteredData = () => {
    const students =
      tabValue === "all"
        ? data?.students
        : tabValue === "student"
        ? data?.studentsWithResponsable
        : data?.studentsWithoutResponsable;

    return students?.filter((student) => {
      const searchQueryLower = searchQuery?.toLowerCase();
      return (
        student?.firstName?.toLowerCase().includes(searchQueryLower) ||
        student?.lastName?.toLowerCase().includes(searchQueryLower) ||
        student?.phoneNumber?.toLowerCase().includes(searchQueryLower)
      );
    });
  };

  const currentData = filteredData()?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredData()?.length / itemsPerPage);

  useEffect(() => {
    handleFetch();
  }, [tabValue]);

  const renderErrorSB = (
    <SoftSnackbar
      color="error"
      icon="warning"
      title="Ajouter un responsable"
      content="Vous avez atteint le nombre maximum d'utilisateurs"
      dateTime="11 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );
  const licence = localStorage.getItem("licence");
  if (!licence) return;
  const parsedLicence = JSON.parse(licence);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header
        title="Liste des étudiants"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        searchVisible={true}
        ExportVisible={true}
      />

      <Grid>
        <SoftBox mt={2}>
          <Grid container>
            <Grid item xs={12} sm={12} lg={6}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                >
                  <Tab id="tab-all" value="all" label="Tout" />
                  <Tab id="tab-student" value="student" label="Avec responsable" />
                  <Tab id="tab-responsable" value="responsable" label="Sans responsable" />
                  {facility.type === "faculty" && (
                    <Tab id="tab-parcours" value="parcours" label="Sans parcours" />
                  )}
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
          <Grid marginLeft={172}></Grid>
        </SoftBox>
      </Grid>

      {loading ? (
        <SoftBox
          sx={{
            mt: 13,
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </SoftBox>
      ) : (
        <>
          <SoftBox pb={2}>
            <SoftBox mt={{ xs: 1, lg: 2 }} mb={1}>
              <Grid container spacing={3}>
                {tabValue === "all" &&
                  userLogin?.role !== "instructor" &&
                  userLogin?.role !== "responsable" &&
                  userLogin?.role !== "student" && (
                    <Grid
                      onClick={() =>
                        parsedLicence.remainingUser === 0
                          ? openErrorSB()
                          : navigate("/administration/students/add-student")
                      }
                      item
                      xs={12}
                      md={20}
                      lg={4}
                      sx={{ cursor: "pointer" }}
                    >
                      <PlaceholderCard
                        title={{ variant: "h5", text: "Ajouter étudiant" }}
                      ></PlaceholderCard>
                    </Grid>
                  )}
                {currentData &&
                  currentData?.map((user) => (
                    <Grid key={user?._id} item xs={12} md={6} lg={4}>
                      <ComplexProjectCard
                        image={
                          user?.imageUrl?.startsWith("https")
                            ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                            : `${process.env.REACT_APP_BASE_URL}/${user?.imageUrl}`
                        }
                        title={`${user.firstName} ${user.lastName}`}
                        phone={user.phoneNumber}
                        email={user.email}
                        description=""
                        occupation={user.occupation}
                        responsable={user?.responsable?.firstName}
                        userId={user?._id}
                        etat={user?.etat}
                        disabled={
                          userLogin?.role === "responsable" ||
                          userLogin?.role === "instructor" ||
                          userLogin?.role === "student"
                        }
                        dropdown={{
                          action: (e) => {
                            setSlackBotMenu(e.currentTarget);
                            setSelectedStudentId(user._id);
                          },
                          menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </SoftBox>
          </SoftBox>
          {totalPages > 1 && (
            <SoftPagination>
              {currentPage > 1 && (
                <SoftPagination item onClick={prevPage}>
                  <ArrowBackIosIcon />
                </SoftPagination>
              )}
              {Array.from({ length: totalPages }, (_, index) => (
                <SoftPagination
                  item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                </SoftPagination>
              ))}
              {currentPage < totalPages && (
                <SoftPagination item onClick={nextPage}>
                  <ArrowForwardIosIcon />{" "}
                </SoftPagination>
              )}
            </SoftPagination>
          )}
        </>
      )}
      {renderErrorSB}
    </DashboardLayout>
  );
}
export default AllStudents;
