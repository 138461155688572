import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { pink } from "@mui/material/colors";
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard PRO React example components
import EventCalendar from "../../shared/Calendar";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import { Dialog } from "@mui/material";
import SeanceDialog from "./seance-components/SeanceDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "utils/axios";
import Swal from "sweetalert2";
export default function InfoEmploi({ formData }) {
  const [open, setOpen] = useState(false);
  const [jour, setJour] = useState("");
  const [seances, setSeances] = useState([]);
  const [loading, setLoading] = useState(false);
  const { values } = formData;
  const {
    enseignant,
    optionsClasses,
    jours,
    dateDebut,
    dateFin,
    heureDebut,
    heureFin,
    planningId,
    instructorId,
  } = values;
  const filteredJours = jours?.filter((jour) => jour.checked === true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleFetch = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/get-all-sessions/${planningId}`);
      setSeances(response?.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };
  useEffect(() => {
    if (planningId) {
      handleFetch();
    }
  }, []);
  const eventContent = (arg) => {
    const cardStyle = {
      padding: "8px 8px",
      backgroundColor: "#7f47e8",
      color: "#333",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "1em",
      marginTop: "4px",
      height: "98%",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      position: "relative", // Ajout de position relative pour positionner les icônes
      display: "flex", // Use flexbox
      justifyContent: "space-between", // Align items with space between
      flexDirection: "column",
    };
    const titleStyle = {
      fontSize: "0.9em",
      lineHeight: "1",
      color: "#fff",
      fontWeight: "bold",
      display: "flex", // Add display flex
      justifyContent: "space-between", // Add space between the two elements
    };
    const timeTextStyle = {
      fontSize: "0.7em",
      color: "#fff",
      fontStyle: "italic",
      display: "flex",
      justifyContent: "space-between",
    };
    const instructorStyle = {
      fontSize: "0.7em", // Adjust the size as needed
      color: "#ffcc00", // Adjust the color as needed
    };
    const iconStyle = {
      position: "absolute",
      top: "-9px", // Ajuster la position verticale des icônes
      right: "10px", // Ajuster la position horizontale des icônes
      display: "flex",
      alignItems: "center",
    };

    const handleDeleteClick = async () => {
      const newSwal = Swal.mixin({
        customClass: {
          confirmButton: "button button-success",
          cancelButton: "button button-error",
        },
        buttonsStyling: false,
      });
      newSwal
        .fire({
          title: "Êtes-vous sûr(e) ?",
          text: "Vous voulez supprimer cette séance ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui, supprimer!",
          cancelButtonText: "Annuler",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await api.delete(`/delete-session/${arg.event.id}`);
              if (response) {
                (await Swal.fire("Suppression !", "La séance a été supprimée.", "success"))
                  .isConfirmed && handleFetch();
              }
            } catch (error) {
              Swal.fire("SErreur!", "La séeance n'a pas été supprimée.", "error");
            }
          }
        });
    };
    return (
      <div style={cardStyle}>
        <div style={iconStyle}>
          <span style={{ marginRight: "10px" }}>
            <DeleteIcon sx={{ color: pink[500] }} onClick={handleDeleteClick} />
          </span>
        </div>
        <div style={titleStyle}>
          <span>{arg.event.title}</span>
          <span>{arg.event.extendedProps.groupe && arg.event.extendedProps.groupe}</span>
        </div>
        <div style={timeTextStyle}>{arg.timeText}</div>
        {/*  <div style={instructorStyle}>
          {arg.event.extendedProps.instructor}
        </div> */}
        <div style={timeTextStyle}>
          <span>{arg.event.extendedProps.salle}</span>
          <span>
            {arg.event.extendedProps?.seance?.classes?.map((classe, index) => (
              <React.Fragment key={index}>
                {index > 0 && ", "}
                {classe.name}
              </React.Fragment>
            ))}
          </span>
          <span>
            {arg.event.extendedProps.week != "toute" && `  ${arg.event.extendedProps.week}`}
            {
              arg.event.extendedProps.parQuinzaine === true && `    /15`
              //: arg.event.extendedProps.subjectType
            }
          </span>
        </div>
      </div>
    );
  };

  const handleDateSelect = (selectInfo) => {
    const selectedDate = selectInfo.startStr; // Récupère la date sélectionnée
    const dateObject = new Date(selectedDate);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(dateObject.getDate()).padStart(2, "0");
    const formatted = `${year}-${month}-${day}`;
    setJour(formatted);
    setOpen(true);
  };
  const convertSeancesToEvents = () => {
    return seances.map((seance) => ({
      id: seance?._id,
      title: `${seance.subjectType.type} ${seance.subjectType.subject.name}`,
      daysOfWeek: [new Date(seance.day).getDay().toString()],
      start: `${seance.day}T${seance.startTime}`,
      end: `${seance.day}T${seance.endTime}`,
      allDay: false,
      startTime: seance.startTime,
      endTime: seance.endTime,
      extendedProps: {
        instructor: `${seance?.instructor?.firstName} ${seance?.instructor?.lastName}`,
        //subjectType: `${seance?.subjectType.subject.name}`,
        salle: `${seance?.salle?.name}`,
        parQuinzaine: seance?.parQuinzaine,
        groupe: seance?.groupe,
        week: seance?.week,
        seance: seance,
      },
    }));
  };

  return (
    <SoftBox pt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={12} sx={{ height: "max-content" }}>
          {useMemo(() => (
            <EventCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "timeGridWeek,dayGridMonth,timeGridDay",
              }}
              initialView="dayGridMonth"
              initialDate={dateDebut}
              validRange={{ start: dateDebut, end: dateFin }}
              events={convertSeancesToEvents()}
              //editable={true}
              selectable={true}
              selectMirror={true}
              // dayMaxEvents={false}
              slotLabelFormat={{
                hour: "2-digit",
                minute: "2-digit",
                hour12: false, // Format en 24 heures
              }}
              businessHours={[
                {
                  daysOfWeek: filteredJours?.map((jour) => jour.value),
                  startTime: heureDebut,
                  endTime: heureFin,
                },
              ]}
              selectConstraint={{
                startTime: heureDebut,
                endTime: heureFin,
                daysOfWeek: filteredJours?.map((jour) => jour.value), // Example days constraint (Monday to Friday)
              }}
              locale={frLocale}
              slotMinTime={heureDebut} // Customize the start time, e.g., 8:00 AM
              slotMaxTime={heureFin} // Customize the end time, e.g., 6:00 PM
              select={handleDateSelect}
              //eventClick={handleEventChange}
              eventContent={eventContent} // Apply custom content to events
              eventDisplay="block" // Ensure events are block elements
              eventBackgroundColor="purple" // Set default background color for events
              eventBorderColor="purple" // Set default border color for events
              slotEventOverlap={false}
              slotLabelContent={(slotInfo) => {
                const hourHeight = 60; // Set the desired height for each hour
                return (
                  <div
                    style={{ height: `${hourHeight}px`, display: "flex", alignItems: "flex-end" }}
                  >
                    {slotInfo.text}
                  </div>
                );
              }}
            />
          ))}
        </Grid>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={open}
          PaperProps={{ style: { backgroundColor: "#EEF1F4" } }}
        >
          <SeanceDialog
            handleClose={handleClose}
            enseignant={enseignant}
            jour={jour}
            instructorId={instructorId}
            optionsClasses={optionsClasses}
            planningId={planningId}
            handleFetch={handleFetch}
            heureDebut={heureDebut}
          />
        </Dialog>
      </Grid>
    </SoftBox>
  );
}
InfoEmploi.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
