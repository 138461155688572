/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
// Soft UI Dashboard PRO React example components

// Soft UI Dashboard PRO React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import curved0 from "assets/images/curved-images/curved0.jpg";
import SoftInput from "components/SoftInput";
import theme from "assets/theme";

function Header({ title, searchVisible, ExportVisible,setSearchQuery,searchQuery }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [isInputVisible, setInputVisible] = useState(false);
  const [isClicked, setIsClicked] = useState(false); // État local pour suivre le clic

  const handleSearchClick = () => {
    setInputVisible(!isInputVisible); // Inverse la visibilité du SoftInput
    if (isClicked) {
      // Si l'icône est déjà cliquée, réinitialisez l'état à false
      setIsClicked(false);
    } else {
      // Sinon, définissez l'état sur vrai pour changer la couleur
      setIsClicked(true);
      // Vous pouvez également ajouter d'autres logiques de recherche ici
    }
  };
  const theme = {
    palette: {
      customized: {
        main: "#7928CA ", // Remplacez par votre couleur info
      },
    },
    gradients: {
      customized: "#FF0080 ", // Remplacez par votre valeur de gradient info
    },
  };
  const backgroundColor = isClicked ? theme.gradients.customized : theme.palette.customized.main;

  return (
    <SoftBox position="relative" >
      <SoftBox
        display="flex"
        alignItems="center"
        //position="relative"
        minHeight="19rem"
        borderRadius="xl"
        sx={{
          // backgroundSize: "cover",
          //backgroundPosition: "50%",
          overflow: "hidden",
          position: "relative",
          mt: -35,
          px: 2,

        }}
      />

      <Grid container spacing={1} alignItems="center">

        <Grid item>
          <SoftBox height="100%" mt={0.5} lineHeight={1}>
            <SoftTypography variant="h5" fontWeight="medium" >
              {title}
            </SoftTypography>
          </SoftBox>
        </Grid>

        <Grid sx={{ ml: 'auto', display: "flex" }} >
          <Grid item xs={12} md={6} sx={{ mr: '238px' }}>
            <SoftBox sx={{
              width: isInputVisible ? "165px" : "", // Largeur contrôlée par la visibilité
              transition: "width 1s ", // Ajout de la transition de 5 secondes
              position: "absolute", // Position absolue pour cacher le champ de recherche
              overflow: "hidden", // Masquer le contenu lorsque la largeur est de 0
            }}>
              {isInputVisible && (
                <SoftInput
                  sx={{
                    display: "flex",
                    padding: 1.1,
                    //width: "150% !important", 
                    borderRadius: "0.50rem",
                  }}
                  placeholder="Recherche.."
                  icon={{ component: "search" }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              )} { }
            </SoftBox>
          </Grid>

          <Grid item xs={12} >
            <SoftBox 
           marginBottom={{ xs: "40px", md: "40px" ,xl:"40px" }}

            sx={{
              display: searchVisible ? 'block' : 'none', // Afficher ou masquer en fonction de la prop

              position: 'relative', // Ajoutez cette ligne pour positionner l'icône de recherche
            }}>
              <Icon fontSize="default"
                variant="gradient"
                sx={{

                  display: "flex",
                  padding: 2.6,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "0.50rem",
                  fontWeight: "bold",
                  width:"2.3em",

                  background: isClicked ? "linear-gradient(137deg, #FF0080 0%, #7928CA 100%, #7928CA 100%)" : "linear-gradient(82deg, #7F47E6 0%, #9645FF 100%)",
                  color: "#FFFFFF",
                  position: 'absolute', // Position absolue pour l'icône de recherche
                  right: '9px', // Position à droite du conteneur parent
                  top: '0', // Position en haut du conteneur parent
                  bottom: '0', // Remplit la hauteur du conteneur parent
                }}
                onClick={handleSearchClick} >
                <SearchIcon sx={{width:"2em !important"}}/>
              </Icon >
            </SoftBox>
          </Grid>

          <Grid item xs={12} md={6}  >
            <SoftBox sx={{display: ExportVisible ? 'block' : 'none', }}>
              <Icon fontSize="default"
                sx={{
                  width:"2.3em",
                  display: "flex",
                  padding: 2.6,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "0.50rem",
                  fontWeight: "bold",
                  background: "linear-gradient(82deg, #7F47E6 0%, #9645FF 100%)",
                  color: "#FFFFFF",
                }} >
                <VerticalAlignBottomIcon />
              </Icon >
            </SoftBox>
          </Grid>

        </Grid>
      </Grid>



    </SoftBox>

  );
}
Header.propTypes = {
  title: PropTypes.string.isRequired,
  searchVisible: PropTypes.string.isRequired,
  ExportVisible: PropTypes.string.isRequired,
  searchQuery:PropTypes.string.isRequired,
  setSearchQuery:PropTypes.func.isRequired
};
export default Header;
