import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  nom: Yup.string()
    .required("Entrez le nom de la tâche")
    .min(2, "La longueur minimale requise est de 2 caractères."),

  description: Yup.string()
    .required("Entrez la description de la tâche")
    .min(5, "La longueur minimale requise est de 5 caractères."),
  
  responsable: Yup.string()
    .required("Entrez le responsable de la tâche")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Entrez une adresse e-mail valide pour le responsable de la tâche"
    ),
  dateLimite: Yup.string().required("Entrez la date limite de la tâche"),
});


export default validationSchema;
