import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

export default function BasicTable({ data }) {
  const getLast6Characters = (code) => code?.slice(-6);
  const facility = JSON.parse(localStorage.getItem("facility"));
  return (
    <TableContainer component={Box} sx={{ padding: 1, marginBottom: 2 }}>
      {facility.type === "faculty" && (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={5}
                align="center"
                sx={{
                  border: "1px solid black",
                  color: "#8392ab",
                  fontSize: "0.65rem",
                  padding: 1,
                  fontWeight: 700,
                }}
              >
                {data?.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                rowSpan={2}
                sx={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: 1,
                  color: "#8392ab",
                  fontSize: "0.65rem",
                  width: "10%",
                  fontWeight: 700,
                }}
              >
                Domaine :
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: "0.65rem",
                  fontWeight: 700,
                }}
              >
                Code : {getLast6Characters(data?.parcours?.at(-1)?.niveau?.filiere?.departement?.domaine?.code)}
              </TableCell>
              <TableCell
                rowSpan={2}
                sx={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: 1,
                  color: "#8392ab",
                  fontSize: "0.65rem",
                  width: "10%",
                  fontWeight: 700,
                }}
              >
                Diplome :
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: "0.65rem",
                  fontWeight: 700,
                }}
              >
                Code : +++++
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={2}
                sx={{
                  border: "1px solid black",
                  textAlign: "left",
                  fontSize: "0.65rem",
                  padding: 1,
                  fontWeight: 700,
                }}
              >
                Libelle : {data?.parcours?.at(-1)?.niveau?.filiere?.departement?.domaine?.name}
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: "0.65rem",
                  fontWeight: 700,
                }}
              >
                Libelle : {data?.name}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                rowSpan={2}
                sx={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: 1,
                  color: "#8392ab",
                  fontSize: "0.65rem",
                  fontWeight: 700,
                }}
              >
                Spécialité :{" "}
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{
                  border: "1px solid black",
                  textAlign: "left",
                  fontSize: "0.65rem",
                  padding: 1,
                  fontWeight: 700,
                }}
              >
                Code : {getLast6Characters(data?.parcours?.at(-1)?.speciality?.code)}
              </TableCell>
              <TableCell
                rowSpan={2}
                sx={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: 1,
                  color: "#8392ab",
                  fontSize: "0.65rem",
                  fontWeight: 700,
                }}
              >
                Mention :{" "}
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: "0.65rem",
                  fontWeight: 700,
                }}
              >
                Code : ++++++
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={2}
                sx={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: "0.65rem",

                  fontWeight: 700,
                }}
              >
                Libelle : {data?.parcours?.at(-1)?.speciality?.name}
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: 1,
                  fontSize: "0.65rem",

                  fontWeight: 700,
                }}
              >
                Libelle : {data?.parcours?.at(-1)?.niveau?.filiere?.mention}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

BasicTable.propTypes = {
  data: PropTypes.object.isRequired, 
};
