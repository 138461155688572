import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SoftBox from "components/SoftBox";
import SoftPagination from "components/SoftPagination";
import api from "utils/axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import CardStudents from "./CardStudent";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import SoftTypography from "components/SoftTypography";
import Stack from "@mui/material/Stack";
import SoftButton from "components/SoftButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import StudentInfo from "../update-class-components/StudentInfo";
import { useSoftUIController } from "context";

export default function StudentsList({ classe }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("tout");
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const [controller] = useSoftUIController();
  const user = controller?.user?.info;
  const hasEditDeletePermission =
    user?.role === "company-admin" ||
    user?.role === "admin" ||
    user?.role === "editor";

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const handleFetch = async () => {
    try {
      setLoading(true);
      let endpoint;
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/classes/${id}/students`;
      } else {
        endpoint = `/faculty/classes/${id}/students`;
      }
      const response = await api.get(endpoint);
      setData(response?.data);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            let endpoint;
            if (facility?.type === "centreDeFormation") {
              endpoint = `training-company/update-student-in-classe/${id}`;
            } else {
              endpoint = `/faculty/update-student-in-classe/${id}`;
            }
            const response = await api.patch(endpoint, {
              studentsToDelete: [selectedStudentId],
            });
            if (response) {
              (
                await Swal.fire(
                  "Supprimé !",
                  "L'étudiant a été supprimée de cette classe.",
                  "success"
                )
              ).isConfirmed && handleFetch();
              closeSlackBotMenu();
            }
          } catch (error) {
            Swal.fire(
              "Une erreur s'est produite !",
              "L'etudiant n'a pas été supprimé.",
              "error"
            );
            closeSlackBotMenu();
          }
        }
      });
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem id="btn-delete" onClick={showAlert}>
        <DeleteIcon />
        &nbsp; Supprimer
      </MenuItem>
    </Menu>
  );

  const totalPages = Math.ceil(
    (tabValue === "tout"
      ? data.students?.length || 0
      : data.groupedStudents?.[tabValue]?.length || 0) / itemsPerPage
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentData =
    tabValue === "tout" && data?.students?.length > 0
      ? data.students.slice(startIndex, endIndex)
      : data.groupedStudents && data.groupedStudents[tabValue]
      ? data.groupedStudents[tabValue].slice(startIndex, endIndex)
      : [];

  return (
    <>
      <SoftBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={{ xs: "flex-start", sm: "space-between" }}
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={1}
      >
        <SoftBox lineHeight={1}>
          <SoftTypography variant="h5" fontWeight="medium">
            {classe?.name}
          </SoftTypography>
        </SoftBox>
        {data?.groupedStudents?.length >= 2 && (
          <Grid>
            <SoftBox>
              <Grid container>
                <Grid item xs={12} sm={12} lg={12}>
                  <AppBar position="static">
                    <Tabs value={tabValue} onChange={handleSetTabValue}>
                      <Tab id="tout" value="tout" label="Tout" />
                      {data?.groupedStudents?.map((group, i) => (
                        <Tab key={`tab-${i}`} id={`tab-g${i + 1}`} value={i} label={`G${i + 1}`} />
                      ))}
                    </Tabs>
                  </AppBar>
                </Grid>
              </Grid>
              <Grid marginLeft={50}></Grid>
            </SoftBox>
          </Grid>
        )}

        {hasEditDeletePermission && (
          <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
            <SoftButton
              id="add-student"
              onClick={handleClickOpen}
              variant="gradient"
              color="purple"
              size="small"
              mb={{ xs: 2, sm: 0 }}
            >
              +Ajouter étudiant
            </SoftButton>
          </Stack>
        )}
      </SoftBox>
      {loading ? (
        <SoftBox
          sx={{
            mt: 13,
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </SoftBox>
      ) : (
        <>
          <SoftBox pb={2}>
            <SoftBox mt={{ xs: 1, lg: 2 }} mb={1}>
              <Grid container spacing={1.5}>
                {currentData &&
                  currentData.map((student) => (
                    <Grid key={student?._id} item xs={12} md={6} lg={6}>
                      <CardStudents
                        image={
                          student?.imageUrl?.startsWith("https")
                            ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                            : `${process.env.REACT_APP_BASE_URL}/${student?.imageUrl}`
                        }
                        name={`${student?.firstName} ${student?.lastName}`}
                        email={student?.email}
                        level={"1"}
                        specialitie={"gl"}
                        members={[team1, team2, team3, team4]}
                        dropdown={
                          hasEditDeletePermission && {
                            action: (e) => {
                              setSlackBotMenu(e.currentTarget);
                              setSelectedStudentId(student?._id);
                            },
                            menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                          }
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
            </SoftBox>
          </SoftBox>
          {totalPages > 1 && (
            <SoftPagination>
              {currentPage > 1 && (
                <SoftPagination item onClick={prevPage}>
                  <ArrowBackIosIcon />
                </SoftPagination>
              )}
              {Array.from({ length: totalPages }, (_, index) => (
                <SoftPagination
                  item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                </SoftPagination>
              ))}
              {currentPage < totalPages && (
                <SoftPagination item onClick={nextPage}>
                  <ArrowForwardIosIcon />
                </SoftPagination>
              )}
            </SoftPagination>
          )}
        </>
      )}
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Modifier Etudiant</DialogTitle>
        <IconButton
          id={`close-modal`}
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <StudentInfo
            handleFetchStudentList={handleFetch}
            handleClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

StudentsList.propTypes = {
  classe: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
