import { Autocomplete, Checkbox, Grid, Stack, TextField } from '@mui/material';
import React, { useState } from 'react'
import PropTypes from "prop-types";
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import { ErrorMessage } from 'formik';


export default function ConfigurationExamen({ formData }) {
  const [classeName, setClasseName] = useState("")
  const [dateDeDebut, setDateDeDebut] = useState("")
  const [dateDeFin, setDateDeFin] = useState("")
  const [heureDeDebut, setHeureDeDebut] = useState("08:00")
  const [heureDeFin, setHeureDeFin] = useState("18:00")
  const { formField, errors, touched, values } = formData;
  const {
    classe,
    dateDebut,
    dateFin,
    heureDebut,
    heureFin
  } = formField;

  const [options, setOptions] = useState([
    { value: "classe 1", label: "Classe 1" },
    { value: "classe 2", label: "Classe 2" },
    { value: "classe 3", label: "Classe 3" },
    { value: "classe 4", label: "classe 4" },
  ]);
  const [optionsJours, setOptionsJours] = useState([
    { value: 1, label: "Lundi", checked: true },
    { value: 2, label: "Mardi", checked: true },
    { value: 3, label: "Mercredi", checked: true },
    { value: 4, label: "Jeudi", checked: true },
    { value: 5, label: "Vendredi", checked: true },
    { value: 6, label: "Samedi", checked: true },
    { value: 7, label: "Dimanche", checked: false },
  ]);
  values.classe = classeName.value
  values.dateDebut = dateDeDebut
  values.dateFin = dateDeFin
  values.heureDebut = heureDeDebut
  values.heureFin = heureDeFin
  values.jours = optionsJours
  // Function to handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedOptions = [...optionsJours];
    updatedOptions[index].checked = !updatedOptions[index].checked;
    setOptionsJours(updatedOptions);
  };

  return (
    <SoftBox mt={1.625}>
      <Grid item xs={12} sm={6}>
        <SoftTypography
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >classe
        </SoftTypography>
        <Stack spacing={3} sx={{ width: "97%", }}>
          <SoftBox>
            <SoftTypography
              variant="caption"
              fontWeight="medium"
              textTransform="capitalize"
            >choix de classe
            </SoftTypography>
            <Autocomplete
              id={`size-small-standard`}
              options={options}
              aria-required={true}
              required={true}

              clearIcon={null}
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => {
                setClasseName(newValue)

              }}
              renderInput={(params) => (
                <TextField sx={{ mb: 2 }}
                  {...params}
                  placeholder="Nom de la classe"
                  required={true}
                  variant="standard"
                />
              )}
            />
          </SoftBox>
        </Stack>
      </Grid>
      <SoftTypography
        variant="caption"
        fontWeight="bold"
        textTransform="capitalize"
      >Période
      </SoftTypography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SoftTypography
            sx={{ mb: 1 }}
            component="label"
            variant="caption"
            fontWeight="medium"
          >
            Date de Début
          </SoftTypography>
          <SoftInput
            required={true}
            id="birthday-commercial"
            type="date"
            onChange={(e) => setDateDeDebut(e.target.value)}

          />

        </Grid>
        <Grid item xs={12} sm={6}>
          <SoftTypography
            sx={{ mb: 1 }}
            component="label"
            variant="caption"
            fontWeight="medium"
          >
            Date de Fin
          </SoftTypography>
          <SoftInput
            required={true}
            id="birthday-commercial"
            type="date"
            onChange={(e) => setDateDeFin(e.target.value)}
            inputProps={{
              min: dateDeDebut
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={0.1} mb={2} display="flex" justifyContent="space-between">
        {
          optionsJours.map((jour, index) => (
            <Grid key={index} item xs={12} md={1.5} sm={4}>
              <Checkbox
                checked={jour.checked}
                onChange={() => handleCheckboxChange(index)}
              />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                // onClick={handleSetAgremment}
                sx={{ ml: 0.5 }}
              >
                {jour.label}
              </SoftTypography>
            </Grid>
          ))
        }

      </Grid>
      <SoftTypography
        variant="caption"
        fontWeight="bold"
        textTransform="capitalize"
      >Plage Horaire
      </SoftTypography>
      <Grid container spacing={3} >
        <Grid item xs={12} sm={6}>
          <SoftTypography
            sx={{ mb: 1 }}
            component="label"
            variant="caption"
            fontWeight="medium"
          >
            Heure de Début
          </SoftTypography>
          <SoftInput
            id="time-emploi"
            type="time"
            defaultValue="08:00"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 900,
            }}
            onChange={(e) => setHeureDeDebut(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SoftTypography
            sx={{ mb: 1 }}
            component="label"
            variant="caption"
            fontWeight="medium"
          >
            Heure de Fin
          </SoftTypography>
          <SoftInput
            id="time-emploi"
            type="time"
            defaultValue="18:00"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 900,
            }}
            onChange={(e) => setHeureDeFin(e.target.value)}
          />
        </Grid>
      </Grid>
    </SoftBox>

  )
}
ConfigurationExamen.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
