import { useState } from "react";
// @mui material components
import SendIcon from "@mui/icons-material/Send";
import EmailIcon from "@mui/icons-material/Email";
import Tooltip from "@mui/material/Tooltip";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import CustomSwitch from "components/shared-users/Switchic";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import api from "utils/axios";
import { useSoftUIController } from "context";
import { setChat } from "context";

function ActionCell({ userId, etat, title, image,disabled }) {
  const [currentEtat, setCurrentEtat] = useState(etat);
  const newText = currentEtat === "active" ? "Desactiver" : "Activer";
  const [_, dispatch] = useSoftUIController();

  const [followsMe, setFollowsMe] = useState(
    etat === "active" ? true : etat === "desactive" ? false : null
  );

  const showAlertDesactivateAccount = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    const newConfirmText = currentEtat === "active" ? "Desactiver" : "Activer";

    newSwal
      .fire({
        title: "Êtes-vous sûr ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: newConfirmText,
        cancelButtonText: "Annuler"
      })
      .then((result) => {
        if (result.isConfirmed) {
          api.post(`/change-status-user/${userId}`)
            .then((res) => {
              const newEtat = res.data.etat;
              setCurrentEtat(newEtat);
              if (newEtat === "active") {
                Swal.fire("Activé!", "le compte a été activé avec succès.", "success");
              } else if (newEtat === "desactive") {
                Swal.fire("Désactivé !", "le compte a été désactivé avec succès.", "success");
              }
            })
            .catch(() => {
              Swal.fire("Erreur !", "le compte n'a pas été désactivé ou activé.", "error");
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          setFollowsMe(followsMe);
        }
      });
  };
  const openedChat = async () => {
    try {
      const response = await api.post("/create-chat", { id: userId });
      setChat(dispatch, {
        open: true,
        receiver: {
          chat: response.data,
          chatId: response.data._id,
          fullName: title,
          userId: userId,
          image: image
        }
      });
    } catch (error) {
      throw error;
    }
  }

  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Message" placement="top">
          <SendIcon sx={{ cursor: "pointer", color: "#7F47E6" }} onClick={openedChat} />
        </Tooltip>
      </SoftTypography>
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Email" placement="top">
            <EmailIcon sx={{ cursor: "pointer", color: "#7F47E6" }} />
          </Tooltip>
        </SoftTypography>
      </SoftBox>
      <SoftTypography
        variant="body1"
        color="secondary"
        sx={{ cursor: "pointer", lineHeight: 0 }}
      ></SoftTypography>
      {etat === "enAttente" ? (
        " En attente"
      ) : (
        <Tooltip title={newText}>
          <CustomSwitch
            onClick={showAlertDesactivateAccount}
            checked={followsMe}
            onChange={() => setFollowsMe(!followsMe)}
            disabled = {disabled}
          />
        </Tooltip>
      )}
    </SoftBox>
  );
}
ActionCell.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]).isRequired,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  etat: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  disabled: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ActionCell;
