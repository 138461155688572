import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import {
  CircularProgress,
  Grid,
  IconButton,
  Autocomplete,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import api from "utils/axios";
import Swal from "sweetalert2";
import SoftBox from "components/SoftBox";
export default function AddSalle({ token }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [capacityError, setCapacityError] = useState("");
  const [errorType, setErrorType] = useState("");
  const [errorBloc, setErrorBloc] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inputs, setInputs] = useState([{ name: "", capacity: "", typeSalle: "", bloc: "" }]);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [optionsTypesSalle, setOptionsTypesSalle] = useState([]);
  const [optionsBlocs, setOptionsBlocs] = useState([]);

  useEffect(() => {
    api.get(`/faculty/all-type-salles/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsTypesSalle(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
        }))
      );
    });
    api.get(`/faculty/all-blocs/${facility?.scholarityConfigId}`).then((res) => {
      setOptionsBlocs(
        res.data.map((item) => ({
          value: item._id,
          label: item.name,
        }))
      );
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setCapacityError("");
    setInputs([{ name: "", capacity: "", typeSalle: "", bloc: "" }]);
  };
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  };
  const handleAddSalle = async () => {
    try {
      setLoading(true);
      // Validate the inputs before submitting
      const hasInvalidInputs = inputs.some((input) => input.name.length < 2);
      const hasInvalidCapacity = inputs.some(
        (input) => input.capacity < 1 || !Number.isInteger(Number(input.capacity))
      );
      const hasInvalidType = inputs.some((input) => !input.typeSalle);
      const hasInvalidBloc = inputs.some((input) => !input.bloc);
      if (hasInvalidInputs) {
        // Set the error message
        setErrorMessage("La longueur minimale requise est de 2 caractères");
      } else if (hasInvalidCapacity) {
        // Set the error message
        setCapacityError("La capacité doit être supérieur ou égale a 1 et n'accepte pas les virgules.");
      }   else if (hasInvalidType) {
        // Set the error message
        setErrorType("Le type du salle doit être sélectionnée.");
      } else if (hasInvalidBloc) {
        // Set the error message
        setErrorBloc("Le bloc doit être sélectionnée.");
      }  else {
        const duplicateName = inputs.find(
          (input, index) =>
            index !==
            inputs.findIndex(
              (otherInput) => otherInput.name.toLowerCase() === input.name.toLowerCase()
            )
        );
        if (duplicateName) {
          // Set the error message for duplicate name
          Swal.fire(
            "Erreur!",
            `Le nom du salle existe déjà : ${duplicateName.name}. Merci de saisir un autre nom !`,
            "error"
          );
        } else {
          await api.post(
            `/faculty/create-salle?scholarityConfigId=${facility.scholarityConfigId}`,
            inputs
          );
          setOpen(true);
          setLoading(false);
          Swal.fire("Success!", "La salle ajoutée avec succès", "success").then(() => {
            setErrorMessage("");
            setCapacityError(""), 
            setInputs([{ name: "", capacity: "" }]);
            token();
            setOpen(false);
          });
        }
      }
    } catch (error) {
      setError(error.response.data.message);
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddSalle();
    }
  };
  return (
    <div>
      <SoftButton
        id="add"
        onClick={handleClickOpen}
        variant="gradient"
        color="purple"
        size="small"
        mb={{ xs: 2, sm: 0 }}
      >
        +Ajouter salle
      </SoftButton>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} onKeyDown={handleKeyPress}>
        <DialogTitle>Ajouter salle</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            Vous devez saisir les informations de la salle afin de l&apos;ajouter.
          </DialogContentText>
          {inputs.map((input, index) => (
            <Grid key={index} sx={{ mt: 1 }}>
              {facility?.type === "faculty" ? (
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "flex-start",
                  }}
                >
                  <Grid item xs={12} sm={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Nom de la salle*
                    </SoftTypography>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                      }}
                    >
                      <SoftInput
                        name="name"
                        margin="normal"
                        id="name"
                        label="foramtion"
                        type="text"
                        fullWidth
                        placeholder="Nom de la salle"
                        inputProps={{
                          minLength: 2,
                          maxLength: 50,
                        }}
                        autoFocus
                        variant="standard"
                        value={input.name}
                        onChange={(event) => handleInputChange(index, event)}
                        onBlur={() => {
                          // Update the error when the input loses focus
                          const newInputs = [...inputs];
                          newInputs[index].error = input.name.length < 2;
                          setInputs(newInputs);
                        }}
                        error={input.error && input.name.length < 2}
                        success={input.name.length >= 2}
                      />
                      {input.name.length < 2 && (
                        <Typography mt={0.75} variant="caption" color="error">
                          {errorMessage}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Capacité*
                    </SoftTypography>
                    <SoftInput
                      id="capacity"
                      name="capacity"
                      type="number"
                      margin="normal"
                      variant="standard"
                      fullWidth
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      placeholder="capacité"
                      value={input.capacity}
                      error={
                        input.error &&
                        (input.capacity < 1 || !Number.isInteger(Number(input.capacity)))
                      }
                      success={input.capacity >= 1 && Number.isInteger(Number(input.capacity))}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                    {(input.capacity === "" ||
                      input.capacity < 1 ||
                      !Number.isInteger(Number(input.capacity))) && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {capacityError}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Type de la salle*
                    </SoftTypography>
                    <Autocomplete
                      id={`size-small-standard`}
                      options={optionsTypesSalle}
                      title="Select departement"
                      aria-required={true}
                      required={true}
                      getOptionLabel={(option) => option.label}
                      defaultValue={null}
                      clearIcon={null}
                      placeholder="Toutes les types"
                      value={
                        optionsTypesSalle.find((option) => option.value === input.typeSalle) || null
                      }
                      onChange={(event, newValue) => {
                        if (newValue !== null && newValue !== undefined) {
                          const newInputs = [...inputs];
                          newInputs[index].typeSalle = newValue.value;
                          setInputs(newInputs);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          required={true}
                          placeholder="Toutes les types"
                        />
                      )}
                    />
                    {input.typeSalle === "" && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorType}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Bloc*
                    </SoftTypography>
                    <Autocomplete
                      id={`size-small-standard`}
                      options={optionsBlocs}
                      title="Select bloc"
                      aria-required={true}
                      required={true}
                      getOptionLabel={(option) => option.label}
                      defaultValue={null}
                      clearIcon={null}
                      placeholder="Toutes les blocs"
                      value={optionsBlocs.find((option) => option.value === input.bloc) || null}
                      onChange={(event, newValue) => {
                        if (newValue !== null && newValue !== undefined) {
                          const newInputs = [...inputs];
                          newInputs[index].bloc = newValue.value;
                          setInputs(newInputs);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          required={true}
                          placeholder="Toutes les blocs"
                        />
                      )}
                    />
                   {input.bloc === "" && (
                      <Typography mt={0.75} variant="caption" color="error">
                        {errorBloc}
                      </Typography>
                    )} 
                  </Grid>
                </Grid>
              ) : (
                <Grid key={index} sx={{ mt: 1 }}></Grid>
              )}
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
            <SoftButton
              onClick={handleClose}
              sx={{ mt: 2 }}
              variant="outlined"
              color="dark"
              size="small"
            >
              Annuler
            </SoftButton>
            {loading ? (
              <CircularProgress />
            ) : (
              <SoftButton
                onClick={handleAddSalle}
                sx={{ mt: 2 }}
                variant="gradient"
                color="purple"
                size="small"
              >
                Ajouter
              </SoftButton>
            )}
          </SoftBox>
        </DialogActions>
      </Dialog>
    </div>
  );
}
AddSalle.propTypes = {
  token: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
