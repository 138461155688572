import checkout from "./form";
const {
  formField: {
    enseignant,
    instructorId,
    optionsClasses,
    jours,
    dateDebut,
    dateFin,
    heureDebut,
    heureFin,
    planningId
  },
} = checkout;
const initialValues = {
  [enseignant.name]: "",
  [instructorId.name]: "",
  [optionsClasses.name]: [],
  [dateDebut.name]: "",
  [dateFin.name]: "",
  [heureDebut.name]: "",
  [heureFin.name]: "",
  [jours.name]: "",
  [planningId.name]:""
};
export default initialValues;
