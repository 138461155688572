import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import api from "utils/axios";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// icons importation
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';

// Image
import lock from "assets/images/illustrations/customized-lock.png";

import { passwordRegEx } from "utils/regex";

function SetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [alert, setAlert] = useState({show: false, message: '', type: ''});
  const [password, setPassword] = useState({ value: "", showPass: false, isValid: true });
  const [confPassword, setConfPassword] = useState({ value: "", showPass: false, isValid: true });
  const { search } = useLocation();

  useEffect(() => {
    // change page title in the header
    document.title = "définir le mot de passe";
  }, []);

  // helper function for clearing the alert message after ms milliseconds
  const resetAlert = (ms) => {
    setTimeout(() => {
      setAlert({show: false, message: '', type: ''});
    }, ms);
  }

  const querys = {};
  for (const query of search.slice(1).split('&')) {
    const [key, value] = query.split('=');
    querys[key] = value;
  }
  
  // email input changes handler function
  const handlePasswordInputChanges = (e) => {
    setPassword((prevState) => ({
      ...prevState,
      value: e.target?.value
    }));
  }
  const handleConfPasswordInputChanges = (e) => {
    setConfPassword((prevState) => ({
      ...prevState,
      value: e.target?.value
    }));
  }

  // handle the onBlur action of the email input (email validation process)
  const handlePasswordValidation = () => {
    setPassword((prevState) => ({
      ...prevState,
      isValid: passwordRegEx.test(prevState.value)
    }));
    if (!passwordRegEx.test(password.value)) {
      setAlert({show: true, message: 'Format de mot de passe invalide', type: 'error'});
      resetAlert(10_000);
    }
  }
  const handleConfPasswordValidation = () => {
    setConfPassword((prevState) => ({
      ...prevState,
      isValid: passwordRegEx.test(prevState.value) && prevState.value === password.value
    }));

    if (!passwordRegEx.test(confPassword.value) && password.value !== confPassword.value) {
      setAlert({show: true, message: 'Format de mot de passe invalide et les deux mot de passe sont différentes', type: 'error'});
      resetAlert(10_000);
    } else if (password.value !== confPassword.value) {
      setAlert({show: true, message: 'La valeur du mot de passe et la valeur de confirmation du mot de passe sont différentes', type: 'error'});
      resetAlert(10_000);
    } else if (!passwordRegEx.test(confPassword.value)) {
      setAlert({show: true, message: 'Format de mot de passe invalide', type: 'error'});
      resetAlert(10_000);
    }
  }

  // toggle the password visibility
  const changePasswordVisibility = () => {
    setPassword((prevState) => ({
      ...prevState,
      showPass: !prevState.showPass
    }));
  }
  const changeConfPasswordVisibility = () => {
    setConfPassword((prevState) => ({
      ...prevState,
      showPass: !prevState.showPass
    }));
  }

  // send button handler
  const sendButtonHandler = () => {
    setIsLoading(true);
    api.put(`/create-password/${querys.token}`, {
      newPassword: password.value
    }).then((response) => {
      setAlert({ show: true, message: "Réinitialisation de votre mot de passe avec succés. On va vous rediriger vers la page d'authentification dans quelques secondes...", type: 'success'});
      setTimeout(() => { navigate('/'); }, 10_000);
    }).catch((error) => {
      console.log(error);
      if (error.code === 'ERR_BAD_REQUEST') {
        setAlert({ show: true, message: 'jeton invalide', type: 'error'});
      } else if (error.code === 'ERR_NETWORK') {
        setAlert({ show: true, message: 'Problem coté server 500', type: 'error'});
      } else {
        setAlert({ show: true, message: error.message, type: 'error'});
      }
      resetAlert(10_000);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  let userEmail;
  try {
    // decode the token and then parse it...
    userEmail = JSON.parse(atob(querys.token?.split('.')[1])).userEmail;
  } catch (error) {
    // token in the query does not exist or invalid set an error alert
    throw error;
  }

  return (
    <IllustrationLayout
      showDefaultNavBar={false}
      title="Créez votre mot de passe."
      description="Accepter l'invitation par la création d'un mot de passe pour votre compte."
      illustration={{
        image: lock,
        title: "Proservices Square",
        description: "Accepter l'invitation par la création d'un mot de passe pour votre compte.",
      }}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2} id="token-encoded-user-email-box">
          <SoftInput
              type="email"
              readOnly
              size="large"
              value= {userEmail}
              icon = {{component: <EmailIcon color = "customized" />, direction: "right" }}
              id="token-encoded-user-email"
          />
        </SoftBox>
        <SoftBox mb={2} id="create-password-box">
          <SoftInput
              onBlur = {handlePasswordValidation}
              error = {!password.isValid}
              onChange={handlePasswordInputChanges}
              type={password.showPass ? "text" : "password"}
              placeholder="mot de passe"
              size="large"
              icon = {{ component: (password.showPass ? <VisibilityOffIcon color = "customized" onClick={changePasswordVisibility} sx={{ cursor: "pointer" }} id="set-password-visibility-icon" /> : <VisibilityIcon color = "customized" onClick={changePasswordVisibility} sx={{ cursor: "pointer" }} id="set-password-visibility-icon" />), direction: "right" }}
              autoComplete = "current-password"
              id="set-password-input"
          />
        </SoftBox>
        <SoftBox mb={2} id="confirm-password-box">
          <SoftInput
            onBlur = {handleConfPasswordValidation}
            error = {!confPassword.isValid}
            onChange={handleConfPasswordInputChanges}
            type={confPassword.showPass ? "text" : "password"}
            placeholder="confirmer votre mot de passe"
            size="large"
            icon = {{ component: (confPassword.showPass ? <VisibilityOffIcon color = "customized" onClick={changeConfPasswordVisibility} sx={{ cursor: "pointer" }} id="conf-password-visibility-icon" /> : <VisibilityIcon color = "customized" onClick={changeConfPasswordVisibility} sx={{ cursor: "pointer" }} id="conf-password-visibility-icon" />), direction: "right" }}
            autoComplete = "current-password"
            id="confirm-password-input"
          />
        </SoftBox>
        <SoftBox mt={4} mb={1} display="flex" justifyContent="center">
          {
            isLoading ? <CircularProgress />
            : <SoftButton
                disabled={!(passwordRegEx.test(password.value) && passwordRegEx.test(confPassword.value) && (password.value === confPassword.value))}
                variant="gradient" color="customized" size="large" fullWidth id="set-password-button" onClick={sendButtonHandler}
              >
                Créez
              </SoftButton>
          }
        </SoftBox>
        { alert.show && <SoftTypography m={4} color={alert.type} id="error-alert" fontWeight="bold" fontSize={15} style={{ textAlign: 'center' }} >{ alert.message } </SoftTypography> }
        { alert.type === 'success' && <LinearProgress color="success" id="set-password-sucess-progress-bar" /> }
      </SoftBox>
    </IllustrationLayout>
  );
}

export default SetPassword;
