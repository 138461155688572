const SelectInfo = {
    type: [
      { value: "Chiffre Affaires", label: "Chiffre Affaires" },
      { value: "Gain", label: "Gain" },
      { value: "Charge", label: "Charge" },
    ],
    periode:[
        { value: "Mensuel", label: "Mensuel" },
        { value: "Annuel", label: "Annuel" },
    ],
    mois:[
      { value: "January", label: "January" },
      { value: "February", label: "February" },
      { value: "March", label: "March" },
      { value: "April", label: "April" },
      { value: "May", label: "May" },
      { value: "June", label: "June" },
      { value: "July", label: "July" },
      { value: "August", label: "August" },
      { value: "September", label: "September" },
      { value: "October", label: "October" },
      { value: "November", label: "November" },
      { value: "December", label: "December" },
    ]
  };
  export default SelectInfo;