import { Autocomplete, Checkbox, Grid, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import api from 'utils/axios';
import { ErrorMessage, useFormikContext } from 'formik';
export default function ConfigurationEmploi({ formData }) { 
  const { formField, values } = formData;
  const {
    enseignant: enseignantX,
    instructorId: instructorIdX,
    dateDebut: dateDebutX,
    dateFin: dateFinX,
  } = values;
  const [instructorName, setInstructorName] = useState("")
  const [instructorId, setInstructorId] = useState(instructorIdX)
  const [dateDeDebut, setDateDeDebut] = useState("")
  const [dateDeFin, setDateDeFin] = useState("")
  const [heureDeDebut, setHeureDeDebut] = useState("08:00")
  const [heureDeFin, setHeureDeFin] = useState("18:00")
  const { setFieldError } = useFormikContext();
  const [optionsEnseignant, setOptionsEnseignant] = useState([]);
  const [optionsJours, setOptionsJours] = useState([
    { value: 1, label: "Lundi", checked: true },
    { value: 2, label: "Mardi", checked: true },
    { value: 3, label: "Mercredi", checked: true },
    { value: 4, label: "Jeudi", checked: true },
    { value: 5, label: "Vendredi", checked: true },
    { value: 6, label: "Samedi", checked: true },
    { value: 7, label: "Dimanche", checked: false },
  ]);
  const {enseignant,dateDebut,dateFin}=formField
  values.enseignant = instructorName.label
  values.instructorId = instructorId
  //values.optionsClasses = optionsClasses
  values.dateDebut = dateDeDebut
  values.dateFin = dateDeFin
  values.heureDebut = heureDeDebut
  values.heureFin = heureDeFin
  values.jours = optionsJours

  // Function to handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedOptions = [...optionsJours];
    updatedOptions[index].checked = !updatedOptions[index].checked;
    setOptionsJours(updatedOptions);
  };
  const facility = JSON.parse(localStorage.getItem("facility"));
  useEffect(() => {
    api.get(`/get-all-users-by-role/${facility._id}?role=instructor`).then((res) => {
      setOptionsEnseignant(
        res.data.map((item) => ({
          value: item._id,
          label: `${item.firstName} ${item.lastName}`,
        }))
      );
    });
  }, []);
  return (
    <SoftBox mt={1.625}>
      <Grid item xs={12} sm={6}>
        <SoftTypography
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >Enseignant
        </SoftTypography>
        <Stack spacing={3} sx={{ width: "97%", }}>
          <SoftBox>
            <SoftTypography
              variant="caption"
              fontWeight="medium"
              textTransform="capitalize"
            >choix de l&apos;enseignant
            </SoftTypography>
            <Autocomplete
              name={enseignant.name}
              id={`size-small-standard`}
              options={optionsEnseignant}
              aria-required={true}
              clearIcon={null}
              getOptionLabel={(option) => option.label}
              /*  value={
                optionsEnseignant.length > 0 &&
                optionsEnseignant.find((option) => option.value === instructorId)
              }   */
              onChange={(event, newValue) => {
                setInstructorName(newValue)
                setInstructorId(newValue.value)
                setFieldError("enseignant", undefined);           
              }}
              renderInput={(params) => (
                <TextField sx={{ mb: 2 }}
                  {...params}
                  placeholder="Nom de l'enseignant"
                  variant="standard"
                />
              )}
            />
            <SoftBox mt={0.5}>
            <SoftTypography component="div" variant="caption" color="error">
              <ErrorMessage name={enseignant.name} />
            </SoftTypography>
          </SoftBox>
          </SoftBox>
        </Stack>
      </Grid>
      <SoftTypography
        variant="caption"
        fontWeight="bold"
        textTransform="capitalize"
      >Période
      </SoftTypography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SoftTypography
            sx={{ mb: 1 }}
            component="label"
            variant="caption"
            fontWeight="medium"
          >
            Date de Début
          </SoftTypography>         
          <SoftInput
            name={dateDebut.name}
            id="birthday-commercial"
            type="date"
            value={dateDeDebut}
            onChange={(e) =>{ 
              setDateDeDebut(e.target.value)
              setFieldError("dateDebut", undefined);
            }}
          />
          <SoftBox mt={0.75}>
            <SoftTypography component="div" variant="caption" color="error">
              <ErrorMessage name={dateDebut.name} />
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SoftTypography
            sx={{ mb: 1 }}
            component="label"
            variant="caption"
            fontWeight="medium"
          >
            Date de Fin
          </SoftTypography>       
          <SoftInput
           name={dateFin.name}
            id="birthday-commercial"
            type="date"
            value={dateDeFin}
            onChange={(e) => {
              setDateDeFin(e.target.value)
              setFieldError("dateFin", undefined);
            }}
            inputProps={{
              min: dateDeDebut
            }}
          />
          <SoftBox mt={0.75}>
            <SoftTypography component="div" variant="caption" color="error">
              <ErrorMessage name={dateFin.name} />
            </SoftTypography>
          </SoftBox>
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={0.1} mb={2} display="flex" justifyContent="space-between">
        {
          optionsJours.map((jour, index) => (
            <Grid key={index} item xs={12} md={1.5} sm={4}>
              <Checkbox
                checked={jour.checked}
                onChange={() => handleCheckboxChange(index)}
              />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                sx={{ ml: 0.5 }}
              >
                {jour.label}
              </SoftTypography>
            </Grid>
          ))
        }
      </Grid>
      <SoftTypography
        variant="caption"
        fontWeight="bold"
        textTransform="capitalize"
      >Plage Horaire
      </SoftTypography>
      <Grid container spacing={3} >
        <Grid item xs={12} sm={6}>
          <SoftTypography
            sx={{ mb: 1 }}
            component="label"
            variant="caption"
            fontWeight="medium"
          >
            Heure de Début
          </SoftTypography>
          <SoftInput
            id="time-emploi"
            type="time"
            defaultValue="08:00"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 900,
            }}
            onChange={(e) => setHeureDeDebut(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SoftTypography
            sx={{ mb: 1 }}
            component="label"
            variant="caption"
            fontWeight="medium"
          >
            Heure de Fin
          </SoftTypography>
          <SoftInput
            id="time-emploi"
            type="time"
            defaultValue="17:00"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 900,
              min:heureDeDebut
            }}
            onChange={(e) => setHeureDeFin(e.target.value)}
          />
        </Grid>
      </Grid>
    </SoftBox>
  )
}
ConfigurationEmploi.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
