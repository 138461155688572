import { Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import SoftInput from "components/SoftInput";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import api from "utils/axios";
import validationSchema from "./validerChamps/ValidationSchema";
import PropTypes from "prop-types";
import { grey } from "@mui/material/colors";
import moment from "moment";
import Swal from "sweetalert2";

function UpdateCharge({ open, handleCloseModal, financierId, handleFinanciers }) {
  // Inside your component
  const theme = useTheme();
  let isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [financier, setFinancier] = useState({});
  const handleFinancier = (financierId) => {
    api.get(`get-financier/${financierId}`).then((res) => {
      setFinancier(res.data);
    });
  };

  useEffect(() => {
    if (financierId) {
      handleFinancier(financierId);
    }
  }, [financierId]);

  const handleSetFinancierType = (_, newValue) => {
    setFinancier({ ...financier, type: newValue });
  };

  const initialValues = {
    type: financier.type,
    titre: financier.titre,
    valeur: financier.valeur,
  };

  useEffect(() => {
    setFinancier({ ...financier, date: moment(financier.date).format("YYYY-MM-DD") });
  }, [financier.type]);

  const UpdateCharge = (financierId) => {
    if (
      financier.type !== "" &&
      financier.titre?.length >= 2 &&
      financier.valeur > 0 &&
      financier.date !== ""
    ) {
      const updatedFinancierData = {
        type: financier.type,
        titre: financier.titre,
        valeur: financier.valeur,
        date: financier.date,
      };
      api.put(`update-financier/${financierId}`, updatedFinancierData).then((res) => {
        handleFinanciers();
        handleCloseModal();
        Swal.fire({
          title: "Succès",
          text: "Update financière réussie",
          icon: "success",
          confirmButtonText: "ok",
        });
      });
    } else {
      if (financier.valeur <= 0) {
        Swal.fire({
          title: "Warning",
          text: "Veuillez ajouter une valeur positive",
          icon: "warning",
          confirmButtonText: "ok",
        });
      } else {
        Swal.fire({
          title: "Warning",
          text: "Veuillez ajouter les champs corrects.",
          icon: "warning",
          confirmButtonText: "ok",
        });
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      sx={{
        "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
          bgcolor: "rgba(0,0,0,0.03) !important",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          height: "60%",
          overflowY: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: "2px 2px 2px 2px grey",
          p: "24px 32px",
        }}
      >
        <Formik initialValues={initialValues} validationSchema={validationSchema}>
          {({ errors, setFieldValue, handleSubmit, handleChange, handleBlur }) => (
            <Form onSubmit={handleSubmit} style={{ marginTop: "8px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography variant="h5" sx={{ flexGrow: "1" }}>Editer Charge {financier.type === "Fixed" ? 'Fixe' : 'Variable'}</Typography>


                <IconButton onClick={handleCloseModal}>

                  <Close
                    fontSize="24px !important"
                    sx={{
                      "&:hover": {
                        bgcolor: grey[100],
                        borderRadius: "50%",
                      },
                    }}
                  />
                </IconButton>
              </Box>
      
              <Box sx={{ mt: "16px", width: "100%" }}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mb: 0.5 }}
                >
                  Titre
                </SoftTypography>
                <Field name="titre">
                  {({ field }) => (
                    <SoftInput
                      {...field}
                      variant="outlined"
                      error={financier.titre?.length < 2}
                      success={financier.titre?.length >= 2}
                      onBlur={handleBlur}
                      value={financier.titre}
                      onChange={(event) => {
                        handleChange(event);
                        setFinancier({ ...financier, titre: event.target.value });
                        setFieldValue("titre", event.target.value);
                      }}
                    />
                  )}
                </Field>
                {financier.titre?.length < 2 && (
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "400", fontSize: "12px" }}
                    color="error"
                  >
                    {errors.titre}
                  </Typography>
                )}
              </Box>
              <Box sx={{ mt: "16px", width: "100%" }}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mb: 0.5 }}
                >
                  Valeur
                </SoftTypography>
                <Field name="valeur">
                  {({ field }) => (
                    <SoftInput
                      {...field}
                      type="number"
                      placeholder="en DT"
                      variant="outlined"
                      error={financier.valeur <= 0}
                      success={financier.valeur > 0}
                      onBlur={handleBlur}
                      value={financier.valeur}
                      onChange={(event) => {
                        handleChange(event);
                        setFinancier({ ...financier, valeur: event.target.value });
                        setFieldValue("valeur", event.target.value);
                      }}
                    />
                  )}
                </Field>
                {financier.valeur <= 0 && (
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "400", fontSize: "12px" }}
                    color="error"
                  >
                    {errors.valeur}
                  </Typography>
                )}
              </Box>
              {/* {financier.type === "Variable" && ( */}
              <Box sx={{ mt: "16px", width: "100%" }}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  sx={{ mb: 0.5 }}
                >
                  Date
                </SoftTypography>

                <SoftInput
                  type="date"
                  value={
                    financier.date
                    // financier.date !== undefined
                    //   ? financier.date
                    //   : moment(new Date()).format("YYYY-MM-DD")
                  }
                  variant="outlined"
                  onChange={(event) => {
                    setFinancier({ ...financier, date: event.target.value });
                  }}
                />
              </Box>
              {/* )} */}
              <Box
                sx={{
                  mt: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >

                 <SoftButton

                  type="reset"
                  sx={{ mt: 2 }}
                  variant="outlined"
                  color="dark"
                  size="small"
                  onClick={handleCloseModal}
                >
                  Annuler
                </SoftButton>
                <SoftButton
                  type="submit"
                  variant="gradient"
                  color="purple"
                  onClick={() => {
                    UpdateCharge(financierId);
                  }}
                >
                  Modifier
                </SoftButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

UpdateCharge.propTypes = {
  open: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  financierId: PropTypes.string,
  handleFinanciers: PropTypes.func,
};

export default UpdateCharge;
