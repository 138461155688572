import checkout from "pages/user/client/schema/form";

const {
  formField: {
    firstName,
    lastName,
    email,
    address,
    zipCode,
    siteWeb,
    passeport,
    cin,
    rib,
    bank,
    description,
    phone,
    gender,
    country,
    city,
    cnssNumber,
    occupation,
    coporationName,
    coporationSerialNumber,
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [email.name]: "",
  [address.name]: "",
  [zipCode.name]: "",
  [siteWeb.name]: "",
  [passeport.name]: "",
  [cin.name]: "",
  [rib.name]: "",
  [bank.name]: "",
  [description.name]: "",
  [phone.name]: "",
  [gender.name]: "",
  [country.name]: "",
  [city.name]: "",
  [cnssNumber.name]: "",
  [occupation.name]: "",
  [coporationName.name]: "",
  [coporationSerialNumber.name]: "",
};

export default initialValues;