import { Avatar, Card, Grid, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import api from "utils/axios";
import { useEffect, useState } from "react";
import { useSoftUIController } from "context";
import MiniStatisticsCard from "./components/MiniStatisticsCard";
import ReactApexChart from 'react-apexcharts';
import { green, red } from "@mui/material/colors";
import DefaultLineChart from "./components/LineChart";
import Table from "examples/Tables/Table";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import ChatIcon from '@mui/icons-material/Chat';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

function Kpicompany(){
  const [controller, _] = useSoftUIController();
  
  const [nbinstructor , setnbinstructor] = useState(0);
  const handlecountinstructeur = ()=>{
    api.get("/countinstructors").then((res)=>{
      setnbinstructor(res.data.instructorCount)
    })
  }

  const [nbStudent , setnbStudent] = useState(0);
  const handcountstudents = ()=>{
    api.get("/countstudents").then((res)=>{
      setnbStudent(res.data.studentCount)
    })
  }

  const [countadmins , setcountadmins] = useState(0);
  const handlecountadmin = ()=>{
    api.get("countadmins").then((res)=>{
      setcountadmins(res.data.adminCount)
    })
  }
  const [countEditor , setcountEditor] = useState(0);
  const handlecounteditor = ()=>{
    api.get("counteditors").then((res)=>{
      setcountEditor(res.data.editorCount)
    })
  }

  const [TotalGainCompanyAdmin , setTotalGainCompanyAdmin] = useState("");
  const handlegaintotalCompanyAdmin = ()=>{
    api.get("get-all-gain-companyAdmin").then((res)=>{
      setTotalGainCompanyAdmin(res.data.totalGainCurrentYear);
      
    })
  }
  const [TotalGainCompanyAdminPrevious,setTotalGainCompanyAdminPrevious]= useState("");
  const handlegaintotalCompanyAdminPrevious = () => {
    api.get("get-all-gain-companyAdmin").then((res) => {
    setTotalGainCompanyAdminPrevious(res.data.totalGainPreviousYear) ; 
  })
  };

  
  const [TotalGainmonth , setTotalGainmonth] = useState("");
  const handlegainmounth = ()=>{
    api.get("get-monthly-gain-companyAdmin").then((res)=>{
      setTotalGainmonth(res.data.totalGainCurrentMonth);
      
    })
  }
  const [PercentageGain , setPercentageGain] = useState("");
  const handelPercentageGain = ()=>{
    api.get("get-monthly-gain-companyAdmin").then((res)=>{
      setPercentageGain(res.data.percentageDifferenceMonth);
      
    })
  }
  const [PercentageCharge , setPercentageCharge] = useState("");
  const handelPercentageCharge = ()=>{
    api.get("getChargeTotalForEveryMonthCurrentYear").then((res)=>{
      setPercentageCharge(res.data.percentageDifferenceMonth);
      
    })
  }
  const [PercentageChiffreAffaire , setPercentageChiffreAffaire] = useState("");
  const handelPercentageChiffreAffaire = ()=>{
    api.get("getMounthlychiffreAffaire").then((res)=>{
      setPercentageChiffreAffaire(res.data.percentageDifference);
      
    })
  }

  const [chargetotalCompanyAdmin, setchargetotalCompanyAdmin] = useState(0);
  const handleChargeTotal = () =>{
    api.get("getChargeTotalForYear").then((res)=>{
      setchargetotalCompanyAdmin(res.data.totalChargesCurrentYear)
    })
  }
  
  const [chargetotalMonth, setchargetotalMonth] = useState(0);
  const handlechargemonth = () =>{
    api.get("getChargeTotalForEveryMonthCurrentYear").then((res)=>{
      setchargetotalMonth(res.data.currentMonth)
    })
  }


  const [ChiffreAffaireCompanyAdmin, setChiffreAffaireCompanyAdmin] = useState("");
  const handleChiffreAffaireCompanyAdmin = () =>{
    api.get("getChiffreAffaireCompanyAdmin").then((res)=>{
      setChiffreAffaireCompanyAdmin(res.data.chiffreAffaireCurrentYear)

    })
  }
  const [ChiffreAffairemonth, setChiffreAffairemonth] = useState("");
  const handleChiffreAffairemounth = () =>{
    api.get("getMounthlychiffreAffaire").then((res)=>{
      setChiffreAffairemonth(res.data.chiffreAffaireCurrentMonth)

    })
  }
  

  const [percentageEspaceDeStockageUtiliser , setPercentageEspaceDeStockageUtiliser] = useState(0);
  const HandlePercentageOfEspaceDeStockageUtiliser = ()=>{
    api.get("get-espace-stockage-restant").then((res)=>{
      setPercentageEspaceDeStockageUtiliser(res.data.pourcentageStockageRestant)
    })
  }
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const hadnledatelicense = () => {
    api.get("LicencePeriode").then((res) => {
      const activePeriods = res.data.activePeriods;
      if (activePeriods && activePeriods.length > 0) {
        setStartDate(activePeriods[0].startDate);
        setEndDate(activePeriods[0].endDate);
      }
    });
  };

  const [ChargeMounthlyCompanyAdmin, setChargeMounthlyCompanyAdmin] = useState([]);
  const handleChargeMonthly = ()=>{
    api.get("get-Charge-forEverymonth").then((res)=>{
      setChargeMounthlyCompanyAdmin(res.data)
    })
  }

  const [gainMonthlyForCompanyAdmin, setgainMonthlyForCompanyAdmin] = useState([]);
  const handleGainMonthly = ()=>{
    api.get("get-each-month-gain-companyAdmin").then((res)=>{
      setgainMonthlyForCompanyAdmin(res.data)
    })
  }

  const [chiffreAffaireMounthlyCompanyAdmin , setchiffreAffaireMounthlyCompanyAdmin] = useState([]);
  const handlechiffreAffaireMounthlyCompanyAdmin = ()=>{
    api.get("chiffreAffaire/monthly").then((res)=>{
      setchiffreAffaireMounthlyCompanyAdmin(res.data)
    })
  }

  const [chargedayperCOmpanyadmin, setchargedayperCOmpanyadmin] = useState([]);
  const handleChargeDayly = (month)=>{
    api.get(`getChargeForSpecific/${month}`).then((res)=>{
      setchargedayperCOmpanyadmin(res.data)
    })
  }

  const [gaindayPercompanyAdmin, setgaindayPercompanyAdmin] = useState([]);
  const handleGainDayly = (month)=>{
    api.get(`get-Permonth-gain-companyAdmin/${month}`).then((res)=>{
      setgaindayPercompanyAdmin(res.data)
    })
  }

  const [chiffreAffaireDaylyForSuperAdmin , setChiffreAffaireDaylyForSuperAdmin] = useState([]);
  const handleChiffreAffaireDaylyForSuperAdmin = (month)=>{
    api.get(`get-chiffre-affaires-super-admin-per-day/${month}`).then((res)=>{
      setChiffreAffaireDaylyForSuperAdmin(res.data)
    })
  }

  const [moisChart, setMoisChart] = useState("January");

  useEffect(()=>{
    handleChargeDayly(moisChart);
    handleGainDayly(moisChart);
    handleChiffreAffaireDaylyForSuperAdmin(moisChart);
  },[moisChart]);

  const [gainMonthlyForCommercial, setGainMonthlyForCommercial] = useState([]);
  const handleGainMonthlyForCommercial = (commercialName)=>{
    const selectedCommercial = commercials.find(commercial => commercial.firstName + " " + commercial.lastName === commercialName);
  
    // Vérification si l'objet commercial a été trouvé
    if (selectedCommercial) {
      const commercialId = selectedCommercial._id;

      api.get(`get-total-gain-commercial-per-month/${commercialId}`).then((res)=>{
        setGainMonthlyForCommercial(res.data.monthlyGains);
      })
    }
  }

  const [gainDaylyForCommercial, setGainDaylyForCommercial] = useState([]);
  const handleGainDaylyForCommercial = (month , commercialName)=>{
    const selectedCommercial = commercials.find(commercial => commercial.firstName + " " + commercial.lastName === commercialName);
  
    // Vérification si l'objet commercial a été trouvé
    if (selectedCommercial) {
      const commercialId = selectedCommercial._id;
      api.get(`get-total-gain-commercial-per-day/${month}/${commercialId}`).then((res)=>{
        setGainDaylyForCommercial(res.data);
      })
    }
  }



  const [factureYearCompanyAdmin, setfactureYearCompanyAdmin] = useState("");
  const handleFacturesTotalCurrentYear = ()=>{
    api.get("/factures/count/year").then((res)=>{
      setfactureYearCompanyAdmin(res.data.count)
    })
  }

  const [FactireMonthCompanyAdmin, setFactireMonthCompanyAdmin] = useState("");
  const handleFacturesTotalCurrentMonth = ()=>{
    api.get("/factures/count/month").then((res)=>{
      setFactireMonthCompanyAdmin(res.data.count)
    })
  }

  const [devisTotalCurrentYearSuperAdmin, setDevisTotalCurrentYearSuperAdmin] = useState("");
  const handleDevisTotalCurrentYear = ()=>{
    api.get("/devis/count/year").then((res)=>{
      setDevisTotalCurrentYearSuperAdmin(res.data.count)
    })
  }

  const [DevisYearCompanyAdmin, setDevisYearCompanyAdmin] = useState("");
  const handleDevisTotalCurrentMonth = ()=>{
    api.get("/devis/count/month").then((res)=>{
      setDevisYearCompanyAdmin(res.data.count)
    })
  }
  

  const [dateDevis, setDateDevis] = useState("Current Year")
  const handleChangeDateDevis = (newValue) => {
    setDateDevis(newValue); 
  };

  const [dateFacture, setdatefacure] = useState("Current Year")



const [dateGain, setDateGain] = useState("Current Year");
const [dateCharge, setDateCharge] = useState("Current Year");
const [dateChiffreAffaire, setDateChiffreAffaire] = useState("Current Year");

  const handleChangeDateFacture = (newValue) => {
    setdatefacure(newValue); // Update the date value
  };

  const handleChangeDateGain = (newValue) => {
    setDateGain(newValue); // Update the gain date
  };
  
  const handleChangeDateCharge = (newValue) => {
    setDateCharge(newValue); // Update the charge date
  };
  
  const handleChangeDateChiffreAffaire = (newValue) => {
    setDateChiffreAffaire(newValue); // Update the chiffre d'affaire date
  };

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("getUsersFromOwnedFacilities");
        const formattedData = response.data.map(user => ({
          image: <Avatar  src={`${process.env.REACT_APP_BASE_URL}/${user.imageUrl}`} alt="Logo" />,
          nom: `${user.firstName} ${user.lastName}`,
          Statut:  <FiberManualRecordIcon sx={{ color: "#06B034" }} />,
          Message: <ChatIcon/>,  // Placeholder, adjust as needed
          Role: user.role
        }));
        setRows(formattedData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);
  
  


  useEffect(()=>{
   
    hadnledatelicense() ;
    handlecountinstructeur();
    handcountstudents();
    handlecountadmin();
    handlecounteditor();
    handlegaintotalCompanyAdmin();
    handleGainMonthly();
    handlegainmounth();
    handlegaintotalCompanyAdminPrevious();
    handelPercentageGain() ; 
    handelPercentageCharge();
    handelPercentageChiffreAffaire() ; 
   
    handleChargeTotal();
    handlechargemonth();
    handleChargeMonthly();
   

    handleChiffreAffaireCompanyAdmin();
    handleChiffreAffairemounth();
    handlechiffreAffaireMounthlyCompanyAdmin();

    HandlePercentageOfEspaceDeStockageUtiliser();

    
    handleFacturesTotalCurrentYear();
    handleFacturesTotalCurrentMonth();
    handleDevisTotalCurrentYear();
    handleDevisTotalCurrentMonth();
    
   
  },[]);

  const [chartData, setChartData] = useState(null);
  useEffect(() => {
    const newInitialData = {
      series: [percentageEspaceDeStockageUtiliser],
      options: {
        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                formatter: function () {
                  return undefined;
                },
              },
              value: {
                offsetY: 76,
                fontSize: '22px',
                color: 'undefined',
                formatter: function (val) {
                  return val + '%';
                },
              },
            },
          },
        },
        stroke: {
          dashArray: 4,
        },
      },
    };
  
    // Set fill colors based on the updated series value
    newInitialData.options.fill = {
      colors: percentageEspaceDeStockageUtiliser <= 70 ? [`${green[500]}`] : percentageEspaceDeStockageUtiliser >= 90 ? [`${red[800]}`] : [`${red[500]}`],
    };
  
    setChartData(newInitialData);
  }, [percentageEspaceDeStockageUtiliser]);

    return(
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3} mt="16px">
              <Grid item xs={12} md={6} lg={3}>
                <MiniStatisticsCard
                  title={{ text: "Instructor" }}
                  count={nbinstructor}
                  // percentage={{ color: "error", text: "+55%" }}
                  icon={{ color: "dark", component: "apartment" }}
                />
              </Grid> 

              <Grid item xs={12} md={6} lg={3}>
                <MiniStatisticsCard
                  title={{ text: "Student" }}
                  count={nbStudent}
                  // percentage={{ color: "error", text: "+55%" }}
                  icon={{ color: "dark", component: "apartment" }}
                />
              </Grid> 

              <Grid item xs={12} md={6} lg={3}>
                <MiniStatisticsCard
                  title={{ text: "admin" }}
                  count={countadmins}
                  // percentage={{ color: "error", text: "+55%" }}
                  icon={{ color: "dark", component: "apartment" }}
                />
              </Grid> 

              <Grid item xs={12} md={6} lg={3}>
                <MiniStatisticsCard
                  title={{ text: "Editor" }}
                  count={countEditor}
                  // percentage={{ color: "error", text: "+55%" }}
                  icon={{ color: "dark", component: "apartment" }}
                />
              </Grid> 
          

              <Grid container spacing={3} xs={12} lg={9} ml="0px" mt="0px">
                 
              <Grid item xs={12} lg={4}>
              <MiniStatisticsCard
  title={{ text: "Gain" }}
  date={dateGain}
  onChangeDate={handleChangeDateGain}
  count={dateGain === "Current Year" ? TotalGainCompanyAdmin : TotalGainmonth}
  percentage={dateGain === "Current Month" ? `+${PercentageGain}`: undefined }
  icon={{ color: "success", component: "attach_money" }}
/>
</Grid>


<Grid item xs={12} lg={4}>
  <MiniStatisticsCard
    title={{ text: "Charge" }}
    date={dateCharge}
    onChangeDate={handleChangeDateCharge}
    count={dateCharge === "Current Year" ? chargetotalCompanyAdmin : chargetotalMonth}
    percentage={dateCharge === "Current Month" ? PercentageCharge : undefined }

    icon={{ color: "error", component: "money_off" }}
  />
</Grid>

<Grid item xs={12} lg={4}>
  <MiniStatisticsCard
    title={{ text: "CA" }}
    date={dateChiffreAffaire}
    onChangeDate={handleChangeDateChiffreAffaire}
    count={dateChiffreAffaire === "Current Year" ? ChiffreAffaireCompanyAdmin : ChiffreAffairemonth}
    percentage={dateChiffreAffaire === "Current Month" ? PercentageChiffreAffaire : undefined }

    icon={{ color: "primary", component: "bar_chart" }}
  />
</Grid>

                 
              
               

                
                 <Grid item xs={12}>
                  <DefaultLineChart
                    title="Gestion Financier"
                    LineCharteData={{
                      labels: ["Jan", "Fev" , "Mar" , "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                      datasets: [
                        {
                          label: "Chiffre Affaires",
                          color: "primary",
                          data: chiffreAffaireMounthlyCompanyAdmin,
                        },
                        {
                          label: "Gain",
                          color: "success",
                          data: gainMonthlyForCompanyAdmin,
                        },
                        {
                          label: "Charge",
                          color: "error",
                          data: ChargeMounthlyCompanyAdmin
                        },
                      ],
                    }}
                    typeChartResponse={false}
                  />
                </Grid>
              </Grid> 





              <Grid item xs={12} lg={3}>
        
      {chartData !== null && (
        <Card id="chart" sx={{ p: 3 }}>
          <Typography variant="h6" color="initial">Company Admin espace de stockage</Typography>
          <ReactApexChart options={chartData.options} series={chartData.series} type="radialBar" height={350} />

          {/* Date Inputs */}
            {/* License Start Date */}
            <Typography variant="h6" fontWeight="medium" fontSize="12px"  sx={{ mt: 2 }}><span variant="h6" style={{color:"black",fontWeight:'bold'}}> License Periode :</span> {startDate} `//`  {endDate}
</Typography>
 
      {/* License End Date */}

          {/* Button to handle submission */}
      
        </Card>
      )}
    </Grid>

              <Grid item xs={12}>
                <DefaultLineChart
                  title="Financier"
                  LineCharteData={{
                    labels: ["Jan", "Fev" , "Mar" , "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                    datasets: [
                      {
                        label: "Chiffre Affaires",
                        color: "primary",
                        data: chiffreAffaireMounthlyCompanyAdmin
                      },
                      {
                        label: "Gain",
                        color: "success",
                        data: gainMonthlyForCompanyAdmin,
                      },
                      {
                        label: "Charge",
                        color: "error",
                        data: ChargeMounthlyCompanyAdmin
                      },
                      {
                        label: "Chiffre Affaires Par jour",
                        color: "primary",
                        data: chiffreAffaireDaylyForSuperAdmin
                      },
                      {
                        label: "Gain Par jour",
                        color: "success",
                        data: gaindayPercompanyAdmin,
                      },
                      {
                        label: "Charge Par jour",
                        color: "error",
                        data: chargedayperCOmpanyadmin
                      },
                    ],
                  }}
                  height="300px"
                  display="block"
                  typeChartResponse={true}
                  moisChart={moisChart}
                  setMoisChart={setMoisChart}
                />
              </Grid>

              

              <Grid container xs={12} md={12} spacing={3} m="0px">
                <Grid item xs={24} lg={4}>
                  <MiniStatisticsCard
                    title={{ text: "Factures" }}
                    date={dateFacture}
                    onChangeDate={handleChangeDateFacture}
                    count={dateFacture === "Current Year" ? factureYearCompanyAdmin : FactireMonthCompanyAdmin}
                    // percentage={{ color: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? "error" : "success", text: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? percentageChargeTotalForSuperAdmin : `+${percentageChargeTotalForSuperAdmin}` }}
                    icon={{ color: "warning", component: "assignment" }}
                  />
                </Grid> 

                <Grid item xs={12} lg={4}>
                  <MiniStatisticsCard
                    title={{ text: "Factures Payant" }}
                    count={0}
                    // percentage={{ color: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? "error" : "success", text: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? percentageChargeTotalForSuperAdmin : `+${percentageChargeTotalForSuperAdmin}` }}
                    icon={{ color: "warning", component: "assignment" }}
                  />
                </Grid> 
                <Grid item xs={12} lg={4}>
                  <MiniStatisticsCard
                    title={{ text: "Factures Non Payant" }}
                    count={0}
                    // percentage={{ color: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? "error" : "success", text: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? percentageChargeTotalForSuperAdmin : `+${percentageChargeTotalForSuperAdmin}` }}
                    icon={{ color: "warning", component: "assignment" }}
                  />
                </Grid> 
                

                <Grid item xs={12} lg={4}>
                  <MiniStatisticsCard
                    title={{ text: "Devis" }}
                    date={dateDevis}
                    onChangeDate={handleChangeDateDevis}
                    count={dateDevis === "Current Year" ? devisTotalCurrentYearSuperAdmin : DevisYearCompanyAdmin}
                    // percentage={{ color: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? "error" : "success", text: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? percentageChargeTotalForSuperAdmin : `+${percentageChargeTotalForSuperAdmin}` }}
                    icon={{ color: "warning", component: "assignment" }}
                    
                  />
                </Grid> 

                

                <Grid item xs={12} lg={4}>
                  <MiniStatisticsCard
                    title={{ text: "Devis Active" }}
                    count={0}
                    // percentage={{ color: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? "error" : "success", text: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? percentageChargeTotalForSuperAdmin : `+${percentageChargeTotalForSuperAdmin}` }}
                    icon={{ color: "warning", component: "assignment" }}
                  />
                </Grid> 

               

                <Grid item xs={12} lg={4}>
                  <MiniStatisticsCard
                    title={{ text: "Devis Désactive" }}
                    count={0}
                    // percentage={{ color: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? "error" : "success", text: percentageChargeTotalForSuperAdmin.charAt(0) === "-" ? percentageChargeTotalForSuperAdmin : `+${percentageChargeTotalForSuperAdmin}` }}
                    icon={{ color: "warning", component: "assignment" }}
                  />
                </Grid> 

                </Grid> 
               <Grid item xs={24} >
                <Card>
                  <SoftBox pt={3} px={3}>
                    <SoftTypography variant="h6" fontWeight="medium">
                      Administration
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox py={1}>
                    <Table
                      columns={[
                        { name: "image", align: "center" },
                        { name: "nom", align: "left" },
                        { name: "Statut", align: "center" },
                        { name: "Message", align: "center" },
                        { name: "Role", align: "center" },
                        
                        
                      ]}
                      rows={rows}
                     
                    />
                  </SoftBox>
                </Card>
              
          
              </Grid>

              {/* <Grid item xs={12} lg={6}>
                <PieCharte 
                  title="Nombres Des Taches Par Statut"
                  PieCharteData={{
                    labels: ["Taches A faire" , "Taches En cours", "Taches Terminer"],
                    datasets: {
                      backgroundColors: ["light", "success", "primary","warning", "dark", "secondary", "error", "info"],
                      data: [nbTachesAFaire, nbTachesEnCours, nbTachesTerminer]
                    },
                  }}
                  sousTitle={"tâches"}
                  sousNb={nbTaches}/>
              </Grid> */}

               
                  

              

            
          </Grid>
        </DashboardLayout>
    )
}

export default Kpicompany;