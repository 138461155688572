/** 
  All of the routes for the page layout of Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the DefaultNavbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `name` key is used for the name of the route on the DefaultNavbar.
  3. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  4. The `icon` key is used for the icon of the route on the DefaultNavbar, you have to add a node.
  5. The `collapse` key is used for making a collapsible item on the DefaultNavbar that contains other routes inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  6. The `route` key is used to store the route location which is used for the react router.
  7. The `href` key is used to store the external links location.
*/

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import HomeIcon from '@mui/icons-material/Home';

const pageRoutes = [
  {
    name: "Tableaux de bord",
    key: "dashboards",
    route: "/dashboards/default",
    icon: <Shop size="12px" color="white" />,
  },
  {
    name: "Accueil",
    key: "accueil",
    route: "/accueil/default",
    icon: <HomeIcon size="12px" color="white" />,
  },
  {
    name: "Entreprises",
    key: "companies",
    route: "/companies/companies",
    icon: <Office size="12px" color="white" />,
  },

  {
    name: "Commercial",
    key: "commercials",
    route: "/commercials/commercial",
    icon: <Office size="12px" color="white" />,
  },
  {
    name: "Finances",
    key: "finances",
    route: "/finances/default",
    icon: <Office size="12px" color="white" />,
  },
  {
    name: "Facturation",
    key: "billing",
    icon: <Shop size="12px" color="white" />,
    collapse: [
      {
        name: "Paramètres",
        key: "settings",
        route: "/billing/settings",
      },
      {
        name: "Devis",
        key: "devis",
        route: "/billing/devis",
      },
      {
        name: "Factures",
        key: "Factures",
        route: "/billing/factures",
      },
      {
        name: "Pré-inscription",
        key: "preregistration",
        route: "/billing/preregistration",
      },
      {
        name: "Inscription",
        key: "register",
        route: "/billing/register",
      },
    ],
  },
  {
    name: "Utilisateurs",
    key: "users",
    icon: <Document size="12px" color="white" />,
    collapse: [
      {
        name: " Collaborateur",
        key: "collaborator",
        route: "/users/collaborator",
      },
      {
        name: " Instructeur",
        key: "instructor",
        route: "/users/instructor",
      },
      {
        name: "Responsables",
        key: "responsables",
        route: "/users/responsables",
      },
      {
        name: " Éudiants",
        key: "student",
        route: "/users/student",
      },
    ],
  },
  ,
  {
    name: "Cours",
    key: "cours",
    route: "/cours/default",
    collapse: [
      {
        name: "Emploi de temps",
        key: "emploi-temps",
        route: "/cours/emploi-temps",
      },
      {
        name: "Emploi d'examen",
        key: "emploi-examen",
        route: "/cours/emploi-examen",
      },
      {
        name: "Matieres",
        key: "matieres",
        route: "/cours/matieres",
      },
      {
        name: "Notes",
        key: "notes",
        route: "/cours/notes",
      },
      {
        name: "Moyenne",
        key: "moyenne",
        route: "/cours/moyenne",
      },
      {
        name: "Présence",
        key: "presence",
        route: "/cours/presence",
      },
      {
        name: "Evaluation",
        key: "evaluation",
        route: "/cours/evaluation",
      },
    ]
  },
  {
    name: "Scolarité",
    key: "schooling",
    icon: <Document size="12px" color="white" />,
    collapse: [
      {
        name: "Domaine",
        key: "domain",
        route: "/schooling/domain",
      },
      {
        name: "Département",
        key: "departements",
        route: "/schooling/departements",
      },




      {
        name: "Formation",
        key: "formation",
        route: "/schooling/formation",
      },

      {
        name: "Filiére",
        key: "filiere",
        route: "/schooling/filiere",
      },{
        name: "Tronc Commun",
        key: "tronc-commun",
        route: "/schooling/tronc-commun",
      }, {
        name: "Spécialitées",
        key: "speciality",
        route: "/schooling/speciality",
      },{
        name: "Parcours",
        key: "parcours",
        route: "/schooling/parcours",
      },
      {
        name: "Modules",
        key: "modules",
        route: "/schooling/modules",
      },
      {
        name: "Classes",
        key: "classes",
        route: "/schooling/classes",
      },
      {
        name: "Matières",
        key: "subject",
        route: "/schooling/subject",
      },
      
      {
        name: "Plan",
        key: "plan",
        route: "/schooling/plan",
      },
      {
        name: "Paramètre",
        key: "parametre",
       // route: "/parametre/default",
        collapse: [
          {
            name: "Période",
            key: "period",
            route: "/parametre/period",
          }, 
          {
            name: "Sous Période",
            key: "sous-period",
            route: "/parametre/sous-period",
          },
        ]
      },
      {
        name: "Paramètres salle",
        key: "parametresalle",
       // route: "/schooling/classroom",
       collapse: [
        {
          name: "Bloc",
          key: "bloc",
          route: "/parametresalle/bloc",
        }, 
        {
          name: "Type",
          key: "type",
          route: "/parametresalle/type",
        }, 
        {
          name: "Salle",
          key: "salle",
          route: "/parametresalle/salle",
        }
      ]
      },

    ],
  },
  {
    name: "Planification",
    key: "planning",
    route: "/planning/default",
    collapse: [
      {
        name: "Emplois",
        key: "emplois",
        route: "/planning/emplois",
      },
      {
        name: "Examens",
        key: "examens",
        route: "/planning/examens",
      },
    ]
  },
  {
    name: "events",
    key: "events",
    route: "/events/default",
    icon: <Office size="12px" color="white" />,
  },
  
  {
    name: "Historique",
    key: "history",
    route: "/history/default",
  },
];

export default pageRoutes;
