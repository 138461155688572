import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import api from "utils/axios";
import SoftTypography from "components/SoftTypography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import SoftButton from "components/SoftButton";

import Stack from "@mui/material/Stack";

function Table() {
  const [loading, setLoading] = useState(false);
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [data, setData] = useState([]);


  const facility = JSON.parse(localStorage.getItem("facility"));

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "plan";
  }, []);
  const handleFetch = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/faculty/get-plan/${facility?.scholarityConfigId}`);
      setData(response?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);
 

  const handleVisibilityIconClick = (parcour) => {
    
    navigate(`/plan/plan/${parcour.name}`, { state: { data: parcour } });
  };
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "flex-start", sm: "space-between" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Liste des plans d&lsquo;études
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
              <SoftButton variant="outlined" color="purple" size="small" mr={2}>
                export
              </SoftButton>
            </Stack>
          </SoftBox>
          {loading ? (
            <SoftBox sx={{ mt: 13, textAlign: "center" }}>
              <CircularProgress />
            </SoftBox>
          ) : (
            <>
              <DataTable
                table={{
                  columns: [
                    { Header: "Nom du parcours", accessor: "name" },
                    { Header: "action", accessor: "action", width: "12%" },
                  ],
                  rows:
                    data &&
                    data.map((parcour) => {
                      return {
                        name:parcour.name,

                        action: (
                          <SoftTypography
                            variant="body1"
                            color="purple"
                            sx={{ cursor: "pointer", lineHeight: 0 }}
                          >
                            <Tooltip title="Consulter" placement="left">
                              <VisibilityIcon
                                  onClick={() => handleVisibilityIconClick(parcour)}
                              />
                            </Tooltip>
                          </SoftTypography>
                        ),
                      };
                    }),
                }}
                entriesPerPage={
                  afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false
                }
                canSearch={afficheFilter ? true : false}
              />
            </>
          )}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Table;
