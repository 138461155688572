import { Avatar, Grid, Icon, Typography } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftSelect from 'components/SoftSelect';
import SoftTypography from 'components/SoftTypography';
import EnterpriseFormField from 'pages/enterprise/schema/enterprise-schemas/EnterpriseFormField';
import React, { useState, useEffect, useRef } from 'react'
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";
import FormField from "layouts/pages/account/components/FormField";
import { ErrorMessage } from 'formik';
import { getCountryAdd } from 'utils/citysTn';
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { fetchData } from 'utils/citysTn';

export default function ResponsableB2BInfo({ formData }) {
  const [info, setInfo] = useState({});
  const [data, setData] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { formField, values, errors, touched } = formData;
  const {
    imageUrl,
    firstName,
    lastName,
    email,
    address,
    zipCode,
    cin,
    phone,
    country,
    city,
    coporationName,
    coporationSerialNumber
  } = formField;
  const {
    imageUrl: imageUrlV,
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    address: addressV,
    zipCode: zipCodeV,
    description: descriptionV,
    phone: phoneV,
    secondaryPhoneNumber: secondaryPhoneNumberV,
    cin: cinV,
    country: countryV,
    city: cityV,
    coporationName: coporationNameV,
    coporationSerialNumber: coporationSerialNumberV
  } = values;
  const [clientPhone, setClientPhone] = useState(phoneV);
  const [clientSecondPhone, setClientSecondPhone] = useState("");
  const [description, setDescription] = useState(descriptionV);
  const [image, setImage] = useState("");
  const fileInputRef = useRef(null);
  const countries = getCountryAdd(info, data);
  const [dataToSend, setDataToSend] = useState({
    country: countryV ? countryV : "",
    city: cityV ? cityV : "",
  });
  values.phone = clientPhone;
  values.secondaryPhoneNumber = clientSecondPhone;
  values.description = description;
  values.city = dataToSend.city;
  values.country = dataToSend.country;
  values.imageUrl = image;
  values.files = selectedOptions;

  const handleCountryChange = (selectedOption) => {
    const selectedCountry = selectedOption.value;
    const cities = data.find((country) => country.country === selectedCountry).cities;

    setDataToSend({ ...dataToSend, country: selectedCountry });
    setCityOptions(cities.map((city) => ({ value: city, label: city })));
  };

  const handleCityChange = (selectedOption) => {
    setDataToSend({ ...dataToSend, city: selectedOption.value });
  };

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteImage = () => {

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setImage(null);
  };

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // Specify the allowed file extensions
      const allowedExtensions = ["jpg", "jpeg", "png", "gif", "jfif"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        // Display an error message if the file extension is not allowed
        setErrorMessage("Unsupported file format. Please select a JPG, JPEG, PNG, or GIF file.");
        setImage(null); // Clear the logo
        return;
      }
      // Reset the error message if the file extension is allowed
      setErrorMessage("");
      setImage(selectedFile);
      // Specify the maximum allowed file size in bytes (e.g., 2MB)
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      const maxWidth = 650; // Maximum width in pixels
      const maxHeight = 650; // Maximum height in pixels
      if (selectedFile.size > maxSize) {
        // Display an error message if the file size exceeds the limit
        setErrorMessage("File size is too large. Maximum size allowed is 2MB.");
        setImage(null); // Clear the logo
      } else {
        // Check image dimensions
        const img = new Image();
        img.src = URL.createObjectURL(selectedFile);

        img.onload = () => {
          if (img.width > maxWidth || img.height > maxHeight) {
            // Display an error message if the image dimensions exceed the limit
            setErrorMessage(
              `Image dimensions are too large. Maximum dimensions allowed are ${maxWidth}x${maxHeight} pixels.`
            );
            setImage(null); // Clear the logo
          }
        };
      }
    }
  };
  useEffect(() => {
    const fetchDataAsync = async () => {
      const data = await fetchData();
      setData(data);
    };
    fetchDataAsync();
  }, []);
  return (
    <>
      <SoftBox >
        <SoftBox>
          <Grid item xs={12} sm={13} container justifyContent="center">
            <SoftBox position="relative" height="max-content" mx="auto">
              <Avatar
                onClick={handleAvatarClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{
                  filter: isHovered ? "blur(2px)" : "none",
                  cursor: "pointer",
                  width: 100,
                  height: 100,
                  borderRadius: "16px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={image ? URL.createObjectURL(image) : ""}
                value={image}
                alt="profile picture"
                size="xl"
                variant="rounded"
              />
              <SoftBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                <SoftButton
                  onClick={handleDeleteImage}
                  variant="gradient"
                  color="error"
                  size="small"
                  iconOnly
                >
                  <Icon>delete</Icon>
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </Grid>
          <input
            type="file"
            id="btn-img-client"
            // Specify the accepted file types
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={onFileChange}
            accept=".jpg, .jpeg, .png, .gif .jfif"
          />
          {errorMessage && (
            <Typography align="center" sx={{ mt: 2 }} variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
          <SoftBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="corporation-name"
                  type={coporationName.type}
                  label={coporationName.label}
                  name={coporationName.name}
                  value={coporationNameV}
                  inputProps={{
                    minLength: 2,
                    maxLength: 30
                  }}
                  placeholder={coporationName.placeholder}
                  error={errors.coporationName && touched.coporationName}
                  success={coporationNameV.length > 0 && !errors.coporationName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="corporation-serialNumber"
                  type={coporationSerialNumber.type}
                  label={coporationSerialNumber.label}
                  name={coporationSerialNumber.name}
                  value={coporationSerialNumberV}
                  inputProps={{
                    minLength: 2,
                    maxLength: 16
                  }}
                  placeholder={coporationSerialNumber.placeholder}
                  error={errors.coporationSerialNumber && touched.coporationSerialNumber}
                  success={coporationSerialNumberV.length > 0 && !errors.coporationSerialNumber}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftTypography my={1} variant="button" color="#8b8a8a" fontWeight="bold">
                  Information du directeur
                </SoftTypography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="firstname-client"
                  type={firstName.type}
                  label={firstName.label}
                  name={firstName.name}
                  value={firstNameV}
                  inputProps={{
                    minLength: 2,
                    maxLength: 30
                  }}
                  placeholder={firstName.placeholder}
                  error={errors.firstName && touched.firstName}
                  success={firstNameV.length > 0 && !errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="lastname-client"
                  type={lastName.type}
                  label={lastName.label}
                  name={lastName.name}
                  value={lastNameV}
                  inputProps={{
                    minLength: 2,
                    maxLength: 30
                  }}
                  placeholder={lastName.placeholder}
                  error={errors.lastName && touched.lastName}
                  success={lastNameV.length > 0 && !errors.lastName}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="email-client"
                  type={email.type}
                  label={email.label}
                  name={email.name}
                  value={emailV}
                  inputProps={{
                    maxLength: 50
                  }}
                  placeholder={email.placeholder}
                  error={errors.email && touched.email}
                  success={emailV.length > 0 && !errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="cin-client"
                  type={cin.type}
                  label={cin.label}
                  name={cin.name}
                  value={cinV}
                  inputProps={{
                    maxLength: 20
                  }}
                  placeholder={cin.placeholder}
                  error={errors.cin && touched.cin}
                  success={cinV.length > 0 && !errors.cin}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Téléphone*
                </SoftTypography>
                <PhoneInput
                  id="tel-client"
                  country={"tn"}
                  value={clientPhone}
                  enableSearch
                  inputStyle={{
                    width: "100%",
                  }}
                  onChange={(value) => {
                    setClientPhone(value);
                  }}
                />
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={phone.name} />
                  </SoftTypography>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Deuxième Téléphone
                </SoftTypography>
                <PhoneInput
                  id="second-phone"
                  country={"tn"}
                  enableSearch
                  inputStyle={{
                    width: "100%",
                  }}
                  value={clientSecondPhone}
                  onChange={(value) => {
                    setClientSecondPhone(value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Pays
                </SoftTypography>
                <SoftSelect
                  id="country-client"
                  value={dataToSend.country && { value: dataToSend.country, label: dataToSend.country }}
                  options={countries.map((country) => ({ value: country, label: country }))}
                  onChange={handleCountryChange}
                />
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={country.name} />
                  </SoftTypography>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Ville
                </SoftTypography>
                <SoftSelect id="city-client"
                  value={dataToSend.city && { value: dataToSend.city, label: dataToSend.city }}
                  options={cityOptions} onChange={handleCityChange} />
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={city.name} />
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="adresse-client"
                  type={address.type}
                  label={address.label}
                  name={address.name}
                  value={addressV}
                  inputProps={{
                    maxLength: 50
                  }}
                  placeholder={address.placeholder}

                />
              </Grid>


              <Grid item xs={12} sm={6}>
                <EnterpriseFormField
                  id="code-postal-client"
                  type={zipCode.type}
                  label={zipCode.label}
                  name={zipCode.name}
                  value={zipCodeV}
                  placeholder={zipCode.placeholder}
                  error={errors.zipCode && touched.zipCode}
                  success={zipCodeV.length > 0 && !errors.zipCode}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <FormField
                  multiline
                  rows={5}
                  id="description-client"
                  label="description"
                  value={description}
                  inputProps={{
                    maxLength: 250
                  }}
                  placeholder="description"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </>
  )
}
// typechecking props for CommercialIfo
ResponsableB2BInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
