import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DashboardNavbar from "layouts/DashboardNavbar";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ComplexProjectCard from "components/shared-users/Cards";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import SoftPagination from "components/SoftPagination"
// Project page components
import Header from "layouts/pages/profile/components/Header";
import api from "utils/axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import SoftSnackbar from 'components/SoftSnackbar';
import { useSoftUIController } from 'context';
function AllProjects() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle
  const [errorSB, setErrorSB] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const itemsPerPage = 8; // Nombre d'articles par page
  // ComplexProjectCard dropdown menu state
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  // TeamProfileCard dropdown menu handlers*
  const facility = JSON.parse(localStorage.getItem("facility"));
  const navigate = useNavigate();

  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [controller] = useSoftUIController();
  const userLogin = controller?.user?.info;

  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler"
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(`/facilities/${facility._id}/users/${selectedUserId}`);
            if (response) {
              (await Swal.fire("Supprimé !", "l'instructeur a été supprimé.", "success"))
                .isConfirmed && handleFetch();
              closeSlackBotMenu()
            }
          } catch (error) {
            Swal.fire("Une erreur s'est produite !", "l'instructeur n'a pas été supprimé.", "error");
            closeSlackBotMenu()
          }
        }
      });
  };
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  // Fonction pour passer à la page précédente
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={() => navigate(`/administration/instructor/view/${selectedUserId}`)}> <RemoveRedEyeIcon />   &nbsp; Voir plus</MenuItem>
      <MenuItem onClick={() => navigate(`/administration/instructor/${selectedUserId}`)}> <EditIcon />  &nbsp; Modifier</MenuItem>
      <MenuItem onClick={showAlert}> <DeleteIcon />&nbsp;   Supprimer</MenuItem>
    </Menu>
  );

  const handleFetch = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/get-all-users-by-role/${facility._id}?role=instructor`);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };
  useEffect(() => {
    // change page title in the header
    document.title = "instructor";
  }, []);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  // Extraction des données de la page actuelle
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);
  useEffect(() => {
    handleFetch();
  }, []);

  const renderErrorSB = (
    <SoftSnackbar
      color="error"
      icon="warning"
      title="Ajouter un responsable"
      content="Vous avez atteint le nombre maximum d'utilisateurs"
      dateTime="11 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );
  const licence = localStorage.getItem("licence");
  if (!licence) return;
  const parsedLicence = JSON.parse(licence);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="Liste des Instructeurs" searchVisible={true} ExportVisible={true} />
      {loading ? (
        <SoftBox
          sx={{
            mt: 13,
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </SoftBox>
      ) : (<>
        <SoftBox pb={2}>
          <SoftBox mt={{ xs: 1, lg: 2 }} mb={1}>
            <Grid container spacing={3}>

            {userLogin?.role !== "instructor"  && (
              <Grid
                onClick={() => parsedLicence.remainingUser === 0 ? openErrorSB() : navigate("/administration/instructor/add-instructor")}
                item
                xs={12}
                md={20}
                lg={4}
                sx={{ cursor: "pointer" }}
              >
                <PlaceholderCard
                  title={{ variant: "h5", text: "Ajouter  Instructeur" }}
                ></PlaceholderCard>
              </Grid>
             )}

              {currentData &&
                currentData?.map((user) => (
                  <Grid key={user?._id} item xs={12} md={6} lg={4}>
                    <ComplexProjectCard
                      image={user?.imageUrl?.startsWith("https")
                        ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                        : `${process.env.REACT_APP_BASE_URL}/${user?.imageUrl}`}
                      title={`${user.firstName} ${user.lastName}`}
                      phone={user.phoneNumber}
                      email={user.email}
                      description=""
                      occupation={user.occupation}
                      userId={user?._id}
                      etat={user?.etat}
                      disabled ={userLogin?.role === "instructor" ? true: false}
                      dropdown={{
                        action: (e) => {
                          // openSlackBotMenu;
                          setSlackBotMenu(e.currentTarget);
                          setSelectedUserId(user._id);
                        },
                        menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </SoftBox>
        </SoftBox>
        {totalPages > 1 && (<SoftPagination>
          {currentPage > 1 && (
            <SoftPagination item onClick={prevPage}><ArrowBackIosIcon /></SoftPagination>
          )}
          {Array.from({ length: totalPages }, (_, index) => (
            <SoftPagination item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </SoftPagination >
          ))}
          {currentPage < totalPages && (
            <SoftPagination item onClick={nextPage}><ArrowForwardIosIcon /> </SoftPagination>
          )}
        </SoftPagination>
        )}
      </>
      )}
      {renderErrorSB}
    </DashboardLayout>
  );
}
export default AllProjects;
