import React from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import { FormControl } from "material-ui-core";
const CommonComponentWeek = ({
  typeSalle,
  setSubjectType,
  setMatiere,
  setSalle,
  setTypeSalle,
  optionsMatiere,
  optionsSalle,
  optionsClasses,
  setClasse,
  parQuinzaine,
  setParQuinzaine,
  groupe,
  setGroupe,
  setWeek,
  errorMatiere,
  matiere,
  errorClasse,
  classe,
  errorSalle,
  salle,
  setSalleLink,
}) => {
  return (
    <>
      <SoftBox>
        <>
          {" "}
          <Grid
            container
            mt={0.5}
            mb={1}
            style={{
              border: "2px dashed #000",
              borderRadius: "5px",
              background: "#f0f0f0",
              padding: "10px",
            }}
          >
            <Grid container alignItems="center" item xs={12} sm={3}>
              <SoftTypography
                mr={1}
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
                onClick={() => setParQuinzaine(!parQuinzaine)}
              >
                Par quinzaine :
              </SoftTypography>
              <Checkbox checked={parQuinzaine} onChange={() => setParQuinzaine(!parQuinzaine)} />
            </Grid>
            <Grid container alignItems="center" item xs={12} sm={6}>
              <SoftTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                Par semaine :
              </SoftTypography>
              <Grid item ml={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    onChange={(e) => setWeek(e.target.value)}
                    defaultValue="toute"
                  >
                    <FormControlLabel value="toute" control={<Radio />} label="Toute" />
                    <FormControlLabel value="sem A" control={<Radio />} label="Semaine A" />
                    <FormControlLabel value="sem B" control={<Radio />} label="Semaine B" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={3} alignItems="center">
              <SoftTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                Par groupe :
              </SoftTypography>
              <Grid item ml={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    onChange={(e) => setGroupe(e.target.value)}
                  >
                    <FormControlLabel value="G1" control={<Radio />} label="G1" />
                    <FormControlLabel value="G2" control={<Radio />} label="G2" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Stack spacing={3}>
              <SoftBox>
                <SoftTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                  Choix des matières :
                </SoftTypography>
                <Autocomplete
                  id={`size-small-standard`}
                  options={optionsMatiere}
                  aria-required={true}
                  required={true}
                  clearIcon={null}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    setMatiere(newValue.value);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Radio
                        checked={selected}
                        onChange={() => {
                          /* handle option selection */
                        }}
                      />
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Sélectionner le nom de matière"
                      sx={{ mb: 2 }}
                      {...params}
                      required={true}
                      variant="standard"
                    />
                  )}
                />
              </SoftBox>
            </Stack>
            {matiere === "" && (
              <Typography mt={0.75} variant="caption" color="error">
                {errorMatiere}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            <SoftTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              La salle :
            </SoftTypography>
            <Grid ml={1} mb={2}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  onChange={(e) => {
                    setTypeSalle(e.target.value);
                    if (e.target.value === "Presentiel") {
                      setSalleLink("");
                    } else {
                      setSalle("");
                    }
                  }}
                  defaultValue="Presentiel"
                >
                  <FormControlLabel value="Presentiel" control={<Radio />} label="Présentiel" />
                  <FormControlLabel value="En ligne" control={<Radio />} label="En ligne" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={12}>
              {typeSalle === "En ligne" ? (
                <SoftInput
                  margin="normal"
                  id="outlined-basic"
                  label="Jour"
                  type="Text"
                  placeholder="Taper votre lien"
                  inputProps={{
                    min: "30",
                    max: "540",
                    step: "30",
                  }}
                  variant="standard"
                  onChange={(e) => setSalleLink(e.target.value)}
                />
              ) : (
                <Grid item xs={12} sm={12}>
                  <Stack spacing={3}>
                    <SoftBox>
                      <Autocomplete
                        id={`size-small-standard`}
                        options={optionsSalle}
                        aria-required={true}
                        required={true}
                        clearIcon={null}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          setSalle(newValue.value);
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Radio
                              checked={selected}
                              onChange={() => {
                                /* handle option selection */
                              }}
                            />
                            {option.label}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Sélectionner la salle"
                            sx={{ mb: 2 }}
                            {...params}
                            required={true}
                            variant="standard"
                          />
                        )}
                      />
                    </SoftBox>
                  </Stack>
                  {salle === "" && (
                    <Typography mt={0.75} variant="caption" color="error">
                      {errorSalle}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Stack spacing={3}>
              <SoftBox>
                <SoftTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                  Choix des classes :
                </SoftTypography>
                <Autocomplete
                  id={`size-small-standard`}
                  options={optionsClasses}
                  aria-required={true}
                  required={true}
                  clearIcon={null}
                  multiple={true}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    setClasse(newValue.map((value) => value.value));
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        checked={selected}
                        onChange={() => {
                          /* handle option selection */
                        }}
                      />
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Sélectionner la classe"
                      sx={{ mb: 2 }}
                      {...params}
                      required={true}
                      variant="standard"
                    />
                  )}
                />
              </SoftBox>
            </Stack>
            {classe === "" && (
              <Typography mt={0.75} variant="caption" color="error">
                {errorClasse}
              </Typography>
            )}
          </Grid>
        </>
      </SoftBox>
    </>
  );
};
CommonComponentWeek.propTypes = {
  typeSalle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setMatiere: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setTypeSalle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  optionsMatiere: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  optionsSalle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  optionsClasses: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setSubjectType: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setSalle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setClasse: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  parQuinzaine: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setParQuinzaine: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  groupe: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setGroupe: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setWeek: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  errorMatiere: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  matiere: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  errorClasse: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  classe: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  errorSalle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  salle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setSalleLink: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
export default CommonComponentWeek;
