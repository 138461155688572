import SoftBox from "components/SoftBox";
import React, { useEffect, useState } from "react";
import {
  Paper,
  List,
  Button,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Grid,
} from "@mui/material";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { ErrorMessage, useFormikContext } from "formik";
import FaqCollapse from "layouts/pages/pricing-page/components/FaqCollapse";
import api from "utils/axios";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function InstructorInfoUpdate({ formData }) {
  const [collapse, setCollapse] = useState(1);
  const { formField, errors, touched, values } = formData;
  const { setFieldError, setFieldValue } = useFormikContext();
  const [checked, setChecked] = React.useState([]);
  const [data, setData] = useState([]);
  const [searchQueries, setSearchQueries] = useState({
    COURS: "",
    TD: "",
    TP: "",
    MiniProjet: "",
  });
  const initialSectionsState = {
    COURS: {
      left: [],
      right: values.instructorCours,
    },
    TD: {
      left: [],
      right: values.instructorTd,
    },
    TP: {
      left: [],
      right: values.instructorTp,
    },
    MiniProjet: {
      left: [],
      right: values.instructorMp,
    },
  };
  // Fonction pour comparer les objets
  function comparerObjets(objet1, objet2) {
    return objet1._id === objet2._id;
  }
  const [sectionsState, setSectionsState] = useState(initialSectionsState);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const { instructorCours, instructorTd, instructorTp, instructorMp } = formField;
  const handleFetch = async () => {
    try {
      const response = await api.get(`/get-all-users-by-role/${facility._id}?role=instructor`);
      setData(response.data);
      setSectionsState({
        ...initialSectionsState,
        COURS: {
          ...initialSectionsState.COURS,
          left: response.data.filter(
            (objet1) =>
              !sectionsState["COURS"].right.some((objet2) => comparerObjets(objet1, objet2))
          ),
        },
        TD: {
          ...initialSectionsState.TD,
          left: response.data.filter(
            (objet1) => !sectionsState["TD"].right.some((objet2) => comparerObjets(objet1, objet2))
          ),
        },
        TP: {
          ...initialSectionsState.TP,
          left: response.data.filter(
            (objet1) => !sectionsState["TP"].right.some((objet2) => comparerObjets(objet1, objet2))
          ),
        },
        MiniProjet: {
          ...initialSectionsState.MiniProjet,
          left: response.data.filter(
            (objet1) =>
              !sectionsState["MiniProjet"].right.some((objet2) => comparerObjets(objet1, objet2))
          ),
        },
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleCheckedRight = (section) => {
    const { left, right } = sectionsState[section];
    const leftChecked = intersection(checked, left);
    setSectionsState({
      ...sectionsState,
      [section]: {
        left: not(left, leftChecked),
        right: right.concat(leftChecked),
      },
    });
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = (section) => {
    const { left, right } = sectionsState[section];
    const rightChecked = intersection(checked, right);
    setSectionsState({
      ...sectionsState,
      [section]: {
        left: left.concat(rightChecked),
        right: not(right, rightChecked),
      },
    });
    setChecked(not(checked, rightChecked));
  };

  const filterItems = (data, section) =>
    data.filter((value) =>
      searchQueries[section]?.trim() === ""
        ? true
        : `${value.firstName} ${value.lastName}`.includes(searchQueries[section])
    );

  values.instructorCours = sectionsState["COURS"].right?.map((r) => r._id);
  values.instructorTd = sectionsState["TD"].right?.map((r) => r._id);
  values.instructorTp = sectionsState["TP"].right?.map((r) => r._id);
  values.instructorMp = sectionsState["MiniProjet"].right?.map((r) => r._id);

  const customList = (title, data, section, showSearch = false) => (
    <Paper>
      <Grid item xs={12} md={12} lg={12}>
        <SoftBox p={3}>
          <SoftBox ml="auto">
            {showSearch && (
              <SoftInput
                size="large"
                icon={{ component: "search", direction: "left" }}
                placeholder="Rechercher..."
                value={searchQueries[section]}
                onChange={(e) => setSearchQueries({ ...searchQueries, [section]: e.target.value })}
              />
            )}
          </SoftBox>
        </SoftBox>
      </Grid>
      <List
        sx={{
          width: 400,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {filterItems(data, section).map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          return (
            <ListItemButton key={value} role="listitem" onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.firstName} ${value.lastName}`} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );

  const sections = [
    values.checkedCours && "COURS",
    values.checkedTd && "TD",
    values.checkedTp && "TP",
    values.checkedMp && "MiniProjet",
  ].filter(Boolean);

  return (
    <SoftBox mt={3}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <SoftTypography
            variant="h2"
            align="center"
            fontWeight="bold"
            gutterBottom
          ></SoftTypography>
        </Grid>
        <Grid item xs={12} md={11}>
          {sections.map((section, index) => (
            <>
              <FaqCollapse
                key={index}
                title={section}
                isRequired
                open={collapse === index + 1}
                onClick={() =>
                  collapse === index + 1 ? setCollapse(false) : setCollapse(index + 1)
                }
              >
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                  <Grid item>
                    {customList("Choices", sectionsState[section].left, section, true)}
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={() => handleCheckedRight(section)}
                        disabled={sectionsState[section].left.length === 0}
                        aria-label="move selected right"
                      >
                        &gt;
                      </Button>
                      <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={() => handleCheckedLeft(section)}
                        disabled={sectionsState[section].right.length === 0}
                        aria-label="move selected left"
                      >
                        &lt;
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>{customList("Chosen", sectionsState[section].right, section)}</Grid>
                </Grid>
              </FaqCollapse>
              <SoftBox mt={0.75}>
                <SoftTypography component="div" variant="caption" color="error">
                  <ErrorMessage
                    name={
                      section === "COURS"
                        ? instructorCours.name
                        : section === "TD"
                        ? instructorTd.name
                        : section === "TP"
                        ? instructorTp.name
                        : instructorMp.name
                    }
                  />
                </SoftTypography>
              </SoftBox>
            </>
          ))}
        </Grid>
      </Grid>
    </SoftBox>
  );
}

InstructorInfoUpdate.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
