import { useEffect, useState } from "react";
// @mui material components
import Tooltip from "@mui/material/Tooltip";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import CustomSwitch from "components/shared-users/Switchic";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import api from "utils/axios";

function ListIconCardStudent({ student, etat}) {
  const [followsMe, setFollowsMe] = useState(
    etat === "active" ? true : etat === "desactive" ? false : null
  );

  const navigate = useNavigate();
  const showAlertDesactivateAccount = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `${etat === "active" ? "Desactiver" : "Activer"}`,
        cancelButtonText: "Annuler"
      })
      .then((result) => {
        if (result.isConfirmed) {
          api
            .post(`/change-status-user/${student?._id}`)

            .then((res) => {
              if (res.data.etat === "active") {
                Swal.fire(
                  "Activé!",
                  "L'etudiant a été activée avec succès.",
                  "success"
                ).then(() => {
                  navigate(0);
                });
              } else if (res.data.etat === "desactive") {
                Swal.fire(
                  "Désactivé !",
                  "L'etudiant' a été désactivé avec succès.",
                  "success",
                  "success"
                ).then(() => {
                  navigate(0);
                });
              }
            })
            .catch(() => {
              Swal.fire("Erreur !", "L'etudiant n'a pas été désactivé ou activé.", "erreur");
            });
        }
        else if (result.dismiss === Swal.DismissReason.cancel) {
          setFollowsMe(followsMe);
        }
      });
  };
  return (
    <SoftBox display="flex" alignItems="center">

      <SoftTypography
        variant="body1"
        color="secondary"
        sx={{ cursor: "pointer", lineHeight: 0 }}
      ></SoftTypography>
      {etat === "enAttente" ? (
        " En attente"
      ) : (
        <Tooltip title={etat === "active" ? "Active" : etat === "desactive" ? "Desactiver" : null}>
          <CustomSwitch
            onClick={showAlertDesactivateAccount}
            checked={followsMe}
            onChange={() => setFollowsMe(!followsMe)}
          />
        </Tooltip>
      )}
    </SoftBox>
  );
}
ListIconCardStudent.propTypes = {
  student: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  etat: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  setOpen: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ListIconCardStudent;
