import { useEffect, useState } from "react";
import { Box, Card, IconButton, Tooltip, CircularProgress, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { green, grey, red } from "@mui/material/colors";
import {
  Delete,
  DragHandle,
  Edit,
  KeyboardArrowDown, 
  KeyboardDoubleArrowUp,
} from "@mui/icons-material";
import api from "utils/axios";
import RemainingTime from "./TempsLimite/RemainingTime";
import PropTypes from 'prop-types';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';


function ListeTâches() {
  const { projectID } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1); // Track current page number
  const [taches, setTaches] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("accessToken");

  const facility = JSON.parse(localStorage.getItem("facility"));

  const listeTaches = async () => {
    setLoading(true);
    try {

      let endpoint;
      // Determine the endpoint URL based on facility type
      if (facility?.type === "centreDeFormation") {
        endpoint = `/training-company/get-tache-ByProjet-centre-formation/${projectID}`;
      } else {
        endpoint = `/faculty/get-tache-ByProjet-faculty/${projectID}`;
      }
  
      const response = await api.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTaches(response.data);
    } catch (error) {
      console.error("Error fetching taches:", error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    listeTaches();
  }, [projectID]);

  const [nameTache, setNameTache] = useState([]);
  const ListeProjet = async () => {
    try {
      let endpoint ;
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/get-projects-center-formation/${facility.scholarityConfigId}`;
      } else {
        endpoint = `faculty/get-projects-faculty/${facility.scholarityConfigId}`;
      }
      const response = await api.get(endpoint,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const projects = response.data;
      const projectOptions = projects.map(project => ({
        value: project._id,
        label: project.nom,
      }));
      setNameTache(projectOptions);
    } catch (error) {
      console.error('Error fetching project names:', error);
    }
  };

  useEffect(() => {
    ListeProjet();


  }, []);

  const handleProjectChange = selectedOption => {
    navigate(`/suiviTache/listeTaches/${selectedOption.value}`);
  };
  const deleteTache = async (id) => {
    try {
      let endpoint;
      // Determine the endpoint URL based on facility type
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/delete-tache-center-formation/${id}`;
      } else {
        endpoint = `faculty/delete-tache-faculty/${id}`;
      }
      await api.delete(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      await listeTaches();
      Swal.fire({
        title: "Succès",
        text: "Suppression de la tâche réussie",
        icon: "success",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "button button-success",
        },
        buttonsStyling: false,
      });
    } catch (error) {
      Swal.fire({
        title: "Erreur",
        text: "Erreur lors de la suppression de la tâche",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "button button-error",
        },
        buttonsStyling: false,
      });
    }
  };

  const confirmDeleteTache = (id) => {
    Swal.fire({
      title: "Êtes-vous sûr(e)",
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer !",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTache(id);
      }
    });
  };
  const getLast6Characters = (code) => code?.slice(-6);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Tooltip title="Liste Des Projet">
                          <IconButton
                            sx={{ "&:hover": { bgcolor: grey[200] } }}
                            onClick={() => {
                              navigate("/suiviTache/ListeProjet");
                            }}
                          >
                            <TurnLeftIcon fontSize="Large" color="purple" />
                          </IconButton>
                        </Tooltip>

      <Card sx={{ my: 2 }}>
        {loading ? (
          <Box sx={{ mt: 13, textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              p={3}
            >
              <SoftTypography >
                Liste Des Tache
              </SoftTypography>

              <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                <SoftSelect
                  placeholder="Choissiser un Projet"
                  options={nameTache}
                  size="medium"
                  onChange={handleProjectChange}
                />
                <SoftButton
                  variant="gradient"
                  color="purple"
                  size="small"
                  onClick={() => navigate(`/suiviTache/kanbanTaches/${projectID}`)}
                >
                  Kanban Tache
                </SoftButton>
                <SoftButton
                  variant="gradient"
                  color="purple"
                  size="small"
                  onClick={() => navigate(`/suiviTache/kanbanTaches/ajouterTache/${projectID}`)}
                >
                  Ajouter une tâche
                </SoftButton>
              </Stack>
            </Box>
            <DataTable
              table={{
                columns: [
                  { Header: "Nom", accessor: "nom", width: "10%" },
                  { Header: "Responsable", accessor: "responsable", width: "15%" },
                  { Header: "Temps Restant", accessor: "tempsRestant", width: "15%", align: "center" },
                  { Header: "Priorité", accessor: "priorite", width: "10%" },
                  { Header: "Statut", accessor: "statut", width: "10%" },
                  { Header: "Date Limite", accessor: "dateLimite", width: "15%" },
                  { Header: "Évaluation", accessor: "evaluation", width: "10%" },
                  { Header: "Action", accessor: "action", width: "15%" },
                ],
                rows: taches.slice((currentPage - 1) * taches.length, currentPage * taches.length).map((tache) => ({
                  nom: tache.nom,
                  responsable: tache.responsable,
                  tempsRestant: tache.statut !== "Terminer" ? (
                    <Box color={true ? `${red[500]} !important` : `${green[500]} !important`}>
                      <RemainingTime dateLimite={tache.dateLimite} />
                    </Box>
                  ) : (
                    "_"
                  ),
                  priorite: (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {tache.priorite === "Haute" ? (
                        <KeyboardDoubleArrowUp color="error" />
                      ) : tache.priorite === "Moyenne" ? (
                        <DragHandle color="warning" />
                      ) : (
                        <KeyboardArrowDown color="info" />
                      )}
                      <Typography variant="body" sx={{ ml: "8px" }}>
                        {tache.priorite}
                      </Typography>
                    </Box>
                  ),
                  statut: tache.statut,
                  dateLimite: tache.dateLimite,
                  evaluation: (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {tache.evaluation === "Aucune" ? (
                        <SoftBadgeDot color="secondary" size="xs" badgeContent="Aucune" />
                      ) : tache.evaluation === "Positive" ? (
                        <SoftBadgeDot color="success" size="xs" badgeContent="Positive" />
                      ) : (
                        <SoftBadgeDot color="error" size="xs" badgeContent="Négative" />
                      )}
                    </Box>
                  ),
                  action: (
                    <Box display="flex" alignItems="center">
                      <Box mx={2}>
                        <Tooltip title="Modifier">
                          <IconButton
                            sx={{ "&:hover": { bgcolor: grey[200] } }}
                            onClick={() => {
                              navigate(`/suiviTache/listeTaches/editerTache/${tache._id}`);
                            }}
                          >
                            <Edit fontSize="small" color="purple" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Tooltip title="Supprimer">
                        <IconButton
                          sx={{ "&:hover": { bgcolor: grey[200] } }}
                          onClick={() => confirmDeleteTache(tache._id)}
                        >
                          <Delete fontSize="small" color="purple" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ),
                })),
              }}
              entriesPerPage={{ defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }}
              canSearch
            />
          </Box>
        )}
      </Card>
    </DashboardLayout>
  );
}
ListeTâches.propTypes = {
  id: PropTypes.string.isRequired,
};
export default ListeTâches; 
