import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Teams page components
import Stories from "./components/Stories";
import Post from "./components/Post";
import Header from "./components/Header";

import ProfilesListInvitation from "./components/ProfilesListInvitation";
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";
import ProfilesListAmie from "./components/ProfilesListAmie";
import ProfilesListSuggestion from "./components/ProfilesListSuggestion";
import FormField from "pages/billing-settings/components/FormField";
import SoftButton from "components/SoftButton";
import { Autocomplete, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SoftInput from "components/SoftInput";
import SoftAvatar from "components/SoftAvatar";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import EmojiPicker from "emoji-picker-react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Swal from "sweetalert2";
import api from "utils/axios";
import { useNavigate } from "react-router-dom";
import { useSoftUIController } from "context";
function Home() {
  const [marketingMenu, setMarketingMenu] = useState(null);
  const [designMenu, setDesignMenu] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const facility = JSON.parse(localStorage.getItem("facility"));
  // TeamProfileCard dropdown menu handlers
  const openMarketingMenu = (event) => setMarketingMenu(event.currentTarget);
  const closeMarketingMenu = () => setMarketingMenu(null);
  const openDesignMenu = (event) => setDesignMenu(event.currentTarget);
  const closeDesignMenu = () => setDesignMenu(null);
  const navigate=useNavigate()
  const [controller] = useSoftUIController();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    const newOption = { value: file, label: file.name };

    // Check if the file is already selected
    const isSelected = selectedOptions.some((option) => option.label === newOption.label);

    if (!isSelected) {
      // If it's not selected, add it to both options and selectedOptions
      setOptions([newOption]);
      setSelectedOptions([newOption]);
    }
  };
  // Dropdown menu for the digital marketing TeamProfileCard
  const renderMarketingMenu = (
    <Menu
      anchorEl={marketingMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(marketingMenu)}
      onClose={closeMarketingMenu}
      keepMounted
    >
      <MenuItem onClick={closeMarketingMenu}>Action</MenuItem>
      <MenuItem onClick={closeMarketingMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMarketingMenu}>Something else here</MenuItem>
    </Menu>
  );

  // Dropdown menu for the design TeamProfileCard
  const renderDesignMenu = (
    <Menu
      anchorEl={designMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(designMenu)}
      onClose={closeDesignMenu}
      keepMounted
    >
      <MenuItem onClick={closeDesignMenu}>Action</MenuItem>
      <MenuItem onClick={closeDesignMenu}>Another action</MenuItem>
      <MenuItem onClick={closeDesignMenu}>Something else here</MenuItem>
    </Menu>
  )
  useEffect(() => {
    // change page title in the header
    document.title = "accueil";
  }, []);
  // add emoji
  const addEmoji = (e) => {
    const sym = e.unified.split("_");
    const codeArray = [];
    sym.forEach((el) => codeArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codeArray);
    setDescription(description + emoji);
  };
 
  const handleAddPost =async () => {
    try {
      setLoading(true);
    const form = new FormData();
    selectedOptions &&
        selectedOptions.length > 0 &&
        selectedOptions.forEach((item) => {
          form.append("image", item.value);
        });
      form.append("description", description);
      form.append("facilityId", facility?._id);
      const response = await api.post(`/social-media/create-post`, form);
      if (response.status === 201) {
        Swal.fire("Succès !", "Le poste a été ajouté avec succès", "success").then(() => {
          setLoading(false);
          navigate(0);
        });
      }
    } catch (error) {
      setLoading(true);
      if (error.response && error.response.data && error.response.data.message) {
        Swal.fire("Error", error.response.data.message, "error").then(() => navigate(0));
      } else {
        Swal.fire(
          "Error",
          "Une erreur s'est produite. Veuillez réessayer ultérieurement.",
          "error"
        ).then(() => navigate(0));
      }
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddPost();
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  return (
    <DashboardLayout>
      <Header />
      <SoftBox my={3}>
        <Stories />
      </SoftBox>
      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Card sx={{ padding: "10px", marginBottom: "10px" }}>
              <SoftBox display="flex">
                <SoftBox
                  borderRadius="50%"
                  width="3.625rem"
                  height="3.625rem"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="white"
                  mx="auto"
                  sx={{ cursor: "pointer", marginRight: "3px" }}
                >
                  <SoftAvatar src={
                      controller?.user?.info?.imageUrl?.startsWith("https")
                      ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                      : `${process.env.REACT_APP_BASE_URL}/${controller?.user?.info?.imageUrl}`
  
                  } alt={"logo"} />
                </SoftBox>
                <SoftInput style={{ cursor: 'pointer' }} onClick={handleClickOpen}
                  placeholder="Commencer un post" />
              </SoftBox>

              <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}
                onKeyDown={handleKeyPress}>
                <DialogTitle textAlign='left'>
                  <Grid item xs={5} sm={5} md={5} lg={5}
                    display="flex" sx={{ flex: "0 0 100%" }}>
                    <SoftBox
                      borderRadius="50%"
                      width="3.625rem"
                      height="3.625rem"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      color="white"
                      mx="auto"
                      sx={{ cursor: "pointer" }}
                    >
                      <SoftAvatar src={controller?.user?.info?.imageUrl?.startsWith("https")
                      ? "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                      : `${process.env.REACT_APP_BASE_URL}/${controller?.user?.info?.imageUrl}`} alt={"name"} />
                    </SoftBox>
                    <SoftBox
                      ml={2}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="center"
                    >
                      <SoftTypography variant="button" fontWeight="medium">
                        {controller?.user?.info?.firstName} {controller?.user?.info?.lastName}
                      </SoftTypography>
                      <SoftTypography variant="caption" color="text">
                        Visibilité : Tout le monde
                      </SoftTypography>
                    </SoftBox>
                  </Grid>

                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.grey[200],
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent >

                  <SoftBox  >
                    <FormField
                      mb={2}
                      id="description"
                      value={description}
                      multiline
                      rows={4}
                      placeholder="De quoi souhaitez-vous discuter ?"
                      inputProps={{
                        maxLength: 250
                      }}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <span style={{ display: "flex", justifyContent: "flex-end", margin: "2px" }}
                      onClick={() => setShowEmoji(!showEmoji)}
                      className="cursor-pointer hover:text-slate-300"
                    >
                      <SentimentSatisfiedAltIcon />
                    </span>
                    {showEmoji && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '-50%',
                          right: '2rem',
                        }}
                      >
                        <EmojiPicker
                          //data={data}
                          emojiSize={20}
                          emojiButtonSize={28}
                          onEmojiClick={addEmoji}
                          maxFrequentRows={0}
                        />

                      </div>
                    )}
                    <SoftBox>
                      <Grid container spacing={3}>
                        <Grid sx={{ mt: 2, mr: "auto", display: "flow" }} item xs={12}>
                          <SoftButton
                            id="btn-upload"
                            sx={{ mb: 1 }}
                            component="label"
                            onChange={handleFileUpload}
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                          >
                            Télécharger image
                            <VisuallyHiddenInput type="file" />
                          </SoftButton>
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            placeholder="Télécharger image "
                            options={options}
                            value={selectedOptions}
                            filterSelectedOptions
                            freeSolo
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" placeholder="Télécharger image" />
                            )}
                            onChange={(event, newValue) => {
                              setSelectedOptions(newValue);
                            }}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                          />
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </SoftBox>

                </DialogContent>
                <DialogActions>
                  <SoftBox mt={2} width="100%" display="flex" justifyContent="flex-end">
                   {loading ? (
              <CircularProgress />
            ) : (
                    <SoftButton
                      onClick={handleAddPost}
                      variant="gradient"
                      color="customized"
                      disabled={(description || selectedOptions &&
                        selectedOptions.length > 0) ? false : true}
                    >
                      Post
                    </SoftButton>
                  )} 
                  </SoftBox>
                </DialogActions>
              </Dialog>
            </Card>
            <Post />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid item xs={12}>
              <SoftBox mb={3}>
                <ProfilesListInvitation title="Invitation" profiles={profilesListData} />
              </SoftBox>
            </Grid>
            <Grid item xs={12}>
              <SoftBox mb={3}>
                <ProfilesListAmie title="Amie" profiles={profilesListData}
                  dropdown={{
                    action: openMarketingMenu,
                    menu: renderMarketingMenu,
                  }} />
              </SoftBox>
            </Grid>
            <Grid item xs={12}>
              <SoftBox mb={3}>
                <ProfilesListSuggestion title="Suggestion" profiles={profilesListData} />
              </SoftBox>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Home;
