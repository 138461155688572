import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DashboardNavbar from "layouts/DashboardNavbar";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ComplexProjectCard from "pages/resource-management/classe/components/Cards";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import SoftPagination from "components/SoftPagination";
import Header from "layouts/pages/profile/components/Header";
import api from "utils/axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import logoInvision from "assets/images/classe.png";
import { useSoftUIController } from "context";

function AllClasses() {
  const [searchQuery, setSearchQuery] = useState("");
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const navigate = useNavigate();
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [selectedStudentCode, setSelectedStudentCode] = useState(null);
  const [controller] = useSoftUIController();
  const user = controller?.user?.info
  const hasEditDeletePermission =
  user?.role === 'company-admin' ||
  user?.role === 'admin' ||
  user?.role === 'editor';
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            let endpoint;
            if (facility?.type === "centreDeFormation") {
              endpoint = `training-company/delete-classe/${selectedStudentId}`;
            } else {
              endpoint = `/faculty/delete-classe/${selectedStudentId}`;
            }
            const response = await api.delete(endpoint);
            if (response) {
              (await Swal.fire("Supprimé !", "La classe a été supprimée.", "success"))
                .isConfirmed && handleFetch();
              closeSlackBotMenu();
            }
          } catch (error) {
            Swal.fire("Une erreur s'est produite !", "La classe n'a pas été supprimé.", "error");
            closeSlackBotMenu();
          }
        }
      });
  };
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Fonction pour passer à la page précédente
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem id="btn-show" onClick={() => navigate(`/classes/view/${selectedStudentId}`)}>
        {" "}
        <RemoveRedEyeIcon /> &nbsp; Voir plus
      </MenuItem>
      {hasEditDeletePermission && (<><MenuItem id="btn-edit" onClick={() => navigate(`/classes/${selectedStudentId}`)}>
        <EditIcon /> &nbsp; Modifier
      </MenuItem>
      <MenuItem id="btn-delete" onClick={showAlert}>
        <DeleteIcon />
        &nbsp; Supprimer
      </MenuItem></>)}
    </Menu>
  );
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const facility = JSON.parse(localStorage.getItem("facility"));
    const handleFetch = async () => {
      try {
        let endpoint;
        if (facility?.type === "centreDeFormation") {
          endpoint = `training-company/classes/byRole/${user?._id}/${facility.scholarityConfigId}`;
        } else {
          endpoint = `/faculty/get-classes-by-role/${user?._id}`;
        }
        const response = await api.get(endpoint);
        setData(response.data);
      } catch (error) {
        throw error;
      }
    };
    useEffect(() => {
      handleFetch();
    }, []);
    useEffect(() => {
      document.title = "classe";
    }, []);
   // Ensure data is an array before filtering
const filteredData = Array.isArray(data) ? data.filter(classe => {
  return classe?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase());
}) : [];
    const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
    // Extraction des données de la page actuelle
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = filteredData?.slice(startIndex, endIndex);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="Liste des classes" searchQuery={searchQuery} setSearchQuery={setSearchQuery} searchVisible={true} ExportVisible={true} />
      {loading ? (
        <SoftBox
          sx={{
            mt: 13,
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </SoftBox>
      ) : (
        <>
          <SoftBox pb={2}>
            <SoftBox mt={{ xs: 1, lg: 2 }} mb={1}>
              <Grid container spacing={1.5}>
                {hasEditDeletePermission && (<Grid
                  onClick={() => navigate("/classes/add-class")}
                  item
                  xs={12}
                  md={20}
                  lg={4}
                  sx={{ cursor: "pointer" }}
                >
                  <PlaceholderCard
                    title={{ variant: "h5", text: "Ajouter classe" }}
                  ></PlaceholderCard>
                </Grid>)}
                {currentData && facility &&
                  currentData?.map((classe) => (
                    facility?.type === "centreDeFormation" ? (
                      <Grid key={classe?._id} item xs={12} md={6} lg={4}>
                        <ComplexProjectCard
                          image={logoInvision}
                          name={classe?.name}
                          students={classe?.students?.length}
                          studentId={classe?._id}
                          dateTime={classe?.year}
                          start={classe?.startDate}
                          end={classe?.endDate}
                          studentList={classe?.students}
                          members={[team1, team2, team3, team4]}
                          dropdown={{
                            action: (e) => {
                              setSlackBotMenu(e.currentTarget);
                              setSelectedStudentId(classe?._id);
                              setSelectedStudentCode(classe?._id);
                            },
                            menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid key={classe?._id} item xs={12} md={6} lg={4}>
                        <ComplexProjectCard
                          image={logoInvision}
                          name={classe?.name}
                          students={classe?.students?.length}
                          level={classe?.parcour?.niveau?.name}
                          specialitie={classe?.parcour?.speciality?.name}
                          studentId={classe?._id}
                          departementId={classe?.parcour?.speciality?.departement?.name}
                          dateTime={classe?.year}
                          start={classe?.semester?.startDate}
                          groupes={classe?.groupNumber}
                          capacite={classe?.abilityMaximum}
                          studentList={classe?.students}
                          end={classe?.semester?.endDate}
                          members={[team1, team2, team3, team4]}
                          dropdown={{
                            action: (e) => {
                              setSlackBotMenu(e.currentTarget);
                              setSelectedStudentId(classe?._id);
                              setSelectedStudentCode(classe?._id);
                            },
                            menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                          }}
                        />
                      </Grid>
                    )
                  ))}
              </Grid>
            </SoftBox>
          </SoftBox>
          {totalPages > 1 && (
            <SoftPagination>
              {currentPage > 1 && (
                <SoftPagination item onClick={prevPage}>
                  <ArrowBackIosIcon />
                </SoftPagination>
              )}
              {Array.from({ length: totalPages }, (_, index) => (
                <SoftPagination
                  item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                </SoftPagination>
              ))}
              {currentPage < totalPages && (
                <SoftPagination item onClick={nextPage}>
                  <ArrowForwardIosIcon />{" "}
                </SoftPagination>
              )}
            </SoftPagination>
          )}
        </>
      )}
    </DashboardLayout>
  );
}

export default AllClasses;
