import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Card, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import api from "utils/axios";
import { passwordRegEx } from "utils/regex";
import CheckIcon from '@mui/icons-material/Check';

export default function ChangePassword({ user,isProfile }) {
  
  const location = useLocation();
  const pathname = location.pathname.split("/").slice(1);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    currentPassword:"",
    passwordValue: "",
    isValidPassword: false,
    isPasswordTouched: false, // is email input touched
    confirmPassValue: "",
    isValidConfirmPass: false,
    isConfirmPassTouched: false, // is password input touched
  });
  // handle the password visibility
  const changePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword(prevState => !prevState);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword(prevState => !prevState);
    }
  }
  const handleCurrentPasswordChange = (e) =>{
    setCredentials((prevState) => ({
      ...prevState,
      currentPassword: e.target?.value,
    }));
  }

  const digitRegEx = /\d/;
  const lowercaseRegEx = /[a-z]/;
  const uppercaseRegEx = /[A-Z]/;
  const specialCharacterRegEx = /[!@#$%^&*()\-_=~`+[{\]}\\|:;"'<,>.?/]/;




  const [isValidSpecialCharacter, setIsValidSpecialCharacter] = useState(false);
const [isValidMinimumLength, setIsValidMinimumLength] = useState(false);
const [isValidUppercase, setIsValidUppercase] = useState(false);
const [isValidLowercase, setIsValidLowercase] = useState(false);
const [isValidDigit, setIsValidDigit] = useState(false);
const handleRequirementChange = (password) => {
  setIsValidSpecialCharacter(specialCharacterRegEx.test(password));
  setIsValidMinimumLength(password.length >= 8);
  setIsValidUppercase(uppercaseRegEx.test(password));
  setIsValidLowercase(lowercaseRegEx.test(password));
  setIsValidDigit(digitRegEx.test(password));
};


const handlePassworChange = (e) => {
  const newPassword = e.target?.value;

  setCredentials((prevState) => ({
    ...prevState,
    passwordValue: newPassword,
    isValidPassword: passwordRegEx.test(newPassword),
    isPasswordTouched: true,
  }));
  handleRequirementChange(newPassword);
};

const handleConfirmPasswordChange = (e) => {
  const confirmPassValue = e.target?.value;

  setCredentials((prevState) => ({
    ...prevState,
    confirmPassValue,
    isValidConfirmPass: confirmPassValue === credentials.passwordValue,
  }));
};

// ...

  const handlePasswordValidation = () => {
    setCredentials((prevState) => ({
      ...prevState,
      isPasswordTouched: true,
      isValidPassword: passwordRegEx.test(prevState.passwordValue),
    }));
  };
  const handleConfirmPasswordValidation = () => {
    setCredentials((prevState) => ({
      ...prevState,
      isConfirmPassTouched: true,
      isValidConfirmPass: prevState.confirmPassValue === credentials.passwordValue,
    }));
  };
  
  const navigate = useNavigate();
  const passwordRequirements = [
    "Un caractère spécial",
    "Minimum 8 caractères",
    "Au moins une lettre majuscule",
    "Au moins une lettre minuscule ",
    "Au moins un chiffre ",
    
  ];
  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;
    let isValid;
  
    switch (key) {
      case 0:
        isValid = isValidSpecialCharacter;
        break;
      case 1:
        isValid = isValidMinimumLength;
        break;
      case 2:
        isValid = isValidUppercase;
        break;
      case 3:
        isValid = isValidLowercase;
        break;
      case 4:
        isValid = isValidDigit;
        break;
      default:
        isValid = false;
        break;
    }
  
    return (
      <SoftBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <SoftTypography variant="button" color={isValid ? "success" : "black"} fontWeight="regular" verticalAlign="middle">
          {item} {isValid ? <CheckIcon /> : ""}
        </SoftTypography>
      </SoftBox>
    );
  });
  

  const handleSubmit = async () => {
    try {
      const response = await api.patch(`/reset-password-by-admin/${user?._id}`, {
        newPassword: credentials.passwordValue,
      });
      if (response) {
        Swal.fire("Succés", "Votre mot de passe a été modifié avec succès. ", "success").then(() => {
          setCredentials((prevState) => ({
            ...prevState,
            confirmPassValue: "",
            passwordValue: "",
            isPasswordTouched: false,
            isConfirmPassTouched: false,
          }));
  
          // Réinitialiser les états des exigences de mot de passe ici
          setIsValidSpecialCharacter(false);
          setIsValidMinimumLength(false);
          setIsValidUppercase(false);
          setIsValidLowercase(false);
          setIsValidDigit(false);
        });
      }
    } catch (error) {
      throw error;
    }
  };
  
  const handleUpadateUserProfile = async() =>{
    try {
      const response = await api.put(`/password/${user._id}`,{
        currentPassword: credentials.currentPassword,
        newPassword: credentials.passwordValue
      });
      if(response){
        Swal.fire("Succés", "Votre mot de passe a été modifié avec succès. ", "success").then(() => {
          navigate(0);
        });  
      }
    } catch (error) {
      
      Swal.fire("Error", "Mot de passe actuel est incorrect", "error");
    }
  }

  return (
    <Card id="change-password">
      <SoftBox p={3}>
        <SoftTypography variant="h5">Changer le mot de passe</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          {pathname[0] === "settings" && (
        <Grid item xs={12}>
            <SoftTypography variant="caption" fontWeight="bold" color="text">
              Mot de passe actuel
            </SoftTypography>
           
            
              <SoftInput
              id="nv-mot-de-passe"
                onChange={handleCurrentPasswordChange}
                label="Mot de passe actuel"
                placeholder="Mot de passe actuel"
                inputProps={{ type:showPassword ? "text" : "password"}}
                icon = {{ component: (showPassword ? <VisibilityIcon  onClick={()=>changePasswordVisibility("password")} sx={{ cursor: "pointer" }} id="visibility-icon" /> : <VisibilityOffIcon  onClick={()=>changePasswordVisibility('password')} sx={{ cursor: "pointer" }} id="visibility-icon" />), direction: "right" }}
              />
            
          </Grid>
          )
           }
          <Grid item xs={12}>
            <SoftTypography variant="caption" fontWeight="bold" color="text">
              Nouveau Mot de passe
            </SoftTypography>
            <SoftInput
            id="nv-mot-de-passe"
            value={credentials.passwordValue}
              onBlur={handlePasswordValidation}
              success={credentials.isPasswordTouched && credentials.isValidPassword}
              error={credentials.isPasswordTouched && !credentials.isValidPassword}
              onChange={handlePassworChange}
              label="Nouveau mot de passe"
              placeholder="Nouveau mot de passe"
              inputProps={{ type:showPassword ? "text" : "password"}}
              icon = {{ component: (showPassword ? <VisibilityOffIcon variant="gradient"
              color="purple" onClick={()=>changePasswordVisibility("password")} sx={{ cursor: "pointer" }} id="visibility-icon" /> : <VisibilityIcon variant="gradient"
              color="purple" onClick={()=>changePasswordVisibility('password')} sx={{ cursor: "pointer" }} id="visibility-icon" />), direction: "right" }}
            />
          </Grid>
          <Grid item xs={12}>
            <SoftTypography variant="caption" fontWeight="bold" color="text">
              Confirmer votre mot de passe
            </SoftTypography>
            <SoftInput
            id="cofirm-mot-de-passe"
              label="Nouveau mot de passe"
              value={credentials.confirmPassValue}
              onBlur={handleConfirmPasswordValidation}
              success={credentials.isConfirmPassTouched && credentials.isValidConfirmPass}
              error={credentials.isConfirmPassTouched && !credentials.isValidConfirmPass}
              onChange={handleConfirmPasswordChange}
              placeholder="Nouveau mot de passe"
              inputProps={{ type:showConfirmPassword ? "text" : "password"}}
              icon = {{ component: (showConfirmPassword ? <VisibilityOffIcon variant="gradient"
              color="purple" onClick={()=>changePasswordVisibility("confirmPassword")} sx={{ cursor: "pointer" }} id="visibility-icon" /> : <VisibilityIcon variant="gradient"
              color="purple" onClick={()=>changePasswordVisibility("confirmPassword")} sx={{ cursor: "pointer" }} id="visibility-icon" />), direction: "right" }}
            />
          </Grid>
        </Grid>
        <SoftBox mt={6} mb={1}>
          <SoftTypography variant="h5">Exigences de mot de passe</SoftTypography>
        </SoftBox>
        <SoftBox mb={1}>
          <SoftTypography variant="body2" color="text">
          Veuillez suivre ce guide pour un mot de passe fort
          </SoftTypography>
        </SoftBox>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          <SoftBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </SoftBox>
        
        </SoftBox>
        <SoftBox display="flex" justifyContent="flex-end">
  <SoftButton
    id="btn-change-pass"
    disabled={
      (credentials.isConfirmPassTouched && !credentials.isValidConfirmPass) ||
      (credentials.isPasswordTouched && !credentials.isValidPassword) ||
      credentials.passwordValue === "" ||
      credentials.confirmPassValue === ""
    }
    variant="gradient"
    color="purple"
    size="small"
    onClick={!isProfile ? handleSubmit : handleUpadateUserProfile}
  >
    Changer le mot de passe
  </SoftButton>
</SoftBox>
      </SoftBox>
    </Card>
  );
}
ChangePassword.defaultProps = {
  isProfile: false,
};
ChangePassword.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  isProfile: PropTypes.oneOfType([PropTypes.bool]),
};
