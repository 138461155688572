import { useEffect, useRef, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import {CircularProgress, Slide } from "@mui/material";
import api from "utils/axios";
import Stack from '@mui/material/Stack';
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import LevelActions from "./components/LevelActions";
function LevelTable() {
  const [loading, setLoading] = useState(false);
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [data, setData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const myRef = useRef(null);
  const facility = JSON.parse(localStorage.getItem("facility"));
  useEffect(() => {
    document.title = "niveau";
  }, []);
  const handleFetch = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/faculty/get-all-levels/${facility?.scholarityConfigId}`);
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetch();
  }, []);
  const getLast6Characters = (code) => code?.slice(-6);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Liste des niveaux
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>

              <SoftButton variant="outlined" color="purple" size="small" mr={2}>
                export
              </SoftButton>
            </Stack>
          </SoftBox>
          {loading ? (
            <SoftBox
              sx={{
                mt: 13,
                textAlign: "center",
              }}
            >
              <CircularProgress />
            </SoftBox>
          ) : (
            <div>
              <Slide direction="right" in={!showDetails} mountOnEnter unmountOnExit>
                <div ref={myRef}>
                  {!showDetails && (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "code", accessor: (row) => getLast6Characters(row?.code) },
                          { Header: "Nom du niveau", accessor: "levelName" },
                          { Header: "Département affectée", accessor: "departementName" , Cell: row => row.value ? row.value : "-" },
                          { Header: "Domaine", accessor: "domaineName" , Cell: row => row.value ? row.value : "-" },
                          { Header: "sous periodes", accessor: "sousPeriodes" , Cell: row => row.value ? row.value : "-" },
                          { Header: "action", accessor: "action", width: "10%" },
                        ],
                        rows:
                          data &&
                          data?.map((level, index) => {                         
                            return {
                              code: level?.code,
                              levelName:level.name,
                              departementName: level?.filiere?.departement?.name,                    
                              domaineName: level?.filiere?.departement?.domaine?.name,
                              sousPeriodes:level?.sousPeriodes?.map((sousPeriode,index) => (
                                <div key={sousPeriode?._id}>
                                  <ul>                                 
                                      <li key={index}>{sousPeriode?.name}</li> 
                                  </ul>                  
                                </div>
                              )),  
                              action: (
                                <LevelActions
                                  key={index}
                                  level={level}
                                  handleFetch={handleFetch}
                                  setShowDetails={setShowDetails}
                                  setDetailsData={setDetailsData}
                                />
                              ),                                                  
                            };
                          }),
                      }}
                      entriesPerPage={
                        afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] } : false
                      }
                      canSearch={afficheFilter ? true : false}
                    />
                  )}
                </div>
              </Slide>           
            </div>
          )}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default LevelTable;
