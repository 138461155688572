import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import { Box, Grid, Typography } from "@mui/material";
import SoftButton from "components/SoftButton";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import AjouterDemande from "../AjouterDemande";
import UpdateDemande from "../UpdateDemande";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Card from "./components/Card";
import api from "utils/axios";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import Swal from "sweetalert2";
import UpdateDemandeByStudent from "../UpdateDemandeByStudent";
import SoftSelect from "components/SoftSelect";
import { useNavigate, useParams } from "react-router-dom";
import ViewDemande from "../ViewDemande";
import SelectData from "../data/SelectData";
import { useSoftUIController } from "context";
function KanbanDemande() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [controller, _] = useSoftUIController();

  //ajouter Demande
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };


  // update Modal By an admin or editor
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);

  const handleOpenUpdateModal = (id) => {
    setSelectedCardId(id);
    setOpenUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setOpenUpdateModal(false);
  };

  //update Modal By a student
  const [openUpdateModalByStudent, setOpenUpdateModalByStudent] = useState(false);
  const [selectedUpdateDemandeByStudent, setSelectedUpdateDemandeByStudent] = useState(null);

  const handleOpenUpdateModalByStudent = (id) => {
    setSelectedUpdateDemandeByStudent(id);
    setOpenUpdateModalByStudent(true);
  };

  const handleCloseUpdateModalByStudent = () => {
    setOpenUpdateModalByStudent(false);
  };

  //voir demande Modal by student
  const [openViewModalByStudent, setOpenViewModalByStudent] = useState(false);
  const [selectedViewDemandeByStudent, setSelectedViewDemandeByStudent] = useState(null);

  const handleOpenViewModalByStudent = (id) => {
    setSelectedViewDemandeByStudent(id);
    setOpenViewModalByStudent(true);
  };

  const handleCloseViewModalByStudent = () => {
    setOpenViewModalByStudent(false);
  };

  const [demandes, setDemandes] = useState([]);
  const [fullNamesStudent, setFullNamesStudent] = useState([]);
  const [fullName, setFullName] = useState("");
  const [niveaux , setNiveaux] = useState([]);
  const [niveauEtudiant, setNiveauEtudiant] = useState("");
  const [filieres , setFilieres] = useState([]);
  const [filiereEtudiant ,setFiliereEtudiant] = useState("");
  const handleDemandes = () => {
    if(controller.user.info.role === "admin" || controller.user.info.role === "editor"){
      api.get(`get-demandes-by-userId/${controller.user.info._id}/${facility._id}`).then((res) => {
        setDemandes(res.data.demandesDocsByPersonneConnecter);
        setFullNamesStudent(res.data.studentsName);
        setFilieres(res.data.filieres);
        setNiveaux(res.data.niveaux);
      });
    }
    if(controller.user.info.role === "student"){
      api.get(`get-demandes-student/${controller.user.info._id}`).then((res) => {
        setDemandes(res.data);
      });
    }
  };
  useEffect(() => {
    handleDemandes();
  }, []);


  const defaultOption = { value: "Default", label: "Default" };
  const [typeEvent, setTypeEvent] = useState("");
  const filteredOptions = [defaultOption, ...SelectData.type];

  const [students, setStudents] = useState([]);
  const getStudents = () => {
    api.get("get-user-commercial?role=student").then((response) => {
      setStudents(response.data);
    });
  };

  useEffect(() => {
    getStudents();
  }, []);

  const facility = JSON.parse(localStorage.getItem("facility"));

  const [filiereId, setFiliereId] = useState("");
  const [niveauId , setNiveauId] = useState("");
  const handleAllFiliers = async () => {
    try {
      const response = await api.get(
        `/faculty/all-filiere/${facility?.scholarityConfigId}`
      );
      
      if(filiereEtudiant !== ""){
        const filiereId = response?.data.map((filiere)=>{
          return filiere._id;
        })
        setFiliereId(filiereId);
      }

      if(niveauEtudiant !== ""){
        const niveauId = response?.data.flatMap((filiere)=>{
          return filiere.niveaux.map((niv)=>{ return niv.name === niveauEtudiant ? niv._id : null }).filter(id => id !== null);
        })
        setNiveauId(niveauId[0]);
      }
    } catch (error) {
       throw error
    }
  };

  const [parcours , setParcours] = useState([]);
  const handleAllParcours = async () =>{
    try {
      const response = await api.get(
        `/faculty/all-parcours/${facility?.scholarityConfigId}`
      );
      setParcours(response.data)
    } catch (error) {
       throw error
    }
  }
  useEffect(() => {
    if(facility !== null){
      handleAllFiliers();
      handleAllParcours();
    }
  }, [niveauEtudiant , filiereEtudiant]);

  const cards = (stat) => {
    let filteredDemandes = [];

    // Apply combined filters
    filteredDemandes = demandes.filter((demande) => {

      // Filter by fullName if it's not empty and matches the user's full name
      const fullNameFilter =
        fullName !== ""
          ? students.find(
              (student) =>
                `${student.firstName} ${student.lastName}` === fullName &&
                demande.userId === student._id
            )
          : true;

      //Filter by type event 
      const typeEventFilter =
        typeEvent!== ""
       ? demande.type === typeEvent
          : true;

      //Filter by filière
      const filiereEtudiantFilter = filiereEtudiant !== "" ? 

      parcours.some(parcour => {
        const filiereIdExists = parcour.niveau && parcour.niveau.filiere;
        const usersExist = parcour.users;
        if (filiereIdExists && usersExist) {
          return parcour.niveau.filiere.includes(filiereId) && parcour.users.includes(demande.userId);
        }
        return false;
      }) 
      : true;
      
      //Filter by niveau
      const niveauEtudiantFilter = niveauEtudiant !== "" ? 
      parcours.some(parcour => {
        const niveauIdExists = parcour.niveau && parcour.niveau._id;
        const usersExist = parcour.users;
        if (niveauIdExists && usersExist) {
          return parcour.niveau._id.includes(niveauId) && parcour.users.includes(demande.userId);
        }
        return false;
      })
        : true;
      // Filter by role
      const roleFilter =
      controller.user.info.role === "student" ? demande.userId === controller.user.info._id : true;
      // Filter by statut
      const statutFilter = demande.statut === stat;

      // Return true if all filters pass, otherwise false
      return fullNameFilter && typeEventFilter && filiereEtudiantFilter && niveauEtudiantFilter && statutFilter && roleFilter;
    });


    const cardsUtiliser = filteredDemandes.map((demande) => ({
      id: demande._id,
      template: (
        <Card
        id={demande._id}
          evaluation={{
            color:
              demande.evaluation === "Valider"
                ? "success"
                : demande.evaluation === "Non valider"
                ? "error"
                : "secondary",
            label: demande.evaluation !== undefined ? demande.evaluation : "Neutral",
          }}
          type={demande.type}
          description={demande.description.length >= 45 ? demande.description.substring(0,45) +  "..." : demande.description }
          date={demande.createdAt}
          onAddEventClick={() => {
            updateDemandeisEventAdded(demande._id, true);
            navigate(`/events/kanbanEvents/ajouterEvent/${demande._id}`);
          }}
        />
      ),
    }));

    return cardsUtiliser;
  };

  const status = {
    columns: [
      {
        id: "0",
        title: "A faire",
        cards: cards("A faire"),
      },
      {
        id: "1",
        title: "En cours",
        cards: cards("En cours"),
      },
      {
        id: "2",
        title: "Terminer",
        cards: cards("Terminer"),
      },
    ],
  };


  const [columns, setColumns] = useState(status.columns);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Do nothing if the item is dropped outside the list
    if (!destination) return;

    // Do nothing if the item is dropped in the same place
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // Find the dragged task using its ID
    const draggedDemande = demandes.find((demande) => demande._id === result.draggableId);

    if (!draggedDemande) {
      return;
    }

    // Determine the new status based on the destination.droppableId
    let newStatus = "";
    switch (destination.droppableId) {
      case "0":
        newStatus = "A faire";
        break;
      case "1":
        newStatus = "En cours";
        break;
      case "2":
        newStatus = "Terminer";
        break;
    }

    // Check if the evaluation is Neutral and the destination status is Terminer
    if (
      draggedDemande.evaluation === undefined &&
      destination.droppableId === "2" // Terminer column
    ) {
      Swal.fire({
        title: "Warning",
        text: "Veuillez sélectionner le statut de la demande.",
        icon: "warning",
        confirmButtonText: "ok",
      });
    } else {
      if (
        draggedDemande.statut === "Terminer" &&
        (draggedDemande.evaluation === "Valider" || draggedDemande.evaluation === "Non valider") &&
        destination.droppableId !== source.droppableId
      ) {
        // Show a confirmation alert indicating that the card cannot be moved
        Swal.fire({
          title: "Warning",
          text: "L'évaluation a déjà été choisie.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
        newStatus = "Terminer";
      }
      // Update the task status locally
      const updatedDemandes = demandes.map((demande) =>
        demande._id === draggedDemande._id ? { ...demande, statut: newStatus } : demande
      );
      setDemandes(updatedDemandes);

      // Update the task status in the backend
      updateDemandeStatus(draggedDemande._id, newStatus);
    }
  };

  const updateDemandeStatus = (demandeId, newStatus) => {
    const updatedDemande = { statut: newStatus };
    api.put(`update-demande/${demandeId}`, updatedDemande);
  };

  const updateDemandeisEventAdded = (demandeId, isEventAdded) => {
    const updatedDemande = { isEventAdded: isEventAdded };
    api.put(`update-demande/${demandeId}`, updatedDemande);
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box display="flex" mt="32px" alignItems="center" marginBottom={5}>
        <Typography variant="h5"sx={{ flexGrow: "1", }}>
          Kanban des documents demandés
        </Typography>
        {controller.user.info.role === "student" && (
          <SoftButton 
            variant="gradient"
            color="purple"
            onClick={() => {
              handleOpenModal();
            }}
          >
            Ajouter une demande
          </SoftButton>
        )}
      </Box>
      {open && (
        <AjouterDemande
          open={open}
          handleCloseModal={handleCloseModal}
          handleDemandes={handleDemandes}
        />
      )}

      {(controller.user.info.role === "admin" || controller.user.info.role === "editor" || controller.user.info.role === "company-admin") && (
        <>
          
          <Grid container>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  mr: { xs: "0px", md: "4px" },
                  mt: { xs: "16px", md: "0px" },
                  zIndex: 5,
                  position: "relative",
                }}
              >
                <Typography variant="body2">Nom et Prénom</Typography>

                <SoftSelect
                  options={fullNamesStudent.map((fullName) => ({
                    value: fullName,
                    label: fullName,
                  }))}
                  size="medium"
                  placeholder="Nom et Prénom"
                  onChange={(event) => { 
                    if(event.value === "Default"){
                      setFullName("");
                    }else{
                      setFullName(event.value);
                    }
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  mx: { xs: "0px", md: "4px" },
                  mt: { xs: "16px", md: "0px" },
                  zIndex: 4,
                  position: "relative",
                }}
              >
                <Typography variant="body2">Type De Demande</Typography>

                <SoftSelect  options={filteredOptions}
                  size="medium"
                  placeholder="Choissir Le Type De Demande"
                  onChange={(event) => {
                    if(event.value === "Default"){
                      setTypeEvent("")
                    }else{
                      setTypeEvent(event.value);
                    }
                  }} />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  mx: { xs: "0px", md: "4px" },
                  mt: { xs: "16px", md: "0px" },
                  zIndex: 3,
                  position: "relative",
                }}
              >
                <Typography variant="body2">Filière</Typography>

                <SoftSelect 
                  options={filieres.map((filiere)=>({
                    value: filiere,
                    label: filiere,
                  }))}
                  size="medium"
                  placeholder="Filière Etudiant"
                  onChange={(event) => {
                    if(event.value === "Default"){
                      setFiliereEtudiant("")
                    }else{
                      setFiliereEtudiant(event.value);
                    }
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  ml: { xs: "0px", md: "4px" },
                  mt: { xs: "16px", md: "0px" },
                  zIndex: 2,
                  position: "relative",
                }}
              >
                <Typography variant="body2">Niveau</Typography>

                <SoftSelect             
                
                  options={niveaux.map((niv) => ({
                    value: niv,
                    label: niv,
                  }))}
                  size="medium"
                  placeholder="Niveau Etudiant"
                  onChange={(event) => {
                    if(event.value === "Default"){
                      setNiveauEtudiant("");
                    }else{
                      setNiveauEtudiant(event.value);
                    }
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <DragDropContext
            sx={{ overflow: "visible", zIndex: 1 }}
            onDragEnd={(result) => {
              onDragEnd(result, status.columns, setColumns);
            }}
          >
            <Grid container my={4}>
              {status.columns.map((column) => (
                <Grid
                  item
                  key={column.id}
                  sx={{
                    backgroundColor: "#EDF0F3",
                    flex: "1 1", // flexGrow, flexShrink
                    maxWidth : {md :"calc(50% - 20px)"},
                    minWidth:"330px",
                    margin: "10px",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <SoftTypography variant="h6">{column.title}</SoftTypography>
                  <Droppable droppableId={column.id}>
                    {(provided) => (
                      <Box ref={provided.innerRef} {...provided.droppableProps}>
                        {column.cards.map((card, index) => (
                          <Draggable key={card.id} draggableId={card.id} index={index}>
                            {(provided) => (
                              <>
                                <Box
                                  onClick={() => {
                                    handleOpenUpdateModal(card.id);
                                  }}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <SoftBox
                                    // key={card.id}
                                    // width={{ xs: "calc(330px - 40px)", md: "calc(382px - 40px)" }}
                                    bgColor="white"
                                    color="text"
                                    borderRadius="10px"
                                    mt="20px"
                                    py="14px"
                                    px="14px"
                                    lineHeight="12px"
                                  >
                                    {card.template}
                                  </SoftBox>
                                </Box>
                              </>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Grid>
              ))}
            </Grid>
          </DragDropContext>
        </>
      )}

      {selectedCardId && (
        <UpdateDemande
          open={openUpdateModal}
          handleCloseModal={handleCloseUpdateModal}
          demandeId={selectedCardId}
          handleDemandes={handleDemandes}
        />
      )}

      {controller.user.info.role === "student" && (
        <Grid container my={4}>
          {status.columns.map((column) => (
            <Grid
              item
              key={column.id}
              sx={{
                backgroundColor: "#EDF0F3",
                flex: "1 1", // flexGrow, flexShrink
                maxWidth : {md :"calc(50% - 20px)"},
                minWidth:"330px",
                margin: "10px",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <SoftTypography variant="h6">{column.title}</SoftTypography>
              {column.cards.map((card, index) => (
                <Box
                  key={card.id}
                  onClick={() => {
                    column.title === "A faire"
                      ? handleOpenUpdateModalByStudent(card.id)
                      : handleOpenViewModalByStudent(card.id)
                  }}
                  sx={{ cursor: "pointer" }}
                  index={index}
                >
                  <SoftBox
                    // width={{ xs: "calc(330px - 40px)", md: "calc(382px - 40px)" }}
                    bgColor="white"
                    color="text"
                    borderRadius="10px"
                    mt="20px"
                    py="14px"
                    px="14px"
                    lineHeight="12px"
                  >
                    {card.template}
                  </SoftBox>
                </Box>
              ))}
            </Grid>
          ))}
        </Grid>
      )}

      {selectedUpdateDemandeByStudent && (
        <UpdateDemandeByStudent
          open={openUpdateModalByStudent}
          handleCloseModal={handleCloseUpdateModalByStudent}
          demandeId={selectedUpdateDemandeByStudent}
          handleDemandes={handleDemandes}
        />
      )}

{selectedViewDemandeByStudent && (
        <ViewDemande
          open={openViewModalByStudent}
          handleCloseModal={handleCloseViewModalByStudent}
          demandeId={selectedViewDemandeByStudent}
        />
      )}
    </DashboardLayout>
  );
}

export default KanbanDemande;
