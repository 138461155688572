// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function ActionCell({ id, showVisiIcon, onEdit, onDelete }) {
  return (
    <SoftBox display="flex" alignItems="center">
      { showVisiIcon &&
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Preview product" placement="top">
            <Icon>visibility</Icon>
          </Tooltip>
        </SoftTypography>
      }
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Modifier" placement="top">
            <Icon onClick={onEdit}>edit</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Supprimer" placement="left">
          <Icon onClick={onDelete}>delete</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

ActionCell.defaultProps = {
  id: 0,
  showVisiIcon: true,
  onEdit: () => {},
  onDelete: () => {}
};

ActionCell.propTypes = {
  id: PropTypes.number,
  showVisiIcon: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default ActionCell;
