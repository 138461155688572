import {
  Autocomplete,
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import api from "utils/axios";
import Swal from "sweetalert2";

export default function UpdateSeanceDialog({
  handleFetch,
  handleClose,
  seance,
  optionsClasses,
  optionsSalle,
  optionsMatiere,
}) {
  const [loading, setLoading] = useState(false);
  const [groupe, setGroupe] = useState(seance?.groupe);
  const [jour, setJour] = useState(seance?.day);
  const [week, setWeek] = useState(seance?.week);
  const [parQuinzaine, setParQuinzaine] = useState(seance?.parQuinzaine);
  const [typeSalle, setTypeSalle] = useState(seance?.salleType);
  const [duree, setDuree] = useState(seance?.duree);
  const [subjectType, setSubjectType] = useState(seance?.subjectType);
  const [salle, setSalle] = useState(seance?.salle?._id);
  const [salleLink, setSalleLink] = useState(seance?.salleLink);
  const [matiere, setMatiere] = useState(seance?.subject?._id);
  const [classes, setClasses] = useState(seance?.classes);
  const [debutSeance, setDebutSeance] = useState(seance?.startTime);
  const [finSeance, setFinSeance] = useState(seance?.endTime);
  const calculateEndTime = () => {
    const debutArray = debutSeance.split(":"); // Splitting the start time
    const debutHours = parseInt(debutArray[0]);
    const debutMinutes = parseInt(debutArray[1]);
    const dureeMinutes = parseInt(duree);
    let finHours = debutHours + Math.floor((debutMinutes + dureeMinutes) / 60);
    let finMinutes = (debutMinutes + dureeMinutes) % 60;
    // Formatting the end time with leading zeros if necessary
    finHours = ("0" + finHours).slice(-2);
    finMinutes = ("0" + finMinutes).slice(-2);
    const formattedEndTime = `${finHours}:${finMinutes}`;
    setFinSeance(formattedEndTime); // Update state with the calculated end time
  };

  useEffect(() => {
    calculateEndTime();
  }, [debutSeance, duree]);
  const handleUpdateSeance = async () => {
    try {
      setLoading(true);
      const seanceInfo = {
        duree: duree,
        day: jour,
        startTime: debutSeance,
        endTime: finSeance,
        week: week,
        parQuinzaine: parQuinzaine,
        subject: matiere,
        groupe: groupe,
        subjectType: matiere,
        salleType: typeSalle,
        classes: classes,
        ...(typeSalle === "Presentiel"
          ? { salle: salle, salleLink: "" }
          : { salleLink: salleLink }),
      };
      const response = await api.patch(`/update-session/${seance?._id}`, seanceInfo);
      if (response.status === 200) {
        Swal.fire("Succès !", "Mise à jour séance succès", "success").then(() => {
          setLoading(false);
          handleFetch();
          handleClose();
        });
      }
    } catch (error) {
      setLoading(true);
      if (error.response && error.response.data && error.response.data.message) {
        Swal.fire("Error", error.response.data.message, "error");
      } else {
        Swal.fire(
          "Error",
          "Une erreur s'est produite. Veuillez réessayer ultérieurement.",
          "error"
        );
      }
    }
  };
  return (
    <>
      <DialogTitle textAlign={"center"}>Update séance</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <SoftBox mt={1.625}>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={6}>
              <SoftTypography
                sx={{ mb: 1 }}
                component="label"
                variant="caption"
                fontWeight="medium"
              >
                Durée
              </SoftTypography>
              <SoftInput
                name="Durée"
                margin="normal"
                id="outlined-basic"
                label="Durée"
                type="number"
                defaultValue={duree}
                inputProps={{
                  min: "30",
                  max: "540",
                  step: "30",
                }}
                variant="standard"
                onChange={(e) => setDuree(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftTypography
                sx={{ mb: 1 }}
                component="label"
                variant="caption"
                fontWeight="medium"
              >
                Jour
              </SoftTypography>
              <SoftInput
                name="Jour"
                margin="normal"
                id="outlined-basic"
                label="Jour"
                type="Text"
                defaultValue={jour}
                disabled
                inputProps={{
                  min: "30",
                  max: "540",
                  step: "30",
                }}
                variant="standard"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SoftTypography
                sx={{ mb: 1 }}
                component="label"
                variant="caption"
                fontWeight="medium"
              >
                Début de la séance
              </SoftTypography>
              <SoftInput
                id="time-emploi"
                type="time"
                defaultValue={debutSeance}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 900,
                }}
                onChange={(e) => setDebutSeance(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftTypography
                sx={{ mb: 1 }}
                component="label"
                variant="caption"
                fontWeight="medium"
              >
                Fin de la séance
              </SoftTypography>
              <SoftInput
                id="time-emploi"
                type="time"
                disabled
                value={finSeance}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 900,
                }}
                onChange={(e) => setFinSeance(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={6}></Grid>
          </Grid>
          <Box
            sx={{ width: "100%" }}
            style={{ backgroundColor: "white", borderRadius: "16px", padding: "15px" }}
          >
            <Box>
              <SoftBox>
                <>
                  {" "}
                  <Grid
                    container
                    mt={0.5}
                    mb={1}
                    style={{
                      border: "2px dashed #000",
                      borderRadius: "5px",
                      background: "#f0f0f0",
                      padding: "10px",
                    }}
                  >
                    <Grid container alignItems="center" item xs={12} sm={3}>
                      <SoftTypography
                        mr={1}
                        variant="caption"
                        fontWeight="medium"
                        textTransform="capitalize"
                        onClick={() => setParQuinzaine(!parQuinzaine)}
                      >
                        Par quinzaine :
                      </SoftTypography>
                      <Checkbox
                        defaultChecked={parQuinzaine}
                        onChange={() => setParQuinzaine(!parQuinzaine)}
                      />
                    </Grid>
                    <Grid container alignItems="center" item xs={12} sm={6}>
                      <SoftTypography
                        variant="caption"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        Par semaine :
                      </SoftTypography>
                      <Grid item ml={2}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name="position"
                            defaultValue={week}
                            onChange={(e) => setWeek(e.target.value)}
                          >
                            <FormControlLabel value="toute" control={<Radio />} label="Toute" />
                            <FormControlLabel value="sem A" control={<Radio />} label="Semaine A" />
                            <FormControlLabel value="sem B" control={<Radio />} label="Semaine B" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={3} alignItems="center">
                      <SoftTypography
                        variant="caption"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        Par groupe :
                      </SoftTypography>
                      <Grid item ml={2}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name="position"
                            defaultValue={groupe}
                            onChange={(e) => setGroupe(e.target.value)}
                          >
                            <FormControlLabel value="G1" control={<Radio />} label="G1" />
                            <FormControlLabel value="G2" control={<Radio />} label="G2" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack spacing={3}>
                      <SoftBox>
                        <SoftTypography
                          variant="caption"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          Choix des matières :
                        </SoftTypography>
                        <Autocomplete
                          id={`size-small-standard`}
                          options={optionsMatiere}
                          aria-required={true}
                          required={true}
                          clearIcon={null}
                          getOptionLabel={(option) => option.label}
                          value={
                            optionsMatiere.length > 0 &&
                            optionsMatiere?.find(
                              (option) => option.value === seance?.subjectType?._id
                            )
                          }
                          onChange={(event, newValue) => {
                            setMatiere(newValue.value);
                          }}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Radio checked={selected} onChange={() => {}} />
                              {option.label}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              placeholder="Sélectionner le nom de matière"
                              sx={{ mb: 2 }}
                              {...params}
                              required={true}
                              variant="standard"
                            />
                          )}
                        />
                      </SoftBox>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SoftTypography
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      La salle :
                    </SoftTypography>
                    <Grid ml={1} mb={2}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-form-control-label-placement"
                          name="position"
                          onChange={(e) => {
                            setTypeSalle(e.target.value);
                            if (e.target.value === "Presentiel") {
                              setSalleLink("");
                            } else {
                              setSalle("");
                            }
                          }}
                          defaultValue={typeSalle}
                        >
                          <FormControlLabel
                            value="Presentiel"
                            control={<Radio />}
                            label="Présentiel"
                          />
                          <FormControlLabel value="En ligne" control={<Radio />} label="En ligne" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} mb={2}>
                    <Grid item xs={12} sm={12}>
                      {typeSalle === "En ligne" ? (
                        <SoftInput
                          margin="normal"
                          id="outlined-basic"
                          label="Jour"
                          type="Text"
                          placeholder="Taper votre lien"
                          inputProps={{
                            min: "30",
                            max: "540",
                            step: "30",
                          }}
                          defaultValue={salleLink}
                          onChange={(e) => setSalleLink(e.target.value)}
                          variant="standard"
                        />
                      ) : (
                        <Grid item xs={12} sm={12}>
                          <Stack spacing={3}>
                            <SoftBox>
                              <Autocomplete
                                id={`size-small-standard`}
                                options={optionsSalle}
                                aria-required={true}
                                required={true}
                                clearIcon={null}
                                getOptionLabel={(option) => option.label}
                                defaultValue={optionsSalle?.find(
                                  (option) => option.value === seance?.salle?._id
                                )}
                                onChange={(event, newValue) => {
                                  setSalle(newValue.value);
                                }}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Radio checked={selected} onChange={() => {}} />
                                    {option.label}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    placeholder="Sélectionner la salle"
                                    sx={{ mb: 2 }}
                                    {...params}
                                    required={true}
                                    variant="standard"
                                  />
                                )}
                              />
                            </SoftBox>
                          </Stack>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack spacing={3}>
                      <SoftBox>
                        <SoftTypography
                          variant="caption"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          Choix des classes :
                        </SoftTypography>
                        <Autocomplete
                          id={`size-small-standard`}
                          options={optionsClasses}
                          aria-required={true}
                          multiple={true}
                          required={true}
                          clearIcon={null}
                          getOptionLabel={(option) => option.label}
                          defaultValue={optionsClasses?.find(
                            (option) => option.value === seance?.instructor?._id
                          )}
                          onChange={(event, newValue) => {
                            setClasses(newValue.value);
                          }}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox checked={selected} onChange={() => {}} />
                              {option.label}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              placeholder="Sélectionner le nom de l'enseignant"
                              sx={{ mb: 2 }}
                              {...params}
                              required={true}
                              variant="standard"
                            />
                          )}
                        />
                      </SoftBox>
                    </Stack>
                  </Grid>
                </>
              </SoftBox>
            </Box>
          </Box>
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <SoftButton onClick={handleClose} sx={{ mt: 2, ml: 2 }} variant="outlined" color="dark">
          Annuler
        </SoftButton>
        <SoftButton
          onClick={handleUpdateSeance}
          sx={{ mt: 2 }}
          variant="gradient"
          color="customized"
        >
          Enregistrer
        </SoftButton>
      </DialogActions>
    </>
  );
}
UpdateSeanceDialog.propTypes = {
  handleFetch: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  handleClose: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  seance: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  optionsClasses: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  optionsMatiere: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  optionsSalle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
