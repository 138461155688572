import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import React, { useEffect, useState } from "react";
import SoftTypography from "components/SoftTypography";
import { Card, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import api from "utils/axios";
import FichePresence from "./components/view-class-components/presence/FichePresence";
import CoursInfo from "./components/view-class-components/CoursInfo";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import TaskIcon from "@mui/icons-material/Task";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import LockPersonIcon from '@mui/icons-material/LockPerson';
import StudentsList from "./components/view-class-components/StudentsList";
import Emploi from "./components/view-class-components/Emploi";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Confidentialite from "./components/view-class-components/Confidentialite";
import { useSoftUIController } from "context";

export default function ClassView() {
  const { id } = useParams();
  const [classe, setClasse] = useState({});
  const [selectedItem, setSelectedItem] = useState(0);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [controller] = useSoftUIController();
  const user = controller?.user?.info
  const hasEditDeletePermission =
  user?.role === 'company-admin' ||
  user?.role === 'admin' ||
  user?.role === 'editor';
  const handleItemClick = (index) => {
    setSelectedItem(index);
  };
  const handleFetchClasseById = async (idToFetch) => {
    try {
      if (idToFetch) {
        let endpoint;
        if (facility?.type === "centreDeFormation") {
          endpoint = `training-company/get-classe/${idToFetch}`;
        } else {
          endpoint = `/faculty/get-classe/${idToFetch}`;
        }
        const response = await api.get(endpoint);
        const fetchClasse = response?.data;
        setClasse(fetchClasse);
      }
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    if (id !== undefined) {
      handleFetchClasseById(id);
    }
  }, [id]);
  const sidenavItems = (facility?.type === "centreDeFormation"&&hasEditDeletePermission) ? [
    { icon: <Diversity3Icon />, label: "Étudiants", href: "/Étudiants" },
    { icon: <TaskIcon />, label: "Fiche de présence", href: "/fiches-presence" },
    { icon: <DocumentScannerIcon />, label: "Cours", href: "/cours" },
    { icon: <LockPersonIcon />, label: "Confidentialité", href: "/confidentialite" },
    { icon: <AssignmentIcon />, label: "Emploi du temps", href: "/emploi" }
  ] : [
    { icon: <Diversity3Icon />, label: "Étudiants", href: "/Étudiants" },
    { icon: <TaskIcon />, label: "Fiche de présence", href: "/fiches-presence" },
    { icon: <DocumentScannerIcon />, label: "Cours", href: "/cours" },
    { icon: <AssignmentIcon />, label: "Emploi du temps", href: "/emploi" }
  ]
  const renderSidenavItems = sidenavItems?.map(({ icon, label, href }, index) => {
    const itemKey = `item-${index}`;
    const isSelected = index === selectedItem;
    return (
      <SoftBox
        key={itemKey}
        component="li"
        onClick={() => handleItemClick(index)}
        pt={index === 0 ? 0 : 1}
      >
        <SoftTypography
          component="a"
          href={`#${href}`}
          variant="button"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),
            "&:hover": {
              backgroundColor: light.main,
            },
            backgroundColor: index === selectedItem ? "#e0e0e0" : "transparent",
          })}
        >
          <SoftBox mr={1.5} lineHeight={1}>
            {icon}
          </SoftBox>
          {label}
        </SoftTypography>
      </SoftBox>
    );
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={9}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            {/* START SIDE NAV*/}
            <Card
              sx={{
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                position: "sticky",
                top: "1%",
              }}
            >
              <SoftBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={2}
                m={0}
                sx={{ listStyle: "none" }}
              >
                {renderSidenavItems}
              </SoftBox>
            </Card>
            {/* END SIDE NAV*/}
          </Grid>
          <Grid item xs={12} lg={9}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {(facility?.type === "centreDeFormation"&& hasEditDeletePermission) ? (
                    selectedItem === 0 ? (
                    <StudentsList classe={classe} />
                  ) : selectedItem === 1 ? (
                    <FichePresence classe={classe} />
                  ) : selectedItem === 2 ? (
                    <CoursInfo classe={classe} />
                  ) : selectedItem === 3 ? (
                    <Confidentialite classe={classe} handleFetchClasseById={handleFetchClasseById}/>
                  ) : selectedItem === 4 ? (
                    <Emploi classe={classe} />
                  ) : (
                    ""
                  )) : ((selectedItem === 0 ? (
                    <StudentsList classe={classe} />
                  ) : selectedItem === 1 ? (
                    <FichePresence classe={classe} />
                  ) : selectedItem === 2 ? (
                    <CoursInfo classe={classe} />
                  ) : selectedItem === 3 ? (
                    <Emploi classe={classe} />
                  ) : (
                    ""
                  )))}
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}
