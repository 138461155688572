import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import {
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import SoftBox from "components/SoftBox";
import api from "utils/axios";
import Icon from "@mui/material/Icon";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { downloadExcelFile } from "utils/excelUtils";
import SubjectTranningFile from "assets/filesExcel/training-company/subject-tranning.xlsx"; 
import FileDownloadIcon from '@mui/icons-material/FileDownload';
export default function AddSubject({ handleFetch }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [fileToUpload, setFileToUpload] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFileToUpload(null);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleFileSubmit();
    }
  };

  const handleFileUpload = (incomingFiles) => {
    // Only handle the first file
    if (incomingFiles.length > 0) {
      setFileToUpload(incomingFiles[0]);
    }
  };

  const removeFile = () => {
    setFileToUpload(null);
  };

  const handleFileSubmit = async () => {
    if (!fileToUpload) {
      Swal.fire("Erreur!", "Veuillez sélectionner un fichier.", "error");
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", fileToUpload.file);

      await api.post(
        `training-company/upload-subjects?scholarityConfigId=${facility.scholarityConfigId}`,
        formData
      );

      Swal.fire("Success!", "Le fichier a été téléchargé avec succès", "success").then(() => {
        handleFetch();
        handleClose();
      });
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleExportExcel = () => {
    downloadExcelFile(SubjectTranningFile, 'subject-tranning.xlsx');
  };
  return (
    <div>
      <SoftButton
        id="add"
        onClick={handleClickOpen}
        variant="outlined"
        color="purple"
        size="small"
        mr={2}
      >
        <Icon>description</Icon>
        &nbsp;import Excel
      </SoftButton>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} onKeyDown={handleKeyPress}>
        <DialogTitle>Ajouter le fichier Excel </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
        <DialogContentText mb={1}>
           Télécharger le fichier Excel matière
          </DialogContentText>
        <SoftButton variant="outlined" color="purple" size="small" mr={2}  onClick={handleExportExcel}>
                  <FileDownloadIcon />
                 {/*  <Icon>description</Icon> */}
                  &nbsp;export Excel
                  
                </SoftButton>
          <DialogContentText my={1}>
            Importer des matières depuis un fichier Excel
          </DialogContentText>
          <Grid>
            <Dropzone
              onChange={handleFileUpload}
              value={fileToUpload ? [fileToUpload] : []}
              accept=".xlsx, .xls"
              localization="FR-fr"
              headerConfig={{ deleteFiles: false }}
              background="radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);"
            >
              {fileToUpload && <FileMosaic key={fileToUpload.id} {...fileToUpload} localization="FR-fr" onDelete={removeFile} info />}
            </Dropzone>
          </Grid>
        </DialogContent>

        <DialogActions>
          <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
            <SoftButton
              onClick={handleClose}
              sx={{ mt: 1 }}
              variant="outlined"
              color="dark"
              size="small"
            >
              Annuler
            </SoftButton>

            {loading ? (
              <CircularProgress />
            ) : (
              <SoftButton
                onClick={handleFileSubmit}
                sx={{ mt: 1 }}
                variant="gradient"
                color="purple"
                size="small"
              >
                Ajouter
              </SoftButton>
            )}
          </SoftBox>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AddSubject.propTypes = {
  handleFetch: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
