import { useEffect, useState } from "react";
// formik components
import { Formik, Form } from "formik";
import Header from "layouts/pages/profile/components/Header";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "utils/axios";
import Swal from "sweetalert2";
import SubjectInfo from "./components/SubjectInfo";
import TypesInfo from "./components/TypesInfo";
import SalleInfo from "./components/SalleInfo";
import InstructorInfo from "./components/InstructorInfo";
import CategoryInfo from "./components/CategoryInfo";
import form from "./schema/form";
import initialValues from "./schema/initialValues";
import validations from "./schema/validations";

function getSteps() {
  return ["Matière", "Type", "Salles", "Categorie", "Enseignants"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <SubjectInfo formData={formData} />;
    case 1:
      return <TypesInfo formData={formData} />;
    case 2:
      return <SalleInfo formData={formData} />;
    case 3:
      return <CategoryInfo formData={formData} />;
    case 4:
      return <InstructorInfo formData={formData} />;
    default:
      return null;
  }
}
function NewSubject() {
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const steps = getSteps();
  const navigate = useNavigate();
  const { formId, formField } = form;
  const facility = JSON.parse(localStorage.getItem("facility"));
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep(activeStep - 1);
  };
  const handleValidate = async (values, actions) => {
    try {
      setIsLoading(true);
      const { data } = await api.post("/validation", {
        type: "SUBJECT_CODE_VALIDATION",
        payload: {
          code: values.code,
          scholarityConfigId: facility.scholarityConfigId,
        },
      });
      setIsLoading(false);
      setActiveStep(activeStep + 1);
    } catch (error) {
      Swal.fire(
        "Error!",
        error.response.data.name !== true &&
          `Le code existe déjà : ${values.code}. Merci de saisir un autre code !`,
        "error"
      ).then(() => {
        setIsLoading(false);
      });
      setIsLoading(false);
    }
  };

  const submitForm = async (values, actions) => {
    try {
      setIsLoading(true);
      const requestData = {
        code: values.code,
        name: values.name,
        coefficient: values.coefficient,
        credit: values.credit,
        module: values.module,
        categorie: values.categorie,
        types: [
          values.checkedCours
            ? {
                type: "Cours",
                bloc: values.bloccours,
                salles: values.sallecours,
                volumeHoraire: values.vhcours || 0,
                duration: values.durationtd || 0,
                profs: values.instructorCours,
              }
            : null,
          values.checkedTd
            ? {
                type: "TD",
                bloc: values.bloctd,
                salles: values.salletd,
                volumeHoraire: values.vhtd || 0,
                duration: values.durationtd || 0,
                profs: values.instructorTd,
              }
            : null,
          values.checkedTp
            ? {
                type: "TP",
                bloc: values.bloctp,
                salles: values.salletp,
                volumeHoraire: values.vhtp || 0,
                duration: values.durationtp || 0,
                profs: values.instructorTp,
              }
            : null,
          values.checkedMp
            ? {
                type: "Mini Projet",
                bloc: values.blocmp,
                salles: values.sallemp,
                volumeHoraire: values.vhmp || 0,
                duration: values.durationmp || 0,
                profs: values.instructorMp,
              }
            : null,
        ].filter(Boolean),
        configurations: [
          values.checkedPcExamen ? { type: "Examen", pourcentage: values.pcexamen } : null,
          values.checkedPcDs1 ? { type: "DS1", pourcentage: values.pcds1 } : null,
          values.checkedPcDs2 ? { type: "DS2", pourcentage: values.pcds2 } : null,
          values.checkedPcCc ? { type: "CC", pourcentage: values.pccc } : null,
          values.checkedPcTd ? { type: "TD", pourcentage: values.pctd } : null,
          values.checkedPcTp ? { type: "TP", pourcentage: values.pctp } : null,
          values.checkedPcMp ? { type: "MP", pourcentage: values.pcmp } : null,
        ].filter(Boolean),
      };
      await api.post(
        `/faculty/create-subject?scholarityConfigId=${facility.scholarityConfigId}`,
        requestData
      );
      Swal.fire("Succès", "La matière a été ajouté avec succès", "success").then(() => {
        navigate("/plan/subject");
      });
    } catch (error) {
      setError(error.response.data);
      Swal.fire("Erreur!", error.response.data, "error");
    } finally {
      setIsLoading(false);
    }
    setActiveStep(4);
  };

  const handleSubmit = (values, actions) => {
    if (activeStep === 0) {
      handleValidate(values, actions);
    } else if (isLastStep) {
      switch (true) {
        case values.checkedCours && values.instructorCours.length === 0:
          actions.setFieldError("instructorCours", "L'enseignant est obligatoire pour les cours.");
          break;
        case values.checkedTd && values.instructorTd.length === 0:
          actions.setFieldError("instructorTd", "L'enseignant est obligatoire pour les TD.");
          break;
        case values.checkedTp && values.instructorTp.length === 0:
          actions.setFieldError("instructorTp", "L'enseignant est obligatoire pour les TP.");
          break;
        case values.checkedMp && values.instructorMp.length === 0:
          actions.setFieldError(
            "instructorMp",
            "L'enseignant est obligatoire pour les Mini-Projets."
          );
          break;
        default:
          submitForm(values, actions);
          break;
      }
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  if (error) {
    Swal.fire(
      "Error!",
      error === "duplicate inputs" ? "Le code est déjà utilisé." : error,
      "error"
    ).then(() => {
      setError(null);
    });
  }
  useEffect(() => {
    document.title = "ajouter matière";
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title="Ajouter une matière" searchVisible={false} ExportVisible={false} />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <SoftBox />
                          ) : (
                            <SoftButton
                              color="dark"
                              variant="outlined"
                              size="small"
                              onClick={handleBack}
                            >
                              Retour
                            </SoftButton>
                          )}
                          {activeStep === 0 && (
                            <SoftBox width="100%" display="flex" justifyContent="space-between">
                              <SoftButton
                                size="small"
                                onClick={() => navigate("/plan/subject")}
                                variant="outlined"
                                color="dark"
                              >
                                Annuler
                              </SoftButton>
                            </SoftBox>
                          )}
                          {isLoading ? (
                            <CircularProgress />
                          ) : (
                            <SoftButton
                              size="small"
                              type="submit"
                              variant="gradient"
                              color="customized"
                              id="send"
                            >
                              {isLastStep ? "Ajouter" : "Suivant"}
                            </SoftButton>
                          )}
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default NewSubject;
