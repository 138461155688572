import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'layouts/DashboardNavbar'
import Header from 'layouts/pages/profile/components/Header'
import React, { useEffect, useState } from 'react'
import SoftBox from 'components/SoftBox';
import { Card, CircularProgress, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import SoftButton from 'components/SoftButton';
import { useNavigate } from 'react-router-dom';
import form from './schema/form';
import validations from './schema/validations';
import initialValues from './schema/initialValues';
import ResponsableB2CInfo from './components/ResponsableB2CInfo';
import api from 'utils/axios';
import Swal from 'sweetalert2';
import { useSoftUIController } from 'context';

function getStepContent(_, formData) {
  return <ResponsableB2CInfo formData={formData} />
}

export default function AddClientB2C() {
  // ---------------------this for check the size limit of the company
  const [controller, _] = useSoftUIController();
  const [errorLimitSize, setErrorLimitSize] = useState(null);
  const [limitSize, setLimitSize] = useState();
  const [limitUsers, setLimitUsers] = useState();
  if (controller?.user?.info?.role !== ("company-admin" || "admin" || "editor")) return;
  // ---------------------this for check the size limit of the company

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const navigate = useNavigate()
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];

  //-----------------add-responsable
  const submitForm = async (values, _) => {
    try {
      //! to be deleted
      // if (values.imageUrl === "" && errorLimitSize) setErrorLimitSize(null);
      // check if the image is selected and the company dosent have a limit of size
      // if (errorLimitSize && values.imageUrl !== "") return Swal.fire("Erreur!", errorLimitSize, "error");
      const form = new FormData();
      const responsable = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        address: values.address,
        zipCode: values.zipCode,
        siteWeb: values.siteWeb,
        passeport: values.passeport,
        cin: values.cin,
        rib: values.rib,
        bank: values.bank,
        comment: values.description,
        phoneNumber: values.phone,
        secondaryPhoneNumber: values.secondaryPhoneNumber || "",
        gender: values.gender,
        files: values.files,
        occupation: values.occupation,
        imageUrl: values.imageUrl,
        birthday: values.birthday,
        cnssNumber: values.cnssNumber,
        country: values.country,
        city: values.city,
        responsableType: "B2C",
        role: "responsable",
      };
      form.append("firstName", responsable.firstName);
      form.append("lastName", responsable.lastName);
      form.append("email", responsable.email);
      form.append("cin", responsable.cin);
      form.append("siteWeb", responsable.siteWeb);
      form.append("occupation", responsable.occupation);
      form.append("address", responsable.address);
      form.append("zipCode", responsable.zipCode);
      form.append("bank", responsable.bank);
      form.append("rib", responsable.rib);
      form.append("birthday", responsable.birthday);
      form.append("cnssNumber", responsable.cnssNumber);
      form.append("phoneNumber", responsable.phoneNumber);
      form.append("secondaryPhoneNumber", responsable.secondaryPhoneNumber);
      form.append("gender", responsable.gender);
      form.append("country", responsable.country);
      form.append("city", responsable.city);
      form.append("passeport", responsable.passeport);
      form.append("comment", responsable.comment);
      form.append("responsableType", responsable.responsableType);
      form.append("role", responsable.role);
      form.append("imageUrl", responsable.imageUrl);
      responsable.files && responsable.files.length > 0 &&
        responsable.files.forEach((item) => {
          form.append("files", item.value);
        })

      setIsLoading(true);
      const data = await api.post(`/add-One-user?facilityId=${facility._id}`, form);
      if (data.status === 201) {
        Swal.fire("success !", "Le responsable a été ajouté avec succès", "success").then(() => {
          setIsLoading(false);
          navigate("/administration/responsables");
        })
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        Swal.fire("Erreur!", error.response.data.message, "error")
      } else {
        Swal.fire("Erreur!", "Une erreur s'est produite. Veuillez réessayer ultérieurement.", "error")
      }
    }
    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  /**
   * This function check if the enterprise has reached the limit size of data
   * @author med-aziz-guennichi
   * @returns {Promise<void>}
   */
  const handleCheckSizeLimit = async () => {
    try {
      const { data } = await api.get(
        `/get-files-size-enterprise/${JSON.parse(localStorage.getItem("licence")).enterpriseId}`
      );
      // if the Megabytes is equal to the limit of the enterprise or if it's greater than the limit
      if (data.Megabytes === limitSize || data.Megabytes > limitSize) {
        setErrorLimitSize("You dont have access to push another file");
      }
    } catch (error) {
      throw error;
    }
  };

  // Check if the limit size is set and if it's the case get the limit size
  // from the localStorage and set it to the state
  // We also need to trigger the check if the limit size is reached or not
  useEffect(() => {
    if (localStorage.getItem("licence")) {
      const licence = JSON.parse(localStorage.getItem("licence"));
      // If there is no licence, we return and not proceed
      if (!licence) return;
      // Set the limit size to the state
      setLimitSize(licence.remainingDataSizeMb);
      // Set the limit users to the state
      setLimitUsers(licence.remainingUser);
      // Check if the limit size is reached or not, if it's the case
      // we display an error message to the user
      handleCheckSizeLimit();
    }
  }, [limitSize, limitUsers]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header title={"Ajout un responsable B2C"} />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, _ }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">

                          <SoftButton size="small" onClick={() => navigate('/administration/responsables')} variant="outlined" color="dark" >
                            Annuler
                          </SoftButton>
                          {
                            isLoading ? <CircularProgress /> : (
                              <SoftButton
                                type="submit"
                                variant="gradient"
                                color="customized"
                                id="send"
                                size="small"
                                disabled={limitUsers === 0}
                              >
                                Ajouter
                              </SoftButton>
                            )
                          }
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  )
}
