import { useEffect, useState } from "react";
import {
  Box,
  Card,
  IconButton,
  Tooltip,
  CircularProgress,
  Grid,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { grey } from "@mui/material/colors";

import {
  Delete,
  Edit,

} from "@mui/icons-material";
import api from "utils/axios";
import AjouterProjet from "./AjouterProjet";
import UpdateProject from "./UpdateProjet";
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import { useSoftUIController } from "context";

function ListeProjet() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1); // Track current page number
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedProject, setSelecteProject] = useState(null);

  const [controller, _] = useSoftUIController();

  const facility = JSON.parse(localStorage.getItem("facility"));

  const handleprojects = async () => {
    setLoading(true);
    try {

      let endpoint;
      if (facility.type === "centreDeFormation") {
        endpoint = `training-company/get-projects-centre-formation/${facility.scholarityConfigId}`;
      }else if(facility.type === "faculty"){
        endpoint = `faculty/get-projects-faculty/${facility.scholarityConfigId}`;
      }
  
      const response = await api.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleprojects();
  }, []);

  const GOToKanban = async (Id) => {
    const result = await Swal.fire({
      title: "Êtes-vous sûr(e)",
      text: "Voulez-vous visiter Kanban Projet?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    });

    if (result.isConfirmed) {
      navigate(`/suiviTache/kanbanTaches/${Id}`);
    }
  };


  const deleteProjet = async (id) => {
    try {
      let endpoint;
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/delete-project-centre-formation/${id}`;
      } else {
        endpoint = `faculty/delete-project-faculty/${id}`;
      }
  
      await api.delete(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      await handleprojects();
      Swal.fire({
        title: "Succès",
        text: "Suppression du projet réussie",
        icon: "success",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "button button-success",
        },
        buttonsStyling: false,
      });
    } catch (error) {
      Swal.fire({
        title: "Erreur",
        text: "Erreur lors de la suppression du projet",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "button button-error",
        },
        buttonsStyling: false,
      });
    }
  };

  const confirmdeleteProjet = (id) => {
    Swal.fire({
      title: "Êtes-vous sûr(e)",
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer !",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProjet(id);
      }
    });
  };
  const getLast6Characters = (code) => code?.slice(-6);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card sx={{ my: 2 }}>
        {loading ? (
          <Box sx={{ mt: 13, textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent={{ xs: "flex-start", sm: "space-between" }}
              alignItems={{ xs: "flex-start", sm: "center" }}
              p={3}
            >
              <SoftTypography variant="h5" fontWeight="medium">
                Liste des Projects
              </SoftTypography>
              <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
                <SoftButton variant="outlined" color="purple" size="small">
                  export
                </SoftButton>
                <SoftButton
                  variant="gradient"
                  color="purple"
                  size="small"
                  onClick={() => setOpen(true)}
                >
                  Ajouter Projet
                </SoftButton>
                {open && (
                  <AjouterProjet
                    open={open}
                    handleCloseModal={() => setOpen(false)}
                    handleprojects={handleprojects}
                  />
                )}
              </Stack>
            </Box>
            <DataTable
              table={{
                columns: [
                  { Header: "Titre De projet", accessor: "nom", width: "25%" },
                  { Header: "Description", accessor: "description", width: "25%" },
                  {
                    Header: "Responsable", accessor: "responsable", width: "20%", align: "center",
                  },
                  { Header: "Nombre Collaborateurs", accessor: "nbCollaborateurs", width: "20%", align: "center" },
                  { Header: "Action", accessor: "action", width: "10%", align: "center" },
                ],
                rows: projects
                  .slice((currentPage - 1) * projects.length, currentPage * projects.length)
                  .map((project) => ({
                    nom: project.nom,
                    description: project.description,
                    responsable: project.responsable,
                    nbCollaborateurs: project.nbCollaborateurs,

                    action: (
                      <Box display="flex" alignItems="center">
                        <Box display="flex" alignItems="center">
                          <Box mx={1}>
                            <Tooltip title="Modifier">
                              <IconButton
                                disabled={project.responsable === controller.user.info.email ? false : true}
                                sx={{ "&:hover": { bgcolor: grey[200] } }}
                                onClick={() => {
                                  setSelecteProject(project._id);
                                  setOpenUpdateModal(true);
                                  
                                }}
                              >
                                <Edit fontSize="small" color={project.responsable === controller.user.info.email ? "purple" : "grey"} />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <Box mx={1}>
                            <Tooltip title="Supprimer">
                              <IconButton
                                disabled={project.responsable === controller.user.info.email ? false : true}
                                sx={{ "&:hover": { bgcolor: grey[200] } }}
                                onClick={() => confirmdeleteProjet(project._id)}
                              >

                                <Delete fontSize="small" color={project.responsable === controller.user.info.email ? "purple" : "grey"} />
                              </IconButton>
                            </Tooltip>

                          </Box>
                        </Box>
                        <Box mx={1}>
                          <Tooltip title="Visiter Kanban Project">
                          <IconButton
                              disabled={(project.responsable === controller.user.info.email || project.collaborateursEmail.includes(controller.user.info.email) || controller.user.info.role == "company-admin") ? false : true}
                              sx={{ "&:hover": { bgcolor: grey[200] } }}
                              onClick={() => GOToKanban(project._id)}
                            >
                            <ViewKanbanIcon 
                            
                            color={(project.responsable === controller.user.info.email || project.collaborateursEmail.includes(controller.user.info.email) || controller.user.info.role == "company-admin") ? "purple" : "grey"} fontSize="small"  />
                              
                            </IconButton>
                          </Tooltip>
                        </Box> 
                        </Box>
                    ),
                  })),
              }}
              entriesPerPage={{ defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }}
              canSearch
            />
          </Box>
        )}
      </Card>
      {selectedProject && (
        <UpdateProject
          open={openUpdateModal}
          handleCloseModal={() => setOpenUpdateModal(false)}
          projectID={selectedProject}
          handleprojects={handleprojects}
        />
        
      )}
    </DashboardLayout>
  );
}


export default ListeProjet;
