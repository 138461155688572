import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import SoftButton from 'components/SoftButton';
import { Autocomplete,CircularProgress, Card, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import api from "utils/axios";
import FormField from "layouts/pages/account/components/FormField";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function Etablisement({  enterprise }) {
  // const [open, setOpen] = useState(false);
  const [switchValues, setSwitchValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [options, setOptions] = useState([
    { value: "cresh", label: "Crèche" },
    { value: "jardinDenfent", label: "Jardin d'enfants" },
    { value: "ecolePrimaire", label: "École primaire" },
    { value: "college", label: "Collège" },
    { value: "lycee", label: "Lycée" },
    { value: "faculty", label: "Université" },
    { value: "centreDeFormation", label: "Centre de formation" },
  ]);
  const [inputs, setInputs] = useState([]);


  if (enterprise) {
    // Create an array to store facility data
    const facilityDataArray = [];

    const handleFetch = async () => {
      for (const facility of enterprise?.facilities) {
        if (facility) {
          const facilityData = await api.get(`/getOneFacility/${facility}`);
          facilityDataArray.push({
            _id: facilityData.data._id,
            facilityName: facilityData.data.name,
            facilityType: facilityData.data.type,
            serialNumber: facilityData.data.serialNumber
          });
        }
      }
      // After fetching all facilities, set the state with the entire array
      setInputs(facilityDataArray);
    };

    useEffect(() => {
      handleFetch();
    }, [enterprise]);
  }

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[index] = {
      ...newInputs[index],
      [name]: value,
    };
    setInputs(newInputs);
  };

  const handleAddInput = () => {
    setInputs([...inputs, { facilityName: "", facilityType: "", serialNumber: "" }]);
  };

  const handleRemoveInput = (indexToRemove) => {
    const newInputs = [...inputs];
    newInputs.splice(indexToRemove, 1); // Remove the input field pair at the specified index
    setInputs(newInputs);
  };

  // Define your facility types
  const facilityTypes = [
    "cresh",
    "jardinDenfent",
    "ecolePrimaire",
    "college",
    "lycée",
    "faculty",
    "centreDeFormation"
  ];

  // Initialize switch values based on facilityData.type
  useEffect(() => {
    // Initialize switch values based on facilityData.type
    const initialSwitchValues = {};
    facilityTypes.forEach(type => {
      initialSwitchValues[type] = enterprise.facilities.type && enterprise.facilities.type.includes(type);
    });
    setSwitchValues(initialSwitchValues);
  }, [enterprise]);


  const navigate = useNavigate();

  const updateWithAlert = (input) => {
    const hasInvalidInputs = inputs.some((input) => input.serialNumber.length < 8);
    if (hasInvalidInputs) {
      setError("La longueur minimale est de 8 caractères.")
    } else {
      setLoading(true);
      const form = new FormData();
      form.append("facilities", JSON.stringify(inputs));
      api.patch(`/update-enterprise/${enterprise._id}`, form)
        .then(() => {
          Swal.fire("Succès", "Mise à jour établissement réussie", "success").then(() => {
            navigate(0)
            setLoading(false);

          })
        }).catch((error) => {
          setLoading(true)
          if (error.response && error.response.data && error.response.data.message) {
            Swal.fire("Error", error.response.data.message, "error").then(() => setLoading(false));
          } else {
            Swal.fire("Erreur", "Une erreur s'est produite. Veuillez réessayer ultérieurement.", "error").then(() => navigate(0));
          }
        })
    }


  };

  const showAlertDeleteAccount = (input) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr(e)",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler"
      })
      .then((result) => {
        if (result.isConfirmed) {
          api.delete(`/deleteFacility/${input._id}`)
            .then(() => {
              Swal.fire("Succès", "L'établissement a été supprimée.", "success").then(() => {
                navigate(0)
              })
            }).catch(() => {
              Swal.fire("Erreur!", "L'établissement n'a pas été supprimé.", "error");
            })
        }
      });
  };

  return (
    <>
      <Card id="basic-info" sx={{ overflow: "visible" }}>

        <SoftBox component="form" pb={2} px={3} >
          {inputs.map((input, index) => (
            <Grid key={index} container justifyContent="space-between"
             alignItems="center" mt={2} spacing={1} >
              <Grid item xs={12} sm={3.5} style={{ position: 'relative' }}>
                <FormField
                  name="facilityName"
                  value={input.facilityName}
                  onChange={(e) => handleInputChange(index, e)}
                  label="Nom*"
                  placeholder="Nom"
                />
              </Grid>
              <Grid item xs={12} sm={3.5} style={{ position: 'relative' }} mb={0.5}>
                <FormField
                  name="serialNumber"
                  value={input.serialNumber}
                  onChange={(e) => handleInputChange(index, e)}
                  label="Matricule Fiscal*"
                  placeholder="Matricule Fiscal"
                  error={input.serialNumber.length < 8}
                />
                {input.serialNumber.length < 8 && (
                  <Typography variant="caption" color="error" 
                  style={{ position: 'absolute', top: '100%',marginTop:"5px" }}
                  >
                    {error}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={3.5} mt={-2}>
                <SoftTypography variant="caption" fontWeight="bold" color="text">Sélectionner un établissement*</SoftTypography>
                <Autocomplete
                  id={`size-small-standard-${index}`}
                  options={options}
                  aria-required={true}
                  getOptionLabel={(option) => option.label}
                  value={
                    options.find((option) => option.value === input.facilityType) || null
                  }
                  onChange={(event, newValue) => {
                    if (newValue !== null && newValue !== undefined) {
                      const newInputs = [...inputs];
                      newInputs[index].facilityType = newValue.value;
                      setInputs(newInputs);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      value={input.facilityType}
                    />
                  )}
                />
              </Grid>
              <Grid item  >
                {
                  input._id ? (
                    <>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"

                        onClick={handleClick}
                        style={{ marginTop: "20px" }}
                      >
                        <MoreVertIcon fontSize="small" style={{}} />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <MenuItem onClick={() => showAlertDeleteAccount(input)}>Delete</MenuItem>
                      </Menu>
                    </>
                  ) : (
                    <>
                      <IconButton style={{ marginTop: "20px" }}><RemoveCircleIcon color="error" fontSize="medium" onClick={() => handleRemoveInput(index)} /></IconButton>
                    </>
                  )
                }
              </Grid>
            </Grid>
          ))}

        </SoftBox>
        <Grid item ml={"auto"} mr={"auto"} xs={12} sm={4}>
          <SoftButton size="small"     sx={{
              
              mr: 3, mt: 2, mb: 2, ml: "auto", left: "auto", top: "auto" ,
              borderWidth: "2px",
              borderStyle: "dashed solid",
              borderColor: "purple ",
            }} text onClick={handleAddInput} >
            +Ajouter plus
          </SoftButton>
        </Grid>
        <Grid item xs={12} mb={2}>
          <SoftBox ml={3} sx={{ mr: 3 }} display={"flex"} justifyContent={"space-between"}>
            <SoftButton onClick={() => navigate('/companies/companies')} sx={{ mr: 2 }} variant="outlined" size="small" color="dark">Annuler</SoftButton>
            {loading ? (
              <CircularProgress />
            ) : (<SoftButton onClick={updateWithAlert} variant="gradient" color="customized" size="small" >Modifier</SoftButton>)}
          </SoftBox>
        </Grid>

      </Card>


    </>
  );
};
Etablisement.propTypes = {
  facilityData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  enterprise: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  getFunction: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}
