import { Box, Card, IconButton, Tooltip, Typography } from "@mui/material";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import { useEffect, useState } from "react";
import api from "utils/axios";
import DataTable from "examples/Tables/DataTable";
import { ArrowBackIos, ArrowForwardIos, Delete, Edit } from "@mui/icons-material";
import Swal from "sweetalert2";
import { grey } from "@mui/material/colors";
import moment from "moment";
import UpdateDemande from "../UpdateDemande";
import AjouterDemande from "../AjouterDemande";
import { useSoftUIController } from "context";

function ListDemandeDocument() {
  const [controller, _] = useSoftUIController();
  const [currentPage, setCurrentPage] = useState(1); // Track current page number
  const [open, setOpen] = useState(false); // Modal state
  const [selectedDemande, setSelectedDemande] = useState(null);
  const [openUpdateModal, setOpenUpdateModal] = useState(false); // Update modal state
  const [demandes, setDemandes] = useState([]); // Demand list

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  const handleOpenUpdateModal = (demande) => {
    setSelectedDemande(demande);
    setOpenUpdateModal(true);
  };
  const handleCloseUpdateModal = () => setOpenUpdateModal(false);
  const facility = JSON.parse(localStorage.getItem("facility"));
  const handleDemandes = () => {
    api.get(`get-demandes-by-userId/${controller.user.info._id}/${facility._id}`).then((res) => {
      setDemandes(res.data.demandesDocsByPersonneConnecter);
    });
  };

  useEffect(() => {
    handleDemandes();
  }, []);

  const deleteDemande = (id) => {
    api.delete(`delete-demande/${id}`).then(() => {
      handleDemandes();
    });
  };

  const handleSupprimer = (id) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Êtes-vous sûr(e)",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler",
      }).then(async (result) => {
      if (result.isConfirmed) {
        deleteDemande(id);
        Swal.fire({
          title: "Succès",
          text: "Suppression de la tâche réussie",
          icon: "success",
          confirmButtonText: "ok",
        });
      }
    });
  };

  const indexOfLastDemand = currentPage * demandes.length;
  const indexOfFirstDemand = indexOfLastDemand - demandes.length;
  const currentDemands = demandes.slice(indexOfFirstDemand, indexOfLastDemand);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card sx={{ my:2 }}>
        <Box display="flex" m="24px" alignItems="center">
          <Typography variant="h5" sx={{ flexGrow: "1" }}>
            Liste des documents demandés
          </Typography>
          <Box>
            <SoftButton
              variant="outlined"
              color="purple"
              size="small"
              sx={{ mr : "8px" }}
            >
              export
            </SoftButton>
            {(controller.user.info.role === "admin" || controller.user.info.role === "editor") && (
              <SoftButton variant="gradient" color="purple" size="small" onClick={handleOpenModal}>
                Ajouter une demande
              </SoftButton>
            )}
          </Box>
        </Box>

        {open && (
          <AjouterDemande open={open} handleCloseModal={handleCloseModal} handleDemandes={handleDemandes} />
        )}
        <DataTable
          table={{
            columns: [
              { Header: "Type", accessor: "Type", align: "center" },
              { Header: "Description", accessor: "Description", align: "center" },
              { Header: "Statut", accessor: "Statut", align: "center" },
              { Header: "Date Création", accessor: "Date_Création", align: "center" },
              { Header: "Évaluation", accessor: "Evaluation", align: "center" },
              { Header: "Réponse", accessor: "Reponse", align: "center" },
              { Header: "Action", accessor: "Action", align: "center" },
            ],
            rows: currentDemands.map((demande) => ({
              Type: demande.type,
              Description:
                demande.description.length > 20
                  ? demande.description.substr(0, 20) + "..."
                  : demande.description,
              Statut: demande.statut || "_",
              Date_Création: moment(demande.createdAt).format("DD/MM/YYYY HH:mm:ss"),
              Evaluation: demande.evaluation || "_",
              Reponse:
                demande.reponse && demande.reponse !== ""
                  ? demande.reponse.length > 20
                    ? demande.reponse.substr(0, 20) + "..."
                    : demande.reponse
                  : "_",
              Action: (
                <Box display="flex" alignItems="center">
                  <Box mx={2}>
                    <Tooltip title="Modfier">
                      <IconButton
                        sx={{ "&:hover": { bgcolor: grey[200] } }}
                        onClick={() => handleOpenUpdateModal(demande)}
                      >
                        <Edit fontSize="small" color="purple" />
                      </IconButton>
                    </Tooltip>

                    {/* UpdateDemande modal */}
                    {selectedDemande && (
                      <UpdateDemande
                        open={openUpdateModal}
                        handleCloseModal={handleCloseUpdateModal}
                        demandeId={selectedDemande._id}
                        handleDemandes={handleDemandes}
                      />
                    )}
                  </Box>

                  <Tooltip title="Supprimer">
                    <IconButton
                      sx={{ "&:hover": { bgcolor: grey[200] } }}
                      onClick={() => handleSupprimer(demande._id)}
                    >
                      <Delete fontSize="small" color="purple" />
                    </IconButton>
                  </Tooltip>
                </Box>
              ),
            })),
          }}
          entriesPerPage={{ defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }}
          canSearch
        />
      </Card>

    </DashboardLayout>
  );
}

export default ListDemandeDocument;
