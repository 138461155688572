import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
// @mui material components
import Card from "@mui/material/Card";


// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { Avatar, Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EnterpriseAction from "./components/EnterpriseActions";
import dataTableData from "./FetchEnterprise";


// const dataTo = await dataTableData();


function EnterpriseTable() {
  const [dataTo, setDataTo] = useState([])
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [afficheFilter, setAfficheFilter] = useState(true);
  const sessionId = localStorage.getItem('sessionId');
  useEffect(() => {

    document.title = "entreprises";
  }, []);
  useEffect(() => {
    setLoading(true)
    dataTableData().then((res) => {
      setDataTo(res)
    }).finally(() => {
      setLoading(false)
    })
  }, [])
  return (
    <DashboardLayout>
      <DashboardNavbar />
    this is the session id : {sessionId}
      <SoftBox my={3}>
        <Card>
        <SoftBox
  display="flex"
  flexDirection={{ xs: 'column', sm: 'row' }} 
  justifyContent={{ xs: 'flex-start', sm: 'space-between' }} 
  alignItems={{ xs: 'flex-start', sm: 'center' }} 
  p={3}
>
  <SoftBox lineHeight={1}>
    <SoftTypography variant="h5" fontWeight="medium">
      Liste des entreprises
    </SoftTypography>
  </SoftBox>
  <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>

    <SoftButton variant="outlined" color="purple" size="small" mr={2}>
      export
    </SoftButton>
    <SoftButton
      onClick={() => navigate("/companies/add-enterprise")}
      variant="gradient"
      color="purple"
      size="small"
      mb={{ xs: 2, sm: 0 }} 
    >
      +Ajouter entreprise
    </SoftButton>
  </Stack>
</SoftBox>

          {loading ? (
            <SoftBox
              sx={{
                mt: 13,
                textAlign: "center",
              }}
            >
              <CircularProgress />
            </SoftBox>
          ) : (
            <DataTable table={{
              columns: [
                { Header: "nom de l'entreprise", accessor: "enterpriseName", width: "25%" },
                { Header: "Matricule Fiscal", accessor: "numeroDeSerie", width: "30%" },
                { Header: "paiement", accessor: "payment" },
                { Header: "email", accessor: "email", width: "30%" },
                { Header: "téléphone", accessor: "phone", width: "30%" },
                { Header: "active", accessor: "active", width: "12%" },
                { Header: "action", accessor: "action" }
              ],
              rows:
                dataTo && dataTo.map((enterprise) => ({
                  enterpriseName: (
                    <>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar sx={{ mr: 2 }} src={`${process.env.REACT_APP_BASE_URL}/${enterprise.logoUrl}`} alt="Logo" />
                        {enterprise.name}
                      </Box>
                    </>
                  ),
                  numeroDeSerie: enterprise.serialNumber,
                  payment: enterprise.paymentState,
                  email: enterprise.email,
                  phone: enterprise.phone,
                  active: enterprise.paymentState,
                  action: <EnterpriseAction key={enterprise._id} enterprise={enterprise} />
                })),
            }}
              entriesPerPage={afficheFilter ? { defaultValue: 7, entries: [5, 7, 10, 15, 20, 25], } : false} canSearch={afficheFilter ? true : false}
            />
          )}
        </Card>

      </SoftBox>

    </DashboardLayout>
  );
}

export default EnterpriseTable;
