import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/DashboardNavbar";
import React, { useEffect, useState } from "react";
import {  Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import api from "utils/axios";
import ClassInfo from "./components/update-class-components/ClassInfo";
import ClassInfoCabinet from "./classe-cabinet/ClassInfoCabinet";
const facility = JSON.parse(localStorage.getItem("facility"));

export default function UpdateClass() {
  const { id } = useParams();
  const [classe, setClasse] = useState({});
  const handleFetchStudentById = async (idToFetch) => {
    try {
      if (idToFetch) {
        let endpoint;
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/get-classe/${idToFetch}`;
      } else {
        endpoint = `/faculty/get-classe/${idToFetch}`;
      }  
        const response = await api.get(endpoint);
        const fetchClasse= response.data;
        setClasse(fetchClasse);
      }
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    if (id !== undefined) {
      handleFetchStudentById(id);
    }
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={9}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Grid item xs={12}>
            {
              facility?.type === "centreDeFormation" ? (
                <ClassInfoCabinet classe={classe} />
              ):(
                <ClassInfo classe={classe} />
              )
            }
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}
