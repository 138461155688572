import React, { useEffect, useState } from "react";
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import api from "utils/axios";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  TextField
} from "@mui/material";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSoftUIController } from "context";
function ForamtionActions({ formation, handleFetch, setShowDetails, setDetailsData }) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(formation.name);
  const [type, setType] = useState(formation.type);
  const [nbrNiveaux, setnbrNiveaux] = useState(formation.nbrNiveaux);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageType, setErrorMessageType] = useState("");
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [controller] = useSoftUIController();
  const [nbrNiveauxError, setNbrNiveauxError] = useState("");

  const [optionsTypes, setOptionsTypes] = useState([
    { value: "semestre", label: "Semestre" },
    { value: "trimestre", label: "Trimestre" },
  ]);

  useEffect(() => {
    api.get(`/all-departements/${facility._id}`).then((res) => {
      setOptions(
        res.data.map((item) => {
          return {
            value: item.code,
            label: item.name,
          };
        })
      );
    });
  }, []);
  const handleUpdate = async () => {
    try {
      setLoading(true);
      if (name.length < 2) {
        setErrorMessage("La longueur minimale requise est de 2 caractères.");
        setNbrNiveauxError("");
      }/*  else if (nbrNiveaux < 1) {
        setNbrNiveauxError("Le nombre de niveaux doit être supérieur ou égal à 1.");
        setErrorMessage("");
      } */ else if (nbrNiveaux < 1 || nbrNiveaux > 10 || !Number.isInteger(Number(nbrNiveaux))) {   
        setNbrNiveauxError("Le nombre de niveaux doit être entre 1 et 10 et n'accepte pas les virgules.");
        setErrorMessage("");
      } else {
        // Effectuez la mise à jour de la formation
        const response = await api.patch(`/faculty/update-formation/${formation._id}`, { name, nbrNiveaux ,type});
        setName(response.data.name);
        setnbrNiveaux(response.data.nbrNiveaux);
        Swal.fire("Success!", "Mise à jour formation réussie", "success");
        handleFetch();
        setOpen(false);
        setErrorMessage("");
        setNbrNiveauxError("");
      }
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setNbrNiveauxError("");
    setName(formation.name);
    setnbrNiveaux(formation.nbrNiveaux);
  };
  const showAlert = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous voulez supprimer cette foramtion ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await api.delete(`/faculty/delete-formation/${formation._id}`);
            if (response) {
              (await Swal.fire("Suppression !", "La formation  a été supprimée.", "success"))
                .isConfirmed && handleFetch();
            }
          } catch (error) {
            Swal.fire("Erreur!", "La formation n'a pas été supprimée.", "error");
          }
        }
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Consulter" placement="left">
          <VisibilityIcon
            onClick={() => {
              setDetailsData(formation);
              setShowDetails(true);
            }}
          />
        </Tooltip>
      </SoftTypography>
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Modifier" placement="top">
            <Icon onClick={() => setOpen(true)} id="edit-enterprise-page">
              edit
            </Icon>
          </Tooltip>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            onKeyDown={handleKeyPress}
          >
            <DialogTitle>Modifier formation</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.grey[200],
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <DialogContentText>
                Vous devez saisir les information de formation .
              </DialogContentText>
              <Grid sx={{ mt: 1 }}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "flex-start",
                  }}
                >
                  <Grid item xs={12} sm={4}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="medium"
                      color="text"
                      sx={{ mb: 0.5 }}
                    >
                      Nom de formation*
                    </SoftTypography>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",

                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                      }}
                    >
                      <SoftInput
                        name="name"
                        margin="normal"
                        id="name"
                        label="spécialité"
                        type="text"
                        fullWidth
                        placeholder="Nom de la spécialité"
                        inputProps={{
                          minLength: 2,
                          maxLength: 50,
                        }}
                        autoFocus
                        variant="standard"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={name?.length < 2}
                        success={name?.length >= 2}
                      />
                      {name?.length < 2 && (
                        <Typography mt={0.75} variant="caption" color="error">
                          {errorMessage}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  {facility?.type === "faculty" && (

                    <Grid item xs={12} sm={4}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                        color="text"
                        sx={{ mb: 0.5 }}
                      >
                        Nombre de niveau*
                      </SoftTypography>
                      <SoftInput
                        id="credit"
                        name="nbrNiveaux"
                        type="number"
                        margin="normal"
                        variant="standard"
                        fullWidth
                        placeholder="Nombre de niveau"
                        value={nbrNiveaux} // Assurez-vous de lier la valeur de l'input à nbrNiveaux
                        error={nbrNiveaux < 1 || nbrNiveaux > 10 || !Number.isInteger(Number(nbrNiveaux))}      
                        success={nbrNiveaux >= 1 && nbrNiveaux <= 10 && Number.isInteger(Number(nbrNiveaux))}
                        onChange={(event) => setnbrNiveaux(event.target.value)}
                      />
                      {(nbrNiveaux < 1 || nbrNiveaux > 10 || !Number.isInteger(Number(nbrNiveaux))) && (
                    <Typography mt={0.75} variant="caption" color="error">
                      {nbrNiveauxError}  </Typography>
                  )}
                    </Grid>

                  )}
                   <Grid item xs={12} sm={ 4}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="medium"
                    color="text"
                    sx={{ mb: 0.5 }}
                  >
                    Type*
                  </SoftTypography>
                  <Autocomplete
                    id={`size-small-standard`}
                    options={optionsTypes}
                    title="Sélectionner uu type"
                    aria-required={true}
                    required={true}
                    getOptionLabel={(option) => option.label}
                    defaultValue={null}
                    clearIcon={null}
                    placeholder="Veuillez
                    sélectionner le
                    type "
                    value={optionsTypes.find((option) => option.value === type) || null}
                    onChange={(event, newValue) => {
                      if (newValue !== null && newValue !== undefined) {
                       setType(newValue.value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required={true}
                        //value={input.facilityType}
                        placeholder="Veuillez
                        sélectionner le type"
                      />
                    )}
                  />
                {/*   {input.type === "" && (
                    <Typography mt={0.75} variant="caption" color="error">
                      {errorMessageType}
                    </Typography>
                  )} */}
                </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>

              <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                <SoftButton
                  onClick={handleClose}
                  sx={{ mt: 2 }}
                  variant="outlined"
                  color="dark"
                  size="small"
                >

                  Annuler
                </SoftButton>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <SoftButton
                    onClick={handleUpdate}
                    sx={{ mt: 2 }}
                    variant="gradient"
                    color="customized"
                    size="small"
                  >
                    Modifier
                  </SoftButton>
                )}
              </SoftBox>
            </DialogActions>
          </Dialog>
        </SoftTypography>
      </SoftBox>
      {controller?.user?.info?.role === "company-admin" && (
        <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Supprimer" placement="left">
            <Icon id="delete-enterprise" onClick={showAlert}>
              delete
            </Icon>
          </Tooltip>
        </SoftTypography>
      )}
    </SoftBox>
  );
}
ForamtionActions.propTypes = {
  formation: PropTypes.object.isRequired,
  handleFetch: PropTypes.func.isRequired,
  setShowDetails: PropTypes.bool.isRequired,
  setDetailsData: PropTypes.array.isRequired,
};
export default ForamtionActions;
