import { AppBar, Grid, Tab, Tabs } from '@mui/material';
import SoftBox from 'components/SoftBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'layouts/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import breakpoints from "assets/theme/base/breakpoints";
import Cours from './components/Cours';
import Td from './components/Td';
import Flux from './components/Flux';
import SoftTypography from 'components/SoftTypography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SoftButton from 'components/SoftButton';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from 'utils/axios';
import { useSoftUIController } from 'context';
import Tp from './components/Tp';
import MiniProjet from './components/MiniProjet';

export default function ListAllCours() {
    const { id, subjectId } = useParams()
    const location = useLocation();
    const selectedType = location?.state?.selectedType || "";
    const subjectName = location?.state?.subjectName || "";
    const selectedIns = location?.state?.selectedIns || "";
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [controller] = useSoftUIController();
    const facility = JSON.parse(localStorage.getItem("facility"));
    const [tabValue, setTabValue] = useState(facility?.type === "centreDeFormation" ? "Cours" : selectedType);
    const user = controller?.user?.info
    const hasEditDeletePermission =
        user?.role === 'company-admin' ||
        user?.role === 'instructor' ||
        user?.role === 'admin' ||
        user?.role === 'editor';
    const handleFetch = async () => {
        try {
            let endpoint;
            if (facility?.type === "centreDeFormation") {
                endpoint = `training-company/get-all-lessons/?scholarityConfigId=${facility.scholarityConfigId}&subjectId=${subjectId}&instructorId=${selectedIns}&classeId=${id}`;
            } else {
                endpoint = `/faculty/get-all-lessons-by-subjectId/${subjectId}`;
            }
            const response = await api.get(endpoint);
            setData(response.data);
        } catch (error) {
            throw error;
        }
    };
    useEffect(() => {
        handleFetch();
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color:
                theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    }));
    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }
        /** 
         The event listener that's calling the handleTabsOrientation function when resizing the window.
        */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid item xs={6} md={12} lg={6} mt={2} >
                <SoftTypography
                    m={1}
                    fontWeight="medium"
                    color="dark">
                    {subjectName}
                </SoftTypography>
            </Grid>
            <Grid>
                <SoftBox mt={2}>
                    <Grid container>
                        <Grid item xs={12} sm={6} lg={4}>
                            <AppBar position="static">
                                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                                    {facility?.type === "centreDeFormation" ? <Tab id="tab-cours" value="Cours" label="Cours" /> : selectedType === "Cours" && <Tab id="tab-cours" value="Cours" label="Cours" />}
                                    {selectedType === "TD" && <Tab id="tab-td" value="TD" label="TD"
                                        disabled={facility?.type === "centreDeFormation" ? true : false} />}
                                    {selectedType === "TP" && <Tab id="tab-flux" value="TP" label="TP"
                                        disabled={facility?.type === "centreDeFormation" ? true : false} />}
                                    {selectedType === "Mini Projet" && <Tab id="tab-flux" value="Mini Projet" label="Mini Projet"
                                        disabled={facility?.type === "centreDeFormation" ? true : false} />}
                                    <Tab id="tab-flux" value="flux" label="Flux"
                                        disabled={facility?.type === "centreDeFormation" ? true : false} />
                                </Tabs>
                            </AppBar>
                        </Grid>
                        <Grid item xs={12} sm={2} lg={4}
                            overflow={{ xs: 'hidden', md: 'hidden' }}>
                        </Grid>
                        <Grid item xs={12} sm={2} lg={4}>
                            <div>
                                {hasEditDeletePermission && <SoftBox display="flex"
                                    justifyContent={{ xs: 'center', md: "flex-end" }}>
                                    <SoftButton variant="gradient"
                                        style={{ backgroundColor: "#7F47E6", color: "#FFFFFF", minWidth: "120px" }}
                                        id="demo-customized-button"
                                        aria-controls={open ? 'demo-customized-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        disableElevation
                                        onClick={handleClick}
                                    >
                                        <AddCircleOutlineIcon style={{ marginRight: '8px' }} /> Creé
                                    </SoftButton>
                                </SoftBox>}
                                <StyledMenu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    {facility?.type === "centreDeFormation" ? <><MenuItem onClick={() => {
                                        navigate(`/classes/${id}/matieres/${subjectId}/add-cours`, { state: { paramName: "Cours", selectedIns: selectedIns } })
                                        handleClose
                                    }} disableRipple
                                        style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', border: '1px solid #ccc', fontSize: "17px" }}>
                                        <AutoStoriesIcon style={{ color: '#7F47E6' }} />
                                        Cours
                                    </MenuItem>
                                        {/* <Divider sx={{ my: 0.5 }} /> */}</> : selectedType === "Cours" && <><MenuItem onClick={() => {
                                            navigate(`/classes/${id}/matieres/${subjectId}/add-cours`, { state: { paramName: "Cours", selectedIns: selectedIns } })
                                            handleClose
                                        }} disableRipple
                                            style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', border: '1px solid #ccc', fontSize: "17px" }}>
                                            <AutoStoriesIcon style={{ color: '#7F47E6' }} />
                                            Cours
                                        </MenuItem>
                                            {/* <Divider sx={{ my: 0.5 }} /> */}</>}
                                    {selectedType === "TD" && <><MenuItem disabled={facility?.type === "centreDeFormation" ? true : false} onClick={() => {
                                        navigate(`/classes/${id}/matieres/${subjectId}/add-cours`, { state: { paramName: "TD" } })
                                        handleClose
                                    }} disableRipple
                                        style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', border: '1px solid #ccc', fontSize: "17px" }}>
                                        <EditIcon style={{ color: '#7F47E6' }} />
                                        TD
                                    </MenuItem>
                                        {/* <Divider sx={{ my: 0.5 }} /> */}</>}
                                    {selectedType === "TP" && <><MenuItem disabled={facility?.type === "centreDeFormation" ? true : false} onClick={() => {
                                        navigate(`/classes/${id}/matieres/${subjectId}/add-cours`, { state: { paramName: "TP" } })
                                        handleClose
                                    }} disableRipple
                                        style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', border: '1px solid #ccc', fontSize: "17px" }}>
                                        <DescriptionOutlinedIcon style={{ color: '#7F47E6' }} />
                                        TP
                                    </MenuItem>
                                        {/* <Divider sx={{ my: 0.5 }} /> */}</>}
                                    {selectedType === "Mini Projet" && <MenuItem disabled={facility?.type === "centreDeFormation" ? true : false} onClick={() => {
                                        navigate(`/classes/${id}/matieres/${subjectId}/add-cours`, { state: { paramName: "Mini Projet" } })
                                        handleClose
                                    }} disableRipple
                                        style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', border: '1px solid #ccc', fontSize: "17px" }}>
                                        <DescriptionOutlinedIcon style={{ color: '#7F47E6' }} />
                                        Mini Projet
                                    </MenuItem>}
                                </StyledMenu>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid marginLeft={172}>
                    </Grid>
                </SoftBox>
            </Grid>
            {tabValue == "Cours" ? <Cours data={data} handleFetch={handleFetch} /> : tabValue == "TD" ? <Td data={data} handleFetch={handleFetch} /> : tabValue == "TP" ? <Tp data={data} handleFetch={handleFetch} /> : tabValue == "Mini Projet" ? <MiniProjet data={data} handleFetch={handleFetch} /> : <Flux />}
        </DashboardLayout>
    )
}