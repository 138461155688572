import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate, useParams } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import api from "utils/axios";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import SoftButton from "components/SoftButton";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "examples/Tables/DataTable";
import { useSoftUIController } from "context";
function PresenceActions({
  presence,
  index,
  deleteEyes,
  deleteUpdate,
  deleteDelete,
  handleFetch,
  setShowDetails,
  setDetailsData,
  handleFetchHistorique
}) {
  const navigate = useNavigate();
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [open, setOpen] = useState(false);
  const {id}=useParams()
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const facility = JSON.parse(localStorage.getItem("facility"));
  const [students, setStudents] = useState([]);
  const [controller, dispatch] = useSoftUIController();
 const user = controller.user.info;
  const hasEditDeletePermissionBtn =
  user?.role === 'company-admin' ||
  user?.role === 'instructor' ||
  user?.role === 'admin' ||
  user?.role === 'editor';

  useEffect(() => {
    if (presence && presence.students) {
      setStudents(presence.students);
    }
  }, [presence]);

  const handleStatusPChange = (id) => {
    setStudents((prevStudents) =>
      prevStudents.map((student) =>
        student._id === id
          ? { ...student, presence: student.presence === "absent" ? "present" : "absent" }
          : student
      )
    );
  };
const studentStatusArray = students?.map(student => ({
    student: student.student._id,
    presence: student.presence
  }));
 
  const handleUpdate = async () => {
    const info = {
      classeId: id,
      subjectId: presence?.subjectId?._id,
      sessionDate: presence.sessionDate,
      startDate: presence.startDate,
      endDate: presence.endDate,
      students: studentStatusArray
    };
    try {
      setLoading(true);
      let endpoint;
      if (facility?.type === "centreDeFormation") {
        endpoint = `training-company/update-attendance/${presence._id}`;
      } else {
        endpoint = `/faculty/update-attendance/${presence._id}`;
      }
        const response = await api.put(endpoint,info);
        Swal.fire("Success!", "Mise à jour présence réussie", "success")  .isConfirmed
        ? handleFetch()   
        : "";        
      setOpen(false);
      setErrorMessage("");
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
  };
  const showAlert = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });
    newSwal
      .fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous voulez supprimer ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            let endpoint;
            if (facility?.type === "centreDeFormation") {
              endpoint = `training-company/delete-attendance/${presence._id}`;
            } else {
              endpoint = `/faculty/delete-attendance/${presence._id}`;
            }
            const response = await api.delete(endpoint);
            if (response) {
              (await Swal.fire("Suppression !", "Présence a été supprimée.", "success"))
                .isConfirmed
                ? handleFetchHistorique()
                : "";
            }
          } catch (error) {
            Swal.fire("Erreur!", "Présence n'a pas été supprimée.", "error");
          }
        }
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };
  return (
    <SoftBox display="flex" alignItems="center">
      {!deleteEyes && (
        <SoftBox>
          <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
            <Tooltip title="Consulter" placement="left">
              <VisibilityIcon
              id={`btn-show-${index}`}
                onClick={() => {
                  setDetailsData(prevState => {               
                    return {...prevState,...presence };
                });
                  setShowDetails(true);
                  handleFetchHistorique()        
                }}
              />
            </Tooltip>
          </SoftTypography>
        </SoftBox>
      )}
      {!deleteUpdate && (
       hasEditDeletePermissionBtn && <SoftBox mx={2}>
          <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
            <Tooltip title="Modifier" placement="top">
              <Icon  id={`btn-edit-${index}`} onClick={() => setOpen(true)} >
                edit
              </Icon>
            </Tooltip>
            <Dialog
              fullWidth
              maxWidth="md"
              open={open}
              onClose={handleClose}
              onKeyDown={handleKeyPress}
            >
              <DialogTitle>Modifier prèsence</DialogTitle>
              <IconButton
               id={`btn-close`}
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.grey[200],
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <SoftBox id="basic-info" sx={{ overflow: "visible" }}>
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Nom et Prènom ", accessor: "fullName", width: "15%" },
                        { Header: "Telephone", accessor: "tel", width: "15%" },
                        { Header: "Statut", accessor: "statut", width: "15%" },
                        { Header: "action", accessor: "action", width: "10%" },
                        ],
                        rows:  students &&
                        students?.map((student,index) => ({
                          fullName: `${student?.student?.firstName} ${student?.student?.lastName}`,
                          tel: student?.student?.phoneNumber ? `+${student?.student?.phoneNumber}` : "",
                          statut:student?.presence,
                          action: (
                            <SoftBox display="flex" alignItems="center">
                              <SoftBox>
                                <SoftTypography
                                  variant="body1"
                                  color="purple"
                                  sx={{ cursor: "pointer", lineHeight: 0 }}
                                >
                                  <Checkbox
                                   id={`btn-show-${index}`}
                                    checked={student?.presence==="absent"}
                                    onChange={() => handleStatusPChange(student._id)}
                                    color="primary"
                                  />
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          ),
                        })),
                      }}
                      entriesPerPage={
                        afficheFilter ? { defaultValue: 10, entries: [5, 7, 10, 15, 20, 25] } : false
                      }
                      canSearch={afficheFilter ? true : false}
                    />
                </SoftBox>
              </DialogContent>
              <DialogActions>
                <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                  <SoftButton
                   id={`btn-cancel`}
                    onClick={handleClose}
                    sx={{ mt: 2 }}
                    variant="outlined"
                    color="dark"
                    size="small"
                  >
                    Annuler
                  </SoftButton>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <SoftButton
                      onClick={handleUpdate}
                      sx={{ mt: 2 }}
                      variant="gradient"
                      color="customized"
                      size="small"
                      id={`btn-update`}
                    >
                      Modifier
                    </SoftButton>
                  )}
                </SoftBox>
              </DialogActions>
            </Dialog>
          </SoftTypography>
        </SoftBox>
      )}
      {!deleteDelete && (
        hasEditDeletePermissionBtn && <SoftTypography variant="body1" color="purple" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="supprimer" placement="left">
            <Icon  id={`btn-delete-${index}`} onClick={showAlert}>
              delete
            </Icon>
          </Tooltip>
        </SoftTypography>
      )}
    </SoftBox>
  );
}
PresenceActions.propTypes = {
  parcours: PropTypes.object.isRequired,
  presence: PropTypes.object.isRequired,
  sousPeriode: PropTypes.object.isRequired,
  handleFetch: PropTypes.func.isRequired,
  handleFetchHistorique:PropTypes.func.isRequired,
  setDetailsData: PropTypes.array.isRequired,
  setShowDetails: PropTypes.bool.isRequired,
  deleteEyes: PropTypes.bool.isRequired,
  deleteUpdate: PropTypes.bool.isRequired,
  deleteDelete: PropTypes.bool.isRequired,
  index:PropTypes.string.isRequired
};
export default PresenceActions;
