/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "pages/resource-management/classe/schemas/form";

const {
  formField: {
    name, 
    abilityMaximum,
    groupNumber,
    level,
    filiere,
    parcour,
    periode
  },
} = checkout;

const initialValues = {
  [name.name]: "",
  [abilityMaximum.name]: "",
  [groupNumber.name]:"",
  [level.name]: "",
  [filiere.name]: "",
  [parcour.name]: "",
  [periode.name]: "",

};

export default initialValues;
