import { useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import EnterpriseFormField from "pages/enterprise/schema/enterprise-schemas/EnterpriseFormField";

// NewUser page components
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Autocomplete, IconButton, Stack, TextField } from "@mui/material";
import SoftButton from "components/SoftButton";

function EnterpriseConfiguration({ formData }) {
  const { formField, values, errors, touched } = formData;
  const {
    facilityName,
    facilitySerialNumber,
    facilityType
  } = formField;


  const [facilityTypee, setFacilityTypee] = useState(values.facilityType);
  values.facilityType = facilityTypee;
  // const [facilityName,setFacilityName] = useState("");
  const [options, setOptions] = useState([
    { value: "cresh", label: "Crèche" },
    { value: "jardinDenfent", label: "Jardin d'enfants" },
    { value: "ecolePrimaire", label: "École primaire" },
    { value: "college", label: "Collège" },
    { value: "lycee", label: "Lycée" },
    { value: "faculty", label: "Université" },
    { value: "centreDeFormation", label: "Centre de formation" },
  ]);
  const facilityy = values?.facilityName && values?.facilityName?.map(fac => ({
    facilityName: fac ? fac.facilityName : "",
    facilityType: fac ? fac.facilityType : "",
    facilitySerialNumber: fac ? fac.facilitySerialNumber : ""
  }));

  const [inputs, setInputs] = useState(values?.facilityName ? facilityy : [{
    facilityName: "",
    facilityType: "",
    facilitySerialNumber: ""
  }]);

  let {
    facilityName: nameX
  } = values;
  
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  };
  values.facilityName = inputs;


  const handleAddInput = () => {
    setInputs([...inputs, { facilityName: "", facilityType: "", facilitySerialNumber: "" }]);
  };
  const handleRemoveInput = (indexToRemove) => {
    const newInputs = [...inputs];

    if (indexToRemove >= 0 && indexToRemove < newInputs.length) {
      // Remove the input at the specified index
      newInputs.splice(indexToRemove, 1);
      setInputs(newInputs);
    }

  }
  return (
    <SoftBox sx={{ overflow: "visible" }}>
      <SoftBox lineHeight={1}>
        <SoftTypography variant="h5" fontWeight="bold">
          Ajouter et configurer vos établissements
        </SoftTypography>

      </SoftBox>
      <SoftBox mt={1.625}>
        {inputs.map((input, index) => (
          <Grid container key={index} justifyContent="center" alignItems="center" gap={2}>
            <Grid item xs={12} sm={3}>
              <EnterpriseFormField
                id="facility-name"
                type={facilityName.type}
                label={facilityName.label}
                // disabled={inputs.length === 1}
                name={facilityName.name}
                required={true}
                value={input.facilityName}
                inputProps={{
                  maxLength: 30
                }}
                placeholder={facilityName.placeholder}
                onChange={(event) => handleInputChange(index, event)}
                error={input.facilityName.length < 2 && touched.facilityName}
                success={input.facilityName.length >= 2 && !errors.facilityName}

              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <EnterpriseFormField
                id="facility-serial-number"
                type={facilitySerialNumber.type}
                label={facilitySerialNumber.label}
                // disabled={inputs.length === 1}
                name={facilitySerialNumber.name}
                inputProps={{
                  minLength: 5,
                  maxLength: 20
                }}
                required={true}
                value={input.facilitySerialNumber}
                placeholder={facilitySerialNumber.placeholder}
                onChange={(event) => handleInputChange(index, event)}
                error={input.facilitySerialNumber.length < 8 && touched.facilitySerialNumber}
                success={input.facilitySerialNumber.length >= 8 && !errors.facilitySerialNumber}
              />
            </Grid>
            <Grid item xs={12} sm={4} mt={-2}>
              <Stack spacing={2} sx={{ width: "100%", margin: "auto" }}>
                <SoftBox>
                  <SoftTypography
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >type d&apos;établissement
                  </SoftTypography>
                  <Autocomplete
                    id={`size-small-standard-${index}`}
                    options={options}
                    aria-required={true}
                    required={true}
                    getOptionLabel={(option) => option.label}
                    value={options.find((option) => option.value === input.facilityType) || null}
                    onChange={(event, newValue) => {
                      if (newValue !== null && newValue !== undefined) {
                        const newInputs = [...inputs];
                        newInputs[index].facilityType = newValue.value;
                        setInputs(newInputs);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        variant="standard"
                        value={input.facilityType}
                      />
                    )}
                  />
                </SoftBox>
              </Stack>
            </Grid>
            <Grid item>
              {inputs.length > 1 && (
                <IconButton style={{ marginTop: "35px" }}>
                  <RemoveCircleIcon color="error" fontSize="medium" onClick={() => handleRemoveInput(index)} />
                </IconButton>
              )}
            </Grid>

          </Grid>
        ))}

        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
          <SoftButton
              size="small" sx={{
              mt: 2,
              borderWidth: "2px",
              borderStyle: "dashed solid",
              borderColor: "purple ",
            }} onClick={handleAddInput}>
            +ajouter plus
          </SoftButton>
        </Stack>
      </SoftBox>
    </SoftBox>
  );
}


// typechecking props for Address
EnterpriseConfiguration.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};



export default EnterpriseConfiguration;
